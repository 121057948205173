import React, { useEffect, useState } from 'react';
import InfluencerMetricTable from '../../../../../../components/all/Metrics/InfluencerMetricTable/InfluencerMetricTable';
import InfluencerList from '../../../../../../components/user/marketplace/influencer/InfluencerList';
import './InfluencerInCampaign.css';
import {
  getInfluencersInCampaign,
  campaignClear,
  setInfluencerInCampaign,
} from '../../../../../../functionality/actions/campaignActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Spinner from '../../../../../../components/all/Spinner';
import Error from '../../../../../../components/all/Error';

const InfluencerInCampaignView = (props) => {
  const [option, setOption] = useState('');

  const {
    campaignReducer: {
      influencers_in_campaign,
      influencers_in_campaign_loading,
      influencer_campaign_page,
      influencer_campaign_total_pages,
      influencer_campaign_total_list,
    },
    match: {
      params: { id: campaign_consecutive },
      url,
    },
    location: { search },
    siteReducer: {
      site_texts: { text_approved, text_pending, text_declined },
    },
    history,
    getInfluencersInCampaign,
    setInfluencerInCampaign,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      const route = `${url}?status=1`;
      await history.push(route);
    };

    if (influencers_in_campaign.length === 0) {
      fetchData();
    }
    setOption('1');

    return () => {
      setInfluencerInCampaign([]);
    };
  }, []);

  useEffect(() => {
    getInfluencersInCampaign(campaign_consecutive, search);
  }, [search]);

  /**
   * When the user changes the value of the select element, update the state of the component to
   * reflect the new value.
   */
  const handleChangeOption = async (event) => {
    const value = event.target.value;
    const route = `${url}?status=${value}`;
    setOption(value);
    await history.push(route);
  };

  const Options = () => {
    return (
      <>
        {option === '1' && (
          <InfluencerMetricTable
            loading={influencers_in_campaign_loading}
            data={influencers_in_campaign}
            currentPage={influencer_campaign_page}
            totalPage={influencer_campaign_total_pages}
          />
        )}
        {option === '2' && (
          <InfluencerList
            showAds={true}
            influencers={influencers_in_campaign}
            currentPage={influencer_campaign_page}
            totalPage={influencer_campaign_total_pages}
            otherCol={'col-sm-12'}
            type={'list'}
          />
        )}
        {option === '3' && (
          <InfluencerList
            showAds={true}
            influencers={influencers_in_campaign}
            currentPage={influencer_campaign_page}
            totalPage={influencer_campaign_total_pages}
            type={'list'}
          />
        )}
      </>
    );
  };
  return (
    <div>
      <div className="influencer__options">
        <div className="influencer__options_btns">
          <button
            value={'1'}
            onClick={handleChangeOption}
            className={option === '1' ? 'active' : ''}
          >
            {text_pending || 'Pendiente'}
          </button>
          <button
            value={'2'}
            onClick={handleChangeOption}
            className={option === '2' ? 'active' : ''}
          >
            {text_approved || 'Aprobado'}
          </button>
          <button
            value={'3'}
            onClick={handleChangeOption}
            className={option === '3' ? 'active' : ''}
          >
            {text_declined || 'Declinados'}
          </button>
        </div>
        <div className="total__influencers">
          {influencers_in_campaign_loading ? (
            <span>...</span>
          ) : (
            influencer_campaign_total_list
          )}
        </div>
      </div>
      <div className="influencer__content">
        {influencers_in_campaign_loading ? (
          <div className="spinner-loading">
            <Spinner type="purple" />
          </div>
        ) : (
          <>
            {influencers_in_campaign.length !== 0 && <Options />}
            {!influencers_in_campaign.length && <Error type="no-influencer" />}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ campaignReducer, siteReducer }) => {
  return {
    campaignReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  getInfluencersInCampaign,
  campaignClear,
  setInfluencerInCampaign,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InfluencerInCampaignView)
);
