import React, { useState } from 'react';
import './PostCardData.css';
import { connect } from 'react-redux';
import { getPostEdit } from '../../../functionality/actions/postActions';
import CampaignPostDetail from '../../../pages/user/manager/views/CampaignDetailView/CampaignPosts/CampaignPostDetail/CampaignPostDetail';

const PostCardData = (props) => {
  const {
    siteReducer: {
      site_texts: { text_see_post_here },
    },
    post,
    getPostEdit,
  } = props;
  const [showModalPost, setShowModalPost] = useState(false);

  const SocialNetworks = () => {
    return (
      <>
        {post.social_networks &&
          post.social_networks.map((network) => (
            <>
              {network.link === '' ? (
                <div
                  className={`network__circle circle-${network.slug}`}
                  key={network.id}
                >
                  <i className={`${network.icon}`}></i>
                </div>
              ) : (
                <a
                  data-toggle="tooltip"
                  title="Go to link"
                  className={`network__circle circle-${network.slug}`}
                  key={network.id}
                  href={network.link}
                  target="blank"
                >
                  <i className={`${network.icon}`}></i>
                </a>
              )}
            </>
          ))}
      </>
    );
  };

  const handleViewPost = () => {
    setShowModalPost(true);
  };

  const handleCloseModal = () => {
    setShowModalPost(false);
  };

  return (
    <div className="card__data__container">
      {showModalPost && (
        <div className="modal-background">
          <CampaignPostDetail handleCloseModal={handleCloseModal} />
        </div>
      )}

      <div className="influencer__profile">
        <img
          className="influencer_profile_photo"
          src={post.influencer.image}
          alt=""
        />
        <p>@{post.influencer.username}</p>
      </div>
      {/* <h5>{post.name}</h5> */}
      <h5>{post.post_type.name}</h5>
      <div className="post__networks">
        <SocialNetworks />
      </div>
      <button
        className="post__link"
        onClick={async () => {
          await getPostEdit(post.consecutive);
          handleViewPost();
        }}
      >
        {text_see_post_here ? text_see_post_here : 'Ver post aquí'}
      </button>
    </div>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

const mapDispatchToProps = {
  getPostEdit,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostCardData);
