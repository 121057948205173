import React, { useState, useEffect } from 'react';
import ManagerLayout from './layouts/ManagerLayout';
import { connect } from 'react-redux';
import TopSpinner from '../../../components/all/TopSpinner';
import { getBrandByUser } from '../../../functionality/actions/brandActions';
import ManagerBrands from '../../../components/ManagerBrands';
import { cropperImage } from '../../../functionality/actions/cropperActions';
import CropImages from '../../../components/all/CropImages';
import Api from '../../../functionality/Api';
import { openLoading } from '../../../functionality/actions/userActions';
import {
  brandChangeImage,
  brandChangeCover,
} from '../../../functionality/actions/brandActions';
import BoxWhite from '../../../components/user/marketplace/BoxWhite';

const BrandsPage = (props) => {
  const [imageName, setImageName] = useState('');

  const {
    brandReducer: { brand_loading, brand_user_list },
    userReducer: {
      user_data: { user_type_id },
    },
    getBrandByUser,
  } = props;

  useEffect(() => {
    if (!brand_user_list.length) {
      getBrandByUser();
    }
  }, []);

  const onChangeImage = (e, imageName, width = 500, height = 500) => {
    const { cropperImage } = props;
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    setImageName(imageName);

    const reader = new FileReader();
    reader.onload = () => {
      let config = {
        cropper_image: reader.result,
        cropper_ratio_width: width,
        cropper_ratio_height: height,
      };
      cropperImage(config);
    };
    reader.readAsDataURL(files[0]);
  };

  const onCrop = async (image) => {
    const {
      siteReducer: { text_uploading_image },
      openLoading,
      brandChangeImage,
      brandChangeCover,
    } = props;

    if (imageName === 'img_profile') {
      openLoading(
        text_uploading_image ? text_uploading_image : 'Subiendo Imagen...'
      );
      let data = new FormData();
      data.append('imgBase64', image);
      Api.uploadFile(data, (res) => {
        if (res.location) {
          document.getElementById(imageName).value = '';
          this.userUpdate(res.location);
        }
      });
      return;
    }
    if (imageName === 'brand_image') {
      brandChangeImage(image);
      return;
    }
    if (imageName === 'brand_cover') {
      brandChangeCover(image);
      return;
    }
  };
  return (
    <ManagerLayout>
      {brand_loading && <TopSpinner />}
      {!brand_loading && (
        <>
          <CropImages onCrop={onCrop} />

          <div className="title-manager">
            <div>
              <span className="fa-solid fa-bullhorn" />
              {'Mis Marcas'}
            </div>
          </div>
          <div>
            {/* <div className="row">{renderCard}</div> */}
            <BoxWhite>
              <ManagerBrands onCropImage={onChangeImage} isInManager />
            </BoxWhite>
          </div>
        </>
      )}
    </ManagerLayout>
  );
};

const mapStateToProps = ({ brandReducer, userReducer, siteReducer }) => {
  return {
    brandReducer,
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  getBrandByUser,
  cropperImage,
  brandChangeImage,
  brandChangeCover,
  openLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandsPage);
