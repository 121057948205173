import React, {Component} from 'react';
import UserImageRating from './UserImageRating';
import {Link} from 'react-router-dom';
import ImageProduct  from '../../../images/image-product.jpg';
import './css/ItemProductInline.css';

class ItemProductInline extends Component {
	toLike = (e, id) => {
		e.preventDefault();
		console.log('LIKE', id);
	}

	toAddCart = (e, id) => {
		e.preventDefault();
		console.log('ADD CART', id);
	}

	render() {
		let {data} = this.props;
		return(
			<div className="itemProductInline_container">
				<Link to={`/product/${data.id}`}>
					<div className="itemProductInline_image">
						<div className="itemProductInline_menu">
							<button onClick={(e) => this.toLike(e, data.id)}>
								<span className="icon-like"/>
							</button>
							<button onClick={(e) => this.toAddCart(e, data.id)}>
								<span className="icon-to-cart"/>
							</button>
						</div>
						<img src={ImageProduct} alt={data.name} />
					</div>
					<div className="itemProductInline_box-info">
						<div>
							<div className="itemProductInline_name">{data.name}</div>
							{data.discountPrice !== "" ?
								<div className="itemProductInline_box-price">
									<div className="itemProductInline_price">
										BAX$ {data.discountPrice}
									</div>
									<div className="itemProductInline_discount">
										BAX$ {data.price}
									</div>
								</div>
								:
								<div className="itemProductInline_box-price">
									<div className="itemProductInline_price">
										BAX$ {data.price}
									</div>
								</div>
							}
						</div>
						<UserImageRating data={data.user}/>
					</div>
				</Link>
			</div>
		)
	}
}

export default ItemProductInline;