import React from "react";
import ManagerLayout from "./layouts/ManagerLayout";
import ProductImportView from "./views/ProductImportView";

function ProductImportPage(props) {
  return (
    <ManagerLayout>
      <ProductImportView />
    </ManagerLayout>
  );
}

export default ProductImportPage;
