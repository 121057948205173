import {
  USER_DATA,
  USER_VALIDATE,
  USER_LOGOUT,
  USER_DOC_NUMBER,
  USER_DOC_TYPE,
  USER_EMAIL,
  USER_FIRSTNAME,
  USER_LASTNAME,
  USER_PASSWORD,
  USER_ACTIVITY,
  USER_INIT,
  USER_INIT_ERROR,
  USER_LOADING,
  USER_ERROR,
  USER_CLEAR,
  USER_CLEAR_ERROR,
  USER_SUCCESS,
  USER_CLOSE_LOADING,
  USER_DOC_DATE,
  USER_PROFESSION,
  USER_BILL,
  USER_RUT_SCANN,
  USER_NIT_NUMBER,
  USER_DOC_FRONT,
  USER_DOC_BACK,
  USER_BANK_CERTIFICATION,
  USER_CAMARA_COMERCIO,
  USER_BUSINESS_NAME,
  USER_PHONE,
  USER_DESCRIPTION,
  USER_AVG_SALES,
  USER_WEBSITE,
  USER_FACEBOOK,
  USER_TWITTER,
  USER_LINKEDIN,
  USER_INSTAGRAM,
  USER_COD_CITY,
  USER_ADDRESS,
  USER_ADDRESS_ADDON,
  USER_COD_ACTIVITY,
  USER_COD_DEPARTMENT,
  USER_CHANGE_STEP,
  USER_INITIAL_STEP,
  USER_SUCCESS_ACTIVATION,
  USER_TIMETABLE,
  USER_CHANGE_BUSINESS_PAGE,
  USER_LOADING_INIT,
  USER_BUSINESS_CATEGORY_CLEAR,
  USER_BUSINESS_CATEGORY,
  USER_BUSINESS_CHANGE_PAGE_CATEGORY,
  USER_LIST_TYPE_PRODUCTS,
  USER_CHANGE_PAGE_PRODUCTS,
  USER_CLEAR_TYPE_PRODUCTS,
  USER_USERNAME,
  USER_USERNAME_ERROR,
  USER_USERNAME_VALIDATE,
  USER_USERNAME_SUCCESS,
  USER_PASSWORD_NEW,
  USER_PASSWORD_REPEAT,
  USER_PRODUCT_SEARCH,
  USER_PRODUCT_CHANGE_SEARCH,
  USER_BUSINESS_SEARCH,
  USER_BUSINESS_CATEGORY_SEARCH,
  USER_BUSINESS_CHANGE_SEARCH,
  USER_CHANGE_FILTER_PRICE,
  USER_FILTER_PRICE,
  USER_FAVORITES,
  USER_TYPE_ID,
  USER_VERIFIED,
  USER_COMPANIES,
  USER_REFERRED,
  USER_FAVORITE_COMPANIES,
  USER_TYPE_PERSON,
  USER_RISK_CENTERS,
  USER_LEGAL_REPRESENTATIVE,
  USER_NAME_LEGAL_REPRESENTATIVE,
  USER_LASTNAME_LEGAL_REPRESENTATIVE,
  USER_SOCIAL,
  USER_PROVIDERS,
  USER_VALID_INFORMATION,
  USER_SOCIAL_NETWORKS,
  USER_PRINCIPAL_NETWORK,
  USER_BIRTHDATE,
} from '../types/userTypes';

import Api from '../Api';
import Swal from 'sweetalert2';
import { notificationView } from './notificationActions';
import { setUserProfileData } from './profileActions';

const { REACT_APP_COUNTRY: COUNTRY, REACT_APP_COOKIE_NAME: COOKIE_NAME } =
  process.env;

export const changeLoading = (payload) => (dispatch) => {
  dispatch({
    type: USER_LOADING,
    payload,
  });
};

export const changeFirstname = (value) => (dispatch) => {
  dispatch({
    type: USER_FIRSTNAME,
    payload: value,
  });
};

export const changeLastname = (value) => (dispatch) => {
  dispatch({
    type: USER_LASTNAME,
    payload: value,
  });
};

export const changeEmail = (value) => (dispatch) => {
  dispatch({
    type: USER_EMAIL,
    payload: value,
  });
};

export const changeDocType = (value) => (dispatch) => {
  dispatch({
    type: USER_DOC_TYPE,
    payload: value,
  });
};

export const changeDocNumber = (value) => (dispatch) => {
  dispatch({
    type: USER_DOC_NUMBER,
    payload: value,
  });
};

const changeDateFormat = (value, payload) => {
  const add = value.length < payload.length ? true : false;

  if (add && payload.length < 10 && payload.length === 4) {
    payload = `${payload}-`;
  }
  if (add && payload.length < 10 && payload.length === 7) {
    payload = `${payload}-`;
  }

  return payload;
};

export const changeDocDate = (payload) => (dispatch, getState) => {
  const { doc_expedition_date } = getState().userReducer;
  payload = changeDateFormat(doc_expedition_date, payload);

  dispatch({
    type: USER_DOC_DATE,
    payload,
  });
};

export const changeProfession = (value) => (dispatch) => {
  dispatch({
    type: USER_PROFESSION,
    payload: value,
  });
};

export const changePassword = (value) => (dispatch) => {
  dispatch({
    type: USER_PASSWORD,
    payload: value,
  });
};

export const changePasswordNew = (value) => (dispatch) => {
  dispatch({
    type: USER_PASSWORD_NEW,
    payload: value,
  });
};

export const changePasswordRepeat = (value) => (dispatch) => {
  dispatch({
    type: USER_PASSWORD_REPEAT,
    payload: value,
  });
};

export const changeActivity = (value) => (dispatch) => {
  dispatch({
    type: USER_ACTIVITY,
    payload: value,
  });
};

export const changeRiskCenters = (value) => (dispatch) => {
  dispatch({
    type: USER_RISK_CENTERS,
    payload: value,
  });
};

export const changeBill = (value) => (dispatch) => {
  dispatch({
    type: USER_BILL,
    payload: value,
  });
};

export const changeRutScann = (value) => (dispatch) => {
  dispatch({
    type: USER_RUT_SCANN,
    payload: value,
  });
};

export const changeNitNumber = (value) => (dispatch) => {
  dispatch({
    type: USER_NIT_NUMBER,
    payload: value,
  });
};

export const changeDocFront = (value) => (dispatch) => {
  dispatch({
    type: USER_DOC_FRONT,
    payload: value,
  });
};

export const changeDocBack = (value) => (dispatch) => {
  dispatch({
    type: USER_DOC_BACK,
    payload: value,
  });
};

export const changeBankCertification = (value) => (dispatch) => {
  dispatch({
    type: USER_BANK_CERTIFICATION,
    payload: value,
  });
};

export const changeCamaraComercio = (value) => (dispatch) => {
  dispatch({
    type: USER_CAMARA_COMERCIO,
    payload: value,
  });
};

export const changeBusinessName = (value) => (dispatch) => {
  dispatch({
    type: USER_BUSINESS_NAME,
    payload: value,
  });
};

export const changePhone = (value) => (dispatch) => {
  dispatch({
    type: USER_PHONE,
    payload: value,
  });
};

export const changeDescription = (value) => (dispatch) => {
  dispatch({
    type: USER_DESCRIPTION,
    payload: value,
  });
};

export const changeAvgSales = (value) => (dispatch) => {
  dispatch({
    type: USER_AVG_SALES,
    payload: value,
  });
};

export const changeWebsite = (value) => (dispatch) => {
  dispatch({
    type: USER_WEBSITE,
    payload: value,
  });
};

export const changeTimetable = (value) => (dispatch) => {
  dispatch({
    type: USER_TIMETABLE,
    payload: value,
  });
};

export const changeFacebook = (value) => (dispatch) => {
  dispatch({
    type: USER_FACEBOOK,
    payload: value,
  });
};

export const changeTwitter = (value) => (dispatch) => {
  dispatch({
    type: USER_TWITTER,
    payload: value,
  });
};

export const changeLinkedin = (value) => (dispatch) => {
  dispatch({
    type: USER_LINKEDIN,
    payload: value,
  });
};

export const changeInstagram = (value) => (dispatch) => {
  dispatch({
    type: USER_INSTAGRAM,
    payload: value,
  });
};

export const changeCodDepartment = (value) => (dispatch) => {
  dispatch({
    type: USER_COD_DEPARTMENT,
    payload: value,
  });
};

export const changeCodCity = (value) => (dispatch) => {
  dispatch({
    type: USER_COD_CITY,
    payload: value,
  });
};

export const changeAddress = (value) => (dispatch) => {
  dispatch({
    type: USER_ADDRESS,
    payload: value,
  });
};

export const changeAddressAddon = (value) => (dispatch) => {
  dispatch({
    type: USER_ADDRESS_ADDON,
    payload: value,
  });
};

export const changeCategory = (value) => (dispatch) => {
  dispatch({
    type: USER_COD_ACTIVITY,
    payload: value,
  });
};

export const changeUserTypeId = (payload) => (dispatch) => {
  dispatch({
    type: USER_TYPE_ID,
    payload,
  });
};

export const changeSettingStep = (value) => (dispatch) => {
  document.getElementById('initSteps').scrollIntoView();
  dispatch({
    type: USER_CHANGE_STEP,
    payload: value,
  });
};

export const initialSettingStep = (value) => (dispatch) => {
  dispatch({
    type: USER_INITIAL_STEP,
  });
};

export const changeReferred = (payload) => (dispatch) => {
  dispatch({
    type: USER_REFERRED,
    payload,
  });
};

export const changeTypePerson = (payload) => (dispatch) => {
  dispatch({
    type: USER_TYPE_PERSON,
    payload,
  });
};

export const changeNameLegalRepresentative = (payload) => (dispatch) => {
  dispatch({
    type: USER_NAME_LEGAL_REPRESENTATIVE,
    payload,
  });
};

export const changeLastnameLegalRepresentative = (payload) => (dispatch) => {
  dispatch({
    type: USER_LASTNAME_LEGAL_REPRESENTATIVE,
    payload,
  });
};

export const changeUserSocial = (payload) => (dispatch) => {
  dispatch({
    type: USER_SOCIAL,
    payload,
  });
};

export const changeUserProviders = (payload) => (dispatch) => {
  dispatch({
    type: USER_PROVIDERS,
    payload,
  });
};

export const changeUserValidInformation = (payload) => (dispatch) => {
  dispatch({
    type: USER_VALID_INFORMATION,
    payload,
  });
};

export const changeUserFavorites = (payload) => (dispatch) => {
  dispatch({
    type: USER_FAVORITES,
    payload,
  });
};

export const changeUserFavoriteCompanies = (payload) => (dispatch) => {
  dispatch({
    type: USER_FAVORITE_COMPANIES,
    payload,
  });
};

export const changeLegalRepresentative = (payload) => (dispatch, getState) => {
  const {
    user_data: { name, lastname },
  } = getState().userReducer;

  if (payload) {
    dispatch(changeNameLegalRepresentative(name));
    dispatch(changeLastnameLegalRepresentative(lastname));
  } else {
    dispatch(changeNameLegalRepresentative(''));
    dispatch(changeLastnameLegalRepresentative(''));
  }

  dispatch({
    type: USER_LEGAL_REPRESENTATIVE,
    payload,
  });
};

export const register = (text_loading) => async (dispatch, getState) => {
  dispatch({
    type: USER_LOADING,
    payload: text_loading,
  });
  const { firstname, lastname, email, password, user_referred, user_type_id } =
    getState().userReducer;

  let data = {
    name: firstname,
    lastname,
    email,
    password,
    user_type_id: parseInt(user_type_id),
    terms_conditions: true,
    privacy_policy: true,
    device_name: window.navigator.userAgent,
  };

  if (user_referred) {
    data.referred = user_referred;
  }

  Api.callMethod(
    'v1/users/register',
    'POST',
    data,
    function (res) {
      if (res.status === 201) {
        const { data, token } = res.response;

        let sessionExpiresDate = new Date();
        sessionExpiresDate.setDate(sessionExpiresDate.getDate() + 180);
        document.cookie = `${COOKIE_NAME}_token=${token}; expires=${sessionExpiresDate}`;

        dispatch({
          type: USER_DATA,
          payload: data,
        });
      } else {
        dispatch(userSorryError());
      }
    },
    function (err) {
      dispatch(userSorryError());
    }
  );
};

export const sessionValidate = () => async (dispatch) => {
  const cookies = document.cookie.split(';');
  let token = cookies.filter(
    (item) => item.indexOf(`${COOKIE_NAME}_token=`) >= 0
  );
  if (token.length) {
    dispatch({
      type: USER_VALIDATE,
    });

    Api.callMethod(
      'v1/users/profile',
      'get',
      null,
      function (res) {
        if (res.status === 200) {
          let { data } = res.response;
          if (data) {
            dispatch({
              type: USER_DATA,
              payload: data,
            });
            dispatch(setUserProfileData(data));

            if (data.is_active) {
              const minutes = 1000 * 60;
              dispatch(notificationView());
              setInterval(() => {
                dispatch(notificationView());
              }, minutes);
            }

            let name =
              data.docs && data.docs.business_name
                ? data.docs.business_name
                : `${data.name} ${data.lastname}`;

            // Add user userguiding
            window.userGuiding.identify(data.id, {
              email: data.email,
              name: name,
            });

            // add user to chat
            window.intercomSettings = {
              api_base: 'https://api-iam.intercom.io',
              app_id: 'bsfnos5u',
              name: name,
              email: data.email,
              created_at: new Date().getTime(),
            };
            (function () {
              var w = window;
              var ic = w.Intercom;
              if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
              } else {
                var d = document;
                var i = function () {
                  i.c(arguments);
                };
                i.q = [];
                i.c = function (args) {
                  i.q.push(args);
                };
                w.Intercom = i;
                var l = function () {
                  var s = d.createElement('script');
                  s.type = 'text/javascript';
                  s.async = true;
                  s.src = 'https://widget.intercom.io/widget/bsfnos5u';
                  var x = d.getElementsByTagName('script')[0];
                  x.parentNode.insertBefore(s, x);
                };
                if (document.readyState === 'complete') {
                  l();
                } else if (w.attachEvent) {
                  w.attachEvent('onload', l);
                } else {
                  w.addEventListener('load', l, false);
                }
              }
            })();
          } else {
            dispatch(logout());
          }
        } else {
          dispatch(logout());
        }
      },
      function (err) {
        console.log('Error profile: ', err);
        dispatch(logout());
      }
    );
  }
};

export const initialList = () => async (dispatch, getState) => {
  const { item_per_page } = getState().userReducer;
  const {
    site_texts: { text_error_bring_activities },
  } = getState().siteReducer;

  dispatch({
    type: USER_LOADING_INIT,
  });
  Api.callMethod(
    `v1/site/initial?country=${COUNTRY ? COUNTRY : 'US'}`,
    'get',
    null,
    function (res) {
      if (res.status === 200) {
        let data = res.response.data;
        data.listViewBusiness = data.listBusiness.slice(0, item_per_page);
        dispatch({
          type: USER_INIT,
          payload: data,
        });
      } else {
        dispatch({
          type: USER_INIT_ERROR,
          payload: `${
            text_error_bring_activities ||
            'Hubo un error al traer las actividades.'
          }`,
        });
      }
    },
    function (err) {
      dispatch({
        type: USER_INIT_ERROR,
        payload: `${
          text_error_bring_activities ||
          'Hubo un error al traer las actividades.'
        }`,
      });
    }
  );
};

export const logout = () => (dispatch, getState) => {
  const {
    site_texts: { text_log_out },
  } = getState().siteReducer;
  dispatch({
    type: USER_LOADING,
    payload: text_log_out || 'Cerrar sesión',
  });
  Api.callMethod(
    'v1/users/logout',
    'get',
    null,
    (res) => {
      dispatch({
        type: USER_LOGOUT,
      });
      document.cookie = `${COOKIE_NAME}_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
      window.location.href = '/';
    },
    (error) => {
      console.log('Error userSubmitLogout ', error);
      document.cookie = `${COOKIE_NAME}_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
      window.location.href = '/';
    }
  );
};

export const login = (text_loading) => (dispatch, getState) => {
  dispatch({
    type: USER_LOADING,
    payload: text_loading,
  });
  const { email, password } = getState().userReducer;

  let data = {
    email,
    password,
    device_name: window.navigator.userAgent,
  };

  Api.callMethod(
    'v1/users/login',
    'POST',
    data,
    function (res) {
      if (res.status === 200) {
        const { data, token } = res.response;

        let sessionExpiresDate = new Date();
        sessionExpiresDate.setDate(sessionExpiresDate.getDate() + 180);
        document.cookie = `${COOKIE_NAME}_token=${token}; expires=${sessionExpiresDate}`;

        dispatch({
          type: USER_DATA,
          payload: data,
        });
        dispatch(setUserProfileData(data));
      } else {
        dispatch(userSorryError());
      }
    },
    function (err) {
      dispatch(userSorryError());
    }
  );
};

export const userClear = () => (dispatch) => {
  dispatch({
    type: USER_CLEAR,
  });
};

export const userClearError = () => (dispatch) => {
  dispatch({
    type: USER_CLEAR_ERROR,
  });
};

export const forgot = (text_loading) => (dispatch, getState) => {
  dispatch({
    type: USER_LOADING,
    payload: text_loading,
  });
  const { email } = getState().userReducer;
  const {
    site_texts: { text_send_password },
  } = getState().siteReducer;
  let data = { email };
  Api.callMethod(
    'v1/users/forgot',
    'POST',
    data,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: USER_SUCCESS,
          payload: text_send_password
            ? text_send_password
            : 'La contraseña fue enviada con éxito.',
        });
      } else {
        dispatch(userSorryError());
      }
    },
    function (err) {
      dispatch(userSorryError());
    }
  );
};

export const openLoading = (text_loading) => (dispatch) => {
  dispatch({
    type: USER_LOADING,
    payload: text_loading,
  });
};

export const closeLoading = () => (dispatch) => {
  dispatch({
    type: USER_CLOSE_LOADING,
  });
};

export const userUpdate =
  (data, step = null) =>
  async (dispatch, getState) => {
    const {
      site_texts: { text_updating_profile },
    } = getState().siteReducer;

    if (step) {
      dispatch({
        type: USER_LOADING,
        payload: `${text_updating_profile || 'Actualizando Perfil'}...`,
      });
    }

    Api.callMethod(
      'v1/users/update',
      'PUT',
      data,
      function (res) {
        if (res.status === 200) {
          dispatch({
            type: USER_DATA,
            payload: res.response.data,
          });
          if (step) {
            dispatch({
              type: USER_CHANGE_STEP,
              payload: step,
            });
          }
        } else {
          dispatch(userSorryError());
        }
      },
      function (err) {
        dispatch(userSorryError());
      }
    );
  };

export const saveUsername = (data, step) => async (dispatch) => {
  Api.callMethod(
    'v1/users/update',
    'PUT',
    data,
    function (res) {
      if (res.status !== 200) {
        dispatch(userSorryError());
      }
    },
    function (err) {
      dispatch(userSorryError());
    }
  );
};

export const userSaveDocs =
  (data, step = null) =>
  async (dispatch, getState) => {
    const {
      user_data: { docs },
    } = getState().userReducer;
    const route = docs ? `v1/user-documents/${docs.id}` : 'v1/user-documents';
    const method = docs ? 'PUT' : 'POST';
    const status = docs ? 200 : 201;

    if (step) {
      dispatch({
        type: USER_LOADING,
        payload: 'Guardando información...',
      });
    }

    Api.callMethod(
      route,
      method,
      data,
      function (res) {
        if (res.status === status) {
          dispatch({
            type: USER_DATA,
            payload: res.response.data,
          });
          if (step) {
            dispatch({
              type: USER_CHANGE_STEP,
              payload: step,
            });
          }
        } else {
          dispatch(userSorryError());
        }
      },
      function (err) {
        dispatch(userSorryError());
      }
    );
  };

export const userReqActivation = () => async (dispatch, getState) => {
  const {
    site_texts: { text_success_text, text_requesting_activation },
  } = getState().siteReducer;

  dispatch({
    type: USER_LOADING,
    payload: text_requesting_activation
      ? text_requesting_activation
      : 'Solicitando activación...',
  });

  Api.callMethod(
    'v1/users/request-activation',
    'POST',
    {},
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: USER_SUCCESS_ACTIVATION,
          payload: text_success_text
            ? text_success_text
            : 'La solicitud ha sido enviada con éxito.',
        });
        dispatch({
          type: USER_DATA,
          payload: res.response.data,
        });
      } else {
        dispatch(userSorryError());
      }
    },
    function (err) {
      dispatch(userSorryError());
    }
  );
};

export const businessChangePage = (listView, currentPage) => (dispatch) => {
  let data = {
    listView,
    currentPage,
  };
  dispatch({
    type: USER_CHANGE_BUSINESS_PAGE,
    payload: data,
  });
};

export const businessViewCategory = (cod_category) => (dispatch, getState) => {
  const { list_business, item_per_page } = getState().userReducer;
  dispatch({
    type: USER_BUSINESS_CATEGORY_CLEAR,
  });
  let totalList = list_business.filter(
    (item) => item.activity_id === parseInt(cod_category)
  );
  let listView = totalList.slice(0, item_per_page);
  let data = {
    totalList,
    listView,
  };
  dispatch({
    type: USER_BUSINESS_CATEGORY,
    payload: data,
  });
};

export const businessCategoryChangePage = (data) => (dispatch) => {
  dispatch({
    type: USER_BUSINESS_CHANGE_PAGE_CATEGORY,
    payload: data,
  });
};

export const businessCategoryClear = () => (dispatch) => {
  dispatch({
    type: USER_BUSINESS_CATEGORY_CLEAR,
  });
};

export const productsType = (cod_type) => (dispatch, getState) => {
  const { list_products, item_per_page } = getState().userReducer;
  let list_total = list_products.filter(
    (item) => item.product_type_id === parseInt(cod_type)
  );
  let list_view = list_total.slice(0, item_per_page);
  dispatch({
    type: USER_LIST_TYPE_PRODUCTS,
    payload: { list_total, list_view },
  });
};

export const filterPriceProduct = (data) => (dispatch) => {
  dispatch({
    type: USER_FILTER_PRICE,
    payload: data,
  });
};

export const productsTypeCategory =
  (cod_type, cod_category) => (dispatch, getState) => {
    const { list_products, item_per_page } = getState().userReducer;
    let list_total = list_products.filter(
      (item) =>
        item.product_type_id === parseInt(cod_type) &&
        item.array_categories.indexOf(parseInt(cod_category)) >= 0
    );
    let list_view = list_total.slice(0, item_per_page);
    dispatch({
      type: USER_LIST_TYPE_PRODUCTS,
      payload: { list_total, list_view },
    });
  };

export const productChangePage = (data) => (dispatch) => {
  dispatch({
    type: USER_CHANGE_PAGE_PRODUCTS,
    payload: data,
  });
};

export const productSearch = (data) => (dispatch) => {
  dispatch({
    type: USER_PRODUCT_SEARCH,
    payload: data,
  });
};

export const changeSearch = (value) => (dispatch) => {
  dispatch({
    type: USER_PRODUCT_CHANGE_SEARCH,
    payload: value,
  });
};

export const businessSearch = (data) => (dispatch) => {
  dispatch({
    type: USER_BUSINESS_SEARCH,
    payload: data,
  });
};

export const businessCategorySearch = (data) => (dispatch) => {
  dispatch({
    type: USER_BUSINESS_CATEGORY_SEARCH,
    payload: data,
  });
};

export const businessChangeSearch = (value) => (dispatch) => {
  dispatch({
    type: USER_BUSINESS_CHANGE_SEARCH,
    payload: value,
  });
};

export const productClear = () => (dispatch) => {
  dispatch({
    type: USER_CLEAR_TYPE_PRODUCTS,
  });
};

export const userChangeError = (payload) => (dispatch) => {
  dispatch({
    type: USER_ERROR,
    payload,
  });
};

export const userSorryError = () => (dispatch, getState) => {
  const {
    site_texts: { text_sorry },
  } = getState().siteReducer;
  dispatch(
    userChangeError(
      text_sorry ? text_sorry : 'Lo sentimos, vuelve a intentarlo más tarde.'
    )
  );
};

export const changeUsername = (value) => (dispatch, getState) => {
  const {
    site_texts: {
      text_blanks_characters,
      text_user_valid,
      text_username_not_available,
      text_username_error,
      text_username_characters,
    },
  } = getState().siteReducer;
  dispatch({
    type: USER_USERNAME,
    payload: value,
  });
  if (value && value.length >= 4) {
    let expression = /^[a-zA-Z0-9\-_.]*$/;
    if (!expression.test(value)) {
      dispatch({
        type: USER_USERNAME_ERROR,
        payload: text_blanks_characters
          ? text_blanks_characters
          : 'Tiene espacios en blanco o caracteres especiales.',
      });
      return;
    }
    dispatch({
      type: USER_USERNAME_ERROR,
      payload: '',
    });
    dispatch({
      type: USER_USERNAME_VALIDATE,
      payload: true,
    });
    Api.callMethod(
      'v1/users/username/validate',
      'POST',
      {
        username: value,
      },
      function (res) {
        if (res.status === 200) {
          dispatch({
            type: USER_USERNAME_VALIDATE,
            payload: false,
          });
          let response = res.response.is_posible;
          if (response) {
            dispatch({
              type: USER_USERNAME_SUCCESS,
              payload: text_user_valid
                ? text_user_valid
                : 'El usuario es válido.',
            });
          } else {
            dispatch({
              type: USER_USERNAME_ERROR,
              payload: text_username_not_available
                ? text_username_not_available
                : 'Nombre de usuario no disponible.',
            });
          }
        } else {
          dispatch({
            type: USER_USERNAME_ERROR,
            payload: text_username_error
              ? text_username_error
              : 'Hubo un error al validar el usuario.',
          });
        }
      },
      function (err) {
        dispatch({
          type: USER_USERNAME_ERROR,
          payload: text_username_error
            ? text_username_error
            : 'Hubo un error al validar el usuario.',
        });
      }
    );
  } else if (value && value.length > 0 && value.length <= 4) {
    dispatch({
      type: USER_USERNAME_ERROR,
      payload: text_username_characters
        ? text_username_characters
        : 'El nombre de usuario debe tener más de 4 caracteres.',
    });
  } else {
    dispatch({
      type: USER_USERNAME_ERROR,
      payload: '',
    });
  }
};

export const savePassword = (data) => (dispatch, getState) => {
  const {
    site_texts: {
      text_change_password,
      text_changing_password,
      text_congratulations,
      text_success_password,
    },
  } = getState().siteReducer;

  dispatch({
    type: USER_LOADING,
    payload: text_changing_password
      ? text_changing_password
      : 'Cambiando contraseña...',
  });
  Api.callMethod(
    'v1/users/change-password',
    'POST',
    data,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: USER_CLOSE_LOADING,
        });
        Swal.fire({
          title: text_congratulations
            ? text_congratulations
            : 'Felicitaciones!',
          text: text_success_password
            ? text_success_password
            : 'La contraseña fue cambiada con éxito.',
          type: 'success',
          confirmButtonColor: '#50318b',
        }).then(() => {
          dispatch(changePasswordRepeat(''));
          dispatch(changePasswordNew(''));
          dispatch(changePassword(''));
        });
      } else {
        dispatch(
          userChangeError(
            text_change_password
              ? text_change_password
              : 'Hubo un error al cambiar la contraseña.'
          )
        );
      }
    },
    function (err) {
      dispatch(
        userChangeError(
          text_change_password
            ? text_change_password
            : 'Hubo un error al cambiar la contraseña.'
        )
      );
    }
  );
};

export const changeFilterPrice = (value) => (dispatch) => {
  dispatch({
    type: USER_CHANGE_FILTER_PRICE,
    payload: value,
  });
};

export const userFavorite = (cod_product) => (dispatch) => {
  Api.callMethod(
    `v1/product-favorites`,
    'POST',
    {
      product_id: cod_product,
    },
    function (res) {
      if (res.status === 201) {
        dispatch({
          type: USER_DATA,
          payload: res.response.data,
        });
      }
    },
    function (err) {}
  );
};

export const sendEmailVerified = () => (dispatch, getState) => {
  const {
    site_texts: {
      text_sending_email,
      text_congratulations,
      text_message_email,
    },
  } = getState().siteReducer;
  dispatch({
    type: USER_LOADING,
    payload: text_sending_email ? text_sending_email : 'Enviando correo...',
  });

  Api.callMethod(
    `v1/users/send-verified`,
    'POST',
    {},
    function (res) {
      Swal.fire({
        title: text_congratulations ? text_congratulations : 'Felicitaciones!',
        text: text_message_email
          ? text_message_email
          : 'El correo ha sido enviado satisfactoriamente.',
        type: 'success',
        confirmButtonColor: '#50318b',
      }).then(() => {
        dispatch({
          type: USER_LOADING,
          payload: '',
        });
      });
    },
    function (err) {}
  );
};

export const verified = () => (dispatch, getState) => {
  const {
    site_texts: {
      text_validation_error,
      text_congratulations,
      text_validation_congratulations,
    },
  } = getState().siteReducer;

  Api.callMethod(
    `v1/users/verified`,
    'POST',
    {},
    function (res) {
      if (res.status !== 200) {
        Swal.fire({
          title: 'Upsss!!!',
          text: text_validation_error
            ? text_validation_error
            : 'Hubo un error al validar el correo.',
          type: 'warning',
          confirmButtonColor: '#50318b',
        }).then(() => {
          dispatch({
            type: USER_VERIFIED,
            payload: true,
          });
        });
      } else {
        Swal.fire({
          title: text_congratulations
            ? text_congratulations
            : 'Felicitaciones!',
          text: text_validation_congratulations
            ? text_validation_congratulations
            : 'El correo ha sido validado con éxito.',
          type: 'success',
          confirmButtonColor: '#50318b',
        }).then(() => {
          dispatch({
            type: USER_DATA,
            payload: res.response.data,
          });
          dispatch({
            type: USER_VERIFIED,
            payload: true,
          });
        });
      }
    },
    function (err) {}
  );
};

export const addUserCompanies = () => (dispatch, getState) => {
  const { user_companies } = getState().userReducer;

  user_companies.push({
    name: '',
    address: '',
    website: '',
    contact_name: '',
    contact_phone: '',
    contact_email: '',
  });

  dispatch(changeUserCompanies(user_companies));
};

export const removeUserCompanies = (index) => (dispatch, getState) => {
  let { user_companies } = getState().userReducer;
  const id = user_companies[index].id;
  if (id) {
    Api.callMethod(
      `v1/recommended-companies/${id}`,
      'DELETE',
      {},
      function (res) {
        console.log(res.response.message);
      },
      function (err) {
        console.log('Error removeUserCompanies: ', err);
      }
    );
  }

  user_companies.splice(index, 1);

  dispatch(changeUserCompanies(user_companies));
};

export const changeUserCompanies = (payload) => (dispatch) => {
  dispatch({
    type: USER_COMPANIES,
    payload,
  });
};

export const changeUserCompanyName = (text, index) => (dispatch, getState) => {
  const { user_companies } = getState().userReducer;
  const userData = user_companies[index];

  userData.name = text;

  user_companies[index] = userData;

  dispatch(changeUserCompanies(user_companies));
};

export const changeUserCompanyAddress =
  (text, index) => (dispatch, getState) => {
    const { user_companies } = getState().userReducer;
    const userData = user_companies[index];

    userData.address = text;

    user_companies[index] = userData;

    dispatch(changeUserCompanies(user_companies));
  };

export const changeUserCompanyWebsite =
  (text, index) => (dispatch, getState) => {
    const { user_companies } = getState().userReducer;
    const userData = user_companies[index];

    userData.website = text;

    user_companies[index] = userData;

    dispatch(changeUserCompanies(user_companies));
  };

export const changeUserCompanyContactName =
  (text, index) => (dispatch, getState) => {
    const { user_companies } = getState().userReducer;
    const userData = user_companies[index];

    userData.contact_name = text;

    user_companies[index] = userData;

    dispatch(changeUserCompanies(user_companies));
  };

export const changeUserCompanyContactEmail =
  (text, index) => (dispatch, getState) => {
    const { user_companies } = getState().userReducer;
    const userData = user_companies[index];

    userData.contact_email = text;

    user_companies[index] = userData;

    dispatch(changeUserCompanies(user_companies));
  };

export const changeUserCompanyContactPhone =
  (text, index) => (dispatch, getState) => {
    const { user_companies } = getState().userReducer;
    const userData = user_companies[index];

    userData.contact_phone = text;

    user_companies[index] = userData;

    dispatch(changeUserCompanies(user_companies));
  };

export const saveRecommendedCompanies = () => (dispatch, getState) => {
  const { user_companies, user_data } = getState().userReducer;
  const {
    site_texts: { text_requesting_activation },
  } = getState().siteReducer;

  if (!user_data.send_request) {
    dispatch({
      type: USER_LOADING,
      payload: text_requesting_activation
        ? text_requesting_activation
        : 'Solicitando activación...',
    });
  } else {
    dispatch({
      type: USER_LOADING,
      payload: 'Actualizando...',
    });
  }

  Api.callMethod(
    'v1/recommended-companies',
    'POST',
    {
      companies: user_companies,
      risk_centers: true,
      valid_information: true,
    },
    function (res) {
      if (res.status === 201) {
        if (!user_data.send_request) {
          dispatch(userReqActivation());
        } else {
          dispatch(changeUserCompanies(res.response.data));
          dispatch({
            type: USER_LOADING,
            payload: '',
          });
        }
      } else {
        dispatch(userSorryError());
      }
    },
    function (err) {
      console.log('Error saveRecommendedCompanies: ', err);
      dispatch(userSorryError());
    }
  );
};

export const getRecommendedCompanies = () => (dispatch) => {
  Api.callMethod(
    'v1/recommended-companies',
    'GET',
    null,
    function (res) {
      if (res.status === 200) {
        dispatch(changeUserCompanies(res.response.data));
      }
    },
    function (err) {
      console.log('Error getRecommendeCompanies: ', err);
    }
  );
};

export const userFavoriteCompany = (company_id) => (dispatch, getState) => {
  const { user_data } = getState().userReducer;
  Api.callMethod(
    `v1/favorite-companies`,
    'POST',
    {
      company_id,
    },
    function (res) {
      if (res.status === 200) {
        user_data.favorite_companies = res.response.data;
        dispatch({
          type: USER_DATA,
          payload: user_data,
        });
        dispatch({
          type: USER_FAVORITE_COMPANIES,
          payload: res.response.data,
        });
      }
    },
    function (err) {}
  );
};

const showError = (message) => (dispatch) => {
  Swal.fire({
    title: 'Upsss!!!',
    text: message,
    type: 'warning',
    confirmButtonColor: '#50318B',
    onClose: () => dispatch(userClearError()),
  });
};

export const requestAprovation = () => (dispatch, getState) => {
  const { user_data, user_risk_centers, user_valid_information } =
    getState().userReducer;
  const {
    site_texts: {
      text_form_validate,
      text_already_request,
      text_risk_centers_error,
      text_valid_information_error,
    },
  } = getState().siteReducer;

  if (!user_data.send_request) {
    let { docs } = user_data;

    if (
      !docs ||
      user_data.profession === null ||
      user_data.doc_expedition_date === null ||
      docs.business_name === null ||
      docs.nit_number === null ||
      docs.phone === null ||
      docs.cod_category === null ||
      docs.average_sales === null ||
      docs.description === null ||
      docs.department_id === null ||
      docs.city_id === null ||
      docs.address === null ||
      docs.rut_scann === null ||
      docs.doc_scan_front === null ||
      docs.doc_scan_back === null ||
      user_data.type_person === null
    ) {
      dispatch(
        showError(
          text_form_validate
            ? text_form_validate
            : 'Válida que cada uno de los puntos del formulario estén debidamente diligenciados.'
        )
      );
      return;
    }
    if (!user_risk_centers) {
      dispatch(
        showError(
          text_risk_centers_error
            ? text_risk_centers_error
            : 'Debe autorizar la consulta en centrales de riesgo.'
        )
      );
      return;
    }
    if (!user_valid_information) {
      dispatch(
        showError(
          text_valid_information_error
            ? text_valid_information_error
            : 'Debes certificar que la información es veraz.'
        )
      );
      return;
    }
    dispatch(saveRecommendedCompanies());
  } else {
    dispatch(
      showError(
        text_already_request
          ? text_already_request
          : 'Ya enviaste la solicitud de aprobación.'
      )
    );
  }
};

export const changeUserSocialNetworks = (payload) => (dispatch) => {
  dispatch({
    type: USER_SOCIAL_NETWORKS,
    payload,
  });
};

export const influencerRequestAprovation = () => (dispatch, getState) => {
  const {
    user_data,
    user_social_networks,
    doc_type,
    doc_number,
    doc_expedition_date,
    rut_scann,
    user_valid_information,
    user_risk_centers,
    doc_scan_back,
    doc_scan_front,
  } = getState().userReducer;
  const { tags_user } = getState().tagsReducer;
  const { targets_user } = getState().targetReducer;
  const {
    site_texts: {
      text_form_validate,
      text_already_request,
      text_risk_centers_error,
      text_valid_information_error,
    },
  } = getState().siteReducer;

  if (!user_data.send_request) {
    if (
      !user_social_networks.length ||
      doc_type === '' ||
      doc_number === '' ||
      doc_expedition_date === '' ||
      rut_scann === '' ||
      doc_scan_back === '' ||
      doc_scan_front === '' ||
      !tags_user.length ||
      !targets_user.length ||
      user_data.image === ''
    ) {
      dispatch(
        showError(
          text_form_validate
            ? text_form_validate
            : 'Válida que cada uno de los puntos del formulario estén debidamente diligenciados.'
        )
      );
      return;
    }
    if (!user_risk_centers) {
      dispatch(
        showError(
          text_risk_centers_error
            ? text_risk_centers_error
            : 'Debe autorizar la consulta en centrales de riesgo.'
        )
      );
      return;
    }
    if (!user_valid_information) {
      dispatch(
        showError(
          text_valid_information_error
            ? text_valid_information_error
            : 'Debes certificar que la información es veraz.'
        )
      );
      return;
    }
    dispatch(saveRecommendedCompanies());
  } else {
    dispatch(
      showError(
        text_already_request || 'Ya enviaste la solicitud de aprobación.'
      )
    );
  }
};

export const changePrincipalNetwork = (payload) => (dispatch) => {
  dispatch({
    type: USER_PRINCIPAL_NETWORK,
    payload,
  });
};

export const changeBirthdate = (payload) => (dispatch, getState) => {
  const { user_birthdate } = getState().userReducer;
  payload = changeDateFormat(user_birthdate, payload);

  dispatch({
    type: USER_BIRTHDATE,
    payload,
  });
};

export const savePrincipalNetwork = () => (dispatch, getState) => {
  const { user_principal_network, user_data } = getState().userReducer;
  const {
    site_texts: { text_select_principal_network },
  } = getState().siteReducer;

  Api.callMethod(
    `v1/users/${user_data.id}/social-networks/${user_principal_network}/principal`,
    'POST',
    {},
    (res) => {
      if (res.status !== 200) {
        Swal.fire({
          title: 'Upsss!!!',
          text:
            text_select_principal_network ||
            'Hubo un error al guardar la red social principal.',
          type: 'warning',
          confirmButtonColor: '#50318b',
        });
      }
    },
    (error) => {
      console.log('Error savePrincipalNetwork', error);
      Swal.fire({
        title: 'Upsss!!!',
        text:
          text_select_principal_network ||
          'Hubo un error al guardar la red social principal.',
        type: 'warning',
        confirmButtonColor: '#50318b',
      });
    }
  );
};
