import React, { Component } from "react";
import SlideCategory from "./SlideCategory";
import "./css/SliderCategories.css";
import GeneralSlider from "./GeneralSlider";

class SliderCategory extends Component {
  renderCategories = () => {
    const { data, language } = this.props;
    let items_per_page = 2;
    let pages = Math.ceil(data.length / items_per_page);
    let width_page = pages * 180;
    let render_categories = (
      <div className="SliderCategories_row" style={{ width: width_page }}>
        {data.map((item) => {
          return (
            <SlideCategory data={item} key={item.id} language={language} />
          );
        })}
      </div>
    );
    return render_categories;
  };
  render() {
    return (
      <div className="SliderCategories_container">
        <div className="sliderCategories_desktop">
          <GeneralSlider
            data={this.props.data}
            slide={SlideCategory}
            viewItems={4}
          />
        </div>
        <div className="sliderCategories_mobile">{this.renderCategories()}</div>
      </div>
    );
  }
}

export default SliderCategory;
