import React from 'react';
import ManagerLayout from '../layouts/ManagerLayout';
import CampaignDetailView from '../views/CampaignDetailView/CampaignDetailView';

const CampaignDetail = () => {
  return (
    <ManagerLayout>
      <CampaignDetailView />
    </ManagerLayout>
  );
};

export default CampaignDetail;
