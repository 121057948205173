import React from 'react';
import { connect } from 'react-redux';
import { changeAdResourceId } from '../../../../functionality/actions/adActions';

function SelectResource(props) {
  const {
    productReducer: { product_my_list },
    adReducer: { ad_space_id, ad_category_id, ad_resource_id },
    siteReducer: {
      site_texts: { text_select },
    },
    changeAdResourceId,
  } = props;

  let options = product_my_list;

  if (ad_space_id.id === 1 || ad_space_id.id === 2) {
    options = product_my_list.filter(
      (product) => product.product_type_id === 1
    );
  }
  if (ad_space_id.id === 3 || ad_space_id.id === 4) {
    options = product_my_list.filter(
      (product) => product.product_type_id === 2
    );
  }
  if (ad_space_id.id === 2 || ad_space_id.id === 4) {
    options = options.filter(
      (option) => option.categories[0] === parseInt(ad_category_id)
    );
  }

  return (
    <>
      <div>
        {text_select ? text_select : 'Seleccionar'}{' '}
        {ad_space_id.id === 1 || ad_space_id.id === 2
          ? 'producto'
          : ad_space_id.id === 3 || ad_space_id.id === 4
          ? 'servicio'
          : 'producto o servicio'}
        :
      </div>
      <select
        className="form-control"
        value={ad_resource_id}
        onChange={(input) => changeAdResourceId(input.target.value)}
      >
        <option>-- {text_select ? text_select : 'Seleccionar'} --</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </>
  );
}

const mapStateToProps = ({ adReducer, productReducer, siteReducer }) => {
  return {
    adReducer,
    productReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  changeAdResourceId,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectResource);
