import React, { Component } from "react";
import { connect } from "react-redux";
import {
  adClear,
  getAdSettings,
} from "../../../functionality/actions/adActions";
import ManagerLayout from "./layouts/ManagerLayout";
import AdFormView from "./views/AdFormView";

class AdFormPage extends Component {
  componentDidMount() {
    const {
      adReducer: { ad_settings_loading },
      getAdSettings,
    } = this.props;

    if (ad_settings_loading) {
      getAdSettings();
    }
  }

  componentWillUnmount() {
    this.props.adClear();
  }

  render() {
    const {
      match: { params },
    } = this.props;

    return (
      <ManagerLayout>
        <AdFormView id={params.id} />
      </ManagerLayout>
    );
  }
}

const mapStateToProps = ({ adReducer }) => {
  return {
    adReducer,
  };
};

const mapDispatchToProps = {
  adClear,
  getAdSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdFormPage);
