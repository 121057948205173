import Api from '../Api';
import { OBJECTIVES, OBJECTIVES_LOADING } from '../types/objectivesTypes';

export const setObjectives = (value) => (dispatch) => {
  dispatch({
    type: OBJECTIVES,
    payload: value,
  });
};

export const setObjectivesLoading = (value) => (dispatch) => {
  dispatch({
    type: OBJECTIVES_LOADING,
    payload: value,
  });
};

export const getObjectives = () => async (dispatch) => {
  dispatch(setObjectivesLoading(true));
  try {
    const response = await Api.asyncCallMethod('v1/objectives', 'GET');
    dispatch(setObjectives(response.response.data));
    dispatch(setObjectivesLoading(false));
  } catch (error) {
    dispatch(setObjectivesLoading(false));
    console.log('error in getObjectives', error);
  }
};
