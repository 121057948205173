import React from 'react';
import { withRouter } from 'react-router-dom';
import './Paginator.css';

const Paginator = (props) => {
  const {
    totalPages,
    currentPage,
    history,
    location: { search },
    match: { url },
  } = props;

  const changePage = (page) => {
    let route = `${url}?page=${page}`;
    if (search !== '') {
      const params = search.split('&');
      params.forEach((item) => {
        if (item.indexOf('?page') < 0) {
          const newItem = item.replace('?', '');
          route = `${route}&${newItem}`;
        }
      });
    }

    history.push(route);
  };

  const renderButtons = () => {
    let html = [];
    let initPage = currentPage > 2 ? currentPage - 2 : 1;
    let maxPage = currentPage < totalPages - 2 ? currentPage + 2 : totalPages;

    for (let i = initPage; i <= maxPage; i++) {
      let isActive = currentPage === i ? 'active' : '';
      let button = (
        <button key={i} className={isActive} onClick={() => changePage(i)}>
          {i}
        </button>
      );
      html.push(button);
    }

    return html;
  };

  const nextPage = () => {
    changePage(currentPage + 1);
  };

  const prevPage = () => {
    changePage(currentPage - 1);
  };

  return (
    <div className="paginator">
      {currentPage > 1 && (
        <button className="previus__button" onClick={prevPage}>
          <i className="fa-solid fa-angles-left"></i>
          {' previus'}
        </button>
      )}
      <div className="pages">
        {currentPage > 3 && totalPages > 3 && (
          <button onClick={() => changePage(1)}>1</button>
        )}
        {currentPage > 4 && <button>...</button>}
        {renderButtons()}
        {totalPages > 2 && currentPage < totalPages - 3 && <button>...</button>}
        {totalPages > 2 && currentPage < totalPages - 2 && (
          <button onClick={() => changePage(totalPages)}>{totalPages}</button>
        )}
      </div>
      {currentPage < totalPages && (
        <button className="next__button" onClick={nextPage}>
          {'Next'} <i className="fa-solid fa-angles-right"></i>
        </button>
      )}
    </div>
  );
};

export default withRouter(Paginator);
