import React, { Component } from "react";
import MarketplaceLayout from "./layouts/MarketplaceLayout";
import Error from "../../../components/all/Error";

class Page404 extends Component {
  render() {
    return (
      <MarketplaceLayout>
        <Error type="404" buttonName="Ir al Inicio" link="/home" />
      </MarketplaceLayout>
    );
  }
}

export default Page404;
