import React, { Component } from "react";
import { connect } from "react-redux";
import ManagerLayout from "./layouts/ManagerLayout";
import DashboardView from "./views/DashboardView";

import * as dashboardActions from "../../../functionality/actions/dashboardActions";
import TopSpinner from "../../../components/all/TopSpinner";

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataOrders: [
        {
          id: 1,
          description: "Mi 1 transacción",
          value: "100000",
          updated_on: "01/06/19",
          cod_status: "1",
          image: "",
        },
        {
          id: 2,
          description: "Mi 2 transacción",
          value: "200000",
          updated_on: "02/06/19",
          cod_status: "1",
          image: "",
        },
        {
          id: 3,
          description: "Mi 3 transacción",
          value: "300000",
          updated_on: "03/06/19",
          cod_status: "2",
          image: "",
        },
        {
          id: 4,
          description: "Mi 4 transacción",
          value: "400000",
          updated_on: "04/06/19",
          cod_status: "3",
          image: "",
        },
        {
          id: 5,
          description: "Mi 5 transacción",
          value: "500000",
          updated_on: "05/06/19",
          cod_status: "4",
          image: "",
        },
      ],
      dataChats: [
        {
          id: 1,
          name: "Nombre Usuario 1",
          message: "Mi mensaje 1",
          image: "",
        },
        {
          id: 2,
          name: "Nombre Usuario 2",
          message: "Mi mensaje 2",
          image: "",
        },
        {
          id: 3,
          name: "Nombre Usuario 3",
          message: "Mi mensaje 3",
          image: "",
        },
        {
          id: 4,
          name: "Nombre Usuario 4",
          message: "Mi mensaje 4",
          image: "",
        },
        {
          id: 5,
          name: "Nombre Usuario 5",
          message: "Mi mensaje 5",
          image: "",
        },
      ],
      actionsOrders: false,
      actionsChats: false,
    };
  }

  componentDidMount() {
    const { dataDashboard } = this.props;
    dataDashboard();
  }

  componentWillUnmount() {
    const { clearDashboard } = this.props;
    clearDashboard();
  }

  render() {
    const {
      dashboardReducer: { dashboard_graph_bar, dashboard_loading },
      userReducer: { loading_init },
    } = this.props;
    return (
      <ManagerLayout>
        <div className="title-manager">
          <div>
            <span className="icon-dashboard" />
            Dashboard
          </div>
        </div>
        {loading_init && dashboard_loading ? (
          <TopSpinner />
        ) : (
          <DashboardView
            dataChartBar={dashboard_graph_bar}
            dataOrders={this.state.dataOrders}
            dataChats={this.state.dataChats}
            actionsOrders={this.state.actionsOrders}
            actionsChats={this.state.actionsChats}
          />
        )}
      </ManagerLayout>
    );
  }
}

const mapStateToProps = ({ dashboardReducer, userReducer }) => {
  return {
    dashboardReducer,
    userReducer,
  };
};

export default connect(mapStateToProps, dashboardActions)(DashboardPage);
