import React, { Component } from "react";
import { connect } from "react-redux";
import MarketPlaceLayout from "./layouts/MarketplaceLayout";
import UserView from "./views/UserView";
import TopSpinner from "../../../components/all/TopSpinner";

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusSearch: false,
    };
  }

  openCloseSearch = (statusSearch) => {
    this.setState({
      statusSearch,
    });
  };

  render() {
    const { loading_init } = this.props;
    return (
      <MarketPlaceLayout
        statusSearch={this.state.statusSearch}
        openCloseSearch={this.openCloseSearch}
      >
        {loading_init ? (
          <TopSpinner />
        ) : (
          <UserView username={this.props.match.params.username} />
        )}
      </MarketPlaceLayout>
    );
  }
}

const mapStateToProps = ({ userReducer }) => userReducer;

export default connect(mapStateToProps)(UserPage);
