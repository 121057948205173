import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ItemSlider from "./ItemSlider";
import Slider from "react-slick";
import "./css/HomeSlider.css";

class HomeSlider extends Component {
  next = () => {
    this.slider.slickNext();
  };
  previous = () => {
    this.slider.slickPrev();
  };
  render() {
    const {
      userReducer: { slider, loading_init },
    } = this.props;
    // console.log(slider, loading_init);
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    // if (!loading_init && slider.length === 0) {
    // 	return <div></div>;
    // }
    return (
      <Fragment>
        {!loading_init && slider.length > 0 && (
          <div className="homeSlider_container">
            {slider.length > 1 && (
              <Fragment>
                <button
                  onClick={this.previous}
                  className="d-flex homeSlider_arrow homeSlider_arrow-left"
                >
                  <span className="icon-prev" />
                </button>
                <button
                  onClick={this.next}
                  className="d-flex homeSlider_arrow homeSlider_arrow-right"
                >
                  <span className="icon-next" />
                </button>
              </Fragment>
            )}
            <Slider {...settings} ref={(c) => (this.slider = c)}>
              {slider.map((item) => {
                return <ItemSlider key={item.id} data={item} />;
              })}
            </Slider>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

export default connect(mapStateToProps)(HomeSlider);
