import React from 'react';
import './Porposal.css';
import Gallery from '../Gallery/Gallery';
import Spinner from '../Spinner';
import { connect } from 'react-redux';

const Porposal = (props) => {
  const {
    siteReducer: {
      site_texts: {
        text_brand,
        text_networks,
        text_cost,
        text_suggested_images,
        text_deliverables,
      },
    },
    loading,
    data,
  } = props;

  if (!data) return null;

  const formattedText = data.deliverables
    ? data.deliverables.replace(/\n/g, '<br />')
    : '';
  return (
    <>
      {loading ? (
        <div className="spinner-loading">
          <Spinner type="purple" />
        </div>
      ) : (
        <div className="porposal">
          <h2 className="porposal__title">{data.name}</h2>
          {/* data */}
          <div className="porposal__data">
            <div className="data__field">
              <h4 className="porposal__subtitle">
                {text_brand ? text_brand : 'Marca'}
              </h4>
              <span className="data__text">
                {data.brand.name ? data.brand.name : 'nombre de la marca'}
              </span>
            </div>
            <div className="data__field">
              <h4 className="porposal__subtitle">
                {text_networks ? text_networks : 'Redes'}
              </h4>
              <div className="networks__porposal">
                {data.social_networks &&
                  data.social_networks.map((network) => (
                    <div
                      className={`network__circle circle-${network.slug}`}
                      key={network.id}
                    >
                      <i className={`${network.icon}`}></i>
                    </div>
                  ))}
              </div>
            </div>
            <div className="data__field">
              <h4 className="porposal__subtitle">
                {text_cost ? text_cost : 'Coste'}
              </h4>
              <span className="data__text">{`BAX$ ${data.price}`}</span>
            </div>
          </div>
          {/* images */}
          {data.attachments.length !== 0 && (
            <div className="">
              <h4 className="porposal__subtitle">
                {text_suggested_images
                  ? text_suggested_images
                  : 'Imágenes sugeridas'}
              </h4>
              <Gallery images={data.attachments} />
            </div>
          )}
          {/* deliverables */}
          <div className="detail__purpose">
            <h4 className="porposal__subtitle">
              {text_deliverables ? text_deliverables : 'Entregables'}
            </h4>
            <p
              className="porposal__text"
              dangerouslySetInnerHTML={{ __html: formattedText }}
            ></p>
          </div>
          {/* video */}
          {data.video && (
            <div className="detail__purpose">
              <h4 className="porposal__subtitle">sptich</h4>
              <video src={data.video} controls />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(Porposal);
