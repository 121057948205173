import React, { useState, useEffect } from 'react';
import MultiRangeSlider from 'multi-range-slider-react';
import { formatNumber } from '../../../utils/formatNumber';

import './MultiRange.css';

const MultiRange = (props) => {
  const {
    min,
    max,
    title,
    more,
    setMinInput,
    setMaxInput,
    defaultMin,
    defaultMax,
  } = props;

  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);
  const [minOnChangeValue, setMinOnChangeValue] = useState(defaultMin ?? min);
  const [maxOnChangeValue, setMaxOnChangeValue] = useState(defaultMax ?? max);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setMinValue(min);
      setMaxValue(max);
    }

    return () => {
      isMounted = false;
    };
  }, [min, max]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setMinInput(minOnChangeValue);
      setMaxInput(maxOnChangeValue);
    }

    return () => {
      isMounted = false;
    };
  }, [minOnChangeValue, maxOnChangeValue, setMaxInput, setMinInput]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setMinValue(minOnChangeValue);
      setMaxValue(maxOnChangeValue);
    }

    return () => {
      isMounted = false;
    };
  }, [minOnChangeValue, maxOnChangeValue]);

  const handleSliderChange = (e) => {
    setMinOnChangeValue(e.minValue);
    setMaxOnChangeValue(e.maxValue);
  };

  return (
    <>
      <div className="values">
        <span className="title">{title}</span>
        <span className="values__minmax">{`${formatNumber(
          minValue
        )} -  ${formatNumber(maxValue)} ${more ? '+' : ''}`}</span>
      </div>
      <div className="slider">
        <MultiRangeSlider
          className="range"
          min={min}
          max={max}
          minValue={minOnChangeValue}
          maxValue={maxOnChangeValue}
          onInput={(e) => {
            setMinValue(e.minValue);
            setMaxValue(e.maxValue);
          }}
          onChange={(e) => {
            handleSliderChange(e);
            setMinValue(e.minValue);
            setMaxValue(e.maxValue);
          }}
          label={false}
          ruler={false}
          step={min >= 100 ? 1000 : 0}
          stepOnly={minValue > 100}
          style={{ border: 'none', boxShadow: 'none' }}
          barLeftColor="#bdc1c5"
          barInnerColor="#DB308B"
          barRightColor="#bdc1c5"
          thumbLeftColor="#DB308B"
          thumbRightColor="#DB308B"
        />
      </div>
    </>
  );
};

MultiRange.defaultProps = {
  more: false,
};

export default MultiRange;
