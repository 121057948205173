import React from "react";
import { connect } from "react-redux";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

function SocialNetwork(props) {
  const {
    siteReducer: {
      site_texts: { text_share_on },
    },
    url,
    title,
    description,
    hashtags,
    facebook,
    linkedin,
    twitter,
    whatsapp,
    email,
    showTitle,
    className,
  } = props;

  return (
    <>
      {showTitle && (
        <div className="productView_social-title">
          {text_share_on ? text_share_on : "Compartir en"}
        </div>
      )}
      <div className={className}>
        {facebook && (
          <FacebookShareButton
            url={url}
            quote={`${title}\n${description}`}
            windowWidth={600}
            windowHeight={400}
          >
            <FacebookIcon size={36} round={true} />
          </FacebookShareButton>
        )}
        {linkedin && (
          <LinkedinShareButton
            title={title}
            summary={description}
            url={url}
            windowWidth={600}
            windowHeight={400}
          >
            <LinkedinIcon size={36} round={true} />
          </LinkedinShareButton>
        )}
        {twitter && (
          <TwitterShareButton
            title={`${title}; ${description}`}
            hashtags={hashtags}
            url={url}
            windowWidth={600}
            windowHeight={400}
          >
            <TwitterIcon size={36} round={true} />
          </TwitterShareButton>
        )}
        {whatsapp && (
          <WhatsappShareButton
            title={`${title}; ${description}`}
            url={url}
            windowWidth={600}
            windowHeight={400}
          >
            <WhatsappIcon size={36} round={true} />
          </WhatsappShareButton>
        )}
        {email && (
          <EmailShareButton
            subject={title}
            body={description}
            separator={url}
            windowWidth={600}
            windowHeight={400}
          >
            <EmailIcon size={36} round={true} />
          </EmailShareButton>
        )}
      </div>
    </>
  );
}

SocialNetwork.defaultProps = {
  facebook: true,
  linkedin: true,
  twitter: true,
  whatsapp: true,
  email: false,
  showTitle: true,
  className: "productView_separate",
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(SocialNetwork);
