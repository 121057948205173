import React, { Component } from "react";
import { connect } from "react-redux";
import MarketplaceLayout from "./layouts/MarketplaceLayout";
import BoxWhite from "../../../components/user/marketplace/BoxWhite";
import TopSpinner from "../../../components/all/TopSpinner";
import SearchView from "./views/SearchView";

import * as searchActions from "../../../functionality/actions/searchActions";

const { searchClear } = searchActions;

class SearchPage extends Component {
  componentDidMount() {
    const { searchClear } = this.props;
    searchClear();
  }

  render() {
    const {
      userReducer: { loading_init },
      siteReducer: {
        site_texts: { text_search_results },
      },
    } = this.props;
    return (
      <MarketplaceLayout>
        <div className="container shoppingCartView_container">
          <BoxWhite
            title={
              text_search_results
                ? text_search_results.toUpperCase()
                : "RESULTADOS DE LA BUSQUEDA"
            }
          >
            {loading_init ? (
              <TopSpinner />
            ) : (
              <SearchView search={this.props.match.params.type} />
            )}
          </BoxWhite>
        </div>
      </MarketplaceLayout>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  searchClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
