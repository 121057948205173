import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import "./css/PaymentMethodView.css";
import PaymentMethodList from "../../../../components/user/manager/PaymentMethodList";
import PaymentModal from "../../../../components/user/manager/PaymentModal";

import * as paymentActions from "../../../../functionality/actions/paymentActions";

class PaymentMethodsView extends Component {
  componentDidMount() {
    const {
      userReducer: {
        user_data: { payment_type_id },
      },
      changePaymentMethod,
    } = this.props;
    if (payment_type_id !== 0) {
      changePaymentMethod(payment_type_id);
    }
  }

  render() {
    const {
      paymentReducer: { payment_company },
      siteReducer: {
        site_texts: { text_commissions, text_secure_payments },
      },
    } = this.props;

    return (
      <Fragment>
        <PaymentModal />
        <div className="paymentMethodView_description">
          {text_commissions
            ? text_commissions
            : "Para poder cargar las comisiones  (10% + iva) de tus transacciones en BAX  es necesario tener un método de pago predeterminado o Comprar saldo prepago. NUNCA HAREMOS CARGOS EXTRAS. SÓLO CUANDO UNA TRANSACCIÓN SEA 100% EFECTIVA O SOLICITES CARGA DE SALDO PREPAGO."}
        </div>
        <PaymentMethodList />
        {payment_company && (
          <div className="paymentMethodView_footer d-flex justify-content-end align-items-center">
            <div className="paymentMethodView_footer-text">
              {text_secure_payments
                ? text_secure_payments
                : "Pagos seguros con"}
              <img
                src={payment_company.image}
                className="paymentMethodView_img"
                alt={payment_company.name}
              />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ paymentReducer, userReducer, siteReducer }) => {
  return {
    paymentReducer,
    userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, paymentActions)(PaymentMethodsView);
