import React, { Fragment, useEffect } from 'react';
import './UploadImage.css';
import Api from '../../../functionality/Api';
import { connect } from 'react-redux';

const UploadImage = (props) => {
  const {
    stateToUpdate,
    route,
    updateMethod,
    deleteMethod,
    porposal,
    siteReducer: {
      site_texts: { text_upload_images, text_upload_images_caption },
    },
  } = props;
  useEffect(() => {}, [stateToUpdate]);

  const handleImageChange = async (event) => {
    const images = event.target.files;
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const data = new FormData();
      data.append('image', image);
      if (porposal) {
        updateMethod(image);
      } else {
        const response = await Api.uploadCampaignFile(data, route);
        updateMethod(response.data);
      }
    }
  };

  const handleDeleteImage = (event, id) => {
    event.preventDefault();
    deleteMethod(id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    // console.log(event.dataTransfer.files);
    const images = event.dataTransfer.files;
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const data = new FormData();
      data.append('image', image);
      if (porposal) {
        updateMethod(image);
      } else {
        const response = await Api.uploadCampaignFile(data, route);
        updateMethod(response.data);
      }
    }
  };

  return (
    <div className="uploadImage">
      <h4 className="block__title">
        {text_upload_images ? text_upload_images : 'Subir imágenes'}
      </h4>
      <div className="upload__container">
        <input
          className="upload__input"
          id="upload__input"
          type="file"
          accept="image/*"
          multiple
          onChange={(event) => handleImageChange(event)}
        />
        <label
          htmlFor="upload__input"
          className="drag-drop"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <i className="fa-regular fa-images"></i>
          {text_upload_images_caption
            ? text_upload_images_caption
            : 'Sube un archivo o arrastra y suelta PNG, JPG, GIF'}
          <br />
          up to 10MB
        </label>
      </div>

      <div className="image__preview">
        {stateToUpdate &&
          stateToUpdate.map((image, index) => {
            const { id, image: imageUrl, preview, file } = image;
            const imageKey = id || index;
            const deleteHandler = (event) =>
              handleDeleteImage(event, id || index);

            return (
              <Fragment key={imageKey}>
                <img
                  className="preview"
                  src={porposal ? preview || file : imageUrl}
                  alt="Vista previa de imagen"
                />
                <button
                  className="preview__closeButton"
                  onClick={deleteHandler}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </Fragment>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(UploadImage);
