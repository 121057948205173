import React from 'react';
import Analytic from '../../../../../../components/all/Analytic/Analytic.jsx';
// import Audience from '../../../../../../components/all/Audience/Audience.jsx';
// import InfluencerMetricTable from '../../../../../../components/all/Metrics/InfluencerMetricTable/InfluencerMetricTable.jsx';
// import RecentPost from '../../../../../../components/all/RecentPost/RecentPost.jsx';
import './CampaignResume.css';
import { connect } from 'react-redux';
import PostCardData from '../../../../../../components/all/PostCardData/PostCardData.jsx';
import Error from '../../../../../../components/all/Error.js';

const CampaignResumeView = (props) => {
  const {
    campaignReducer: {
      campaign_statistics_influencers,
      campaign_statistics_proposals,
      campaign_statistics_posts,
      campaign_statistics_last_posts,
    },
    siteReducer: {
      site_texts: {
        text_recent_posts,
        text_accepted_influencers,
        text_total_influencers,
        text_pending_influencers,
        text_rejected_influencers,
        text_accepted_proposals,
        text_proposals_pending,
        text_rejected_proposals,
        text_finalized_proposals,
        text_total_proposals,
        text_accepted_posts,
        text_pending_posts,
        text_rejected_posts,
        text_published_posts,
        text_total_posts,
      },
    },
  } = props;
  // console.log(campaign_statistics_proposals);
  return (
    <>
      <div className="performance">
        <h2>Performance</h2>
        <div className="analytics__content">
          <Analytic
            icon={'fa-solid fa-user-check'}
            title={text_accepted_influencers || 'Influencers Aceptados'}
            value={campaign_statistics_influencers.accepted}
          />
          <div className="separator__vertical" />

          <Analytic
            icon={'fa-solid fa-user-group'}
            title={text_pending_influencers || 'Influencers Pendientes'}
            value={campaign_statistics_influencers.pending}
          />
          <div className="separator__vertical" />
          <Analytic
            icon={'fa-solid fa-users-slash'}
            title={text_rejected_influencers || 'Influencers Rechazados'}
            value={campaign_statistics_influencers.rejected}
          />
          <div className="separator__vertical" />

          <Analytic
            icon={'fa-solid fa-users'}
            title={text_total_influencers || 'Influencers Total'}
            value={campaign_statistics_influencers.total}
          />
        </div>
        <div className="analytics__content">
          <Analytic
            icon={'fa-solid fa-star'}
            title={text_accepted_proposals || 'Propuestas Aceptadas'}
            value={campaign_statistics_proposals.accepted}
          />
          <div className="separator__vertical" />
          <Analytic
            icon={'fa-solid fa-star'}
            title={text_proposals_pending || 'Propuestas Pendientes'}
            value={campaign_statistics_proposals.sended}
          />
          <div className="separator__vertical" />
          <Analytic
            icon={'fa-solid fa-star'}
            title={text_rejected_proposals || 'Propuestas Rechazadas'}
            value={campaign_statistics_proposals.rejected}
          />
          <div className="separator__vertical" />
          <Analytic
            icon={'fa-solid fa-star'}
            title={text_finalized_proposals || 'Propuestas Finalizadas'}
            value={campaign_statistics_proposals.ended}
          />
          <div className="separator__vertical" />
          <Analytic
            icon={'fa-solid fa-star'}
            title={text_total_proposals || 'Total de Propuestas'}
            value={campaign_statistics_proposals.total}
          />
        </div>
        <div className="analytics__content">
          <Analytic
            icon={'fa-solid fa-mobile-screen-button'}
            title={text_accepted_posts || 'Posts Aceptados'}
            value={campaign_statistics_posts.accepted}
          />
          <div className="separator__vertical" />
          <Analytic
            icon={'fa-solid fa-mobile-screen-button'}
            title={text_pending_posts || 'Posts Pendientes'}
            value={campaign_statistics_posts.sended}
          />
          <div className="separator__vertical" />
          <Analytic
            icon={'fa-solid fa-mobile-screen-button'}
            title={text_rejected_posts || 'Posts Rechazados'}
            value={campaign_statistics_posts.rejected}
          />
          <div className="separator__vertical" />
          <Analytic
            icon={'fa-solid fa-mobile-screen-button'}
            title={text_published_posts || 'Posts Publicados'}
            value={campaign_statistics_posts.publicated}
          />
          <div className="separator__vertical" />
          <Analytic
            icon={'fa-solid fa-mobile-screen-button'}
            title={text_total_posts || 'Total de Posts'}
            value={campaign_statistics_posts.total}
          />
        </div>
      </div>

      {/* <div className="divider"></div> */}

      {/* <div className="audience">
        <div className="audience__title">
          <h3>audiencia</h3>
          <span> Demografico</span>
        </div>
        <div className="row">
          <Audience data={data.ages_stat} title={'rango edades'} />
          <Audience data={data.country_stat} title={'top paises'} />
          <Audience data={data.gender_stat} title={'género'} />
        </div>
      </div>
      <div className="divider"></div> */}

      <div className="recent__posts">
        <div className="recent__posts__title">
          <h3>{text_recent_posts || 'Post recientes'}</h3>
        </div>
        <div className="container__card">
          {campaign_statistics_last_posts.map((post) => (
            <PostCardData post={post} key={post.id} />
          ))}
          {campaign_statistics_last_posts &&
            !campaign_statistics_last_posts?.length && (
              <Error type="no-posts" />
            )}
        </div>
        {/* <RecentPost data={images} /> */}
      </div>
      <div className="divider"></div>

      {/* <div className="influencer__metrics">
        <h3>Metricas influencer</h3>
        <InfluencerMetricTable data={influencerMetric} />
      </div> */}
    </>
  );
};

const mapStateToProps = ({ campaignReducer, siteReducer }) => {
  return {
    campaignReducer,
    siteReducer,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignResumeView);
