import React from "react";
import { connect } from "react-redux";

function HistorialItem(props) {
  const {
    data,
    siteReducer: { site_language },
  } = props;
  let d = new Date(data.created_at);
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;
  let date = `${year}/${month}/${day}`;

  return (
    <tr>
      <td>{date}</td>
      <td>
        <div>
          <strong>{data.reference}</strong>
        </div>
        <div>{data.product_name}</div>
      </td>
      <td>
        {!site_language || site_language === "en"
          ? data.transaction_type_english
          : data.transaction_type}
      </td>
      <td className="center">
        BAX$
        <br />
        {data.total}
      </td>
    </tr>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(HistorialItem);
