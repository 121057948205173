import React, {Fragment} from 'react';
import './css/Rating.css';

function Rating(props) {
	let rating = [];
	for (let i = 1; i <= 5; i++) {
		if (i <= props.value) {
			rating.push(<span className="rating fa fa-star active" key={i}/>);
		} else {
			rating.push(<span className="rating fa fa-star" key={i}/>);
		}
	}
	return(
		<Fragment>
			{rating}
		</Fragment>
	)
}

export default Rating;