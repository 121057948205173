import {
  USER_DATA,
  USER_VALIDATE,
  USER_LOGOUT,
  USER_DOC_NUMBER,
  USER_DOC_TYPE,
  USER_EMAIL,
  USER_FIRSTNAME,
  USER_LASTNAME,
  USER_PASSWORD,
  USER_INIT,
  USER_INIT_ERROR,
  USER_ACTIVITY,
  USER_LOADING,
  USER_ERROR,
  USER_CLEAR,
  USER_CLEAR_ERROR,
  USER_SUCCESS,
  USER_CLOSE_LOADING,
  USER_DOC_DATE,
  USER_PROFESSION,
  USER_BILL,
  USER_RUT_SCANN,
  USER_NIT_NUMBER,
  USER_DOC_FRONT,
  USER_DOC_BACK,
  USER_BANK_CERTIFICATION,
  USER_CAMARA_COMERCIO,
  USER_BUSINESS_NAME,
  USER_PHONE,
  USER_DESCRIPTION,
  USER_AVG_SALES,
  USER_WEBSITE,
  USER_FACEBOOK,
  USER_TWITTER,
  USER_LINKEDIN,
  USER_INSTAGRAM,
  USER_COD_CITY,
  USER_ADDRESS,
  USER_ADDRESS_ADDON,
  USER_COD_ACTIVITY,
  USER_COD_DEPARTMENT,
  USER_CHANGE_STEP,
  USER_INITIAL_STEP,
  USER_SUCCESS_ACTIVATION,
  USER_TIMETABLE,
  USER_CHANGE_BUSINESS_PAGE,
  USER_LOADING_INIT,
  USER_BUSINESS_CATEGORY,
  USER_BUSINESS_CHANGE_PAGE_CATEGORY,
  USER_BUSINESS_CATEGORY_CLEAR,
  USER_LIST_TYPE_PRODUCTS,
  USER_CHANGE_PAGE_PRODUCTS,
  USER_CLEAR_TYPE_PRODUCTS,
  USER_USERNAME,
  USER_USERNAME_ERROR,
  USER_USERNAME_SUCCESS,
  USER_USERNAME_VALIDATE,
  USER_PASSWORD_NEW,
  USER_PASSWORD_REPEAT,
  USER_PRODUCT_SEARCH,
  USER_PRODUCT_CHANGE_SEARCH,
  USER_BUSINESS_SEARCH,
  USER_BUSINESS_CHANGE_SEARCH,
  USER_BUSINESS_CATEGORY_SEARCH,
  USER_CHANGE_FILTER_PRICE,
  USER_FILTER_PRICE,
  USER_FAVORITES,
  USER_TYPE_ID,
  USER_VERIFIED,
  USER_COMPANIES,
  USER_REFERRED,
  USER_FAVORITE_COMPANIES,
  USER_TYPE_PERSON,
  USER_RISK_CENTERS,
  USER_LEGAL_REPRESENTATIVE,
  USER_NAME_LEGAL_REPRESENTATIVE,
  USER_LASTNAME_LEGAL_REPRESENTATIVE,
  USER_SOCIAL,
  USER_PROVIDERS,
  USER_VALID_INFORMATION,
  USER_SOCIAL_NETWORKS,
  USER_PRINCIPAL_NETWORK,
  USER_BIRTHDATE,
} from '../types/userTypes';

const INITIAL_STATE = {
  activities: [],
  departments: [],
  favorite_products: [],
  favorite_companies: [],
  cities: [],
  categories: [],
  type_products: [],
  last_products: [],
  business_featured: [],
  promotional_products: [],
  slider: [],
  user_data: null,
  is_user: false,
  session_loading: false,
  firstname: '',
  lastname: '',
  email: '',
  doc_type: '',
  doc_number: '',
  password: '',
  password_new: '',
  password_repeat: '',
  cod_activity: '',
  error_activities: '',
  loading: false,
  loading_text: '',
  error: '',
  success: '',
  image: '',
  coverpage: '',
  doc_expedition_date: '',
  profession: '',
  bill: '',
  rut_scann: '',
  nit_number: '',
  doc_scan_front: '',
  doc_scan_back: '',
  bank_certification: '',
  camara_comercio: '',
  business_name: '',
  phone: '',
  description: '',
  average_sales: '',
  website: '',
  facebook: '',
  twitter: '',
  linkedin: '',
  instagram: '',
  address: '',
  address_addon: '',
  user_cod_department: '',
  user_cod_city: '',
  setting_step: 1,
  success_activation: '',
  timetable: '',
  current_page: 1,
  item_per_page: 12,
  list_business: [],
  list_all_business: [],
  list_view_business: [],
  list_business_category: [],
  list_all_business_category: [],
  list_view_business_category: [],
  list_search_business: [],
  business_search: '',
  loading_init: false,
  list_products: [],
  list_type_all_products: [],
  list_type_products: [],
  list_view_type_products: [],
  list_search_products: [],
  product_search: '',
  username: '',
  username_error: '',
  username_success: '',
  username_validate: false,
  user_price_range: [20000, 10000000],
  user_price_range_selected: [20000, 10000000],
  tax_stripe_percentage_1: 0.1,
  tax_stripe_percentage_2: 0.08,
  tax_stripe_percentage_3: 0.06,
  excel_spanish: null,
  excel_english: null,
  order_statuses: [],
  transaction_types: [],
  user_type_id: '',
  user_verified: false,
  user_companies: [
    {
      name: '',
      address: '',
      website: '',
      contact_name: '',
      contact_phone: '',
      contact_email: '',
    },
    {
      name: '',
      address: '',
      website: '',
      contact_name: '',
      contact_phone: '',
      contact_email: '',
    },
    {
      name: '',
      address: '',
      website: '',
      contact_name: '',
      contact_phone: '',
      contact_email: '',
    },
  ],
  user_referred: null,
  user_type_person: '',
  user_risk_centers: false,
  user_legal_representative: '',
  user_name_legal_representative: '',
  user_lastname_legal_representative: '',
  user_social: '',
  user_providers: '',
  user_valid_information: false,
  user_social_networks: [],
  user_principal_network: "",
  user_birthdate: "",
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_VALIDATE:
      return {
        ...state,
        session_loading: true,
      };
    case USER_DATA:
      return {
        ...state,
        user_data: action.payload,
        favorite_products: action.payload.favorite_products,
        favorite_companies: action.payload.favorite_companies,
        is_user: true,
        session_loading: false,
        loading: false,
        error: '',
        loading_text: '',
      };
    case USER_LOGOUT:
      return {
        ...state,
        is_user: false,
        session_loading: false,
        loading: false,
        loading_text: '',
        user_data: null,
      };
    case USER_FIRSTNAME:
      return {
        ...state,
        firstname: action.payload,
      };
    case USER_LASTNAME:
      return {
        ...state,
        lastname: action.payload,
      };
    case USER_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case USER_DOC_NUMBER:
      return {
        ...state,
        doc_number: action.payload,
      };
    case USER_DOC_TYPE:
      return {
        ...state,
        doc_type: action.payload,
      };
    case USER_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case USER_PASSWORD_NEW:
      return {
        ...state,
        password_new: action.payload,
      };
    case USER_PASSWORD_REPEAT:
      return {
        ...state,
        password_repeat: action.payload,
      };
    case USER_ACTIVITY:
      return {
        ...state,
        cod_activity: action.payload,
      };
    case USER_RISK_CENTERS:
      return {
        ...state,
        user_risk_centers: action.payload,
      };
    case USER_INIT:
      return {
        ...state,
        activities: action.payload.activities,
        departments: action.payload.departments,
        cities: action.payload.cities,
        categories: action.payload.categories,
        type_products: action.payload.typeProducts,
        list_business: action.payload.listBusiness,
        list_all_business: action.payload.listBusiness,
        list_search_business: action.payload.listBusiness,
        list_view_business: action.payload.listViewBusiness,
        list_products: action.payload.listProducts,
        last_products: action.payload.lastProducts,
        business_featured: action.payload.listFeatured,
        promotional_products: action.payload.promoProducts,
        order_statuses: action.payload.orderStatuses,
        transaction_types: action.payload.transactionTypes,
        slider: action.payload.slider,
        tax_stripe_percentage_1: action.payload.tax_stripe_percentage_1,
        tax_stripe_percentage_2: action.payload.tax_stripe_percentage_2,
        tax_stripe_percentage_3: action.payload.tax_stripe_percentage_3,
        excel_spanish: action.payload.excel_spanish,
        excel_english: action.payload.excel_english,
        loading_init: false,
      };
    case USER_INIT_ERROR:
      return {
        ...state,
        error_activities: action.payload,
        loading_init: false,
      };
    case USER_LOADING_INIT: {
      return {
        ...state,
        loading_init: true,
      };
    }
    case USER_LOADING:
      return {
        ...state,
        loading: action.payload !== '' ? true : false,
        loading_text: action.payload,
      };
    case USER_ERROR:
      return {
        ...state,
        loading: false,
        loading_text: '',
        error: action.payload,
      };
    case USER_CLEAR: {
      return {
        ...state,
        firstname: '',
        lastname: '',
        email: '',
        doc_type: '',
        doc_number: '',
        password: '',
        password_new: '',
        password_repeat: '',
        image: '',
        coverpage: '',
        cod_activity: '',
        error: '',
        cod_expedition_date: '',
        profession: '',
        bill: '',
        rut_scann: '',
        nit_number: '',
        doc_scan_front: '',
        doc_scan_back: '',
        bank_certification: '',
        camara_comercio: '',
        business_name: '',
        phone: '',
        description: '',
        average_sales: '',
        website: '',
        facebook: '',
        twitter: '',
        linkedin: '',
        instagram: '',
        user_cod_city: '',
        user_cod_department: '',
        address: '',
        address_addon: '',
        success_activation: '',
        timetable: '',
        current_page: 1,
        username: '',
        username_error: '',
        username_success: '',
        username_validate: false,
        user_price_range: [20000, 10000000],
        user_price_range_selected: [20000, 10000000],
        user_referred: null,
        user_type_person: '',
        user_legal_representative: '',
        user_name_legal_representative: '',
        user_lastname_legal_representative: '',
        user_social: '',
        user_providers: '',
        user_valid_information: false,
      };
    }
    case USER_CLEAR_ERROR: {
      return {
        ...state,
        error: '',
        success: '',
        loading: false,
        loading_text: '',
      };
    }
    case USER_SUCCESS: {
      return {
        ...state,
        success: action.payload,
      };
    }
    case USER_CLOSE_LOADING: {
      return {
        ...state,
        loading: false,
        loading_text: '',
      };
    }
    case USER_DOC_DATE: {
      return {
        ...state,
        doc_expedition_date: action.payload,
      };
    }
    case USER_PROFESSION: {
      return {
        ...state,
        profession: action.payload,
      };
    }
    case USER_TYPE_PERSON: {
      return {
        ...state,
        user_type_person: action.payload,
      };
    }
    case USER_BILL: {
      return {
        ...state,
        bill: action.payload,
      };
    }
    case USER_RUT_SCANN: {
      return {
        ...state,
        rut_scann: action.payload,
      };
    }
    case USER_NIT_NUMBER: {
      return {
        ...state,
        nit_number: action.payload,
      };
    }
    case USER_DOC_FRONT: {
      return {
        ...state,
        doc_scan_front: action.payload,
      };
    }
    case USER_DOC_BACK: {
      return {
        ...state,
        doc_scan_back: action.payload,
      };
    }
    case USER_BANK_CERTIFICATION: {
      return {
        ...state,
        bank_certification: action.payload,
      };
    }
    case USER_CAMARA_COMERCIO: {
      return {
        ...state,
        camara_comercio: action.payload,
      };
    }
    case USER_BUSINESS_NAME: {
      return {
        ...state,
        business_name: action.payload,
      };
    }
    case USER_PHONE: {
      return {
        ...state,
        phone: action.payload,
      };
    }
    case USER_DESCRIPTION: {
      return {
        ...state,
        description: action.payload,
      };
    }
    case USER_AVG_SALES: {
      return {
        ...state,
        average_sales: action.payload,
      };
    }
    case USER_WEBSITE: {
      return {
        ...state,
        website: action.payload,
      };
    }
    case USER_FACEBOOK: {
      return {
        ...state,
        facebook: action.payload,
      };
    }
    case USER_TWITTER: {
      return {
        ...state,
        twitter: action.payload,
      };
    }
    case USER_LINKEDIN: {
      return {
        ...state,
        linkedin: action.payload,
      };
    }
    case USER_INSTAGRAM: {
      return {
        ...state,
        instagram: action.payload,
      };
    }
    case USER_COD_DEPARTMENT: {
      return {
        ...state,
        user_cod_department: action.payload,
      };
    }
    case USER_COD_CITY: {
      return {
        ...state,
        user_cod_city: action.payload,
      };
    }
    case USER_ADDRESS: {
      return {
        ...state,
        address: action.payload,
      };
    }
    case USER_ADDRESS_ADDON: {
      return {
        ...state,
        address_addon: action.payload,
      };
    }
    case USER_COD_ACTIVITY: {
      return {
        ...state,
        cod_activity: action.payload,
      };
    }
    case USER_INITIAL_STEP: {
      return {
        ...state,
        setting_step: 1,
      };
    }
    case USER_CHANGE_STEP: {
      return {
        ...state,
        setting_step: action.payload,
      };
    }
    case USER_SUCCESS_ACTIVATION: {
      return {
        ...state,
        success_activation: action.payload,
      };
    }
    case USER_TIMETABLE: {
      return {
        ...state,
        timetable: action.payload,
      };
    }
    case USER_CHANGE_BUSINESS_PAGE:
      return {
        ...state,
        list_view_business: action.payload.listView,
        current_page: action.payload.currentPage,
      };
    case USER_BUSINESS_CATEGORY:
      return {
        ...state,
        list_business_category: action.payload.totalList,
        list_all_business_category: action.payload.totalList,
        list_search_business: action.payload.totalList,
        list_view_business_category: action.payload.listView,
        business_search: '',
      };
    case USER_BUSINESS_SEARCH:
      return {
        ...state,
        list_business: action.payload.list_total,
        list_view_business: action.payload.list_view,
        current_page: 1,
      };
    case USER_BUSINESS_CATEGORY_SEARCH:
      return {
        ...state,
        list_business_category: action.payload.list_total,
        list_view_business_category: action.payload.list_view,
        current_page: 1,
      };
    case USER_BUSINESS_CHANGE_SEARCH:
      return {
        ...state,
        business_search: action.payload,
      };
    case USER_BUSINESS_CHANGE_PAGE_CATEGORY:
      return {
        ...state,
        list_view_business_category: action.payload.list_view,
        current_page: action.payload.current_page,
      };
    case USER_BUSINESS_CATEGORY_CLEAR:
      return {
        ...state,
        list_business_category: [],
        list_all_business_category: [],
        list_view_business_category: [],
        business_search: '',
        current_page: 1,
      };
    case USER_LIST_TYPE_PRODUCTS:
      return {
        ...state,
        list_type_all_products: action.payload.list_total,
        list_type_products: action.payload.list_total,
        list_search_products: action.payload.list_total,
        list_view_type_products: action.payload.list_view,
        product_search: '',
        current_page: 1,
      };
    case USER_PRODUCT_SEARCH:
      return {
        ...state,
        list_type_products: action.payload.list_total,
        list_view_type_products: action.payload.list_view,
        current_page: 1,
      };
    case USER_FILTER_PRICE: {
      return {
        ...state,
        list_type_products: action.payload.list_total,
        list_view_type_products: action.payload.list_view,
        current_page: 1,
      };
    }
    case USER_PRODUCT_CHANGE_SEARCH:
      return {
        ...state,
        product_search: action.payload,
      };
    case USER_CHANGE_PAGE_PRODUCTS:
      return {
        ...state,
        list_view_type_products: action.payload.list_view,
        current_page: action.payload.current_page,
      };
    case USER_CLEAR_TYPE_PRODUCTS:
      return {
        ...state,
        list_type_all_products: [],
        list_type_products: [],
        list_view_type_products: [],
        current_page: 1,
      };
    case USER_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case USER_USERNAME_ERROR:
      return {
        ...state,
        username_error: action.payload,
        username_validate: '',
        username_success: '',
      };
    case USER_USERNAME_SUCCESS:
      return {
        ...state,
        username_success: action.payload,
        username_error: '',
      };
    case USER_USERNAME_VALIDATE:
      return {
        ...state,
        username_validate: action.payload,
        username_error: '',
      };
    case USER_CHANGE_FILTER_PRICE: {
      return {
        ...state,
        user_price_range_selected: action.payload,
      };
    }
    case USER_FAVORITES:
      return {
        ...state,
        favorite_products: action.payload,
      };
    case USER_FAVORITE_COMPANIES:
      return {
        ...state,
        favorite_companies: action.payload,
      };
    case USER_VERIFIED:
      return {
        ...state,
        user_verified: action.payload,
      };
    case USER_COMPANIES:
      return {
        ...state,
        user_companies: action.payload,
      };
    case USER_REFERRED:
      return {
        ...state,
        user_referred: action.payload,
      };
    case USER_LEGAL_REPRESENTATIVE:
      return {
        ...state,
        user_legal_representative: action.payload,
      };
    case USER_NAME_LEGAL_REPRESENTATIVE:
      return {
        ...state,
        user_name_legal_representative: action.payload,
      };
    case USER_LASTNAME_LEGAL_REPRESENTATIVE:
      return {
        ...state,
        user_lastname_legal_representative: action.payload,
      };
    case USER_SOCIAL:
      return {
        ...state,
        user_social: action.payload,
      };
    case USER_PROVIDERS:
      return {
        ...state,
        user_providers: action.payload,
      };
    case USER_VALID_INFORMATION:
      return {
        ...state,
        user_valid_information: action.payload,
      };
    case USER_TYPE_ID:
      return {
        ...state,
        user_type_id: action.payload,
      };
    case USER_SOCIAL_NETWORKS:
      return {
        ...state,
        user_social_networks: action.payload,
      };
    case USER_PRINCIPAL_NETWORK:
      return {
        ...state,
        user_principal_network: action.payload,
      };
    case USER_BIRTHDATE:
      return {
        ...state,
        user_birthdate: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
