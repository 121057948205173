import React, { useMemo, useState } from 'react';
import MarketplaceLayout from '../layouts/MarketplaceLayout';
import InfluencersView from '../views/Influencers/InfluencersView';

const InfluencerPage = (props) => {
  const {
    location: { search },
  } = props;
  const [statusSearch, setStatusSearch] = useState(false);
  const useInfluencersView = useMemo(
    () => <InfluencersView search={search} />,
    [search]
  );

  const openCloseSearch = (statusSearch) => {
    setStatusSearch(statusSearch);
  };

  return (
    <MarketplaceLayout
      statusSearch={statusSearch}
      openCloseSearch={openCloseSearch}
    >
      {useInfluencersView}
    </MarketplaceLayout>
  );
};

export default InfluencerPage;
