import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../../images/logo.png";
import "./css/Questions.css";
import Question from "./Question";

class Questions extends Component {
  state = {
    step: 0,
    totalSteps: 1,
    changeStep2: false,
    changeStep15: false,
    changeStep20: false,
    changeStep22: false,
    showNext: true,
  };

  componentDidMount = () => {
    this.setState({
      totalSteps: this.props.questions.length,
    });
  };

  nextStep = (newStep = null) => {
    if (newStep) {
      this.setState({
        step: newStep,
      });
    } else {
      this.setState({
        step: this.state.step + 1,
      });
    }
  };

  prevStep = (newStep = null) => {
    this.setState({ showNext: true });

    if (newStep) {
      this.setState({
        step: newStep,
      });
    } else {
      this.setState({
        step: this.state.step - 1,
      });
    }
  };

  render() {
    const { step, totalSteps, showNext } = this.state;
    const {
      siteReducer: {
        site_texts: {
          text_questions,
          text_questions_time,
          text_questions_start,
        },
      },
      userReducer: { user_providers },
      questions,
    } = this.props;

    // console.log(questions);

    return (
      <div className="questions_container">
        <div className="questions_content">
          {step > 1 ? (
            <button
              className="questions_button-arrow"
              onClick={() => this.prevStep()}
            >
              <i className="fa fa-chevron-left" />
            </button>
          ) : (
            <div style={{ width: 40, height: 40 }} />
          )}
          <div className="questions_questions">
            {step > 0 && (
              <>
                <div className="questions_conten-line">
                  <div
                    className="questions_line"
                    style={{ width: `${(step * 100) / totalSteps}%` }}
                  />
                </div>
                <img src={logo} className="questions_logo" alt="Logo" />
              </>
            )}
            <Question currentStep={step} step={0} menu={false} content={false}>
              <div className="questions_intro">
                <img src={logo} alt="Logo INFLUENX" />
                <div className="questions_intro-content">
                  <div className="questions_intro-title">
                    {text_questions
                      ? text_questions
                      : "Diligencia el siguiente formulario para terminar tu registro"}
                  </div>
                  <button
                    onClick={() => this.nextStep()}
                    className="questions_button"
                  >
                    {text_questions_start
                      ? text_questions_start
                      : "¡Empecemos!"}
                  </button>
                  <div className="questions_intro-note">
                    {text_questions_time
                      ? text_questions_time
                      : "Tiempo estimado 7 minutos"}
                  </div>
                </div>
              </div>
            </Question>
            {questions.map((item, index) => (
              <Question
                key={index}
                currentStep={step}
                question={item.question || ""}
                note={item.note || ""}
                step={item.step}
                skip={item.skip || false}
                menu={item.menu || false}
                content={item.content || false}
                type={item.type || "input"}
                value={item.value}
                onChange={item.onChange || null}
                inputType={item.inputType || "text"}
                placeholder={item.placeholder || ""}
                maxLength={item.maxLength || ""}
                inputFileTitle={item.inputFileTitle || ""}
                options={item.options || []}
                buttonClass={item.buttonClass || "questions_confirm"}
                inputFileId={item.inputFileId || ""}
                onSaveDoc={item.onSaveDoc || null}
                inputFileLabel={item.inputFileLabel || ""}
                acceptInputFile={item.acceptInputFile || ""}
                isMultiple={item.isMultiple}
                multipleValidation={item.multipleValidation}
                multipleRender={item.multipleRender}
                multipleSelection={item.multipleSelection}
                save={() => {
                  item.save();
                  if (step < questions.length) {
                    this.nextStep();
                  }
                }}
              >
                {item.children || null}
              </Question>
            ))}
          </div>
          {step < totalSteps && step > 0 && showNext ? (
            <button
              className="questions_button-arrow"
              onClick={() => {
                const validateStep = questions.length - 1;
                this.nextStep();
                if (
                  step >= validateStep &&
                  (user_providers !== "" || !user_providers)
                ) {
                  this.setState({ showNext: false });
                }
              }}
            >
              <i className="fa fa-chevron-right" />
            </button>
          ) : (
            <div style={{ width: 40, height: 40 }} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ siteReducer, userReducer }) => {
  return {
    siteReducer,
    userReducer,
  };
};

export default connect(mapStateToProps, null)(Questions);
