import Swal from 'sweetalert2';
import Api from '../Api';
import {
  PRINCIPAL_TAG,
  TAGS,
  TAGS_LOADING,
  TAGS_USER,
} from '../types/tagTypes';

export const changeTags = (payload) => (dispatch) => {
  dispatch({
    type: TAGS,
    payload,
  });
};

export const changeTagsLoading = (payload) => (dispatch) => {
  dispatch({
    type: TAGS_LOADING,
    payload,
  });
};

export const changeTagsUser = (payload) => (dispatch) => {
  dispatch({
    type: TAGS_USER,
    payload,
  });
};

const addOrRemoveTags = (tags, addTag) => {
  const isAdded = tags.find((tag) => tag.id === addTag.id);

  if (isAdded) {
    tags = tags.filter((tag) => tag.id !== addTag.id);
  } else {
    tags.push(addTag);
  }

  return tags;
};

export const addOrRemoveTagsUser = (payload) => (dispatch, getState) => {
  const { tags_user } = getState().tagsReducer;
  let tags = addOrRemoveTags(tags_user, payload);

  dispatch(changeTagsUser(tags));
};

export const getTags = () => async (dispatch) => {
  try {
    const response = await Api.asyncCallMethod('v1/tags', 'GET');
    dispatch(changeTags(response.response.data));
    dispatch(changeTagsLoading(false));
  } catch (error) {
    console.log('Error getTags: ', error);
    dispatch(changeTagsLoading(false));
  }
};

export const saveTags = () => async (dispatch, getState) => {
  const { tags_user } = getState().tagsReducer;
  const { user_data } = getState().userReducer;
  const {
    site_texts: { text_influencer_error_5 },
  } = getState().siteReducer;

  const data = {
    tags: tags_user,
  };

  try {
    const response = await Api.asyncCallMethod(
      `v1/users/${user_data.id}/tags`,
      'POST',
      data
    );
    dispatch(changeTagsUser(response.response.data));
  } catch (error) {
    console.log('Error saveTags: ', error);
    Swal.fire({
      title: 'Upsss!!!',
      text: text_influencer_error_5 || 'Error al guardar los topics',
      type: 'error',
      confirmButtonColor: '#50318B',
    });
  }
};

export const changePricipalTag = (payload) => (dispatch) => {
  dispatch({
    type: PRINCIPAL_TAG,
    payload,
  });
};

export const savePrincipalTag = () => async (dispatch, getState) => {
  const { principal_tag } = getState().tagsReducer;
  const { user_data } = getState().userReducer;
  const {
    site_texts: { text_influencer_error_6 },
  } = getState().siteReducer;

  try {
    const response = await Api.asyncCallMethod(
      `v1/users/${user_data.id}/tags/${principal_tag}/principal`,
      'PUT'
    );
    dispatch(changeTagsUser(response.response.data));
  } catch (error) {
    console.log('Error saveTags: ', error);
    Swal.fire({
      title: 'Upsss!!!',
      text: text_influencer_error_6 || 'Error al guardar el topic principal',
      type: 'error',
      confirmButtonColor: '#50318B',
    });
  }
};
