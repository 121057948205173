import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import "./css/SoldBy.css";
import ImageCompany from "../../../images/believe.jpg";
import { connect } from "react-redux";

function SoldBy(props) {
  const {
    siteReducer: {
      site_texts: { text_rating },
    },
  } = props;

  let { data } = props;
  let link =
    data.username && data.username !== ""
      ? `/${data.username}`
      : `/company/${data.id}`;
  let image = data.image ? data.image : ImageCompany;
  let rating = parseFloat(data.rating).toFixed(1);

  return (
    <Link to={link} className="soldBy_container">
      <img src={image} alt={data.name} className="soldBy_image" />
      <div className="soldBy_rating">
        <Rating value={data.rating} />
      </div>
      {props.viewRating && (
        <Fragment>
          <div className="soldBy_title-rating">
            {text_rating ? text_rating.toUpperCase() : "PROMEDIO"} BAX
          </div>
          <div className="soldBy_number-rating">{rating}</div>
        </Fragment>
      )}
    </Link>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(SoldBy);
