import React from 'react';
import { connect } from 'react-redux';
import './css/PaymentMethodList.css';
import PaymentMethodItem from './PaymentMethodItem';

import {
  openPaymentModal,
  paymentEditCard,
  paymentRemoveCard,
} from '../../../functionality/actions/paymentActions';

function PaymentMethodList(props) {
  const {
    paymentReducer: { payment_list_cards },
    siteReducer: {
      site_texts: {
        text_add_card,
        text_cards_number,
        text_cards_type,
        text_cards_dues,
        text_cards_default,
        text_cards_actions,
      },
    },
    openPaymentModal,
    paymentEditCard,
    paymentRemoveCard,
  } = props;
  return (
    <div className="paymentMethodList_container">
      <div className="table-responsive-md">
        <table className="table">
          <thead>
            <tr>
              <td>{text_cards_number ? text_cards_number : 'No. Tarjeta'}</td>
              <td>{text_cards_type ? text_cards_type : 'Tipo'}</td>
              <td>
                {text_cards_dues ? text_cards_dues : 'No. cuotas por defecto'}
              </td>
              <td>
                {text_cards_default ? text_cards_default : 'Predeterminada'}
              </td>
              <td>{text_cards_actions ? text_cards_actions : 'Acciones'}</td>
            </tr>
          </thead>
          <tbody>
            {payment_list_cards.map((item) => {
              return (
                <PaymentMethodItem
                  key={item.id}
                  data={item}
                  paymentEditCard={paymentEditCard}
                  paymentRemoveCard={paymentRemoveCard}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn paymentMethodList_btn"
          onClick={() => openPaymentModal('card')}
        >
          {text_add_card ? text_add_card : 'AGREGAR TARJETA'}
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = ({ paymentReducer, siteReducer }) => {
  return {
    paymentReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  openPaymentModal,
  paymentEditCard,
  paymentRemoveCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodList);
