import Api from '../Api';
import {
  AD_CLEAR,
  AD_LOADING,
  AD_LOADING_PER_MONTH,
  AD_LOADING_PER_DATE,
  AD_LOADING_RESOURCE,
  AD_IMAGE,
  AD_MOBILE_IMAGE,
  AD_DATE,
  AD_SPACE_ID,
  AD_PLAN_ID,
  AD_NAME,
  AD_RESOURCE_ID,
  AD_CATEGORY_ID,
  AD_SENT,
  AD_MY_LIST_LOADING,
  AD_MY_LIST,
  AD_SETTINGS_LOADING,
  AD_SETTINGS,
  AD_SELECT_DATES,
  AD_DETAIL_LOADING,
  AD_DETAIL,
  // AD_PRODUCTS,
  // AD_SERVICES,
  // AD_LIST,
  AD_LIST_MONTH,
  AD_LIST_DATE,
  AD_LIST_VIEW,
} from '../types/adTypes';
import Swal from 'sweetalert2';

export const adClear = () => (dispatch) => {
  dispatch({
    type: AD_CLEAR,
  });
};

export const changeAdLoading = (payload) => (dispatch) => {
  dispatch({
    type: AD_LOADING,
    payload,
  });
};

export const changeAdLoadingResource = (payload) => (dispatch) => {
  dispatch({
    type: AD_LOADING_RESOURCE,
    payload,
  });
};

export const changeAdMyListLoading = (payload) => (dispatch) => {
  dispatch({
    type: AD_MY_LIST_LOADING,
    payload,
  });
};

export const getAdMyList = () => (dispatch) => {
  dispatch(changeAdMyListLoading(true));

  Api.callMethod(
    'v1/user/ads',
    'GET',
    null,
    (res) => {
      if (res.status === 200) {
        const { data } = res.response;

        dispatch({
          type: AD_MY_LIST,
          payload: data,
        });
      }
      dispatch(changeAdMyListLoading(false));
    },
    (error) => {
      console.log('Error getAdMyList: ', error);
      dispatch(changeAdMyListLoading(false));
    }
  );
};

export const changeAdSettingLoading = (payload) => (dispatch) => {
  dispatch({
    type: AD_SETTINGS_LOADING,
    payload,
  });
};

export const getAdSettings = () => (dispatch) => {
  dispatch(changeAdSettingLoading(true));
  Api.callMethod(
    'v1/settings/ads',
    'GET',
    null,
    (res) => {
      if (res.status === 200) {
        const { data } = res.response;

        dispatch({
          type: AD_SETTINGS,
          payload: data,
        });
      }
      dispatch(changeAdSettingLoading(false));
    },
    (error) => {
      console.log('Error getAdSettings: ', error);
      dispatch(changeAdSettingLoading(false));
    }
  );
};

export const changeAdImage = (payload) => (dispatch) => {
  dispatch({
    type: AD_IMAGE,
    payload,
  });
};

export const changeAdMobileImage = (payload) => (dispatch) => {
  dispatch({
    type: AD_MOBILE_IMAGE,
    payload,
  });
};

export const changeAdCategoryID = (payload) => (dispatch) => {
  dispatch({
    type: AD_CATEGORY_ID,
    payload,
  });
};

export const changeAdResourceId = (payload) => (dispatch) => {
  dispatch({
    type: AD_RESOURCE_ID,
    payload,
  });
};

export const changeAdSpaceId = (payload) => (dispatch) => {
  dispatch(changeAdCategoryID(''));
  dispatch(changeAdResourceId(''));
  dispatch({
    type: AD_SPACE_ID,
    payload,
  });
};

export const changeAdPlanId = (payload) => (dispatch) => {
  dispatch({
    type: AD_PLAN_ID,
    payload,
  });
};

export const changeAdName = (payload) => (dispatch) => {
  dispatch({
    type: AD_NAME,
    payload,
  });
};

export const changeAdSent = (payload) => (dispatch) => {
  dispatch({
    type: AD_SENT,
    payload,
  });
};

export const changeAdDate = (payload) => (dispatch) => {
  dispatch({
    type: AD_DATE,
    payload,
  });
};

export const changeAdSelectDates = (payload) => (dispatch) => {
  dispatch(changeAdDate(payload[0]));
  dispatch({
    type: AD_SELECT_DATES,
    payload,
  });
};

export const changeAdLoadingPerMonth = (payload) => (dispatch) => {
  dispatch({
    type: AD_LOADING_PER_MONTH,
    payload,
  });
};

export const adGetListPerSpaceAndMonth =
  (year, month) => (dispatch, getState) => {
    dispatch(changeAdLoadingPerMonth(true));

    month = `${month}`.padStart(2, 0);
    const { ad_space_id, ad_category_id } = getState().adReducer;
    const space = ad_space_id === '' ? 1 : ad_space_id.id;
    let route = `v1/ads/space-month?year=${year}&month=${month}&space=${space}`;

    if (ad_category_id !== '') {
      route = `${route}&category=${ad_category_id}`;
    }

    Api.callMethod(
      route,
      'get',
      null,
      (res) => {
        if (res.status === 200) {
          const { data } = res.response;
          dispatch({
            type: AD_LIST_MONTH,
            payload: data,
          });
        }
        dispatch(changeAdLoadingPerMonth(false));
      },
      (error) => {
        console.log('adGetListPerSpaceAndMonth: ', error);
        dispatch(changeAdLoadingPerMonth(false));
      }
    );
  };

const showAlert = (message, type = 'warning', title = 'Upsss!!!') => {
  Swal.fire({
    title,
    text: message,
    type: type,
    confirmButtonColor: '#50318B',
  });
};

export const adSave = () => (dispatch, getState) => {
  const {
    ad_detail,
    ad_name,
    ad_space_id,
    ad_plan_id,
    ad_category_id,
    ad_resource_id,
    ad_image,
    ad_mobile_image,
    ad_sent,
    ad_select_dates,
    ad_date,
  } = getState().adReducer;

  const {
    site_texts: {
      text_warning_date_publication,
      text_sucess_congratulations,
      text_sucess_campaign_saved,
      text_sorry,
    },
  } = getState().siteReducer;

  const { user_data } = getState().userReducer;

  if (ad_date === '' || !ad_select_dates.length) {
    showAlert(
      text_warning_date_publication ||
        'Debes seleccionar la fecha de publicación.'
    );
    return;
  }

  const data = {
    ad_space_id: ad_space_id.id,
    ad_plan_id: ad_plan_id.id,
    name: ad_name,
    date: ad_date,
    image: ad_image,
    mobile_image: ad_mobile_image,
    dates: ad_select_dates,
  };

  if (ad_category_id) {
    data.category_id = ad_category_id;
  }
  if (ad_resource_id) {
    data.resource_id = ad_resource_id;
  } else {
    data.resource_id = user_data.id;
  }
  if (ad_sent) {
    data.sent = ad_sent;
  }

  const route = ad_detail ? `v1/ads/${ad_detail.id}` : 'v1/ads';
  const method = ad_detail ? 'PUT' : 'POST';

  Api.callMethod(
    route,
    method,
    data,
    (res) => {
      const {
        status,
        response: { message },
      } = res;
      if (status === 201 || status === 200) {
        Swal.fire(
          `¡${text_sucess_congratulations || 'Felicidades'}!`,
          `${
            text_sucess_campaign_saved ||
            'La campaña ha sido guardada con éxito.'
          }`,
          'sucess'
        ).then(() => {
          window.location.href = `/manager/ads`;
        });
      } else {
        showAlert(
          message
            ? message
            : 'Hubo un error al publicar la campaña, intentalo más tarde.',
          'error'
        );
      }
    },
    (error) => {
      console.log('adSave error: ', error);
      showAlert(
        `${
          text_sorry ||
          'Hubo un error al publicar la campaña, intentalo más tarde.'
        }`,
        'error'
      );
    }
  );
};

export const adRemove = (id) => (dispatch, getState) => {
  const {
    site_texts: {
      text_are_you_sure,
      text_yes_deleted,
      text_question_delete_campaign,
      text_success_deleted_campaign,
      text_sucess_congratulations,
      text_sorry,
    },
  } = getState().siteReducer;
  const { ad_my_list } = getState().adReducer;

  Swal.fire({
    title: text_are_you_sure ? text_are_you_sure : '¿Está seguro?',
    text: `${text_question_delete_campaign || 'Vas a borrar una campaña.'}`,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50318B',
    cancelButtonColor: '#d33',
    confirmButtonText: text_yes_deleted ? text_yes_deleted : 'Si, borrar',
  }).then((res) => {
    if (res.value) {
      Api.callMethod(
        `v1/ads/${id}`,
        'DELETE',
        null,
        (res) => {
          const {
            status,
            response: { message },
          } = res;
          if (status === 200) {
            showAlert(
              `${
                text_success_deleted_campaign ||
                'La campaña ha sido borrada correctamente.'
              }`,
              'sucess',
              `¡${text_sucess_congratulations || 'Felicidades'}!`
            );
            const data = ad_my_list.filter(
              (item) => parseInt(item.id) !== parseInt(id)
            );
            dispatch({
              type: AD_MY_LIST,
              payload: data,
            });
          } else {
            showAlert(message);
          }
        },
        (error) => {
          console.log('Error adRemove: ', error);
          showAlert(
            `${
              text_sorry ||
              'Hubo un error al borrar la campaña, vuelve a intenatarlo más tarde.'
            }`
          );
        }
      );
    }
  });
};

export const changeAdDetailLoading = (payload) => (dispatch) => {
  dispatch({
    type: AD_DETAIL_LOADING,
    payload,
  });
};

export const adGetDetail = (id) => (dispatch) => {
  dispatch(changeAdDetailLoading(true));
  Api.callMethod(
    `v1/ads/${id}`,
    'get',
    null,
    (res) => {
      if (res.status === 200) {
        const { data } = res.response;
        dispatch({
          type: AD_DETAIL,
          payload: data,
        });
        if (data.space) dispatch(changeAdSpaceId(data.space));
        if (data.plan) dispatch(changeAdPlanId(data.plan));
        if (data.name) dispatch(changeAdName(data.name));
        if (data.image) dispatch(changeAdImage(data.image));
        if (data.mobile_image) dispatch(changeAdMobileImage(data.mobile_image));
        if (data.dates) dispatch(changeAdSelectDates(data.dates));
        if (data.resource) dispatch(changeAdResourceId(data.resource));
        if (data.category) dispatch(changeAdCategoryID(data.category));
        dispatch(changeAdSent(data.sent));
        dispatch(changeAdDetailLoading(false));
      } else {
        window.location.href = '/manager/ads';
      }
    },
    (error) => {
      console.log('Error adGetDetail: ', error);
      window.location.href = '/manager/ads';
    }
  );
};

export const changeAdLoadingDate = (payload) => (dispatch) => {
  dispatch({
    type: AD_LOADING_PER_DATE,
    payload,
  });
};

export const getAdsToday = () => (dispatch) => {
  dispatch(changeAdLoadingDate(true));
  const date = new Date();
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = `${month}`.padStart(2, 0);
  let day = date.getDate();
  day = `${day}`.padStart(2, 0);
  const today = `${year}-${month}-${day}`;

  Api.callMethod(
    `v1/ads?date=${today}`,
    'get',
    null,
    (res) => {
      if (res.status === 200) {
        const { data } = res.response;
        dispatch({
          type: AD_LIST_DATE,
          payload: data,
        });
      }
      dispatch(changeAdLoadingDate(false));
    },
    (error) => {
      console.log('Error getAdsToday: ', error);
      dispatch(changeAdLoadingDate(false));
    }
  );
};

export const adListView =
  (space, category = null) =>
  (dispatch, getState) => {
    dispatch({
      type: AD_LIST_VIEW,
      payload: [],
    });

    const { ad_list_date } = getState().adReducer;
    let data = ad_list_date.filter((item) => item.space === space);

    if (space === 2 || space === 4 || space === 6) {
      data = data.filter((item) => item.category === parseInt(category));
    }

    dispatch({
      type: AD_LIST_VIEW,
      payload: data,
    });
  };
