import React from 'react';
import { connect } from 'react-redux';

function OrderProduct(props) {
  const {
    siteReducer: {
      site_texts: { text_product, text_product_price, text_amount, text_total },
    },
    orderDetail,
  } = props;
  const numberFormat = new Intl.NumberFormat('es-CO');

  if (!orderDetail) {
    return null;
  }

  return (
    <>
      <strong>{text_product || 'Producto'}: </strong>
      {orderDetail.product.name}
      <div className="orderDetail_product-images">
        {orderDetail.product.images.map((image) => (
          <img
            src={image.image}
            key={image.id}
            alt={`Product ${image.id}`}
            className="orderDetail_img"
          />
        ))}
      </div>
      <div className="table-responsive-md orderDetail_table">
        <table className="table table-striped">
          <thead>
            <tr>
              <td>{text_product_price || 'Precio'}</td>
              <td>{text_amount || 'Cantidad'}</td>
              <td>{text_total || 'Total'}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>BAX$ {numberFormat.format(orderDetail.price)}</td>
              <td>{orderDetail.quantity}</td>
              <td>BAX$ {numberFormat.format(orderDetail.total)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderProduct);
