import React, { Fragment, Component, useMemo } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import BoxWhite from '../../../../components/user/marketplace/BoxWhite';
import DetailSellerBuyer from '../../../../components/user/manager/DetailSellerBuyer';
import ChatMessage from '../../../../components/user/manager/ChatMessage';
import OrderRating from '../../../../components/user/manager/OrderDetail/OrderRating';
import OrderChangeStatus from '../../../../components/user/manager/OrderDetail/OrderChangeStatus';
import { orderClearError } from '../../../../functionality/actions/orderActions';
import './css/OrderDetailView.css';
import OrderProduct from '../../../../components/user/manager/OrderDetail/OrderProduct';
import OrderProposal from '../../../../components/user/manager/OrderDetail/OrderProposal';

const MySwal = withReactContent(Swal);

function OrderDetailView(props) {
  const {
    orderReducer: { order_detail, order_alert_error },
    userReducer: { user_data },
    siteReducer: {
      site_texts: { text_no_order, text_messages, text_handling_order_no },
    },
  } = props;

  const showError = (message, error = 'warning') => {
    MySwal.fire({
      title: 'Upsss!!!',
      text: message,
      type: error,
      confirmButtonColor: '#50318B',
      onClose: () => props.orderClearError(),
    });
  };

  const memoOrderProduct = useMemo(
    () => <OrderProduct orderDetail={order_detail} />,
    [order_detail]
  );
  const memoOrderProposal = useMemo(
    () => <OrderProposal orderDetail={order_detail} />,
    [order_detail]
  );
  const memoOrderChangeStatus = useMemo(
    () => (
      <OrderChangeStatus orderDetail={order_detail} showError={showError} />
    ),
    [order_detail]
  );
  const memoOrderRating = useMemo(
    () => <OrderRating orderDetail={order_detail} />,
    [order_detail]
  );

  if (!order_detail || !user_data) {
    return 'Cargando....';
  }

  let send_user =
    user_data.id === order_detail.seller.id
      ? order_detail.buyer.id
      : order_detail.seller.id;

  const orderTitle = text_no_order || 'No. Orden';
  const subjectTitle = text_handling_order_no || 'Manejo orden No.';

  return (
    <>
      {order_alert_error !== '' && showError(order_alert_error, 'error')}
      <div className="row">
        <div className="col-md-7">
          <BoxWhite title={`${orderTitle}: ${order_detail.reference}`}>
            <DetailSellerBuyer
              seller={order_detail.seller}
              buyer={order_detail.buyer}
            />
            {order_detail.type === 'Product' && memoOrderProduct}
            {order_detail.type === 'Proposal' && memoOrderProposal}
            {parseInt(user_data.id) === parseInt(order_detail.seller.id)
              ? memoOrderChangeStatus
              : memoOrderRating}
          </BoxWhite>
        </div>
        <div className="col-md-5">
          <BoxWhite
            title={text_messages ? text_messages.toUpperCase() : 'MENSAJES'}
          >
            <ChatMessage
              type="order"
              send_user={send_user}
              cod_type={order_detail.id}
              subject={`${subjectTitle} ${order_detail.reference}`}
            />
          </BoxWhite>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ orderReducer, userReducer, siteReducer }) => {
  return {
    orderReducer,
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  orderClearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailView);
