import React from "react";
import HistorialItem from "./HistorialItem";
import "./css/HistorialList.css";
import { connect } from "react-redux";

function HistorialList(props) {
  const {
    siteReducer: {
      site_texts: { text_date, text_concept, text_type, text_price },
    },
  } = props;

  return (
    <div className="historialItem_content">
      <div className="table-responsive-md">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th className="color-purple text-center">
                {text_date ? text_date : "Fecha"}
              </th>
              <th className="color-purple text-center">
                {text_concept ? text_concept : "Concepto"}
              </th>
              <th className="color-purple text-center">
                {text_type ? text_type : "Tipo"}
              </th>
              <th className="color-purple text-center">
                {text_price ? text_price : "Valor"}
              </th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((item) => {
              return <HistorialItem data={item} key={item.id} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(HistorialList);
