import React from 'react';
import './css/ModalSocial.css';

function ModalSocial(props) {
  const { children } = props;

  return <div className="ModalSocial_container">{children}</div>;
}

export default ModalSocial;
