import React from 'react';
import Spinner from './Spinner';
import './css/Loading.css';

function Loading(props) {
  const { title, backgroundClass, spinnerColor } = props;

  return (
    <div className={`loading_container ${backgroundClass}`}>
      <Spinner type={spinnerColor} />
      {title && <p>{title}</p>}
    </div>
  );
}

Loading.defaultProps = {
  backgroundClass: 'loading_white',
  spinnerColor: 'purple',
};

export default Loading;
