import {
  CROPPER_VISIBLE,
  CROPPER_CROP_IMAGE,
  CROPPER_CLEAR,
} from "../types/cropperTypes";

const INITIAL_STATE = {
  cropper_visible: "",
  cropper_image: "",
  cropper_ratio_width: 1,
  cropper_ratio_height: 1,
};

const cropperReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CROPPER_VISIBLE:
      return {
        ...state,
        cropper_visible: action.payload,
      };
    case CROPPER_CROP_IMAGE:
      return {
        ...state,
        cropper_visible: "visible",
        cropper_image: action.payload.cropper_image,
        cropper_ratio_width: action.payload.cropper_ratio_width,
        cropper_ratio_height: action.payload.cropper_ratio_height,
      };
    case CROPPER_CLEAR:
      return {
        ...state,
        cropper_visible: "",
        cropper_image: "",
        cropper_ratio_width: 1,
        cropper_ratio_height: 1,
      };
    default:
      return { ...state };
  }
};

export default cropperReducer;
