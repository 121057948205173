import Swal from 'sweetalert2';
import Api from '../Api';
import {
  CAMPAIGN_BRAND_ID,
  CAMPAIGN_USER_ID,
  CAMPAIGN_STATUS_ID,
  CAMPAIGN_CONSECUTIVE,
  CAMPAIGN_NAME,
  CAMPAING_LINK,
  CAMPAIGN_DATE_START,
  CAMPAIGN_DATE_END,
  CAMPAIGN_DESCRIPTION,
  CAMPAIGN_PHRASE,
  CAMPAIGN_DELIVERABLES,
  CAMPAIGN_HASHTAGS,
  CAMPAIGN_HASHTAGS_TEXT,
  CAMPAIGN_TARGET_GENDER,
  CAMPAIGN_TARGET_DESCRIPTION,
  CAMPAIGN_TARGET_MIN_AGE,
  CAMPAIGN_TARGET_MAX_AGE,
  CAMPAIGN_INFLUENCER_RATING,
  CAMPAIGN_INFLUENCER_GENDER,
  CAMPAIGN_MIN_AGE_RANGE,
  CAMPAIGN_MAX_AGE_RANGE,
  CAMPAIGN_MIN_FOLLOWERS_RANGE,
  CAMPAIGN_MAX_FOLLOWERS_RANGE,
  CAMPAIGN_MIN_LIKES_RANGE,
  CAMPAIGN_MAX_LIKES_RANGE,
  CAMPAIGN_MIN_VIEWS_RANGE,
  CAMPAIGN_MAX_VIEWS_RANGE,
  CAMPAIGN_IMAGES,
  CAMPAIGN_CITIES,
  CAMPAIGN_COUNTRY,
  CAMPAIGN_SOCIAL_NETWORKS,
  CAMPAIGN_TAGS,
  CAMPAIGN_OBJECTIVES,
  CAMPAIGNS_LIST_CREATED,
  CAMPAIGN_LIST_LOADING,
  CAMPAIGN_TOTAL_LIST,
  CAMPAIGN_PAGE,
  CAMPAIGN_TOTAL_PAGES,
  CAMPAIGN_CLEAR,
  CAMPAIGN_CURRENT_DETAIL,
  CAMPAIGN_CURRENT_LOADING,
  SET_STATUS_EDIT,
  INFLUENCER_IN_CAMPAIGN,
  INFLUENCER_IN_CAMPAIGN_LOADING,
  INFLUENCER_CAMPAIGN_TOTAL_LIST,
  INFLUENCER_CAMPAIGN_PAGE,
  INFLUENCER_CAMPAIGN_TOTAL_PAGES,
  CAMPAIGN_STATISTICS_INFLUENCERS,
  CAMPAIGN_STATISTICS_PROPOSALS,
  CAMPAIGN_STATISTICS_POSTS,
  CAMPAIGN_STATISTICS_LAST_POSTS,
} from '../types/campaignTypes';

const messageSwal = Swal.mixin({
  customClass: {
    confirmButton: 'button__confirm',
  },
  buttonsStyling: false,
});

export const campaignClear = () => (dispatch) => {
  dispatch({
    type: CAMPAIGN_CLEAR,
  });
};

export const setInfluencerInCampaign = (value) => (dispatch) => {
  dispatch({
    type: INFLUENCER_IN_CAMPAIGN,
    payload: value,
  });
};

export const setInfluencerInCampaignLoading = (value) => (dispatch) => {
  dispatch({
    type: INFLUENCER_IN_CAMPAIGN_LOADING,
    payload: value,
  });
};

export const setCampaignBrandId = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_BRAND_ID,
    payload: parseFloat(value),
  });

export const setCampaignUserId = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_USER_ID,
    payload: value,
  });

export const setCampaignStatusId = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_STATUS_ID,
    payload: value,
  });

export const setCampaignConsecutive = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_CONSECUTIVE,
    payload: value,
  });

export const setCampaignName = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_NAME,
    payload: value,
  });
};

export const setCampaignLink = (value) => (dispatch) =>
  dispatch({
    type: CAMPAING_LINK,
    payload: value,
  });

export const setCampaignDateStart = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_DATE_START,
    payload: value,
  });

export const setCampaignDateEnd = (value) => (dispatch, getState) => {
  const { campaign_date_start } = getState().campaignReducer;
  const {
    site_texts: { text_error_enter_date_after },
  } = getState().siteReducer;

  if (campaign_date_start > value) {
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `${
        text_error_enter_date_after || 'Debes ingresar una fecha posterior a'
      } ${campaign_date_start}`,
      type: 'error',
      confirmButtonColor: '#50318B',
    });
  } else {
    dispatch({
      type: CAMPAIGN_DATE_END,
      payload: value,
    });
  }
};

export const setCampaignDescription = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_DESCRIPTION,
    payload: value,
  });

export const setCampaignPhrase = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_PHRASE,
    payload: value,
  });

export const setCampaignDeliverables = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_DELIVERABLES,
    payload: value,
  });
};

export const setCampaignHashtags = (value) => (dispatch) => {
  /* `const newHashtags = value.split(',').map((hashtag) => hashtag.trim());` is splitting the `value`
  string into an array of substrings using the comma as a separator, and then mapping over each
  substring to remove any leading or trailing whitespace using the `trim()` method. This creates a
  new array of cleaned up hashtags, which is then used as the payload for the `CAMPAIGN_HASHTAGS`
  action. */

  const newHashtags = value.split(',').map((hashtag) => hashtag.trim());

  dispatch({
    type: CAMPAIGN_HASHTAGS,
    payload: newHashtags,
  });
  //todo revisar segundo dispatch
  dispatch({
    type: CAMPAIGN_HASHTAGS_TEXT,
    payload: value,
  });
};

export const setCampaignTargetGender = (value) => (dispatch, getState) => {
  const currentValues = getState().campaignReducer.campaign_target_gender;
  const newValue = parseFloat(value);
  let newValues;

  if (currentValues.includes(newValue)) {
    newValues = currentValues.filter((val) => val !== newValue);
  } else {
    newValues = [...currentValues, newValue];
  }

  dispatch({
    type: CAMPAIGN_TARGET_GENDER,
    payload: newValues,
  });
};

export const setCampaignTargetDescription = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_TARGET_DESCRIPTION,
    payload: value,
  });

export const setCampaignTargetMinAge = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_TARGET_MIN_AGE,
    payload: value,
  });
};

export const setCampaignTargetMaxAge = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_TARGET_MAX_AGE,
    payload: value,
  });
};

export const setCampaignInfluencerRating = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_INFLUENCER_RATING,
    payload: parseFloat(value),
  });
};

export const setCampaignInfluencerGender = (value) => (dispatch, getState) => {
  const currentValues = getState().campaignReducer.campaign_influencer_gender;
  const newValue = parseFloat(value);
  let newValues;
  if (currentValues.includes(newValue)) {
    newValues = currentValues.filter((val) => val !== newValue);
  } else {
    newValues = [...currentValues, newValue];
  }
  dispatch({
    type: CAMPAIGN_INFLUENCER_GENDER,
    payload: newValues,
  });
};

export const setCampaignMinAgeRange = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_MIN_AGE_RANGE,
    payload: value,
  });
};

export const setCampaignMaxAgeRange = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_MAX_AGE_RANGE,
    payload: value,
  });
};

export const setCampaignMinFollowersRange = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_MIN_FOLLOWERS_RANGE,
    payload: value,
  });

export const setCampaignMaxFollowersRange = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_MAX_FOLLOWERS_RANGE,
    payload: value,
  });

export const setCampaignMinLikesRange = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_MIN_LIKES_RANGE,
    payload: value,
  });

export const setCampaignMaxLikesRange = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_MAX_LIKES_RANGE,
    payload: value,
  });

export const setCampaignMinViewsRange = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_MIN_VIEWS_RANGE,
    payload: value,
  });

export const setCampaignMaxViewsRange = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_MAX_VIEWS_RANGE,
    payload: value,
  });
};

export const deleteCampaignImage = (id) => async (dispatch, getState) => {
  const { campaign_consecutive } = getState().campaignReducer;
  const route = `v1/campaigns/${campaign_consecutive}/images/${id}`;

  try {
    const response = await Api.asyncCallMethod(route, 'DELETE');
    if (response && response.response.data) {
      dispatch(setCampaignImages(response.response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const setCampaignImages = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_IMAGES,
    payload: value,
  });

export const addCityToCampaign = (value) => (dispatch, getState) => {
  const currentCities = getState().campaignReducer.campaign_cities;

  if (!currentCities.some((city) => city.id === value.id)) {
    const updatedCities = [...currentCities, value];
    dispatch(setCampaignCities(updatedCities));
  }
};

export const removeCityToCampaign = (value) => (dispatch, getState) => {
  const currentCities = getState().campaignReducer.campaign_cities;
  const updatedCities = currentCities.filter((city) => city.id !== value);
  dispatch(setCampaignCities(updatedCities));
};

export const setCampaignCities = (value) => (dispatch, getState) => {
  dispatch({
    type: CAMPAIGN_CITIES,
    payload: value,
  });
};

export const addCountryToCampaign = (value) => (dispatch, getState) => {
  const currentCountries = getState().campaignReducer.campaign_country;

  if (!currentCountries.some((country) => country.id === value.id)) {
    const updatedCountries = [...currentCountries, value];
    dispatch(setCampaignCountry(updatedCountries));
  }
};

export const removeCountryToCampaign = (value) => (dispatch, getState) => {
  const currentCountries = getState().campaignReducer.campaign_country;
  const updatedCountries = currentCountries.filter(
    (country) => country.id !== value
  );
  dispatch(setCampaignCountry(updatedCountries));
};

export const setCampaignCountry = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_COUNTRY,
    payload: value,
  });
};

export const setCampaignSocialNetworks = (value) => (dispatch, getState) => {
  const currentValues = getState().campaignReducer.campaign_social_networks;
  const newValue = parseFloat(value);
  let newValues;

  if (currentValues.includes(newValue)) {
    newValues = currentValues.filter((val) => val !== newValue);
  } else {
    newValues = [...currentValues, newValue];
  }
  dispatch({
    type: CAMPAIGN_SOCIAL_NETWORKS,
    payload: newValues,
  });
};

export const addOrRemoveTags = (tags, addTag) => {
  if (!tags) {
    tags = [];
  }
  const isAdded = tags.find((tag) => tag.id === addTag.id);
  if (isAdded) {
    tags = tags.filter((tag) => tag.id !== addTag.id);
  } else {
    tags.push(addTag);
  }
  return tags;
};

export const addOrRemoveTagsCampaign = (value) => (dispatch, getState) => {
  const { campaign_tags } = getState().campaignReducer;
  let tags = addOrRemoveTags(campaign_tags, value);
  dispatch(setCampaignTags(tags));
};

export const setCampaignTags = (value) => (dispatch) =>
  dispatch({
    type: CAMPAIGN_TAGS,
    payload: value,
  });

export const addOrRemoveCampaignObjective = (value) => (dispatch, getState) => {
  const currentValues = getState().campaignReducer.campaign_objectives;
  let updatedValues;

  if (!currentValues.some((objective) => objective.id === value?.id)) {
    updatedValues = [...currentValues, value];
  } else {
    updatedValues = currentValues.filter(
      (objective) => objective.id !== value?.id
    );
  }
  dispatch(setCampaignObjectives(updatedValues));
};

export const setCampaignObjectives = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_OBJECTIVES,
    payload: value,
  });
};

export const setCampaignListCreated = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGNS_LIST_CREATED,
    payload: value,
  });
};

export const setCampaignCurrentDetail = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_CURRENT_DETAIL,
    payload: value,
  });
};

export const setCampaignCurrentLoading = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_CURRENT_LOADING,
    payload: value,
  });
};
export const setCampaignListLoading = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_LIST_LOADING,
    payload: value,
  });
};

export const setStatusEdit = () => (dispatch, getState) => {
  dispatch(setCampaignCurrentLoading(true));

  const { campaign_current_detail } = getState().campaignReducer;
  dispatch({
    type: SET_STATUS_EDIT,
    payload: campaign_current_detail,
  });
  dispatch(setCampaignCurrentLoading(false));
};

export const setCampaignStatisticsInfluencers = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_STATISTICS_INFLUENCERS,
    payload: value,
  });
};
export const setCampaignStatisticsProposals = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_STATISTICS_PROPOSALS,
    payload: value,
  });
};
export const setCampaignStatisticsPosts = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_STATISTICS_POSTS,
    payload: value,
  });
};
export const setCampaignStatisticsLastPosts = (value) => (dispatch) => {
  dispatch({
    type: CAMPAIGN_STATISTICS_LAST_POSTS,
    payload: value,
  });
};

export const getStatistics = (campaignConsecutive) => async (dispatch) => {
  dispatch(setCampaignCurrentLoading(true));
  const route = `v1/campaigns/${campaignConsecutive}/statistics`;

  try {
    const response = await Api.asyncCallMethod(route, 'GET');

    const {
      data: { influencers, last_posts, posts, proposals },
    } = response.response;

    dispatch(setCampaignStatisticsInfluencers(influencers));
    dispatch(setCampaignStatisticsProposals(proposals));
    dispatch(setCampaignStatisticsPosts(posts));
    dispatch(setCampaignStatisticsLastPosts(last_posts.data));
    dispatch(setCampaignCurrentLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setCampaignCurrentLoading(false));
  }
};

export const onSaveStep1 = () => async (dispatch, getState) => {
  const {
    campaign_hashtags,
    campaign_consecutive,
    campaign_name,
    campaign_description,
    campaign_phrase,
    campaign_deliverables,
    campaign_date_start,
    campaign_date_end,
    campaign_brand_id,
    campaign_link,
  } = getState().campaignReducer;
  const method = !campaign_consecutive ? 'POST' : 'PUT';
  const route = !campaign_consecutive
    ? 'v1/campaigns'
    : `v1/campaigns/${campaign_consecutive}`;
  const errorType = !campaign_consecutive ? 'Actualizar' : 'Crear';
  const data = {
    brand_id: campaign_brand_id,
    name: campaign_name,
    hashtags: campaign_hashtags,
    description: campaign_description,
    phrase: campaign_phrase,
    deliverables: campaign_deliverables,
    date_start: campaign_date_start,
    date_end: campaign_date_end,
    link: campaign_link,
  };

  try {
    const response = await Api.asyncCallMethod(`${route}`, `${method}`, data);
    dispatch(setCampaignConsecutive(response.response.data.consecutive));
  } catch (error) {
    console.error('Error creating the campaign', error);
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `Error al ${errorType} la campaña`,
      type: 'error',
      confirmButtonColor: '#50318B',
    });
  }
};

export const onSaveStep2 = () => async (dispatch, getState) => {
  const {
    site_texts: { text_warning_choose_location },
  } = getState().siteReducer;

  const {
    campaign_consecutive,
    campaign_target_gender,
    campaign_target_max_age,
    campaign_target_min_age,
    campaign_social_networks,
    campaign_target_description,
    campaign_cities,
    campaign_country,
    campaign_name,
    campaign_description,
    campaign_phrase,
    campaign_deliverables,
    campaign_date_start,
    campaign_date_end,
    campaign_brand_id,
  } = getState().campaignReducer;
  const method = 'PUT';
  const route = `v1/campaigns/${campaign_consecutive}`;

  if (campaign_country.length === 0 && campaign_cities.length === 0) {
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `${
        text_warning_choose_location || 'Debes de elegir una ciudad o un pais'
      }`,
      type: 'warning',
      confirmButtonColor: '#50318B',
    });
    return false;
  }

  const data = {
    brand_id: campaign_brand_id,
    name: campaign_name,
    date_start: campaign_date_start,
    date_end: campaign_date_end,
    description: campaign_description,
    phrase: campaign_phrase,
    deliverables: campaign_deliverables,
    target_gender: campaign_target_gender,
    target_age_range: [campaign_target_min_age, campaign_target_max_age],
    social_networks: campaign_social_networks,
    target_description: campaign_target_description,
  };

  if (campaign_country.length > 0) {
    data.countries = campaign_country;
  }

  if (campaign_cities.length > 0) {
    data.cities = campaign_cities;
  }
  try {
    const response = await Api.asyncCallMethod(route, method, data);

    if (response.status === 200) {
    } else {
      console.error('the request was rejected: ', response.response.error);
    }
    return true;
  } catch (error) {
    console.error('Error creating the campaign', error);
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `Error al actualizar la campaña`,
      type: 'error',
      confirmButtonColor: '#50318B',
    });
  }
};

export const onSaveStep3 = () => async (dispatch, getState) => {
  const {
    campaign_consecutive,
    campaign_name,
    campaign_description,
    campaign_phrase,
    campaign_deliverables,
    campaign_date_start,
    campaign_date_end,
    campaign_brand_id,
    campaign_tags,
  } = getState().campaignReducer;
  const method = 'PUT';
  const route = `v1/campaigns/${campaign_consecutive}`;

  const data = {
    brand_id: campaign_brand_id,
    name: campaign_name,
    date_start: campaign_date_start,
    date_end: campaign_date_end,
    description: campaign_description,
    phrase: campaign_phrase,
    deliverables: campaign_deliverables,
    tags: campaign_tags,
  };

  try {
    const response = await Api.asyncCallMethod(`${route}`, `${method}`, data);

    if (response.status === 200) {
    } else {
      console.error('the request was rejected: ', response.response.error);
    }
  } catch (error) {
    console.error('Error creating the campaign', error);
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `Error al actualizar la campaña`,
      type: 'error',
      confirmButtonColor: '#50318B',
    });
  }
};

export const onSaveStep4 = () => async (dispatch, getState) => {
  const {
    campaign_consecutive,
    campaign_name,
    campaign_description,
    campaign_phrase,
    campaign_deliverables,
    campaign_date_start,
    campaign_date_end,
    campaign_brand_id,
    campaign_min_age_range,
    campaign_max_age_range,
    campaign_min_followers_range,
    campaign_max_followers_range,
    campaign_min_likes_range,
    campaign_max_likes_range,
    campaign_min_views_range,
    campaign_max_views_range,
    campaign_influencer_gender,
    campaign_influencer_rating,
    campaign_objectives,
  } = getState().campaignReducer;
  const method = 'PUT';
  const route = `v1/campaigns/${campaign_consecutive}`;

  const data = {
    brand_id: campaign_brand_id,
    name: campaign_name,
    date_start: campaign_date_start,
    date_end: campaign_date_end,
    description: campaign_description,
    phrase: campaign_phrase,
    deliverables: campaign_deliverables,
    age_range: [campaign_min_age_range, campaign_max_age_range],
    followers_range: [
      campaign_min_followers_range,
      campaign_max_followers_range,
    ],
    likes_range: [campaign_min_likes_range, campaign_max_likes_range],
    views_range: [campaign_min_views_range, campaign_max_views_range],
    influencer_gender: campaign_influencer_gender,
    influencer_rating: campaign_influencer_rating,
    objectives: campaign_objectives,
  };

  try {
    const response = await Api.asyncCallMethod(`${route}`, `${method}`, data);

    if (response.status === 200) {
    } else {
      console.error('the request was rejected: ', response.response.error);
    }
  } catch (error) {
    console.error('Error creating the campaign', error);
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `Error al actualizar la campaña`,
      type: 'error',
      confirmButtonColor: '#50318B',
    });
  }
};

export const getCampaignList = (search) => async (dispatch) => {
  dispatch(setCampaignListLoading(true));
  const route = search ? `v1/campaigns${search}` : `v1/campaigns`;
  try {
    const response = await Api.asyncCallMethod(route, 'GET');
    const {
      meta: { last_page, total, current_page },
    } = response?.response;
    dispatch({
      type: CAMPAIGN_TOTAL_PAGES,
      payload: last_page,
    });
    dispatch({
      type: CAMPAIGN_TOTAL_LIST,
      payload: total,
    });
    dispatch({
      type: CAMPAIGN_PAGE,
      payload: current_page,
    });
    dispatch(setCampaignListCreated(response.response.data));
    dispatch(setCampaignListLoading(false));
  } catch (error) {
    console.error('error get campaigns list', error);
    dispatch(setCampaignListLoading(false));
  }
};

// !DEPRECATED!!
// export const getCampaignByStatus = (status) => async (dispatch) => {
//   dispatch(setCampaignListLoading(true));

//   const route = `v1/campaigns?status=${status}`;
//   try {
//     const response = await Api.asyncCallMethod(route, 'GET');
//     const {
//       meta: { last_page, total, current_page },
//     } = response.response;
//     dispatch({
//       type: CAMPAIGN_TOTAL_PAGES,
//       payload: last_page,
//     });
//     dispatch({
//       type: CAMPAIGN_TOTAL_LIST,
//       payload: total,
//     });
//     dispatch({
//       type: CAMPAIGN_PAGE,
//       payload: current_page,
//     });
//     dispatch(setCampaignListCreated(response.response.data));
//     dispatch(setCampaignListLoading(false));
//   } catch (error) {
//     console.error('error get campaigns list', error);
//     dispatch(setCampaignListLoading(false));
//   }
// };

export const getCampaignDetail = (consecutive) => async (dispatch) => {
  dispatch(setCampaignCurrentLoading(true));
  const route = `v1/campaigns/${consecutive}`;
  try {
    const response = await Api.asyncCallMethod(route, 'GET');
    dispatch(setCampaignCurrentDetail(response.response.data));
    dispatch(setCampaignCurrentLoading(false));
  } catch (error) {
    console.error('error getCampaignDetail', error);
    dispatch(setCampaignCurrentLoading(false));
  }
};

export const getCampaignToEdit = (consecutive) => async (dispatch) => {
  const route = `v1/campaigns/${consecutive}/edit`;
  try {
    dispatch(setCampaignCurrentLoading(true));
    const response = await Api.asyncCallMethod(route, 'GET');
    dispatch(setCampaignCurrentDetail(response.response.data));
  } catch (error) {
    console.error('error getCampaignDetail', error);
    dispatch(setCampaignCurrentLoading(false));
  }
};

export const publishBreif = (consecutive) => async () => {
  const route = `v1/campaigns/${consecutive}/publish`;
  try {
    const response = await Api.asyncCallMethod(route, 'POST');
    return response;
  } catch (error) {
    console.error('error publishBreif', error);
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `Error al publicar la campaña`,
      type: 'error',
      confirmButtonColor: '#50318B',
    });
  }
};

export const changeStatus = (consecutive, statusId) => async () => {
  const route = `v1/campaigns/${consecutive}/change-status`;
  const data = {
    campaign_status_id: parseFloat(statusId),
  };

  try {
    await Api.asyncCallMethod(route, 'POST', data);
  } catch (error) {
    console.error('error changeStatus', error);
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `Error al cambiar el status de campaña`,
      type: 'error',
      confirmButtonColor: '#50318B',
    });
  }
};

export const getInfluencersInCampaign =
  (consecutive, search) => async (dispatch) => {
    dispatch(setInfluencerInCampaignLoading(true));

    const route = `v1/campaigns/${consecutive}/influencers${search}`;

    try {
      const response = await Api.asyncCallMethod(route, 'GET');
      const {
        meta: { last_page, total, current_page },
      } = response.response;
      dispatch({
        type: INFLUENCER_CAMPAIGN_TOTAL_PAGES,
        payload: last_page,
      });
      dispatch({
        type: INFLUENCER_CAMPAIGN_TOTAL_LIST,
        payload: total,
      });
      dispatch({
        type: INFLUENCER_CAMPAIGN_PAGE,
        payload: current_page,
      });
      dispatch({
        type: INFLUENCER_IN_CAMPAIGN,
        payload: response.response.data,
      });
      dispatch(setInfluencerInCampaignLoading(false));
    } catch (error) {
      console.error('error getInfluencersInCampaign', error);
      dispatch(setInfluencerInCampaignLoading(false));
    }
  };

export const AcceptOrRejectCampaign =
  (campaignConsecutive, statusId) => async (dispatch, getState) => {
    dispatch(setInfluencerInCampaignLoading(true));

    const {
      site_texts: {
        text_success_accepted_campaign,
        text_success_rejected_campaign,
        text_sorry,
      },
    } = getState().siteReducer;

    const route = `v1/influencers/campaigns/${campaignConsecutive}/change-status`;

    const data = {
      status_id: statusId,
    };
    const acceptOrReject =
      statusId === 2
        ? `${
            text_success_accepted_campaign ||
            'Campaña aceptada satisfactoriamente'
          }`
        : `${text_success_rejected_campaign || 'Campaña rechazada'}`;

    try {
      const response = await Api.asyncCallMethod(route, 'POST', data);
      if (response.status === 200) {
        messageSwal.fire({
          title: acceptOrReject,
          type: 'success',
        });
        return response;
      } else {
        messageSwal.fire({
          title: 'ERROR',
          text: `${text_sorry || 'Ha ocurrido un error, intentalo más tarde.'}`,
          type: 'error',
        });
      }
    } catch (error) {
      console.error('error in AcceptOrReject', error);
    }
  };

export const sentToInfluencer =
  (campaignConsecutive, userName) => async (dispatch, getState) => {
    const route = `v1/campaigns/${campaignConsecutive}/send-to/${userName}`;
    const {
      site_texts: { text_request_sent_to, text_sorry },
    } = getState().siteReducer;

    try {
      const response = await Api.asyncCallMethod(route, 'POST');
      if (response.status === 200) {
        messageSwal.fire({
          title: `${
            text_request_sent_to || 'Solicitud enviada a:'
          } @${userName}`,
          type: 'success',
        });
      } else {
        messageSwal.fire({
          title: 'ERROR',
          text: `${text_sorry || 'Ha ocurrido un error, intentalo más tarde.'}`,
          type: 'error',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
