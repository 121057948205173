import React from "react";
import "./css/InboxChats.css";
import ChatList from "./ChatList";
import { connect } from "react-redux";

function InboxChats(props) {
  const {
    siteReducer: {
      site_texts: { text_messages, text_all_message, text_not_read },
    },
  } = props;

  return (
    <div className="inboxChats_container">
      <div className="inboxChats_container-title">
        <div className="inboxChats_title">
          {text_messages ? text_messages : "Mensajes"}
        </div>
        <div className="inboxChats_menu">
          <button
            className="inboxChats_all"
            onClick={() => props.handleFilter(0)}
          >
            {text_all_message ? text_all_message : "Todos"}
          </button>
          <button
            className="inboxChats_no-read"
            onClick={() => props.handleFilter(1)}
          >
            {text_not_read ? text_not_read : "No Leídos"}
          </button>
        </div>
      </div>
      <div className="inboxChats_content">
        <ChatList
          data={props.data}
          actions={props.actions}
          notifications={props.notifications}
          handleViewMessage={props.handleViewMessage}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(InboxChats);
