import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PorposeChat from '../../../../../../components/user/manager/PorposeChat/PorposeChat.jsx';
import {
  getCampaignPorposals,
  porposalClear,
  getPorposalDetail,
  changePorposalStatus,
} from '../../../../../../functionality/actions/porposalActions.js';
import Porposal from '../../../../../../components/all/Porposal/Porposal.jsx';
import Spinner from '../../../../../../components/all/Spinner.js';
import Swal from 'sweetalert2';
import ChatMessage from '../../../../../../components/user/manager/ChatMessage.js';
import './CampaignInbox.css';
import BoxWhite from '../../../../../../components/user/marketplace/BoxWhite.js';

const CampaignInboxView = (props) => {
  const {
    porposalReducer: {
      porposal_campaigns,
      porposal_campaigns_accepted,
      current_porposal,
      porposal_loading,
      porposal_loading_detail,
      proposal_type,
    },
    userReducer: { user_data },
    match: { params },
    match: {
      params: { id: campaign_consecutive },
    },
    getCampaignPorposals,
    getPorposalDetail,
    porposalClear,
    changePorposalStatus,
    siteReducer: {
      site_texts: {
        text_accepted,
        text_inbox_campaign,
        text_proposals,
        text_accept_proposal,
        text_discard,
        text_are_you_sure,
        text_not_reverse_action,
      },
    },
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      await getPorposalDetail(params.porposalConsecutive);
    };

    fetchData();

    return () => {
      porposalClear();
    };
  }, [params.porposalConsecutive]);

  useEffect(() => {
    getCampaignPorposals(campaign_consecutive);
  }, []);

  if (!user_data) return null;

  const acceptOrRejectProposal = (event) => {
    const value = parseInt(event.target.value);

    const confirmButtonText =
      value === 4
        ? text_accept_proposal || 'Aceptar'
        : text_discard || 'Descartar';

    const swalStyled = Swal.mixin({
      customClass: {
        confirmButton: 'button__confirm',
        cancelButton: 'button__cancel',
      },
      buttonsStyling: false,
    });

    swalStyled
      .fire({
        title: text_are_you_sure || '¿Estás seguro?',
        text: text_not_reverse_action || 'Tú no podrás revertir esta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28cae4',
        cancelButtonColor: '#50318b',
        confirmButtonText: confirmButtonText,
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value === true) {
          await changePorposalStatus(params.porposalConsecutive, value);
          getCampaignPorposals(campaign_consecutive);
          getPorposalDetail(params.porposalConsecutive);
        }
      });
  };
  // console.log('status4', current_porposal.proposal_status_id !== 4);
  // console.log('status5', current_porposal.proposal_status_id === 5);
  // console.log(
  //   current_porposal.proposal_status_id !== 4 ||
  //     current_porposal.proposal_status_id === 5
  // );

  return (
    <>
      <h3 className="title__option">
        {text_inbox_campaign || 'Inbox Campaña'}
      </h3>
      <div className="row campaign__inbox__content">
        <div className="col-md-4 inbox__chats">
          {user_data.user_type_id !== 8 && (
            <PorposeChat
              chats={porposal_campaigns}
              path={`/manager/campaign/${campaign_consecutive}/inbox-porpose/`}
              //   loading={porposal_loading}
              inboxChat={false}
              title={text_proposals || 'Propuestas'}
            />
          )}
          <PorposeChat
            chats={porposal_campaigns_accepted}
            path={`/manager/campaign/${campaign_consecutive}/inbox-porpose/`}
            // loading={porposal_loading}
            inboxChat={false}
            title={text_accepted || 'Aceptadas'}
          />
        </div>
        <div className="col-md-8">
          {porposal_loading ? (
            <BoxWhite>
              <div className="spinner-loading">
                <Spinner type="purple" />
              </div>
            </BoxWhite>
          ) : (
            <>
              {current_porposal &&
                (proposal_type === 'Detail' ||
                  !current_porposal.chat_reference) && (
                  <BoxWhite>
                    <Porposal
                      loading={porposal_loading}
                      data={current_porposal}
                    />
                  </BoxWhite>
                )}
              {!porposal_loading &&
                proposal_type === 'Chat' &&
                current_porposal &&
                current_porposal.chat_reference && (
                  <ChatMessage cod_chat={current_porposal.chat_reference} />
                )}

              {current_porposal &&
                ![4, 5].includes(current_porposal.proposal_status_id) && (
                  <>
                    <div className="separator"></div>
                    <div className="answers">
                      <div className="answers__buttons">
                        <button
                          value={6}
                          onClick={(event) => {
                            acceptOrRejectProposal(event);
                          }}
                        >
                          {text_discard || 'Descartar'}
                        </button>
                        <button
                          value={4}
                          onClick={(event) => {
                            acceptOrRejectProposal(event);
                          }}
                        >
                          {text_accept_proposal || 'Aceptar propuesta'}
                        </button>
                      </div>
                    </div>
                  </>
                )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ porposalReducer, userReducer, siteReducer }) => {
  return {
    porposalReducer,
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  getCampaignPorposals,
  getPorposalDetail,
  porposalClear,
  changePorposalStatus,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignInboxView)
);
