export const AD_LOADING = "AD_LOADING";
export const AD_LOADING_PER_MONTH = "AD_LOADING_PER_MONTH";
export const AD_LOADING_PER_DATE = "AD_LOADING_PER_DATE";
export const AD_MY_LIST_LOADING = "AD_MY_LIST_LOADING";
export const AD_MY_LIST = "AD_MY_LIST";
export const AD_DETAIL_LOADING = "AD_DETAIL_LOADING";
export const AD_DETAIL = "AD_DETAIL";
export const AD_LIST = "AD_LIST";
export const AD_LIST_MONTH = "AD_LIST_MONTH";
export const AD_LIST_DATE = "AD_LIST_DATE";
export const AD_IMAGE = "AD_IMAGE";
export const AD_MOBILE_IMAGE = "AD_MOBILE_IMAGE";
export const AD_DATE = "AD_DATE";
export const AD_SPACE_ID = "AD_SPACE_ID";
export const AD_PLAN_ID = "AD_PLAN_ID";
export const AD_NAME = "AD_NAME";
export const AD_CATEGORY_ID = "AD_CATEGORY_ID";
export const AD_RESOURCE_ID = "AD_RESOURCE_ID";
export const AD_SENT = "AD_SENT";
export const AD_CLEAR = "AD_CLEAR";
export const AD_LOADING_RESOURCE = "AD_LOADING_RESOURCE";
export const AD_PRODUCTS = "AD_PRODUCTS";
export const AD_SERVICES = "AD_SERVICES";
export const AD_SETTINGS_LOADING = "AD_SETTINGS_LOADING";
export const AD_SETTINGS = "AD_SETTINGS";
export const AD_SELECT_DATES = "AD_SELECT_DATES";
export const AD_DATA = "AD_DATA";
export const AD_LIST_VIEW = "AD_LIST_VIEW";
