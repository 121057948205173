import Api from '../Api';
import {
  SOCIAL_NETWORKS,
  SOCIAL_NETWORKS_FILTER,
  SOCIAL_NETWORKS_LOADING,
} from '../types/socialNetworksTypes';

export const setSocialNetworks = (value) => (dispatch) => {
  dispatch({
    type: SOCIAL_NETWORKS,
    payload: value,
  });
};

export const setSocialNetworksLoading = (value) => (dispatch) => {
  dispatch({
    type: SOCIAL_NETWORKS_LOADING,
    payload: value,
  });
};

export const getSocialNetworks = () => async (dispatch) => {
  dispatch(setSocialNetworksLoading(true));
  try {
    const response = await Api.asyncCallMethod('v1/social-networks', 'GET');
    dispatch(setSocialNetworks(response.response.data));
    dispatch(setSocialNetworksLoading(false));
  } catch (error) {
    console.error('Error GetSocialNetworks: ', error);
    dispatch(setSocialNetworksLoading(false));
  }
};

export const changeSocialNetworkFilter = (payload) => (dispatch, getState) => {
  payload = parseInt(payload);

  const { social_networks_filter } = getState().socialNetworksReducer;
  let itemsFilter = social_networks_filter;
  const isAdded = itemsFilter.find((item) => item === payload);

  if (!isAdded) {
    itemsFilter.push(payload);
  } else {
    itemsFilter = itemsFilter.filter((item) => item !== payload);
  }

  dispatch({
    type: SOCIAL_NETWORKS_FILTER,
    payload: itemsFilter,
  });
};
