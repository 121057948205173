import React from "react";
import { connect } from "react-redux";
import "./css/DashboardBalance.css";

// function viewBalance(init, transfered) {
// 	let balance = parseInt(init) - parseInt(transfered)
// 	return balance;
// }

function viewPercentage(init, transfered) {
  // let balance = viewBalance(init, transfered);
  let percentage = (transfered * 100) / parseInt(init);
  return percentage;
}

function DashboardBalance(props) {
  const {
    initial,
    transfered,
    siteReducer: {
      site_texts: { text_my_balance },
    },
  } = props;

  const numberFormat = new Intl.NumberFormat("es-CO");

  return (
    <div className="dashboardBalance_content">
      <div className="dashboardBalance_title">
        <div className="dashboardBalance_title-balance">
          {text_my_balance ? text_my_balance.toUpperCase() : "MI SALDO"}
        </div>
        <div className="dashboardBalance_number">
          BAX$ {numberFormat.format(transfered)}
        </div>
      </div>
      <div className="dashboardBalance_box-progress-bar">
        <div
          className="dashboardBalance_progress-bar"
          style={{ width: `${viewPercentage(initial, transfered)}%` }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(DashboardBalance);
