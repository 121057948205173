import React from "react";
import { connect } from "react-redux";
import { importProducts } from "../../../../functionality/actions/productActions";
import BoxWhite from "../../../../components/user/marketplace/BoxWhite";

const { REACT_APP_LANGUAGE: APP_LANGUAGE } = process.env;

function ProductImportView(props) {
  const {
    siteReducer: {
      site_texts: { text_import_products, text_product_import_file },
    },
    userReducer: { excel_spanish, excel_english },
    importProducts,
  } = props;

  return (
    <>
      <div className="title-manager">
        <div>
          <span className="icon-import-products" />
          {text_import_products ? text_import_products : "Importar Productos"}
        </div>
        <a
          href={APP_LANGUAGE === "es" ? excel_spanish : excel_english}
          className="btn productsView_new"
          target="_blank"
          rel="noreferrer"
        >
          {text_product_import_file
            ? text_product_import_file
            : "Descargar archivo de ejemplo"}
        </a>
      </div>
      <div className="container">
        <div className="row">
          <BoxWhite>
            <div className="row">
              <div className="col-md-8">
                <input
                  type="file"
                  id="products-import"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  className="form-control"
                />
              </div>
              <div className="col-md-4">
                <button
                  className="btn btn-block btn-bax"
                  onClick={importProducts}
                >
                  {text_import_products
                    ? text_import_products
                    : "Importar Productos"}
                </button>
              </div>
            </div>
          </BoxWhite>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ siteReducer, userReducer }) => {
  return {
    siteReducer,
    userReducer,
  };
};

const mapDispatchToProps = {
  importProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductImportView);
