import React, { useState } from 'react';
import CropImages from '../../../all/CropImages';
import InputFile from '../../../DocumentRegister/InputFile';
import { connect } from 'react-redux';

function SelectImages(props) {
  const [image, setImage] = useState('');
  const {
    changeAdImage,
    changeAdMobileImage,
    cropperImage,
    space,
    adImage,
    adMobileImage,
    siteReducer: {
      site_texts: {
        text_image_desktop,
        text_image_mobile,
        text_upload_image_desktop,
        text_upload_image_mobile,
      },
    },
  } = props;

  const handleChange = (e, width, height, image) => {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    setImage(image);

    const reader = new FileReader();
    reader.onload = () => {
      let config = {
        cropper_image: reader.result,
        cropper_ratio_width: width ? width : 1000,
        cropper_ratio_height: height ? height : 500,
      };
      cropperImage(config);
    };
    reader.readAsDataURL(files[0]);
  };

  const saveCrop = (file) => {
    if (image === 'desktop') {
      changeAdImage(file);
    } else if (image === 'mobile') {
      changeAdMobileImage(file);
    }
  };

  return (
    <>
      <CropImages onCrop={saveCrop} />
      <div className="row">
        <div className="col-md-6">
          <InputFile
            id="adDesktop"
            saveDoc={(file) =>
              handleChange(file, space.width, space.height, 'desktop')
            }
            value={adImage}
            label={text_image_desktop ? text_image_desktop : 'Imagen desktop'}
            accept=".jpg, .png, .jpeg"
            title={
              text_upload_image_desktop
                ? text_upload_image_desktop
                : 'Subir la imagen de desktop'
            }
            upload={false}
            description={`Imagen campaña para desktop, medidas mínimas: (${space.width}px por ${space.height}px)`}
            preview={true}
          />
        </div>
        <div className="col-md-6">
          <InputFile
            id="adMobile"
            saveDoc={(file) =>
              handleChange(
                file,
                space.width_mobile,
                space.height_mobile,
                'mobile'
              )
            }
            value={adMobileImage}
            label={text_image_mobile ? text_image_mobile : 'Imagen mobile'}
            accept=".jpg, .png, .jpeg"
            title={
              text_upload_image_mobile
                ? text_upload_image_mobile
                : 'Subir la imagen de mobile'
            }
            upload={false}
            description={`Imagen campaña para mobile, medidas mínimas: (${space.width_mobile}px por ${space.height_mobile}px)`}
            preview={true}
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(SelectImages);
