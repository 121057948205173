import React from 'react';

function Youtube(props) {
  const { data } = props;

  if (data.length === 0) return null;

  const {
    result: { items },
  } = data;

  return (
    <>
      {items.length !== 0 &&
        items.map((item) => (
          <div key={item.id.videoId} className="youtube_container item">
            <a
              href={`https://www.youtube.com/watch?v=${item.id.videoId}&ab_channel=${item.snippet.channelTitle}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-youtube" />
              <img src={item.snippet.thumbnails.high.url} alt="Video Youtube" />
            </a>
          </div>
        ))}
    </>
  );
}

export default Youtube;
