import React, { Component } from "react";
import { connect } from "react-redux";
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import "./css/PriceFilter.css";

import * as userActions from "../../../functionality/actions/userActions";

const { changeFilterPrice, filterPriceProduct } = userActions;

function Handle({ handle: { id, percent }, getHandleProps }) {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: "absolute",
        marginLeft: -15,
        marginTop: 0,
        zIndex: 2,
        width: 30,
        height: 30,
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "50%",
        border: "2px solid #ffffff",
        backgroundColor: "#DB308B",
        color: "#333",
      }}
      {...getHandleProps(id)}
    />
  );
}

function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 10,
        zIndex: 1,
        marginTop: 10,
        backgroundColor: "#DB308B",
        borderRadius: 5,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

class PriceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      min: props.user_price_range_selected[0],
      max: props.user_price_range_selected[1],
    };
  }

  handleChange = (e) => {
    let { min, max } = this.state;
    if (e.target.name === "min") {
      min = e.target.value !== "" ? parseInt(e.target.value) : 0;
    } else {
      max = e.target.value !== "" ? parseInt(e.target.value) : 0;
    }
    this.setState({ min, max });
    if (min && max) {
      this.props.changeFilterPrice([min, max]);
    }
  };

  applyFilter = () => {
    const {
      user_price_range_selected,
      list_type_all_products,
      filterPriceProduct,
      item_per_page,
    } = this.props;
    let filterPrice = list_type_all_products.filter(
      (item) =>
        item.price >= user_price_range_selected[0] &&
        item.price <= user_price_range_selected[1]
    );
    let filterViewPrice = filterPrice.slice(0, item_per_page);
    let data = {
      list_total: filterPrice,
      list_view: filterViewPrice,
    };
    filterPriceProduct(data);
  };

  changeFilter = (values) => {
    this.setState({
      min: values[0],
      max: values[1],
    });
    this.props.changeFilterPrice(values);
  };

  render() {
    const {
      siteReducer: {
        site_texts: { text_filter_price, text_apply_filter },
      },
    } = this.props;

    const sliderStyle = {
      position: "relative",
      width: "90%",
      height: 30,
      marginTop: 10,
      marginRight: "auto",
      marginBottom: 10,
      marginLeft: "auto",
    };

    const railStyle = {
      position: "absolute",
      width: "100%",
      height: 10,
      marginTop: 10,
      borderRadius: 5,
      backgroundColor: "#dfdee3",
    };
    return (
      <div className="priceFilter_container">
        <div className="priceFilter_title">
          {text_filter_price
            ? text_filter_price.toUpperCase()
            : "FILTRAR POR PRECIO"}
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div>Min</div>
            <div className="d-flex align-items-center">
              $
              <input
                type="number"
                value={this.state.min}
                name="min"
                className="form-control priceFilter_input"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="text-right">Max</div>
            <div className="d-flex align-items-center">
              $
              <input
                type="number"
                value={this.state.max}
                name="max"
                className="form-control priceFilter_input"
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
        <Slider
          mode={2}
          step={5000}
          rootStyle={sliderStyle}
          domain={this.props.user_price_range}
          values={this.props.user_price_range_selected}
          onUpdate={this.changeFilter}
        >
          <Rail>
            {({ getRailProps }) => (
              <div style={railStyle} {...getRailProps()} />
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="priceFilter_handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
        <div className="d-flex justify-content-end">
          <button
            className="btn priceFilter_apply"
            onClick={() => this.applyFilter()}
          >
            {text_apply_filter ? text_apply_filter : "Aplicar Filtro"}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    ...userReducer,
    siteReducer,
  };
};
const mapDispatchToProps = {
  changeFilterPrice,
  filterPriceProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceFilter);
