import {
  REGISTER_ALERT_RESPONSE,
  REGISTER_ALERT_VALIDATE,
  REGISTER_ALERT_VISIBLE,
} from '../types/registerAlertTypes';
import Swal from 'sweetalert2';
import Api from '../Api';

export const changeRegisterAlertValidate = (payload) => (dispatch) => {
  dispatch({
    type: REGISTER_ALERT_VALIDATE,
    payload,
  });
};

export const changeRegisterAlertVisible = (payload) => (dispatch) => {
  dispatch({
    type: REGISTER_ALERT_VISIBLE,
    payload,
  });
};

export const changeRegisterAlertResponse = (payload) => (dispatch) => {
  dispatch({
    type: REGISTER_ALERT_RESPONSE,
    payload,
  });
};

export const sendAlertResponse = () => (dispatch, getState) => {
  const { register_alert_response } = getState().registerAlertReducer;

  const {
    site_texts: {
      text_question_reason_not_register,
      text_success_see_you_later,
      text_thank_you,
    },
  } = getState().siteReducer;

  if (!register_alert_response.trim().length) {
    Swal.fire({
      title: 'Upsss!!!',
      text: `${
        text_question_reason_not_register ||
        'Escribe el motivo por el que no te registras.'
      }`,
      type: 'warning',
      confirmButtonColor: '#50318b',
    });
    return;
  }

  Api.callMethod(
    'v1/alert-register',
    'POST',
    { message: register_alert_response },
    () => {
      Swal.fire({
        title: `${text_thank_you || '¡Muchas gracias!'}`,
        text: `${
          text_success_see_you_later || 'Esperamos verte por aquí nuevamente.'
        }`,
        type: 'success',
        confirmButtonColor: '#50318b',
      });
      dispatch(changeRegisterAlertResponse(''));
    }
  );
};
