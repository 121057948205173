import React from 'react';
import './css/ProductItem.css';
// import ImageDefault from '../../../images/default-picture.jpg';
import ImageDefault from '../../../images/image-product.jpg';
import { Link } from 'react-router-dom';

function ProductItem(props) {
  let { data } = props;
  let firstImage = data.images.length > 0 ? data.images[0] : ImageDefault;
  return (
    <div className="col-md-6 col-lg-4">
      <div className="productItem_content">
        <Link
          to={`/manager/products/edit/${data.id}`}
          className="productItem_link"
        >
          <div className="productItem_title">{data.name}</div>
          <img
            src={firstImage.image}
            alt={firstImage.image}
            className="productItem_image"
          />
        </Link>
        {props.actions && (
          <div className="productItem_menu">
            <Link to={`/manager/products/edit/${data.id}`}>
              <span className="icon-edit" />
            </Link>
            <button>
              <span className="icon-remove" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductItem;
