import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Redirect } from "react-router";
import "./css/Search.css";

import * as searchActions from "../../functionality/actions/searchActions";

const MySwal = withReactContent(Swal);

class Search extends Component {
  showError = (message) => {
    MySwal.fire({
      title: "Upsss!!!",
      text: message,
      type: "warning",
      confirmButtonColor: "#50318B",
    });
  };

  changeDepartment = (value) => {
    const {
      userReducer: { cities },
      searchCities,
      searchChangeDepartment,
    } = this.props;
    let view_cities = [];
    if (value !== "") {
      view_cities = cities.filter(
        (item) => item.department_id === parseInt(value)
      );
    }
    searchChangeDepartment(value);
    searchCities(view_cities);
  };

  changeSearch = (search_type) => {
    this.setState({
      search_type,
    });
  };

  search = () => {
    const {
      searchUrl,
      searchReducer: {
        search_city,
        search_category,
        search_rating,
        search_name,
        search_type,
      },
      siteReducer: {
        site_texts: { text_search_error },
      },
    } = this.props;
    if (
      search_city !== "" ||
      search_category !== "" ||
      search_rating !== "" ||
      search_name !== ""
    ) {
      let route = `/search/type=${search_type}`;
      if (search_name !== "") {
        route += `&name=${search_name}`;
      }
      if (search_city !== "") {
        route += `&city=${search_city}`;
      }
      if (search_category !== "") {
        route += `&category=${search_category}`;
      }
      if (search_rating !== "") {
        route += `&rating=${search_rating}`;
      }
      searchUrl(route);
    } else {
      this.showError(
        text_search_error
          ? text_search_error
          : "Debes escribir el nombre de lo que quieres buscar o seleccionar una de las siguientes opciones: Ciudad, Categoría o Calificación."
      );
    }
  };

  render() {
    const {
      userReducer: { departments, categories },
      searchReducer: {
        search_city,
        search_category,
        search_rating,
        search_name,
        search_type,
        search_cities,
        search_department,
        search_redirect,
        search_url,
      },
      siteReducer: {
        site_language,
        site_texts: {
          text_products,
          text_services,
          text_companies,
          text_how_search,
          text_by_name,
          text_state_capitalize,
          text_city_capitalize,
          text_category,
          text_qualification,
          text_close,
          text_go_search,
        },
      },
      searchChangeCity,
      searchChangeCategory,
      searchChangeRating,
      searchChangeName,
      searchChangeType,
    } = this.props;
    let statusSearch = this.props.statusSearch ? "active" : "";
    let activeCompany = search_type === "company" ? "active" : "";
    let activeService = search_type === "service" ? "active" : "";
    let activeProduct = search_type === "product" ? "active" : "";
    if (this.props.statusSearch) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return ReactDOM.createPortal(
      <div className={`search_container ${statusSearch}`}>
        {search_redirect && <Redirect to={search_url} />}
        <div className={`search_box ${statusSearch}`}>
          <div className="search_menu">
            <button
              className={`btn ${activeCompany}`}
              onClick={() => searchChangeType("company")}
            >
              <span className="icon-company1" />
              {text_companies ? text_companies : "Empresas"}
            </button>
            <button
              className={`btn ${activeService}`}
              onClick={() => searchChangeType("service")}
            >
              <span className="icon-list-products" />
              {text_services ? text_services : "Servicios"}
            </button>
            <button
              className={`btn ${activeProduct}`}
              onClick={() => searchChangeType("product")}
            >
              <span className="icon-company1" />
              {text_products ? text_products : "Productos"}
            </button>
          </div>
          <div className="search_form">
            <div className="search_title">
              {text_how_search ? text_how_search : "¿Cómo quieres buscar?"}
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="search_input-group">
                  <input
                    className="search_input"
                    value={search_name}
                    onChange={(input) => searchChangeName(input.target.value)}
                    type="text"
                    placeholder={text_by_name ? text_by_name : "Por Nombre"}
                  />
                  <div className="search_input-group-append">
                    <span className="icon-agenda" />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="search_input-group">
                  <select
                    className="search_input"
                    value={search_department}
                    onChange={(input) =>
                      this.changeDepartment(input.target.value)
                    }
                  >
                    <option value="">
                      {text_state_capitalize
                        ? text_state_capitalize
                        : "Departamento"}
                    </option>
                    {departments.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="search_input-group-append">
                    <span className="icon-location" />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="search_input-group">
                  <select
                    className="search_input"
                    value={search_city}
                    onChange={(input) => searchChangeCity(input.target.value)}
                  >
                    <option value="">
                      {text_city_capitalize ? text_city_capitalize : "Ciudad"}
                    </option>
                    {search_cities.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="search_input-group-append">
                    <span className="icon-location" />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="search_input-group">
                  <select
                    className="search_input"
                    value={search_category}
                    onChange={(input) =>
                      searchChangeCategory(input.target.value)
                    }
                  >
                    <option value="">
                      {text_category ? text_category : "Categoría"}
                    </option>
                    {categories.map((item) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {site_language === "es"
                            ? item.name
                            : item.english_name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="search_input-group-append">
                    <span className="icon-category" />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="search_input-group">
                  <select
                    className="search_input"
                    value={search_rating}
                    onChange={(input) => searchChangeRating(input.target.value)}
                  >
                    <option value="">
                      {text_qualification ? text_qualification : "Calificación"}
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                  <div className="search_input-group-append">
                    <span className="icon-score" />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 d-flex justify-content-end">
                <button
                  className="btn search_close"
                  onClick={() => this.props.openCloseSearch(false)}
                >
                  {text_close ? text_close : "Cerrar"}
                </button>
                <button className="btn search_btn" onClick={this.search}>
                  {text_go_search ? text_go_search : "Buscar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.getElementById("modal")
    );
  }
}

const mapStateToProps = ({ userReducer, searchReducer, siteReducer }) => {
  return { userReducer, searchReducer, siteReducer };
};

export default connect(mapStateToProps, searchActions)(Search);
