import React from 'react';

import './css/Spinner.css';

function Spinner(props) {
  let classSpinner = (props.type !== undefined) ? props.type : 'white';
  return (
    <div className={`lds-spinner ${classSpinner}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Spinner;