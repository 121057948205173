import React, { useEffect } from 'react';
import './DemographicUbication.css';
import { connect } from 'react-redux';
import {
  getCountries,
  getCities,
  changeCitySelected,
  changeCountrySelected,
} from '../../../functionality/actions/countryAcitons';
import {
  setCampaignCities,
  addCityToCampaign,
  setCampaignCountry,
  addCountryToCampaign,
} from '../../../functionality/actions/campaignActions';
import SelectSearch from '../../SelectSearch';

const DemographicUbication = (props) => {
  const {
    siteReducer: {
      site_language,
      site_texts: { text_select_coutry, text_select_city },
    },
    countryReducer: {
      country_selected,
      country_city_selected,
      country_loading,
      countries,
      country_cities_loading,
      country_cities,
    },
    changeCitySelected,
    changeCountrySelected,
    getCountries,
    addCityToCampaign,
    cityOrCountry,
    setCampaignCities,
    addCountryToCampaign,
    containerClass,
    addCountry,
    addCity,
  } = props;

  useEffect(() => {
    if (!countries.length) {
      getCountries();
    }
  }, []);

  const addNewCity = () => {
    if (addCountry && !country_city_selected) {
      addCountry(country_selected);
      return;
    }
    if (addCity) {
      addCity(country_city_selected);
      return;
    }
    addCityToCampaign(country_city_selected);
  };

  const addNewCountry = () => {
    if (addCountry) {
      addCountry(country_selected);
      return;
    }
    addCountryToCampaign(country_selected);
  };

  return (
    <>
      {cityOrCountry === 'city' && (
        <div className={containerClass}>
          <div className="col-city">
            <SelectSearch
              label={text_select_coutry || 'Selecciona el país'}
              placeholder={text_select_coutry || 'Selecciona el país'}
              options={countries}
              loading={country_loading}
              onChange={changeCountrySelected}
              value={country_selected}
              optionName={site_language === 'es' ? 'name' : 'english_name'}
              style="input"
            />
          </div>
          <div className="col-city">
            <SelectSearch
              label={text_select_city || 'Selecciona la ciudad'}
              placeholder={text_select_city || 'Selecciona la ciudad'}
              options={country_cities}
              loading={country_cities_loading}
              onChange={changeCitySelected}
              optionName="name"
              secondOptionName="department_name"
              value={country_city_selected}
              style="input"
            />
          </div>
          <button
            className="add__ubication"
            onClick={(event) => {
              event.preventDefault();
              addNewCity(event);
            }}
          >
            <i className="fa-solid fa-plus"></i>
          </button>
        </div>
      )}
      {cityOrCountry === 'country' && (
        <div className="demographic__ubication">
          <div className="col-country">
            <SelectSearch
              label={text_select_coutry || 'Selecciona el país'}
              placeholder={text_select_coutry || 'Selecciona el país'}
              options={countries}
              loading={country_loading}
              onChange={(value) => changeCountrySelected(value, false)}
              value={country_selected}
              optionName={site_language === 'es' ? 'name' : 'english_name'}
              style="input"
            />
          </div>
          <button
            className="add__ubication"
            onClick={(event) => {
              event.preventDefault();
              addNewCountry(event);
            }}
          >
            <i className="fa-solid fa-plus"></i>
          </button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ countryReducer, siteReducer }) => {
  return {
    countryReducer,
    siteReducer,
  };
};
const mapDispatchToProps = {
  getCountries,
  getCities,
  changeCitySelected,
  changeCountrySelected,
  setCampaignCities,
  addCityToCampaign,
  setCampaignCountry,
  addCountryToCampaign,
};

DemographicUbication.defaulProps = {
  containerClass: 'demographic__ubication',
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(DemographicUbication)
);
