import React, { Component } from "react";
import { connect } from "react-redux";
import ProductsView from "./views/ProductsView";
import ManagerLayout from "./layouts/ManagerLayout";

import * as productActions from "../../../functionality/actions/productActions";
import TopSpinner from "../../../components/all/TopSpinner";

class ProductsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
  }

  async componentDidMount() {
    const { myProducts, product_my_list } = this.props;
    if (!product_my_list.length) {
      await myProducts();
    }
  }

  handleChangePage = (currentPage, initView, endView) => {
    let { product_search_manager_list, productChangePage } = this.props;
    let viewProducts = product_search_manager_list.slice(initView, endView);
    productChangePage(viewProducts, currentPage);
  };

  handleChangeSearch = (search) => {
    const { product_my_list, product_item_per_page, productChangeSearch } =
      this.props;
    this.setState({ search });
    if (search !== "") {
      this.setState({
        search,
      });
    } else {
      let viewProducts = product_my_list.slice(0, product_item_per_page);
      let searchProducts = product_my_list;
      let currentPage = 1;
      productChangeSearch(viewProducts, searchProducts, currentPage);
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    const { product_my_list, product_item_per_page, productChangeSearch } =
      this.props;
    let { search } = this.state;
    if (search !== "") {
      let products = product_my_list.filter(
        (item) => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
      let viewProducts = products.slice(0, product_item_per_page);
      let searchProducts = products;
      let currentPage = 1;
      productChangeSearch(viewProducts, searchProducts, currentPage);
    }
  };

  render() {
    const {
      product_loading,
      product_error,
      product_search_manager_list,
      product_list_manager_view,
      product_current_page,
      product_item_per_page,
    } = this.props;
    return (
      <ManagerLayout>
        {product_loading && product_error === "" && <TopSpinner />}
        {!product_loading && product_error !== "" && <div>{product_error}</div>}
        {!product_loading && product_error === "" && (
          <ProductsView
            itemsPerPage={product_item_per_page}
            viewProducts={product_list_manager_view}
            products={product_search_manager_list}
            currentPage={product_current_page}
            changePage={this.handleChangePage}
            search={this.state.search}
            changeSearch={this.handleChangeSearch}
            toSearch={this.handleSearch}
          />
        )}
      </ManagerLayout>
    );
  }
}

const mapStateToProps = ({ productReducer }) => productReducer;

export default connect(mapStateToProps, productActions)(ProductsPage);
