import React, { Component } from "react";
import { connect } from "react-redux";
import { openLoading } from "../../functionality/actions/userActions";
import Api from "../../functionality/Api";
import OpenImage from "../user/manager/OpenImage";

class InputFile extends Component {
  state = {
    loading: "",
  };

  uploadFile = (file, callback) => {
    const {
      siteReducer: {
        site_texts: { text_uploading },
      },
      openLoading,
      upload,
    } = this.props;

    if (upload) {
      let data = new FormData();

      data.append("file", file.target.files[0]);
      data.append("folder", "documents");

      openLoading(text_uploading ? text_uploading : "Subiendo Archivo...");

      Api.uploadFile(data, (res) => {
        if (res.location) {
          callback(res.location);
        }
      });
    } else {
      callback(file);
    }
  };

  render() {
    const {
      siteReducer: {
        site_texts: { text_show },
      },
      id,
      saveDoc,
      value,
      label,
      accept,
      title,
      description,
      preview,
    } = this.props;
    const classPreview = preview ? "preview" : "";

    return (
      <div>
        {description && <div className="mb-2">{description}</div>}
        <input
          type="file"
          className="hidden"
          id={id}
          onChange={(input) => this.uploadFile(input, saveDoc)}
          accept={accept}
        />
        {value !== "" ? (
          <div
            className={`openImage_container-inline openImage_view-doc d-flex ${classPreview}`}
          >
            {preview && (
              <div
                className="openImage_container-preview"
                style={{
                  backgroundImage: `url(${value})`,
                }}
              />
            )}
            <button
              onClick={() => document.getElementById(id).click()}
              className="profileView_remove"
            >
              <span className="icon-pencil"></span>
            </button>
            {text_show ? `${text_show}:` : "Ver:"}
            <a href={value} target="_blank" rel="noopener noreferrer">
              {label}
            </a>
          </div>
        ) : (
          <OpenImage
            open={() => document.getElementById(id).click()}
            title={title}
            type="inline"
            preview={preview}
          />
        )}
      </div>
    );
  }
}

InputFile.defaultProps = {
  upload: true,
  preview: false,
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

const mapDispatchToProps = {
  openLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(InputFile);
