import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./css/SettingsView.css";
import SettingsSteps from "../../../../components/user/manager/SettingsSteps";

import * as userActions from "../../../../functionality/actions/userActions";

const MySwal = withReactContent(Swal);

const {
  changePassword,
  changePasswordNew,
  changePasswordRepeat,
  userClear,
  savePassword,
  userClearError,
} = userActions;

class SettingsView extends Component {
  showError = (message) => {
    MySwal.fire({
      title: "Upsss!!!",
      text: message,
      type: "warning",
      confirmButtonColor: "#50318B",
      onClose: () => this.props.userClearError(),
    });
  };

  componentWillUnmount() {
    this.props.userClear();
  }

  savePassword = () => {
    const {
      password,
      password_new,
      password_repeat,
      savePassword,
      siteReducer: {
        site_texts: {
          text_enter_current_password,
          text_enter_new_password,
          text_confirm_new_password_error,
          text_passwords_not_match,
        },
      },
    } = this.props;
    if (password === "") {
      this.showError(
        text_enter_current_password
          ? text_enter_current_password
          : "Debe escribir la contraseña actual."
      );
      return;
    }
    if (password_new === "") {
      this.showError(
        text_enter_new_password
          ? text_enter_new_password
          : "Debe escribir la contraseña nueva."
      );
      return;
    }
    if (password_repeat === "") {
      this.showError(
        text_confirm_new_password_error
          ? text_confirm_new_password_error
          : "Debe confirmar la nueva contraseña."
      );
      return;
    }
    if (password_new !== password_repeat) {
      this.showError(
        text_passwords_not_match
          ? text_passwords_not_match
          : "Las contraseñas no coinciden."
      );
      return;
    }
    let data = {
      old_password: password,
      new_password: password_new,
    };
    savePassword(data);
  };

  render() {
    const {
      password,
      password_new,
      password_repeat,
      changePassword,
      changePasswordNew,
      changePasswordRepeat,
      siteReducer: {
        site_texts: {
          text_settings,
          text_update_password,
          text_current_password,
          text_new_password,
          text_confirm_new_password,
          text_change_password,
        },
      },
    } = this.props;
    return (
      <Fragment>
        <div className="title-manager">
          <div>
            <span className="icon-settings" />
            {text_settings ? text_settings : "Configuración"}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="settingsView_title">
              {text_update_password
                ? text_update_password.toUpperCase()
                : "ACTUALIZAR CONTRASEÑA"}
            </div>
            <div className="settingsView_box-basic">
              <label>
                {text_current_password
                  ? text_current_password.toUpperCase()
                  : "CONTRASEÑA ACTUAL"}
              </label>
              <input
                type="password"
                placeholder={
                  text_current_password
                    ? text_current_password.toUpperCase()
                    : "CONTRASEÑA ACTUAL"
                }
                className="form-control"
                value={password}
                onChange={(input) => changePassword(input.target.value)}
              />
              <div className="row">
                <div className="col-md-6">
                  <label>
                    {text_new_password
                      ? text_new_password.toUpperCase()
                      : "NUEVA CONTRASEÑA"}
                  </label>
                  <input
                    type="password"
                    placeholder={
                      text_new_password
                        ? text_new_password.toUpperCase()
                        : "NUEVA CONTRASEÑA"
                    }
                    className="form-control"
                    value={password_new}
                    onChange={(input) => changePasswordNew(input.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label>
                    {text_confirm_new_password
                      ? text_confirm_new_password.toUpperCase()
                      : "CONFIRMAR NUEVA CONTRASEÑA"}
                  </label>
                  <input
                    type="password"
                    placeholder={
                      text_confirm_new_password
                        ? text_confirm_new_password.toUpperCase()
                        : "CONFIRMAR NUEVA CONTRASEÑA"
                    }
                    className="form-control"
                    value={password_repeat}
                    onChange={(input) =>
                      changePasswordRepeat(input.target.value)
                    }
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="btn settingsView_save"
                  onClick={this.savePassword}
                >
                  {text_change_password
                    ? text_change_password
                    : "CAMBIAR CONTRASEÑA"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <SettingsSteps />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    ...userReducer,
    siteReducer,
  };
};
const mapDispatchToProps = {
  changePassword,
  changePasswordNew,
  changePasswordRepeat,
  userClear,
  userClearError,
  savePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);
