import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  changeAdSpaceId,
  changeAdPlanId,
  changeAdName,
  changeAdImage,
  changeAdMobileImage,
  changeAdSelectDates,
  adGetListPerSpaceAndMonth,
  changeAdSent,
  adSave,
} from '../../../../functionality/actions/adActions';
import { cropperImage } from '../../../../functionality/actions/cropperActions';
import SelectOption from './SelectOption';
import SelectCategories from './SelectCategories';
import Calendar from './Calendar/Calendar';
import SelectImages from './SelectImages';
import './css/Form.css';
import Menu from './Menu';
import SelectResource from './SelectResource';

function Form(props) {
  const [step, setStep] = useState(1);

  const {
    adReducer: {
      ad_settings,
      ad_name,
      ad_space_id,
      ad_plan_id,
      ad_category_id,
      ad_resource_id,
      ad_image,
      ad_mobile_image,
      ad_loading_per_month,
      ad_sent,
      ad_detail,
    },
    changeAdSpaceId,
    changeAdPlanId,
    changeAdName,
    changeAdImage,
    changeAdMobileImage,
    cropperImage,
    changeAdSelectDates,
    adGetListPerSpaceAndMonth,
    changeAdSent,
    adSave,
    siteReducer: {
      site_texts: { text_write_name_campaign, text_send_campaign_validate },
    },
  } = props;

  const changeStep = (step) => {
    setStep(step);
  };

  return (
    <div className="form_content">
      {step === 1 && (
        <>
          <div className="mb-1">
            {text_write_name_campaign
              ? text_write_name_campaign
              : 'Escribe un nombre para la campaña'}
            :
          </div>
          <input
            type="text"
            value={ad_name}
            onChange={(input) => changeAdName(input.target.value)}
            className="form-control"
          />
        </>
      )}
      {step === 2 && (
        <SelectOption
          label="Selecciona el plan:"
          showPrice={true}
          options={ad_settings.plans}
          value={ad_plan_id}
          onChange={changeAdPlanId}
        />
      )}
      {step === 3 && (
        <SelectOption
          label="Selecciona el espacio donde vas a publicar:"
          options={ad_settings.spaces}
          value={ad_space_id}
          onChange={changeAdSpaceId}
        />
      )}
      {step === 4 && <SelectCategories />}
      {step === 5 && <SelectResource />}
      {step === 6 && (
        <SelectImages
          space={ad_space_id}
          adImage={ad_image}
          adMobileImage={ad_mobile_image}
          changeAdImage={changeAdImage}
          changeAdMobileImage={changeAdMobileImage}
          cropperImage={cropperImage}
        />
      )}
      {step === 7 && (
        <>
          <div className="d-flex justify-content-center">
            <Calendar
              daysToSelect={ad_plan_id.days}
              loading={ad_loading_per_month}
              setDates={changeAdSelectDates}
              adGetListPerSpaceAndMonth={adGetListPerSpaceAndMonth}
              dates={ad_detail ? ad_detail.dates : null}
              date={ad_detail ? ad_detail.date : null}
            />
          </div>
          <label className="form_label">
            <input
              type="checkbox"
              checked={ad_sent ? 'cheched' : ''}
              onChange={() => changeAdSent(!ad_sent)}
            />
            {text_send_campaign_validate
              ? text_send_campaign_validate
              : 'Envíar campaña para validar su publicación.'}
          </label>
        </>
      )}
      <Menu
        changeStep={changeStep}
        step={step}
        name={ad_name}
        space={ad_space_id}
        plan={ad_plan_id}
        category={ad_category_id}
        resource={ad_resource_id}
        image={ad_image}
        mobileImage={ad_mobile_image}
        save={adSave}
      />
    </div>
  );
}

const mapStateToProps = ({ adReducer, siteReducer }) => {
  return {
    adReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  changeAdName,
  changeAdSpaceId,
  changeAdPlanId,
  cropperImage,
  changeAdImage,
  changeAdMobileImage,
  changeAdSelectDates,
  adGetListPerSpaceAndMonth,
  changeAdSent,
  adSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
