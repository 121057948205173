import React from 'react';
import { connect } from 'react-redux';
import './css/SelectOption.css';

function SelectOption(props) {
  const {
    siteReducer: {
      site_texts: { text_continue, text_cancel },
    },
    onCloseModal,
    onContinue,
    onSelect,
    itemSelected,
    options,
    selectName,
    title,
    text,
  } = props;

  const renderOptions = () => {
    const html = [];

    options.forEach((item, index) => {
      if (item[selectName]) {
        const selected =
          itemSelected && itemSelected.id === item.id ? 'selected' : '';

        html.push(
          <button
            className={`SelectOption_button ${selected}`}
            onClick={() => onSelect(item)}
            key={index}
          >
            {item[selectName]}
          </button>
        );
      }
    });

    return html;
  };

  return (
    <div className="SelectOption_content">
      <div className="SelectOption_header">
        <h2>{title}</h2>
        <button className="socialNetworks_close" onClick={onCloseModal}>
          <i className="fa-solid fa-xmark" />
        </button>
      </div>
      <div className="SelectOption_body">
        {text && <p>{text}</p>}
        {renderOptions()}
      </div>
      <div className="SelectOption_footer">
        <button className="SelectOption_cancel" onClick={onCloseModal}>
          {text_cancel || 'Cancelar'}
        </button>
        <button className="SelectOption_continue" onClick={onContinue}>
          {text_continue || 'Continuar'}
        </button>
      </div>
    </div>
  );
}

SelectOption.defaultProps = {
  selectName: 'name',
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(SelectOption);
