import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  sessionValidate,
  initialList,
  userClearError,
} from './functionality/actions/userActions';
import { notificationView } from '../src/functionality/actions/notificationActions';
import { siteGetLanguage } from './functionality/actions/siteActions';
import SessionLoading from './components/all/SessionLoading';
import Logo from '../src/images/favicon.png';

import DashboardPage from './pages/user/manager/DashboardPage';
import InboxPage from './pages/user/manager/InboxPage';
import InboxDetailPage from './pages/user/manager/InboxDetailPage';
import InboxNewPage from './pages/user/manager/InboxNewPage';
import OrdersPage from './pages/user/manager/OrdersPage';
import ProductsPage from './pages/user/manager/ProductsPage';
import HistorialPage from './pages/user/manager/HistorialPage';
import ProfilePage from './pages/user/manager/ProfilePage';
import SettingsPage from './pages/user/manager/SettingsPage';
import PaymentMethodsPage from './pages/user/manager/PaymentMethodsPage';
import OrderDetailPage from './pages/user/manager/OrderDetailPage';
import ProductDetailPage from './pages/user/manager/ProductDetailPage';
import StatusPage from './pages/user/manager/StatusPage';
import PaymentHistorialPage from './pages/user/manager/PaymentHistorialPage';

import IndexPage from './pages/user/marketplace/IndexPage';
import UserPage from './pages/user/marketplace/UserPage';
import ProductsMarketplace from './pages/user/marketplace/ProductsPage';
import ProductPage from './pages/user/marketplace/ProductPage';
import BestSellerPage from './pages/user/marketplace/BestSellerPage';
import BestCompaniesPage from './pages/user/marketplace/BestCompaniesPage';
import NewProductsPage from './pages/user/marketplace/NewProductsPage';
import OffersPage from './pages/user/marketplace/OffersPage';
import FavoritesPage from './pages/user/marketplace/FavoritesPage';
import ServicesPage from './pages/user/marketplace/ServicesPage';
import CompaniesPage from './pages/user/marketplace/CompaniesPage';
import ShoppingCartPage from './pages/user/marketplace/ShoppingCartPage';
// import IndexViewInit from './pages/user/marketplace/views/IndexViewInit';
import LoginPage from './pages/user/marketplace/LoginPage';
import RegisterPage from './pages/user/marketplace/RegisterPage';
import ForgotPage from './pages/user/marketplace/ForgotPage';
import CompaniesCategoryPage from './pages/user/marketplace/CompaniesCategoryPage';
import CheckoutPage from './pages/user/marketplace/CheckoutPage';
import SearchPage from './pages/user/marketplace/SearchPage';

import Loading from './components/all/Loading';
import Page404 from './pages/user/marketplace/Page404';
import ViewModal from './components/all/ViewModal';
import ScrollToTop from './components/ScrollToTop';
import VerifiedPage from './pages/user/manager/VerifiedPage';
import ProductImportPage from './pages/user/manager/ProductImportPage';
import ReferredPage from './pages/user/manager/ReferredPage';
import ConfirmPage from './pages/user/manager/ConfirmPage';
import DocumentRegisterView from './pages/user/manager/views/DocumentRegisterView';
import AdsPage from './pages/user/manager/AdsPage';
import AdFormPage from './pages/user/manager/AdFormPage';
import InstagramRegisterPage from './pages/user/marketplace/InstagramRegisterPage';
import InfluencerPage from './pages/user/marketplace/InlfuencerPage/InfluencerPage';
import CampaignPage from './pages/user/manager/MyCampaign/CampaignPage';
import NewCampaignView from './pages/user/manager/views/NewCampaignView/NewCampaignView';
import CampaignDetail from './pages/user/manager/CampaignDetail/CampaignDetail';
import InboxPorposalPage from './pages/user/manager/InboxPorposalPage/InboxPorposalPage';
import CampaignChatsPage from './pages/user/manager/CampaignChatsPage';
import CampaignInboxPage from './pages/user/manager/CampaignInboxPage';
import CampaignResumePage from './pages/user/manager/CampaignResumePage';
import CampaignInfluencersPage from './pages/user/manager/CampaignInfluencersPage';
import CampaignPostsPage from './pages/user/manager/CampaignPostsPage';
import CampaignBreifPage from './pages/user/manager/CampaignBreifPage';
import NewPorposalPage from './pages/user/manager/porposalCampaign/PorposalNew/NewPorposalPage';
import EditPorposalPage from './pages/user/manager/porposalCampaign/PorposalEdit/EditPorposalPage';
import InfluencerProfile from './pages/user/manager/InfluencerProfile/InfluencerProfile';
import PorposalCampaignPage from './pages/user/manager/porposalCampaign/PorposalCampaignPage';
import BrandsPage from './pages/user/manager/BrandsPage';
import TikTokPage from './pages/user/manager/TikTokPage';

const MySwal = withReactContent(Swal);

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_tooltip: false,
      tooltip_position: '',
    };
  }

  componentDidMount() {
    const { siteGetLanguage, sessionValidate } = this.props;
    let windowWidth = window.screen.width;
    if (windowWidth < 1025) {
      let baxnetTooltipHome = localStorage.getItem('baxnetTooltipHome');
      let tooltip_position = '';
      let my_navigator = navigator.userAgent.toLowerCase();
      if (my_navigator.indexOf('iphone') !== -1) {
        tooltip_position = 'bottom';
      } else {
        tooltip_position = 'top';
      }
      if (!baxnetTooltipHome) {
        setTimeout(() => {
          this.setState({
            modal_tooltip: true,
            tooltip_position,
          });
        }, 500);
      }
    }
    siteGetLanguage();
    if (!this.props.userReducer.activities.length) {
      this.props.initialList();
    }
    sessionValidate();
  }

  showError = (message) => {
    MySwal.fire({
      title: 'Upsss!!!',
      text: message,
      type: 'error',
      confirmButtonColor: '#50318B',
      onClose: () => this.props.userClearError(),
    });
  };

  closeTooltip = () => {
    localStorage.setItem('baxnetTooltipHome', true);
    this.setState({ modal_tooltip: false });
  };

  render() {
    const {
      userReducer: { loading, error, loading_text, session_loading },
      siteReducer: {
        site_loading,
        site_texts: { text_intro, text_close },
      },
    } = this.props;
    if (session_loading || site_loading) {
      return <SessionLoading />;
    }

    const textIntro = text_intro
      ? text_intro
      : 'Agrega <strong>BAX</strong> a tu pantalla de inicio para que la utilices como app. Selecciona añadir a la pantalla de inicio.';

    return (
      <Router>
        {loading && <Loading title={loading_text} />}
        <ScrollToTop />
        <ViewModal
          is_visible={this.state.modal_tooltip}
          position={this.state.tooltip_position}
          overflow={false}
        >
          <img src={Logo} alt="BAX" className="viewModal_icon" />
          <p dangerouslySetInnerHTML={{ __html: textIntro }} />
          <div className="viewModal_menu">
            <button onClick={this.closeTooltip} className="btn btn-pymex">
              {text_close ? text_close : 'Cerrar'}
            </button>
          </div>
          {this.state.tooltip_position === 'bottom' ? (
            <div className="viewModal_arrow-bottom" />
          ) : (
            <div className="viewModal_arrow-top" />
          )}
        </ViewModal>
        {error !== '' && this.showError(error)}
        <Switch>
          <Redirect exact from="/manager" to="manager/dashboard" />
          <Route exact path="/manager/dashboard" component={DashboardPage} />
          <Route exact path="/manager/inbox" component={InboxPage} />
          <Route exact path="/manager/inbox/new" component={InboxNewPage} />
          <Route
            exact
            path="/manager/inbox/message/:id"
            component={InboxDetailPage}
          />
          <Route
            exact
            path="/manager/inbox/porposal/:id"
            component={InboxPorposalPage}
          />
          <Route exact path="/manager/orders/:type" component={OrdersPage} />
          <Route exact path="/manager/order/:id" component={OrderDetailPage} />
          <Route exact path="/manager/products" component={ProductsPage} />
          <Route exact path="/manager/campaign" component={CampaignPage} />
          <Route
            exact
            path="/manager/campaign/new"
            component={NewCampaignView}
          />
          <Route
            exact
            path="/manager/campaign/:id/edit"
            component={NewCampaignView}
          />
          <Route
            exact
            path="/manager/campaign/:id"
            component={CampaignDetail}
          />
          <Route
            exact
            path={`/manager/campaign/:id/inbox`}
            component={CampaignChatsPage}
          />
          <Route
            exact
            path={`/manager/campaign/:id/inbox-porpose/:porposalConsecutive`}
            component={CampaignInboxPage}
          />
          <Route
            exact
            path={`/manager/campaign/:id/resume`}
            component={CampaignResumePage}
          />
          <Route
            exact
            path={`/manager/campaign/:id/breif`}
            component={CampaignBreifPage}
          />
          <Route
            exact
            path={`/manager/campaign/:id/porpose`}
            component={PorposalCampaignPage}
          />
          <Route
            exact
            path={`/manager/campaign/:id/porpose/edit/:consecutive`}
            component={EditPorposalPage}
          />
          <Route
            exact
            path={`/manager/campaign/:id/porpose/new`}
            component={NewPorposalPage}
          />
          <Route
            exact
            path={`/manager/campaign/:id/influencer`}
            component={CampaignInfluencersPage}
          />
          <Route
            exact
            path={`/manager/campaign/:id/posts`}
            component={CampaignPostsPage}
          />
          <Route
            exact
            path="/manager/products/import"
            component={ProductImportPage}
          />
          <Route
            exact
            path="/manager/products/edit/:id"
            component={ProductDetailPage}
          />
          <Route
            exact
            path="/manager/products/new"
            component={ProductDetailPage}
          />
          <Route exact path="/manager/brands" component={BrandsPage} />
          <Route exact path="/manager/historial" component={HistorialPage} />
          <Route
            exact
            path="/influencer/:username"
            component={InfluencerProfile}
          />
          <Route exact path="/manager/profile" component={ProfilePage} />
          <Route exact path="/manager/settings" component={SettingsPage} />
          <Route
            exact
            path="/manager/pyment-methods"
            component={PaymentMethodsPage}
          />
          <Route
            exact
            path="/manager/register/docs"
            component={DocumentRegisterView}
          />
          <Route exact path="/manager/status" component={StatusPage} />
          <Route
            exact
            path="/manager/payment/historial"
            component={PaymentHistorialPage}
          />
          <Route exact path="/manager/ads" component={AdsPage} />
          <Route exact path="/manager/ads/create" component={AdFormPage} />
          <Route exact path="/manager/ads/edit/:id" component={AdFormPage} />
          <Route exact path="/manager/referrals" component={ReferredPage} />
          <Route exact path="/manager/confirm" component={ConfirmPage} />
          <Route
            exact
            path="/manager/register/tiktok"
            component={InstagramRegisterPage}
          />

          <Route exact path="/" component={RegisterPage} />
          <Route exact path="/home" component={IndexPage} />
          <Route exact path="/product/:id" component={ProductPage} />
          <Route exact path="/products" component={ProductsMarketplace} />
          <Route
            exact
            path="/products/category/:id"
            component={ProductsMarketplace}
          />
          <Route exact path="/services" component={ServicesPage} />
          <Route exact path="/services/category/:id" component={ServicesPage} />
          <Route exact path="/companies" component={CompaniesPage} />
          <Route exact path="/company/:username" component={UserPage} />
          <Route
            exact
            path="/companies/category/:id"
            component={CompaniesCategoryPage}
          />

          {/* //*Influencers */}
          <Route exact path="/influencers" component={InfluencerPage} />

          {/* //*Other */}
          <Route exact path="/best-seller" component={BestSellerPage} />
          <Route exact path="/best-companies" component={BestCompaniesPage} />
          <Route exact path="/offers" component={OffersPage} />
          <Route exact path="/favorites" component={FavoritesPage} />
          <Route exact path="/new-products" component={NewProductsPage} />
          <Route exact path="/cart" component={ShoppingCartPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/register" component={RegisterPage} />
          <Route exact path="/r/:username" component={RegisterPage} />
          <Route exact path="/forgot" component={ForgotPage} />
          <Route exact path="/checkout/:cod_product" component={CheckoutPage} />
          <Route exact path="/search/:type" component={SearchPage} />
          <Route exact path="/:username" component={UserPage} />
          <Route exact path="/verified/:email" component={VerifiedPage} />
          <Route exact path="/social-network/tik-tok" component={TikTokPage} />

          <Route component={Page404} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  userClearError,
  sessionValidate,
  initialList,
  notificationView,
  siteGetLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
