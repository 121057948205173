import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  changeTagFilter,
  changeTargetFilter,
  changeRatingFilter,
  changeAgeMinFilter,
  changeAgeMaxFilter,
  changeFollowerMinFilter,
  changeFollowerMaxFilter,
  addCityFilter,
  removeCityFilter,
  addCountryFilter,
  removeCountryFilter,
} from '../../../../../functionality/actions/influencerActions';
import { changeSocialNetworkFilter } from '../../../../../functionality/actions/socialNetworksActions';
import { getInfluencersSeachRoute } from '../../../../../utils/searchRoute';
import CollapseComponent from '../../../../all/Collapse/CollapseComponent';
import MultiRange from '../../../../all/MultiRange/MultiRange';
import Score from '../../../../../components/all/Influencer/InfluencerScore/Score';
import Keywords from '../../../../all/keywords/Keywords';
import DemographicUbication from '../../../../all/DemographicUbication/DemographicUbication';
import CheckboxTargets from '../../../../all/CheckboxTargets';
import CheckboxSocialNetworks from '../../../../all/CheckboxSocialNetworks';
import './InfluencerFilter.css';

const InfluencerFilter = (props) => {
  const {
    siteReducer: {
      site_language,
      site_texts: {
        text_apply_filter,
        text_demographics,
        text_average_influenx_rating,
        text_location,
        text_networks,
        text_age,
      },
    },
    influencerReducer: {
      influencer_target_filter,
      influencer_tags_filter,
      influencer_cities_filter,
      influencer_countries_filter,
      influencer_rating_filter,
      influencer_age_min_filter,
      influencer_age_max_filter,
      influencer_follower_min_filter,
      influencer_follower_max_filter,
      influencer_page,
    },
    socialNetworksReducer: { social_networks_filter },
    changeTargetFilter,
    changeTagFilter,
    changeRatingFilter,
    changeSocialNetworkFilter,
    changeAgeMinFilter,
    changeAgeMaxFilter,
    changeFollowerMinFilter,
    changeFollowerMaxFilter,
    addCityFilter,
    removeCityFilter,
    addCountryFilter,
    removeCountryFilter,
    history,
    match: { path },
  } = props;

  const memoCheckboxTargets = useMemo(
    () => (
      <CheckboxTargets
        values={influencer_target_filter}
        onChange={changeTargetFilter}
        labelName={site_language === 'es' ? 'name' : 'english_name'}
      />
    ),
    [influencer_target_filter]
  );
  const memoDemographicUbication = useMemo(
    () => (
      <DemographicUbication
        cityOrCountry="city"
        containerClass="demographic__container"
        addCity={addCityFilter}
        addCountry={addCountryFilter}
      />
    ),
    []
  );
  const memoCheckboxSocialNetworks = useMemo(
    () => (
      <CheckboxSocialNetworks
        values={social_networks_filter}
        onChange={changeSocialNetworkFilter}
        labelName={site_language === 'es' ? 'name' : 'english_name'}
      />
    ),
    [social_networks_filter]
  );
  const memoKeywords = useMemo(
    () => (
      <Keywords
        title="Keywords"
        onChange={changeTagFilter}
        selected={influencer_tags_filter}
        objectName={site_language === 'es' ? 'name' : 'english_name'}
      />
    ),
    [influencer_tags_filter]
  );

  const renderPills = (items, removeAction) => {
    return (
      <div className="influencerFilter__pills">
        {items.map((item) => (
          <div className="pill" key={item.id}>
            <div className="influencerFilter__pill">
              {item.name}{' '}
              {item.department_name && <small> ({item.department_name})</small>}
            </div>
            <button onClick={() => removeAction(item)}>
              <i className="fa-regular fa-trash-can" />
            </button>
          </div>
        ))}
      </div>
    );
  };

  const getFilter = () => {
    const route = getInfluencersSeachRoute(
      influencer_page,
      influencer_target_filter,
      influencer_tags_filter,
      influencer_cities_filter,
      influencer_countries_filter,
      influencer_rating_filter,
      influencer_age_min_filter,
      influencer_age_max_filter,
      influencer_follower_min_filter,
      influencer_follower_max_filter,
      social_networks_filter
    );

    history.push(`${path}${route}`);
  };

  return (
    <div className="influencerFilter__container">
      <CollapseComponent
        title={text_demographics || 'Demograficos'}
        open={true}
      >
        {memoCheckboxTargets}
        <MultiRange
          title={text_age || 'Edad'}
          min={18}
          max={80}
          defaultMin={influencer_age_min_filter}
          defaultMax={influencer_age_max_filter}
          setMinInput={changeAgeMinFilter}
          setMaxInput={changeAgeMaxFilter}
        />
      </CollapseComponent>
      <CollapseComponent title={text_location || 'Ubicacion'} open={true}>
        {influencer_countries_filter.length ? (
          <>{renderPills(influencer_countries_filter, removeCountryFilter)}</>
        ) : null}
        {influencer_cities_filter.length ? (
          <>{renderPills(influencer_cities_filter, removeCityFilter)}</>
        ) : null}
        {memoDemographicUbication}
      </CollapseComponent>
      <CollapseComponent title={text_networks || 'Redes'} open={true}>
        {memoCheckboxSocialNetworks}
        <MultiRange
          title={'Followers'}
          min={2000}
          max={1000000}
          defaultMin={influencer_follower_min_filter}
          defaultMax={influencer_follower_max_filter}
          setMinInput={changeFollowerMinFilter}
          setMaxInput={changeFollowerMaxFilter}
        />
      </CollapseComponent>
      <CollapseComponent title={'Influenx'} open={true}>
        <Score
          title={
            text_average_influenx_rating || 'Promedio Calificación Influenx'
          }
          score={influencer_rating_filter}
          setScore={changeRatingFilter}
        />
        {memoKeywords}
      </CollapseComponent>
      <button className="filter__btn" onClick={getFilter}>
        {text_apply_filter || 'Aplilcar filtro'}
      </button>
    </div>
  );
};

const mapStateToProps = ({
  siteReducer,
  influencerReducer,
  socialNetworksReducer,
}) => {
  return {
    siteReducer,
    influencerReducer,
    socialNetworksReducer,
  };
};

const mapDispatchToProps = {
  changeTargetFilter,
  changeTagFilter,
  changeRatingFilter,
  changeSocialNetworkFilter,
  changeAgeMinFilter,
  changeAgeMaxFilter,
  changeFollowerMinFilter,
  changeFollowerMaxFilter,
  addCityFilter,
  removeCityFilter,
  addCountryFilter,
  removeCountryFilter,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InfluencerFilter)
);
