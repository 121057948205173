import React, { Component } from "react";
import { connect } from "react-redux";
import MarketPlaceLayout from "./layouts/MarketplaceLayout";
import ProductsView from "./views/ProductsView";
import TopSpinner from "../../../components/all/TopSpinner";

class ProductsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      principalSlider: [],
      categories: [],
      products: [],
      viewProducts: [],
      itemsPerPage: 12,
      currentPage: 1,
      typeList: null,
      priceRange: [0, 0],
      priceSelected: [0, 0],
      statusSearch: false,
    };
  }

  componentDidMount() {
    let principalSlider = [];
    for (let i = 0; i < 5; i++) {
      let link = i % 2 === 0 ? "/product/1" : "";
      let data = {
        id: i,
        image: "",
        link,
        name: `principalSlider ${i}`,
      };
      principalSlider.push(data);
    }
    this.setState({
      principalSlider,
      priceRange: [50000, 1000000],
      priceSelected: [50000, 1000000],
    });
  }

  handleUploadPriceFilter = (values) => {
    this.setState({
      priceSelected: values,
    });
  };

  openCloseSearch = (statusSearch) => {
    this.setState({
      statusSearch,
    });
  };

  render() {
    let viewCategory =
      this.props.match.params.id !== undefined
        ? this.props.match.params.id
        : false;
    const { loading_init } = this.props;
    return (
      <MarketPlaceLayout
        statusSearch={this.state.statusSearch}
        openCloseSearch={this.openCloseSearch}
      >
        {loading_init ? (
          <TopSpinner />
        ) : (
          <ProductsView
            type="products"
            principalSlider={this.state.principalSlider}
            typeList={this.state.typeList}
            priceRange={this.state.priceRange}
            priceSelected={this.state.priceSelected}
            uploadFilterPrice={this.handleUploadPriceFilter}
            viewCategory={viewCategory}
            openCloseSearch={this.openCloseSearch}
          />
        )}
      </MarketPlaceLayout>
    );
  }
}

const mapStateToProps = ({ userReducer }) => userReducer;

export default connect(mapStateToProps)(ProductsPage);
