import React, { Component } from "react";
import "./css/ViewModal.css";

class ViewModal extends Component {
  render() {
    const { is_visible, position, overflow } = this.props;

    let visible = !is_visible ? "" : "visible";
    let class_position = !position ? "" : position;
    if (is_visible && overflow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return (
      <div className={`viewModal_container ${visible}`}>
        <div className={`viewModal_box ${visible} ${class_position}`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

ViewModal.defaultProps = {
  overflow: true,
};

export default ViewModal;
