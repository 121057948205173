import {
  AD_LOADING,
  AD_LOADING_PER_MONTH,
  AD_LOADING_PER_DATE,
  AD_MY_LIST_LOADING,
  AD_MY_LIST,
  AD_LIST,
  AD_LIST_MONTH,
  AD_LIST_DATE,
  AD_IMAGE,
  AD_MOBILE_IMAGE,
  AD_DATE,
  AD_SPACE_ID,
  AD_PLAN_ID,
  AD_NAME,
  AD_RESOURCE_ID,
  AD_CATEGORY_ID,
  AD_SENT,
  AD_CLEAR,
  AD_LOADING_RESOURCE,
  AD_PRODUCTS,
  AD_SERVICES,
  AD_DETAIL_LOADING,
  AD_DETAIL,
  AD_SETTINGS_LOADING,
  AD_SETTINGS,
  AD_SELECT_DATES,
  AD_DATA,
  AD_LIST_VIEW,
} from "../types/adTypes";

const INITIAL_STATE = {
  ad_loading: true,
  ad_loading_per_month: true,
  ad_loading_per_date: true,
  ad_my_list_loading: true,
  ad_my_list: [],
  ad_list: [],
  ad_list_month: [],
  ad_list_date: [],
  ad_image: "",
  ad_mobile_image: "",
  ad_date: "",
  ad_space_id: "",
  ad_plan_id: "",
  ad_name: "",
  ad_category_id: "",
  ad_resource_id: "",
  ad_sent: false,
  ad_loading_resource: true,
  ad_products: [],
  ad_services: [],
  ad_detail: null,
  ad_detail_loading: false,
  ad_settings: {},
  ad_settings_loading: true,
  ad_select_dates: [],
  ad_data: null,
  ad_list_view: [],
};

const adReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AD_CLEAR:
      return {
        ...state,
        ad_image: "",
        ad_mobile_image: "",
        ad_date: "",
        ad_space_id: "",
        ad_plan_id: "",
        ad_name: "",
        ad_category_id: "",
        ad_resource_id: "",
        ad_sent: false,
        ad_select_dates: [],
        ad_detail: null,
        ad_data: null,
      };
    case AD_LOADING:
      return {
        ...state,
        ad_loading: action.payload,
      };
    case AD_LOADING_PER_MONTH:
      return {
        ...state,
        ad_loading_per_month: action.payload,
      };
    case AD_LOADING_PER_DATE:
      return {
        ...state,
        ad_loading_per_date: action.payload,
      };
    case AD_MY_LIST_LOADING:
      return {
        ...state,
        ad_my_list_loading: action.payload,
      };
    case AD_MY_LIST:
      return {
        ...state,
        ad_my_list: action.payload,
      };
    case AD_LIST:
      return {
        ...state,
        ad_list: action.payload,
      };
    case AD_LIST_MONTH:
      return {
        ...state,
        ad_list_month: action.payload,
      };
    case AD_LIST_DATE:
      return {
        ...state,
        ad_list_date: action.payload,
      };
    case AD_IMAGE:
      return {
        ...state,
        ad_image: action.payload,
      };
    case AD_MOBILE_IMAGE:
      return {
        ...state,
        ad_mobile_image: action.payload,
      };
    case AD_DATE:
      return {
        ...state,
        ad_date: action.payload,
      };
    case AD_SPACE_ID:
      return {
        ...state,
        ad_space_id: action.payload,
      };
    case AD_PLAN_ID:
      return {
        ...state,
        ad_plan_id: action.payload,
      };
    case AD_NAME:
      return {
        ...state,
        ad_name: action.payload,
      };
    case AD_RESOURCE_ID:
      return {
        ...state,
        ad_resource_id: action.payload,
      };
    case AD_CATEGORY_ID:
      return {
        ...state,
        ad_category_id: action.payload,
      };
    case AD_SENT:
      return {
        ...state,
        ad_sent: action.payload,
      };
    case AD_LOADING_RESOURCE:
      return {
        ...state,
        ad_loading_resource: action.payload,
      };
    case AD_PRODUCTS:
      return {
        ...state,
        ad_products: action.payload,
      };
    case AD_SERVICES:
      return {
        ...state,
        ad_services: action.payload,
      };
    case AD_DETAIL_LOADING:
      return {
        ...state,
        ad_detail_loading: action.payload,
      };
    case AD_DETAIL:
      return {
        ...state,
        ad_detail: action.payload,
      };
    case AD_SETTINGS_LOADING:
      return {
        ...state,
        ad_settings_loading: action.payload,
      };
    case AD_SETTINGS:
      return {
        ...state,
        ad_settings: action.payload,
      };
    case AD_SELECT_DATES:
      return {
        ...state,
        ad_select_dates: action.payload,
      };
    case AD_DATA:
      return {
        ...state,
        ad_data: action.payload,
      };
    case AD_LIST_VIEW:
      return {
        ...state,
        ad_list_view: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default adReducer;
