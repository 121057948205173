import React, {Component} from 'react';
import HistorialView from './views/HistorialView';
import ManagerLayout from './layouts/ManagerLayout';

class HistorialPage extends Component {
	render() {
		return (
			<ManagerLayout>
				<HistorialView />
			</ManagerLayout>
		)
	}
}

export default HistorialPage;