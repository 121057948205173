import {
  PAYMENT_METHOD,
  PAYMENT_CLOSE_MODAL,
  PAYMENT_OPEN_MODAL,
  PAYMENT_CASH_TYPE,
  PAYMENT_CASH_VALUE,
  PAYMENT_LOADING,
  PAYMENT_CLOSE_LOADING,
  PAYMENT_LOADED,
  PAYMENT_LIST_CARDS,
  PAYMENT_NUMBER,
  PAYMENT_EXP_MONTH,
  PAYMENT_EXP_YEAR,
  PAYMENT_CVC,
  PAYMENT_HEADLINE,
  PAYMENT_DUES,
  PAYMENT_LIST,
  PAYMENT_CLEAR,
  PAYMENT_EDIT_CARD,
  PAYMENT_DEFAULT,
  PAYMENT_CLEAR_ERROR,
  PAYMENT_CASH_SELECT,
  PAYMENT_BANKS_PSE,
  PAYMENT_BANKS_ERROR,
  PAYMENT_PSE_BANK,
  PAYMENT_NAME,
  PAYMENT_LASTNAME,
  PAYMENT_DOC_NUMBER,
  PAYMENT_DOC_TYPE,
} from "../types/paymentTypes";

const INITIAL_STATE = {
  payment_method: 0,
  payment_modal: false,
  payment_type: "",
  payment_cash_type: "",
  payment_cash_value: 100000,
  payment_loading: false,
  payment_loading_message: "",
  payment_list_cards: [],
  payment_loaded: false,
  payment_number: "",
  payment_exp_month: "",
  payment_exp_year: "",
  payment_cvc: "",
  payment_dues: "",
  payment_headline: "",
  payment_error: "",
  payment_id_card: "",
  payment_is_default: "",
  payment_modal_error: "",
  payment_cash_select: "cash",
  payment_banks_pse: [],
  payment_banks_error: "",
  payment_pse_bank: 0,
  payment_name: "",
  payment_lastname: "",
  payment_doc_type: "",
  payment_doc_number: "",
  payment_company: null,
};

const paymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAYMENT_CLEAR:
      return {
        ...state,
        payment_method: 0,
        payment_modal: false,
        payment_type: "",
        payment_cash_type: "",
        payment_cash_value: 100000,
        payment_loading: false,
        payment_loading_message: "",
        payment_loaded: false,
        payment_number: "",
        payment_exp_month: "",
        payment_exp_year: "",
        payment_cvc: "",
        payment_dues: "",
        payment_headline: "",
        payment_error: "",
        payment_cash_select: "cash",
        payment_banks_pse: [],
        payment_banks_error: "",
        payment_pse_bank: 0,
      };
    case PAYMENT_CLEAR_ERROR:
      return {
        ...state,
        payment_modal: false,
        payment_modal_error: action.payload,
      };
    case PAYMENT_METHOD:
      return {
        ...state,
        payment_method: action.payload,
      };
    case PAYMENT_CLOSE_MODAL:
      return {
        ...state,
        payment_modal: false,
        payment_cash_type: "",
        payment_cash_value: 100000,
        payment_number: "",
        payment_exp_month: "",
        payment_exp_year: "",
        payment_cvc: "",
        payment_dues: "",
        payment_headline: "",
        payment_cash_select: "cash",
        payment_pse_bank: 0,
      };
    case PAYMENT_OPEN_MODAL:
      return {
        ...state,
        payment_modal: true,
        payment_loading: false,
        payment_loading_message: "",
        payment_number: "",
        payment_exp_month: "",
        payment_exp_year: "",
        payment_cvc: "",
        payment_dues: "",
        payment_headline: "",
        payment_id_card: "",
        payment_is_default: "",
        payment_type: action.payload,
      };
    case PAYMENT_CASH_TYPE:
      return {
        ...state,
        payment_cash_type: action.payload,
      };
    case PAYMENT_CASH_VALUE:
      return {
        ...state,
        payment_cash_value: action.payload,
      };
    case PAYMENT_LOADING:
      return {
        ...state,
        payment_loading: true,
        payment_loading_message: action.payload,
      };
    case PAYMENT_CLOSE_LOADING:
      return {
        ...state,
        payment_loading: false,
        payment_loading_message: action.payload,
      };
    case PAYMENT_LOADED:
      return {
        ...state,
        payment_loaded: true,
      };
    case PAYMENT_LIST_CARDS:
      return {
        ...state,
        payment_list_cards: action.payload,
      };
    case PAYMENT_NUMBER:
      return {
        ...state,
        payment_number: action.payload,
      };
    case PAYMENT_EXP_MONTH:
      return {
        ...state,
        payment_exp_month: action.payload,
      };
    case PAYMENT_EXP_YEAR:
      return {
        ...state,
        payment_exp_year: action.payload,
      };
    case PAYMENT_CVC:
      return {
        ...state,
        payment_cvc: action.payload,
      };
    case PAYMENT_HEADLINE:
      return {
        ...state,
        payment_headline: action.payload,
      };
    case PAYMENT_DUES:
      return {
        ...state,
        payment_dues: action.payload,
      };
    case PAYMENT_LIST:
      return {
        ...state,
        payment_list_cards: action.payload.cards,
        payment_company: action.payload.payment_company,
        // payment_cash: action.payload.cash,
        payment_cash: 0,
        payment_loaded: false,
      };
    case PAYMENT_EDIT_CARD:
      return {
        ...state,
        payment_id_card: action.payload.id_card,
        payment_dues: action.payload.dues,
        payment_is_default: action.payload.is_default,
        payment_type: action.payload.type,
        payment_loading_message: "",
        payment_modal: true,
      };
    case PAYMENT_DEFAULT:
      return {
        ...state,
        payment_is_default: action.payload,
      };
    case PAYMENT_CASH_SELECT:
      return {
        ...state,
        payment_cash_select: action.payload,
      };
    case PAYMENT_BANKS_PSE:
      return {
        ...state,
        payment_banks_pse: action.payload,
      };
    case PAYMENT_BANKS_ERROR:
      return {
        ...state,
        payment_banks_error: action.payload,
      };
    case PAYMENT_PSE_BANK:
      return {
        ...state,
        payment_pse_bank: action.payload,
      };
    case PAYMENT_NAME:
      return {
        ...state,
        payment_name: action.payload,
      };
    case PAYMENT_LASTNAME:
      return {
        ...state,
        payment_lastname: action.payload,
      };
    case PAYMENT_DOC_TYPE:
      return {
        ...state,
        payment_doc_type: action.payload,
      };
    case PAYMENT_DOC_NUMBER:
      return {
        ...state,
        payment_doc_number: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default paymentReducer;
