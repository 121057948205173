import React, { useEffect } from 'react';
import InputGroup from '../InputGroup/InputGroup';
import InputTextArea from '../InputTextArea/InputTextArea';
import OptionCheckboxImage from '../OptionCheckboxImage/OptionCheckboxImage';
import UploadImage from '../UploadImage/UploadImage';
import UploadVideo from '../UploadVideo/UploadVideo';
import './BreifPorposal.css';
import { connect } from 'react-redux';
import { getSocialNetworks } from '../../../functionality/actions/socialNetworksActions';
import Spinner from '../Spinner';
import {
  sendPorposal,
  setPorposalVideo,
  setPorposalDeliverables,
  setPorposalPrice,
  setPorposalImage,
  setPorposalName,
  addImagePorposal,
  deleteImagePorposal,
  setPorposalSocialNetworks,
  addVideoPorposal,
  porposalClear,
  publishPorposal,
  editPorposal,
} from '../../../functionality/actions/porposalActions';
import { withRouter } from 'react-router';
import Swal from 'sweetalert2';

const BreifPorposal = (props) => {
  const {
    porposalReducer: {
      porposal_name,
      porposal_images,
      porposal_social_networks,
      porposal_video,
      porposal_deliverables,
      porposal_price,
      proposal_status_id,
      porposal_consecutive,
      porposal_loading,
    },
    siteReducer: {
      site_texts: {
        text_brand,
        text_campaign_name,
        text_campaign_start,
        text_end_of_campaign,
        text_proposed_name,
        text_give_name_proposal,
        text_deliverables,
        text_campaign_networks,
        text_cost_in_bax,
        text_save_changes,
        text_save,
        text_publish,
        text_are_you_sure,
        text_not_reverse_action,
        text_publish_proposal,
      },
    },
    socialNetworksReducer: { social_networks, social_networks_loading },
    getSocialNetworks,
    sendPorposal,
    history,
    setPorposalVideo,
    setPorposalDeliverables,
    setPorposalPrice,
    addImagePorposal,
    deleteImagePorposal,
    setPorposalSocialNetworks,
    addVideoPorposal,
    porposalClear,
    data, //? data to print
    setPorposalName,
    publishPorposal,
    isEdit,
    editPorposal,
  } = props;

  useEffect(() => {
    if (social_networks.length === 0) {
      getSocialNetworks();
    }
    return () => {
      porposalClear();
    };
  }, []);

  const allFieldsFilled =
    porposal_name &&
    porposal_images &&
    porposal_social_networks &&
    porposal_deliverables &&
    porposal_price;

  const publish = () => {
    const swalStyled = Swal.mixin({
      customClass: {
        confirmButton: 'button__confirm',
        cancelButton: 'button__cancel',
      },
      buttonsStyling: false,
    });

    swalStyled
      .fire({
        title: text_are_you_sure || '¿Estas Seguro?',
        text: text_not_reverse_action || 'Tú no podrás revertir esta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28cae4',
        cancelButtonColor: '#50318b',
        confirmButtonText: text_publish_proposal || 'Publicar Propuesta',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value === true) {
          await publishPorposal(data.consecutive);
          history.goBack();
        }
      });
  };

  return (
    <div className="breif">
      <InputGroup
        type="text"
        name="brand__name"
        label={text_brand ? text_brand : 'Marca'}
        isRequired={true}
        value={data.brand.name}
        disabled={true}
      />
      <InputGroup
        type={'text'}
        name={'campaign__name'}
        label={text_campaign_name ? text_campaign_name : 'Nombre campaña'}
        isRequired={true}
        value={data.name}
        disabled={true}
      />
      <div className="inputdate__container">
        <InputGroup
          isRequired={true}
          label={
            text_campaign_start ? text_campaign_start : 'Inicio de campaña'
          }
          name={'start__date'}
          type={'date'}
          value={data.date_start}
          disabled={true}
          aditionalClassname={'input__date'}
        />
        <InputGroup
          isRequired={true}
          label={
            text_end_of_campaign
              ? text_end_of_campaign
              : 'finalización de campaña'
          }
          name={'end-date'}
          type={'date'}
          value={data.date_end}
          disabled={true}
          aditionalClassname={'input__date'}
        />
      </div>
      <InputGroup
        isRequired
        value={porposal_name}
        label={
          text_proposed_name ? text_proposed_name : 'nombre de la propuesta'
        }
        name={'porposal__name'}
        handleInputChange={setPorposalName}
        placeHolder={
          text_give_name_proposal
            ? text_give_name_proposal
            : 'Dale un nombre a tu propuesta'
        }
      />
      <InputTextArea
        defaultValue={isEdit ? porposal_deliverables : data.deliverables}
        isRequired={true}
        label={text_deliverables ? text_deliverables : 'entregables'}
        name={'campaign__deliverable'}
        handleInputChange={setPorposalDeliverables}
      />

      <div className="porposal__block">
        <label className="block__title">
          {text_campaign_networks
            ? text_campaign_networks
            : 'Redes para la campaña'}
        </label>
        <div className=" row">
          {social_networks_loading ? (
            <div className="spinner-loading">
              <Spinner type="purple" />
            </div>
          ) : (
            social_networks.map((network) => {
              if (!network.in_campaign) return null;
              return (
                <div
                  className={`col-md-4 circulo-red-social circulo-${network.slug}`}
                  key={network.id}
                >
                  <OptionCheckboxImage
                    icon={network.icon}
                    label={network.name}
                    value={network.id}
                    checked={porposal_social_networks.includes(network.id)}
                    handleInputChange={setPorposalSocialNetworks}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
      <UploadImage
        porposal={true}
        stateToUpdate={porposal_images}
        updateMethod={addImagePorposal}
        deleteMethod={deleteImagePorposal}
      />

      <UploadVideo
        stateToUpdate={porposal_video}
        updateMethod={addVideoPorposal}
        deleteMethod={setPorposalVideo}
        editView
      />

      <InputGroup
        isRequired={true}
        label={text_cost_in_bax ? text_cost_in_bax : 'costo en bax'}
        name={'propose__cost'}
        type={'number'}
        value={porposal_price}
        handleInputChange={setPorposalPrice}
      />
      <div className="interest__buttons">
        {porposal_loading ? (
          <div className="spinner-loading">
            <Spinner type="purple" />
          </div>
        ) : (
          <>
            {proposal_status_id === 1 && (
              <button
                className={`interest__btn ${!allFieldsFilled && 'disabled'}`}
                disabled={!allFieldsFilled}
                onClick={async () => {
                  await editPorposal(data.consecutive);
                  history.goBack();
                }}
              >
                {text_save_changes ? text_save_changes : 'Guardar Cambios'}
              </button>
            )}

            {!proposal_status_id && (
              <button
                disabled={!allFieldsFilled}
                className={`interest__btn ${!allFieldsFilled && 'disabled'}`}
                onClick={async () => {
                  await sendPorposal(data.consecutive).then((res) => {
                    history.push(
                      `/manager/campaign/${data.consecutive}/porpose/edit/${res}`
                    );
                  });
                }}
              >
                {text_save ? text_save : 'Guardar'}
              </button>
            )}
            {porposal_consecutive && (
              <button
                disabled={!allFieldsFilled}
                className={`interest__btn ${!allFieldsFilled && 'disabled'}`}
                onClick={publish}
              >
                {text_publish ? text_publish : 'Publicar'}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  socialNetworksReducer,
  porposalReducer,
  siteReducer,
}) => {
  return {
    socialNetworksReducer,
    porposalReducer,
    siteReducer,
  };
};
const mapDispatchToProps = {
  getSocialNetworks,
  setPorposalVideo,
  setPorposalDeliverables,
  setPorposalPrice,
  setPorposalImage,
  addImagePorposal,
  deleteImagePorposal,
  sendPorposal,
  setPorposalSocialNetworks,
  addVideoPorposal,
  porposalClear,
  setPorposalName,
  publishPorposal,
  editPorposal,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BreifPorposal)
);
