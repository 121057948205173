import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import CampaignCard from '../../../../../components/all/Campaign/Card/CampaignCard';
import CampaignTable from '../../../../../components/all/Campaign/Table/CampaignTable';
import Paginator from '../../../../../components/all/Paginator/Paginator';
import { getCountries } from '../../../../../functionality/actions/countryAcitons';
import { getCampaignList } from '../../../../../functionality/actions/campaignActions';
import {
  paymentClear,
  paymentList,
} from '../../../../../functionality/actions/paymentActions';
import './CampaignsView.css';
import { connect } from 'react-redux';
import Spinner from '../../../../../components/all/Spinner';
import Error from '../../../../../components/all/Error';
import Swal from 'sweetalert2';

const CampaignView = (props) => {
  const {
    userReducer: { user_data },
    countryReducer: { countries },
    campaignReducer: {
      campaign_list_created,
      campaign_list_loading,
      campaign_page,
      campaign_total_pages,
    },
    match: { url },
    history,
    location: { search },
    paymentReducer: { payment_list_cards },
    getCampaignList,
    getCountries,
    paymentClear,
    paymentList,
    siteReducer: {
      site_texts: {
        text_alls,
        text_draft,
        text_finished,
        text_warning_add_payment_1,
        text_add_payment_here,
        text_my_campaigns,
      },
    },
  } = props;
  const [filterOption, setFilterOption] = useState(undefined);
  const [isGridView, setIsGridView] = useState(true);

  const handleFilterChange = async (event) => {
    const value = event.target.value;
    const route = `${url}?status=${value}`;
    setFilterOption(value);
    await history.push(value === '' ? url : route);
  };

  const handleViewChange = () => {
    setIsGridView(!isGridView);
  };

  useEffect(() => {
    const fetchCampaignList = async () => {
      try {
        await getCampaignList();
      } catch (error) {
        console.error(error);
      }
    };
    fetchCampaignList();
    setFilterOption('');

    if (countries.length === 0) {
      getCountries();
    }
    if (payment_list_cards.length === 0) {
      paymentList();
    }

    return () => {
      paymentClear();
    };
  }, []);

  useEffect(() => {
    getCampaignList(search);
  }, [search]);

  if (!user_data) return null;

  const { user_type_id } = user_data;

  const renderCard = campaign_list_created.map((campaign) => (
    <div className="col-md-6 col-lg-4" key={campaign.id}>
      <CampaignCard key={campaign.id} data={campaign} />
    </div>
  ));

  const pushNewCampaign = () => {
    if (payment_list_cards.length === 0) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text:
          text_warning_add_payment_1 ||
          'Para crear una campaña debe añadir un método de pago',
        footer: `<a href="/manager/pyment-methods">${
          text_add_payment_here || 'Añada su método de pago AQUÍ'
        }</a>`,
      });
    } else {
      history.push('/manager/campaign/new');
    }
  };

  const renderTable = (
    <div className="col-md-12">
      <CampaignTable campaignData={campaign_list_created} />
    </div>
  );
  return (
    <>
      <div className="title-manager">
        <div>
          <span className="fa-solid fa-bullhorn" />
          {text_my_campaigns || 'Mis Campañas'}
        </div>
        {user_type_id !== 8 && (
          <div className="new__campaign">
            <button onClick={() => pushNewCampaign()}>{'CREAR CAMPAÑA'}</button>
          </div>
        )}
      </div>
      <div className="my__campaigns">
        <div className="campaigns__view__options">
          <div className="campaigns__filter">
            <button
              value={undefined}
              onClick={handleFilterChange}
              className={filterOption === '' ? 'active' : ''}
            >
              {text_alls || 'Todos'}
            </button>
            <button
              value={2}
              onClick={handleFilterChange}
              className={filterOption === '2' ? 'active' : ''}
            >
              Live
            </button>
            {user_type_id !== 8 && (
              <button
                value={1}
                onClick={handleFilterChange}
                className={filterOption === '1' ? 'active' : ''}
              >
                {text_draft || 'En borrador'}
              </button>
            )}
            <button
              value={4}
              onClick={handleFilterChange}
              className={filterOption === '4' ? 'active' : ''}
            >
              {text_finished || 'Finalizado'}
            </button>
          </div>
          <div className="button__change__view">
            <button
              onClick={handleViewChange}
              className={`${isGridView ? 'active' : ''}`}
            >
              <i className="fa-solid fa-grip" />
            </button>
            <button
              onClick={handleViewChange}
              className={`${isGridView ? '' : 'active'}`}
            >
              <i className="fa-solid fa-list" />
            </button>
          </div>
        </div>
        <div className="row my__campaigns__container">
          {campaign_list_loading ? (
            <div className="spinner-loading">
              <Spinner type="purple" />
            </div>
          ) : (
            <>
              {campaign_list_created.length !== 0 &&
                (isGridView ? renderCard : renderTable)}
              {!campaign_list_created.length && <Error type="no-campaign" />}
            </>
          )}
        </div>
        {campaign_total_pages > 1 && (
          <Paginator
            totalPages={campaign_total_pages}
            currentPage={campaign_page}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  campaignReducer,
  userReducer,
  countryReducer,
  paymentReducer,
  siteReducer,
}) => {
  return {
    campaignReducer,
    userReducer,
    countryReducer,
    paymentReducer,
    siteReducer,
  };
};
const mapDispatchToProps = {
  getCampaignList,
  getCountries,
  paymentClear,
  paymentList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignView)
);
