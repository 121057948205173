import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InboxDetailView from './views/InboxDetailView';
import ManagerLayout from './layouts/ManagerLayout';

import * as chatActions from '../../../functionality/actions/chatActions';
import TopSpinner from '../../../components/all/TopSpinner';

const { chatList, chatListView, chatNew, chatClearMessages, chatProposalList } =
  chatActions;

class InboxDetailPage extends Component {
  state = {
    viewBoxMessage: false,
    codInbox: '',
  };

  componentDidMount() {
    const {
      chatList,
      chatProposalList,
      chatReducer: { chat_list_view, chat_list_proposal },
    } = this.props;
    if (!chat_list_view.length) {
      chatList();
    }
    if (!chat_list_proposal.length) {
      chatProposalList();
    }

    let codInbox = this.props.match.params.id;
    if (codInbox) {
      this.handleViewMessage(codInbox);
    }
  }

  handleViewMessage = (codInbox) => {
    const { chatNew } = this.props;
    chatNew(false);
    this.setState({ codInbox });
    setTimeout(() => {
      chatNew(true);
    }, 50);
  };

  handleFilter = (not_read) => {
    const {
      chatListView,
      chatReducer: { chat_list },
    } = this.props;
    if (not_read === 1) {
      let filter = chat_list.filter((item) => item.viewed === not_read);
      chatListView(filter);
    } else {
      chatListView(chat_list);
    }
  };

  componentDidUpdate(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.handleViewMessage(nextProps.match.params.id);
    }
  }

  render() {
    const {
      chatReducer: {
        chat_loading,
        chat_list_view,
        chat_new,
        chat_list_proposal,
      },
      siteReducer: {
        site_texts: { text_new_message },
      },
    } = this.props;

    return (
      <ManagerLayout>
        <div className="title-manager">
          <div>
            <span className="icon-chat" />
            Inbox
          </div>
          <Link to="/manager/inbox/new" className="btn inbox_new-message">
            <i className="icon-send-message"></i>
            {text_new_message
              ? text_new_message.toUpperCase()
              : 'NUEVO MENSAJE'}
          </Link>
        </div>
        {chat_loading ? (
          <TopSpinner />
        ) : (
          <InboxDetailView
            data={chat_list_view}
            dataPorposal={chat_list_proposal}
            actions={true}
            codInbox={this.state.codInbox}
            viewBoxMessage={chat_new}
            handleFilter={this.handleFilter}
          />
        )}
      </ManagerLayout>
    );
  }
}

const mapStateToProps = ({ chatReducer, siteReducer }) => {
  return {
    chatReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  chatList,
  chatListView,
  chatNew,
  chatClearMessages,
  chatProposalList,
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxDetailPage);
