import React, { Component } from "react";
import { connect } from "react-redux";
import { getAdsToday } from "../../../../functionality/actions/adActions";
import Header from "../../../../components/all/Header";
import Footer from "../../../../components/all/Footer";
import ManagerMenu from "../../../../components/user/manager/ManagerMenu";
import "./css/MarketplaceLayout.css";

class MarketplaceLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuVisible: "hidden",
      classContent: "all",
      scrollTop: 0,
    };
  }

  componentDidMount() {
    const {
      adReducer: { ad_loading_per_date },
      getAdsToday,
    } = this.props;

    if (ad_loading_per_date) {
      getAdsToday();
    }
  }

  handleChangeMenu = () => {
    this.setState({
      menuVisible: this.state.menuVisible === "hidden" ? "visible" : "hidden",
      classContent: this.state.classContent === "resize" ? "all" : "resize",
    });
  };

  moveScroll = (e) => {
    let lastScrollY = window.scrollY;
    this.setState({
      scrollTop: lastScrollY,
    });
  };

  render() {
    let height = window.innerHeight - 250;

    return (
      <div onWheel={this.moveScroll} ref={(box) => (this.box = box)}>
        <div className="marketplaceLayout_container">
          <ManagerMenu
            isVisible={this.state.menuVisible}
            onChangeMenu={this.handleChangeMenu}
          />
          <div
            className={`managerLayout_box-contain managerLayout_box-contain-${this.state.classContent}`}
            style={{ minHeight: height }}
          >
            <Header
              statusSearch={this.props.statusSearch}
              openCloseSearch={this.props.openCloseSearch}
              onChangeMenu={this.handleChangeMenu}
              top={this.state.scrollTop}
            />
            {this.props.children}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

MarketplaceLayout.defaultProps = {
  redirect: true,
};

const mapStateToProps = ({ userReducer, adReducer }) => {
  return {
    userReducer,
    adReducer,
  };
};

const mapDispatchToProps = {
  getAdsToday,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceLayout);
