import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ViewModal from "../../all/ViewModal";

const MySwal = withReactContent(Swal);

class RatingOrder extends Component {
  showError = (message) => {
    MySwal.fire({
      title: "Hubo un error",
      text: message,
      type: "error",
      confirmButtonColor: "#50318B",
    });
  };

  next = () => {
    const { orderNextRating, order_product_rating } = this.props;
    if (order_product_rating === "") {
      this.showError("Debes seleccionar una calificación para el producto.");
      return;
    }
    orderNextRating("user");
  };

  send = () => {
    const {
      order_product_rating,
      order_product_comment,
      order_user_rating,
      order_user_comment,
      cod_order,
      orderApproved,
    } = this.props;
    if (order_user_rating === "") {
      this.showError("Debes seleccionar una calificación para el vendedor.");
      return;
    }
    let data = {
      order_product_rating,
      order_product_comment,
      order_user_rating,
      order_user_comment,
    };
    orderApproved(cod_order, data);
  };

  renderRating = (action, value) => {
    const {
      siteReducer: {
        site_texts: { text_select_value },
      },
    } = this.props;

    return (
      <select
        className="form-control"
        onChange={(input) => action(input.target.value)}
        value={value}
      >
        <option value="">
          -- {text_select_value ? text_select_value : "Selecciona un valor"} --
        </option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </select>
    );
  };

  renderTextarea = (action, value) => {
    const {
      siteReducer: {
        site_texts: { text_comment },
      },
    } = this.props;

    return (
      <>
        <label>{text_comment ? text_comment : "Comentario"}:</label>
        <textarea
          onChange={(input) => action(input.target.value)}
          value={value}
          className="viewModal_textarea"
        />
      </>
    );
  };

  render() {
    const {
      order_status_rating,
      order_user_comment,
      order_user_rating,
      order_product_comment,
      order_product_rating,
      orderViewRating,
      oderUserRating,
      oderUserComment,
      oderProductRating,
      oderProductComment,
      siteReducer: {
        site_texts: {
          text_rate_product,
          text_qualification,
          text_close,
          text_next,
          text_send,
          text_qualify_seller,
        },
      },
    } = this.props;
    return (
      <ViewModal is_visible={this.props.is_visible}>
        {order_status_rating === "product" ? (
          <>
            <div className="viewModal_title">
              {text_rate_product ? text_rate_product : "CALIFICAR PRODUCTO"}
            </div>
            <div className="row">
              <div className="col-sm-3">
                <label>
                  {text_qualification ? text_qualification : "Calificación"}:
                </label>
              </div>
              <div className="col-sm-9">
                {this.renderRating(oderProductRating, order_product_rating)}
              </div>
            </div>
            {this.renderTextarea(oderProductComment, order_product_comment)}
          </>
        ) : (
          <>
            <div className="viewModal_title">
              {text_qualify_seller ? text_qualify_seller : "CALIFICAR VENDEDOR"}
            </div>
            <div className="row">
              <div className="col-sm-3">
                <label>
                  {text_qualification ? text_qualification : "Calificación"}:
                </label>
              </div>
              <div className="col-sm-9">
                {this.renderRating(oderUserRating, order_user_rating)}
              </div>
            </div>
            {this.renderTextarea(oderUserComment, order_user_comment)}
          </>
        )}
        <div className="viewModal_menu">
          <button
            className="btn btn-pymex-2"
            onClick={() => orderViewRating(false)}
          >
            {text_close ? text_close : "Cerrar"}
          </button>
          {order_status_rating === "product" ? (
            <button className="btn btn-pymex" onClick={this.next}>
              {text_next ? text_next : "Siguiente"}
            </button>
          ) : (
            <button className="btn btn-pymex" onClick={this.send}>
              {text_send ? text_send : "Enviar"}
            </button>
          )}
        </div>
      </ViewModal>
    );
  }
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(RatingOrder);
