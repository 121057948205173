import {
  SOCIAL_NETWORKS,
  SOCIAL_NETWORKS_FILTER,
  SOCIAL_NETWORKS_LOADING,
} from '../types/socialNetworksTypes';

const INITIAL_STATE = {
  social_networks: [],
  social_networks_loading: true,
  social_networks_filter: [],
};

const socialNetworksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SOCIAL_NETWORKS_LOADING:
      return {
        ...state,
        social_networks_loading: action.payload,
      };
    case SOCIAL_NETWORKS:
      return {
        ...state,
        social_networks: action.payload,
      };
    case SOCIAL_NETWORKS_FILTER:
      return {
        ...state,
        social_networks_filter: action.payload,
      };
    default:
      return state;
  }
};
export default socialNetworksReducer;
