import {
  PAYMENT_HISTORIAL_LOADING,
  PAYMENT_HISTORIAL_ERROR,
  PAYMENT_HISTORIAL,
  PAYMENT_HISTORIAL_PAGE,
} from "../types/paymentHistorialTypes";
import Api from "../Api";

export const paymentHistorial = () => (dispatch, getState) => {
  const { payment_historial_items_page } = getState().paymentHistorialReducer;
  dispatch({
    type: PAYMENT_HISTORIAL_LOADING,
  });
  Api.callMethod(
    "v1/pay-orders/historial",
    "GET",
    {},
    function (res) {
      if (res.status === 200) {
        let list_total = res.response.data;
        let list_view = list_total.slice(0, payment_historial_items_page);
        let data = {
          list_total,
          list_view,
        };
        dispatch({
          type: PAYMENT_HISTORIAL,
          payload: data,
        });
      } else {
        dispatch({
          type: PAYMENT_HISTORIAL_ERROR,
        });
      }
    },
    function (err) {
      dispatch({
        type: PAYMENT_HISTORIAL_ERROR,
      });
    }
  );
};

export const paymentHistorialPage = (data) => (dispatch) => {
  dispatch({
    type: PAYMENT_HISTORIAL_PAGE,
    payload: data,
  });
};
