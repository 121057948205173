import React from 'react';
import { formatNumber } from '../../../../utils/formatNumber';

function TikTok(props) {
  const { data } = props;

  return (
    <>
      {data.map((item) => (
        <div className="tiktok__content item" key={item.id}>
          <a href={item.share_url} target="_blank" rel="noopener noreferrer">
            <img src={item.cover_image_url} alt={`Content ${item.id}`} />
            <div className="tiktok__footer">
              <div className="tiktok__icon">
                <i className="fa-regular fa-eye" />
                {formatNumber(item.view_count)}
              </div>
              <div className="tiktok__icon">
                <i className="fa-solid fa-heart" />
                {formatNumber(item.like_count)}
              </div>
              <div className="tiktok__icon">
                <i className="fa-solid fa-share" />
                {formatNumber(item.share_count)}
              </div>
              <div className="tiktok__icon">
                <i className="fa-regular fa-comment" />
                {formatNumber(item.comment_count)}
              </div>
            </div>
          </a>
        </div>
      ))}
    </>
  );
}

export default TikTok;
