import React from 'react';
import { connect } from 'react-redux';
import {
  orderViewRating,
  orderNextRating,
  oderUserRating,
  oderUserComment,
  oderProductRating,
  oderProductComment,
  orderApproved,
  orderReport,
  orderViewReport,
  orderClaim,
} from '../../../../functionality/actions/orderActions';
import RatingOrder from '../RatingOrder';
import ReportOrder from '../ReportOrder';

function OrderRating(props) {
  const {
    orderReducer: {
      order_status_rating,
      order_user_comment,
      order_user_rating,
      order_product_comment,
      order_product_rating,
      order_view_rating,
      order_view_report,
      order_report,
    },
    siteReducer: {
      site_texts: {
        text_report_non_conformity,
        text_accept_satisfaction,
        text_status,
      },
      site_language,
    },
    userReducer: { user_data },
    orderViewRating,
    orderNextRating,
    oderUserRating,
    oderUserComment,
    oderProductRating,
    oderProductComment,
    orderApproved,
    orderReport,
    orderViewReport,
    orderClaim,
    orderDetail,
  } = props;

  if (!orderDetail || !user_data) return null;

  return (
    <>
      {orderDetail.status_id === 3 && orderDetail.seller.id !== user_data.id ? (
        <>
          <div className="row">
            <RatingOrder
              orderViewRating={orderViewRating}
              orderNextRating={orderNextRating}
              oderUserRating={oderUserRating}
              oderUserComment={oderUserComment}
              oderProductRating={oderProductRating}
              oderProductComment={oderProductComment}
              order_status_rating={order_status_rating}
              order_user_comment={order_user_comment}
              order_user_rating={order_user_rating}
              order_product_comment={order_product_comment}
              order_product_rating={order_product_rating}
              is_visible={order_view_rating}
              cod_order={orderDetail.reference}
              orderApproved={orderApproved}
            />
            <ReportOrder
              is_visible={order_view_report}
              orderReport={orderReport}
              order_report={order_report}
              orderViewReport={orderViewReport}
              cod_order={orderDetail.reference}
              orderClaim={orderClaim}
            />
            <div className="col-md-6">
              <button
                className="btn btn-pymex-2 btn-block"
                onClick={() => orderViewReport(true)}
              >
                {text_report_non_conformity || 'REPORTAR INCONFORMIDAD'}
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-pymex btn-block"
                onClick={() => {
                  orderViewRating(true);
                  orderNextRating(
                    orderDetail.type === 'Product' ? 'product' : 'user'
                  );
                }}
              >
                {text_accept_satisfaction || 'ACEPTA A SATISFACCIÓN'}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <label>{text_status || 'Estado'}:</label>
          <div className="form-control">
            {!site_language || site_language === 'en'
              ? orderDetail.status_english_name
              : orderDetail.status_name}
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = ({ orderReducer, siteReducer, userReducer }) => {
  return {
    orderReducer,
    siteReducer,
    userReducer,
  };
};

const mapDispatchToProps = {
  orderViewRating,
  orderNextRating,
  oderUserRating,
  oderUserComment,
  oderProductRating,
  oderProductComment,
  orderApproved,
  orderReport,
  orderViewReport,
  orderClaim,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderRating);
