/**
 * If the number is greater than or equal to 1 million, return the number divided by 1 million with 1
 * decimal place and the letter M appended to the end. If the number is greater than or equal to 1
 * thousand, return the number divided by 1 thousand with no decimal places and the letter K appended
 * to the end. Otherwise, return the number as a string.
 * @returns A function that takes a number and returns a string.
 */
export const formatNumber = (num) => {
  if (!num) return '0';

  num = parseInt(num);
  const million = 1000000;
  const thousand = 1000;
  if (num >= million) {
    return `${(num / million).toFixed(1)}M`;
  } else if (num >= thousand) {
    return `${Math.floor(num / thousand)}K`;
  } else {
    return num;
  }
};
