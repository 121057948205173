import React, { Component } from "react";
import ReactDom from "react-dom";
import { connect } from "react-redux";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./css/CropImages.css";

import * as cropperActions from "../../functionality/actions/cropperActions";

class CropImages extends Component {
  constructor(props) {
    super(props);
    this.cropper = null;
  }

  crop = () => {
    this.props.onCrop(this.cropper.getCroppedCanvas().toDataURL());
    this.props.cropperClear();
  };

  cancel = () => {
    this.props.cropperClear();
  };

  render() {
    const {
      cropperReducer: {
        cropper_visible,
        cropper_ratio_height,
        cropper_ratio_width,
        cropper_image,
      },
      siteReducer: {
        site_texts: { text_save, text_cancel },
      },
      cropWidth,
      cropHeight,
    } = this.props;

    const ratioWidth = cropWidth ? cropWidth : cropper_ratio_width;
    const ratioHeight = cropWidth ? cropHeight : cropper_ratio_height;

    return ReactDom.createPortal(
      <div className={`cropper_container ${cropper_visible}`}>
        <div className={`cropper_box ${cropper_visible}`}>
          <Cropper
            ref={(inst) => (this.cropper = inst)}
            src={cropper_image}
            style={{ height: 310, width: "100%" }}
            aspectRatio={ratioWidth / ratioHeight}
            guides={false}
          />
          <div className="cropper_menu">
            <button className="btn search_close" onClick={this.cancel}>
              {text_cancel ? text_cancel : "Cancelar"}
            </button>
            <button className="btn search_btn" onClick={this.crop}>
              {text_save ? text_save : "Guardar"}
            </button>
          </div>
        </div>
      </div>,
      document.getElementById("cropper")
    );
  }
}

const mapStateToProps = ({ cropperReducer, siteReducer }) => {
  return {
    cropperReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, cropperActions)(CropImages);
