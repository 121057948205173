export const SEARCH_CITY = 'SEARCH_CITY';
export const SEARCH_CATEGORY = 'SEARCH_CATEGORY';
export const SEARCH_RATING = 'SEARCH_RATING';
export const SEARCH_TYPE = 'SEARCH_TYPE';
export const SEARCH_NAME = 'SEARCH_NAME';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const SEARCH_CITIES = 'SEARCH_CITIES';
export const SEARCH_DEPARTMENT = 'SEARCH_DEPARTMENT';
export const SEARCH_REDIRECT = 'SEARCH_REDIRECT';
export const SEARCH_URL = 'SEARCH_URL';
export const SEARCH_LOADING = 'SEARCH_LOADING';
export const SEARCH_LIST = 'SEARCH_LIST';