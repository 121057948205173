import {
  HISTORIAL_LOADING,
  HISTORIAL_ERROR,
  HISTORIAL_VIEW,
  HISTORIAL_CHANGE_PAGE,
  HISTORIAL_CLEAR,
  HISTORIAL_CLEAR_FILTER,
  HISTORIAL_DATE,
  HISTORIAL_COD_ORDER,
  HISTORIAL_TYPE,
  HISTORIAL_FILTER,
} from "../types/historialTypes";
import Api from "../Api";

export const clearHistorial = () => (dispatch) => {
  dispatch({
    type: HISTORIAL_CLEAR,
  });
};

export const loadHistorial = () => (dispatch, getState) => {
  const { historial_items_per_page } = getState().historialReducer;
  dispatch({
    type: HISTORIAL_LOADING,
  });
  Api.callMethod(
    "v1/bank/historial",
    "GET",
    null,
    function (res) {
      if (res.status === 200) {
        let data = res.response.data;
        let data_view = data.slice(0, historial_items_per_page);
        let payload = {
          data,
          data_view,
        };
        dispatch({
          type: HISTORIAL_VIEW,
          payload,
        });
      } else {
        dispatch({
          type: HISTORIAL_ERROR,
          payload: "Error",
        });
      }
    },
    function (err) {
      dispatch({
        type: HISTORIAL_ERROR,
        payload: "Error",
      });
    }
  );
};

export const changePage = (data) => (dispatch) => {
  dispatch({
    type: HISTORIAL_CHANGE_PAGE,
    payload: data,
  });
};

export const historialDate = (value) => (dispatch) => {
  dispatch({
    type: HISTORIAL_DATE,
    payload: value,
  });
};

export const historialCodOrder = (value) => (dispatch) => {
  dispatch({
    type: HISTORIAL_COD_ORDER,
    payload: value,
  });
};

export const historialTypeOder = (value) => (dispatch) => {
  dispatch({
    type: HISTORIAL_TYPE,
    payload: value,
  });
};

export const clearFilterHistorial = () => (dispatch, getState) => {
  const { historial, historial_items_per_page } = getState().historialReducer;
  let view_historial = historial.slice(0, historial_items_per_page);
  dispatch({
    type: HISTORIAL_CLEAR_FILTER,
    payload: view_historial,
  });
};

export const historialFilter = (data) => (dispatch) => {
  dispatch({
    type: HISTORIAL_FILTER,
    payload: data,
  });
};
