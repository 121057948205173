import React, { Component } from 'react';
import { connect } from 'react-redux';
import { verified } from '../../../functionality/actions/userActions';
import { Redirect } from 'react-router';
import BoxForm from '../../../components/all/BoxForm';
import MarketplaceLayout from '../marketplace/layouts/MarketplaceLayout';
import Spinner from '../../../components/all/Spinner';
import ReactGA from 'react-ga';

const GA = process.env.REACT_APP_GA;
ReactGA.initialize(GA);

class VerifiedPage extends Component {
  componentDidMount() {
    this.props.verified();
  }

  render() {
    const {
      userReducer: { user_data, user_verified },
      siteReducer: {
        site_texts: { text_hi, text_validation },
      },
    } = this.props;

    if (user_verified) {
      ReactGA.event({
        category: 'Registro',
        action: 'Correo confirmado',
      });
      return <Redirect to="/manager/register/docs" />;
    }

    return (
      <MarketplaceLayout redirect={false}>
        <BoxForm>
          <div className="container">
            <div className="center">
              <Spinner type="purple" />
            </div>
            <h3>
              <span className="color-fuchsia">
                {text_hi ? text_hi : 'Hola'}{' '}
              </span>
              <span className="color-purple">
                {user_data.name} {user_data.lastname}
              </span>
            </h3>
            <p>
              {text_validation
                ? text_validation
                : 'Estamos validando el correo...'}
            </p>
          </div>
        </BoxForm>
      </MarketplaceLayout>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  verified,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifiedPage);
