import React from "react";
import "./css/SelectUser.css";
import profileDefault from "../../../images/believe.jpg";

function SelectUser(props) {
  let { data } = props;
  let name = data.username ? data.username : data.docs.business_name;
  return (
    <button onClick={() => props.selectUser(data)} className="selectUser_row">
      {!data.image ? (
        <img src={profileDefault} alt={name} className="selectUser_image" />
      ) : (
        <img src={data.image} alt={name} className="selectUser_image" />
      )}
      {name.toUpperCase()}
    </button>
  );
}

export default SelectUser;
