import React from "react";
import "./css/DetailSellerBuyer.css";
import ImageDefault from "../../../images/believe.jpg";
import Rating from "../marketplace/Rating";
import { connect } from "react-redux";

function renderUser(data, title) {
  let name = data.username ? data.username : data.business_name;
  return (
    <div className="detailSellerBuyer_box-content">
      <div className="detailSellerBuyer_title">{title}</div>
      <div className="detailSellerBuyer_box-user">
        {data.image ? (
          <img
            src={data.image}
            alt={data.business_name}
            className="detailSellerBuyer-img"
          />
        ) : (
          <img
            src={ImageDefault}
            alt={data.business_name}
            className="detailSellerBuyer-img"
          />
        )}
        <div className="detailSellerBuyer_box-name">
          <div className="detailSellerBuyer_text-name">
            {name
              .toUpperCase()
              .replace("-", " ")
              .replace("_", " ")
              .replace(".", " ")}
          </div>
          <div>
            <Rating value={data.rating} />
          </div>
        </div>
      </div>
    </div>
  );
}

function DetailSellerBuyer(props) {
  const {
    siteReducer: {
      site_texts: { text_seller, text_buyer },
    },
  } = props;

  return (
    <div className="detailSellerBuyer_continer">
      {renderUser(
        props.seller,
        text_seller ? text_seller.toUpperCase() : "VENDEDOR"
      )}
      {renderUser(
        props.buyer,
        text_buyer ? text_buyer.toUpperCase() : "COMPRADOR"
      )}
    </div>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(DetailSellerBuyer);
