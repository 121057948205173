import {
  ORDER_MY_LIST,
  ORDER_LOADING,
  ORDER_ERROR,
  ORDER_CLEAR,
  ORDER_CHANGE_SELLER,
  ORDER_CHANGE_BUYER,
  ORDER_DETAIL,
  ORDER_CLEAR_ERROR,
  ORDER_ALERT_ERROR,
  ORDER_USER_COMMENT,
  ORDER_USER_RATING,
  ORDER_PRODUCT_COMMENT,
  ORDER_PRODUCT_RATING,
  ORDER_REPORT,
  ORDER_VIEW_RATING,
  ORDER_VIEW_REPORT,
  ORDER_NEXT_RATING,
  ORDER_NOTIFICATION,
  ORDER_LIST_VIEW,
  ORDER_STATUS_CODE,
} from '../types/orderTypes';

const INITIAL_STATE = {
  order_loading: false,
  order_list: [],
  order_list_view: [],
  order_list_seller: [],
  order_list_buyer: [],
  order_list_view_seller: [],
  order_list_view_buyer: [],
  order_error: '',
  order_alert_error: '',
  order_detail: null,
  order_user_rating: '',
  order_user_comment: '',
  order_product_rating: '',
  order_product_comment: '',
  order_report: '',
  order_view_rating: false,
  order_view_report: false,
  order_status_rating: 'product',
  order_status_code: '',
};

const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ORDER_CLEAR:
      return {
        ...state,
        order_loading: false,
        order_list_seller: [],
        order_list_buyer: [],
        order_list_view_seller: [],
        order_list_view_buyer: [],
        order_error: '',
        order_detail: null,
        order_user_rating: '',
        order_user_comment: '',
        order_product_rating: '',
        order_product_comment: '',
        order_status_rating: 'product',
        order_report: '',
        order_view_rating: false,
        order_view_report: false,
      };
    case ORDER_LOADING:
      return {
        ...state,
        order_loading: action.payload,
      };
    case ORDER_MY_LIST:
      return {
        ...state,
        order_list: action.payload,
        order_list_view: action.payload,
        order_status_code: '',
      };
    case ORDER_LIST_VIEW:
      return {
        ...state,
        order_list_view: action.payload,
      };
    case ORDER_STATUS_CODE:
      return {
        ...state,
        order_status_code: action.payload,
      };
    case ORDER_NOTIFICATION: {
      return {
        ...state,
        order_list_seller: action.payload.seller,
        order_list_buyer: action.payload.buyer,
      };
    }
    case ORDER_ERROR:
      return {
        ...state,
        order_loading: false,
        order_error: action.payload,
      };
    case ORDER_CLEAR_ERROR:
      return {
        ...state,
        order_error: '',
        order_alert_error: '',
      };
    case ORDER_ALERT_ERROR:
      return {
        ...state,
        order_alert_error: action.payload,
      };
    case ORDER_CHANGE_SELLER:
      return {
        ...state,
        order_list_view_seller: action.payload,
      };
    case ORDER_CHANGE_BUYER:
      return {
        ...state,
        order_list_view_buyer: action.payload,
      };
    case ORDER_DETAIL:
      return {
        ...state,
        order_loading: false,
        order_detail: action.payload,
      };
    case ORDER_USER_COMMENT:
      return {
        ...state,
        order_user_comment: action.payload,
      };
    case ORDER_USER_RATING:
      return {
        ...state,
        order_user_rating: action.payload,
      };
    case ORDER_PRODUCT_COMMENT:
      return {
        ...state,
        order_product_comment: action.payload,
      };
    case ORDER_PRODUCT_RATING:
      return {
        ...state,
        order_product_rating: action.payload,
      };
    case ORDER_REPORT:
      return {
        ...state,
        order_report: action.payload,
      };
    case ORDER_VIEW_RATING:
      return {
        ...state,
        order_view_rating: action.payload,
        order_user_rating: '',
        order_user_comment: '',
        order_product_rating: '',
        order_product_comment: '',
      };
    case ORDER_VIEW_REPORT:
      return {
        ...state,
        order_view_report: action.payload,
        order_report: '',
      };
    case ORDER_NEXT_RATING:
      return {
        ...state,
        order_status_rating: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default orderReducer;
