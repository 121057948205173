import React from "react";

function Input(props) {
  const { value, onChange, type, placeholder, max } = props;

  return (
    <input
      type={type}
      value={value}
      onChange={(input) => onChange(input.target.value)}
      placeholder={placeholder}
      className="questions_input"
      maxLength={max}
    />
  );
}

Input.defaultProps = {
  type: "text",
  placeholder: "",
  max: "",
};

export default Input;
