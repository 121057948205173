import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { changeUserSocialNetworks } from '../../functionality/actions/userActions';
import Api from '../../functionality/Api';
import ModalSocial from './ModalSocial';
import SelectOption from './Components/SelectOption';
const {
  REACT_APP_FACEBOOK_APP: FACEBOOK_APP,
  REACT_APP_FACEBOOK_API: FACEBOOK_API,
} = process.env;

class FacebookButton extends Component {
  state = {
    profile: null,
    pages: [],
    page: null,
    show_modal: false,
    step: 0,
    access_token: null,
  };

  componentDidMount() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: FACEBOOK_APP,
        cookie: true,
        xfbml: true,
        version: FACEBOOK_API,
      });

      window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  onLogin = async () => {
    const {
      siteReducer: {
        site_texts: {
          text_facebook_question,
          text_facebook_message,
          text_facebook_yes,
          text_facebook_no,
          text_loading_social_data,
        },
      },
      onChangeLoading,
    } = this.props;

    Swal.fire({
      title: text_facebook_question || '¿Tienes Fan Page en Facebook?',
      text:
        text_facebook_message || 'Es para validar las métricas de la cuenta',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#50318B',
      cancelButtonColor: '#d33',
      confirmButtonText: text_facebook_yes || 'Si tengo',
      cancelButtonText: text_facebook_no || 'No tengo',
    }).then((res) => {
      if (res.value) {
        onChangeLoading(
          text_loading_social_data || 'Conectando con la red social...'
        );
        window.FB.getLoginStatus((response) => {
          if (response.status === 'connected') {
            window.FB.logout();
          }
          window.FB.login(
            (response) => {
              if (response.status === 'connected') {
                const {
                  authResponse: { accessToken },
                } = response;
                this.getData(accessToken);
              }
            },
            {
              scope:
                'user_birthday,user_location,user_likes,user_photos,user_videos,user_friends,user_posts,user_link,user_age_range,email,read_insights,pages_show_list,business_management,pages_read_engagement,pages_manage_metadata,pages_read_user_content',
            }
          );
        });
      }
    });
  };

  showError = (error) => {
    const { onChangeLoading } = this.props;

    Swal.fire({
      title: 'Upsss!!!',
      text: error,
      type: 'error',
      confirmButtonColor: '#50318B',
    }).then(() => {
      onChangeLoading('');
    });
  };

  getData = async (accessToken) => {
    const {
      siteReducer: {
        site_texts: { text_error_social },
      },
      onChangeLoading,
    } = this.props;
    this.setState({
      access_token: accessToken,
    });

    try {
      const facebookData = await Api.asyncCallMethod(
        'v1/facebook/get-data',
        'POST',
        {
          accessToken,
        }
      );
      if (facebookData && facebookData.response.code === 403) {
        this.showError(facebookData.response.message);
        return;
      }
      const facebookAccounts = await Api.asyncCallMethod(
        'v1/facebook/get-accounts',
        'POST',
        {
          accessToken,
        }
      );
      if (facebookAccounts && facebookAccounts.response.code === 403) {
        this.showError(facebookAccounts.response.message);
        return;
      }

      const { data } = facebookAccounts.response;
      const pages = [];

      data.forEach((page) => {
        const item = { ...page };
        item.cover = page.cover;
        item.picture =
          page.picture && page.picture.data ? page.picture.data.url : null;
        pages.push(item);
      });

      onChangeLoading('');
      this.setState({
        pages,
        show_modal: true,
        step: 1,
      });
    } catch (error) {
      console.log('Error getData: ', error);
      this.showError(
        text_error_social ||
          'Hubo un error al traer los datos, intentalo más tarde.'
      );
    }
  };

  onSelectPage = (page) => {
    this.setState({
      page,
    });
  };

  onCloseModal = () => {
    this.setState({
      show_modal: false,
      step: 0,
      page: null,
    });
  };

  getPageData = async () => {
    const { page } = this.state;
    const {
      siteReducer: {
        site_texts: {
          text_error_social,
          text_social_save,
          text_congratulations,
          text_social_success,
        },
      },
      onChangeLoading,
      changeUserSocialNetworks,
      id,
    } = this.props;

    this.onCloseModal();
    onChangeLoading('Trayendo datos...');

    const pageFeeds = await Api.asyncCallMethod('v1/facebook/feed', 'POST', {
      accessToken: page.access_token,
      pageId: page.id,
    });

    try {
      const insightsWeek = await Api.asyncCallMethod(
        'v1/facebook/get-insights-week',
        'POST',
        {
          pageToken: page.access_token,
          pageId: page.id,
        }
      );
      const insightsDays28 = await Api.asyncCallMethod(
        'v1/facebook/get-insights-days-28',
        'POST',
        {
          pageToken: page.access_token,
          pageId: page.id,
        }
      );
      const insightsDay = await Api.asyncCallMethod(
        'v1/facebook/get-insights-day',
        'POST',
        {
          pageToken: page.access_token,
          pageId: page.id,
        }
      );
      onChangeLoading(text_social_save || 'Guardando información...');

      const data = {
        social_network_id: id,
        profile: page,
        contents: pageFeeds.response.data,
        statistics_week: insightsWeek.response.data,
        statistics_days_28: insightsDays28.response.data,
        statistics_day: insightsDay.response.data,
      };

      const satusSave = await Api.asyncCallMethod('v1/facebook', 'POST', data);
      Swal.fire({
        title: text_congratulations || 'Felicitaciones!',
        text: text_social_success || 'Tu cuenta ha sido agregada con éxito',
        type: 'success',
        confirmButtonColor: '#50318B',
      }).then(() => {
        onChangeLoading('');
        changeUserSocialNetworks(satusSave.response.data);
      });
    } catch (error) {
      console.log('Error getPageData: ', error);
      this.showError(text_error_social);
    }
  };

  render() {
    const { show_modal, step, pages, page } = this.state;
    const {
      siteReducer: {
        site_texts: { text_facebook_select_pages, text_facebook_fan_page_text },
      },
      type,
      icon,
      name,
      isAdded,
    } = this.props;

    if (type === 'register') {
      return (
        <>
          {show_modal && (
            <ModalSocial>
              {step === 1 && (
                <SelectOption
                  options={pages}
                  itemSelected={page}
                  onSelect={this.onSelectPage}
                  onContinue={this.getPageData}
                  onCloseModal={this.onCloseModal}
                  title={text_facebook_select_pages || 'Selecciona tu fan page'}
                  text={text_facebook_fan_page_text}
                />
              )}
            </ModalSocial>
          )}
          <button
            className="socialNetworks_social-button facebook"
            onClick={this.onLogin}
          >
            <div className="socialNetworks_social">
              <div className="socialNetworks_social-icon">
                <i className={icon} />
              </div>
              {name}
            </div>
            {isAdded && <i className="fa-solid fa-check" />}
          </button>
        </>
      );
    }

    return (
      <div className="socialNetworks_social-button facebook">
        <div className="socialNetworks_social">
          <div className="socialNetworks_social-icon">
            <i className={icon} />
          </div>
          {name}
        </div>
        {isAdded && <i className="fa-solid fa-check" />}
      </div>
    );
  }
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

const mapDispatchToProps = {
  changeUserSocialNetworks,
};

export default connect(mapStateToProps, mapDispatchToProps)(FacebookButton);
