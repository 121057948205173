import React from "react";
import "./css/OpenImage.css";

function OpenImage(props) {
  const { type, preview } = props;

  let container =
    type === "inline" ? "openImage_container-inline" : "openImage_container";
  const previewClass = preview ? "preview" : "";
  return (
    <button
      className={`${container} d-flex ${previewClass}`}
      onClick={props.open}
    >
      <div className="openImage-box-icon-up">
        <div className="openImage-icon-up-1">
          <div className="openImage-icon-up-2 d-flex">
            <span className="icon-up" />
          </div>
        </div>
      </div>
      <div className="openImage_title">{props.title}</div>
    </button>
  );
}

export default OpenImage;
