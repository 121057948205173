import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import * as userActions from "../../../functionality/actions/userActions";

const MySwal = withReactContent(Swal);

class SettingsStep3 extends Component {
  componentDidMount() {
    const {
      userReducer: {
        user_data: { docs },
      },
      changeCodDepartment,
      changeCodCity,
      changeAddress,
      changeAddressAddon,
    } = this.props;
    if (docs && docs.department_id !== null) {
      changeCodDepartment(docs.department_id);
    }
    if (docs && docs.city_id !== null) {
      changeCodCity(docs.city_id);
    }
    if (docs && docs.address !== null) {
      changeAddress(docs.address);
    }
    if (docs && docs.address_addon !== null) {
      changeAddressAddon(docs.address_addon);
    }
  }

  renderDepartments = () => {
    const {
      userReducer: { departments, user_cod_department },
      siteReducer: {
        site_texts: { text_select },
      },
      changeCodDepartment,
    } = this.props;
    return (
      <select
        className="form-control"
        value={user_cod_department}
        onChange={(input) => changeCodDepartment(input.target.value)}
      >
        <option key="0">
          -- {text_select ? text_select : "Seleccionar"} --
        </option>
        {departments.map((department) => (
          <option value={department.id} key={department.id}>
            {department.name}
          </option>
        ))}
      </select>
    );
  };

  renderCities = () => {
    const {
      userReducer: { user_cod_department, cities, user_cod_city },
      siteReducer: {
        site_texts: { text_select },
      },
      changeCodCity,
    } = this.props;
    let citiesDepartment = cities.filter(
      (city) => city.department_id === parseInt(user_cod_department)
    );
    return (
      <select
        className="form-control"
        value={user_cod_city}
        onChange={(input) => changeCodCity(input.target.value)}
      >
        <option key="0">
          -- {text_select ? text_select : "Seleccionar"} --
        </option>
        {citiesDepartment.map((city) => (
          <option value={city.id} key={city.id}>
            {city.name}
          </option>
        ))}
      </select>
    );
  };

  showError = (message) => {
    MySwal.fire({
      title: "Upsss!!!",
      text: message,
      type: "warning",
      confirmButtonColor: "#50318B",
      onClose: () => this.props.userClearError(),
    });
  };

  saveDocs = () => {
    const {
      userReducer: {
        user_cod_department,
        user_cod_city,
        address,
        address_addon,
      },
      siteReducer: {
        site_texts: { text_department_city, text_write_address },
      },
      userSaveDocs,
    } = this.props;
    if (user_cod_department === "" || user_cod_city === "") {
      this.showError(
        text_department_city
          ? text_department_city
          : "Debes seleccionar un Departamento y Ciudad."
      );
      return;
    }
    if (address === "") {
      this.showError(
        text_write_address
          ? text_write_address
          : "Debes escribir una dirección."
      );
      return;
    }
    let data = {
      department_id: user_cod_department,
      city_id: user_cod_city,
      address,
      address_addon,
    };
    userSaveDocs(data, 4);
  };

  render() {
    const {
      changeAddress,
      changeAddressAddon,
      userReducer: { address, address_addon },
      siteReducer: {
        site_texts: {
          text_location_company,
          text_more_about_company,
          text_state,
          text_city,
          text_address,
          text_complement,
          text_tower,
          text_continue,
        },
      },
    } = this.props;
    return (
      <Fragment>
        <div className="settingsView_title">
          {text_location_company
            ? text_location_company.toUpperCase()
            : "UBICACIÓN DE LA EMPRESA O NEGOCIO"}
        </div>
        <div className="settingsView_description">
          {text_more_about_company
            ? text_more_about_company
            : "Necesitamos saber más sobre tu empresa, negocio o servicio antes de darte de alta en BAX. La información ingresada será visible para todos los miembros de la plataforma en tu perfil menos tus ventas mensuales promedio que sólo serán utilizadas para tu estudio de crédito dentro de la plataforma."}
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>
              {text_state ? text_state.toUpperCase() : "DEPARTAMENTO"}
            </label>
            {this.renderDepartments()}
          </div>
          <div className="col-md-6">
            <label>{text_city ? text_city.toUpperCase() : "CIUDAD"}</label>
            {this.renderCities()}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>
              {text_address ? text_address.toUpperCase() : "DIRECCIÓN"}
            </label>
            <input
              type="text"
              placeholder={
                text_address ? text_address.toUpperCase() : "DIRECCIÓN"
              }
              className="form-control"
              value={address}
              onChange={(input) => changeAddress(input.target.value)}
            />
          </div>
          <div className="col-md-6">
            <label>
              {text_complement ? text_complement.toUpperCase() : "COMPLEMENTO"}
            </label>
            <input
              type="text"
              placeholder={
                text_tower ? text_tower : "TORRE - OFICINA - APARTAMENTO"
              }
              className="form-control"
              value={address_addon}
              onChange={(input) => changeAddressAddon(input.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button className="settingsView_next" onClick={this.saveDocs}>
            <span className="icon-chek" />
            {text_continue ? text_continue : "Continuar"}
          </button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, userActions)(SettingsStep3);
