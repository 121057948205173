import React from 'react';
import Facebook from './Facebook';
import moment from 'moment';
import Instagram from './Instagram';
import Youtube from './Youtube';
import { connect } from 'react-redux';
import TikTok from './TikTok';

function Analytics(props) {
  const {
    statistics_days_28,
    updated_at,
    slug,
    statistics_lifetime,
    statistics_day,
    statistics_week,
    statistics,
    siteReducer: {
      site_texts: { text_updated_on },
    },
  } = props;
  const updateAt = moment(updated_at);

  if (
    !statistics_week &&
    !statistics_day &&
    !statistics_days_28 &&
    !statistics_lifetime &&
    !statistics
  ) {
    return null;
  }

  const getStatistics = () => {
    switch (slug) {
      case 'facebook':
        return <Facebook statistics={statistics_days_28} />;
      case 'instagram':
        return <Instagram statistics={statistics_days_28} />;
      case 'youtube':
        return <Youtube statistics={statistics_lifetime} />;
      case 'tiktok':
        return <TikTok statistics={statistics} />;
      default:
        return null;
    }
  };

  return (
    <div className="analytics">
      <div className="analitycs__title">
        <h3>Analitycs</h3>
        <span>
          {' '}
          {text_updated_on ? text_updated_on : 'Actualizado el'}:{' '}
          {updateAt.format('LL')}
        </span>
      </div>
      {getStatistics()}
    </div>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(Analytics);
