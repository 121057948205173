import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getInfluencerDetail } from '../../../../../functionality/actions/influencerActions';
import Spinner from '../../../../../components/all/Spinner';
import Error from '../../../../../components/all/Error';
import defaultImage from '../../../../../images/believe.jpg';
import defaultCoverpage from '../../../../../images/coverpage.jpg';
import ProfileHeader from '../../../../../components/all/ProfileHeader';
import SocialNetworks from '../../../../../components/all/SocialNetworks';
import './InfluencerProfileView.css';
import { getCampaignList } from '../../../../../functionality/actions/campaignActions';

const InfluencerProfileView = (props) => {
  const {
    username,
    getInfluencerDetail,
    siteReducer: {
      site_language,
      site_texts: { text_brands, text_about_me, text_cities, text_profile },
    },
    influencerReducer: {
      influencer_detail,
      influencer_detail_loading,
      influencer_detail_error,
    },
    userReducer: { user_data },
    campaignReducer: { campaign_list_created },
    getCampaignList,
  } = props;
  const labelName = site_language === 'es' ? 'name' : 'english_name';

  useEffect(() => {
    getInfluencerDetail(username);
    getCampaignList('?status=2');
  }, []);

  if (!user_data || !influencer_detail) {
    return null;
  }

  const Title = ({ title }) => {
    return (
      <>
        <div className="principal__data">
          <h4 className="influencer__title">{title}</h4>
        </div>
        <div className="separator" />
      </>
    );
  };

  const Pills = ({ title, data, labelName, secondLabel }) => {
    return (
      <>
        {title && <Title title={title} />}
        <div className="categories__container">
          {data.map((item) => (
            <span key={item.id}>
              {item[labelName]}
              {secondLabel && <small> ({item[secondLabel]})</small>}
            </span>
          ))}
        </div>
      </>
    );
  };

  const DataRow = ({ icon, text, type }) => {
    return (
      <div className="data__row">
        <i className={icon}></i>
        {type === 'link' ? (
          <a href={text} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        ) : (
          <span>{text}</span>
        )}
      </div>
    );
  };

  if (influencer_detail_loading) {
    return (
      <div className="spinner-loading">
        <Spinner type="purple" />
      </div>
    );
  }

  if (influencer_detail_error) {
    return <Error type="404" />;
  }

  const {
    id: profileId,
    name,
    lastname,
    image,
    coverpage,
    tag,
    social_network,
    tags,
    cities,
    targets,
    city,
    phone,
    website,
    description,
    brands,
    total_sales,
    total_shopping,
    rating,
    user_type_id: roleProfile,
    social_networks,
  } = influencer_detail;
  let currentRole = null;
  if (user_data) {
    currentRole = user_data.user_type_id;
  }
  return (
    <>
      <div className="container influencer__container">
        <div className="row">
          <div className="col-md-4 col-lg-3 background-white">
            <div className="profile__photo">
              <img src={image || defaultImage} alt="influencer__photo" />
            </div>
            <div className="influencer__information">
              <div className="principal__data">
                <h2 className="influencer__name">
                  {name} {lastname}
                </h2>
                {social_network && (
                  <div className={`influencer__icon ${social_network.slug}`}>
                    <i className={social_network.icon} />
                  </div>
                )}
                <h4 className="influencer__username">
                  <strong>@{username}</strong>
                </h4>
              </div>
              <Pills
                title={tag ? tag[labelName] : ''}
                data={tags}
                labelName={labelName}
              />
              <Pills
                title={text_cities || 'Ciudades'}
                data={cities}
                labelName="name"
                secondLabel="department_name"
              />
              <Pills title="Targets" data={targets} labelName={labelName} />
              <Title title={text_profile || 'Perfil'} />
              <div className="influencer__data">
                {city && (
                  <DataRow
                    icon="fa-solid fa-earth-americas"
                    text={`${city.name}-${city[`country_${labelName}`]}`}
                  />
                )}
                {phone && <DataRow icon="fa-solid fa-phone" text={phone} />}
                {website && (
                  <DataRow
                    icon="fa-solid fa-globe"
                    text={website}
                    type="link"
                  />
                )}
                {tag && (
                  <DataRow icon="fa-solid fa-star" text={tag[labelName]} />
                )}
              </div>
              {description && (
                <>
                  <div className="influencer__aboutme">
                    <h3>{text_about_me || 'Sobre mí'}</h3>
                    {description}
                  </div>
                </>
              )}
              {brands?.data.length !== 0 && (
                <>
                  <div className="influencer__brands">
                    <h3>{text_brands || 'Marcas'}</h3>
                    <div className="brands__logos row">
                      {brands?.data.map((brand, index) => (
                        <img
                          className="col-md-6"
                          alt="brand__logo"
                          src={brand.image}
                          key={index}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="separator"></div>
                </>
              )}
            </div>
          </div>
          {/* lado derecho */}
          <div className="col-md-8 col-lg-9 background-white">
            <div className="banner__content">
              <div>
                <img
                  src={coverpage || defaultCoverpage}
                  className="userView_coverpage"
                  alt="banner__influencer"
                />
                <ProfileHeader
                  rating={rating}
                  sales={total_sales}
                  shopping={total_shopping}
                  roleProfile={roleProfile}
                  currentRole={currentRole}
                  campaingsLive={campaign_list_created}
                  username={username}
                />
              </div>
            </div>
            <div className="metrics__content">
              <SocialNetworks
                networks={social_networks}
                profileId={profileId}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  influencerReducer,
  siteReducer,
  userReducer,
  campaignReducer,
}) => {
  return {
    influencerReducer,
    siteReducer,
    userReducer,
    campaignReducer,
  };
};

const mapDispatchToProps = {
  getInfluencerDetail,
  getCampaignList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerProfileView);
