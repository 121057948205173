import React, { useState } from 'react';
import { connect } from 'react-redux';
import Metrics from '../Metrics/Metrics';

function SocialNetworks(props) {
  const {
    networks,
    userReducer: { user_data },
  } = props;
  const [metricOf, setMetricOf] = useState(networks[0].name);
  let currentId = null;

  if (user_data) {
    currentId = user_data.id;
  }

  const MetricButton = ({ value }) => (
    <button
      value={value}
      className={metricOf === value ? 'active' : ''}
      onClick={(e) => setMetricOf(value)}
    >
      {value}
    </button>
  );

  const currentNetwork = networks.find((network) => network.name === metricOf);

  return (
    <div className="row metrics__content">
      <div className="metrics__menu">
        {networks.map((network) => (
          <MetricButton value={network.name} key={network.id} />
        ))}
      </div>
      <Metrics
        network={currentNetwork}
        currentId={currentId}
        profileId={currentNetwork.user_id}
      />
    </div>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

export default connect(mapStateToProps, null)(SocialNetworks);
