import React, { Component } from "react";
import { connect } from "react-redux";
import MarketPlaceLayout from "./layouts/MarketplaceLayout";
import CompaniesCategoryView from "./views/CompaniesCategoryView";
import TopSpinner from "../../../components/all/TopSpinner";

class CompaniesCategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      principalSlider: [],
      statusSearch: false,
    };
  }

  componentDidMount() {
    let principalSlider = [];
    for (let i = 0; i < 5; i++) {
      let link = i % 2 === 0 ? "/product/1" : "";
      let data = {
        id: i,
        image: "",
        link,
        name: `principalSlider ${i}`,
      };
      principalSlider.push(data);
    }
    this.setState({
      principalSlider,
    });
  }

  openCloseSearch = (statusSearch) => {
    this.setState({
      statusSearch,
    });
  };

  render() {
    const {
      loading_init,
      adReducer: { ad_loading_per_date },
    } = this.props;
    let viewActivity =
      this.props.match.params.id !== undefined
        ? this.props.match.params.id
        : false;
    return (
      <MarketPlaceLayout
        statusSearch={this.state.statusSearch}
        openCloseSearch={this.openCloseSearch}
      >
        {!loading_init && !ad_loading_per_date ? (
          <CompaniesCategoryView
            viewActivity={viewActivity}
            principalSlider={this.state.principalSlider}
            openCloseSearch={this.openCloseSearch}
          />
        ) : (
          <TopSpinner />
        )}
      </MarketPlaceLayout>
    );
  }
}

const mapStateToProps = ({ userReducer, adReducer }) => {
  return {
    ...userReducer,
    adReducer,
  };
};

export default connect(mapStateToProps)(CompaniesCategoryPage);
