export const ORDER_LOADING = "ORDER_LOADING";
export const ORDER_MY_LIST = "ORDER_MY_LIST";
export const ORDER_CLEAR = "ORDER_CLEAR";
export const ORDER_ERROR = "ORDER_ERROR";
export const ORDER_ALERT_ERROR = "ORDER_ALERT_ERROR";
export const ORDER_CLEAR_ERROR = "ORDER_CLEAR_ERROR";
export const ORDER_CHANGE_SELLER = "ORDER_CHANGE_SELLER";
export const ORDER_CHANGE_BUYER = "ORDER_CHANGE_BUYER";
export const ORDER_DETAIL = "ORDER_DETAIL";
export const ORDER_PRODUCT_RATING = "ORDER_PRODUCT_RATING";
export const ORDER_PRODUCT_COMMENT = "ORDER_PRODUCT_COMMENT";
export const ORDER_USER_RATING = "ORDER_USER_RATING";
export const ORDER_USER_COMMENT = "ORDER_USER_COMMENT";
export const ORDER_REPORT = "ORDER_REPORT";
export const ORDER_VIEW_RATING = "ORDER_VIEW_RATING";
export const ORDER_VIEW_REPORT = "ORDER_VIEW_REPORT";
export const ORDER_NEXT_RATING = "ORDER_NEXT_RATING";
export const ORDER_NOTIFICATION = "ORDER_NOTIFICATION";
export const ORDER_LIST_VIEW = "ORDER_LIST_VIEW";
export const ORDER_STATUS_CODE = "ORDER_STATUS_CODE";
