import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { adRemove } from '../../../../functionality/actions/adActions';
import AdItem from './AdItem';
import './css/AdList.css';

function AdList(props) {
  const {
    adReducer: { ad_my_list },
    userReducer: { user_data },
    paymentReducer: { payment_list_cards },
    adRemove,
    siteReducer: {
      site_texts: {
        text_start_campaign,
        text_payment_methods,
        text_no_have_campaigns,
      },
    },
  } = props;

  return (
    <>
      <div className="adList_header">
        {user_data.total_cards || payment_list_cards.length ? (
          <Link to="/manager/ads/create" className="btn btn-bax">
            {text_start_campaign ? text_start_campaign : 'Iniciar Campaña'}
          </Link>
        ) : (
          <Link to="/manager/pyment-methods" className="btn btn-bax">
            {text_payment_methods ? text_payment_methods : 'Métodos de Pago'}
          </Link>
        )}
      </div>
      {!ad_my_list.length ? (
        <div className="adList_no-content">
          {text_no_have_campaigns
            ? text_no_have_campaigns
            : 'No tienes campañas publicitarias creadas.'}
        </div>
      ) : (
        <div className="row">
          {ad_my_list.map((item) => (
            <AdItem {...item} key={item.id} remove={adRemove} />
          ))}
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({
  adReducer,
  userReducer,
  paymentReducer,
  siteReducer,
}) => {
  return {
    adReducer,
    userReducer,
    paymentReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  adRemove,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdList);
