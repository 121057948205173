import {
  CROPPER_VISIBLE,
  CROPPER_CROP_IMAGE,
  CROPPER_CLEAR,
} from '../types/cropperTypes';

export const openCloseCropper = (status) => (dispatch) => {
  dispatch({
    type: CROPPER_VISIBLE,
    payload: status,
  });
}

export const cropperImage = (config) => (dispatch) => {
  dispatch({
    type: CROPPER_CROP_IMAGE,
    payload: config,
  });
}

export const cropperClear = () => (dispatch) => {
  dispatch({
    type: CROPPER_CLEAR,
  })
}