import React from 'react';

function Twitter(props) {
  const { data, profile } = props;

  return (
    <>
      {data.map((item) => (
        <div key={item.id} className="twitter_container item">
          <a
            href={`https://twitter.com/${profile.username}/status/${item.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="twitter_header">
              <img src={profile.profile_image_url} alt="Imagen Profile" />
              <div>
                <div className="twitter_name">{profile.name}</div>
                <div className="twitter_username">@{profile.username}</div>
              </div>
            </div>
            <div className="twitter_body">{item.text}</div>
            <div className="twitter_footer">
              <div>
                <i className="fa-regular fa-comment" />
                {`${item.public_metrics.retweet_count}`}
              </div>
              <div>
                <i className="fa-solid fa-retweet" />
                {`${item.public_metrics.quote_count}`}
              </div>
              <div>
                <i className="fa-regular fa-heart" />
                {`${item.public_metrics.like_count}`}
              </div>
              <div>
                <i className="fa-solid fa-chart-simple" />
                {`${item.public_metrics.impression_count}`}
              </div>
            </div>
          </a>
        </div>
      ))}
    </>
  );
}

export default Twitter;
