import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAdMyList,
  changeAdMyListLoading,
} from "../../../functionality/actions/adActions";
import ManagerLayout from "./layouts/ManagerLayout";
import AdsView from "./views/AdsView";

class AdsPage extends Component {
  componentDidMount() {
    const {
      adReducer: { ad_my_list_loading },
      getAdMyList,
    } = this.props;

    if (ad_my_list_loading) {
      getAdMyList();
    }
  }

  componentWillUnmount() {
    this.props.changeAdMyListLoading(true);
  }

  render() {
    return (
      <ManagerLayout>
        <AdsView />
      </ManagerLayout>
    );
  }
}

const mapStateToProps = ({ adReducer }) => {
  return {
    adReducer,
  };
};

const mapDispatchToProps = {
  getAdMyList,
  changeAdMyListLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsPage);
