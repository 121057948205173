import React from 'react';
import {connect} from 'react-redux';
import './css/QuantityItemCart.css';

import * as cartActions from '../../functionality/actions/cartActions';

const {
  addItemProduct, subtractItemProduct,
} = cartActions;

function QuantityItemCart(props) {
  const {
    addItemProduct, subtractItemProduct, cod_product, min, max
  } = props;
  return(
    <div className="quantity_box">
      <button
        onClick={() => subtractItemProduct(cod_product, min)}
        className="quantity_btn"
      >-</button>
      <div className="quantity_number">{props.total_items}</div>
      <button
        onClick={() => addItemProduct(cod_product, max)}
        className="quantity_btn"
      >+</button>
    </div>
  )
}

const mapStateToProps = ({cartReducer}) => cartReducer;

const mapDispatchToProps = {
  addItemProduct, subtractItemProduct,
}

export default connect(mapStateToProps, mapDispatchToProps)(QuantityItemCart);