import React, { useState, useEffect, useMemo } from 'react';
import OptionCheckBox from '../../OptionCheckBox/OptionCheckBox';
import MultiRange from '../../MultiRange/MultiRange';
import OptionCheckboxImage from '../../OptionCheckboxImage/OptionCheckboxImage';
import InputTextArea from '../../InputTextArea/InputTextArea';
import DemographicUbication from '../../DemographicUbication/DemographicUbication';
import { getTargets } from '../../../../functionality/actions/targetsActions';
import {
  setCampaignTargetGender,
  setCampaignTargetMaxAge,
  setCampaignTargetMinAge,
  setCampaignSocialNetworks,
  setCampaignTargetDescription,
  removeCityToCampaign,
  setCampaignCountry,
  setCampaignCities,
  removeCountryToCampaign,
} from '../../../../functionality/actions/campaignActions';
import { getSocialNetworks } from '../../../../functionality/actions/socialNetworksActions';
import { connect } from 'react-redux';
import Spinner from '../../../../../src/components/all/Spinner.js';

const CampaignFormSetp2 = (props) => {
  const {
    targetReducer: { targets, targets_loading },
    campaignReducer: {
      campaign_target_gender,
      campaign_target_min_age,
      campaign_target_max_age,
      campaign_social_networks,
      campaign_target_description,
      campaign_cities,
      campaign_country,
    },
    socialNetworksReducer: { social_networks, social_networks_loading },
    getTargets,
    getSocialNetworks,
    setCampaignTargetGender,
    setCampaignTargetMaxAge,
    setCampaignTargetMinAge,
    setCampaignSocialNetworks,
    setCampaignTargetDescription,
    removeCityToCampaign,
    setCampaignCities,
    setCampaignCountry,
    removeCountryToCampaign,
    siteReducer: {
      site_texts: {
        text_target_brand,
        text_gender,
        text_to,
        text_age_range,
        text_campaign_networks,
        text_target_description,
        text_where_target_campaign,
        text_country,
        text_city,
        text_or,
        text_return,
      },
    },
  } = props;

  const [cityOrCountry, setCityOrCountry] = useState('');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      if (campaign_cities.length) {
        setCityOrCountry('city');
      } else if (campaign_country.length) {
        setCityOrCountry('country');
      }
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    if (targets.length === 0) {
      getTargets();
    }
    if (social_networks.length === 0) {
      getSocialNetworks();
    }
  }, []);

  const RenderPills = ({ title, items, action }) => {
    return (
      <>
        <h3 className="block__title">{title}</h3>
        <div className="location__container">
          {items.map((item) => (
            <div className="location__pills" key={item.id}>
              {item.name}
              {item.department_name ? ` (${item.department_name})` : null}
              <button
                className="pill__btn"
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  action(item.id);
                }}
              >
                <i className="fa-solid fa-trash" />
              </button>
            </div>
          ))}
        </div>
      </>
    );
  };

  const Form = ({ cityOrCountry, campaignCities, campaignCountry }) => {
    if (cityOrCountry === '') return null;
    const items = cityOrCountry === 'city' ? campaignCities : campaignCountry;
    const title = cityOrCountry === 'city' ? 'Ciudades' : 'Países';
    const action =
      cityOrCountry === 'city' ? removeCityToCampaign : removeCountryToCampaign;

    return (
      <div className="ubication__target">
        <RenderPills title={title} items={items} action={action} />
        <DemographicUbication
          cityOrCountry={cityOrCountry}
          containerClass={'demographic__ubication'}
        />
      </div>
    );
  };

  const memoForm = useMemo(
    () => (
      <Form
        cityOrCountry={cityOrCountry}
        campaignCities={campaign_cities}
        campaignCountry={campaign_country}
      />
    ),
    [cityOrCountry, campaign_cities, campaign_country]
  );

  return (
    <div className="campaign__form">
      <div className="form__column">
        <div className="campaign__block">
          <h3 className="block__title">
            {text_target_brand ? text_target_brand : 'Target Marca'}
          </h3>
          <div className="brands__targets">
            <div className="target">
              <h4 className="target__title">
                {text_gender ? text_gender : 'Género'}
              </h4>
              <div className="target__content">
                {targets_loading ? (
                  <div className="spinner-loading">
                    <Spinner type="purple" />
                  </div>
                ) : (
                  targets.map((target) => (
                    <OptionCheckBox
                      label={target.name}
                      key={target.id}
                      value={target.id}
                      checked={campaign_target_gender.includes(target.id)}
                      handleInputChange={setCampaignTargetGender}
                    />
                  ))
                )}
              </div>
            </div>
            <div className="target">
              <h4 className="target__title">
                {text_age_range ? text_age_range : 'Rango de edad'}
              </h4>
              <div className="target__content">
                <MultiRange
                  title={text_to ? text_to : 'entre'}
                  min={18}
                  max={90}
                  defaultMin={campaign_target_min_age}
                  defaultMax={campaign_target_max_age}
                  setMinInput={setCampaignTargetMinAge}
                  setMaxInput={setCampaignTargetMaxAge}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="campaign__block">
          <h3 className="block__title">
            {text_campaign_networks
              ? text_campaign_networks
              : 'Redes para la campaña'}
          </h3>
          <div className="row">
            {social_networks_loading ? (
              <div className="spinner-loading">
                <Spinner type="purple" />
              </div>
            ) : (
              social_networks.map((network) => {
                if (!network.in_campaign) return null;
                return (
                  <div
                    className={`col-md-6 col-lg-4 circulo-red-social circulo-${network.slug}`}
                    key={network.id}
                  >
                    <OptionCheckboxImage
                      icon={network.icon}
                      label={network.name}
                      value={network.id}
                      checked={campaign_social_networks.includes(network.id)}
                      handleInputChange={setCampaignSocialNetworks}
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <div className="form__column">
        <InputTextArea
          isRequired={false}
          label={
            text_target_description
              ? text_target_description
              : 'Descripción del target'
          }
          name={'description__target'}
          defaultValue={campaign_target_description}
          handleInputChange={setCampaignTargetDescription}
        />
        {cityOrCountry === '' && (
          <div
            className="
          cityOrCountry"
          >
            <h3 className="block__title col-md-12">
              {text_where_target_campaign
                ? text_where_target_campaign
                : 'A donde vas a dirigir tu campaña'}
            </h3>
            <div className="buttons__container">
              <button
                className=""
                onClick={(event) => {
                  event.preventDefault();
                  setCityOrCountry('country');
                }}
              >
                {text_country ? text_country : 'País'}
              </button>
              <span>{text_or ? text_or : 'O'}</span>
              <button
                className=""
                onClick={(event) => {
                  event.preventDefault();
                  setCityOrCountry('city');
                }}
              >
                {text_city ? text_city : 'Ciudad'}
              </button>
            </div>
          </div>
        )}
        {memoForm}
        {cityOrCountry !== '' && (
          <div>
            <button
              type="button"
              className="btn__back"
              onClick={(event) => {
                event.preventDefault();
                setCampaignCities([]);
                setCampaignCountry([]);
                setCityOrCountry('');
              }}
            >
              {text_return ? text_return : 'Regresar'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  targetReducer,
  campaignReducer,
  socialNetworksReducer,
  siteReducer,
}) => {
  return {
    targetReducer,
    campaignReducer,
    socialNetworksReducer,
    siteReducer,
  };
};
const mapDispatchToProps = {
  getTargets,
  getSocialNetworks,
  setCampaignTargetGender,
  setCampaignTargetMaxAge,
  setCampaignTargetMinAge,
  setCampaignSocialNetworks,
  setCampaignTargetDescription,
  setCampaignCities,
  removeCityToCampaign,
  setCampaignCountry,
  removeCountryToCampaign,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFormSetp2);
