export const COMPANY_LOADING = "company_loading";
export const COMPANY_DATA = "company_data";
export const COMPANY_CHANGE_PAGE = "company_change_page";
export const COMPANY_CLEAR = "company_clear";
export const COMPANY_ERROR = "company_error";
export const COMPANY_COMMENTS = "company_comments";
export const COMPANY_LIST_PRODUCTS = "COMPANY_LIST_PRODUCTS";
export const COMPANY_LIST_VIEW_PRODUCTS = "COMPANY_LIST_VIEW_PRODUCTS";
export const COMPANY_SELLER = "COMPANY_SELLER";
export const COMPANY_SHOPPING = "COMPANY_SHOPPING";
export const COMPANY_PRODUCTS_LOADING = "COMPANY_PRODUCTS_LOADING";
export const COMPANY_COMMENTS_LOADING = "COMPANY_COMMENTS_LOADING";
