import React, { useState } from 'react';
import { connect } from 'react-redux';
import { changeOrderStatus } from '../../../../functionality/actions/orderActions';

function OrderChangeStatus(props) {
  const {
    siteReducer: {
      site_language,
      site_texts: {
        text_status,
        text_change_of_states,
        text_select_state,
        text_accept,
        text_refuse,
        text_deliver,
        text_change_status,
        text_you_must_select_state,
      },
    },
    changeOrderStatus,
    showError,
    orderDetail,
  } = props;
  const [status, onChangeStatus] = useState('');

  const changeStatus = () => {
    if (status === '') {
      showError(text_you_must_select_state || 'Debes seleccionar un estado.');
      return;
    }
    if (
      parseInt(status) === 2 ||
      parseInt(status) === 3 ||
      parseInt(status) === 4
    ) {
      changeOrderStatus(orderDetail.id, parseInt(status));
    }
  };

  return (
    <>
      {orderDetail.status_id === 3 ||
      orderDetail.status_id === 4 ||
      orderDetail.status_id === 5 ? (
        <>
          <label>{text_status || 'Estado'}:</label>
          <div className="form-control">
            {!site_language || site_language === 'en'
              ? orderDetail.status_english_name
              : orderDetail.status_name}
          </div>
        </>
      ) : (
        <>
          <label>{text_change_of_states || 'Cambio de Estados'}:</label>
          <div className="row">
            <div className="col-md-8">
              <select
                className="form-control"
                onChange={(input) =>
                    onChangeStatus(input.target.value)
                }
              >
                <option>
                  -- {text_select_state || 'Seleccionar Estado'} --
                </option>
                {orderDetail.status_id === 1 && (
                  <>
                    <option value="2">{text_accept || 'Aceptar'}</option>
                    <option value="4">{text_refuse || 'Rechazar'}</option>
                  </>
                )}
                {orderDetail.status_id === 2 && (
                  <>
                    <option value="3">{text_deliver || 'Entregar'}</option>
                  </>
                )}
              </select>
            </div>
            <div className="col-md-4">
              <button
                className="btn btn-pymex btn-block"
                onClick={changeStatus}
              >
                {text_change_status || 'Cambiar Estado'}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = ({ orderReducer, userReducer, siteReducer }) => {
  return {
    orderReducer,
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  changeOrderStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderChangeStatus);
