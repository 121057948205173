import {
  BRAND_USER_LIST,
  BRAND_CATEGORY,
  BRAND_COVER,
  BRAND_DESCRIPTION,
  BRAND_ID,
  BRAND_IMAGE,
  BRAND_IS_ACTIVE,
  BRAND_NAME,
  BRAND_CLEAR,
  BRAND_QUESTION,
  BRAND_LOADING,
} from "../types/brandTypes";

const INITIAL_STATE = {
  brand_user_list: [],
  brand_question: null,
  brand_id: null,
  brand_category: "",
  brand_name: "",
  brand_description: "",
  brand_image: "",
  brand_cover: "",
  brand_is_active: true,
  brand_loading: false,
};

const brandReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BRAND_CLEAR:
      return {
        ...state,
        brand_id: null,
        brand_category: "",
        brand_name: "",
        brand_description: "",
        brand_image: "",
        brand_cover: "",
        brand_is_active: true,
      };
    case BRAND_LOADING:
      return {
        ...state,
        brand_loading: action.payload,
      };
    case BRAND_QUESTION:
      return {
        ...state,
        brand_question: action.payload,
      };
    case BRAND_USER_LIST:
      return {
        ...state,
        brand_user_list: action.payload,
      };
    case BRAND_ID:
      return {
        ...state,
        brand_id: action.payload,
      };
    case BRAND_CATEGORY:
      return {
        ...state,
        brand_category: action.payload,
      };
    case BRAND_NAME:
      return {
        ...state,
        brand_name: action.payload,
      };
    case BRAND_DESCRIPTION:
      return {
        ...state,
        brand_description: action.payload,
      };
    case BRAND_IMAGE:
      return {
        ...state,
        brand_image: action.payload,
      };
    case BRAND_COVER:
      return {
        ...state,
        brand_cover: action.payload,
      };
    case BRAND_IS_ACTIVE:
      return {
        ...state,
        brand_is_active: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default brandReducer;
