import {
  FAVORITE_CLEAR,
  FAVORITE_LOADING,
  FAVORITE_PRODUCTS,
  FAVORITE_PRODUCTS_VIEW,
  FAVORITE_COMPANIES,
  FAVORITE_COMPANIES_VIEW,
  FAVORITE_COMPANIES_PAGE,
  FAVORITE_PRODUCTS_PAGE,
} from "../types/favoriteTypes";

const INITIAL_STATE = {
  favorite_loading: true,
  favorite_item_per_page: 8,
  favorite_products: [],
  favorite_products_view: [],
  favorite_products_page: 1,
  favorite_companies: [],
  favorite_companies_view: [],
  favorite_companies_page: 1,
};

const favoriteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FAVORITE_CLEAR:
      return {
        ...state,
        favorite_products: [],
        favorite_products_view: [],
        favorite_products_page: 1,
        favorite_companies: [],
        favorite_companies_view: [],
        favorite_companies_page: 1,
      };
    case FAVORITE_PRODUCTS_PAGE:
      return {
        ...state,
        favorite_products_page: action.payload,
      };
    case FAVORITE_LOADING:
      return {
        ...state,
        favorite_loading: action.payload,
      };
    case FAVORITE_PRODUCTS:
      return {
        ...state,
        favorite_products: action.payload,
      };
    case FAVORITE_PRODUCTS_VIEW:
      return {
        ...state,
        favorite_products_view: action.payload,
      };
    case FAVORITE_COMPANIES:
      return {
        ...state,
        favorite_companies: action.payload,
      };
    case FAVORITE_COMPANIES_VIEW:
      return {
        ...state,
        favorite_companies_view: action.payload,
      };
    case FAVORITE_COMPANIES_PAGE:
      return {
        ...state,
        favorite_companies_page: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default favoriteReducer;
