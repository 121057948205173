import React, { useEffect, useState } from 'react';
import './CampaignDetailView.css';
import { NavLink, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getCampaignDetail,
  changeStatus,
  campaignClear,
  publishBreif,
} from '../../../../../functionality/actions/campaignActions';
import { porposalClear } from '../../../../../functionality/actions/porposalActions';
import Spinner from '../../../../../components/all/Spinner';
import Swal from 'sweetalert2';

const CampaignDetailView = (props) => {
  const {
    userReducer: {
      // user_data: { id: user_id },
      user_data,
    },
    campaignReducer: { campaign_current_detail, campaign_current_loading },
    match: {
      params: { id: consecutive },
      url,
    },
    getCampaignDetail,
    changeStatus,
    publishBreif,
    porposalClear,
    siteReducer: {
      site_texts: {
        text_search_influencer,
        text_finish_campaign,
        text_inbox,
        text_resume,
        text_brief,
        text_my_proposals,
        text_influencers,
        text_posts,
        text_are_you_sure,
        text_not_reverse_action,
        text_cancel,
      },
    },
  } = props;

  const [option, setOption] = useState('');
  const statusColors = {
    2: '#28cae4',
    4: '#D9348A',
    draft: '#50318B',
  };

  const basePath = `/manager/campaign/${consecutive}`;

  useEffect(() => {
    const fetchCampaignDetail = async () => {
      try {
        await getCampaignDetail(consecutive);
      } catch (error) {
        console.log(error);
      }
    };

    if (consecutive !== campaign_current_detail?.consecutive) {
      fetchCampaignDetail();
    }
    return () => {
      porposalClear();
    };
  }, []);
  const statusColor = statusColors[campaign_current_detail?.campaign_status_id];

  if (user_data === null) {
    return <Redirect to={'/'} />;
  }

  const handleFinishCampaign = async () => {
    const swalStyled = Swal.mixin({
      customClass: {
        confirmButton: 'button__confirm',
        cancelButton: 'button__cancel',
      },
      buttonsStyling: false,
    });

    swalStyled
      .fire({
        title: text_are_you_sure || '¿Estas seguro?',
        text: text_not_reverse_action || 'No podras revertir esta acción.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28cae4',
        cancelButtonColor: '#50318b',
        confirmButtonText: text_finish_campaign || 'Finalizar Campaña',
        cancelButtonText: text_cancel || 'Cancelar',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value === true) {
          await changeStatus(campaign_current_detail?.consecutive, 4);
          getCampaignDetail(consecutive);
        }
      });
  };

  return (
    <>
      {campaign_current_loading ? (
        <div className="spinner-loading">
          <Spinner type="purple" />
        </div>
      ) : (
        <>
          {url === basePath && <Redirect to={`${basePath}/inbox`} />}
          {url === basePath && user_data?.user_type_id === 8 && (
            <Redirect to={`${basePath}/breif`} />
          )}
          {campaign_current_detail &&
            campaign_current_detail?.campaign_status_id === 1 && (
              <Redirect to={`/manager/campaign/${consecutive}/edit`} />
            )}
          <div className="campaign-detail-page">
            <div className="title-manager">
              <div>
                <span className="fa-solid fa-bullhorn" />
                {campaign_current_detail?.name}
              </div>
              <div className="info__section">
                <div className="general__data">
                  <div className="campaign__date">
                    <i className="fa-regular fa-calendar"></i>
                    <span>{`${campaign_current_detail?.date_start} - ${campaign_current_detail?.date_end}`}</span>
                  </div>
                  <div className="campaign__members">
                    <i className="fa-solid fa-user"></i>
                    <span>
                      {campaign_current_detail?.total_members
                        ? campaign_current_detail?.total_members
                        : '0'}
                    </span>
                  </div>
                  <div
                    className="campaign__status"
                    style={{ backgroundColor: statusColor }}
                  >
                    <span className="status__name">
                      {campaign_current_detail?.status &&
                        campaign_current_detail?.status.name}
                    </span>
                  </div>
                </div>
                <div className="creator__actions">
                  {campaign_current_detail?.user_id === user_data?.id &&
                    campaign_current_detail?.campaign_status_id === 2 && (
                      <button
                        className=""
                        onClick={async () => {
                          await publishBreif(
                            campaign_current_detail.consecutive
                          );
                          getCampaignDetail(consecutive);
                        }}
                      >
                        {text_search_influencer || 'Buscar nuevos influencers'}
                      </button>
                    )}
                  {campaign_current_detail?.user_id === user_data?.id &&
                    campaign_current_detail?.campaign_status_id === 2 && (
                      <button className="" onClick={handleFinishCampaign}>
                        {text_finish_campaign || 'Finalizar'}
                      </button>
                    )}
                </div>
              </div>
            </div>
            <div className="campaign__detail__container">
              <div className="options__campaign">
                {(user_data?.user_type_id !== 8 ||
                  campaign_current_detail?.infleuncer_status === 2) && (
                  <NavLink
                    to={`${basePath}/inbox`}
                    value="inbox"
                    className={option === 'inbox' ? 'active' : ''}
                    onClick={(e) => setOption(e.target.value)}
                  >
                    {text_inbox || 'Inbox'}
                  </NavLink>
                )}
                {user_data?.user_type_id !== 8 && (
                  <NavLink
                    to={`${basePath}/resume`}
                    value="resume"
                    className={option === 'resume' ? 'active' : ''}
                    onClick={(e) => setOption(e.target.value)}
                  >
                    {text_resume || 'Resumen'}
                  </NavLink>
                )}

                <NavLink
                  to={`${basePath}/breif`}
                  value="breif"
                  className={option === 'breif' ? 'active' : ''}
                  onClick={(e) => setOption(e.target.value)}
                >
                  {text_brief || 'Brief'}
                </NavLink>

                {user_data?.user_type_id === 8 &&
                  campaign_current_detail?.infleuncer_status === 2 && (
                    <NavLink
                      to={`${basePath}/porpose`}
                      value="influencer"
                      className={option === 'influencer' ? 'active' : ''}
                      onClick={(e) => setOption(e.target.value)}
                    >
                      {text_my_proposals || 'Mis Propuestas'}
                    </NavLink>
                  )}

                {user_data?.user_type_id !== 8 && (
                  <NavLink
                    to={`${basePath}/influencer`}
                    value="influencer"
                    className={option === 'influencer' ? 'active' : ''}
                    onClick={(e) => setOption(e.target.value)}
                  >
                    {text_influencers || 'Influencers'}
                  </NavLink>
                )}
                {(user_data?.user_type_id !== 8 ||
                  campaign_current_detail?.infleuncer_status === 2) && (
                  <NavLink
                    to={`${basePath}/posts`}
                    value="post"
                    className={option === 'post' ? 'active' : ''}
                    onClick={(e) => setOption(e.target.value)}
                  >
                    {text_posts || 'Posts'}
                  </NavLink>
                )}
              </div>
              <div className="campaign__content">{props.children}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ campaignReducer, userReducer, siteReducer }) => {
  return {
    campaignReducer,
    siteReducer,
    userReducer,
  };
};

const mapDispatchToProps = {
  getCampaignDetail,
  changeStatus,
  campaignClear,
  porposalClear,
  publishBreif,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignDetailView)
);
