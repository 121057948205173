import {
  HISTORIAL_LOADING,
  HISTORIAL_VIEW,
  HISTORIAL_ERROR,
  HISTORIAL_CLEAR,
  HISTORIAL_CHANGE_PAGE,
  HISTORIAL_CLEAR_FILTER,
  HISTORIAL_DATE,
  HISTORIAL_COD_ORDER,
  HISTORIAL_TYPE,
  HISTORIAL_FILTER,
} from "../types/historialTypes";

const INITIAL_STATE = {
  historial_loading: false,
  historial: [],
  historial_view: [],
  historial_page: 1,
  historial_items_per_page: 12,
  historial_error: "",
  historial_date: "",
  historial_cod_order: "",
  historial_type: "",
  historial_filter: [],
};

const historialReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HISTORIAL_CLEAR:
      return {
        ...state,
        historial_loading: false,
        historial: [],
        historial_view: [],
        historial_page: 1,
        historial_error: "",
        historial_date: "",
        historial_cod_order: "",
        historial_type: "",
        historial_filter: [],
      };
    case HISTORIAL_LOADING:
      return {
        ...state,
        historial_loading: true,
      };
    case HISTORIAL_ERROR:
      return {
        ...state,
        historial_error: action.payload,
      };
    case HISTORIAL_VIEW:
      return {
        ...state,
        historial: action.payload.data,
        historial_view: action.payload.data_view,
        historial_loading: false,
      };
    case HISTORIAL_CHANGE_PAGE:
      return {
        ...state,
        historial_view: action.payload.data_view,
        historial_page: action.payload.page,
      };
    case HISTORIAL_CLEAR_FILTER:
      return {
        ...state,
        historial_view: action.payload,
        historial_date: "",
        historial_cod_order: "",
        historial_type: "",
        historial_filter: [],
      };
    case HISTORIAL_DATE:
      return {
        ...state,
        historial_date: action.payload,
      };
    case HISTORIAL_COD_ORDER:
      return {
        ...state,
        historial_cod_order: action.payload,
      };
    case HISTORIAL_TYPE:
      return {
        ...state,
        historial_type: action.payload,
      };
    case HISTORIAL_FILTER:
      return {
        ...state,
        historial_view: action.payload.data_view,
        historial_filter: action.payload.filter,
        historial_page: 1,
      };
    default:
      return {
        ...state,
      };
  }
};

export default historialReducer;
