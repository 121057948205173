import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getBrandByUser,
  brandEdit,
} from '../../functionality/actions/brandActions';
import Spinner from '../all/Spinner';
import BrandForm from './BrandForm';
import './css/ManagerBrands.css';
import Image from '../../images/believe.jpg';

class ManagerBrands extends Component {
  state = {
    modal: '',
  };

  componentDidMount() {
    const {
      getBrandByUser,
      brandReducer: { brand_user_list },
    } = this.props;

    if (!brand_user_list.length) {
      getBrandByUser();
    }
  }

  render() {
    const {
      brandReducer: { brand_loading, brand_user_list },
      siteReducer: {
        site_texts: {
          text_brand_add_title,
          text_brand_title,
          text_brand_no_list,
          text_edit_2,
        },
      },
      onCropImage,
      brandEdit,
      isInManager,
    } = this.props;

    return (
      <>
        <BrandForm
          modal={this.state.modal}
          setModal={(modal) => this.setState({ modal })}
          onCropImage={onCropImage}
        />
        <div className={`ManagerBrands_content`}>
          <div
            className={`ManagerBrands_header ${
              isInManager ? 'justify-content-end' : ''
            }`}
          >
            {!isInManager && (
              <div className="ManagerBrands_title">
                {text_brand_title || 'Agregar marca'}
              </div>
            )}
            <button onClick={() => this.setState({ modal: 'visible' })}>
              {text_brand_add_title || 'Agregar marca'}
            </button>
          </div>
          <div className={`ManagerBrands_body`}>
            {brand_user_list && !brand_user_list.length ? (
              <p>{text_brand_no_list || 'No tienes marcas registradas.'}</p>
            ) : (
              brand_user_list &&
              brand_user_list && (
                <>
                  {brand_loading ? (
                    <div className="spinner-loading">
                      <Spinner type="purple" />
                    </div>
                  ) : (
                    <>
                      <div className="ManagerBrands_row">
                        <div
                          className={`ManagerBrands_item ${
                            isInManager ? 'item__title' : ''
                          }`}
                        >
                          Imagen
                        </div>
                        <div
                          className={`ManagerBrands_item ${
                            isInManager ? 'item__title' : ''
                          }`}
                        >
                          Nombre
                        </div>
                        <div
                          className={`ManagerBrands_item ${
                            isInManager ? 'item__title' : ''
                          }`}
                        >
                          Acciones
                        </div>
                      </div>
                      {brand_user_list.map((brand) => (
                        <div key={brand.id} className="ManagerBrands_row">
                          <div className="ManagerBrands_item">
                            <img
                              src={brand.image ? brand.image : Image}
                              alt={brand.name}
                            />
                          </div>
                          <div className="ManagerBrands_item">{brand.name}</div>
                          <div className="ManagerBrands_item">
                            <button
                              onClick={() => {
                                brandEdit(brand);
                                this.setState({ modal: 'visible' });
                              }}
                              className="ManagerBrands_edit"
                            >
                              {text_edit_2 || 'Editar'}
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )
            )}
          </div>
        </div>
      </>
    );
  }
}

ManagerBrands.defaultProps = {
  onCropImage: null,
};

const mapStateToProps = ({ brandReducer, siteReducer }) => {
  return {
    brandReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  getBrandByUser,
  brandEdit,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerBrands);
