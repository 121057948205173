import React from "react";
import Spinner from "./Spinner";
import "./css/SessionLoading.css";
import Logo from "../../images/logo-footer.png";

function SessionLoading() {
  return (
    <div className="box-loading centerCenter">
      <img src={Logo} alt="Logo" />
      <Spinner />
    </div>
  );
}

export default SessionLoading;
