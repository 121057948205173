import {
  PRODUCT_ADD_IMAGES,
  PRODUCT_NAME,
  PRODUCT_BRAND_ID,
  PRODUCT_DESCRIPTION,
  PRODUCT_PRICE,
  PRODUCT_PRICE_PROMO,
  PRODUCT_CATEGORY,
  PRODUCT_TYPE,
  PRODUCT_SKU,
  PRODUCT_ID,
  PRODUCT_ERROR,
  PRODUCT_CLEAR_ERROR,
  PRODUCT_IS_ACTIVE,
  PRODUCT_SUCCESS,
  PRODUCT_CLEAR,
  PRODUCT_LOADING,
  PRODUCT_MY_LIST,
  PRODUCT_CHANGE_MANAGER_PAGE,
  PRODUCT_CHANGE_MANAGER_SEARCH,
  PRODUCT_DETAIL_ERROR,
  PRODUCT_SHORT_DESCRIPTION,
  PRODUCT_DETAIL,
  PRODUCT_CLEAR_DETAIL,
  PRODUCT_DATE_PROMO,
  PRODUCT_COMMENTS,
  PRODUCT_LOADING_IMAGES,
  PRODUCT_RELATED_PRODUCTS,
  PRODUCT_DETAIL_LOADING,
} from '../types/productTypes';
import Api from '../Api';
import Swal from 'sweetalert2';
import { USER_LOADING } from '../types/userTypes';

export const productClear = () => (dispatch) => {
  dispatch({
    type: PRODUCT_CLEAR,
  });
};

export const productAddImages = (images) => (dispatch) => {
  dispatch({
    type: PRODUCT_ADD_IMAGES,
    payload: images,
  });
};

export const productLoadingImages = (payload) => (dispatch) => {
  dispatch({
    type: PRODUCT_LOADING_IMAGES,
    payload,
  });
};

export const productImagesUpload = (files) => (dispatch, getState) => {
  const {
    site_texts: {
      text_images_error_total,
      text_images_error_size,
      text_uploading_images_error,
    },
  } = getState().siteReducer;

  if (files.length > 3) {
    Swal.fire({
      title: 'Upsss!!!',
      text: text_images_error_total
        ? text_images_error_total
        : 'No puedes subir más de 3 imágenes',
      type: 'warning',
      confirmButtonColor: '#50318b',
    });
    return null;
  }

  let data = new FormData();
  for (let i = 0; i < files.length; i++) {
    const size = files[i].size;
    if (size > 2000000) {
      Swal.fire({
        title: 'Upsss!!!',
        text: text_images_error_size
          ? text_images_error_size
          : 'No puedes subir imágenes de un pero mayor a 2MB',
        type: 'warning',
        confirmButtonColor: '#50318b',
      });
      return null;
    } else {
      data.append('files[]', files[i]);
    }
  }

  dispatch(productLoadingImages(true));

  Api.submitForm('v1/product-images', data, (res) => {
    if (res.status === 201) {
      dispatch(productAddImages(res.response.images));
    } else {
      Swal.fire({
        title: 'Upsss!!!',
        text: text_uploading_images_error
          ? text_uploading_images_error
          : 'Hubo un error al subir las imágenes, vuelve a intentarlo más tarde.',
        type: 'warning',
        confirmButtonColor: '#50318b',
      });
    }
    dispatch(productLoadingImages(false));
  });
};

export const productDeleteImage = (index) => (dispatch, getState) => {
  const { product_images } = getState().productReducer;
  const image = product_images[index];
  product_images.splice(index, 1);

  dispatch(productAddImages(product_images));
  Api.callMethod(
    `v1/product-images/${image.id}`,
    'DELETE',
    null,
    (res) => {
      console.log(res);
    },
    (error) => {
      console.log('Error productDeleteImage: ', error);
    }
  );
};

export const productId = (id) => (dispatch) => {
  dispatch({
    type: PRODUCT_ID,
    payload: id,
  });
};

export const productName = (value) => (dispatch) => {
  dispatch({
    type: PRODUCT_NAME,
    payload: value,
  });
};
export const setProductBrandId = (value) => (dispatch) => {
  console.log(parseFloat(value));
  dispatch({
    type: PRODUCT_BRAND_ID,
    payload: parseFloat(value),
  });
};

export const productDescription = (value) => (dispatch) => {
  dispatch({
    type: PRODUCT_DESCRIPTION,
    payload: value,
  });
};

export const productShortDescription = (value) => (dispatch) => {
  if (value.length <= 300) {
    dispatch({
      type: PRODUCT_SHORT_DESCRIPTION,
      payload: value,
    });
  }
};

export const productPrice = (value) => (dispatch) => {
  dispatch({
    type: PRODUCT_PRICE,
    payload: value,
  });
};

export const productPricePromo = (value) => (dispatch) => {
  dispatch({
    type: PRODUCT_PRICE_PROMO,
    payload: value,
  });
};

export const productCategory = (value) => (dispatch) => {
  dispatch({
    type: PRODUCT_CATEGORY,
    payload: [value],
  });
};

export const productType = (value) => (dispatch) => {
  dispatch({
    type: PRODUCT_TYPE,
    payload: value,
  });
};

export const productSku = (value) => (dispatch) => {
  dispatch({
    type: PRODUCT_SKU,
    payload: value,
  });
};

export const productClearError = () => (dispatch) => {
  dispatch({
    type: PRODUCT_CLEAR_ERROR,
  });
};

export const productIsActive = (value) => (dispatch) => {
  dispatch({
    type: PRODUCT_IS_ACTIVE,
    payload: value,
  });
};

export const productGetEdit = (id) => (dispatch, getState) => {
  const {
    site_texts: { text_product_sorry },
  } = getState().siteReducer;

  Api.callMethod(
    `v1/products/${id}`,
    'get',
    null,
    (res) => {
      if (res.status === 200) {
        console.log(res);
        const {
          response: {
            data: {
              id,
              images,
              name,
              brand_id,
              short_description,
              description,
              price,
              price_promo,
              categories,
              product_type_id,
              is_active,
              promotion_date,
              available,
            },
          },
        } = res;
        dispatch(productId(id));
        dispatch(productAddImages(images));
        dispatch(productName(name));
        dispatch(setProductBrandId(brand_id));
        dispatch(productShortDescription(short_description));
        dispatch(productDescription(description));
        dispatch(productPrice(price));
        dispatch(productCategory(categories[0]));
        dispatch(productType(product_type_id));
        dispatch(productIsActive(is_active));
        if (price_promo) {
          dispatch(productPricePromo(price_promo));
        }
        if (promotion_date) {
          dispatch(productDatePromo(promotion_date));
        }
        if (available) {
          dispatch(productSku(available));
        }
      } else {
        dispatch(
          productDetailError(
            text_product_sorry
              ? text_product_sorry
              : 'Lo sentimos, no puedes ver este producto.'
          )
        );
      }
    },
    (error) => {
      console.log(error);
      dispatch(
        productDetailError(
          text_product_sorry
            ? text_product_sorry
            : 'Lo sentimos, no puedes ver este producto.'
        )
      );
    }
  );
};

export const productSave = (data) => (dispatch, getState) => {
  const {
    site_texts: { text_product_error },
  } = getState().siteReducer;
  const method = data.id ? 'PUT' : 'POST';
  const url = data.id ? `v1/products/${data.id}` : 'v1/products';
  const status = data.id ? 200 : 201;

  Api.callMethod(
    url,
    method,
    data,
    function (res) {
      console.log(res);
      if (res.status === status) {
        dispatch({
          type: PRODUCT_SUCCESS,
          payload: res.response.data,
        });
      } else {
        dispatch({
          type: PRODUCT_ERROR,
          payload: text_product_error
            ? text_product_error
            : 'Lo sentimos, no se pudo guardar el producto o servicio, vuelve a intentarlo más tarde.',
        });
      }
    },
    function (err) {
      dispatch({
        type: PRODUCT_ERROR,
        payload: text_product_error
          ? text_product_error
          : 'Lo sentimos, no se pudo guardar el producto o servicio, vuelve a intentarlo más tarde.',
      });
    }
  );
};

const productShowDetail = (product) => (dispatch) => {
  let images = JSON.parse(product.images);
  dispatch(productAddImages(images));
  dispatch(productId(product.id_product));
  dispatch(productName(product.name));
  dispatch(productShortDescription(product.short_description));
  dispatch(productDescription(product.description));
  dispatch(productPrice(product.price));
  dispatch(productPricePromo(product.price_promo ? product.price_promo : 0));
  dispatch(productCategory(product.cod_category));
  dispatch(productType(product.cod_type_product));
  dispatch(productIsActive(product.is_active));
  if (product.sku) {
    dispatch(productSku(product.sku));
  }
  if (product.promotion_date) {
    dispatch(productDatePromo(product.promotion_date));
  }
};

export const getProductsByUser = (user_id) => (dispatch, getState) => {
  const { product_item_per_page } = getState().productReducer;
  dispatch({
    type: PRODUCT_LOADING,
  });
  Api.callMethod(
    `v1/users/${user_id}/products`,
    'GET',
    null,
    function (res) {
      if (res.status === 200) {
        let data = {
          list: res.response.data,
          listView: res.response.data.slice(0, product_item_per_page),
        };
        dispatch({
          type: PRODUCT_MY_LIST,
          payload: data,
        });
      } else {
        dispatch({
          type: PRODUCT_ERROR,
          payload: 'Lo sentimos, no se pudieron traer los productos.',
        });
      }
    },
    function (err) {
      dispatch({
        type: PRODUCT_ERROR,
        payload: 'Otra cosa.',
      });
    }
  );
};

export const myProducts =
  (codProduct = null) =>
  (dispatch, getState) => {
    const { product_item_per_page } = getState().productReducer;

    dispatch({
      type: PRODUCT_LOADING,
    });
    Api.callMethod(
      'v1/products/my/list',
      'GET',
      null,
      function (res) {
        if (res.status === 200) {
          let data = {
            list: res.response.data,
            listView: res.response.data.slice(0, product_item_per_page),
          };
          dispatch({
            type: PRODUCT_MY_LIST,
            payload: data,
          });
          if (codProduct) {
            const product = data.list.find(
              (product) => parseInt(product.id_product) === parseInt(codProduct)
            );
            if (product) {
              dispatch(productShowDetail(product));
            }
          }
        } else {
          dispatch({
            type: PRODUCT_ERROR,
            payload: 'Lo sentimos, no se pudieron traer los productos.',
          });
        }
      },
      function (err) {
        dispatch({
          type: PRODUCT_ERROR,
          payload: 'Otra cosa.',
        });
      }
    );
  };

export const productChangePage = (listView, currentPage) => (dispatch) => {
  let data = {
    listView,
    currentPage,
  };
  dispatch({
    type: PRODUCT_CHANGE_MANAGER_PAGE,
    payload: data,
  });
};

export const productChangeSearch =
  (listView, searchList, currentPage) => (dispatch) => {
    let data = {
      listView,
      searchList,
      currentPage,
    };
    dispatch({
      type: PRODUCT_CHANGE_MANAGER_SEARCH,
      payload: data,
    });
  };

export const productDetailError = (value) => (dispatch) => {
  dispatch({
    type: PRODUCT_DETAIL_ERROR,
    payload: value,
  });
};

export const productDetail = (cod_product) => (dispatch, getState) => {
  const { list_products } = getState().userReducer;
  dispatch({
    type: PRODUCT_DETAIL_LOADING,
    payload: true,
  });

  Api.callMethod(
    `v1/products/${cod_product}/detail`,
    'get',
    null,
    (res) => {
      const {
        response: { data },
      } = res;
      const relatedProducs = list_products.filter(
        (product) =>
          product.slug !== cod_product &&
          product.array_categories.indexOf(data.array_categories[0]) >= 0
      );
      dispatch({
        type: PRODUCT_DETAIL,
        payload: data,
      });
      dispatch({
        type: PRODUCT_RELATED_PRODUCTS,
        payload: relatedProducs.slice(0, 8),
      });
      dispatch({
        type: PRODUCT_DETAIL_LOADING,
        payload: false,
      });
    },
    (error) => {
      console.log('Error productDetail: ', error);
    }
  );
};

export const productDatePromo = (value) => (dispatch) => {
  dispatch({
    type: PRODUCT_DATE_PROMO,
    payload: value,
  });
};

export const productClearDetail = () => (dispatch) => {
  dispatch({
    type: PRODUCT_CLEAR_DETAIL,
  });
};

export const productComments = (cod_product) => (dispatch) => {
  Api.callMethod(
    `v1/products/${cod_product}/comments`,
    'GET',
    null,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: PRODUCT_COMMENTS,
          payload: res.response.data,
        });
      }
    }
  );
};

export const importProducts = () => (dispatch, getState) => {
  const {
    site_texts: {
      text_products_loading,
      text_products_error,
      text_congratulations,
      text_products_upload,
      text_products_upload_error,
    },
  } = getState().siteReducer;

  const file = document.getElementById('products-import');

  if (file.value === '') {
    Swal.fire({
      title: 'Upsss!!!',
      text: text_products_error
        ? text_products_error
        : 'Debes seleccionar el archivo de excel.',
      type: 'warning',
      confirmButtonColor: '#50318b',
    });
  }

  let data = new FormData();
  data.append('import', file.files[0]);

  dispatch({
    type: USER_LOADING,
    payload: text_products_loading
      ? text_products_loading
      : 'Importando productos..',
  });

  Api.submitForm('v1/products/import', data, (res) => {
    if (res.status === 201) {
      Swal.fire({
        title: text_congratulations ? text_congratulations : '¡Felicidades!',
        text: text_products_upload
          ? text_products_upload
          : 'Los productos se importaron con éxito..',
        type: 'success',
        confirmButtonColor: '#50318b',
      }).then(() => {
        window.location.href = '/manager/products';
      });
    } else {
      Swal.fire({
        title: 'Upsss!!!',
        text: text_products_upload_error
          ? text_products_upload_error
          : 'Hubo un error al importar los productos.',
        type: 'warning',
        confirmButtonColor: '#50318b',
      }).then(() => {
        dispatch({
          type: USER_LOADING,
          payload: '',
        });
      });
    }
  });
};
