import React, { useEffect, useMemo } from 'react';
import CampaignDetailView from './views/CampaignDetailView/CampaignDetailView';
import ManagerLayout from './layouts/ManagerLayout';
import CampaignInboxView from './views/CampaignDetailView/CampaignInbox/CampaignInboxView';
import { connect } from 'react-redux';
import {
  chatList,
  chatListView,
  chatNew,
  chatClearMessages,
  chatProposalList,
} from '../../../functionality/actions/chatActions';

const CampaignInboxPage = (props) => {
  const {
    chatReducer: { chat_list_view, chat_new, chat_list_proposal },
    chatList,
  } = props;

  useEffect(() => {
    if (!chat_list_view.length) {
      chatList();
    }
    if (!chat_list_proposal.length) {
      chatProposalList();
    }

    return () => {};
  }, []);

  const memoCampaignInboxView = useMemo(
    () => <CampaignInboxView viewBoxMessage={chat_new} />,
    [chat_new]
  );

  return (
    <ManagerLayout>
      <CampaignDetailView>{memoCampaignInboxView}</CampaignDetailView>
    </ManagerLayout>
  );
};

const mapStateToProps = ({ chatReducer }) => {
  return {
    chatReducer,
  };
};

const mapDispatchToProps = {
  chatList,
  chatListView,
  chatNew,
  chatClearMessages,
  chatProposalList,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignInboxPage);
