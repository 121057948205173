import React from 'react';
import defaultImage from '../../../../images/believe.jpg';
import { formatNumber } from '../../../../utils/formatNumber';

function Instagram(props) {
  const { data, profile, image } = props;

  const getContent = (item) => {
    if (item.media_type) {
      let image = '';
      if (item.media_type.toLowerCase() === 'image') {
        image = item.media_url;
      } else {
        image = item.thumbnail_url;
      }

      return (
        <>
          <div className="facebook__image">
            {item.media_type.toLowerCase() === 'video' && (
              <i className="fa-regular fa-circle-play" />
            )}
            <img src={image} alt="Content" />
          </div>
          <div className="facebook__share">
            <div className="facebook__icon">
              <i className="fa-regular fa-thumbs-up" />
              {formatNumber(item.like_count)}
            </div>
            <div className="facebook__icon">
              <i className="fa-regular fa-comment" />
              {formatNumber(item.comments_count)}
            </div>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <>
      {data.map((item) => (
        <div className="facebook__content item" key={item.id}>
          <a href={item.permalink} target="_blank" rel="noopener noreferrer">
            <div className="twitter_header">
              <img src={image || defaultImage} alt="Profile" />
              <div>
                <div className="twitter_name">@{profile.username}</div>
              </div>
            </div>
            {getContent(item)}
            {item.caption && (
              <div className="facebook__content-text">{item.caption}</div>
            )}
          </a>
        </div>
      ))}
    </>
  );
}

export default Instagram;
