import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import OpenImage from "./OpenImage";
import withReactContent from "sweetalert2-react-content";

import * as userActions from "../../../functionality/actions/userActions";
import Api from "../../../functionality/Api";

const MySwal = withReactContent(Swal);

class SettingsStep4 extends Component {
  componentDidMount() {
    const {
      userReducer: { user_data },
      changeTypePerson,
    } = this.props;

    if (user_data.type_person) {
      changeTypePerson(user_data.type_person);
    }
  }

  showError = (message) => {
    MySwal.fire({
      title: "Upsss!!!",
      text: message,
      type: "warning",
      confirmButtonColor: "#50318B",
      onClose: () => this.props.userClearError(),
    });
  };

  uploadFile = (file, callback) => {
    const {
      siteReducer: {
        site_texts: { text_uploading },
      },
    } = this.props;
    let data = new FormData();
    data.append("file", file);
    data.append("folder", "documents");
    this.props.openLoading(
      text_uploading ? text_uploading : "Subiendo Archivo..."
    );
    Api.uploadFile(data, (res) => {
      if (res.location) {
        callback(res.location);
      }
    });
  };

  saveDocFront = (file) => {
    const { userSaveDocs } = this.props;
    let data = { doc_scan_front: file };
    userSaveDocs(data);
  };

  saveDocBack = (file) => {
    const { userSaveDocs } = this.props;
    let data = { doc_scan_back: file };
    userSaveDocs(data);
  };

  saveCamaraComercio = (file) => {
    const { userSaveDocs } = this.props;
    let data = { camara_comercio: file };
    userSaveDocs(data);
  };

  saveRut = (file) => {
    const { userSaveDocs } = this.props;
    let data = { rut_scann: file };
    userSaveDocs(data);
  };

  saveDocs = () => {
    const {
      userReducer: { user_data, user_type_person },
      siteReducer: {
        site_texts: {
          text_form_validate,
          text_type_person_error,
          text_type_person_chamber_commerce,
        },
      },
      userSaveDocs,
    } = this.props;

    let { docs } = user_data;
    if (user_type_person === "") {
      this.showError(
        text_type_person_error
          ? text_type_person_error
          : "Debes seleccionar el tipo de persona."
      );
      return;
    }
    if (parseInt(user_type_person) === 2 && !docs.camara_comercio) {
      this.showError(
        text_type_person_chamber_commerce
          ? text_type_person_chamber_commerce
          : "Adjunte el archivo de la Cámara de Comercio."
      );
      return;
    }
    if (
      !docs ||
      docs.rut_scann === null ||
      docs.doc_scan_front === null ||
      docs.doc_scan_back === null
    ) {
      this.showError(
        text_form_validate
          ? text_form_validate
          : "Válida que cada uno de los puntos del formulario estén debidamente diligenciados."
      );
      return;
    }

    let data = {
      rut_scann: docs.rut_scann,
      doc_scan_front: docs.doc_scan_front,
      doc_scan_back: docs.doc_scan_back,
      type_person: user_type_person,
    };

    if (data.camara_comercio) {
      data.camara_comercio = docs.camara_comercio;
    }

    userSaveDocs(data, 5);
  };

  render() {
    const {
      userReducer: {
        user_data: { docs },
        user_type_person,
      },
      siteReducer: {
        site_texts: {
          text_documents_required,
          text_validate_company,
          text_identification_document,
          text_legal_representative_name,
          text_show,
          text_front_face,
          text_upload_front_face,
          text_upper_side,
          text_upload_back_face,
          text_chamber_commerce,
          text_last_30_days,
          text_see_document,
          text_upload_pdf,
          text_rut,
          text_continue,
          text_select,
          text_type_person,
          text_natural_person,
          text_legal_person,
        },
      },
      changeTypePerson,
    } = this.props;

    return (
      <Fragment>
        <div className="settingsView_title">
          {text_documents_required
            ? text_documents_required
            : "DOCUMENTOS REQUERIDOS"}
        </div>
        <div className="settingsView_description settingsView_no-border">
          {text_validate_company
            ? text_validate_company
            : "Es necesario validar la identidad legal de tu empresa o negocio, para esto es necesario adjuntar algunos documentos legales."}
        </div>
        <div className="settingsView_row">
          <div className="settingsView_col">
            <div>
              <div className="d-flex settingsView_files-title">
                {text_identification_document
                  ? text_identification_document
                  : "Documento de identidad"}

                <br />
                {text_legal_representative_name
                  ? text_legal_representative_name
                  : "Representante legal"}
              </div>
              <div className="settingsView_files-content">
                <input
                  type="file"
                  className="hidden"
                  id="frontDoc"
                  onChange={(input) =>
                    this.uploadFile(input.target.files[0], this.saveDocFront)
                  }
                />
                {docs && docs.doc_scan_front !== null ? (
                  <div className="openImage_container-inline openImage_view-doc d-flex">
                    <button
                      onClick={() =>
                        document.getElementById("frontDoc").click()
                      }
                      className="profileView_remove"
                    >
                      <span className="icon-pencil"></span>
                    </button>
                    {text_show ? `${text_show}:` : "Ver:"}
                    <a
                      href={docs.doc_scan_front}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {text_front_face ? text_front_face : "Cara Frontal"}
                    </a>
                  </div>
                ) : (
                  <OpenImage
                    open={() => document.getElementById("frontDoc").click()}
                    title={
                      text_upload_front_face
                        ? text_upload_front_face
                        : "SUBIR CARA FRONTAL"
                    }
                    type="inline"
                  />
                )}
                <input
                  type="file"
                  className="hidden"
                  id="backDoc"
                  onChange={(input) =>
                    this.uploadFile(input.target.files[0], this.saveDocBack)
                  }
                />
                {docs && docs.doc_scan_back !== null ? (
                  <div className="openImage_container-inline openImage_view-doc d-flex">
                    <button
                      onClick={() => document.getElementById("backDoc").click()}
                      className="profileView_remove"
                    >
                      <span className="icon-pencil"></span>
                    </button>
                    {text_show ? text_show : "Ver:"}
                    <a
                      href={docs.doc_scan_back}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {text_upper_side ? text_upper_side : "Cara Posterior"}
                    </a>
                  </div>
                ) : (
                  <OpenImage
                    open={() => document.getElementById("backDoc").click()}
                    title={
                      text_upload_back_face
                        ? text_upload_back_face
                        : "SUBIR CARA POSTERIOR"
                    }
                    type="inline"
                  />
                )}
              </div>
            </div>
            <div>
              <div className="d-flex settingsView_files-title">
                {text_type_person
                  ? text_type_person
                  : "Seleccionar el tipo de persona"}
              </div>
              <div className="settingsView_files-content">
                <select
                  value={user_type_person}
                  onChange={(input) => changeTypePerson(input.target.value)}
                  className="form-control mt-3"
                >
                  <option>
                    -- {text_select ? text_select : "Seleccionar"} --
                  </option>
                  <option value="1">
                    {text_natural_person
                      ? text_natural_person
                      : "Persona natural"}
                  </option>
                  <option value="2">
                    {text_legal_person ? text_legal_person : "Persona jurídica"}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="settingsView_col">
            <div>
              <div className="d-flex settingsView_files-title">
                {text_rut ? text_rut.toUpperCase() : "RUT"}
              </div>
              <div className="settingsView_files-content">
                <input
                  type="file"
                  className="hidden"
                  id="rut"
                  accept=".pdf"
                  onChange={(input) =>
                    this.uploadFile(input.target.files[0], this.saveRut)
                  }
                />
                <div className="settingsView_files-square">
                  {docs && docs.rut_scann !== null ? (
                    <div className="openImage_view-pdf d-flex">
                      <button
                        onClick={() => document.getElementById("rut").click()}
                        className="profileView_remove"
                      >
                        <span className="icon-pencil"></span>
                      </button>
                      <a
                        href={docs.rut_scann}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {text_see_document
                          ? text_see_document
                          : "Ver Documento"}
                      </a>
                    </div>
                  ) : (
                    <OpenImage
                      open={() => document.getElementById("rut").click()}
                      title={text_upload_pdf ? text_upload_pdf : "CARGAR PDF"}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex settingsView_files-title">
                {text_chamber_commerce
                  ? text_chamber_commerce
                  : "CÁMARA DE COMERCIO"}
                <br />
                {text_last_30_days
                  ? text_last_30_days
                  : "(Últimos 30 días, solo para personas jurídicas)"}
              </div>
              <div className="settingsView_files-content">
                <input
                  type="file"
                  className="hidden"
                  id="ccb"
                  accept=".pdf"
                  onChange={(input) =>
                    this.uploadFile(
                      input.target.files[0],
                      this.saveCamaraComercio
                    )
                  }
                />
                <div className="settingsView_files-square">
                  {docs && docs.camara_comercio !== null ? (
                    <div className="openImage_view-pdf d-flex">
                      <button
                        onClick={() => document.getElementById("ccb").click()}
                        className="profileView_remove"
                      >
                        <span className="icon-pencil"></span>
                      </button>
                      <a
                        href={docs.camara_comercio}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {text_see_document
                          ? text_see_document
                          : "Ver Documento"}
                      </a>
                    </div>
                  ) : (
                    <OpenImage
                      open={() => document.getElementById("ccb").click()}
                      title={text_upload_pdf ? text_upload_pdf : "CARGAR PDF"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button className="settingsView_next" onClick={this.saveDocs}>
            <span className="icon-chek" />
            {text_continue ? text_continue : "Continuar"}
          </button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, userActions)(SettingsStep4);
