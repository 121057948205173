import React from 'react';
import { connect } from 'react-redux';
import defaultImage from '../../../../images/believe.jpg';
import './InfluencerRow.css';
import { formatNumber } from '../../../../utils/formatNumber';
import { Link } from 'react-router-dom';

const InfluencerRow = (props) => {
  const {
    siteReducer: {
      site_language,
      site_texts: { text_see_detail },
    },
    image,
    name,
    lastname,
    city,
    social_networks,
    // rating,
    username,
    id,
    tags,
  } = props;
  const arrayName = site_language === 'es' ? 'english_name' : 'name';

  return (
    <tr className="influencer__row">
      <td>
        <div className="influencer__img">
          <img src={image || defaultImage} alt="influencer-img" />
        </div>
      </td>
      <td className="influencer__name">{username || `${name} ${lastname}`}</td>
      <td className="influencer__city">
        {city && (
          <>
            {city.name}
            <br />
            <small>
              ({city.department_name}, {city[`country_${arrayName}`]})
            </small>
          </>
        )}
      </td>
      <td style={{ maxWidth: 300 }}>
        <div className="influencer__categories">
          {tags.map((tag) => (
            <div className="category__container" key={tag.id}>
              <h4 className="category__name">{tag[arrayName]}</h4>
            </div>
          ))}
        </div>
      </td>
      <td style={{ maxWidth: 300 }}>
        <div className="influencer__followers">
          {social_networks.map((item) => (
            <div className="media" key={item.id}>
              <div className={`network__circle circle-${item.slug}`}>
                <i className={item.icon} />
              </div>
              <p className="media__viewers">{formatNumber(item.followers)}</p>
            </div>
          ))}
        </div>
      </td>
      <td>
        <Link
          className="influencerRow__link"
          to={`/influencer/${username || id}`}
        >
          {text_see_detail ? text_see_detail : 'Ver detalle'}
        </Link>
      </td>
    </tr>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(InfluencerRow);
