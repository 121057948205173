import React, { Component } from "react";
import { connect } from "react-redux";
import {
  initialSettingStep,
  userClear,
  changeSettingStep,
} from "../../../functionality/actions/userActions";
import EmailVerified from "./EmailVerified";
import SettingsStep1 from "./SettingsStep1";
import SettingsStep2 from "./SettingsStep2";
import SettingsStep3 from "./SettingsStep3";
import SettingsStep4 from "./SettingsStep4";
import SettingsStep5 from "./SettingsStep5";

class SettingsSteps extends Component {
  componentWillUnmount() {
    let { initialSettingStep, userClear } = this.props;
    initialSettingStep();
    userClear();
  }

  render() {
    const {
      userReducer: { user_data, setting_step },
      siteReducer: {
        site_texts: { text_registration_data },
      },
      changeSettingStep,
    } = this.props;
    let { docs, is_verified, recommended_companies } = user_data;

    let firstActive = setting_step === 1 ? "active" : "";
    let secondActive = setting_step === 2 ? "active" : "";
    let thirdActive = setting_step === 3 ? "active" : "";
    let quarterActive = setting_step === 4 ? "active" : "";
    let fifthActive = setting_step === 5 ? "active" : "";
    let statusStep1 =
      user_data.profession !== null &&
      user_data.doc_expedition_date &&
      user_data.doc_number &&
      user_data.doc_type !== null
        ? true
        : false;
    let statusStep2 = false;
    let statusStep3 = false;
    let statusStep4 = false;
    let statusStep5 = false;
    if (
      docs &&
      docs.business_name !== null &&
      docs.nit_number !== null &&
      docs.phone !== null &&
      docs.cod_category !== null &&
      docs.average_sales !== null &&
      docs.description !== null
    ) {
      statusStep2 = true;
    }
    if (
      docs &&
      docs.cod_department !== null &&
      docs.cod_city !== null &&
      docs.address !== null
    ) {
      statusStep3 = true;
    }
    if (
      user_data.type_person &&
      docs &&
      docs.rut_scann !== null &&
      docs.doc_scan_front !== null &&
      docs.doc_scan_back !== null &&
      docs.rut_scann !== null
    ) {
      statusStep4 = true;
    }
    if (recommended_companies && recommended_companies >= 3) {
      statusStep5 = true;
    }
    return (
      <>
        {is_verified ? (
          <>
            <div className="settingsView_title" id="initSteps">
              {text_registration_data
                ? text_registration_data
                : "DATOS DE REGISTRO"}
            </div>
            <div className="settingsView_header-steps d-flex">
              <div className={`settingsView_steps d-flex ${firstActive}`}>
                {statusStep1 && (
                  <div className="settingsView_steps-icon d-flex">
                    <span className="icon-chek" />
                  </div>
                )}
                <button onClick={() => changeSettingStep(1)}>1</button>
              </div>
              <div className={`settingsView_steps d-flex ${secondActive}`}>
                {statusStep2 && (
                  <div className="settingsView_steps-icon d-flex">
                    <span className="icon-chek" />
                  </div>
                )}
                <button onClick={() => changeSettingStep(2)}>2</button>
              </div>
              <div className={`settingsView_steps d-flex ${thirdActive}`}>
                {statusStep3 && (
                  <div className="settingsView_steps-icon d-flex">
                    <span className="icon-chek" />
                  </div>
                )}
                <button onClick={() => changeSettingStep(3)}>3</button>
              </div>
              <div className={`settingsView_steps d-flex ${quarterActive}`}>
                {statusStep4 && (
                  <div className="settingsView_steps-icon d-flex">
                    <span className="icon-chek" />
                  </div>
                )}
                <button onClick={() => changeSettingStep(4)}>4</button>
              </div>
              <div className={`settingsView_steps d-flex ${fifthActive}`}>
                {statusStep5 && (
                  <div className="settingsView_steps-icon d-flex">
                    <span className="icon-chek" />
                  </div>
                )}
                <button onClick={() => changeSettingStep(5)}>5</button>
              </div>
            </div>
            <div className="settingsView_steps-container">
              <div className={`settingsView_page ${firstActive}`}>
                <SettingsStep1 />
              </div>
              <div className={`settingsView_page ${secondActive}`}>
                <SettingsStep2 />
              </div>
              <div className={`settingsView_page ${thirdActive}`}>
                <SettingsStep3 />
              </div>
              <div className={`settingsView_page ${quarterActive}`}>
                <SettingsStep4 />
              </div>
              <div className={`settingsView_page ${fifthActive}`}>
                <SettingsStep5 />
              </div>
            </div>
          </>
        ) : (
          <EmailVerified />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  initialSettingStep,
  userClear,
  changeSettingStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsSteps);
