import React from 'react';
import ChatInboxItem from '../ChatInboxItem/ChatInboxItem';

import './PorposeChat.css';
import Spinner from '../../../all/Spinner';
import { withRouter } from 'react-router';

const PorposeChat = (props) => {
  const {
    match: {
      params: { porposalConsecutive, id },
    },
    loading,
    chats,
    path,
    inboxChat,
    title,
  } = props;

  return (
    <div className="inbox__porpose">
      <div className="inbox__title">
        {title && <h4>{title}</h4>}
        {/* <div className="inbox__buttons">
          <button>todo</button>
          <button>no leidos</button>
        </div> */}
      </div>
      {loading ? (
        <div className="spinner-loading">
          <Spinner type="purple" />
        </div>
      ) : (
        <div className="inbox__content">
          {chats &&
            chats.map((chat) => {
              let isActive = false;
              if (chat.reference) {
                isActive =
                  chat.reference === porposalConsecutive ||
                  chat.reference === id;
              }
              if (chat.consecutive) {
                isActive =
                  chat.consecutive === porposalConsecutive ||
                  chat.consecutive === id;
              }
              return (
                <ChatInboxItem
                  inboxChat={inboxChat}
                  data={chat}
                  key={chat.id}
                  path={path}
                  isActive={isActive}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default withRouter(PorposeChat);
