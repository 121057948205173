import React, { Fragment } from "react";
import "./css/ManagerHeader.css";
import Logo from "../../../images/logo.png";
import { Link } from "react-router-dom";
import MenuHeader from "../../all/MenuHeader";

function ManagerHeader(props) {
  let fixed = props.top > 65 ? true : false;
  return (
    <Fragment>
      <div className="managerHeader_container background-purple">
        <div className="managerHeader_box-logo">
          <button onClick={props.onChangeMenu}>
            <span className="icon-menu" />
          </button>
          <Link to="/home" className="managerHeader_logo">
            <img src={Logo} alt="Logo BAX" />
          </Link>
        </div>
        <MenuHeader type="manager" fixed={props.fixed} />
      </div>
      {fixed === true && (
        <div className={`d-flex header_container fixed`}>
          <div className="managerHeader_box-logo">
            <button onClick={props.onChangeMenu}>
              <span className="icon-menu" />
            </button>
            <Link to="/home" className="managerHeader_logo">
              <img src={Logo} alt="Logo BAX" />
            </Link>
          </div>
          <MenuHeader
            search={true}
            openCloseSearch={props.openCloseSearch}
            fixed={fixed}
          />
        </div>
      )}
    </Fragment>
  );
}

export default ManagerHeader;
