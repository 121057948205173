import React from 'react';
import { connect } from 'react-redux';
import { sendEmailVerified } from '../../../functionality/actions/userActions';
import BoxForm from '../../all/BoxForm';

function EmailVerified(props) {
  const {
    siteReducer: {
      site_texts: { text_hi, text_verified, text_verified_button },
    },
    userReducer: { user_data },
    sendEmailVerified,
  } = props;

  if (!user_data) return null;

  return (
    <BoxForm>
      <div className="container">
        <h3>
          <span className="color-fuchsia">{text_hi || 'Hola'} </span>
          <span className="color-purple">
            {user_data.name} {user_data.lastname}
          </span>
        </h3>
        <p className="text-validate" id="text-validate">
          {text_verified ||
            'Debes confirmar el email para continuar con el proceso de registro, el correo fue enviado a:'}
          <strong>{` ${user_data.email}`}</strong>
        </p>
        <button
          onClick={sendEmailVerified}
          type="button"
          className="btn profileView_btn-save form-control"
        >
          {text_verified_button || 'Recibir correo'}
        </button>
      </div>
    </BoxForm>
  );
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  sendEmailVerified,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerified);
