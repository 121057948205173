export const CAMPAIGN_USER_ID = 'CAMPAIGN_USER_ID';
export const CAMPAIGN_BRAND_ID = 'CAMPAIGN_BRAND_ID';
export const CAMPAIGN_STATUS_ID = 'CAMPAIGN_STATUS_ID';
export const CAMPAIGN_CONSECUTIVE = 'CAMPAIGN_CONSECUTIVE';
export const CAMPAIGN_NAME = 'CAMPAIGN_NAME';
export const CAMPAING_LINK = 'CAMPAING_LINK';
export const CAMPAIGN_DATE_START = 'CAMPAIGN_DATE_START';
export const CAMPAIGN_DATE_END = 'CAMPAIGN_DATE_END';
export const CAMPAIGN_DESCRIPTION = 'CAMPAIGN_DESCRIPTION';
export const CAMPAIGN_PHRASE = 'CAMPAIGN_PHRASE';
export const CAMPAIGN_DELIVERABLES = 'CAMPAIGN_DELIVERABLES';
export const CAMPAIGN_HASHTAGS = 'CAMPAIGN_HASHTAGS';
export const CAMPAIGN_HASHTAGS_TEXT = 'CAMPAIGN_HASHTAGS_TEXT';
export const CAMPAIGN_TARGET_GENDER = 'CAMPAIGN_TARGET_GENDER';
export const CAMPAIGN_TARGET_DESCRIPTION = 'CAMPAIGN_TARGET_DESCRIPTION';
export const CAMPAIGN_TARGET_MIN_AGE = 'CAMPAIGN_TARGET_MIN_AGE';
export const CAMPAIGN_TARGET_MAX_AGE = 'CAMPAIGN_TARGET_MAX_AGE';
export const CAMPAIGN_INFLUENCER_RATING = 'CAMPAIGN_INFLUENCER_RATING';
export const CAMPAIGN_INFLUENCER_GENDER = 'CAMPAIGN_INFLUENCER_GENDER';
export const CAMPAIGN_MIN_AGE_RANGE = 'CAMPAIGN_MIN_AGE_RANGE';
export const CAMPAIGN_MAX_AGE_RANGE = 'CAMPAIGN_MAX_AGE_RANGE';
export const CAMPAIGN_MIN_FOLLOWERS_RANGE = 'CAMPAIGN_MIN_FOLLOWERS_RANGE';
export const CAMPAIGN_MAX_FOLLOWERS_RANGE = 'CAMPAIGN_MAX_FOLLOWERS_RANGE';
export const CAMPAIGN_MIN_LIKES_RANGE = 'CAMPAIGN_MIN_LIKES_RANGE';
export const CAMPAIGN_MAX_LIKES_RANGE = 'CAMPAIGN_MAX_LIKES_RANGE';
export const CAMPAIGN_MIN_VIEWS_RANGE = 'CAMPAIGN_MIN_VIEWS_RANGE';
export const CAMPAIGN_MAX_VIEWS_RANGE = 'CAMPAIGN_MAX_VIEWS_RANGE';
export const CAMPAIGN_IMAGES = 'CAMPAIGN_IMAGES';
export const CAMPAIGN_CITIES = 'CAMPAIGN_CITIES';
export const CAMPAIGN_COUNTRY = 'CAMPAIGN_COUNTRY';
export const CAMPAIGN_SOCIAL_NETWORKS = 'CAMPAIGN_SOCIAL_NETWORKS';
export const CAMPAIGN_TAGS = 'CAMPAIGN_TAGS';
export const CAMPAIGN_OBJECTIVES = 'CAMPAIGN_OBJECTIVES';
export const CAMPAIGNS_LIST_CREATED = 'CAMPAIGNS_LIST_CREATED';
export const CAMPAIGN_CLEAR = 'CAMPAIGN_CLEAR';
export const CAMPAIGN_CURRENT_DETAIL = 'CAMPAIGN_CURRENT_DETAIL';
export const CAMPAIGN_CURRENT_LOADING = 'CAMPAIGN_CURRENT_LOADING';
export const SET_STATUS_EDIT = 'SET_STATUS_EDIT';
export const CAMPAIGN_TOTAL_LIST = 'CAMPAIGN_TOTAL_LIST';
export const CAMPAIGN_PAGE = 'CAMPAIGN_PAGE';
export const CAMPAIGN_TOTAL_PAGES = 'CAMPAIGN_TOTAL_PAGES';
export const CAMPAIGN_LIST_LOADING = 'CAMPAIGN_LIST_LOADING';
export const INFLUENCER_IN_CAMPAIGN = 'INFLUENCER_IN_CAMPAIGN';
export const INFLUENCER_IN_CAMPAIGN_LOADING = 'INFLUENCER_IN_CAMPAIGN_LOADING';
export const INFLUENCER_CAMPAIGN_TOTAL_LIST = 'INFLUENCER_CAMPAIGN_TOTAL_LIST';
export const INFLUENCER_CAMPAIGN_PAGE = 'INFLUENCER_CAMPAIGN_PAGE';
export const INFLUENCER_CAMPAIGN_TOTAL_PAGES =
  'INFLUENCER_CAMPAIGN_TOTAL_PAGES';
export const CAMPAIGN_STATISTICS_INFLUENCERS =
  'CAMPAIGN_STATISTICS_INFLUENCERS';
export const CAMPAIGN_STATISTICS_PROPOSALS = 'CAMPAIGN_STATISTICS_PROPOSALS';
export const CAMPAIGN_STATISTICS_POSTS = 'CAMPAIGN_STATISTICS_POSTS';
export const CAMPAIGN_STATISTICS_LAST_POSTS = 'CAMPAIGN_STATISTICS_LAST_POSTS';
