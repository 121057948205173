import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SelectUser from './SelectUser';
import TopSpinner from '../../all/TopSpinner';
import Error from '../../all/Error';
import Spinner from '../../all/Spinner';
import ListMessage from './ListMessage';
import './css/ChatMessage.css';

import * as chatActions from '../../../functionality/actions/chatActions';
import * as notificationActions from '../../../functionality/actions/notificationActions';
import CollapseComponent from '../../all/Collapse/CollapseComponent';
import Porposal from '../../all/Porposal/Porposal';
import PostTable from '../../all/Posts/PostsTable/PostTable';
import { changePorposalStatus } from '../../../functionality/actions/porposalActions';
import CampaignPostDetail from '../../../pages/user/manager/views/CampaignDetailView/CampaignPosts/CampaignPostDetail/CampaignPostDetail';
import CampaignPostForm from '../../../pages/user/manager/views/CampaignDetailView/CampaignPosts/CampaignPostForm/CampaignPostForm';
import { withRouter } from 'react-router';

const MySwal = withReactContent(Swal);

const {
  chatClearError,
  chatMessagesByType,
  chatChangeMessage,
  chatSendMessage,
  chatChangeSubject,
  chatClear,
  chatFor,
  chatCodFor,
  chatSelectUsers,
  chatMessages,
} = chatActions;

const { notificationView } = notificationActions;

class ChatMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list_business: [],
      cod_chat: null,
      showModal: false,
      showModalPost: false,
    };
  }

  showError = (message, error = 'warning') => {
    MySwal.fire({
      title: 'Upsss!!!',
      text: message,
      type: error,
      confirmButtonColor: '#50318B',
      onClose: () => this.props.chatClearError(),
    });
  };

  componentDidMount() {
    const {
      type,
      cod_type,
      subject,
      chatChangeSubject,
      chatMessagesByType,
      chatMessages,
      send_user,
      cod_chat,
      userReducer: { list_business },
      notificationView,
    } = this.props;
    if (cod_chat && cod_chat !== '' && cod_chat !== 'new') {
      chatMessages(cod_chat);
      notificationView();
    }

    if (type === 'order') {
      let data = {
        type,
        type_id: cod_type,
      };
      chatMessagesByType(data);
      chatChangeSubject(subject);
    }
    this.setState({ list_business });
    this.selectUser(send_user);
  }

  selectUser = (send_user) => {
    const { chatCodFor } = this.props;
    chatCodFor(send_user);
  };

  sendMessage = () => {
    const {
      type,
      cod_chat,
      cod_type,
      chatSendMessage,
      chatReducer: {
        chat_message,
        chat_subject,
        chat_cod_for,
        chat_type,
        chat_type_id,
      },
      siteReducer: {
        site_texts: {
          text_select_from,
          text_write_subject,
          text_write_message,
        },
      },
    } = this.props;

    if (!chat_cod_for || chat_cod_for === '') {
      this.showError(
        text_select_from
          ? text_select_from
          : 'Debes seleccionar un destinatario.'
      );
      return;
    }
    if (chat_subject === '') {
      this.showError(
        text_write_subject ? text_write_subject : 'Debes escribir un asunto.'
      );
      return;
    }
    if (chat_message === '') {
      this.showError(
        text_write_message ? text_write_message : 'Debes escribir un mensaje.'
      );
      return;
    }
    let view_type = type ? type : 'user';
    let view_cod_type = cod_type ? cod_type : chat_cod_for;
    let data = {
      type: chat_type ? chat_type : view_type,
      send_user_id: chat_cod_for,
      type_id: chat_type_id ? chat_type_id : view_cod_type,
      message: chat_message,
      subject: chat_subject,
    };
    if (cod_chat) {
      data.cod_chat = cod_chat;
    }
    chatSendMessage(data);
  };

  componentDidUpdate(prevProps) {
    const { cod_chat, chatMessages } = this.props;

    if (cod_chat !== prevProps.cod_chat) {
      chatMessages(cod_chat);
      notificationView();
    }
  }

  componentWillUnmount() {
    this.props.chatClear();
  }

  handleChange = (input) => {
    const {
      chatFor,
      chatSelectUsers,
      chatCodFor,
      userReducer: { list_business },
    } = this.props;
    let value = input.target.value;
    let search_business = [];
    if (value.length > 2) {
      search_business = list_business.filter(
        (item) =>
          item.username.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
          item.business_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    } else {
      chatCodFor('');
    }
    chatFor(value);
    chatSelectUsers(search_business);
  };

  selectFor = (item) => {
    const { chatFor, chatSelectUsers, chatCodFor } = this.props;
    let name = '';
    if (item) {
      name = item && item.username ? item.username : item.docs.business_name;
      chatFor(name.toUpperCase());
      chatCodFor(item.id);
      chatSelectUsers([]);
    }
  };

  handleCreatePost = () => {
    this.setState({ showModal: true });
  };

  handleViewPost = () => {
    this.setState({ showModalPost: true });
  };

  handleCloseModal = () => {
    this.setState({ showModalPost: false, showModal: false });
  };

  handleSend = async () => {
    const messageSwal = Swal.mixin({
      customClass: {
        confirmButton: 'button__confirm',
        cancelButton: 'button__cancel',
      },
      buttonsStyling: false,
    });
    const {
      chatReducer: { chat_response },
      userReducer: { user_data },
      siteReducer: {
        site_texts: {
          text_are_you_sure,
          text_not_reverse_action,
          text_cancel,
          text_finalize_proposal,
          text_success_proposal_finalized,
          text_warning_only_published,
        },
      },
      changePorposalStatus,
      history,
    } = this.props;

    const hasAllStatus5 = chat_response.proposal_posts.data.every(
      (obj) => obj.post_status_id === 5
    );

    if (hasAllStatus5) {
      messageSwal
        .fire({
          title: text_are_you_sure || '¿Estas seguro?',
          text: text_not_reverse_action || 'No podras revertir esta acción.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#28cae4',
          cancelButtonColor: '#50318b',
          confirmButtonText: text_finalize_proposal || 'Finalizar Propuesta',
          cancelButtonText: text_cancel || 'Cancelar',
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value === true) {
            const res = await changePorposalStatus(
              chat_response.proposal.consecutive,
              5
            );
            if (res.status === 200) {
              messageSwal
                .fire({
                  title: 'Hecho!',
                  text:
                    text_success_proposal_finalized ||
                    'Se Finalizo la propuesta',
                  type: 'success',
                })
                .then((res) => {
                  if (res.value === true) {
                    if (user_data.user_type_id !== 8) {
                      history.push('/manager/orders/purchases');
                    } else {
                      history.push('/manager/orders/sales');
                    }
                  }
                });
            }
          }
        });
    } else {
      messageSwal.fire({
        title: 'Upsss!!!',
        text:
          text_warning_only_published ||
          'Solo debe haber post publicados en la lista',
        type: 'warning',
      });
    }
  };

  render() {
    const {
      chatChangeSubject,
      chatChangeMessage,
      type,
      chatReducer: {
        chat_messages_loading,
        chat_send_loading,
        chat_messages,
        chat_error,
        chat_message,
        chat_subject,
        chat_messages_error,
        chat_for,
        chat_select_users,
        chat_response,
        // chat_response: { proposal_posts },
      },
      userReducer: { user_data },
      siteReducer: {
        site_texts: {
          text_from_date,
          text_to,
          text_subject,
          text_send_message,
          text_sending_message,
          text_view_proposal_detail,
          text_view_proposal_post,
          text_submit_proposal,
        },
      },
    } = this.props;

    let constentClass =
      type === 'order' ? 'chatMessage_content-order' : 'chatMessage_content';
    let name = user_data.username
      ? user_data.username
      : user_data.docs
      ? user_data.docs.business_name
      : '';
    let { cod_chat } = this.state;

    return (
      <div className={constentClass}>
        {chat_send_loading && (
          <div className="chatMessage_sending">
            <Spinner type="purple" />
            <div>
              {text_sending_message
                ? text_sending_message
                : 'Enviando mensaje...'}
            </div>
          </div>
        )}
        {chat_messages_error !== '' &&
          this.showError(chat_messages_error, 'error')}
        {chat_messages_loading && chat_error === '' && <TopSpinner />}
        {!chat_messages_loading && chat_error !== '' && (
          <Error type="no-data" title={chat_error} />
        )}
        {!chat_messages_loading && chat_error === '' && (
          <Fragment>
            <div className="chatMessage_label">
              <label>
                {text_from_date ? text_from_date.toUpperCase() : 'DE'}:
              </label>
              <div className="color-gray">
                {name
                  .toUpperCase()
                  .replace('-', ' ')
                  .replace('_', ' ')
                  .replace('.', ' ')}
              </div>
            </div>
            {!cod_chat && type !== 'order' && (
              <Fragment>
                <div className="chatMessage_label">
                  <label>{text_to ? text_to.toUpperCase() : 'PARA'}:</label>
                  <input
                    className="color-gray"
                    onChange={this.handleChange}
                    name="to_name"
                    value={chat_for}
                    placeholder={text_to ? text_to.toUpperCase() : 'PARA'}
                    type="text"
                  />
                  <div className="chatMessage_select-user">
                    {chat_select_users.map((item) => (
                      <SelectUser
                        key={item.id}
                        data={item}
                        selectUser={this.selectFor}
                      />
                    ))}
                  </div>
                </div>
              </Fragment>
            )}
            {type !== 'order' ? (
              <Fragment>
                <div className="chatMessage_label">
                  <label>
                    {text_subject ? text_subject.toUpperCase() : 'ASUNTO'}:
                  </label>
                  <input
                    className="color-gray"
                    onChange={(input) => chatChangeSubject(input.target.value)}
                    value={chat_subject}
                    placeholder={
                      text_subject ? text_subject.toUpperCase() : 'ASUNTO'
                    }
                    type="text"
                  />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="chatMessage_label">
                  <label>
                    {text_subject ? text_subject.toUpperCase() : 'ASUNTO'}:
                  </label>
                  <div className="color-gray">{chat_subject}</div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}

        {chat_response && chat_response.proposal && (
          <>
            <CollapseComponent
              target={'detail_proposal'}
              superTitle={
                text_view_proposal_detail || 'Ver detalle de propuesta'
              }
            >
              <Porposal
                // loading={loadingPorposal}
                data={chat_response.proposal}
              />
            </CollapseComponent>
            <div className="separator" />
            {chat_response.proposal_posts.data.length !== 0 && (
              <>
                {this.state.showModal && (
                  <div className="modal-background">
                    <CampaignPostForm
                      handleCloseModal={this.handleCloseModal}
                    />
                  </div>
                )}
                {this.state.showModalPost && (
                  <div className="modal-background">
                    <CampaignPostDetail
                      handleCloseModal={this.handleCloseModal}
                    />
                  </div>
                )}
                <CollapseComponent
                  target={'posts_proposal'}
                  superTitle={text_view_proposal_post || 'Ver posts'}
                >
                  <PostTable
                    loading={chat_messages_loading}
                    data={chat_response.proposal_posts.data}
                    currentPage={1}
                    totalPage={1}
                    typeUserId={user_data.user_type_id}
                    openModal={this.handleCreatePost}
                    closeModal={this.handleCloseModal}
                    handleViewPost={this.handleViewPost}
                    inChat={true}
                  />
                </CollapseComponent>
                <div className="separator" />

                {chat_response?.proposal.status.id !== 5 && (
                  <button
                    className="btn__deliver__proposal"
                    onClick={this.handleSend}
                  >
                    {text_submit_proposal || 'Entregar Propuesta'}
                  </button>
                )}
              </>
            )}
          </>
        )}

        {!chat_messages_loading &&
          chat_error === '' &&
          chat_messages.length > 0 && (
            <Fragment>
              <ListMessage messages={chat_messages} />
              <div className="chatMessage_box-message-response">
                <textarea
                  onChange={(input) => chatChangeMessage(input.target.value)}
                  value={chat_message}
                />
              </div>
            </Fragment>
          )}
        {!chat_messages_loading &&
          chat_error === '' &&
          chat_messages.length === 0 && (
            <Fragment>
              <div className="chatMessage_box-message">
                <textarea
                  onChange={(input) => chatChangeMessage(input.target.value)}
                  value={chat_message}
                />
              </div>
            </Fragment>
          )}
        {!chat_messages_loading && chat_error === '' && (
          <div className="chatMessage_menu">
            <button
              className="btn btn-right chatMessage_btn"
              onClick={this.sendMessage}
            >
              {text_send_message ? text_send_message : 'Enviar Mensaje'}
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, chatReducer, siteReducer }) => {
  return {
    userReducer,
    chatReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  chatClearError,
  chatMessagesByType,
  chatChangeMessage,
  chatSendMessage,
  chatChangeSubject,
  chatClear,
  chatFor,
  chatCodFor,
  chatSelectUsers,
  chatMessages,
  changePorposalStatus,
  notificationView,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChatMessage)
);
