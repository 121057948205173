import React from "react";
import "./css/ProductList.css";
import ProductItem from "./ProductItem";

function ProductList(props) {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="container">
          <div className="row">
            {props.data.map((item) => {
              return (
                <ProductItem
                  key={item.id}
                  data={item}
                  actions={props.actions}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductList;
