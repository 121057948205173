import React, { Component } from "react";
import { connect } from "react-redux";
import MarketPlaceLayout from "./layouts/MarketplaceLayout";
import ProductView from "./views/ProductView";
import TopSpinner from "../../../components/all/TopSpinner";

class ProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusSearch: false,
    };
  }

  openCloseSearch = (statusSearch) => {
    this.setState({
      statusSearch,
    });
  };

  render() {
    const { loading_init } = this.props;
    return (
      <MarketPlaceLayout
        statusSearch={this.state.statusSearch}
        openCloseSearch={this.openCloseSearch}
      >
        {loading_init ? (
          <TopSpinner />
        ) : (
          <ProductView cod_product={this.props.match.params.id} />
        )}
      </MarketPlaceLayout>
    );
  }
}

const mapStateToProps = ({ userReducer }) => userReducer;

export default connect(mapStateToProps)(ProductPage);
