import { NOTIFICATION_VIEW } from "../types/notificationTypes";

const INITIAL_STATE = {
  notification_total: 0,
  notification_chats: 0,
  notification_orders: 0,
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_VIEW:
      return {
        ...state,
        notification_total: action.payload.notification_total,
        notification_chats: action.payload.notification_chats,
        notification_orders: action.payload.notification_orders,
      };
    default:
      return {
        ...state,
      };
  }
};

export default notificationReducer;
