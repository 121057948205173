import React, { useEffect, useMemo, useState } from 'react';
import './CampaignForm.css';
import { connect } from 'react-redux';
import {
  onSaveStep1,
  onSaveStep2,
  onSaveStep3,
  onSaveStep4,
  campaignClear,
  getCampaignToEdit,
  setStatusEdit,
  publishBreif,
} from '../../../../functionality/actions/campaignActions';
import CampaignFormSetp1 from './CampaignFormSetp1';
import CampaignFormSetp2 from './CampaignFormSetp2';
import CampaignFormStep3 from './CampaignFormStep3';
import CampaignFormStep4 from './CampaignFormStep4';
import { Redirect, withRouter } from 'react-router-dom';
import Spinner from '../../Spinner';
import Swal from 'sweetalert2';

const CampaignForm = (props) => {
  /* The above code is importing and destructuring various properties from the `campaignReducer` object
  and the `setCampaignName` function from the `props` object. It is likely part of a larger React
  component that is using these properties and functions to render and update a campaign form or
  display. */
  const {
    userReducer: { user_data },
    campaignReducer: {
      campaign_user_id,
      campaign_consecutive,
      campaign_brand_id,
      campaign_name,
      campaign_date_start,
      campaign_date_end,
      campaign_description,
      campaign_phrase,
      campaign_deliverables,
      campaign_target_gender,
      campaign_target_description,
      campaign_target_min_age,
      campaign_target_max_age,
      campaign_influencer_rating,
      campaign_influencer_gender,
      campaign_min_age_range,
      campaign_max_age_range,
      campaign_min_followers_range,
      campaign_max_followers_range,
      campaign_min_likes_range,
      campaign_max_likes_range,
      campaign_min_views_range,
      campaign_max_views_range,
      campaign_cities,
      campaign_country,
      campaign_social_networks,
      campaign_tags,
      campaign_objectives,
      campaign_current_detail,
      campaign_current_loading,
      campaign_status_id,
    },
    match: {
      params: { id: consecutive },
    },
    siteReducer: {
      site_texts: {
        text_next,
        text_save_brief,
        text_publish_brief,
        text_are_you_sure,
        text_not_reverse_action,
        text_publish_campaign,
        text_cancel,
        text_campaign_details,
        text_topics_and_subjects,
        text_influencers_profile,
      },
    },
    history,
    onSaveStep1,
    onSaveStep2,
    onSaveStep3,
    onSaveStep4,
    campaignClear,
    getCampaignToEdit,
    publishBreif,
    setStatusEdit,
  } = props;
  const memoCampaignFormSetp1 = useMemo(() => <CampaignFormSetp1 />, []);
  const memoCampaignFormSetp2 = useMemo(() => <CampaignFormSetp2 />, []);
  const memoCampaignFormSetp3 = useMemo(() => <CampaignFormStep3 />, []);
  const memoCampaignFormSetp4 = useMemo(() => <CampaignFormStep4 />, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (consecutive !== campaign_current_detail.consecutive) {
          await getCampaignToEdit(consecutive);
          setStatusEdit();
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (consecutive) {
      fetchData();
    } else {
      campaignClear();
    }
    return () => {
      campaignClear();
    };
  }, []);

  const handleNext = () => {
    const activeTabIndex = tabsData.findIndex((tab) => tab.id === activeTab);
    const nextTabIndex =
      activeTabIndex === tabsData.length - 1 ? 0 : activeTabIndex + 1;
    setActiveTab(tabsData[nextTabIndex].id);
    const updatedTabsData = [...tabsData];
    updatedTabsData[activeTabIndex].isComplete = true;

    setActiveTab(tabsData[nextTabIndex].id);
    setTabsData(updatedTabsData);
  };

  const [tabsData, setTabsData] = useState([
    {
      id: 'details',
      label: text_campaign_details || 'Detalles de Campaña',
      form: memoCampaignFormSetp1,
      isValid: true,
      isComplete: false,
    },
    {
      id: 'target',
      label: 'Target',
      form: memoCampaignFormSetp2,
      isValid: true,
      isComplete: false,
    },
    {
      id: 'social',
      label: text_topics_and_subjects || 'Temas y Materias',
      form: memoCampaignFormSetp3,
      isValid: true,
      isComplete: false,
    },
    {
      id: 'influencers',
      label: text_influencers_profile || 'Perfil de Influencers',
      form: memoCampaignFormSetp4,
      isValid: true,
      isComplete: false,
    },
  ]);

  const [activeTab, setActiveTab] = useState(tabsData[0].id);

  const isAnyFieldEmptyStep1 = [
    campaign_brand_id,
    campaign_name,
    campaign_date_start,
    campaign_date_end,
    campaign_description,
    campaign_phrase,
    campaign_deliverables,
  ].some((field) => !field);
  const isAnyFieldEmptyStep2 = [
    campaign_target_gender,
    campaign_target_min_age,
    campaign_target_max_age,
    campaign_social_networks,
    campaign_target_description,
  ].some((field) => {
    if (Array.isArray(field)) {
      return field.length === 0;
    }
    return !field;
  });
  const isAnyFieldEmptyStep3 = campaign_tags.length === 0;
  const isAnyFieldEmptyStep4 = [
    campaign_min_age_range,
    campaign_max_age_range,
    campaign_min_followers_range,
    campaign_max_followers_range,
    campaign_min_likes_range,
    campaign_max_likes_range,
    campaign_min_views_range,
    campaign_max_views_range,
    campaign_influencer_gender,
    campaign_influencer_rating,
    campaign_objectives,
  ].some((field) => !field);

  const publish = async () => {
    const swalStyled = Swal.mixin({
      customClass: {
        confirmButton: 'button__confirm',
        cancelButton: 'button__cancel',
      },
      buttonsStyling: false,
    });

    swalStyled
      .fire({
        title: text_are_you_sure || '¿Estas seguro?',
        text: text_not_reverse_action || 'No podras revertir esta acción.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28cae4',
        cancelButtonColor: '#50318b',
        confirmButtonText: text_publish_campaign || 'Publicar Campaña',
        cancelButtonText: text_cancel || 'Cancelar',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value === true) {
          await onSaveStep4();
          await publishBreif(campaign_consecutive).then((res) => {
            if (res.status === 200) {
              history.push(`/manager/campaign/${campaign_consecutive}`);
            }
            if (res.status === 405) {
            }
          });
          await campaignClear();
        }
      });
  };

  return (
    <>
      {campaign_current_loading ? (
        <div className="spinner-loading">
          <Spinner type="purple" />
        </div>
      ) : (
        <>
          {campaign_status_id && campaign_status_id !== 1 && (
            <Redirect to={`/manager/campaign/${consecutive}`}></Redirect>
          )}
          {campaign_user_id && campaign_user_id !== user_data.id && (
            <Redirect to={`/manager/campaign`} />
          )}
          <div className="title-manager">
            <div>
              <span className="fa-solid fa-bullhorn" />
              {consecutive
                ? `${campaign_current_detail.name}`
                : 'Nueva campaña'}
            </div>
          </div>
          <div className="campaign__form__container">
            <div className="tabs">
              {tabsData.map((tab) => (
                <button
                  key={tab.id}
                  disabled={!tab.isValid}
                  className={`tab ${activeTab === tab.id && 'active'}`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  {tab.label}
                  {tab.isComplete && (
                    <div className="check__tab">
                      <i className="fa-solid fa-check"></i>
                    </div>
                  )}
                </button>
              ))}
            </div>
            <div className="form__content">
              <div className="form__container">
                {tabsData.find((tab) => tab.id === activeTab)?.form}
              </div>
              <div className="button__container">
                {activeTab === 'details' && (
                  <button
                    className={`next-button ${
                      isAnyFieldEmptyStep1 ? 'disable' : ''
                    }`}
                    disabled={isAnyFieldEmptyStep1}
                    onClick={() => {
                      onSaveStep1();
                      handleNext();
                    }}
                  >
                    {text_next ? text_next : 'Siguiente'}
                  </button>
                )}
                {activeTab === 'target' && (
                  <button
                    className={`next-button ${
                      isAnyFieldEmptyStep2 ? 'disable' : ''
                    }`}
                    disabled={isAnyFieldEmptyStep2}
                    onClick={async () => {
                      await onSaveStep2().then((res) => {
                        if (res === true) {
                          handleNext();
                        }
                      });
                    }}
                  >
                    {text_next ? text_next : 'Siguiente'}
                  </button>
                )}
                {activeTab === 'social' && (
                  <button
                    className={`next-button ${
                      isAnyFieldEmptyStep3 ? 'disable' : ''
                    }`}
                    disabled={isAnyFieldEmptyStep3}
                    onClick={() => {
                      onSaveStep3();
                      handleNext();
                    }}
                  >
                    {text_next ? text_next : 'Siguiente'}
                  </button>
                )}
                {activeTab === 'influencers' && (
                  <>
                    <button
                      className={`${isAnyFieldEmptyStep1 ? 'disable' : ''}`}
                      disabled={isAnyFieldEmptyStep4}
                      onClick={() => {
                        onSaveStep4();
                        campaignClear();
                        history.push('/manager/campaign');
                      }}
                    >
                      {text_save_brief ? text_save_brief : 'Guardar Brief'}
                    </button>
                    <button
                      disabled={isAnyFieldEmptyStep4}
                      className={`publish__breif ${
                        isAnyFieldEmptyStep1 ? 'disable' : ''
                      }`}
                      onClick={publish}
                    >
                      {text_publish_brief
                        ? text_publish_brief
                        : 'Publicar Brief'}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

/**
 * This is a mapStateToProps function that returns the campaignReducer object from the Redux store.
 * @returns The `mapStateToProps` function is returning an object with a single property
 * `campaignReducer`, which is being destructured from the `campaignReducer` property of the argument
 * object. This object is typically used to map the state from the Redux store to the props of a React
 * component.
 */
const mapStateToProps = ({ campaignReducer, userReducer, siteReducer }) => {
  return {
    campaignReducer,
    userReducer,
    siteReducer,
  };
};

/* The above code is defining a `mapDispatchToProps` object in a React application. This object
contains a list of functions that will be used to dispatch actions to update the state of the
application. Each function corresponds to a specific action that can be triggered by the user or the
application. These actions include setting various properties of a campaign, such as the brand ID,
user ID, status ID, name, date range, description, target audience, influencer criteria, and other
campaign details. The `mapDispatchToProps` object is typically used in conjunction with the
`connect` function from the `react-redux` */
const mapDispatchToProps = {
  onSaveStep1,
  onSaveStep2,
  onSaveStep3,
  onSaveStep4,
  campaignClear,
  setStatusEdit,
  getCampaignToEdit,
  publishBreif,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignForm)
);
