import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import * as userActions from "../../../../functionality/actions/userActions";
import { adListView } from "../../../../functionality/actions/adActions";
import HomeSlider from "../../../../components/user/marketplace/HomeSlider";
import HomeMenu from "../../../../components/user/marketplace/HomeMenu";
import BoxWhite from "../../../../components/user/marketplace/BoxWhite";
import Pagination from "../../../../components/all/Pagination";
import ProductList from "../../../../components/user/marketplace/ProductList";
import MenuCategory from "../../../../components/user/marketplace/MenuCategory";
import PriceFilter from "../../../../components/user/marketplace/PriceFilter";
import Error from "../../../../components/all/Error";
import FormSearch from "../../../../components/all/FormSearch";
import "./css/ProductsView.css";

const {
  productsType,
  productChangePage,
  productClear,
  productsTypeCategory,
  productSearch,
  changeSearch,
} = userActions;

class ProductsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
  }

  componentDidMount() {
    const {
      viewCategory,
      adListView,
      match: { path },
    } = this.props;
    const route = path.split("/");
    let type = route[1] === "products" ? 1 : 3;
    this.viewData(viewCategory);

    if (viewCategory) {
      type = type === 1 ? 2 : 4;
      adListView(type, viewCategory);
    } else {
      adListView(type);
    }
  }

  viewData = (viewCategory) => {
    const { type, productsType, productsTypeCategory } = this.props;
    let numType = type === "services" ? 2 : 1;
    if (!viewCategory) {
      productsType(numType);
    } else {
      productsTypeCategory(numType, viewCategory);
    }
  };

  componentWillUnmount() {
    const { productClear } = this.props;
    productClear();
  }

  changePage = (current_page, initView, endView) => {
    const { list_type_products, productChangePage } = this.props;
    let list_view = list_type_products.slice(initView, endView);
    productChangePage({ list_view, current_page });
  };

  renderTitleCategory = (id, categories) => {
    if (!id || !id.length) {
      return null;
    }
    const {
      siteReducer: { site_language },
    } = this.props;

    if (categories.length > 0) {
      let data = categories.filter((item) => item.id === parseInt(id));
      return (
        <div className="productsView_title-category">
          <Link to={`/${this.props.type}`}>/MARKETPLACE</Link>/
          {site_language === "es" ? data[0].name : data[0].english_name}
        </div>
      );
    }
    return null;
  };

  handleChangeSearch = (search) => {
    const { list_search_products, item_per_page, changeSearch, productSearch } =
      this.props;
    changeSearch(search);
    if (search !== "") {
      changeSearch(search);
    } else {
      let viewProducts = list_search_products.slice(0, item_per_page);
      productSearch({
        list_total: list_search_products,
        list_view: viewProducts,
      });
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    const {
      list_search_products,
      item_per_page,
      product_search,
      productSearch,
    } = this.props;
    if (product_search !== "") {
      let products = list_search_products.filter(
        (item) =>
          item.name.toLowerCase().indexOf(product_search.toLowerCase()) !== -1
      );
      let viewProducts = products.slice(0, item_per_page);
      productSearch({ list_total: products, list_view: viewProducts });
    }
  };

  componentDidUpdate(nextProps) {
    if (this.props.viewCategory !== nextProps.viewCategory) {
      const {
        viewCategory,
        adListView,
        match: { path },
      } = this.props;
      const route = path.split("/");
      let type = route[1] === "products" ? 1 : 3;
      this.viewData(viewCategory);
      if (viewCategory) {
        type = type === 1 ? 2 : 4;
        adListView(type, viewCategory);
      } else {
        adListView(type);
      }
    }
  }

  render() {
    const {
      viewCategory,
      categories,
      list_type_products,
      list_view_type_products,
      item_per_page,
      current_page,
      type,
      product_search,
      siteReducer: {
        site_texts: { text_no_services, text_no_products, text_go_home },
      },
    } = this.props;
    const noServices = text_no_services
      ? text_no_services
      : "No hay servicios disponibles.";
    const noProducts = text_no_products
      ? text_no_products
      : "No hay productos disponibles.";
    let text = type === "products" ? noProducts : noServices;
    return (
      <div>
        <HomeSlider data={this.props.principalSlider} />
        <HomeMenu openCloseSearch={this.props.openCloseSearch} />
        <div className="container">
          <BoxWhite>
            <div id="titleCategory">
              {viewCategory &&
                categories.length > 0 &&
                this.renderTitleCategory(viewCategory, categories)}
            </div>
            <div className="row">
              <div className="col-lg-3">
                <MenuCategory
                  type={this.props.type}
                  data={this.props.categories}
                />
                {this.props.priceRange[1] > 0 && (
                  <PriceFilter
                  // priceRange={this.props.priceRange}
                  // priceSelected={this.props.priceSelected}
                  // uploadFilterPrice={this.props.uploadFilterPrice}
                  />
                )}
              </div>
              <div className="col-lg-9">
                <FormSearch
                  toSearch={this.handleSearch}
                  search={product_search}
                  changeSearch={this.handleChangeSearch}
                />
                {list_view_type_products.length > 0 ? (
                  <Fragment>
                    <ProductList
                      data={list_view_type_products}
                      type={this.props.typeList}
                      classColumn={this.props.classColumn}
                      showAds={true}
                    />
                    <Pagination
                      data={list_type_products}
                      itemsPerPage={item_per_page}
                      currentPage={current_page}
                      changePage={this.changePage}
                    />
                  </Fragment>
                ) : (
                  <Error
                    type="no-data"
                    title={text}
                    buttonName={text_go_home ? text_go_home : "Ir al Inicio"}
                    link="/home"
                  />
                )}
              </div>
            </div>
          </BoxWhite>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    ...userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  productsType,
  productChangePage,
  productClear,
  productsTypeCategory,
  productSearch,
  changeSearch,
  adListView,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductsView)
);
