import React, { useState, useEffect } from 'react';
import './Gallery.css';

const Gallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /* A hook that is used to detect if the user presses the escape key. If the user presses the escape
  key, the modal will close. */
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleCloseModal();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  /**
   * When the user clicks on an image, the image is set as the selected image, and the modal is opened.
   */
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  /**
   * When the user clicks the close button, the modal will close and the selected image will be set to
   * null.
   */
  const handleCloseModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="gallery">
        {images.map((image) => (
          <div
            key={image.id}
            className="gallery__item"
            onClick={() => handleImageClick(image)}
          >
            <img src={image.image || image.file} alt={`Galery-Piece`} />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div
          className={`modal ${isModalOpen ? 'open' : ''}`}
          onClick={handleCloseModal}
        >
          <img
            src={selectedImage.image || selectedImage.file}
            alt="View-Modal"
          />
        </div>
      )}
    </>
  );
};

export default Gallery;
