import React, { useEffect } from 'react';
import BreifPorposal from '../../../../components/all/BreifPorposal/BreifPorposal';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import ManagerLayout from '../layouts/ManagerLayout';
import CampaignDetailView from '../views/CampaignDetailView/CampaignDetailView';
import {
  getPorposalsByCampaign,
  porposalClear,
} from '../../../../functionality/actions/porposalActions';
import Spinner from '../../../../components/all/Spinner';
import PorposalTable from '../../../../components/all/PorposalsTable/PorposalTable';
import './PorposalCampaignPage.css';
import { Link } from 'react-router-dom';
import Error from '../../../../components/all/Error';

const PorposalCampaignPage = (props) => {
  const {
    userReducer: { user_data },
    campaignReducer: { campaign_current_detail },
    porposalReducer: { porposal_list, porposal_loading },
    getPorposalsByCampaign,
    porposalClear,
    siteReducer: {
      site_texts: { text_create_propose },
    },
    match: {
      params: { id: campaign_consecutive },
    },
  } = props;
  useEffect(() => {
    if (porposal_list.length === 0) {
      getPorposalsByCampaign(campaign_consecutive);
    }
    return () => {
      porposalClear();
    };
  }, [getPorposalsByCampaign]);

  const { status } = campaign_current_detail;

  return (
    <ManagerLayout>
      <CampaignDetailView>
        {porposal_loading ? (
          <div className="spinner-loading">
            <Spinner type="purple" />
          </div>
        ) : (
          <>
            {user_data?.user_type_id !== 8 && (
              <Redirect
                to={`/manager/campaign/${campaign_current_detail.consecutive}`}
              />
            )}
            <div className="list__porpose__container">
              {porposal_list.length !== 0 && (
                <PorposalTable data={porposal_list}></PorposalTable>
              )}
              {!porposal_list.length && <Error type="no-proposals" />}

              {status.id !== 4 && (
                <Link
                  to={`/manager/campaign/${campaign_current_detail.consecutive}/porpose/new`}
                >
                  {text_create_propose || 'Crear Propuesta'}
                </Link>
              )}
            </div>
          </>
        )}
      </CampaignDetailView>
    </ManagerLayout>
  );
};

const mapStateToProps = ({
  campaignReducer,
  userReducer,
  porposalReducer,
  siteReducer,
}) => {
  return {
    campaignReducer,
    userReducer,
    porposalReducer,
    siteReducer,
  };
};
const mapDispatchToProps = {
  getPorposalsByCampaign,
  porposalClear,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PorposalCampaignPage)
);
