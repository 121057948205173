import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  getFavorites,
  changeProductsPage,
  changeCompaniesPage,
} from "../../../../functionality/actions/favoriteActions";
import ProductList from "../../../../components/user/marketplace/ProductList";
import Pagination from "../../../../components/all/Pagination";
import Error from "../../../../components/all/Error";
import BoxWhite from "../../../../components/user/marketplace/BoxWhite";
import CompaniesList from "../../../../components/user/marketplace/CompaniesList";
import Spinner from "../../../../components/all/Spinner";

class FavoritesView extends Component {
  componentDidMount() {
    const { getFavorites } = this.props;
    getFavorites();
  }

  changePage = (current_page, initView, endView) => {
    const {
      userReducer: { favorites },
      favoritePage,
    } = this.props;
    let list_view = favorites.slice(initView, endView);
    let data = {
      page: current_page,
      list: list_view,
    };
    favoritePage(data);
  };

  render() {
    const {
      favoriteReducer: {
        favorite_item_per_page,
        favorite_products,
        favorite_products_view,
        favorite_products_page,
        favorite_companies,
        favorite_companies_view,
        favorite_companies_page,
        favorite_loading,
      },
      siteReducer: {
        site_texts: {
          text_favorite_products_error,
          text_my_favorite_products,
          text_favorite_companies_error,
          text_my_favorite_companies,
        },
      },
      changeProductsPage,
      changeCompaniesPage,
    } = this.props;

    return (
      <>
        <BoxWhite
          title={
            text_my_favorite_companies
              ? text_my_favorite_companies.toUpperCase()
              : "MIS COMPAÑIAS FAVORITOS"
          }
        >
          {favorite_loading ? (
            <div className="d-flex justify-content-center">
              <Spinner type="purple" />
            </div>
          ) : (
            <>
              {favorite_companies.length > 0 ? (
                <>
                  <CompaniesList
                    className="col-sm-6 col-md-3"
                    companies={favorite_companies_view}
                  />
                  <Pagination
                    data={favorite_companies}
                    itemsPerPage={favorite_item_per_page}
                    currentPage={favorite_companies_page}
                    changePage={changeCompaniesPage}
                  />
                </>
              ) : (
                <Error
                  type="no-data"
                  title={
                    text_favorite_companies_error
                      ? text_favorite_companies_error
                      : `No hay comañias favoritos disponibles.`
                  }
                />
              )}
            </>
          )}
        </BoxWhite>
        <BoxWhite
          title={
            text_my_favorite_products
              ? text_my_favorite_products.toUpperCase()
              : "MIS PRODUCTOS FAVORITOS"
          }
        >
          {favorite_loading ? (
            <div className="d-flex justify-content-center">
              <Spinner type="purple" />
            </div>
          ) : (
            <>
              {favorite_products.length > 0 ? (
                <>
                  <ProductList
                    data={favorite_products_view}
                    classColumn="col-md-3"
                  />
                  <Pagination
                    data={favorite_products}
                    itemsPerPage={favorite_item_per_page}
                    currentPage={favorite_products_page}
                    changePage={changeProductsPage}
                  />
                </>
              ) : (
                <Error
                  type="no-data"
                  title={
                    text_favorite_products_error
                      ? text_favorite_products_error
                      : `No hay productos favoritos disponibles.`
                  }
                />
              )}
            </>
          )}
        </BoxWhite>
      </>
    );
  }
}

const mapStateToProps = ({ userReducer, favoriteReducer, siteReducer }) => {
  return { userReducer, favoriteReducer, siteReducer };
};

const componentDidMount = {
  getFavorites,
  changeProductsPage,
  changeCompaniesPage,
};

export default connect(mapStateToProps, componentDidMount)(FavoritesView);
