import React, { Component } from "react";
import MarketPlaceLayout from "./layouts/MarketplaceLayout";

class BestSellerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusSearch: false,
    };
  }
  openCloseSearch = (statusSearch) => {
    this.setState({
      statusSearch,
    });
  };
  render() {
    return (
      <MarketPlaceLayout
        statusSearch={this.state.statusSearch}
        openCloseSearch={this.openCloseSearch}
      >
        BestSellerPage
      </MarketPlaceLayout>
    );
  }
}

export default BestSellerPage;
