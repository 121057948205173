import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import HomeSlider from '../../../../components/user/marketplace/HomeSlider';
import HomeMenu from '../../../../components/user/marketplace/HomeMenu';
import BoxWhite from '../../../../components/user/marketplace/BoxWhite';
import GeneralSlider from '../../../../components/user/marketplace/GeneralSlider';
import SlideProduct from '../../../../components/user/marketplace/SlideProduct';
import SlideCompany from '../../../../components/user/marketplace/SlideCompany';
import ListOffer from '../../../../components/user/marketplace/ListOffer';
import TopSpinner from '../../../../components/all/TopSpinner';
import SliderCategories from '../../../../components/user/marketplace/SliderCategories';

function IndexView(props) {
  const {
    userReducer: {
      business_featured,
      last_products,
      promotional_products,
      loading_init,
      categories,
    },
    siteReducer: {
      site_texts: {
        text_categories,
        text_featured_companies,
        text_new_products,
      },
    },
  } = props;
  let othersNewProducts = {
    type: 'new',
  };
  let view_promo =
    promotional_products.length > 0 ? promotional_products.slice(0, 12) : [];
  return (
    <Fragment>
      <HomeSlider />
      <HomeMenu openCloseSearch={props.openCloseSearch} />
      <div className="container">
        {loading_init && <TopSpinner />}
        {categories.length && (
          <BoxWhite
            title={
              text_categories ? text_categories.toUpperCase() : 'CATEGORÍAS'
            }
          >
            <SliderCategories data={categories} />
          </BoxWhite>
        )}
        {/* <BoxWhite title="LO MÁS VENDIDO">
					<GeneralSlider
						data={props.bestSteller}
						slide={SlideProduct}
						viewItems={4}
						// viewMore='/best-seller'
						others={othersBestSteller}
					/>
				</BoxWhite> */}
        {business_featured.length > 0 && (
          <BoxWhite
            title={
              text_featured_companies
                ? text_featured_companies.toUpperCase()
                : 'EMPRESAS Y PROFESIONALES DESTACADOS'
            }
          >
            <GeneralSlider
              data={business_featured}
              slide={SlideCompany}
              viewItems={4}
              // viewMore='/best-companies'
            />
          </BoxWhite>
        )}
        {view_promo.length > 0 && <ListOffer data={view_promo} />}
        {last_products.length > 0 && (
          <BoxWhite
            title={
              text_new_products
                ? text_new_products.toUpperCase()
                : 'NUEVOS PRODUCTOS Y SERVICIOS'
            }
          >
            <GeneralSlider
              data={last_products}
              slide={SlideProduct}
              viewItems={4}
              others={othersNewProducts}
              // viewMore='/new-products'
            />
          </BoxWhite>
        )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return { userReducer, siteReducer };
};

export default connect(mapStateToProps)(IndexView);
