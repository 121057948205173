export const getInfluencersSeachRoute = (
  page,
  targets,
  tags,
  cities,
  countries,
  rating,
  min_age,
  max_age,
  min_followers,
  max_followers,
  networks
) => {
  let route = '?';

  if (page > 1) {
    route = `${route}page=${page}&`;
  }
  if (targets.length) {
    targets.forEach((item) => {
      route = `${route}targets[]=${item}&`;
    });
  }
  if (tags.length) {
    tags.forEach((item) => {
      route = `${route}tags[]=${item.id}&`;
    });
  }
  if (cities.length) {
    cities.forEach((item) => {
      route = `${route}cities[]=${item.id}&`;
    });
  }
  if (countries.length && !cities.length) {
    countries.forEach((item) => {
      route = `${route}countries[]=${item.id}&`;
    });
  }
  if (networks.length) {
    networks.forEach((item) => {
      route = `${route}networks[]=${item}&`;
    });
  }
  if (rating > 0) {
    route = `${route}rating=${route}&`;
  }
  route = `${route}min-age=${min_age}&max-age=${max_age}&min-followers=${min_followers}&max_followers=${max_followers}`;

  return route;
};
