import React from 'react';
import InfluencerMetricRow from '../InfluencerMetricRow/InfluencerMetricRow';
import './InfluencerMetricTable.css';
import Spinner from '../../Spinner';
import Paginator from '../../Paginator/Paginator';
import { connect } from 'react-redux';

const InfluencerMetricTable = (props) => {
  //* the number of rows to show in the first time

  const {
    siteReducer: {
      site_texts: { text_city, text_networks, text_actions, text_categories },
    },
    currentPage,
    totalPage,
    loading,
    data,
  } = props;

  return (
    <>
      {loading ? (
        <div className="spinner-loading">
          <Spinner type="purple" />
        </div>
      ) : (
        <>
          <div className="metricTable__container">
            <table className="influencer-metric-table">
              <thead>
                <tr>
                  <th>Influencer</th>
                  <th>{text_city ? text_city : 'Ciudad'}</th>
                  <th>{text_categories ? text_categories : 'Categorías'}</th>
                  <th>{text_networks ? text_networks : 'Redes'}</th>
                  <th>Posts</th>
                  <th>{text_actions ? text_actions : 'Acciones'}</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((influencerData) => (
                    <InfluencerMetricRow
                      key={influencerData.id}
                      {...influencerData}
                    />
                  ))}
              </tbody>
            </table>
          </div>
          {totalPage > 1 && (
            <Paginator totalPages={totalPage} currentPage={currentPage} />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(InfluencerMetricTable);
