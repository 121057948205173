import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SaleViewList from '../../../../components/user/manager/SaleListView';
// import OrderViewList from "../../../../components/user/manager/OrderViewList";
import TopSpinner from '../../../../components/all/TopSpinner';
import Error from '../../../../components/all/Error';
import './css/OrderView.css';
import { NavLink } from 'react-router-dom';

function OrdersView(props) {
  const {
    siteReducer: {
      site_texts: { text_button_sales, text_purchases },
    },
    orderReducer: { order_loading, order_error, order_list_view },
  } = props;

  return (
    <Fragment>
      <div className="orderView_menu">
        <NavLink
          className={`orderView_menu-button`}
          to="/manager/orders/sales"
          activeClassName="orderView_active"
        >
          {text_button_sales ? text_button_sales.toUpperCase() : 'VENTAS'}
        </NavLink>

        <NavLink
          to="/manager/orders/purchases"
          className={`orderView_menu-button`}
          activeClassName="orderView_active"
        >
          {text_purchases ? text_purchases.toUpperCase() : 'COMPRAS'}
        </NavLink>
      </div>
      <div className="orderView_container">
        {order_loading && <TopSpinner />}
        {!order_loading && order_error !== '' && <Error type="error" />}
        {!order_loading && !order_error && (
          <SaleViewList
            data={order_list_view}
            codFilter={props.codFilter}
            filter={props.filter}
            actions={true}
          />
        )}
      </div>
    </Fragment>
  );
}

const mapStateToProps = ({ siteReducer, orderReducer }) => {
  return {
    siteReducer,
    orderReducer,
  };
};

export default withRouter(connect(mapStateToProps, null)(OrdersView));
