import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InboxNewView from './views/InboxNewView';
import ManagerLayout from './layouts/ManagerLayout';

import * as chatActions from '../../../functionality/actions/chatActions';
import TopSpinner from '../../../components/all/TopSpinner';

const { chatList, chatClearMessages, chatListView } = chatActions;

class InboxNewPage extends Component {
  state = {
    codInbox: '',
  };

  componentDidMount() {
    const {
      chatList,
      chatReducer: { chat_list_view },
    } = this.props;
    if (!chat_list_view.length) {
      chatList();
    }
  }

  handleFilter = (not_read) => {
    const {
      chatListView,
      chatReducer: { chat_list },
    } = this.props;
    if (not_read === 1) {
      let filter = chat_list.filter((item) => item.viewed === not_read);
      chatListView(filter);
    } else {
      chatListView(chat_list);
    }
  };

  render() {
    const {
      chatClearMessages,
      chatReducer: { chat_loading, chat_list_view, chat_list_proposal },
      siteReducer: {
        site_texts: { text_new_message },
      },
    } = this.props;
    return (
      <ManagerLayout>
        <div className="title-manager">
          <div>
            <span className="icon-chat" />
            Inbox
          </div>
          <Link
            to="/manager/inbox/new"
            className="btn inbox_new-message"
            onClick={() => chatClearMessages()}
          >
            <i className="icon-send-message"></i>
            {text_new_message
              ? text_new_message.toUpperCase()
              : 'NUEVO MENSAJE'}
          </Link>
        </div>
        {chat_loading ? (
          <TopSpinner />
        ) : (
          <InboxNewView
            data={chat_list_view}
            dataPorposal={chat_list_proposal}
            actions={true}
            handleFilter={this.handleFilter}
          />
        )}
      </ManagerLayout>
    );
  }
}

const mapStateToProps = ({ chatReducer, siteReducer }) => {
  return {
    chatReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  chatList,
  chatClearMessages,
  chatListView,
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxNewPage);
