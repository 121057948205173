import React from 'react';
import Analityc from '../../Analytic/Analytic';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../utils/formatNumber';

function Instagram(props) {
  const {
    statistics,
    siteReducer: {
      site_texts: { text_reach, text_prints },
    },
  } = props;

  const getValue = (name) => {
    const item = statistics.find((item) => item.name === name);

    if (!item || !item.values) {
      return 0;
    }

    return formatNumber(parseInt(item.values[0].value));
  };

  return (
    <>
      <div className="analitycs__container">
        <Analityc
          icon={'fa-solid fa-users-rectangle'}
          title={text_reach || 'Alcance'}
          value={getValue('reach')}
        />
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={text_prints || 'Impresiones'}
          value={getValue('impressions')}
        />
      </div>
    </>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return { siteReducer };
};

export default connect(mapStateToProps, null)(Instagram);
