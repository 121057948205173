import React from "react";
import { Link } from "react-router-dom";
import "./css/SlideOffer.css";
import ImageOffer from "../../../images/default-picture.jpg";

function SlideOffer(props) {
  let { data } = props;
  let images = data.images;
  const numberFormat = new Intl.NumberFormat("es-CO");

  return (
    <Link
      to={`/product/${data.slug}`}
      style={{ color: data.color }}
      className="slideOffer_container"
    >
      {images.length > 0 ? (
        <img
          src={images[0].image}
          alt={data.name}
          className="slideOffer_image"
        />
      ) : (
        <img src={ImageOffer} alt={data.name} className="slideOffer_image" />
      )}
      <div className="slideOffer_info">
        <div className="slideOffer_name">{data.name}</div>
        <div className="slideOffer_description">
          {data.short_description.slice(0, 100)}...
        </div>
        <div className="slideOffer_price-promo">
          BAX$ {numberFormat.format(data.price)}
        </div>
        <div className="slideOffer_price">
          BAX$ {numberFormat.format(data.price_promo)}
        </div>
      </div>
    </Link>
  );
}

export default SlideOffer;
