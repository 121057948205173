import {
  INFLUENCER_TARGET_FILTER,
  INFLUENCER_NETWORKS_FILTER,
  INFLUENCER_TAGS_FILTER,
  INFLUENCER_CITIES_FILTER,
  INFLUENCER_RATING_FILTER,
  INFLUENCER_AGE_MIN_FILTER,
  INFLUENCER_AGE_MAX_FILTER,
  INFLUENCER_FOLLOWER_MIN_FILTER,
  INFLUENCER_FOLLOWER_MAX_FILTER,
  INFLUENCER_COUNTRIES_FILTER,
  INFLUENCER_LIST,
  INFLUENCER_PAGE,
  INFLUENCER_TOTAL_PAGES,
  INFLUENCER_LOADING,
  INFLUENCER_TOTAL_LIST,
  INFLUENCER_DETAIL,
  INFLUENCER_DETAIL_LOADING,
  INFLUENCER_DETAIL_ERROR,
} from '../types/influencerTypes';

const INITIAL_STATE = {
  influencer_target_filter: [],
  influencer_networks_filter: [],
  influencer_tags_filter: [],
  influencer_cities_filter: [],
  influencer_countries_filter: [],
  influencer_rating_filter: 0,
  influencer_age_min_filter: 18,
  influencer_age_max_filter: 90,
  influencer_follower_min_filter: 2000,
  influencer_follower_max_filter: 1000000,
  influencer_loading: true,
  influencer_list: [],
  influencer_page: 1,
  influencer_total_pages: 1,
  influencer_total_list: 0,
  influencer_detail: null,
  influencer_detail_loading: true,
  influencer_detail_error: false,
};

const influencerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INFLUENCER_TARGET_FILTER:
      return {
        ...state,
        influencer_target_filter: action.payload,
      };
    case INFLUENCER_NETWORKS_FILTER:
      return {
        ...state,
        influencer_networks_filter: action.payload,
      };
    case INFLUENCER_TAGS_FILTER:
      return {
        ...state,
        influencer_tags_filter: action.payload,
      };
    case INFLUENCER_CITIES_FILTER:
      return {
        ...state,
        influencer_cities_filter: action.payload,
      };
    case INFLUENCER_COUNTRIES_FILTER:
      return {
        ...state,
        influencer_countries_filter: action.payload,
      };
    case INFLUENCER_RATING_FILTER:
      return {
        ...state,
        influencer_rating_filter: action.payload,
      };
    case INFLUENCER_AGE_MIN_FILTER:
      return {
        ...state,
        influencer_age_min_filter: action.payload,
      };
    case INFLUENCER_AGE_MAX_FILTER:
      return {
        ...state,
        influencer_age_max_filter: action.payload,
      };
    case INFLUENCER_FOLLOWER_MIN_FILTER:
      return {
        ...state,
        influencer_follower_min_filter: action.payload,
      };
    case INFLUENCER_FOLLOWER_MAX_FILTER:
      return {
        ...state,
        influencer_follower_max_filter: action.payload,
      };
    case INFLUENCER_LOADING:
      return {
        ...state,
        influencer_loading: action.payload,
      };
    case INFLUENCER_LIST:
      return {
        ...state,
        influencer_list: action.payload,
      };
    case INFLUENCER_PAGE:
      return {
        ...state,
        influencer_page: action.payload,
      };
    case INFLUENCER_TOTAL_PAGES:
      return {
        ...state,
        influencer_total_pages: action.payload,
      };
    case INFLUENCER_TOTAL_LIST:
      return {
        ...state,
        influencer_total_list: action.payload,
      };
    case INFLUENCER_DETAIL:
      return {
        ...state,
        influencer_detail: action.payload,
      };
    case INFLUENCER_DETAIL_LOADING:
      return {
        ...state,
        influencer_detail_loading: action.payload,
      };
    case INFLUENCER_DETAIL_ERROR:
      return {
        ...state,
        influencer_detail_error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default influencerReducer;
