import React, { Fragment } from "react";
import { connect } from "react-redux";
import { orderFilterByStatus } from "../../../functionality/actions/orderActions";
import SaleList from "./SaleList";
import "./css/SaleViewList.css";

function SaleViewList(props) {
  const {
    siteReducer: {
      site_language,
      site_texts: { text_all },
    },
    userReducer: { order_statuses },
    orderReducer: { order_status_code },
    orderFilterByStatus,
  } = props;

  let activeAll = order_status_code === "" ? "active" : "";
  return (
    <Fragment>
      <div className="saleViewList_header">
        {!props.type_menu && (
          <Fragment>
            <button
              className={`saleViewList_btn-end ${activeAll}`}
              onClick={() => orderFilterByStatus("")}
            >
              {text_all ? text_all : "Todas"}
            </button>
            {order_statuses.map((item) => {
              let activeNew =
                parseInt(order_status_code) === parseInt(item.id)
                  ? `2px solid ${item.color}`
                  : `2px solid #ffffff`;

              return (
                <button
                  className={`saleViewList_btn`}
                  style={{
                    color: item.color,
                    borderBottom: activeNew,
                  }}
                  onClick={() => orderFilterByStatus(item.id)}
                  key={item.id}
                >
                  {site_language === "en" || !site_language
                    ? item.english_name
                    : item.name}
                </button>
              );
            })}
          </Fragment>
        )}
      </div>
      <div className="saleViewList_content">
        <SaleList data={props.data} actions={props.actions} type={props.type} />
      </div>
    </Fragment>
  );
}

const mapStateToProps = ({ siteReducer, userReducer, orderReducer }) => {
  return {
    siteReducer,
    userReducer,
    orderReducer,
  };
};

const mapDispatchToProps = {
  orderFilterByStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaleViewList);
