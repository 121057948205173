import React, { Component } from 'react';
import { connect } from 'react-redux';
import MarketplaceLayout from '../marketplace/layouts/MarketplaceLayout';
import BoxForm from '../../../components/all/BoxForm';

import ReactGA from 'react-ga';

const GA = process.env.REACT_APP_GA;
ReactGA.initialize(GA);
ReactGA.event({
  category: 'registro',
  action: 'registro_completo',
});

class StatusPage extends Component {
  render() {
    const {
      userReducer: { user_data },
      siteReducer: {
        site_texts: { text_hi, text_hi_profile },
      },
    } = this.props;

    return (
      <MarketplaceLayout>
        {user_data && (
          <BoxForm>
            <div className="container">
              <h3>
                <span className="color-fuchsia">
                  {text_hi ? text_hi : 'Hola'}{' '}
                </span>
                <span className="color-purple">
                  {user_data.name} {user_data.lastname}
                </span>
              </h3>
              <p>
                {text_hi_profile
                  ? text_hi_profile
                  : 'Tu perfil se encuentra en estudio, pronto nos contactaremos para informate el estado del proceso.'}
              </p>
            </div>
          </BoxForm>
        )}
      </MarketplaceLayout>
    );
  }
}
const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps)(StatusPage);
