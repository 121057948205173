import React, { useEffect } from 'react';
import BreifPorposal from '../../../../../components/all/BreifPorposal/BreifPorposal';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import ManagerLayout from '../../layouts/ManagerLayout';
import CampaignDetailView from '../../views/CampaignDetailView/CampaignDetailView';
import {
  getPorposalsByCampaign,
  porposalClear,
  getPorposalEdit,
  setPorposalEdit,
  setCurrentPorposal,
} from '../../../../../functionality/actions/porposalActions';
import Spinner from '../../../../../components/all/Spinner';

const EditPorposalPage = (props) => {
  const {
    userReducer: {
      user_data: { user_type_id },
    },
    campaignReducer: { campaign_current_detail },
    porposalReducer: { current_porposal, porposal_loading },
    match: {
      params: { consecutive: propose_consecutive },
    },
    getPorposalEdit,
    setPorposalEdit,
    setCurrentPorposal,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (propose_consecutive) {
          await getPorposalEdit(propose_consecutive);
          setPorposalEdit();
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (propose_consecutive) {
      fetchData();
    }

    return () => {
      setCurrentPorposal();
    };
  }, []);

  return (
    <ManagerLayout>
      <CampaignDetailView>
        {porposal_loading ? (
          <div className="spinner-loading">
            <Spinner type="purple" />
          </div>
        ) : (
          <>
            {user_type_id !== 8 && (
              <Redirect
                to={`/manager/campaign/${campaign_current_detail.consecutive}`}
              />
            )}
            <div className="form-porposal-container">
              <BreifPorposal isEdit data={campaign_current_detail} />
            </div>
          </>
        )}
      </CampaignDetailView>
    </ManagerLayout>
  );
};

const mapStateToProps = ({ campaignReducer, userReducer, porposalReducer }) => {
  return {
    campaignReducer,
    userReducer,
    porposalReducer,
  };
};
const mapDispatchToProps = {
  getPorposalsByCampaign,
  porposalClear,
  getPorposalEdit,
  setPorposalEdit,
  setCurrentPorposal,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditPorposalPage)
);
