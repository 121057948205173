import React, { Component } from 'react';
import { connect } from 'react-redux';
import { myProducts } from '../../../../functionality/actions/productActions';
import { adGetDetail } from '../../../../functionality/actions/adActions';
import { Link } from 'react-router-dom';
import Spinner from '../../../../components/all/Spinner';
import Form from '../../../../components/user/manager/Ads/Form';
import BoxWhite from '../../../../components/user/marketplace/BoxWhite';

class AdFormView extends Component {
  componentDidMount() {
    const { id, myProducts, adGetDetail } = this.props;

    if (id) {
      adGetDetail(id);
    }
    myProducts();
  }

  render() {
    const {
      id,
      adReducer: { ad_detail_loading, ad_settings_loading },
      siteReducer: {
        site_texts: { text_back, text_advertising_campaign },
      },
    } = this.props;

    return (
      <>
        <div className="title-manager">
          <div>
            <span className="icon-ad" />
            {id ? 'Editar ' : 'Crear '}{' '}
            {text_advertising_campaign || 'Campaña Publicitaria'}
          </div>
          <Link to="/manager/ads" className="btn btn-pymex">
            {text_back || 'Volver'}
          </Link>
        </div>
        <div className="container">
          <div className="row">
            <BoxWhite marginTop={-20}>
              {ad_detail_loading || ad_settings_loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner type="purple" />
                </div>
              ) : (
                <Form />
              )}
            </BoxWhite>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ adReducer, siteReducer }) => {
  return {
    adReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  myProducts,
  adGetDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdFormView);
