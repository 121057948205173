import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  changeLegalRepresentative,
  changeNameLegalRepresentative,
  changeLastnameLegalRepresentative,
  changeDocType,
  changeDocNumber,
  changeDocDate,
  changeProfession,
  changeNitNumber,
  changePhone,
  changeWebsite,
  changeActivity,
  changeAvgSales,
  changeDescription,
  changeAddress,
  changeAddressAddon,
  changeCodDepartment,
  changeCodCity,
  changeUserSocial,
  changeFacebook,
  changeTwitter,
  changeLinkedin,
  changeInstagram,
  changeDocFront,
  changeDocBack,
  changeTypePerson,
  changeCamaraComercio,
  changeRutScann,
  changeUserProviders,
  changeRiskCenters,
  changeUserValidInformation,
  changeBusinessName,
  requestAprovation,
  userSaveDocs,
  userUpdate,
  openLoading,
} from '../../functionality/actions/userActions';
import { cropperImage } from '../../functionality/actions/cropperActions';
import {
  brandChangeQuestion,
  brandChangeImage,
  brandChangeCover,
} from '../../functionality/actions/brandActions';
import CropImages from '../all/CropImages';
import Questions from '../Questions';
import Input from '../Questions/Input';
import InputFile from '../Questions/InputFile';
import Select from '../Questions/Select';
import AddCompanies from '../user/manager/AddCompanies';
import Api from '../../functionality/Api';
import ManagerBrands from '../ManagerBrands';

class DocumentRegister extends Component {
  state = {
    imageName: '',
  };

  renderDepartments = () => {
    const {
      userReducer: { departments, user_cod_department },
      changeCodDepartment,
    } = this.props;
    return (
      <Select
        options={departments}
        value={user_cod_department}
        onChange={changeCodDepartment}
      />
    );
  };

  renderCities = () => {
    const {
      userReducer: { user_cod_department, cities, user_cod_city },
      changeCodCity,
    } = this.props;
    let citiesDepartment = cities.filter(
      (city) => city.department_id === parseInt(user_cod_department)
    );
    return (
      <Select
        options={citiesDepartment}
        value={user_cod_city}
        onChange={changeCodCity}
      />
    );
  };

  onChangeImage = (e, imageName, width = 500, height = 500) => {
    const { cropperImage } = this.props;
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    this.setState({
      imageName,
    });

    const reader = new FileReader();
    reader.onload = () => {
      let config = {
        cropper_image: reader.result,
        cropper_ratio_width: width,
        cropper_ratio_height: height,
      };
      cropperImage(config);
    };
    reader.readAsDataURL(files[0]);
  };

  onCrop = async (image) => {
    const {
      siteReducer: { text_uploading_image },
      openLoading,
      brandChangeImage,
      brandChangeCover,
    } = this.props;
    const { imageName } = this.state;

    if (imageName === 'img_profile') {
      openLoading(
        text_uploading_image ? text_uploading_image : 'Subiendo Imagen...'
      );
      let data = new FormData();
      data.append('imgBase64', image);
      Api.uploadFile(data, (res) => {
        if (res.location) {
          document.getElementById(imageName).value = '';
          this.userUpdate(res.location);
        }
      });
      return;
    }
    if (imageName === 'brand_image') {
      brandChangeImage(image);
      return;
    }
    if (imageName === 'brand_cover') {
      brandChangeCover(image);
      return;
    }
  };

  userUpdate = (image) => {
    const { userUpdate } = this.props;
    const { imageName } = this.state;

    let data;
    if (imageName === 'img_profile') {
      data = {
        image,
      };
    }

    userUpdate(data);
  };

  render() {
    const {
      userReducer: {
        user_legal_representative,
        user_name_legal_representative,
        user_lastname_legal_representative,
        doc_type,
        doc_number,
        doc_expedition_date,
        profession,
        nit_number,
        phone,
        website,
        cod_activity,
        activities,
        average_sales,
        description,
        address,
        address_addon,
        user_cod_department,
        user_cod_city,
        user_social,
        facebook,
        twitter,
        linkedin,
        instagram,
        doc_scan_front,
        doc_scan_back,
        user_type_person,
        camara_comercio,
        rut_scann,
        user_providers,
        user_risk_centers,
        user_valid_information,
        business_name,
      },
      changeLegalRepresentative,
      changeNameLegalRepresentative,
      changeLastnameLegalRepresentative,
      changeDocType,
      changeDocNumber,
      changeDocDate,
      changeProfession,
      changeNitNumber,
      changePhone,
      changeWebsite,
      changeActivity,
      changeAvgSales,
      changeDescription,
      changeAddress,
      changeAddressAddon,
      changeUserSocial,
      changeFacebook,
      changeTwitter,
      changeLinkedin,
      changeInstagram,
      changeDocFront,
      changeDocBack,
      changeTypePerson,
      changeCamaraComercio,
      changeRutScann,
      changeUserProviders,
      changeRiskCenters,
      changeUserValidInformation,
      changeBusinessName,
      requestAprovation,
      userSaveDocs,
      userUpdate,
      brandChangeQuestion,
      siteReducer: {
        site_texts: {
          text_questions_1,
          text_questions_1_suggestion,
          text_yes,
          text_no,
          text_questions_2,
          text_names,
          text_surnames,
          text_questions_3,
          text_cc,
          text_ce,
          text_pnn,
          text_questions_4,
          text_questions_5,
          text_questions_6,
          text_position_representative,
          text_questions_7,
          text_nit_rut,
          text_questions_8,
          text_phone,
          text_questions_9,
          text_web_page,
          text_questions_10,
          text_questions_11,
          text_monthly_sales,
          text_questions_12,
          text_describe_business,
          text_questions_13,
          text_address,
          text_tower,
          text_questions_14,
          text_questions_15,
          text_questions_16,
          text_upload_front_face,
          text_front_face,
          text_questions_17,
          text_upload_back_face,
          text_upper_side,
          text_questions_18,
          text_legal_person,
          text_natural_person,
          text_questions_20,
          text_last_30_days,
          text_chamber_commerce,
          text_upload_pdf,
          text_questions_19,
          text_rut,
          text_questions_21,
          text_questions_22,
          text_company_name,
          text_risk_centers,
          text_request_approval,
          text_valid_information,
          text_company_logo,
          text_company_logo_description,
          text_show_logo,
          text_updalod_image,
          text_brand_question,
          text_company_no_brand,
        },
      },
      userReducer: { user_data },
      brandReducer: { brand_question, brand_user_list },
    } = this.props;

    const questions = [
      {
        step: 1,
        question: text_company_name || 'Nombre de la empresa',
        type: 'input',
        onChange: changeBusinessName,
        value: business_name,
        content: true,
        menu: business_name !== '',
        save: () => {
          userSaveDocs({
            business_name,
          });
        },
      },
      {
        step: 2,
        question:
          text_questions_1 || '¿Es usted el representante legal de la empresa?',
        note:
          text_questions_1_suggestion ||
          'Es importante recordarte que al final será el representante legal quien valide la identidad de la empresa.',
        value: user_legal_representative,
        onChange: (value) => changeLegalRepresentative(value),
        options: [
          { id: 0, name: text_no || 'No' },
          { id: 1, name: text_yes || 'Si' },
        ],
        menu:
          user_name_legal_representative !== '' &&
          user_lastname_legal_representative !== '',
        content: true,
        type: 'select-option',
        save: () => {
          let data = {
            legal_representative: user_name_legal_representative,
            name_legal_representative: user_name_legal_representative,
            lastname_legal_representative: user_lastname_legal_representative,
          };

          userUpdate(data);
        },
        isMultiple: true,
        multipleValidation: 0,
        multipleRender: () => (
          <>
            <div className="questions_question-text">
              {text_questions_2 ||
                'Escriba el nombre y apellidos del representante legal'}
            </div>
            <div className="row">
              <div className="col-md-6">
                <Input
                  value={user_name_legal_representative}
                  onChange={changeNameLegalRepresentative}
                  placeholder={text_names}
                />
              </div>
              <div className="col-md-6">
                <Input
                  value={user_lastname_legal_representative}
                  onChange={changeLastnameLegalRepresentative}
                  placeholder={text_surnames}
                />
              </div>
            </div>
          </>
        ),
      },
      {
        step: 3,
        question: text_questions_3 || 'Tipo de documento representante legal',
        options: [
          { id: 'CC', name: text_cc ? text_cc : 'CÉDULA DE CIUDADANÍA' },
          { id: 'CE', name: text_ce ? text_ce : 'CÉDULA DE EXTRANJERÍA' },
          { id: 'PPN', name: text_pnn ? text_pnn : 'PASAPORTE' },
        ],
        type: 'select-option',
        onChange: changeDocType,
        value: doc_type,
        content: true,
        buttonClass: 'questions_option',
        menu: doc_type !== '',
        save: () => {
          userUpdate({
            doc_type,
          });
        },
      },
      {
        step: 4,
        question: text_questions_4 || 'No. Documento representante legal',
        onChange: changeDocNumber,
        value: doc_number,
        content: true,
        menu: doc_number !== '',
        inputType: 'number',
        save: () => {
          userUpdate({
            doc_number,
          });
        },
      },
      {
        step: 5,
        question: text_questions_5 || 'Fecha de expedición del documento',
        onChange: changeDocDate,
        value: doc_expedition_date,
        content: true,
        menu: doc_expedition_date !== '',
        placeholder: 'yyyy-mm-dd',
        save: () => {
          userUpdate({
            doc_expedition_date,
          });
        },
      },
      {
        step: 6,
        question: text_questions_6 || 'Cargo del resentante legal',
        onChange: changeProfession,
        value: profession,
        content: true,
        menu: profession !== '',
        placeholder: text_position_representative || '',
        save: () => {
          userUpdate({
            profession,
          });
        },
      },
      {
        step: 7,
        question: text_questions_7 || 'NIT de la empresa',
        onChange: changeNitNumber,
        value: nit_number,
        content: true,
        menu: nit_number !== '',
        placeholder: text_nit_rut || '',
        save: () => {
          userSaveDocs({
            nit_number,
          });
        },
      },
      {
        step: 8,
        question: text_questions_8 || 'Número de teléfono',
        onChange: changePhone,
        value: phone,
        content: true,
        menu: phone !== '',
        placeholder: text_phone || '',
        save: () => {
          userSaveDocs({
            phone,
          });
        },
      },
      {
        step: 9,
        question: text_questions_9 || 'Sitio Web',
        onChange: changeWebsite,
        value: website,
        content: true,
        menu: website !== '',
        placeholder: text_web_page || '',
        save: () => {
          userSaveDocs({
            website,
          });
        },
      },
      {
        step: 10,
        question: text_questions_10 || 'Selecciona la actividad del comercio',
        options: activities,
        type: 'select',
        onChange: changeActivity,
        value: cod_activity,
        content: true,
        buttonClass: 'questions_option',
        menu: cod_activity !== '',
        save: () => {
          userSaveDocs({
            activity_id: cod_activity,
          });
        },
      },
      {
        step: 11,
        question:
          text_questions_11 || 'Promedio de ventas mensual de la empresa',
        onChange: changeAvgSales,
        value: average_sales,
        content: true,
        menu: average_sales !== '',
        inputType: 'number',
        placeholder: text_monthly_sales || '',
        save: () => {
          userSaveDocs({
            average_sales,
          });
        },
      },
      {
        step: 12,
        question: text_questions_12 || 'Haz una descripción del negocio',
        placeholder: text_describe_business || '',
        onChange: changeDescription,
        value: description,
        content: true,
        menu: description !== '',
        type: 'textarea',
        save: () => {
          userSaveDocs({
            description,
          });
        },
      },
      {
        step: 13,
        question: text_questions_13 || 'Dirección de la empresa',
        content: false,
        type: 'children',
        menu:
          address !== '' && user_cod_department !== '' && user_cod_city !== '',
        children: (
          <>
            <div className="row">
              <div className="col-md-6 mb-2">{this.renderDepartments()}</div>
              <div className="col-md-6 mb-2">{this.renderCities()}</div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <Input
                  value={address}
                  onChange={changeAddress}
                  placeholder={text_address}
                  type="text"
                />
              </div>
              <div className="col-md-6 mb-2">
                <Input
                  value={address_addon}
                  onChange={changeAddressAddon}
                  placeholder={text_tower}
                  type="text"
                />
              </div>
            </div>
          </>
        ),
        save: () => {
          userSaveDocs({
            address,
            city_id: user_cod_city,
            address_addon,
          });
        },
      },
      {
        step: 14,
        question: text_questions_14 || '¿La empresa tiene redes sociales?',
        value: user_social,
        onChange: (value) => changeUserSocial(value),
        options: [
          { id: 0, name: text_no || 'No' },
          { id: 1, name: text_yes || 'Si' },
        ],
        menu:
          (user_social !== '' && parseInt(user_social) === 0) ||
          facebook !== '' ||
          twitter !== '' ||
          linkedin !== '' ||
          instagram !== '',
        content: true,
        type: 'select-option',
        save: () => {
          let data = {};
          if (facebook && facebook !== '') data.facebook = facebook;
          if (twitter && twitter !== '') data.twitter = twitter;
          if (instagram && instagram !== '') data.instagram = instagram;
          if (linkedin && linkedin !== '') data.linkedin = linkedin;

          userSaveDocs(data);
        },
        isMultiple: true,
        multipleValidation: 1,
        multipleRender: () => (
          <>
            <div className="questions_question-text">
              {text_questions_15 ||
                'Escribe el nombre del usuario de las redes sociales'}
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <div className="d-flex align-items-center">
                  facebook.com/
                  <Input onChange={changeFacebook} value={facebook} />
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="d-flex align-items-center">
                  twitter.com/
                  <Input onChange={changeTwitter} value={twitter} />
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="d-flex align-items-center">
                  instagram.com/
                  <Input onChange={changeInstagram} value={instagram} />
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="d-flex align-items-center">
                  linkedin.com/
                  <Input onChange={changeLinkedin} value={linkedin} />
                </div>
              </div>
            </div>
          </>
        ),
      },
      {
        step: 15,
        question:
          text_questions_16 ||
          'Adjunta un escaner o una fotografía del documento del representante legal (Cara frontal)',
        content: true,
        menu: doc_scan_front !== '',
        type: 'input-file',
        inputFileId: 'frontDoc',
        onSaveDoc: (file) => {
          let data = { doc_scan_front: file };
          changeDocFront(file);
          userSaveDocs(data);
        },
        value: doc_scan_front,
        inputFileLabel: text_front_face || 'Cara Frontal',
        acceptInputFile: 'image/*',
        inputFileTitle: text_upload_front_face || 'SUBIR CARA FRONTAL',
        save: () => null,
      },
      {
        step: 16,
        question:
          text_questions_17 ||
          'Adjunta un escaner o una fotografía del documento del representante legal (Cara posterior)',
        content: true,
        menu: doc_scan_back !== '',
        type: 'input-file',
        inputFileId: 'backDoc',
        onSaveDoc: (file) => {
          let data = { doc_scan_back: file };
          changeDocBack(file);
          userSaveDocs(data);
        },
        value: doc_scan_back,
        inputFileLabel: text_upper_side || 'Cara Posterior',
        acceptInputFile: 'image/*',
        inputFileTitle: text_upload_back_face || 'SUBIR CARA POSTERIOR',
        save: () => null,
      },
      {
        step: 17,
        question: text_questions_18 || 'Selecciona el tipo de persona',
        value: user_type_person,
        onChange: (value) => changeTypePerson(value),
        options: [
          {
            id: 1,
            name: text_natural_person || 'Persona natural',
          },
          {
            id: 2,
            name: text_legal_person || 'Persona jurídica',
          },
        ],
        menu:
          (user_type_person !== '' && user_type_person === 1) ||
          camara_comercio !== '',
        content: true,
        type: 'select-option',
        save: () => {
          userUpdate({
            type_person: user_type_person,
          });
        },
        isMultiple: true,
        multipleValidation: 2,
        buttonClass: 'questions_option',
        multipleRender: () => (
          <>
            <div className="questions_question-text">
              {text_questions_20 ||
                'Adjunta el RUT del representante legal en formato PDF'}
            </div>
            <div className="questions_note">
              {text_last_30_days ||
                '(Últimos 30 días, solo para personas jurídicas)'}
            </div>
            <InputFile
              id="ccb"
              saveDoc={(file) => {
                let data = { camara_comercio: file };
                changeCamaraComercio(file);
                userSaveDocs(data);
              }}
              value={camara_comercio}
              label={text_chamber_commerce || 'CÁMARA DE COMERCIO'}
              accept=".pdf"
              title={text_upload_pdf || 'CARGAR PDF'}
            />
          </>
        ),
      },
      {
        step: 18,
        question:
          text_questions_19 ||
          'Adjunta el RUT del representante legal en formato PDF',
        content: true,
        menu: rut_scann !== '',
        type: 'input-file',
        inputFileId: 'rut',
        onSaveDoc: (file) => {
          let data = { rut_scann: file };
          changeRutScann(file);
          userSaveDocs(data);
        },
        value: rut_scann,
        inputFileLabel: text_rut || 'RUT',
        acceptInputFile: '.pdf',
        inputFileTitle: text_upload_pdf || 'CARGAR PDF',
        save: () => null,
      },
      {
        step: 19,
        question: text_company_logo || 'Agrega el logo de la empresa',
        menu: user_data.image && user_data.image !== '',
        save: () => null,
        children: (
          <InputFile
            id="img_profile"
            saveDoc={(file) =>
              this.onChangeImage(file, 'img_profile', 500, 500)
            }
            value={user_data.image || ''}
            label={text_show_logo || 'Ver logo'}
            accept=".jpg, .png, .jpeg"
            title={text_updalod_image || 'Subir la imagen'}
            upload={false}
            description={
              text_company_logo_description ||
              `La imagen debe ser cuadrada con una medida mínima de 500px X 500px`
            }
            preview={true}
          />
        ),
      },
      {
        step: 20,
        question: text_brand_question || '¿Tienes una marca?',
        value: brand_question,
        onChange: (value) => brandChangeQuestion(value),
        options: [
          { id: 0, name: text_no || 'No' },
          { id: 1, name: text_yes || 'Si' },
        ],
        content: true,
        type: 'select-option',
        save: () => {
          const value = brand_user_list.length ? true : false;
          userUpdate({
            has_brands: value,
          });
        },
        isMultiple: true,
        multipleValidation: 'show',
        menu:
          brand_question === 0 || (brand_user_list && brand_user_list.length),
        multipleRender: () => (
          <>
            {brand_question === 1 ? (
              <ManagerBrands onCropImage={this.onChangeImage} />
            ) : (
              <>
                <p>
                  {text_company_no_brand ||
                    'Como no tienes marcas suscritas a la empresa, se va a crear una marca con el mismo nombre de la empresa.'}
                </p>
              </>
            )}
          </>
        ),
      },
      {
        step: 21,
        question:
          text_questions_21 ||
          '¿Tienes proveedores que te gustaría estuvieran en INFLUENX?',
        value: user_providers,
        onChange: (value) => changeUserProviders(value),
        options: [
          { id: 0, name: text_no || 'No' },
          { id: 1, name: text_yes || 'Si' },
        ],
        menu: false,
        content: true,
        type: 'select-option',
        save: () => null,
        isMultiple: true,
        multipleValidation: 'show',
        multipleRender: () => (
          <>
            {user_providers === 1 && (
              <>
                <div className="questions_question-text">
                  {text_questions_22 || 'Escribe los proveedores'}
                </div>
                <div className="questions_content-companies">
                  <div className="questions_companies">
                    <AddCompanies />
                  </div>
                </div>
              </>
            )}
            <div className="settingsView_checkbox mt-3 mb-0">
              <label>
                <input
                  type="checkbox"
                  checked={user_risk_centers ? 'checked' : ''}
                  value={user_risk_centers}
                  onChange={() => changeRiskCenters(!user_risk_centers)}
                />
                {text_risk_centers
                  ? text_risk_centers
                  : 'Autorizó la consulta y verificación de toda la información suministrada, para obtener un cupo en BAX'}
              </label>
            </div>
            <div className="settingsView_checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={user_valid_information ? 'checked' : ''}
                  value={user_risk_centers}
                  onChange={() =>
                    changeUserValidInformation(!user_valid_information)
                  }
                />
                {text_valid_information
                  ? text_valid_information
                  : 'Certifico que toda la información suministrada en este registro es veraz.'}
              </label>
            </div>
            <div className="questions_menu">
              <button className="questions_button" onClick={requestAprovation}>
                {text_request_approval
                  ? text_request_approval
                  : 'SOLICITAR APROBACIÓN'}
              </button>
            </div>
          </>
        ),
      },
    ];

    return (
      <>
        <CropImages onCrop={this.onCrop} />
        <Questions questions={questions} />
      </>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer, brandReducer }) => {
  return {
    userReducer,
    siteReducer,
    brandReducer,
  };
};

const mapDispatchToProps = {
  changeLegalRepresentative,
  changeNameLegalRepresentative,
  changeLastnameLegalRepresentative,
  changeDocType,
  changeDocNumber,
  changeDocDate,
  changeProfession,
  changeNitNumber,
  changePhone,
  changeWebsite,
  changeActivity,
  changeAvgSales,
  changeDescription,
  changeAddress,
  changeAddressAddon,
  changeCodDepartment,
  changeCodCity,
  changeUserSocial,
  changeFacebook,
  changeTwitter,
  changeLinkedin,
  changeInstagram,
  changeDocFront,
  changeDocBack,
  changeTypePerson,
  changeCamaraComercio,
  changeRutScann,
  changeUserProviders,
  changeBusinessName,
  changeRiskCenters,
  changeUserValidInformation,
  requestAprovation,
  userSaveDocs,
  userUpdate,
  cropperImage,
  openLoading,
  brandChangeQuestion,
  brandChangeImage,
  brandChangeCover,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRegister);
