import React from 'react';
import ManagerLayout from './layouts/ManagerLayout';
import CampaignDetailView from './views/CampaignDetailView/CampaignDetailView';
import BreifCampaign from '../../../components/all/BreifCampaign/BreifCampaign';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Spinner from '../../../components/all/Spinner';
import { AcceptOrRejectCampaign } from '../../../functionality/actions/campaignActions';
import Swal from 'sweetalert2';
import { getCampaignDetail } from '../../../functionality/actions/campaignActions';

const CampaignBreifPage = (props) => {
  const {
    userReducer: { user_data },
    campaignReducer: { campaign_current_detail, campaign_current_loading },
    history,
    match: {
      params: { id: consecutive },
    },
    AcceptOrRejectCampaign,
    siteReducer: {
      site_texts: {
        text_are_you_sure,
        text_not_reverse_action,
        text_view_proposal,
        text_to_accepted,
        text_reject,
        text_confirm_campaign,
        text_demiss_campaign,
      },
    },
    getCampaignDetail,
  } = props;
  const acceptOrReject = (event) => {
    const value = parseInt(event.target.value);
    const confirmButtonText =
      value === 2
        ? text_confirm_campaign || 'Confirmar campaña'
        : text_demiss_campaign || 'Rechazar Campaña';

    const routeToPush =
      value === 2
        ? `/manager/campaign/${campaign_current_detail.consecutive}/porpose`
        : '/manager/campaign';

    const swalStyled = Swal.mixin({
      customClass: {
        confirmButton: 'button__confirm',
        cancelButton: 'button__cancel',
      },
      buttonsStyling: false,
    });

    swalStyled
      .fire({
        title: text_are_you_sure || '¿Estas seguro?',
        text: text_not_reverse_action || 'Tú no podrás revertir esta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28cae4',
        cancelButtonColor: '#50318b',
        confirmButtonText: confirmButtonText,
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value === true) {
          await AcceptOrRejectCampaign(
            campaign_current_detail.consecutive,
            value
          ).then(async (res) => {
            if (res.status === 200) {
              value === 2
                ? await getCampaignDetail(consecutive)
                : history.push(routeToPush);
            }
          });
        }
      });
  };

  const ActionsToInfluencer = () => {
    return (
      <>
        <div className="breif__actions">
          {campaign_current_detail.infleuncer_status === 2 && (
            <Link
              to={`/manager/campaign/${campaign_current_detail.consecutive}/porpose`}
            >
              {text_view_proposal || 'Ver Propuestas'}
            </Link>
          )}
          {campaign_current_detail.infleuncer_status === 1 && (
            <>
              <button
                value={3}
                onClick={(event) => {
                  acceptOrReject(event);
                }}
              >
                {text_reject || 'Rechazar'}
              </button>
              <button
                value={2}
                onClick={(event) => {
                  acceptOrReject(event);
                }}
              >
                {text_to_accepted || 'Aceptar'}
              </button>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <ManagerLayout>
      <CampaignDetailView>
        <div className="breif__content">
          {campaign_current_loading ? (
            <div className="spinner-loading">
              <Spinner type="purple" />
            </div>
          ) : (
            <BreifCampaign
              loading={campaign_current_loading}
              data={campaign_current_detail}
            />
          )}

          {user_data?.user_type_id === 8 && <ActionsToInfluencer />}
        </div>
      </CampaignDetailView>
    </ManagerLayout>
  );
};

const mapStateToProps = ({ campaignReducer, userReducer, siteReducer }) => {
  return {
    campaignReducer,
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  AcceptOrRejectCampaign,
  getCampaignDetail,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignBreifPage)
);
