import { combineReducers } from 'redux';
import userReducer from './userReducer';
import cropperReducer from './cropperReducer';
import productReducer from './productReducer';
import companyReducer from './companyReducer';
import cartReducer from './cartReducer';
import paymentReducer from './paymentReducer';
import orderReducer from './orderReducer';
import chatReducer from './chatReducer';
import historialReducer from './historialReducer';
import searchReducer from './searchReducer';
import favoriteReducer from './favoriteReducer';
import dashboardReducer from './dashboardReducer';
import paymentHistorialReducer from './paymentHistorialReducer';
import notificationReducer from './notificationReducer';
import siteReducer from './siteReducer';
import referredReducer from './referredReducer';
import adReducer from './adReducer';
import registerAlertReducer from './registerAlertReducer';
import generalReducer from './generalReducer';
import brandReducer from './brandReducer';
import tagsReducer from './tagsReducer';
import targetReducer from './targetReducer';
import countryReducer from './countryReducer';
import campaignReducer from './campaignReducer';
import socialNetworksReducer from './socialNetworksReducer';
import objectivesReducer from './objectivesReducer';
import influencerReducer from './influencerReducer';
import porposalReducer from './porposalReducer';
import postReducer from './postReducer';

export default combineReducers({
  userReducer,
  cropperReducer,
  productReducer,
  companyReducer,
  cartReducer,
  paymentReducer,
  orderReducer,
  chatReducer,
  historialReducer,
  searchReducer,
  favoriteReducer,
  dashboardReducer,
  paymentHistorialReducer,
  notificationReducer,
  siteReducer,
  referredReducer,
  adReducer,
  registerAlertReducer,
  generalReducer,
  brandReducer,
  tagsReducer,
  targetReducer,
  countryReducer,
  campaignReducer,
  socialNetworksReducer,
  objectivesReducer,
  influencerReducer,
  porposalReducer,
  postReducer,
});
