export const isPromo = (datePromo) => {
  let d = new Date();
  let year = d.getFullYear();
  let prev_month = d.getMonth() + 1;
  let prev_day = d.getDate();
  let month = (prev_month < 10) ? `0${prev_month}` : prev_month;
  let day = (prev_day < 10) ? `0${prev_day}` : prev_day;
  let date = `${year}-${month}-${day}`;
  let currentDate = new Date(date);
  let isPromo = (currentDate <= datePromo) ? true : false;
  return isPromo;
}

export const viewDate = (date) => {
  let d = new Date(date);
	let year = d.getFullYear();
	let month = d.getMonth() + 1;
	let day = d.getDate();
	month = (month < 10) ? `0${month}` : month;
	day = (day < 10) ? `0${day}` : day;
  let view_date = `${year}/${month}/${day}`;
  return view_date;
}