import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './css/MenuHeader.css';
import Believe from '../../images/believe.jpg';

import * as userActions from '../../functionality/actions/userActions';
import * as cartActions from '../../functionality/actions/cartActions';
import CartModal from './CartModal';

const { logout } = userActions;
const { cartTooltip } = cartActions;

class MenuHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_visible: '',
    };
  }

  componentDidMount() {
    const { cartTooltip } = this.props;
    cartTooltip();
  }

  openCloseMenu = () => {
    const { menu_visible } = this.state;
    let status = menu_visible !== '' ? '' : 'visible';
    this.setState({ menu_visible: status });
  };

  render() {
    const {
      fixed,
      cartReducer: { cart_total },
      userReducer: { user_data, type, is_user, search, openCloseSearch },
      notificationReducer: { notification_total },
      siteReducer: {
        site_texts: {
          text_products,
          text_services,
          text_companies,
          text_form_login,
          text_form_register,
        },
      },
    } = this.props;
    const { menu_visible } = this.state;

    if (!user_data) return null;

    let classMenu =
      type === 'manager'
        ? 'menuHeader_width-manager'
        : 'menuHeader_width-marketplace';
    let img_profile =
      user_data.image && user_data.image !== '' ? user_data.image : Believe;
    const name =
      user_data.docs && user_data.docs.business_name
        ? user_data.docs.business_name
        : user_data.name;

    return (
      <div className={`menuHeader_container-menu ${classMenu}`}>
        <CartModal />
        <button className="btn-menu" onClick={this.openCloseMenu}>
          <span className="fa fa-bars" />
        </button>
        <div className={`menuHeader_info-user ${menu_visible}`}>
          {!is_user && (
            <Fragment>
              <Link to="/login">
                <span className="icon icon-login" />
                <span className="name-menu">
                  {text_form_login ? text_form_login : 'Iniciar Sesión'}
                </span>
              </Link>
              <Link to="/register">
                <span className="icon icon-profile1" />
                <span className="name-menu">
                  {text_form_register ? text_form_register : 'Registrarme'}
                </span>
              </Link>
            </Fragment>
          )}
          {is_user && (
            <Link to="/home">
              <span className="icon icon-screen" />
              <span className="name-menu">Marketplace</span>
            </Link>
          )}
          {fixed && (
            <Fragment>
              <Link to="/products">
                <span className="icon icon-list-products" />
                <span className="name-menu">
                  {text_products ? text_products : 'Productos'}
                </span>
              </Link>
              <Link to="/services">
                <span className="icon icon-service" />
                <span className="name-menu">
                  {text_services ? text_services : 'Servicios'}
                </span>
              </Link>
              <Link to="/companies">
                <span className="icon icon-company1" />
                <span className="name-menu">
                  {text_companies ? text_companies : 'Empresas'}
                </span>
              </Link>
            </Fragment>
          )}
          {search && (
            <button
              className="menuHeader_go-cart"
              onClick={() => openCloseSearch(true)}
            >
              <span className="icon-loup" />
            </button>
          )}
          <Link to="/cart" className="menuHeader_go-cart">
            {cart_total > 0 && (
              <div className="menuHeader_tooltip-cart">{cart_total}</div>
            )}
            <span className="icon-shopping-car" />
          </Link>
          {is_user && (
            <Fragment>
              <Link to="/favorites" className="menuHeader_go-cart">
                <span className="icon-like" />
              </Link>
              {!fixed ? (
                <Link to="/manager" className="btn-profile">
                  <div className="menuHeader_container-image">
                    {notification_total > 0 && (
                      <div className="circle-notification user-notification">
                        {notification_total}
                      </div>
                    )}
                    <div className="menuHeader_box-image-header">
                      <img src={img_profile} alt="Mi Logo" />
                    </div>
                  </div>
                </Link>
              ) : (
                <Link to="/manager" className="menuHeader_go-cart btn-profile">
                  <div className="menuHeader_container-image">
                    {/* <div className="circle-notification user-notification">20</div> */}
                    <div className="menuHeader_box-image-header">
                      <img src={img_profile} alt="Mi Logo" />
                    </div>
                  </div>
                </Link>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  userReducer,
  cartReducer,
  notificationReducer,
  siteReducer,
}) => {
  return {
    userReducer,
    cartReducer,
    notificationReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  logout,
  cartTooltip,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuHeader);
