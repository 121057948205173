import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getSocialNetworks } from '../../../functionality/actions/socialNetworksActions';
import OptionCheckBox from '../OptionCheckBox/OptionCheckBox';

function CheckboxSocialNetworks(props) {
  const {
    socialNetworksReducer: { social_networks },
    getSocialNetworks,
    labelName,
    values,
    onChange,
    notShow,
  } = props;

  useEffect(() => {
    if (!social_networks.length) {
      getSocialNetworks();
    }
  }, []);

  return (
    <>
      {social_networks.map((target) => {
        const isChecked = values.find((item) => item === target.id);
        if (!target[notShow]) return null;

        return (
          <OptionCheckBox
            label={target[labelName]}
            value={target.id}
            handleInputChange={onChange}
            checked={isChecked ? true : false}
            key={target.id}
          />
        );
      })}
    </>
  );
}

CheckboxSocialNetworks.defaultProps = {
  labelName: 'name',
  notShow: 'in_user',
};

const mapStateToProps = ({ socialNetworksReducer }) => {
  return {
    socialNetworksReducer,
  };
};

const mapDispatchToProps = {
  getSocialNetworks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckboxSocialNetworks);
