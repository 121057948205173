import {
  SEARCH_CITY, SEARCH_CATEGORY, SEARCH_CLEAR, SEARCH_RATING, SEARCH_NAME,
  SEARCH_TYPE, SEARCH_DEPARTMENT, SEARCH_CITIES, SEARCH_URL, SEARCH_LIST,
  SEARCH_LOADING
} from "../types/searchTypes";

export const searchClear = () => (dispatch) => {
  dispatch({
    type: SEARCH_CLEAR,
  });
}

export const searchChangeCity = (cod_city) => (dispatch) => {
  dispatch({
    type: SEARCH_CITY,
    payload: cod_city,
  });
}

export const searchChangeCategory = (cod_category) => (dispatch) => {
  dispatch({
    type: SEARCH_CATEGORY,
    payload: cod_category,
  });
}

export const searchChangeRating = (rating) => (dispatch) => {
  dispatch({
    type: SEARCH_RATING,
    payload: rating,
  });
}

export const searchChangeName = (value) => (dispatch) => {
  dispatch({
    type: SEARCH_NAME,
    payload: value,
  });
}

export const searchChangeType = (value) => (dispatch) => {
  dispatch({
    type: SEARCH_TYPE,
    payload: value,
  });
}

export const searchChangeDepartment = (value) => (dispatch) => {
  dispatch({
    type: SEARCH_DEPARTMENT,
    payload: value,
  });
}

export const searchCities = (value) => (dispatch) => {
  dispatch({
    type: SEARCH_CITIES,
    payload: value,
  });
}

export const searchUrl = (value) => (dispatch) => {
  dispatch({
    type: SEARCH_URL,
    payload: value,
  });
}

export const searchList = (data) => (dispatch) => {
  dispatch({
    type: SEARCH_LIST,
    payload: data,
  });
}

export const searchLoading = () => (dispatch) => {
  dispatch({
    type: SEARCH_LOADING,
  });
}