import React, { useEffect } from 'react';
import PorposeChat from '../../../../../../components/user/manager/PorposeChat/PorposeChat';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
  getCampaignPorposals,
  porposalClear,
} from '../../../../../../functionality/actions/porposalActions';

const CampaignChats = (props) => {
  const {
    userReducer: { user_data },
    porposalReducer: {
      porposal_campaigns,
      porposal_campaigns_accepted,
      //   porposal_loading,
    },
    match: {
      params: { id: campaign_consecutive },
    },

    siteReducer: {
      site_texts: { text_accepted, text_inbox_campaign, text_proposals },
    },
    getCampaignPorposals,
  } = props;

  useEffect(() => {
    getCampaignPorposals(campaign_consecutive);
  }, []);

  if (!user_data) return null;

  return (
    <>
      <h3 className="title__option">
        {text_inbox_campaign || 'Inbox Campaña'}
      </h3>
      <div className="row campaign__inbox__content">
        <div className="col-md-4 inbox__chats">
          {/* propuestas */}
          {user_data.user_type_id !== 8 && (
            <PorposeChat
              chats={porposal_campaigns}
              path={`/manager/campaign/${campaign_consecutive}/inbox-porpose/`}
              //   loading={porposal_loading}
              inboxChat={false}
              title={text_proposals || 'Propuestas'}
            />
          )}
          {/* aceptadas */}
          <PorposeChat
            chats={porposal_campaigns_accepted}
            path={`/manager/campaign/${campaign_consecutive}/inbox-porpose/`}
            // loading={porposal_loading}
            inboxChat={false}
            title={text_accepted || 'Aceptadas'}
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({ porposalReducer, userReducer, siteReducer }) => {
  return {
    porposalReducer,
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  getCampaignPorposals,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignChats)
);
