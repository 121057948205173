import {
  CART_TOOLTIP,
  CART_VIEW,
  CART_REMOVE,
  CART_ADD,
  CART_CLOSE_MODAL,
  CART_MESSAGE_ERROR,
  CART_CLEAR_MESSAGE_ERROR,
  CART_TOTAL_ITEMS,
  CART_IMAGES,
  CART_IS_PROMO,
  CART_PRICE,
  CART_COMMISSION,
  CART_IVA,
  CART_VIEW_PRICE,
  CART_CLEAR_DATA,
  CART_PRODUCT,
} from '../types/cartTypes';
import { USER_LOADING, USER_CLOSE_LOADING } from '../types/userTypes';
import Api from '../Api';
import { isPromo } from '../Global';
const { REACT_APP_COOKIE_NAME: COOKIE_NAME } = process.env;

export const cartTooltip = () => (dispatch) => {
  let cart_list = localStorage.getItem(`${COOKIE_NAME}_cart`);
  if (cart_list) {
    cart_list = JSON.parse(cart_list);
    dispatch({
      type: CART_TOOLTIP,
      payload: cart_list.length,
    });
  }
};

export const viewCart = () => (dispatch) => {
  let cart_list = localStorage.getItem(`${COOKIE_NAME}_cart`);

  if (cart_list) {
    cart_list = JSON.parse(cart_list);
    let data = {
      list: cart_list,
      total: cart_list.length,
    };

    dispatch({
      type: CART_VIEW,
      payload: data,
    });
  }
};

export const cartDetailProduct = (slug) => (dispatch, getState) => {
  const {
    tax_stripe_percentage_1,
    tax_stripe_percentage_2,
    tax_stripe_percentage_3,
  } = getState().userReducer;
  let cart_list = localStorage.getItem(`${COOKIE_NAME}_cart`);

  if (cart_list) {
    let cart = JSON.parse(cart_list);
    let product = cart.find((item) => item.slug === slug);

    if (product) {
      let total_items = product.total_items ? product.total_items : 1;
      let images = product.images;
      let date_promo = product.promotion_date
        ? new Date(product.promotion_date)
        : false;
      let is_promo = false;
      if (date_promo) {
        is_promo = isPromo(date_promo);
      }
      let price =
        product.price_promo !== 0 && is_promo
          ? product.price_promo * total_items
          : product.price * total_items;
      let view_price =
        product.price_promo !== 0 && is_promo
          ? product.price_promo
          : product.price;
      let commission = price * parseFloat(tax_stripe_percentage_1);

      if (price >= 8000000 && price < 15000000) {
        commission = price * parseFloat(tax_stripe_percentage_2);
      }
      if (price >= 15000000) {
        commission = price * parseFloat(tax_stripe_percentage_3);
      }

      let iva = 0;

      dispatch({
        type: CART_PRODUCT,
        payload: product,
      });
      dispatch({
        type: CART_TOTAL_ITEMS,
        payload: total_items,
      });
      dispatch({
        type: CART_IMAGES,
        payload: images,
      });
      dispatch({
        type: CART_IS_PROMO,
        payload: is_promo,
      });
      dispatch({
        type: CART_PRICE,
        payload: price,
      });
      dispatch({
        type: CART_COMMISSION,
        payload: commission,
      });
      dispatch({
        type: CART_IVA,
        payload: iva,
      });
      dispatch({
        type: CART_VIEW_PRICE,
        payload: view_price,
      });
    }
  }
};

export const cartDetailProductClear = () => (dispatch) => {
  dispatch({
    type: CART_CLEAR_DATA,
  });
};

export const addCart = (data) => (dispatch) => {
  let cart_list = localStorage.getItem(`${COOKIE_NAME}_cart`);
  if (cart_list) {
    cart_list = JSON.parse(cart_list);
    let is_product = cart_list.find(
      (product) => parseInt(product.id) === parseInt(data.id)
    );

    if (!is_product) {
      cart_list.push(data);
      localStorage.setItem(`${COOKIE_NAME}_cart`, JSON.stringify(cart_list));
      let payload = {
        list: cart_list,
        total: cart_list.length,
      };
      dispatch({
        type: CART_ADD,
        payload: data,
      });
      dispatch({
        type: CART_VIEW,
        payload,
      });
    }
  } else {
    let item = [data];
    item = JSON.stringify(item);
    localStorage.setItem(`${COOKIE_NAME}_cart`, item);
  }
};

export const removeCart = (cod_product) => (dispatch) => {
  let cart_list = localStorage.getItem(`${COOKIE_NAME}_cart`);

  if (cart_list) {
    cart_list = JSON.parse(cart_list);
    let list = cart_list.filter(
      (item) => parseInt(item.id) !== parseInt(cod_product)
    );
    localStorage.setItem(`${COOKIE_NAME}_cart`, JSON.stringify(list));
    let data = {
      list,
      total: list.length,
    };
    dispatch({
      type: CART_REMOVE,
      payload: data,
    });
  }
};

export const clearCart = () => (dispatch) => {
  dispatch({
    type: CART_CLOSE_MODAL,
  });
};

export const addItemProduct = (cod_produt, max) => (dispatch) => {
  let cart_list = localStorage.getItem(`${COOKIE_NAME}_cart`);
  if (cart_list) {
    cart_list = JSON.parse(cart_list);
    let product = viewProduct(cod_produt);
    let init = product.product.total_items ? product.product.total_items : 1;
    product.product.total_items = init < max ? init + 1 : max;
    cart_list[product.index] = product.product;

    let data = {
      list: cart_list,
      total: cart_list.length,
    };
    dispatch({
      type: CART_VIEW,
      payload: data,
    });

    saveProducts(cart_list);
  }
};

export const subtractItemProduct = (cod_produt, min) => (dispatch) => {
  let cart_list = localStorage.getItem(`${COOKIE_NAME}_cart`);
  if (cart_list) {
    cart_list = JSON.parse(cart_list);
    let product = viewProduct(cod_produt);
    let init = product.product.total_items ? product.product.total_items : 1;
    product.product.total_items = init > min ? init - 1 : min;
    cart_list[product.index] = product.product;

    let data = {
      list: cart_list,
      total: cart_list.length,
    };
    dispatch({
      type: CART_VIEW,
      payload: data,
    });

    saveProducts(cart_list);
  }
};

function viewProduct(cod_produt) {
  let cart_list = JSON.parse(localStorage.getItem(`${COOKIE_NAME}_cart`));
  let index = cart_list.findIndex((item) => item.id_product === cod_produt);
  let product = cart_list.filter((item) => item.id_product === cod_produt);
  product = product[0];
  return {
    product,
    index,
  };
}

function saveProducts(cart_list) {
  cart_list = JSON.stringify(cart_list);
  localStorage.setItem(`${COOKIE_NAME}_cart`, cart_list);
}

export const shopProduct = (slug, quantity, price) => (dispatch, getState) => {
  const {
    site_texts: {
      text_processing_purchase,
      text_no_products,
      text_cannot_products,
      text_enough_bax,
      text_sorry_payment,
    },
  } = getState().siteReducer;

  let cart_list = localStorage.getItem(`${COOKIE_NAME}_cart`);
  if (cart_list) {
    const products = JSON.parse(cart_list);
    let endCart = products.filter((product) => product.slug !== slug);
    dispatch({
      type: USER_LOADING,
      payload: text_processing_purchase
        ? text_processing_purchase
        : 'Procesando compra...',
    });
    let data = {
      quantity,
      price,
    };

    Api.callMethod(
      `v1/store/shop/${slug}`,
      'POST',
      data,
      function (res) {
        if (res.status === 201) {
          saveProducts(endCart);
          return (window.location.href = '/manager/orders/purchases');
        }
        dispatch({
          type: USER_CLOSE_LOADING,
        });
        if (res.status === 400) {
          dispatch({
            type: CART_MESSAGE_ERROR,
            payload: text_no_products
              ? text_no_products
              : 'No hay productos disponibles.',
          });
          return;
        }
        if (res.status === 403) {
          dispatch({
            type: CART_MESSAGE_ERROR,
            payload: text_cannot_products
              ? text_cannot_products
              : 'No puedes comprar tu propios productos.',
          });
          return;
        }
        if (res.status === 405) {
          dispatch({
            type: CART_MESSAGE_ERROR,
            payload: text_enough_bax
              ? text_enough_bax
              : 'No tienes BAX suficientes para hacer la compra.',
          });
          return;
        }
        dispatch({
          type: CART_MESSAGE_ERROR,
          payload: text_sorry_payment
            ? text_sorry_payment
            : 'Lo sentimos, no pudimos procesar la compra vuelve a intentarlo más tarde.',
        });
      },
      function (err) {
        dispatch({
          type: USER_CLOSE_LOADING,
        });
        dispatch({
          type: CART_MESSAGE_ERROR,
          payload: text_sorry_payment
            ? text_sorry_payment
            : 'Lo sentimos, no pudimos procesar la compra vuelve a intentarlo más tarde.',
        });
      }
    );
  }
};

export const cartClearError = () => (dispatch) => {
  dispatch({
    type: CART_CLEAR_MESSAGE_ERROR,
  });
};
