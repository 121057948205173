import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "./css/MenuCategory.css";

class MenuCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false,
    };
  }
  openCloseMenu = () => {
    this.setState({
      is_visible: !this.state.is_visible,
    });
  };
  render() {
    const {
      siteReducer: {
        site_language,
        site_texts: { text_activity, text_categories },
      },
    } = this.props;

    const activityName = text_activity
      ? text_activity.toUpperCase()
      : "ACTIVIDAD";
    const categoryName = text_categories
      ? text_categories.toUpperCase()
      : "CATEGORÍAS";
    let title = this.props.type === "companies" ? activityName : categoryName;
    let is_visible = this.state.is_visible ? "visible" : "";

    return (
      <div className="menuCategory_container">
        <div className="menuCategory_container-title">
          <div className="menuCategory_title">{title}</div>
          <button
            onClick={this.openCloseMenu}
            className={`menuCategory_button ${is_visible}`}
          >
            <span className="fa fa-chevron-down" />
          </button>
        </div>
        <div className={`menuCategory_content ${is_visible}`}>
          {this.props.data.map((item) => {
            let id = this.props.type === "companies" ? item.id : item.id;
            let total =
              this.props.type === "companies"
                ? item.total_business
                : this.props.type === "products"
                ? item.total_products.Producto
                : item.total_products.Servicio;
            return (
              <NavLink
                key={id}
                to={`/${this.props.type}/category/${id}`}
                className="menuCategory_item"
                activeClassName="menuCategory_active"
                onClick={() => {
                  let element = document.getElementById("titleCategory");
                  this.openCloseMenu();
                  element.scrollIntoView();
                }}
              >
                <div className="menuCategory_name">
                  {site_language === "es" ? item.name : item.english_name}
                </div>
                <div className="menuCategory_number">{total}</div>
              </NavLink>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(MenuCategory);
