import React, { useMemo } from 'react';
import ManagerLayout from '../layouts/ManagerLayout';
import InfluencerProfileView from '../views/InfluencerProfileView/InfluencerProfileView';

const InfluencerProfile = (props) => {
  const {
    match: { params: { username } }
  } = props;

  const memoInfluencerProfileView = useMemo(
    () => <InfluencerProfileView username={username}/>,
    [username]
  );

  return <ManagerLayout>{memoInfluencerProfileView}</ManagerLayout>;
};

export default InfluencerProfile;
