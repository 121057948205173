import {
  CHAT_LIST,
  CHAT_MESSAGES_LOADING,
  CHAT_LOADING,
  CHAT_SEND_LOADING,
  CHAT_MESSAGES,
  CHAT_CLEAR,
  CHAT_ERROR,
  CHAT_ALERT_ERROR,
  CHAT_CLEAR_ERROR,
  CHAT_MESSAGE,
  CHAT_MESSAGES_ERROR,
  CHAT_SUBJECT,
  CHAT_FOR,
  CHAT_COD_FOR,
  CHAT_SELECT_USERS,
  CHAT_NAME_FOR,
  CHAT_LIST_VIEW,
  CHAT_NEW_MESSAGE,
  CHAT_TYPE,
  CHAT_TYPE_ID,
  CHAT_LIST_PROPOSAL,
  CHAT_RESPONSE,
} from '../types/chatTypes';
import Api from '../Api';

export const chatClear = () => (dispatch) => {
  dispatch({
    type: CHAT_CLEAR,
  });
};

export const chatClearError = () => (dispatch) => {
  dispatch({
    type: CHAT_CLEAR_ERROR,
  });
};

export const chatFor = (value) => (dispatch) => {
  dispatch({
    type: CHAT_FOR,
    payload: value,
  });
};

export const chatCodFor = (value) => (dispatch) => {
  dispatch({
    type: CHAT_COD_FOR,
    payload: value,
  });
};

export const chatNameFor = (value) => (dispatch) => {
  dispatch({
    type: CHAT_NAME_FOR,
    payload: value,
  });
};

export const chatSelectUsers = (value) => (dispatch) => {
  dispatch({
    type: CHAT_SELECT_USERS,
    payload: value,
  });
};

export const chatListView = (data) => (dispatch) => {
  dispatch({
    type: CHAT_LIST_VIEW,
    payload: data,
  });
};

export const chatNew = (status) => (dispatch) => {
  dispatch({
    type: CHAT_NEW_MESSAGE,
    payload: status,
  });
};

export const chatClearMessages = () => (dispatch) => {
  dispatch({
    type: CHAT_MESSAGES,
    payload: [],
  });
  dispatch({
    type: CHAT_SUBJECT,
    payload: '',
  });
  dispatch({
    type: CHAT_COD_FOR,
    payload: '',
  });
  dispatch({
    type: CHAT_FOR,
    payload: '',
  });
};

export const chatList = () => (dispatch, getState) => {
  const {
    site_texts: { text_sorry },
  } = getState().siteReducer;
  dispatch({
    type: CHAT_LOADING,
  });
  Api.callMethod(
    `v1/chats/me`,
    'GET',
    null,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: CHAT_LIST,
          payload: res.response.data,
        });
        return;
      }
      dispatch({
        type: CHAT_ERROR,
        payload: `${
          text_sorry || 'Lo sentimos, vuelve a intentarlo más tarde.'
        }`,
      });
    },
    function (err) {
      dispatch({
        type: CHAT_ERROR,
        payload: `${
          text_sorry || 'Lo sentimos, vuelve a intentarlo más tarde.'
        }`,
      });
    }
  );
};

export const setChatListProposal = (value) => (dispatch) => {
  dispatch({
    type: CHAT_LIST_PROPOSAL,
    payload: value,
  });
};

export const chatProposalList = () => async (dispatch) => {
  dispatch({
    type: CHAT_LOADING,
  });
  const route = `v1/chats/me?type=proposal`;

  try {
    const response = await Api.asyncCallMethod(route, 'GET');
    dispatch(setChatListProposal(response.response.data));
  } catch (error) {
    console.error(error);
  }
};

export const chatMessages = (cod_chat) => (dispatch, getState) => {
  const {
    site_texts: { text_sorry },
  } = getState().siteReducer;

  dispatch({
    type: CHAT_MESSAGES_LOADING,
  });
  Api.callMethod(
    `v1/chats/${cod_chat}`,
    'GET',
    null,
    function (res) {
      if (res.status === 200) {
        const {
          response: {
            chat: { subject, send_user_id, messages, userSend, type, type_id },
          },
        } = res;
        let name = userSend.username
          ? userSend.username
          : userSend.business_name;

        dispatch({
          type: CHAT_RESPONSE,
          payload: res.response.chat,
        });

        dispatch({
          type: CHAT_SUBJECT,
          payload: subject,
        });
        dispatch({
          type: CHAT_COD_FOR,
          payload: send_user_id,
        });
        dispatch({
          type: CHAT_FOR,
          payload: name.toUpperCase(),
        });
        dispatch({
          type: CHAT_MESSAGES,
          payload: messages,
        });
        dispatch({
          type: CHAT_TYPE,
          payload: type,
        });
        dispatch({
          type: CHAT_TYPE_ID,
          payload: type_id,
        });
        return;
      }
      dispatch({
        type: CHAT_MESSAGES_ERROR,
        payload: text_sorry
          ? text_sorry
          : 'Lo sentimos, vuelve a intentarlo más tarde.',
      });
    },
    function (err) {
      dispatch({
        type: CHAT_MESSAGES_ERROR,
        payload: text_sorry
          ? text_sorry
          : 'Lo sentimos, vuelve a intentarlo más tarde.',
      });
    }
  );
};

export const chatMessagesByType = (data) => (dispatch, getState) => {
  const {
    site_texts: { text_sorry },
  } = getState().siteReducer;

  dispatch({
    type: CHAT_MESSAGES_LOADING,
  });
  Api.callMethod(
    `v1/chats/by-type?type=${data.type}&id=${data.type_id}`,
    'get',
    null,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: CHAT_MESSAGES,
          payload: res.response.chat.messages,
        });
        return;
      }
      dispatch({
        type: CHAT_MESSAGES_ERROR,
        payload: '',
      });
    },
    function (err) {
      dispatch({
        type: CHAT_MESSAGES_ERROR,
        payload: text_sorry
          ? text_sorry
          : 'Lo sentimos, vuelve a intentarlo más tarde.',
      });
    }
  );
};

export const chatChangeMessage = (value) => (dispatch) => {
  dispatch({
    type: CHAT_MESSAGE,
    payload: value,
  });
};

export const chatChangeSubject = (value) => (dispatch) => {
  dispatch({
    type: CHAT_SUBJECT,
    payload: value,
  });
};

export const chatSendMessage = (data) => (dispatch, getState) => {
  const {
    site_texts: { text_sorry },
  } = getState().siteReducer;
  dispatch({
    type: CHAT_SEND_LOADING,
  });
  Api.callMethod(
    `v1/chats/send`,
    'POST',
    data,
    function (res) {
      if (res.status === 201) {
        const {
          response: { chat, chats },
        } = res;
        dispatch({
          type: CHAT_LIST,
          payload: chats,
        });
        dispatch({
          type: CHAT_MESSAGES,
          payload: chat.messages,
        });
        return;
      }
      dispatch({
        type: CHAT_ALERT_ERROR,
        payload: `${
          text_sorry || 'Lo sentimos, vuelve a intentarlo más tarde.'
        }`,
      });
    },
    function (err) {
      dispatch({
        type: CHAT_ALERT_ERROR,
        payload: `${
          text_sorry || 'Lo sentimos, vuelve a intentarlo más tarde.'
        }`,
      });
    }
  );
};
