import React from "react";
import EmailVerified from "../../../components/user/manager/EmailVerified";
import ManagerLayout from "./layouts/ManagerLayout";
import ReactGA from "react-ga";

const GA = process.env.REACT_APP_GA;
ReactGA.initialize(GA);
ReactGA.event({
  category: "Registro",
  action: "Registro basico enviado",
});

function ConfirmPage() {
  return (
    <ManagerLayout>
      <EmailVerified />
    </ManagerLayout>
  );
}

export default ConfirmPage;
