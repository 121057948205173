import React from 'react';
import ManagerLayout from '../layouts/ManagerLayout';
import CampaignsView from '../views/CampaignsView/CampaignsView';

const CampaignPage = () => {
  return (
    <ManagerLayout>
      <CampaignsView />
    </ManagerLayout>
  );
};

export default CampaignPage;
