import React from 'react';
import {
  setCampaignName,
  setCampaignHashtags,
  setCampaignDescription,
  setCampaignPhrase,
  setCampaignDeliverables,
  setCampaignDateStart,
  setCampaignDateEnd,
  setCampaignLink,
  onSaveStep1,
  setCampaignBrandId,
} from '../../../../functionality/actions/campaignActions.js';
import { connect } from 'react-redux';
import InputGroup from '../../InputGroup/InputGroup';
import InputTextArea from '../../InputTextArea/InputTextArea';

const CampaignFormSetp1 = (props) => {
  const {
    campaignReducer: {
      campaign_brand_id,
      campaign_name,
      campaign_link,
      campaign_date_start,
      campaign_date_end,
      campaign_description,
      campaign_phrase,
      campaign_deliverables,
      campaign_hashtags,
      campaign_hashtags_text,
    },
    userReducer: {
      user_data: { brands },
    },
    siteReducer: {
      site_texts: {
        text_brand,
        text_select_brand,
        text_no_brands_available,
        text_campaign_name,
        text_end_of_campaign,
        text_campaign_start,
        text_describe_campaign,
        text_campaign_phrase,
        text_campaign_destination_link,
        text_deliverables,
        text_campaign_hashtags,
      },
    },
    setCampaignName,
    setCampaignHashtags,
    setCampaignDescription,
    setCampaignPhrase,
    setCampaignDeliverables,
    setCampaignDateStart,
    setCampaignDateEnd,
    setCampaignLink,
    setCampaignBrandId,
    // onSaveStep1,
  } = props;
  return (
    <form className="campaign__form">
      <div className="form__column">
        <div className="input__group">
          <label>{text_brand ? text_brand : 'Marca'}*</label>
          <select
            className="campaign__input"
            value={`${campaign_brand_id}`}
            onChange={(event) => {
              setCampaignBrandId(event.target.value);
            }}
          >
            <option value={null}>
              {brands
                ? text_select_brand
                  ? text_select_brand
                  : 'Seleccione su marca'
                : text_no_brands_available
                ? text_no_brands_available
                : 'No hay marcas disponibles'}
            </option>
            {brands &&
              brands.map((brand) => (
                <option value={brand.id} key={brand.id}>
                  {`${brand.name}`}
                </option>
              ))}
          </select>
        </div>

        <InputGroup
          isRequired={true}
          label={text_campaign_name ? text_campaign_name : 'Nombre de campaña'}
          name={'campaign_name'}
          type={'text'}
          value={campaign_name}
          handleInputChange={setCampaignName}
        />

        <div className="inputdate__container">
          <InputGroup
            isRequired={true}
            label={
              text_campaign_start ? text_campaign_start : 'Inicio de campaña'
            }
            name={'campaign_date_start'}
            type={'date'}
            aditionalClassname={'input__date'}
            value={campaign_date_start}
            handleInputChange={setCampaignDateStart}
          />
          <InputGroup
            isRequired={true}
            label={
              text_end_of_campaign
                ? text_end_of_campaign
                : 'Finalización de campaña'
            }
            name={'campaign_date_end'}
            type={'date'}
            aditionalClassname={'input__date'}
            value={campaign_date_end}
            handleInputChange={setCampaignDateEnd}
          />
        </div>
        <InputTextArea
          isRequired={true}
          label={
            text_describe_campaign
              ? text_describe_campaign
              : 'Describe tu campaña'
          }
          name={'campaign_description'}
          defaultValue={campaign_description}
          handleInputChange={setCampaignDescription}
        />
        <InputGroup
          isRequired={true}
          label={
            text_campaign_phrase ? text_campaign_phrase : 'Frase de la campaña'
          }
          name={'campaign_phrase'}
          type={'text'}
          value={campaign_phrase}
          handleInputChange={setCampaignPhrase}
        />
        <InputGroup
          isRequired={false}
          label={
            text_campaign_destination_link
              ? text_campaign_destination_link
              : 'link destino para la campaña'
          }
          name={'campaign_link'}
          type={'text'}
          value={campaign_link}
          handleInputChange={setCampaignLink}
        />
      </div>
      <div className="form__column">
        <InputTextArea
          isRequired={true}
          label={text_deliverables ? text_deliverables : 'Entregables'}
          name={'campaign_deliverables'}
          defaultValue={campaign_deliverables}
          handleInputChange={setCampaignDeliverables}
        />
        <div className="input__group">
          <label htmlFor="campaign__hashtag">
            {text_campaign_hashtags
              ? text_campaign_hashtags
              : 'Hashtags de campaña'}
            :
          </label>
          <div className="hashtags__pills__container">
            {campaign_hashtags.map((hashtag, index) => (
              <div key={index} className="hashtag__pill">
                {`#${hashtag}`}
              </div>
            ))}
          </div>
          <input
            type="text"
            onChange={(input) => setCampaignHashtags(input.target.value)}
            value={campaign_hashtags_text}
            className={`campaign__input`}
            id={'campaign__hashtag'}
            name={'campaign_hashtags'}
            placeholder={'example:(Fashion, LifeCare, Cinema)...'}
            required
          />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = ({ userReducer, campaignReducer, siteReducer }) => {
  return {
    userReducer,
    campaignReducer,
    siteReducer,
  };
};
const mapDispatchToProps = {
  setCampaignName,
  setCampaignHashtags,
  setCampaignDescription,
  setCampaignPhrase,
  setCampaignDeliverables,
  setCampaignDateStart,
  setCampaignDateEnd,
  setCampaignLink,
  setCampaignBrandId,

  onSaveStep1,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFormSetp1);
