import React from 'react';
import ManagerLayout from './layouts/ManagerLayout';
import CampaignDetailView from './views/CampaignDetailView/CampaignDetailView';
import InfluencerInCampaignView from './views/CampaignDetailView/InfluencerInCampaign/InfluencerInCampaignView';

const CampaignInfluencersPage = () => {
  return (
    <ManagerLayout>
      <CampaignDetailView>
        <InfluencerInCampaignView />
      </CampaignDetailView>
    </ManagerLayout>
  );
};

export default CampaignInfluencersPage;
