import React from 'react';
import './OptionCheckBox.css';

const OptionCheckBox = (props) => {
  const { label, amount, value, handleInputChange, checked, itemObject } =
    props;
  // console.log(checked);

  const handleClick = (item) => {
    handleInputChange(item);
  };
  return (
    <div className="checkbox__option mb-1">
      <input
        type={'checkbox'}
        className="checkbox__input"
        id={`${label}`}
        aria-label={`${label}`}
        aria-describedby={`${label}`}
        value={value}
        checked={checked}
        onChange={
          itemObject
            ? () => handleClick(value)
            : (event) => handleInputChange(event.target.value)
        }
      />
      <label className="checkbox__text" htmlFor={`${label}`}>
        {label}
      </label>
      {amount && <span className="checkbox__number">{amount}</span>}
    </div>
  );
};

export default OptionCheckBox;
