import React from 'react';
import './OptionCheckboxImage.css';

const OptionCheckboxImage = (props) => {
  const { image, label, icon, value, handleInputChange, checked } = props;

  return (
    <div className="checkbox__image mb-1">
      <input
        type={'checkbox'}
        className={'checkbox__input'}
        id={`${label}`}
        aria-label={`${label}`}
        aria-describedby={`${label}`}
        value={value}
        checked={checked}
        onChange={(event) => handleInputChange(event.target.value)}
      />
      <label htmlFor={`${label}`}>
        {icon && (
          <div className="icon__container">
            <i className={icon}></i>
          </div>
        )}
        {image && (
          <img className="checkbox__img" alt={`${label}__logo`} src={image} />
        )}
      </label>
      <label className="checkbox__text" htmlFor={`${label}`}>
        {label}
      </label>
    </div>
  );
};

export default OptionCheckboxImage;
