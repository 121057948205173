import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InboxView from './views/InboxView';
import ManagerLayout from './layouts/ManagerLayout';

import * as chatActions from '../../../functionality/actions/chatActions';
import TopSpinner from '../../../components/all/TopSpinner';

const { chatList, chatListView, chatProposalList } = chatActions;

class InboxPage extends Component {
  state = {
    viewBoxMessage: false,
    codInbox: '',
  };

  componentDidMount() {
    const { chatList, chatProposalList } = this.props;
    chatList();
    chatProposalList();
  }

  handleFilter = (not_read) => {
    const {
      chatListView,
      chatReducer: { chat_list },
    } = this.props;
    if (not_read === 1) {
      let filter = chat_list.filter((item) => item.viewed === not_read);
      chatListView(filter);
    } else {
      chatListView(chat_list);
    }
  };

  render() {
    const {
      chatReducer: { chat_loading, chat_list_view, chat_list_proposal },
      siteReducer: {
        site_texts: { text_new_message },
      },
    } = this.props;
    return (
      <ManagerLayout>
        <div className="title-manager">
          <div>
            <span className="icon-chat" />
            Inbox
          </div>
          <Link to="/manager/inbox/new" className="btn inbox_new-message">
            <i className="icon-send-message"></i>
            {text_new_message
              ? text_new_message.toUpperCase()
              : 'NUEVO MENSAJE'}
          </Link>
        </div>
        {chat_loading ? (
          <TopSpinner />
        ) : (
          <InboxView
            data={chat_list_view}
            dataPorposal={chat_list_proposal}
            actions={true}
            handleFilter={this.handleFilter}
          />
        )}
      </ManagerLayout>
    );
  }
}

const mapStateToProps = ({ chatReducer, siteReducer }) => {
  return {
    chatReducer,
    siteReducer,
  };
};

const mapDispatchToProps = { chatList, chatListView, chatProposalList };

export default connect(mapStateToProps, mapDispatchToProps)(InboxPage);
