import React from 'react';
import './PorposalTable.css';
import PorposalRow from '../Campaign/Table/Row/porposalRow';
import Spinner from '../Spinner';
import { connect } from 'react-redux';

const PorposalTable = (props) => {
  const {
    siteReducer: {
      site_texts: { text_name, text_social_networks, text_actions },
    },
    data,
  } = props;

  if (!data || data.length === 0) {
    return (
      <div className="spinner-loading">
        <Spinner type="purple" />
      </div>
    );
  }

  return (
    <div className="porposal__table__container">
      <table className="porposal__table">
        <thead className="porposalTable__head">
          <tr>
            <th className="porposalTable__title">
              {text_name ? text_name : 'Nombre'}
            </th>
            <th className="porposalTable__title">Status</th>
            <th className="porposalTable__title">
              {text_social_networks || 'Redes Sociales'}
            </th>
            <th className="porposalTable__title">
              {text_actions || 'Acciones'}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((porposal) => (
            <PorposalRow key={porposal.id} data={porposal} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(PorposalTable);
