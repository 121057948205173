import React, { useState, useEffect } from "react";
import Days from "./Days";
import Header from "./Header";
import Spinner from "../../../../all/Spinner";
import "./css/Calendar.css";

function Calendar(props) {
  const {
    language,
    monthFullName,
    dayFullName,
    daysToSelect,
    loading,
    setDates,
    adGetListPerSpaceAndMonth,
    dates,
    date,
  } = props;
  const [currentMonth, setCurrentMonth] = useState(null);
  const [viewMonth, setViewMonth] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [viewYear, setViewYear] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [totalDays, setTotalDays] = useState(0);
  const [selectedDates, setSelectedDates] = useState([]);
  const spanishDays = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];
  const englishDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const spaninsMonths = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const englishMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    getCurrentDate();

    if (dates) {
      setSelectedDates(dates);
    }
    if (date && !dates) {
      selectDays(date);
    }
  }, []);

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDay();
    const dayDate = date.getDate();

    setCurrentYear(year);
    setViewYear(year);
    setCurrentMonth(month);
    setViewMonth(month);
    setCurrentDay(day);
    setCurrentDate(dayDate);
  };

  useEffect(() => {
    if (viewYear && viewYear) {
      getTotalDays(viewYear, viewMonth + 1);
      adGetListPerSpaceAndMonth(viewYear, viewMonth + 1);
    }
  }, [viewMonth, viewYear]);

  const getTotalDays = (year, month) => {
    const totalDays = new Date(year, month, 0).getDate();
    setTotalDays(totalDays);
  };

  const prevMonth = () => {
    const year = new Date().getFullYear();
    const month = viewMonth === 0 ? 11 : viewMonth - 1;

    if (viewYear === year && month < currentMonth) return null;

    setViewMonth(month);

    if (month === 11) {
      setViewYear(viewYear - 1);
    }
  };

  const nextMonth = () => {
    const month = viewMonth === 11 ? 0 : viewMonth + 1;
    setViewMonth(month);

    if (month === 0) {
      setViewYear(viewYear + 1);
    }
  };

  const selectDays = (date) => {
    date = date.split("-");
    const dates = [];

    for (let i = 0; i < daysToSelect; i++) {
      const nextDate = new Date(
        date[0],
        parseInt(date[1]) - 1,
        parseInt(date[2])
      );
      nextDate.setDate(nextDate.getDate() + i);
      let month = nextDate.getMonth() + 1;
      month = `${month}`.padStart(2, 0);
      let day = nextDate.getDate();
      day = `${day}`.padStart(2, 0);

      dates.push(`${nextDate.getFullYear()}-${month}-${day}`);
    }

    setSelectedDates(dates);
    setDates(dates);
  };

  return (
    <div className="calendar_container">
      <div className={`calendar_loading ${loading ? "visible" : ""}`}>
        <Spinner type="purple" />
      </div>
      {viewMonth !== null && viewYear !== null && (
        <Header
          months={language === "es" ? spaninsMonths : englishMonths}
          days={language === "es" ? spanishDays : englishDays}
          month={viewMonth}
          year={viewYear}
          prevMonth={prevMonth}
          nextMonth={nextMonth}
          fullName={monthFullName}
          currentDay={currentDay}
          currentMonth={currentMonth}
          currentYear={currentYear}
          currentDate={currentDate}
          getCurrentDate={getCurrentDate}
        />
      )}
      {totalDays !== null && (
        <Days
          days={language === "es" ? spanishDays : englishDays}
          totalDays={totalDays}
          month={viewMonth}
          year={viewYear}
          fullName={dayFullName}
          currentYear={currentYear}
          currentMonth={currentMonth}
          currentDate={currentDate}
          selectDays={selectDays}
          selectedDates={selectedDates}
        />
      )}
    </div>
  );
}

Calendar.defaultProps = {
  language: "es",
  monthFullName: true,
  dayFullName: false,
  daysToSelect: 1,
};

export default Calendar;
