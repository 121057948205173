import React, { Component, Fragment } from 'react';
import './css/ProductView.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ListOffer from '../../../../components/user/marketplace/ListOffer';
import BoxWhite from '../../../../components/user/marketplace/BoxWhite';
import SlideProduct from '../../../../components/user/marketplace/SlideProduct';
import GalleryImages from '../../../../components/user/marketplace/GalleryImages';
import Rating from '../../../../components/user/marketplace/Rating';
import SoldBy from '../../../../components/user/marketplace/SoldBy';
import Error from '../../../../components/all/Error';
import Comments from '../../../../components/all/Comments';

import * as productActions from '../../../../functionality/actions/productActions';
import * as cartActions from '../../../../functionality/actions/cartActions';
import * as userActions from '../../../../functionality/actions/userActions';
import { isPromo } from '../../../../functionality/Global';
import TopSpinner from '../../../../components/all/TopSpinner';
import SocialNetwork from '../../../../components/all/SocialNetwork';

const { productDetail, productClearDetail, productComments } = productActions;
const { userFavorite } = userActions;
const { addCart } = cartActions;
const SITE_ROUTE = process.env.REACT_APP_SITE_ROUTE;

class ProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDescription: true,
    };
  }

  componentDidMount() {
    const { productDetail, cod_product, productComments } = this.props;
    productDetail(cod_product);
    productComments(cod_product);
  }

  componentWillUnmount() {
    const { productClearDetail } = this.props;
    productClearDetail();
  }

  componentDidUpdate(newProps) {
    const { cod_product: new_cod_product } = newProps;
    const { cod_product, productDetail, productComments } = this.props;

    if (new_cod_product !== cod_product) {
      productDetail(new_cod_product);
      productComments(new_cod_product);
    }
  }

  changeViewDescription = (viewDescription) => {
    this.setState({
      viewDescription,
    });
  };

  toLike = (e, id) => {
    e.preventDefault();
    const { userFavorite } = this.props;
    userFavorite(id);
  };

  render() {
    const {
      userReducer: { promotional_products },
      productReducer: {
        product_detail,
        related_products,
        product_comments,
        product_detail_loading,
      },
      siteReducer: {
        site_language,
        site_texts: {
          text_short_description,
          text_product_not_available,
          text_go_home,
          text_availability,
          text_in_stock,
          text_category,
          text_add_to_cart,
          text_currently_reviews,
          text_product_information,
          text_reviews,
          text_sold_by,
          text_related_products,
        },
      },
      addCart,
      cod_product,
    } = this.props;
    let others = {
      type: 'percentage',
    };
    let view_promo =
      promotional_products.length > 0 ? promotional_products.slice(0, 12) : [];
    let activeDescription = this.state.viewDescription ? 'active' : '';
    let activeRating = this.state.viewDescription ? '' : 'active';
    let data = product_detail;
    let images = data ? data.images : [];
    let type = data && data.cod_type_product === 1 ? 'products' : 'services';
    let date_promo =
      data && data.promotion_date ? new Date(data.promotion_date) : false;
    let is_promo = false;
    if (date_promo) {
      is_promo = isPromo(date_promo);
    }
    const categoryName =
      data && data.categories.length
        ? site_language === 'es'
          ? data.categories[0].name
          : data.categories[0].english_name
        : '';
    const numberFormat = new Intl.NumberFormat('es-CO');
    const productURL = `${SITE_ROUTE}product/${cod_product}`;

    return (
      <div className="container productView_container">
        <BoxWhite>
          {!product_detail_loading && !data && (
            <Error
              type="no-data"
              title={
                text_product_not_available
                  ? text_product_not_available
                  : 'Lo sentimos, el producto o servicio no se encuentra disponible.'
              }
              buttonName={text_go_home ? text_go_home : 'Ir al Inicio'}
              link="/home"
            />
          )}
          {product_detail_loading || !data ? (
            <TopSpinner />
          ) : (
            <Fragment>
              <div className="productView_title">
                <Link to={`/${type}`} className="productView_marketplace">
                  /MARKETPLACE
                </Link>
                <Link to={`/${type}/category/${data.categories[0].id}`}>
                  /{categoryName}
                </Link>
              </div>
              <div className="row productView_detail">
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-5">
                      <GalleryImages images={images} />
                    </div>
                    <div className="col-md-7">
                      <div className="productView_title-product">
                        {data.name}
                      </div>
                      <div className="d-flex align-items-center productView_separate">
                        <div className="productView_rating">
                          <Rating value={data.rating} />
                        </div>
                        {/* <div className="productView_reviews">0 reseñas</div>
												<button className="productView_to-reviews">Enviar Reseña</button> */}
                      </div>
                      <div className="productView_separate">
                        {data.price_promo !== 0 && is_promo ? (
                          <div className="productView_box-price">
                            <div className="productView_price">
                              BAX$ {numberFormat.format(data.price_promo)}
                            </div>
                            <div className="productView_discount">
                              BAX$ {numberFormat.format(data.price)}
                            </div>
                          </div>
                        ) : (
                          <div className="productView_box-price">
                            <div className="productView_price">
                              BAX$ {numberFormat.format(data.price)}
                            </div>
                          </div>
                        )}
                        <div className="d-flex align-items-center">
                          <div className="productView_availability">
                            {text_availability
                              ? text_availability
                              : 'Disponibilidad'}
                            :
                          </div>
                          <div>
                            {text_in_stock ? text_in_stock : 'En stock'}
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="productView_availability">
                            {text_category ? text_category : 'Categoría'}:
                          </div>
                          <div>{categoryName}</div>
                        </div>
                      </div>
                      <div className="productView_separate">
                        <div className="productView_title-short-description">
                          {text_short_description
                            ? text_short_description
                            : 'Descripción Corta'}
                        </div>
                        <div className="productView_short-description">
                          {data.short_description}
                        </div>
                      </div>
                      <div className="productView_separate">
                        <button
                          className="btn productView_btn-marketplace productView_btn-cart"
                          onClick={() => addCart(data)}
                        >
                          <span className="icon-to-cart" />
                          {text_add_to_cart
                            ? text_add_to_cart
                            : 'Agregar al Carrito'}
                        </button>
                        <button
                          className="btn productView_btn-marketplace"
                          onClick={(e) => this.toLike(e, data.id)}
                        >
                          <span className="icon-like" />
                        </button>
                      </div>
                      <SocialNetwork
                        url={productURL}
                        title={data.name}
                        description={data.short_description}
                        hashtags={['influenx']}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="productView_box-description">
                        <div className="productView_menu-description d-flex">
                          <button
                            className={`${activeDescription}`}
                            onClick={() => this.changeViewDescription(true)}
                          >
                            {text_product_information
                              ? text_product_information
                              : 'Información del Producto'}
                          </button>
                          <button
                            className={`${activeRating}`}
                            onClick={() => this.changeViewDescription(false)}
                          >
                            {text_reviews ? text_reviews : 'Reseñas'}{' '}
                            <span>{product_comments.length}</span>
                          </button>
                        </div>
                        <div className="productView_content-description">
                          {this.state.viewDescription && (
                            <div
                              className="productView_view-description"
                              dangerouslySetInnerHTML={{
                                __html: data.description.replaceAll(
                                  '\n',
                                  '<br />'
                                ),
                              }}
                            />
                          )}
                          {!this.state.viewDescription && (
                            <div className="productView_view-reviews">
                              {product_comments.length === 0 ? (
                                <Error
                                  type="no-data"
                                  title={
                                    text_currently_reviews
                                      ? text_currently_reviews
                                      : 'En este momento no hay reseñas.'
                                  }
                                />
                              ) : (
                                <Comments data={product_comments} />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="productView_title-seller">
                    {text_sold_by ? text_sold_by.toUpperCase() : 'VENDIDO POR'}
                  </div>
                  <SoldBy
                    data={{
                      id: data.user.id,
                      name: data.user.business_name,
                      image: data.user.image,
                      rating: data.user.rating,
                      username: data.user.username,
                    }}
                    viewRating={true}
                  />
                </div>
              </div>
            </Fragment>
          )}
          {related_products.length > 0 && (
            <div className="row">
              <div className="productView_related" />
              <div className="col-md-12 productView_subtitle">
                {text_related_products
                  ? text_related_products.toUpperCase()
                  : 'PRODUCTOS RELACIONADOS'}
              </div>
              {related_products.map((item, index) => {
                return (
                  <div className="col-md-3" key={index}>
                    <SlideProduct data={item} others={others} />
                  </div>
                );
              })}
            </div>
          )}
        </BoxWhite>
        {view_promo.length > 0 && <ListOffer data={view_promo} />}
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, productReducer, siteReducer }) => {
  return {
    userReducer,
    productReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  productDetail,
  productClearDetail,
  addCart,
  userFavorite,
  productComments,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
