import {
  REFERRED_CURRENT_PAGE,
  REFERRED_LIST,
  REFERRED_LIST_VIEW,
  REFERRED_LOADING,
  REFERRED_TOTAL,
} from "../types/ReferredTypes";

const INITIAL_STATE = {
  referred_loading: true,
  referred_list: [],
  referred_total: 0,
  referred_list_view: [],
  referred_current_page: 1,
  referred_item_per_page: 12,
};

const referredReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REFERRED_LOADING:
      return {
        ...state,
        referred_loading: action.payload,
      };
    case REFERRED_LIST:
      return {
        ...state,
        referred_list: action.payload,
      };
    case REFERRED_TOTAL:
      return {
        ...state,
        referred_total: action.payload,
      };
    case REFERRED_LIST_VIEW:
      return {
        ...state,
        referred_list_view: action.payload,
      };
    case REFERRED_CURRENT_PAGE:
      return {
        ...state,
        referred_current_page: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default referredReducer;
