import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function AdItem(props) {
  const {
    id,
    name,
    date,
    end_date,
    image,
    sent,
    is_approved,
    remove,
    siteReducer: {
      site_texts: {
        text_start_date,
        text_finish_date,
        text_not_sent_approval,
        text_status_approval,
        text_delete,
        text_edit_2,
      },
    },
  } = props;

  return (
    <div className="col-md-4">
      <div className="card adList_card">
        <img src={image} className="card-img-top" alt={name} />
        <div className="card-body">
          <h5 className="card-title">{name}</h5>
          <p className="card-text">
            {text_start_date ? text_start_date : 'Fecha Inicio'}: {date}
          </p>
          <p className="card-text">
            {text_finish_date ? text_finish_date : 'Fecha Finalización'}:{' '}
            {end_date}
          </p>
          {!sent ? (
            <p className="card-text">
              {text_not_sent_approval
                ? text_not_sent_approval
                : 'Sin enviar para aprobación'}
            </p>
          ) : (
            <p className="card-text">
              {text_status_approval
                ? text_status_approval
                : 'Estado de aprobación'}
              : {is_approved ? 'Aprobado' : 'Sin aprobar'}
            </p>
          )}
          <div className="card-menu">
            <button onClick={() => remove(id)}>
              {text_delete ? text_delete : 'Borrar'}
            </button>
            <Link to={`/manager/ads/edit/${id}`}>
              {text_edit_2 ? text_edit_2 : 'Editar'}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(AdItem);
