import React, { Component } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormLayout from "./layouts/FormLayout";

import * as userActions from "../../../functionality/actions/userActions";
import { emailExpression } from "../../../utils/globalConst";

const MySwal = withReactContent(Swal);

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusSearch: false,
    };
  }

  componentWillUnmount() {
    this.props.userClear();
  }

  showError = (message) => {
    MySwal.fire({
      title: "Upsss!!!",
      text: message,
      type: "error",
      confirmButtonColor: "#50318B",
      onClose: () => this.props.userClearError(),
    });
  };

  login = () => {
    const {
      userReducer: { email, password },
      siteReducer: {
        site_texts: { text_fields_required, text_email_written, text_logging },
      },
    } = this.props;

    if (email === "" || password === "") {
      this.showError(
        text_fields_required
          ? text_fields_required
          : "Todos los campos son obligatorios."
      );
      return false;
    }
    if (!emailExpression.test(email)) {
      this.showError(
        text_email_written
          ? text_email_written
          : "Valida que el email este bien escrito."
      );
      return false;
    }
    this.props.login(text_logging ? text_logging : "Iniciando Sesión...");
  };

  render() {
    const {
      userReducer: { is_user, email, password },
      siteReducer: {
        site_texts: {
          text_user,
          text_password,
          text_password_recovery,
          text_create_account,
          text_login,
        },
      },
    } = this.props;

    if (is_user) {
      return <Redirect to="/home" />;
    }
    return (
      <FormLayout>
        <div className="form-login">
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <label>{text_user ? text_user : "Usuario"}</label>
                <input
                  type="email"
                  placeholder="email@email.com"
                  className="form-control"
                  value={email}
                  onChange={(input) =>
                    this.props.changeEmail(input.target.value)
                  }
                />
              </div>
              <div className="">
                <label>{text_password ? text_password : "Contraseña"}</label>
                <input
                  type="password"
                  placeholder={text_password ? text_password : "Contraseña"}
                  className="form-control"
                  value={password}
                  onChange={(input) =>
                    this.props.changePassword(input.target.value)
                  }
                />
              </div>
              <div className="submenu-form">
                <Link to="/forgot">
                  {text_password_recovery
                    ? text_password_recovery
                    : "Recuperar contraseña"}
                </Link>
              </div>
              <div className="form-menu">
                <button className="btn btn-block btn-form" onClick={this.login}>
                  {text_login ? text_login : "Ingresar"}
                </button>
                <Link to="/register" className="btn btn-block btn-pymex-2">
                  {text_create_account ? text_create_account : "Crear cuenta"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </FormLayout>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, userActions)(LoginPage);
