import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './css/HomeMenu.css';

function HomeMenu(props) {
  const {
    siteReducer: {
      site_texts: { text_products, text_services, text_companies, text_search },
    },
  } = props;

  return (
    <div className="homeMenu_container">
      <NavLink
        to="/influencers"
        className="homeMenu_link"
        activeClassName="homeMenu_active"
      >
        <span className="icon-loup" />
        {'Influencers'}
      </NavLink>

      <NavLink
        to="/companies"
        className="homeMenu_link"
        activeClassName="homeMenu_active"
      >
        <span className="icon-company1" />
        {text_companies ? text_companies : 'Empresas'}
      </NavLink>

      <NavLink
        to="/services"
        className="homeMenu_link"
        activeClassName="homeMenu_active"
      >
        <span className="icon-service" />
        {text_services ? text_services : 'Servicios'}
      </NavLink>

      <NavLink
        to="/products"
        className="homeMenu_link"
        activeClassName="homeMenu_active"
      >
        <span className="icon-list-products" />
        {text_products ? text_products : 'Productos'}
      </NavLink>

      <button onClick={() => props.openCloseSearch(true)}>
        <span className="icon-loup" />
        {text_search ? text_search : 'Buscador'}
      </button>
    </div>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(HomeMenu);
