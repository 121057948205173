import React, { Component } from "react";
import { connect } from "react-redux";
import MarketplaceLayout from "./layouts/MarketplaceLayout";
import FavoritesView from "./views/FavoritesView";
import TopSpinner from "../../../components/all/TopSpinner";

class FavoritesPage extends Component {
  render() {
    const {
      userReducer: { loading_init },
    } = this.props;

    return (
      <MarketplaceLayout>
        <div className="container shoppingCartView_container">
          {loading_init ? <TopSpinner /> : <FavoritesView />}
        </div>
      </MarketplaceLayout>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return { userReducer, siteReducer };
};

export default connect(mapStateToProps)(FavoritesPage);
