import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../../functionality/actions/userActions';
import RedirectManager from './RedirectManager';
import Logo from '../../../images/believe.jpg';
import './css/ManagerMenu.css';

class ManagerMenu extends Component {
  render() {
    const {
      userReducer: { user_data },
      notificationReducer: { notification_chats, notification_orders },
      siteReducer: {
        site_texts: {
          text_nit_rut,
          text_orders,
          text_my_products,
          text_my_account,
          text_my_profile,
          text_setting,
          text_payment_methods,
          text_log_out,
          text_referrals,
          text_advertisement,
          text_my_brands,
          text_my_campaigns,
        },
      },
      isVisible,
      onChangeMenu,
      logout,
    } = this.props;

    if (!user_data) {
      return null;
    }

    const { user_type_id } = user_data;
    const docs = user_data ? user_data.docs : null;

    let image = user_data && user_data.image !== '' ? user_data.image : Logo;
    let business_name = '';

    if (docs) {
      business_name = docs.business_name ? docs.business_name : user_data.name;
    }

    let nit_number = docs && docs.nit_number ? docs.nit_number : '';

    return (
      <>
        <RedirectManager />
        {user_data && (
          <div className={`managerMenu_box managerMenu_box-${isVisible}`}>
            <button onClick={onChangeMenu} className="managerMenu_mobile-menu">
              <span className="icon-close" />
            </button>
            <div className="managerMenu_container">
              <div className="managerMenu_information">
                <div className="managerMenu_circle-mark">
                  <img src={image} alt="Logo" />
                </div>
                <h6>{business_name.toUpperCase()}</h6>
                {user_type_id !== 8 && (
                  <p className="color-gray managerMenu_doc">
                    {text_nit_rut ? text_nit_rut : 'NIT/RUT'}: {nit_number}
                  </p>
                )}
                {user_type_id === 8 && (
                  <p className="color-gray managerMenu_doc">
                    @{user_data.username}
                  </p>
                )}
              </div>
              <div className="managerMenu_menu">
                <NavLink
                  to="/manager/dashboard"
                  className="color-gray"
                  activeClassName="managerMenu_active"
                >
                  <span className="icon-dashboard" />
                  Dashboard
                </NavLink>
                <NavLink
                  to="/manager/inbox"
                  className="color-gray managerMenu_notification"
                  activeClassName="managerMenu_active"
                >
                  {notification_chats > 0 && (
                    <div className="circle-notification">
                      {notification_chats}
                    </div>
                  )}
                  <span className="icon-chat" />
                  Inbox
                </NavLink>
                <NavLink
                  to="/manager/orders/sales"
                  className="color-gray managerMenu_notification"
                  activeClassName="managerMenu_active"
                >
                  {notification_orders > 0 && (
                    <div className="circle-notification">
                      {notification_orders}
                    </div>
                  )}
                  <span className="icon-shopping-car" />
                  {text_orders ? text_orders : 'Órdenes'}
                </NavLink>
                <NavLink
                  to="/manager/campaign"
                  className="color-gray"
                  activeClassName="managerMenu_active"
                >
                  <span className="fa-solid fa-bullhorn" />

                  {text_my_campaigns ? text_my_campaigns : 'Mis Campañas'}
                </NavLink>
                {user_type_id !== 8 && (
                  <NavLink
                    to="/manager/products"
                    className="color-gray"
                    activeClassName="managerMenu_active"
                  >
                    <span className="icon-products" />
                    {text_my_products ? text_my_products : 'Mis Productos'}
                  </NavLink>
                )}
                {user_type_id !== 8 && (
                  <NavLink
                    to="/manager/brands"
                    className="color-gray"
                    activeClassName="managerMenu_active"
                  >
                    <span className="icon-products" />
                    {text_my_brands ? text_my_brands : 'Mis Marcas'}
                  </NavLink>
                )}
                <NavLink
                  to="/manager/historial"
                  className="color-gray"
                  activeClassName="managerMenu_active"
                >
                  <span className="icon-historial" />
                  {text_my_account ? text_my_account : 'Mi Cuenta BAX'}
                </NavLink>
                <NavLink
                  to="/manager/profile"
                  className="color-gray"
                  activeClassName="managerMenu_active"
                >
                  <span className="icon-profile" />
                  {text_my_profile ? text_my_profile : 'Mi Perfil'}
                </NavLink>
                <NavLink
                  to="/manager/settings"
                  className="color-gray"
                  activeClassName="managerMenu_active"
                >
                  <span className="icon-settings" />
                  {text_setting ? text_setting : 'Configuración'}
                </NavLink>
                {user_type_id !== 8 && (
                  <NavLink
                    to="/manager/pyment-methods"
                    className="color-gray"
                    activeClassName="managerMenu_active"
                  >
                    <span className="icon-card" />
                    {text_payment_methods
                      ? text_payment_methods
                      : 'Métodos de pago'}
                  </NavLink>
                )}
                {user_data.is_active && user_data.send_request ? (
                  <>
                    {user_type_id !== 8 && (
                      <NavLink
                        to="/manager/ads"
                        className="color-gray"
                        activeClassName="managerMenu_active"
                      >
                        <span className="icon-ad" />
                        {text_advertisement
                          ? text_advertisement
                          : 'Anuncios Publicitarios'}
                      </NavLink>
                    )}
                    <NavLink
                      to="/manager/referrals"
                      className="color-gray"
                      activeClassName="managerMenu_active"
                    >
                      <span className="icon-referrals" />
                      {text_referrals ? text_referrals : 'Referidos'}
                    </NavLink>
                  </>
                ) : null}
              </div>
              <button onClick={logout} className="btn managerMenu_btn-manager">
                {text_log_out ? text_log_out.toUpperCase() : 'CERRAR SESIÓN'}
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ userReducer, notificationReducer, siteReducer }) => {
  return {
    userReducer,
    notificationReducer,
    siteReducer,
  };
};

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(ManagerMenu);
