import React, { Fragment } from 'react';
import Pagination from '../../../../components/all/Pagination';
import ProductList from '../../../../components/user/manager/ProductList';
import { Link, Redirect } from 'react-router-dom';
import './css/ProductsView.css';
import Error from '../../../../components/all/Error';
import { connect } from 'react-redux';

function ProductsView(props) {
  const {
    userReducer: {
      user_data: { user_type_id },
    },
    siteReducer: {
      site_texts: {
        text_no_products,
        text_go_home,
        text_new_product,
        text_import_products,
        text_products,
        text_go_search,
      },
    },
  } = props;

  return (
    <Fragment>
      {user_type_id === 8 && <Redirect to={'/manager/dashboard'} />}
      <div className="title-manager">
        <div>
          <span className="icon-products" />
          {text_products ? text_products : 'Productos'}
        </div>
        <form
          className="productView_box-search input-group"
          onSubmit={props.toSearch}
        >
          <input
            type="text"
            className="form-control"
            placeholder={text_go_search ? text_go_search : 'Buscar'}
            value={props.search}
            onChange={(e) => props.changeSearch(e.target.value)}
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="submit">
              <span className="icon-loup" />
            </button>
          </div>
        </form>
        <div className="productsView_menu">
          <Link to="/manager/products/new">
            <i className="icon-add-car"></i>
            {text_new_product
              ? text_new_product.toUpperCase()
              : 'NUEVO PRODUCTO'}
          </Link>
          <Link to="/manager/products/import">
            <i className="icon-import-products"></i>
            {text_import_products
              ? text_import_products.toUpperCase()
              : 'IMPORTAR PRODUCTOS'}
          </Link>
        </div>
      </div>
      {props.viewProducts.length > 0 ? (
        <Fragment>
          <ProductList data={props.viewProducts} actions={true} />
          <Pagination
            data={props.products}
            itemsPerPage={props.itemsPerPage}
            currentPage={props.currentPage}
            changePage={props.changePage}
          />
        </Fragment>
      ) : (
        <Error
          type="no-data"
          title={
            text_no_products
              ? text_no_products
              : 'No hay productos disponibles.'
          }
          link="/home"
          buttonName={text_go_home ? text_go_home : 'Ir al Inicio'}
        />
      )}
    </Fragment>
  );
}

const mapStateToProps = ({ siteReducer, userReducer }) => {
  return {
    siteReducer,
    userReducer,
  };
};

export default connect(mapStateToProps, null)(ProductsView);
