import Api from '../Api';
import {
  INFLUENCER_RATING_FILTER,
  INFLUENCER_TAGS_FILTER,
  INFLUENCER_TARGET_FILTER,
  INFLUENCER_AGE_MIN_FILTER,
  INFLUENCER_AGE_MAX_FILTER,
  INFLUENCER_FOLLOWER_MIN_FILTER,
  INFLUENCER_FOLLOWER_MAX_FILTER,
  INFLUENCER_CITIES_FILTER,
  INFLUENCER_COUNTRIES_FILTER,
  INFLUENCER_LOADING,
  INFLUENCER_PAGE,
  INFLUENCER_LIST,
  INFLUENCER_TOTAL_PAGES,
  INFLUENCER_TOTAL_LIST,
  INFLUENCER_DETAIL_LOADING,
  INFLUENCER_DETAIL_ERROR,
  INFLUENCER_DETAIL,
} from '../types/influencerTypes';

export const changeTargetFilter = (payload) => (dispatch, getState) => {
  payload = parseInt(payload);

  const { influencer_target_filter } = getState().influencerReducer;
  let itemsFilter = influencer_target_filter;
  const isAdded = itemsFilter.find((item) => item === payload);

  if (!isAdded) {
    itemsFilter.push(payload);
  } else {
    itemsFilter = itemsFilter.filter((item) => item !== payload);
  }

  dispatch({
    type: INFLUENCER_TARGET_FILTER,
    payload: itemsFilter,
  });
};

export const changeTagFilter = (payload) => (dispatch, getState) => {
  const { influencer_tags_filter } = getState().influencerReducer;
  let itemsFilter = influencer_tags_filter;
  const isAdded = itemsFilter.find((item) => item.id === payload.id);

  if (!isAdded) {
    itemsFilter.push(payload);
  } else {
    itemsFilter = itemsFilter.filter((item) => item.id !== payload.id);
  }

  dispatch({
    type: INFLUENCER_TAGS_FILTER,
    payload: itemsFilter,
  });
};

export const changeRatingFilter = (payload) => (dispatch) => {
  payload = payload ? parseInt(payload) : 0;

  dispatch({
    type: INFLUENCER_RATING_FILTER,
    payload,
  });
};

export const changeAgeMinFilter = (payload) => (dispatch) => {
  payload = payload ? parseInt(payload) : 0;

  dispatch({
    type: INFLUENCER_AGE_MIN_FILTER,
    payload,
  });
};

export const changeAgeMaxFilter = (payload) => (dispatch) => {
  payload = payload ? parseInt(payload) : 0;

  dispatch({
    type: INFLUENCER_AGE_MAX_FILTER,
    payload,
  });
};

export const changeFollowerMinFilter = (payload) => (dispatch) => {
  payload = payload ? parseInt(payload) : 0;

  dispatch({
    type: INFLUENCER_FOLLOWER_MIN_FILTER,
    payload,
  });
};

export const changeFollowerMaxFilter = (payload) => (dispatch) => {
  payload = payload ? parseInt(payload) : 0;

  dispatch({
    type: INFLUENCER_FOLLOWER_MAX_FILTER,
    payload,
  });
};

export const changeCitiesFilter = (payload) => (dispatch) => {
  dispatch({
    type: INFLUENCER_CITIES_FILTER,
    payload,
  });
};

export const addCityFilter = (city) => (dispatch, getState) => {
  const { influencer_cities_filter } = getState().influencerReducer;
  const data = influencer_cities_filter;
  const isAdded = data.find((item) => item.id === city.id);

  if (!isAdded) {
    data.push(city);
  }

  dispatch(changeCitiesFilter(data));
};

export const removeCityFilter = (city) => (dispatch, getState) => {
  const { influencer_cities_filter } = getState().influencerReducer;
  const data = influencer_cities_filter.filter((item) => item.id !== city.id);

  dispatch(changeCitiesFilter(data));
};

export const addCountryFilter = (country) => (dispatch, getState) => {
  const { influencer_countries_filter } = getState().influencerReducer;
  const data = influencer_countries_filter;
  const isAdded = data.find((item) => item.id === country.id);

  if (!isAdded) {
    data.push(country);
  }

  dispatch(changeCountriesFilter(data));
};

export const removeCountryFilter = (country) => (dispatch, getState) => {
  const { influencer_countries_filter } = getState().influencerReducer;
  const data = influencer_countries_filter.filter(
    (item) => item.id !== country.id
  );

  dispatch(changeCountriesFilter(data));
};

export const changeCountriesFilter = (payload) => (dispatch) => {
  dispatch({
    type: INFLUENCER_COUNTRIES_FILTER,
    payload,
  });
};

export const changeInfluencerLoading = (payload) => (dispatch) => {
  dispatch({
    type: INFLUENCER_LOADING,
    payload,
  });
};

export const changeInfluencerPage = (payload) => (dispatch) => {
  dispatch({
    type: INFLUENCER_PAGE,
    payload,
  });
};

export const getInfluencers = (search) => async (dispatch) => {
  dispatch(changeInfluencerLoading(true));

  try {
    const response = await Api.asyncCallMethod(
      `v1/influencers${search}`,
      'GET',
      null
    );

    if (response.status === 200) {
      const {
        data,
        meta: { last_page, total, current_page },
      } = response.response;

      dispatch({
        type: INFLUENCER_LIST,
        payload: data,
      });
      dispatch({
        type: INFLUENCER_TOTAL_PAGES,
        payload: last_page,
      });
      dispatch({
        type: INFLUENCER_TOTAL_LIST,
        payload: total,
      });
      dispatch(changeInfluencerPage(current_page));
    }
    dispatch(changeInfluencerLoading(false));
  } catch (error) {
    console.error('Error getInfluencers: ', error);
    dispatch(changeInfluencerLoading(false));
  }
};

export const changeInfluencerLoadingDetail = (payload) => (dispatch) => {
  dispatch({
    type: INFLUENCER_DETAIL_LOADING,
    payload,
  });
};

export const changeInfluencerErrorDetail = (payload) => (dispatch) => {
  dispatch({
    type: INFLUENCER_DETAIL_ERROR,
    payload,
  });
};

export const getInfluencerDetail = (username) => async (dispatch) => {
  dispatch(changeInfluencerErrorDetail(false));
  dispatch(changeInfluencerLoadingDetail(true));

  try {
    const response = await Api.asyncCallMethod(
      `v1/influencers/${username}`,
      'GET'
    );
    const {
      response: { data },
      status,
    } = response;
    if (status === 200) {
      dispatch({
        type: INFLUENCER_DETAIL,
        payload: data,
      });
    } else {
      dispatch(changeInfluencerErrorDetail(true));
    }
    dispatch(changeInfluencerLoadingDetail(false));
  } catch (error) {
    console.error('Error getInfluencerDetail: ', error);
    dispatch(changeInfluencerErrorDetail(true));
    dispatch(changeInfluencerLoadingDetail(false));
  }
};
