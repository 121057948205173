import React, { Fragment } from 'react';
import './css/Inbox.css';
import InboxChats from '../../../../components/user/manager/InboxChats';
import ChatMessage from '../../../../components/user/manager/ChatMessage';
import { connect } from 'react-redux';
import PorposeChat from '../../../../components/user/manager/PorposeChat/PorposeChat';

function InboxNewView(props) {
  const {
    userReducer: {
      user_data: { user_type_id },
    },
    data,
    actions,
    handleFilter,
    dataPorposal,
    siteReducer: {
      site_texts: { text_proposals },
    },
  } = props;
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-4">
          <InboxChats
            data={data}
            actions={actions}
            handleFilter={handleFilter}
          />
          <PorposeChat
            chats={dataPorposal}
            path={'/manager/inbox/porposal/'}
            inboxChat={true}
            title={text_proposals || 'Propuestas'}
          />
        </div>
        <div className="col-md-8">
          <ChatMessage />
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(InboxNewView);
