import React from "react";
import "./css/SelectOption.css";

const { REACT_APP_LANGUAGE: APP_LANGUAGE } = process.env;

function SelectOption(props) {
  const { label, options, value, onChange, showPrice } = props;
  const numberFormat = new Intl.NumberFormat("es-CO");

  return (
    <div className="selectOption_content">
      <div className="selectOption_label">{label}</div>
      <div className="selectOption_list">
        {options.map((item) => {
          const isActive = value !== "" && item.id === value.id ? "active" : "";
          return (
            <button
              key={item.id}
              className={`selectOption_item ${isActive}`}
              onClick={() => onChange(item)}
            >
              {APP_LANGUAGE === "es" ? item.name : item.english_name}
              {showPrice && (
                <div className="selectOption_price">
                  ${numberFormat.format(item.price)}
                </div>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
}

SelectOption.defaultProps = {
  showPrice: false,
};

export default SelectOption;
