import React from 'react';
import './css/DashboardSquare.css';

function viewRating(value) {
  let rating = [];
  for (let i = 0; i < parseInt(value); i++) {
    rating.push(<span className="fa fa-star" key={i} />);
  }
  return rating;
}

function DashboardSquare(props) {
  const numberFormat = new Intl.NumberFormat('es-CO');
  const number =
    props.type !== 'rating'
      ? parseFloat(props.number).toFixed(3)
      : parseFloat(props.number).toFixed(1);

  return (
    <div className="dashboardSquare_container">
      <div className="background-lightgray color-darkgray dashboardSquare_box-icon">
        <span className={props.icon} />
      </div>
      <div className="dashboardSquare_box-description">
        <div className="color-gray dashboardSquare_title">{props.title}</div>
        <div className="dashboardSquare_box-number">
          <div className="dashboardSquare_number">
            {props.type !== 'rating' ? '$ ' : ''}
            {props.type === 'rating' ? number : numberFormat.format(number)}
          </div>
          {props.type === 'rating' && (
            <div className="dashboardSquare_box-rating">
              {viewRating(props.number)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashboardSquare;
