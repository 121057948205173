import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { getInfluencers } from '../../../../../functionality/actions/influencerActions';
import CollapseComponent from '../../../../../components/all/Collapse/CollapseComponent';
import HomeMenu from '../../../../../components/user/marketplace/HomeMenu';
import HomeSlider from '../../../../../components/user/marketplace/HomeSlider';
import InfluencerFilter from '../../../../../components/user/marketplace/influencer/InfluencerFilter/InfluencerFilter';
import InfluencerList from '../../../../../components/user/marketplace/influencer/InfluencerList';
import './InfluencersView.css';
import Spinner from '../../../../../components/all/Spinner';

const InfluencersView = (props) => {
  const {
    influencerReducer: {
      influencer_list,
      influencer_total_list,
      influencer_page,
      influencer_total_pages,
      influencer_loading,
      text_filters,
    },
    viewActivity,
    getInfluencers,
    search,
  } = props;
  const [isGridView, setIsGridView] = useState(true); // estado para manejar vista de cuadrícula o tabla
  const memoInfluencerFilter = useMemo(() => <InfluencerFilter />, []);

  useEffect(() => {
    getInfluencers(search);
  }, [search]);

  const handleViewChange = () => {
    setIsGridView(!isGridView);
  };

  return (
    <div>
      <HomeSlider />
      <HomeMenu openCloseSearch={props.openCloseSearch} />
      <div className="container">
        <div id="titleCategory">{viewActivity}</div>
        <div className=" row influencersView">
          <div className="col-sm-12 col-lg-3 ">
            <div className="influencerFilter">
              <CollapseComponent
                superTitle={text_filters || 'Filtros'}
                open={true}
              >
                {memoInfluencerFilter}
              </CollapseComponent>
            </div>
          </div>
          <div className="col-sm-12 col-lg-9 influencerList__container">
            <div className="changeView ">
              <div className="change__result">
                {influencer_total_list} Influencers
              </div>
              <div className="view-buttons">
                <button
                  onClick={handleViewChange}
                  className={`${isGridView ? 'active' : ''}`}
                >
                  <i className="fa-solid fa-grip"></i>
                </button>
                <button
                  onClick={handleViewChange}
                  className={`${!isGridView ? 'active' : ''}`}
                >
                  <i className="fa-solid fa-list"></i>
                </button>
              </div>
            </div>
            {influencer_loading ? (
              <div className="spinner-loading">
                <Spinner type="purple" />
              </div>
            ) : (
              <InfluencerList
                showAds={true}
                influencers={influencer_list}
                type={isGridView ? 'list' : 'table'}
                currentPage={influencer_page}
                totalPages={influencer_total_pages}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ influencerReducer }) => {
  return {
    influencerReducer,
  };
};

const mapDispatchToProps = {
  getInfluencers,
};

export default connect(mapStateToProps, mapDispatchToProps)(InfluencersView);
