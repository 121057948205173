import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './CampaignPostDetail.css';
import Swal from 'sweetalert2';
import {
  setPostMessage,
  changeStatusPost,
  postClear,
  getPostsByCampaign,
  setPostLink,
  editPost,
  getByCampaignAndUserAll,
} from '../../../../../../../functionality/actions/postActions';
import Slider from 'react-slick';

const CampaignPostDetail = (props) => {
  const {
    postReducer: {
      current_post: { attachments },
      current_post,
    },
    userReducer: { user_data },
    handleCloseModal,
    setPostMessage,
    changeStatusPost,
    postClear,

    getPostsByCampaign,
    setPostLink,
    editPost,
    getByCampaignAndUserAll,
    match: {
      params: { id: campaign_consecutive },
    },
    siteReducer: {
      site_texts: {
        text_accept,
        text_see_post,
        text_request_correction,
        text_reject,
        text_publish,
        text_warning_enter_link,
        text_warning_message_here,
        text_save,
        text_post_networks,
        text_post_add_networks_link,
      },
    },
  } = props;
  const [socialNetworks, setSocialNetworks] = useState([]);

  useEffect(() => {
    setSocialNetworks(current_post.social_networks);
  }, []);

  const messageSwal = Swal.mixin({
    customClass: {
      confirmButton: 'button__confirm',
      cancelButton: 'button__cancel',
    },
    buttonsStyling: false,
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let imageUrls;
  let videoUrl;

  useEffect(() => {
    return () => {
      postClear();
    };
  }, []);

  if (attachments[0].mime_type === 'image/jpeg') {
    imageUrls = attachments.map((attachment) => attachment.file);
  }
  if (attachments[0].mime_type === 'video/mp4') {
    videoUrl = attachments.map((attachment) => attachment.file);
  }

  const handleActionButton = async (statusId) => {
    const { value: text } = await messageSwal.fire({
      type: 'question',
      input: 'textarea',
      inputLabel: 'Message',
      inputPlaceholder: `${
        text_warning_message_here || 'Escribe tu mensaje aqui'
      }...`,
      inputAttributes: {
        'aria-label': `${
          text_warning_message_here || 'Escribe tu mensaje aqui'
        }...`,
      },
      showCancelButton: true,
      reverseButtons: true,
      inputValidator: (value) => {
        if (!value) {
          return text_warning_message_here || 'Debes escribir un mensaje';
        }
      },
    });

    if (text) {
      await setPostMessage(text);
      await changeStatusPost(current_post.consecutive, statusId);
      getPostsByCampaign(campaign_consecutive);
    }
    handleCloseModal();
  };

  const handleAddLink = async (statusId) => {
    await editPost(current_post.consecutive);
    await changeStatusPost(current_post.consecutive, statusId, socialNetworks);
    getPostsByCampaign(campaign_consecutive);
    handleCloseModal();
  };

  const onChangeLinkSocialNetwork = (value, index) => {
    let newNetworks = [...socialNetworks];
    newNetworks[index].link = value;

    setSocialNetworks(newNetworks);
  };

  const renderPublishButton = () => {
    const statusPosts = socialNetworks.find((item) => !item.link);
    const textSave = text_save || 'Guardar';
    const textPublish = text_publish || 'Publicar';

    return (
      <button
        className="button__action"
        value={statusPosts ? 4 : 5}
        onClick={async (event) => {
          await handleAddLink(event.target.value);
          getByCampaignAndUserAll(campaign_consecutive);
        }}
      >
        {statusPosts ? textSave : textPublish}
      </button>
    );
  };

  return (
    <div className="modal__view__post">
      <div className="post_detail">
        <button className="close__btn" onClick={handleCloseModal}>
          <i className="fa-solid fa-circle-xmark" />
        </button>
        <div className="post__header">
          <div className="influencer__profile">
            <img src={current_post.influencer.image} alt="influencer_pic"></img>
            <span>{current_post.influencer.username}</span>
          </div>
          <div className="post__networks">
            {current_post.social_networks &&
              current_post.social_networks.map((network) => (
                <div
                  className={`network__circle circle-${network.slug}`}
                  key={network.id}
                >
                  <i className={`${network.icon}`}></i>
                </div>
              ))}
          </div>
        </div>
        <div className="post__detail__content">
          {imageUrls && (
            <Slider className="post__photos__slider" {...settings}>
              {imageUrls.map((image, index) => (
                <img src={image} alt="post" key={index} />
              ))}
            </Slider>
          )}
          {videoUrl && (
            <div className="post__video">
              {videoUrl.map((video, index) => (
                <video src={video} controls key={index} />
              ))}
            </div>
          )}
          <div className="caption">{current_post.description}</div>
          {user_data.user_type_id !== 8 &&
            current_post.post_status_id === 2 && (
              <div className="buttons__actions">
                <button
                  className="button__action"
                  value={3}
                  onClick={async (event) => {
                    await handleActionButton(event.target.value);
                    getPostsByCampaign(campaign_consecutive);
                  }}
                >
                  {text_request_correction || 'Solicitar corrección'}
                </button>
                <button
                  className="button__action"
                  value={6}
                  onClick={async (event) => {
                    await handleActionButton(event.target.value);
                    getPostsByCampaign(campaign_consecutive);
                  }}
                >
                  {text_reject || 'Rechazar'}
                </button>
                <button
                  className="button__action"
                  value={4}
                  onClick={async (event) => {
                    await handleActionButton(event.target.value);
                    getPostsByCampaign(campaign_consecutive);
                  }}
                >
                  {text_accept || 'Aceptar'}
                </button>
              </div>
            )}
          {current_post.post_status_id === 5 && (
            <>
              <h4 className="post__subtile">
                {text_post_networks || 'Links del post en las redes sociales'}
              </h4>
              {socialNetworks.map((item, index) => (
                <div className="input__network" key={index}>
                  <div className="input__icon">
                    <i className={item.icon} />
                  </div>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <div className="input">{item.link}</div>
                  </a>
                </div>
              ))}
            </>
          )}
          {user_data.user_type_id === 8 &&
            current_post.post_status_id === 4 && (
              <>
                <h4 className="post__subtile">
                  {text_post_add_networks_link ||
                    'Escribe los links del post en las redes sociales'}
                </h4>
                {socialNetworks.map((item, index) => (
                  <div className="input__network" key={index}>
                    <div className="input__icon">
                      <i className={item.icon} />
                    </div>
                    <input
                      value={item.link}
                      onChange={(input) =>
                        onChangeLinkSocialNetwork(input.target.value, index)
                      }
                    />
                  </div>
                ))}
                <div className="buttons__actions">{renderPublishButton()}</div>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  postReducer,
  porposalReducer,
  campaignReducer,
  userReducer,
  siteReducer,
}) => {
  return {
    postReducer,
    porposalReducer,
    campaignReducer,
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  setPostMessage,
  changeStatusPost,
  postClear,
  getPostsByCampaign,
  setPostLink,
  editPost,
  getByCampaignAndUserAll,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignPostDetail)
);
