import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ManagerLayout from '../layouts/ManagerLayout';
import InboxPorposalView from '../views/InboxPorposalView/InboxPorposalView';
import { connect } from 'react-redux';
import {
  chatList,
  chatListView,
  chatNew,
  chatClearMessages,
  chatProposalList,
} from '../../../../functionality/actions/chatActions';
import TopSpinner from '../../../../components/all/TopSpinner';

const InboxPorposalPage = (props) => {
  const {
    chatReducer: {
      chat_loading,
      chat_list_view,
      chat_list,
      chat_new,
      chat_list_proposal,
    },
    match,
    chatList,
    chatListView,
    chatNew,
    chatProposalList,
  } = props;

  const [codInbox, setCodInbox] = useState('');

  useEffect(() => {
    if (!chat_list_view.length) {
      chatList();
    }
    if (!chat_list_proposal.length) {
      chatProposalList();
    }

    return () => {};
  }, []);

  useEffect(() => {
    handleViewMessage(match.params.id);
  }, [match.params.id]);

  const handleViewMessage = (codInbox) => {
    chatNew(false);
    setCodInbox(codInbox);
    setTimeout(() => {
      chatNew(true);
    }, 50);
  };

  const handleFilter = (not_read) => {
    if (not_read === 1) {
      let filter = chat_list.filter((item) => item.viewed === not_read);
      chatListView(filter);
    } else {
      chatListView(chat_list);
    }
  };

  return (
    <ManagerLayout>
      <div className="title-manager">
        <div>
          <span className="icon-chat" />
          Inbox
        </div>
        <Link to="/manager/inbox/new" className="btn inbox_new-message">
          <i className="icon-send-message"></i>
          {'NUEVO MENSAJE'}
        </Link>
      </div>
      <div>
        {chat_loading ? (
          <TopSpinner />
        ) : (
          <InboxPorposalView
            codInbox={codInbox}
            viewBoxMessage={chat_new}
            handleFilter={handleFilter}
          />
        )}
      </div>
    </ManagerLayout>
  );
};

const mapStateToProps = ({ chatReducer, siteReducer }) => {
  return {
    chatReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  chatList,
  chatListView,
  chatNew,
  chatClearMessages,
  chatProposalList,
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxPorposalPage);
