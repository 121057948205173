import React from 'react';
import Gallery from '../Gallery/Gallery';
import Image from '../../../images/believe.jpg';

import './BreifCampaign.css';
import { connect } from 'react-redux';
import Spinner from '../Spinner';

const BreifCampaign = (props) => {
  const {
    siteReducer: {
      site_language,
      site_texts: {
        text_campaign_brief,
        text_brand,
        text_phrase,
        text_suggested_images,
        text_campaign_name,
        text_campaign_dates,
        text_networks,
        text_location,
        text_objectives,
        text_target_gender,
        text_deliverables,
        text_product_description,
        text_target_description,
      },
    },
    targetReducer: { targets, targets_loading },
    data,
  } = props;

  const Hashtags = ({ hashtags }) => {
    return (
      <div className="hashtag__container ">
        {hashtags.map((hashtag, index) => (
          <span className="hashtag" key={index}>
            {`#${hashtag}`}
          </span>
        ))}
      </div>
    );
  };

  let formattedText;

  if (data?.length !== 0) {
    formattedText = data?.deliverables.replace(/\n/g, '<br />');
  }

  return (
    <>
      {data?.length === 0 ? (
        <div className="spinner-loading">
          <Spinner type="purple" />
        </div>
      ) : (
        <div className="breif">
          <h2 className="breif__title">
            {text_campaign_brief ? text_campaign_brief : 'Brief Campaña'}
          </h2>
          <div className="logo__container">
            <img
              className="brand__logo"
              src={data.brand.image ? data.brand.image : Image}
              alt="brand__logo"
            />
          </div>
          <div className="brand__data">
            <div className="data__field">
              <h4 className="porposal__subtitle">
                {text_brand ? text_brand : 'Marca'}
              </h4>
              <span className="data__text">
                {data.brand.name ? data.brand.name : 'nombre de la marca'}
              </span>
            </div>
            <div className="data__field">
              <h4 className="porposal__subtitle">web</h4>
              <span className="data__text">
                {data.link ? data.link : 'web '}
              </span>
            </div>
            {data.brand_instagram && (
              <div className="data__field">
                <h4 className="porposal__subtitle">Instagram</h4>
                <span className="data__text">
                  {data.brand_instagram
                    ? data.brand_instagram
                    : 'Instagram de la marca'}
                </span>
              </div>
            )}
            {data.phrase && (
              <div className="data__field">
                <h4 className="porposal__subtitle">
                  {text_phrase ? text_phrase : 'Frase'}
                </h4>
                <span className="data__text">
                  {data.phrase ? data.phrase : 'categoria de la marca'}
                </span>
              </div>
            )}
          </div>
          {data.images.length !== 0 && (
            <div className="campaign__inspiration">
              <h4 className="porposal__subtitle">
                {text_suggested_images
                  ? text_suggested_images
                  : 'Imágenes sugeridas - inspiration'}
              </h4>
              <Gallery images={data.images} />
            </div>
          )}
          <div className="campaign__details">
            <div className="detail__purpose two__details">
              <div>
                <h4 className="porposal__subtitle">
                  {text_campaign_name
                    ? text_campaign_name
                    : 'Nombre de campaña'}
                </h4>
                <span className="porposal__text">{data.name}</span>
              </div>
              <div>
                <h4 className="porposal__subtitle">
                  {text_campaign_dates
                    ? text_campaign_dates
                    : 'Fechas de campaña'}
                </h4>
                <span className="porposal__text">{`${data.date_start} / ${data.date_end}`}</span>
              </div>
              <div className="data__field">
                <h4 className="porposal__subtitle">
                  {text_networks ? text_networks : 'Redes'}
                </h4>
                <div className="networks__porposal">
                  {data.social_networks &&
                    data.social_networks.map((network) => (
                      <div
                        className={`network__circle circle-${network.slug}`}
                        key={network.id}
                      >
                        <i className={`${network.icon}`}></i>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="detail__purpose">
              <div>
                <h4 className="porposal__subtitle">
                  {text_location ? text_location : 'Locación'}
                </h4>
                <div className="cities__breif__container">
                  <>
                    {data.cities &&
                      data.cities.map((city) => (
                        <div
                          className="location__pills"
                          key={city.id}
                        >{`${city.name} (${city.department_name})`}</div>
                      ))}
                    {data.countries &&
                      data.countries.map((country) => (
                        <div className="location__pills" key={country.id}>{`${
                          site_language === 'es'
                            ? country.name
                            : country.english_name
                        } (${country.iso_alpha})`}</div>
                      ))}
                  </>
                </div>
              </div>
            </div>
            <div className="detail__purpose">
              <h4 className="porposal__subtitle">
                {text_product_description
                  ? text_product_description
                  : 'Descripción'}
              </h4>
              <p>{data.description}</p>
            </div>
            <div className="detail__purpose">
              <h4 className="porposal__subtitle">
                {text_objectives ? text_objectives : 'Objetivos'}
              </h4>
              <div className="objectives__container">
                {data.objectives.map((objective) => (
                  <div key={objective.id} className="porposal__text">
                    {site_language === 'es'
                      ? objective.name
                      : objective.english_name}{' '}
                  </div>
                ))}
              </div>
            </div>
            <div className="detail__purpose">
              <h4 className="porposal__subtitle">
                {text_target_gender ? text_target_gender : 'Genero objetivo'}
              </h4>
              <div className="gender__container">
                {targets_loading ? (
                  <div className="spinner-loading">
                    <Spinner type="purple" />
                  </div>
                ) : (
                  <>
                    {targets.map((target) => {
                      if (data.target_gender.includes(target.id)) {
                        return (
                          <div key={target.id} className="gender__text">
                            {site_language === 'es'
                              ? target.name
                              : target.english_name}{' '}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                )}
              </div>
            </div>
            <div className="detail__purpose">
              <h4 className="porposal__subtitle">
                {text_target_description
                  ? text_target_description
                  : 'Descripción del target'}
              </h4>
              <p>{data.target_description}</p>
            </div>
            <div className="detail__purpose">
              <h4 className="porposal__subtitle">
                {text_deliverables ? text_deliverables : 'Entregables'}
              </h4>
              <p
                className="porposal__text"
                dangerouslySetInnerHTML={{ __html: formattedText }}
              ></p>
            </div>
            <div className="detail__purpose">
              <h4 className="porposal__subtitle">Tags</h4>
              <div className="tags__container">
                {data.tags.map((tag) => (
                  <div key={tag.id} className="tag__text">
                    {site_language === 'es' ? tag.name : tag.english_name}{' '}
                  </div>
                ))}
              </div>
            </div>
            <div className="keywords__campaign">
              <h4 className="porposal__subtitle">Hastags</h4>
              <Hashtags hashtags={data.hashtags} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ siteReducer, targetReducer }) => {
  return {
    siteReducer,
    targetReducer,
  };
};

export default connect(mapStateToProps, null)(BreifCampaign);
