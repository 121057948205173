import {
  CAMPAIGN_BRAND_ID,
  CAMPAIGN_USER_ID,
  CAMPAIGN_STATUS_ID,
  CAMPAIGN_CONSECUTIVE,
  CAMPAIGN_NAME,
  CAMPAING_LINK,
  CAMPAIGN_DATE_START,
  CAMPAIGN_DATE_END,
  CAMPAIGN_DESCRIPTION,
  CAMPAIGN_PHRASE,
  CAMPAIGN_DELIVERABLES,
  CAMPAIGN_HASHTAGS,
  CAMPAIGN_HASHTAGS_TEXT,
  CAMPAIGN_TARGET_GENDER,
  CAMPAIGN_TARGET_DESCRIPTION,
  CAMPAIGN_TARGET_MIN_AGE,
  CAMPAIGN_TARGET_MAX_AGE,
  CAMPAIGN_INFLUENCER_RATING,
  CAMPAIGN_INFLUENCER_GENDER,
  CAMPAIGN_MIN_AGE_RANGE,
  CAMPAIGN_MAX_AGE_RANGE,
  CAMPAIGN_MIN_FOLLOWERS_RANGE,
  CAMPAIGN_MAX_FOLLOWERS_RANGE,
  CAMPAIGN_MIN_LIKES_RANGE,
  CAMPAIGN_MAX_LIKES_RANGE,
  CAMPAIGN_MIN_VIEWS_RANGE,
  CAMPAIGN_MAX_VIEWS_RANGE,
  CAMPAIGN_IMAGES,
  CAMPAIGN_CITIES,
  CAMPAIGN_COUNTRY,
  CAMPAIGN_SOCIAL_NETWORKS,
  CAMPAIGN_TAGS,
  CAMPAIGN_OBJECTIVES,
  CAMPAIGNS_LIST_CREATED,
  CAMPAIGN_LIST_LOADING,
  CAMPAIGN_TOTAL_LIST,
  CAMPAIGN_PAGE,
  CAMPAIGN_TOTAL_PAGES,
  CAMPAIGN_CLEAR,
  CAMPAIGN_CURRENT_DETAIL,
  CAMPAIGN_CURRENT_LOADING,
  SET_STATUS_EDIT,
  INFLUENCER_IN_CAMPAIGN,
  INFLUENCER_IN_CAMPAIGN_LOADING,
  INFLUENCER_CAMPAIGN_TOTAL_LIST,
  INFLUENCER_CAMPAIGN_PAGE,
  INFLUENCER_CAMPAIGN_TOTAL_PAGES,
  CAMPAIGN_STATISTICS_INFLUENCERS,
  CAMPAIGN_STATISTICS_PROPOSALS,
  CAMPAIGN_STATISTICS_POSTS,
  CAMPAIGN_STATISTICS_LAST_POSTS,
} from '../types/campaignTypes';

const INITIAL_STATE = {
  campaign_id: undefined,
  campaign_user_id: undefined,
  campaign_brand_id: undefined,
  campaign_status_id: undefined,
  campaign_consecutive: '',
  campaign_name: '',
  campaign_link: '',
  campaign_date_start: '',
  campaign_date_end: '',
  campaign_description: '',
  campaign_phrase: '',
  campaign_deliverables: '',
  campaign_hashtags: [],
  campaign_hashtags_text: '',
  campaign_target_gender: [],
  campaign_target_description: '',
  campaign_target_min_age: undefined,
  campaign_target_max_age: undefined,
  campaign_influencer_rating: undefined,
  campaign_influencer_gender: [],
  campaign_min_age_range: undefined,
  campaign_max_age_range: undefined,
  campaign_min_followers_range: undefined,
  campaign_max_followers_range: undefined,
  campaign_min_likes_range: undefined,
  campaign_max_likes_range: undefined,
  campaign_min_views_range: undefined,
  campaign_max_views_range: undefined,
  campaign_images: [],
  campaign_cities: [],
  campaign_country: [],
  campaign_social_networks: [],
  campaign_tags: [],
  campaign_objectives: [],
  campaign_list_created: [],
  campaign_list_loading: false,
  campaign_total_list: 0,
  campaign_page: 1,
  campaign_total_pages: 1,
  campaign_current_detail: [],
  campaign_current_loading: false,
  influencers_in_campaign: [],
  influencers_in_campaign_loading: false,
  influencer_campaign_total_list: 0,
  influencer_campaign_page: 1,
  influencer_campaign_total_pages: 1,
  campaign_statistics_influencers: [],
  campaign_statistics_proposals: [],
  campaign_statistics_posts: [],
  campaign_statistics_last_posts: [],
};
const campaignReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STATUS_EDIT:
      return {
        ...state,
        campaign_id: action.payload.id,
        campaign_user_id: action.payload.user_id,
        campaign_brand_id: action.payload.brand_id,
        campaign_status_id: action.payload.campaign_status_id,
        campaign_consecutive: action.payload.consecutive,
        campaign_name: action.payload.name,
        campaign_link: action.payload.link,
        campaign_date_start: action.payload.date_start,
        campaign_date_end: action.payload.date_end,
        campaign_description: action.payload.description,
        campaign_phrase: action.payload.phrase,
        campaign_deliverables: action.payload.deliverables,
        campaign_hashtags: action.payload.hashtags,
        campaign_hashtags_text: '',
        campaign_target_gender: action.payload.target_gender,
        campaign_target_description: action.payload.target_description,
        campaign_target_min_age: action.payload.target_min_age,
        campaign_target_max_age: action.payload.target_max_age,
        campaign_influencer_rating: action.payload.influencer_rating,
        campaign_influencer_gender: action.payload.influencer_gender,
        campaign_min_age_range: action.payload.min_age_range,
        campaign_max_age_range: action.payload.max_age_range,
        campaign_min_followers_range: action.payload.min_followers_range,
        campaign_max_followers_range: action.payload.max_followers_range,
        campaign_min_likes_range: action.payload.min_likes_range,
        campaign_max_likes_range: action.payload.max_likes_range,
        campaign_min_views_range: action.payload.min_views_range,
        campaign_max_views_range: action.payload.max_views_range,
        campaign_images: action.payload.images,
        campaign_cities: action.payload.cities,
        campaign_country: action.payload.countries,
        campaign_social_networks: action.payload.social_networks,
        campaign_tags: action.payload.tags,
        campaign_objectives: action.payload.objectives,
      };
    case CAMPAIGN_CLEAR:
      return {
        ...state,
        campaign_id: undefined,
        campaign_user_id: undefined,
        campaign_brand_id: undefined,
        campaign_status_id: undefined,
        campaign_consecutive: '',
        campaign_name: '',
        campaign_link: '',
        campaign_date_start: '',
        campaign_date_end: '',
        campaign_description: '',
        campaign_phrase: '',
        campaign_deliverables: '',
        campaign_hashtags: [],
        campaign_hashtags_text: '',
        campaign_target_gender: [],
        campaign_target_description: undefined,
        campaign_target_min_age: undefined,
        campaign_target_max_age: undefined,
        campaign_influencer_rating: undefined,
        campaign_influencer_gender: [],
        campaign_min_age_range: undefined,
        campaign_max_age_range: undefined,
        campaign_min_followers_range: undefined,
        campaign_max_followers_range: undefined,
        campaign_min_likes_range: undefined,
        campaign_max_likes_range: undefined,
        campaign_min_views_range: undefined,
        campaign_max_views_range: undefined,
        campaign_images: [],
        campaign_cities: [],
        campaign_country: [],
        campaign_social_networks: [],
        campaign_tags: [],
        campaign_objectives: [],
        campaign_current_detail: [],
        influencers_in_campaign: [],
        campaign_statistics_influencers: [],
        campaign_statistics_proposals: [],
        campaign_statistics_posts: [],
        campaign_statistics_last_posts: [],
      };
    case CAMPAIGN_USER_ID:
      return {
        ...state,
        campaign_user_id: action.payload,
      };
    case CAMPAIGN_BRAND_ID:
      return {
        ...state,
        campaign_brand_id: action.payload,
      };
    case CAMPAIGN_STATUS_ID:
      return {
        ...state,
        campaign_status_id: action.payload,
      };
    case CAMPAIGN_CONSECUTIVE:
      return {
        ...state,
        campaign_consecutive: action.payload,
      };
    case CAMPAIGN_NAME:
      return {
        ...state,
        campaign_name: action.payload,
      };
    case CAMPAING_LINK:
      return {
        ...state,
        campaign_link: action.payload,
      };
    case CAMPAIGN_DATE_START:
      return {
        ...state,
        campaign_date_start: action.payload,
      };
    case CAMPAIGN_DATE_END:
      return {
        ...state,
        campaign_date_end: action.payload,
      };
    case CAMPAIGN_DESCRIPTION:
      return {
        ...state,
        campaign_description: action.payload,
      };
    case CAMPAIGN_PHRASE:
      return {
        ...state,
        campaign_phrase: action.payload,
      };
    case CAMPAIGN_DELIVERABLES:
      return {
        ...state,
        campaign_deliverables: action.payload,
      };
    case CAMPAIGN_HASHTAGS:
      return {
        ...state,
        campaign_hashtags: action.payload,
      };
    case CAMPAIGN_HASHTAGS_TEXT:
      return {
        ...state,
        campaign_hashtags_text: action.payload,
      };
    case CAMPAIGN_TARGET_GENDER:
      return {
        ...state,
        campaign_target_gender: action.payload,
      };
    case CAMPAIGN_TARGET_DESCRIPTION:
      return {
        ...state,
        campaign_target_description: action.payload,
      };
    case CAMPAIGN_TARGET_MIN_AGE:
      return {
        ...state,
        campaign_target_min_age: action.payload,
      };
    case CAMPAIGN_TARGET_MAX_AGE:
      return {
        ...state,
        campaign_target_max_age: action.payload,
      };
    case CAMPAIGN_INFLUENCER_RATING:
      return {
        ...state,
        campaign_influencer_rating: action.payload,
      };
    case CAMPAIGN_INFLUENCER_GENDER:
      return {
        ...state,
        campaign_influencer_gender: action.payload,
      };
    case CAMPAIGN_MIN_AGE_RANGE:
      return {
        ...state,
        campaign_min_age_range: action.payload,
      };
    case CAMPAIGN_MAX_AGE_RANGE:
      return {
        ...state,
        campaign_max_age_range: action.payload,
      };
    case CAMPAIGN_MIN_FOLLOWERS_RANGE:
      return {
        ...state,
        campaign_min_followers_range: action.payload,
      };
    case CAMPAIGN_MAX_FOLLOWERS_RANGE:
      return {
        ...state,
        campaign_max_followers_range: action.payload,
      };
    case CAMPAIGN_MIN_LIKES_RANGE:
      return {
        ...state,
        campaign_min_likes_range: action.payload,
      };
    case CAMPAIGN_MAX_LIKES_RANGE:
      return {
        ...state,
        campaign_max_likes_range: action.payload,
      };
    case CAMPAIGN_MIN_VIEWS_RANGE:
      return {
        ...state,
        campaign_min_views_range: action.payload,
      };
    case CAMPAIGN_MAX_VIEWS_RANGE:
      return {
        ...state,
        campaign_max_views_range: action.payload,
      };
    case CAMPAIGN_IMAGES:
      return {
        ...state,
        campaign_images: action.payload,
      };
    case CAMPAIGN_CITIES:
      return {
        ...state,
        campaign_cities: action.payload,
      };
    case CAMPAIGN_COUNTRY:
      return {
        ...state,
        campaign_country: action.payload,
      };
    case CAMPAIGN_SOCIAL_NETWORKS:
      return {
        ...state,
        campaign_social_networks: action.payload,
      };
    case CAMPAIGN_TAGS:
      return {
        ...state,
        campaign_tags: action.payload,
      };
    case CAMPAIGN_OBJECTIVES:
      return {
        ...state,
        campaign_objectives: action.payload,
      };
    case CAMPAIGNS_LIST_CREATED:
      return {
        ...state,
        campaign_list_created: action.payload,
      };
    case CAMPAIGN_LIST_LOADING:
      return {
        ...state,
        campaign_list_loading: action.payload,
      };
    case CAMPAIGN_TOTAL_LIST:
      return {
        ...state,
        campaign_total_list: action.payload,
      };
    case CAMPAIGN_PAGE:
      return {
        ...state,
        campaign_page: action.payload,
      };
    case CAMPAIGN_TOTAL_PAGES:
      return {
        ...state,
        campaign_total_pages: action.payload,
      };
    case CAMPAIGN_CURRENT_DETAIL:
      return {
        ...state,
        campaign_current_detail: action.payload,
      };
    case CAMPAIGN_CURRENT_LOADING:
      return {
        ...state,
        campaign_current_loading: action.payload,
      };
    case INFLUENCER_IN_CAMPAIGN:
      return {
        ...state,
        influencers_in_campaign: action.payload,
      };
    case INFLUENCER_IN_CAMPAIGN_LOADING:
      return {
        ...state,
        influencers_in_campaign_loading: action.payload,
      };
    case INFLUENCER_CAMPAIGN_TOTAL_LIST:
      return {
        ...state,
        influencer_campaign_total_list: action.payload,
      };
    case INFLUENCER_CAMPAIGN_PAGE:
      return {
        ...state,
        influencer_campaign_page: action.payload,
      };
    case INFLUENCER_CAMPAIGN_TOTAL_PAGES:
      return {
        ...state,
        influencer_campaign_total_pages: action.payload,
      };
    case CAMPAIGN_STATISTICS_INFLUENCERS:
      return {
        ...state,
        campaign_statistics_influencers: action.payload,
      };
    case CAMPAIGN_STATISTICS_PROPOSALS:
      return {
        ...state,
        campaign_statistics_proposals: action.payload,
      };
    case CAMPAIGN_STATISTICS_POSTS:
      return {
        ...state,
        campaign_statistics_posts: action.payload,
      };
    case CAMPAIGN_STATISTICS_LAST_POSTS:
      return {
        ...state,
        campaign_statistics_last_posts: action.payload,
      };
    default:
      return state;
  }
};
export default campaignReducer;
