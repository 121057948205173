import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../../../utils/formatNumber';
import defaultImage from '../../../../images/believe.jpg';
import './Influencer.css';

const InfluencerCard = (props) => {
  const {
    siteReducer: { site_language },
    image,
    name,
    lastname,
    city,
    social_networks,
    // rating,
    username,
    id,
    tags,
  } = props;
  const arrayName = site_language === 'es' ? 'english_name' : 'name';

  return (
    <Link to={`/influencer/${username || id}`} className="influencer__card">
      <div className="influencer__img">
        <img src={image || defaultImage} alt="influencer-img" />
      </div>
      <div className="influencer__info">
        <div className="influencer__name">
          {username || `${name} ${lastname}`}
        </div>
        {city && (
          <h3 className="influencer__city">
            {city.name}
            <br />
            <small>
              ({city.department_name}, {city[`country_${arrayName}`]})
            </small>
          </h3>
        )}
        <div className="influencer__categories">
          {tags.map((tag) => (
            <div className="category__container" key={tag.id}>
              <h4 className="category__name">{tag[arrayName]}</h4>
            </div>
          ))}
        </div>
        <div className="influencer__social row">
          {social_networks.map((item) => (
            <div className="media col-md-4" key={item.id}>
              <div className={`network__circle circle-${item.slug}`}>
                <i className={item.icon} />
              </div>
              <p className="media__viewers">{formatNumber(item.followers)}</p>
            </div>
          ))}
        </div>
      </div>
    </Link>
  );
};

InfluencerCard.defaultProps = {
  photo: defaultImage,
  name: 'Nombre por defecto',
  city: 'Ciudad por defecto',
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(InfluencerCard);
