import React from 'react';
import { Link } from 'react-router-dom';
import './CampaignCard.css';
import { connect } from 'react-redux';
import Image from '../../../../images/believe.jpg';

const CampaignCard = (props) => {
  const {
    userReducer: { user_data },
    siteReducer: { site_language },
    data,
  } = props;

  return (
    <Link
      to={
        data.campaign_status_id === 1
          ? `/manager/campaign/${data.consecutive}/edit`
          : `/manager/campaign/${data.consecutive}`
      }
      className={'ancore__card'}
    >
      <div className="campaign__card">
        <div className="campaign__card__header">
          <div className="logo__container">
            <img
              alt="brand__logo"
              src={data.brand_image ? data.brand_image : Image}
            />
          </div>
          <div className="campaign__information">
            <div className="campaign__networks">
              {data.social_networks.slice(0, 3).map((network) => (
                <div
                  className={`network__circle circle-${network.slug}`}
                  key={network.id}
                >
                  <i className={`${network.icon}`}></i>
                </div>
              ))}
            </div>
            <div
              className="campaign__status"
              style={{ backgroundColor: data.status_color }}
            >
              <span className="status__name">
                {site_language === 'es'
                  ? data.status_name
                  : data.status_english_name}
              </span>
            </div>
          </div>
        </div>

        <div className="campaign__card__name">{data.name}</div>

        <div className="campaign__footer">
          <span className="campaign__dates">
            <i className="fa-regular fa-calendar"></i>
            {`${data.date_start} - ${data.date_end}`}
          </span>
          <span className="influencers__campaign">
            <i className="fa-solid fa-user"></i> {data.total_members}
          </span>
        </div>
      </div>
    </Link>
  );
};
const mapStateToProps = ({ siteReducer, userReducer }) => {
  return {
    siteReducer,
    userReducer,
  };
};

export default connect(mapStateToProps, null)(CampaignCard);
