import React, { useEffect } from 'react';
import './Score.css';

const Score = (props) => {
  const { score, setScore, title } = props;
  //   useEffect(() => {}, [score]);

  const handleClickScore = (event) => {
    setScore(event.target.value);
  };

  return (
    <div className="score__form">
      {title && <div className="score__title">{title}</div>}
      <div className="score">
        <input
          id="radio1"
          type="radio"
          name="estrellas"
          value="5"
          checked={score === 5}
          onChange={handleClickScore}
        />
        <label htmlFor="radio1">
          <span className="fa fa-star" />
        </label>
        <input
          id="radio2"
          type="radio"
          name="estrellas"
          value="4"
          checked={score === 4}
          onChange={handleClickScore}
        />
        <label htmlFor="radio2">
          <span className="fa fa-star" />
        </label>
        <input
          id="radio3"
          type="radio"
          name="estrellas"
          value="3"
          checked={score === 3}
          onChange={handleClickScore}
        />
        <label htmlFor="radio3">
          <span className="fa fa-star" />
        </label>
        <input
          id="radio4"
          type="radio"
          name="estrellas"
          value="2"
          checked={score === 2}
          onChange={handleClickScore}
        />
        <label htmlFor="radio4">
          <span className="fa fa-star" />
        </label>
        <input
          id="radio5"
          type="radio"
          name="estrellas"
          value="1"
          checked={score === 1}
          onChange={handleClickScore}
        />
        <label htmlFor="radio5">
          <span className="fa fa-star" />
        </label>
      </div>
    </div>
  );
};

export default Score;
