import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as userActions from "../../../functionality/actions/userActions";

const MySwal = withReactContent(Swal);
const SITE_ROUTE = process.env.REACT_APP_SITE_ROUTE;

class SettingsStep2 extends Component {
  componentDidMount() {
    const {
      userReducer: { user_data },
      changeNitNumber,
      changeBusinessName,
      changePhone,
      changeDescription,
      changeAvgSales,
      changeWebsite,
      changeFacebook,
      changeTwitter,
      changeLinkedin,
      changeInstagram,
      changeActivity,
      changeUsername,
    } = this.props;
    if (user_data.docs) {
      const {
        nit_number,
        business_name,
        phone,
        description,
        average_sales,
        website,
        facebook,
        twitter,
        linkedin,
        instagram,
        activity_id,
      } = user_data.docs;

      changeNitNumber(nit_number ? nit_number : "");
      changeBusinessName(business_name ? business_name : "");
      changePhone(phone ? phone : "");
      changeDescription(description ? description : "");
      changeAvgSales(average_sales ? average_sales : "");
      changeWebsite(website ? website : "");
      changeFacebook(facebook ? facebook : "");
      changeTwitter(twitter ? twitter : "");
      changeLinkedin(linkedin ? linkedin : "");
      changeInstagram(instagram ? instagram : "");
      changeActivity(activity_id ? activity_id : "");
    }
    changeUsername(user_data.username ? user_data.username : "");
  }

  renderCategories = () => {
    const {
      userReducer: { error_activities, activities, cod_activity },
      siteReducer: {
        site_language,
        site_texts: { text_select },
      },
      changeActivity,
      userReducer: {
        user_data: { docs },
      },
    } = this.props;
    let readOnlyCategory = docs && docs.activity_id ? true : false;
    if (error_activities !== "") {
      return <div className="form-control">{this.props.error_activities}</div>;
    }
    return (
      <select
        className="form-control"
        value={cod_activity}
        onChange={(input) => changeActivity(input.target.value)}
        readOnly={readOnlyCategory}
      >
        <option>-- {text_select ? text_select : "Seleccionar"} --</option>
        {activities.map((item) => (
          <option value={item.id} key={item.id}>
            {site_language === "es" ? item.name : item.english_name}
          </option>
        ))}
      </select>
    );
  };

  showError = (message) => {
    MySwal.fire({
      title: "Upsss!!!",
      text: message,
      type: "warning",
      confirmButtonColor: "#50318B",
      onClose: () => this.props.userClearError(),
    });
  };

  saveDocs = () => {
    const {
      userReducer: {
        nit_number,
        business_name,
        phone,
        description,
        average_sales,
        website,
        facebook,
        twitter,
        linkedin,
        instagram,
        username,
        username_error,
        cod_activity,
      },
      siteReducer: {
        site_texts: {
          text_commercial_business,
          text_commercial_name,
          text_name_business,
          text_nit_number,
          text_business_number,
          text_average_sales,
          text_description_business,
        },
      },
      userSaveDocs,
      saveUsername,
    } = this.props;

    if (username.length === 0) {
      this.showError(
        text_commercial_business
          ? text_commercial_business
          : "Escribe el nombre comercial de tu negocio."
      );
      return;
    }
    if (username_error !== "") {
      this.showError(
        text_commercial_name
          ? text_commercial_name
          : "Válida que este bien escrito el nombre comercial."
      );
      return;
    }
    if (business_name === "") {
      this.showError(
        text_name_business
          ? text_name_business
          : "Debes escribir la razón social del negocio."
      );
      return;
    }
    if (nit_number === "") {
      this.showError(
        text_nit_number
          ? text_nit_number
          : "Debes escribir el número de NIT / RUT."
      );
      return;
    }
    if (phone === "") {
      this.showError(
        text_business_number
          ? text_business_number
          : "Debes escribir el número de teléfono."
      );
      return;
    }
    if (average_sales === "") {
      this.showError(
        text_average_sales
          ? text_average_sales
          : "Debes escribir el promedio de ventas."
      );
      return;
    }
    if (description === "") {
      this.showError(
        text_description_business
          ? text_description_business
          : "Debes escribir la descripción de tu negocio."
      );
      return;
    }
    let data = {
      nit_number,
      business_name,
      phone,
      description,
      average_sales,
      website,
      facebook,
      twitter,
      linkedin,
      instagram,
      activity_id: cod_activity,
    };
    let dataUser = {
      username,
    };
    saveUsername(dataUser);
    userSaveDocs(data, 3);
  };

  render() {
    const {
      changeNitNumber,
      changeBusinessName,
      changePhone,
      changeDescription,
      changeAvgSales,
      changeWebsite,
      changeFacebook,
      changeTwitter,
      changeLinkedin,
      changeInstagram,
      changeUsername,
      userReducer: {
        nit_number,
        business_name,
        phone,
        description,
        average_sales,
        website,
        facebook,
        twitter,
        linkedin,
        instagram,
        username,
        username_error,
        username_validate,
        username_success,
        user_data: { docs },
      },
      siteReducer: {
        site_texts: {
          text_source_of_funds_statement,
          text_source_funds_1,
          text_source_funds_2,
          text_source_funds_3,
          text_source_funds_4,
          text_source_funds_5,
          text_company_information,
          text_more_about_company,
          text_tradename,
          text_remember,
          text_business_name,
          text_nit_rut,
          text_phone_number,
          text_website,
          text_trade_activity,
          text_monthly_sales,
          text_describe_business,
          text_social_media,
          text_continue,
          text_username,
        },
      },
    } = this.props;
    let readOnlyBusinessName = docs && docs.business_name ? true : false;
    let readOnlyNit = docs && docs.nit_number ? true : false;
    let readOnlyAvgSales = docs && docs.average_sales ? true : false;
    return (
      <Fragment>
        <div className="settingsView_title">
          {text_source_of_funds_statement
            ? text_source_of_funds_statement.toUpperCase()
            : "DECLARACIÓN ORIGEN DE FONDOS"}
        </div>
        <div className="settingsView_description">
          <ol type="1">
            <li>
              {text_source_funds_1
                ? text_source_funds_1
                : "Mi actividad, profesión u oficio de la persona Natural o Jurídica es lícita, se ejerce dentro del marco legal y los recursos que poseo no provienen de actividades ilícitas de las contempladas en el Código Penal Colombiano."}
            </li>
            <li>
              {text_source_funds_2
                ? text_source_funds_2
                : "La información suministrada en este documento es veraz y verificable y me comprometo a actualizarla anualmente. El incumplimiento de esta obligación faculta a BAX, para revocar y/o rescindir unilateralmente el contrato."}
            </li>
            <li>
              {text_source_funds_3
                ? text_source_funds_3
                : "Los recursos que se deriven de esta relación contractual no se destinarán a la Financiación del Terrorismo."}
            </li>
            <li>
              {text_source_funds_4
                ? text_source_funds_4
                : "Manifiesto que no he sido declarado responsable judicialmente por la comisión de delitos contra la Administración Pública cuya pena sea privativa de la libertad o que afecten el patrimonio del Estado o por delitos relacionados con la pertenencia, promoción o financiación de grupos ilegales, delitos de lesa humanidad, narcotráfico o corrupcion."}
            </li>
            <li>
              {text_source_funds_5
                ? text_source_funds_5
                : "Los recursos que poseo provienen de las siguientes fuentes (detalle ocupación, oficio, actividad o negocio)."}
            </li>
          </ol>
        </div>
        <div className="settingsView_title">
          {text_company_information
            ? text_company_information.toUpperCase()
            : "INFORMACIÓN DE LA EMPRESA"}
        </div>
        <div className="settingsView_description">
          {text_more_about_company
            ? text_more_about_company
            : "Necesitamos saber más sobre tu empresa, negocio o servicio antes de darte de alta en BAX. La información ingresada será visible para todos los miembros de la plataforma en tu perfil menos tus ventas mensuales promedio que sólo serán utilizadas para tu estudio de crédito dentro de la plataforma."}
        </div>
        <div className="row">
          <div className="col-md-12">
            <label>
              {text_tradename
                ? text_tradename.toUpperCase()
                : "NOMBRE COMERCIAL"}
            </label>
            <div>
              {text_remember
                ? text_remember
                : "Recuerda que no debe llevar espacios en blanco, ni carecteres especiales, en caso de querer separar se pueden utilizar: el punto '.', guion '-' o guion bajo '_'."}
            </div>
            <div className="settingsView_name-profile">
              {SITE_ROUTE}
              <input
                type="text"
                placeholder={
                  text_username
                    ? text_username.toUpperCase()
                    : "NOMBRE DE USUARIO"
                }
                className="form-control"
                value={username}
                onChange={(input) => changeUsername(input.target.value)}
              />
            </div>
            <div className="settingsView_message">
              <div className="settingsView_error">{username_error}</div>
              <div className="settingsView_success">{username_success}</div>
              {username_validate && <div>Validando...</div>}
            </div>
          </div>
          <div className="col-md-6">
            <label>
              {text_business_name
                ? text_business_name.toUpperCase()
                : "RAZÓN SOCIAL"}
            </label>
            <input
              type="text"
              placeholder={
                text_business_name
                  ? text_business_name.toUpperCase()
                  : "RAZÓN SOCIAL"
              }
              className="form-control"
              value={business_name}
              onChange={(input) => changeBusinessName(input.target.value)}
              readOnly={readOnlyBusinessName}
            />
          </div>
          <div className="col-md-6">
            <label>
              {text_nit_rut ? text_nit_rut.toUpperCase() : "NIT / RUT"}
            </label>
            <input
              type="text"
              placeholder={
                text_nit_rut ? text_nit_rut.toUpperCase() : "NIT / RUT"
              }
              className="form-control"
              value={nit_number}
              onChange={(input) => changeNitNumber(input.target.value)}
              readOnly={readOnlyNit}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>
              {text_phone_number
                ? text_phone_number.toUpperCase()
                : "NÚMERO DE TELÉFONO"}
            </label>
            <input
              type="text"
              placeholder={
                text_phone_number
                  ? text_phone_number.toUpperCase()
                  : "NÚMERO DE TELÉFONO"
              }
              className="form-control"
              value={phone}
              onChange={(input) => changePhone(input.target.value)}
            />
          </div>
          <div className="col-md-6">
            <label>
              {text_website
                ? text_website.toUpperCase()
                : "SITIO WEB DE TU EMPRESA O NEGOCIO"}
            </label>
            <input
              type="text"
              placeholder={
                text_website
                  ? text_website.toUpperCase()
                  : "SITIO WEB DE TU EMPRESA O NEGOCIO"
              }
              className="form-control"
              value={website}
              onChange={(input) => changeWebsite(input.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>
              {text_trade_activity
                ? text_trade_activity.toUpperCase()
                : "ACTIVIDAD DEL COMERCIO"}
            </label>
            {this.renderCategories()}
          </div>
          <div className="col-md-6">
            <label>
              {text_monthly_sales
                ? text_monthly_sales.toUpperCase()
                : "PROMEDIO DE VENTAS MENSUALES (EN PESOS COLOMBIANOS)"}
            </label>
            <input
              type="text"
              placeholder={
                text_monthly_sales
                  ? text_monthly_sales.toUpperCase()
                  : "PROMEDIO DE VENTAS MENSUALES (EN PESOS COLOMBIANOS)"
              }
              className="form-control"
              value={average_sales}
              onChange={(input) => changeAvgSales(input.target.value)}
              readOnly={readOnlyAvgSales}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label>
              {text_describe_business
                ? text_describe_business.toUpperCase()
                : "DESCRIBE TU NEGOCIO. QUÉ PRODUCTOS O SERVICIOS OFRECES"}
            </label>
            <textarea
              type="text"
              className="form-control"
              value={description}
              onChange={(input) => changeDescription(input.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label>
              {text_social_media
                ? text_social_media.toUpperCase()
                : "PERFILES DE REDES SOCIALES"}
            </label>
          </div>
          <div className="col-md-6 d-flex align-items-center settingsView_social">
            <span>facebook.com/</span>
            <input
              type="text"
              placeholder="Facebook"
              className="form-control"
              value={facebook}
              onChange={(input) => changeFacebook(input.target.value)}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center settingsView_social">
            <span>twitter.com/</span>
            <input
              type="text"
              placeholder="Twitter"
              className="form-control"
              value={twitter}
              onChange={(input) => changeTwitter(input.target.value)}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center settingsView_social">
            <span>linkedin.com/</span>
            <input
              type="text"
              placeholder="Linkedin"
              className="form-control"
              value={linkedin}
              onChange={(input) => changeLinkedin(input.target.value)}
            />
          </div>
          <div className="col-md-6 d-flex align-items-center settingsView_social">
            <span>instagram.com/</span>
            <input
              type="text"
              placeholder="Instagram"
              className="form-control"
              value={instagram}
              onChange={(input) => changeInstagram(input.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button className="settingsView_next" onClick={this.saveDocs}>
            <span className="icon-chek" />
            {text_continue ? text_continue : "Continuar"}
          </button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, userActions)(SettingsStep2);
