import React from "react";
import ChatItem from "./ChatItem";
import "./css/ChatList.css";

function ChatList(props) {
  return (
    <div className="chatList_container">
      {props.data.map((item) => {
        return (
          <ChatItem
            key={item.id}
            data={item}
            actions={props.actions}
            notifications={props.notifications}
            handleViewMessage={props.handleViewMessage}
          />
        );
      })}
    </div>
  );
}

export default ChatList;
