import React from 'react';

function SelectOptions(props) {
  const { value, onChange, options, buttonClass, multipleSelection } = props;

  return (
    <div className="questions_select-options">
      {options.map((option) => {
        let statusActive = false;

        if (multipleSelection) {
          statusActive = value.find((item) => item.id === option.id);
        } else {
          statusActive = option.id === value;
        }

        const isActive = statusActive ? 'active' : '';
        return (
          <button
            onClick={() => onChange(multipleSelection ? option : option.id)}
            className={`${buttonClass} ${isActive}`}
            key={option.id}
          >
            {option.name}
            {isActive === 'active' && <i className="fa fa-check" />}
          </button>
        );
      })}
    </div>
  );
}

SelectOptions.defaultProps = {
  buttonClass: 'questions_confirm',
  multipleSelection: false,
};

export default SelectOptions;
