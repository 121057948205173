import React from 'react';
import defaultImage from '../../../../images/believe.jpg';

function Facebook(props) {
  const { data, profile, image } = props;

  const getFirstAttachment = (attachments) => {
    const { data } = attachments;

    if (!data || !data.length) return null;

    const firstElemet = data[0];
    const {
      media: {
        image: { src },
      },
      title,
    } = firstElemet;

    if (!src) return null;

    return (
      <div className="facebook__image">
        <img src={src} alt="Content" />
        {title && <div className="facebook__image-title">{title}</div>}
      </div>
    );
  };

  return (
    <>
      {data.map((item) => (
        <div key={item.id} className="facebook__content item">
          <a
            href={item.permalink_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="twitter_header">
              <img src={image || defaultImage} alt="Profile" />
              <div>
                <div className="twitter_name">@{profile.name}</div>
              </div>
            </div>
            {item.attachments && getFirstAttachment(item.attachments)}
            {item.message && (
              <div className="facebook__content-text">{item.message}</div>
            )}
          </a>
        </div>
      ))}
    </>
  );
}

export default Facebook;
