import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ViewModal from '../../all/ViewModal';
import { connect } from 'react-redux';

const MySwal = withReactContent(Swal);

class ReportOrder extends Component {
  showError = (message) => {
    MySwal.fire({
      title: 'Hubo un error',
      text: message,
      type: 'error',
      confirmButtonColor: '#50318B',
    });
  };

  sendReport = () => {
    const { order_report, cod_order, orderClaim } = this.props;
    if (order_report === '') {
      this.showError('Debes escribir los cambios a solicitar.');
      return;
    }
    let data = {
      claim: order_report,
    };
    orderClaim(cod_order, data);
  };

  render() {
    const {
      siteReducer: {
        site_texts: { text_report_nonconformity, text_close, text_send },
      },
    } = this.props;

    return (
      <ViewModal is_visible={this.props.is_visible}>
        <div className="viewModal_title">
          {text_report_nonconformity || 'REPORTAR INCONFORMIDAD'}
        </div>
        <textarea
          onChange={(input) => this.props.orderReport(input.target.value)}
          value={this.props.order_report}
          className="viewModal_textarea"
        />
        <div className="viewModal_menu">
          <button
            className="btn btn-pymex-2"
            onClick={() => this.props.orderViewReport(false)}
          >
            {text_close || 'Cerrar'}
          </button>
          <button className="btn btn-pymex" onClick={this.sendReport}>
            {text_send || 'Enviar'}
          </button>
        </div>
      </ViewModal>
    );
  }
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(ReportOrder);
