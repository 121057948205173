import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { changeUserSocialNetworks } from '../../functionality/actions/userActions';
import Api from '../../functionality/Api';

function TwitterButton(props) {
  const {
    siteReducer: {
      site_texts: {
        text_twitter_title,
        text_continue,
        text_cancel,
        text_social_save,
        text_error_social,
        text_congratulations,
        text_social_success,
      },
    },
    id,
    type,
    name,
    icon,
    isAdded,
    changeUserSocialNetworks,
    onChangeLoading,
  } = props;

  const showError = (error) => {
    Swal.fire({
      title: 'Upsss!!!',
      text: error,
      type: 'error',
      confirmButtonColor: '#50318B',
    }).then(() => {
      onChangeLoading('');
    });
  };

  const openSwal = () => {
    Swal.fire({
      title:
        text_twitter_title ||
        'Escribe el nombre de usuario en twitter sin el @',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: text_continue || 'Continuar',
      cancelButtonText: text_cancel || 'Cancelar',
      confirmButtonColor: '#50318B',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
      preConfirm: async (username) => {
        if (!username.trim().length) return;
        const userData = await Api.asyncCallMethod(
          'v1/twitter/get-user-by-username',
          'POST',
          { username }
        );
        if (userData.status === 200 && userData.response.code === 403) {
          return userData;
        }
        const tweets = await Api.asyncCallMethod(
          'v1/twitter/get-tweets-by-userid',
          'POST',
          {
            twitter_user_id: userData.response.data.id,
          }
        );

        tweets.response.user = userData.response.data;

        return tweets;
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.value === '') return;

      const {
        value: { response },
      } = result;

      if (response.code === 403) {
        showError(`${response.message}`);
        return;
      }

      saveData(response);
    });
  };

  const saveData = async (twitterData) => {
    try {
      onChangeLoading(text_social_save || 'Guardando información...');

      const data = {
        social_network_id: id,
        profile: twitterData.user,
        contents: twitterData.data,
      };

      const satusSave = await Api.asyncCallMethod('v1/twitter', 'POST', data);
      Swal.fire({
        title: text_congratulations || 'Felicitaciones!',
        text: text_social_success || 'Tu cuenta ha sido agregada con éxito',
        type: 'success',
        confirmButtonColor: '#50318B',
      }).then(() => {
        onChangeLoading('');
        changeUserSocialNetworks(satusSave.response.data);
      });
    } catch (error) {
      console.log('Error saveData: ', error);
      showError(
        text_error_social ||
          'Hubo un error al traer los datos, intentalo más tarde.'
      );
    }
  };

  if (type === 'register') {
    return (
      <button
        className="socialNetworks_social-button twitter"
        onClick={openSwal}
      >
        <div className="socialNetworks_social">
          <div className="socialNetworks_social-icon">
            <i className={icon} />
          </div>
          {name}
        </div>
        {isAdded && <i className="fa-solid fa-check" />}
      </button>
    );
  }

  return (
    <div className="socialNetworks_social-button twitter">
      <div className="socialNetworks_social">
        <div className="socialNetworks_social-icon">
          <i className={icon} />
        </div>
        {name}
      </div>
      {isAdded && <i className="fa-solid fa-check" />}
    </div>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

const mapDispatchToProps = {
  changeUserSocialNetworks,
};

export default connect(mapStateToProps, mapDispatchToProps)(TwitterButton);
