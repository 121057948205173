import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import MarketPlaceLayout from './layouts/MarketplaceLayout';
import Error from '../../../components/all/Error';
import BoxWhite from '../../../components/user/marketplace/BoxWhite';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import * as cartActions from '../../../functionality/actions/cartActions';
import TopSpinner from '../../../components/all/TopSpinner';

const MySwal = withReactContent(Swal);

class CheckoutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusSearch: false,
      product: [],
      total_items: '',
      images: '',
      is_promo: '',
      price: '',
    };
  }

  showError = (message) => {
    MySwal.fire({
      title: 'Upsss!!!',
      text: message,
      type: 'warning',
      confirmButtonColor: '#50318B',
      onClose: () => this.props.cartClearError(),
    });
  };

  openCloseSearch = (statusSearch) => {
    this.setState({
      statusSearch,
    });
  };

  componentDidMount() {
    const { cartDetailProduct } = this.props;
    let slug = this.props.match.params.cod_product;
    cartDetailProduct(slug);
  }

  render() {
    const {
      shopProduct,
      cartReducer: {
        cart_message_error,
        cart_product,
        cart_total_items,
        cart_images,
        cart_is_promo,
        cart_price,
        cart_commission,
        cart_iva,
        cart_view_price,
      },
      userReducer: {
        loading_init,
        user_data: { total_products },
      },
      siteReducer: {
        site_texts: {
          text_product_or_service,
          text_unit_price,
          text_amount,
          text_cart_price,
          text_commission_costs,
          text_commission,
          text_iva,
          text_finish_purchase,
          the_payment_will_be_made,
          text_shopping_cart,
        },
      },
    } = this.props;

    const numberFormat = new Intl.NumberFormat('es-CO');

    return (
      <MarketPlaceLayout
        statusSearch={this.state.statusSearch}
        openCloseSearch={this.openCloseSearch}
      >
        {cart_message_error !== '' && this.showError(cart_message_error)}
        <div className="container shoppingCartView_container">
          <div className="row">
            <div className="col-sm-12">
              <BoxWhite>
                <div className="shoppingCartView_title">
                  CHECKOUT
                  <div className="shoppingCartView_title-line" />
                </div>
                {loading_init ? (
                  <TopSpinner />
                ) : (
                  <Fragment>
                    {!cart_product ? (
                      <Error
                        type="no-checkout"
                        link="/cart"
                        buttonName={
                          text_shopping_cart
                            ? text_shopping_cart
                            : 'Ir al carrito'
                        }
                      />
                    ) : (
                      <div className="row">
                        {total_products === 0 && <Redirect to="/cart" />}
                        <div className="col-md-8">
                          <BoxWhite>
                            <div className="table-responsive-md">
                              <table className="table itemShoppingCart_table">
                                <thead className="itemShoppingCart_thead">
                                  <tr>
                                    <td className="align-middle">
                                      {text_product_or_service
                                        ? text_product_or_service
                                        : 'PRODUCTO O SERVICIO'}
                                    </td>
                                    <td className="align-middle">
                                      {text_unit_price
                                        ? text_unit_price
                                        : 'PRECIO UNITARIO'}
                                    </td>
                                    <td className="align-middle">
                                      {text_amount
                                        ? text_amount.toUpperCase()
                                        : 'CANTIDAD'}
                                    </td>
                                    <td className="align-middle">
                                      {text_cart_price
                                        ? text_cart_price
                                        : 'PRECIO'}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="align-middle d-flex flex-column align-items-center">
                                      <img
                                        src={cart_images[0].image}
                                        alt={cart_product.name}
                                        className="itemShoppingCart_image"
                                      />
                                      <div className="itemShoppingCart_name">
                                        {cart_product.name}
                                      </div>
                                    </td>
                                    <td className="align-middle text-center">
                                      {cart_product.price_promo !== 0 &&
                                      cart_is_promo ? (
                                        <div className="itemShoppingCart_box-price">
                                          <div className="itemShoppingCart_price">
                                            BAX${' '}
                                            {numberFormat.format(
                                              cart_product.price_promo
                                            )}
                                          </div>
                                          <div className="itemShoppingCart_discount">
                                            BAX${' '}
                                            {numberFormat.format(
                                              cart_product.price
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="itemShoppingCart_box-price">
                                          <div className="itemShoppingCart_price">
                                            BAX${' '}
                                            {numberFormat.format(
                                              cart_product.price
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </td>
                                    <td className="align-middle text-center">
                                      {cart_total_items}
                                    </td>
                                    <td className="align-middle text-center">
                                      $BAX {numberFormat.format(cart_price)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </BoxWhite>
                        </div>
                        <div className="col-md-4">
                          <BoxWhite>
                            <h5 className="text-center color-purple">
                              {text_commission_costs
                                ? text_commission_costs
                                : 'COSTOS DE COMISIÓN'}
                            </h5>
                            <div className="table-responsive-md">
                              <table className="table itemShoppingCart_table">
                                <tbody>
                                  <tr>
                                    <td>
                                      {text_commission
                                        ? text_commission
                                        : 'Comisión'}
                                      :
                                    </td>
                                    <td className="text-right">
                                      ${numberFormat.format(cart_commission)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>{text_iva ? text_iva : 'IVA'}:</td>
                                    <td className="text-right">
                                      ${numberFormat.format(cart_iva)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>TOTAL:</td>
                                    <td className="text-right">
                                      $
                                      {numberFormat.format(
                                        cart_commission + cart_iva
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <hr />
                              <button
                                className="btn btn-block btn-pymex"
                                onClick={() =>
                                  shopProduct(
                                    cart_product.slug,
                                    cart_total_items,
                                    cart_view_price
                                  )
                                }
                              >
                                {text_finish_purchase
                                  ? text_finish_purchase
                                  : 'FINALIZAR COMPRA'}
                              </button>
                              <div className="text-justify">
                                {the_payment_will_be_made
                                  ? the_payment_will_be_made
                                  : 'El pago sera efectuado una vez haya terminado el proceso de entrega del producto o servicio, será cobrado al método de pago seleccionado.'}
                              </div>
                            </div>
                          </BoxWhite>
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
              </BoxWhite>
            </div>
          </div>
        </div>
      </MarketPlaceLayout>
    );
  }
}

const mapStateToProps = ({ cartReducer, userReducer, siteReducer }) => {
  return {
    cartReducer,
    userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, cartActions)(CheckoutPage);
