import React, { Component } from "react";
import SettingsView from "./views/SettingsView";
import ManagerLayout from "./layouts/ManagerLayout";

class SettingsPage extends Component {
  render() {
    return (
      <ManagerLayout>
        <SettingsView />
      </ManagerLayout>
    );
  }
}

export default SettingsPage;
