import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../all/Loading';
import FacebookButton from './FacebookButton';
import InstagramButton from './InstagramButton';
import TikTokButton from './TikTokButton';
import TwitterButton from './TwitterButton';
import YouTubeButton from './YouTubeButton';
import './css/AddSocialNetworks.css';
import { getSocialNetworks } from '../../functionality/actions/generalActions';

class AddSocialNetworks extends Component {
  state = {
    modal: '',
    social_networks: [],
    loading: false,
    text_loading: '',
  };

  componentDidMount() {
    const {
      generalReducer: { general_social_networks },
    } = this.props;

    this.updateSocialNetworks(general_social_networks);

    // const social_networks = general_social_networks.filter(
    //   (item) => item.in_user
    // );

    // this.setState({ social_networks });
  }

  onChangeLoading = (text_loading) => {
    this.setState({
      loading: text_loading !== '',
      text_loading,
    });
  };

  componentDidUpdate(prevProps) {
    const {
      generalReducer: { general_social_networks },
    } = this.props;

    if (
      prevProps.generalReducer.general_social_networks !==
      general_social_networks
    ) {
      this.updateSocialNetworks(general_social_networks);
    }
  }

  updateSocialNetworks(general_social_networks) {
    const social_networks = general_social_networks.filter(
      (item) => item.in_user
    );

    this.setState({ social_networks });
  }

  renderSocialButton = (socialNetwork) => {
    const {
      userReducer: { user_social_networks },
    } = this.props;

    if (socialNetwork.name === 'Facebook') {
      const isAdded = user_social_networks.find(
        (item) => parseInt(socialNetwork.id) === parseInt(item.id)
      );

      return (
        <FacebookButton
          key={socialNetwork.id}
          {...socialNetwork}
          type="register"
          onChangeLoading={this.onChangeLoading}
          isAdded={isAdded}
        />
      );
    }
    if (socialNetwork.name === 'Instagram') {
      const isAdded = user_social_networks.find(
        (item) => parseInt(socialNetwork.id) === parseInt(item.id)
      );
      return (
        <InstagramButton
          key={socialNetwork.id}
          {...socialNetwork}
          type="register"
          isAdded={isAdded}
          onChangeLoading={this.onChangeLoading}
        />
      );
    }
    if (socialNetwork.name === 'Twitter') {
      const isAdded = user_social_networks.find(
        (item) => parseInt(socialNetwork.id) === parseInt(item.id)
      );
      return (
        <TwitterButton
          key={socialNetwork.id}
          {...socialNetwork}
          type="register"
          isAdded={isAdded}
          onChangeLoading={this.onChangeLoading}
        />
      );
    }
    if (socialNetwork.name === 'TikTok') {
      const isAdded = user_social_networks.find(
        (item) => parseInt(socialNetwork.id) === parseInt(item.id)
      );
      return (
        <TikTokButton
          key={socialNetwork.id}
          {...socialNetwork}
          type="register"
          isAdded={isAdded}
          onChangeLoading={this.onChangeLoading}
          redirectUrl={`${process.env.REACT_APP_SITE_ROUTE}manager/register/docs`}
        />
      );
    }
    if (socialNetwork.name === 'Youtube') {
      const isAdded = user_social_networks.find(
        (item) => parseInt(socialNetwork.id) === parseInt(item.id)
      );
      return (
        <YouTubeButton
          key={socialNetwork.id}
          {...socialNetwork}
          type="register"
          isAdded={isAdded}
          onChangeLoading={this.onChangeLoading}
        />
      );
    }

    return null;
  };

  render() {
    const { modal, social_networks, loading, text_loading } = this.state;
    const {
      siteReducer: {
        site_texts: {
          text_add_social_network,
          text_select_social_network,
          text_social_add_text,
          text_add_social_network_2,
        },
      },
      userReducer: { user_social_networks },
      // generalReducer: { general_social_networks },
      onlyButton,
    } = this.props;

    // console.log('social network', social_networks);
    // console.log('general social network', general_social_networks);

    return (
      <>
        {loading && (
          <Loading
            title={text_loading}
            backgroundClass="loading_black"
            spinnerColor="white"
          />
        )}
        <div className={`socialNetworks_modal ${modal}`}>
          <div
            className="socialNetworks_modal-content"
            style={onlyButton ? { margin: '200px auto' } : null}
          >
            <div className="socialNetworks_modal-header">
              <h3>
                {text_select_social_network || 'Selecciona la red social'}
              </h3>
              <button
                className="socialNetworks_close"
                onClick={() => this.setState({ modal: '' })}
              >
                <i className="fa-solid fa-xmark" />
              </button>
            </div>
            <div className="socialNetworks_modal-body">
              {social_networks.map((item) => this.renderSocialButton(item))}
            </div>
          </div>
        </div>

        <div className="socialNetworks_content">
          {!onlyButton && (
            <>
              {user_social_networks.length ? (
                <>
                  <div className="socialNetworks_networks-title">
                    {text_social_add_text || 'Redes sociales agregadas:'}
                  </div>
                  <div className="socialNetworks_networks">
                    {user_social_networks.map((item) => (
                      <div className="socialNetworks_network" key={item.id}>
                        <i className={item.icon} />
                        {item.name}
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </>
          )}

          <button
            className="socialNetworks_open-modal"
            onClick={() => this.setState({ modal: 'show' })}
          >
            {!onlyButton
              ? text_add_social_network || 'Agregar'
              : text_add_social_network_2 || 'Añadir Red Social'}
          </button>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ generalReducer, siteReducer, userReducer }) => {
  return {
    generalReducer,
    siteReducer,
    userReducer,
  };
};

const mapDispatchToProps = { getSocialNetworks };

export default connect(mapStateToProps, mapDispatchToProps)(AddSocialNetworks);
