import React from "react";
import { connect } from "react-redux";

function FormSearch(props) {
  const {
    siteReducer: {
      site_texts: { text_go_search },
    },
  } = props;

  return (
    <div className="box-search">
      <form
        className="productView_box-search input-group"
        onSubmit={props.toSearch}
      >
        <input
          type="text"
          className="form-control"
          placeholder={text_go_search ? text_go_search : "Buscar"}
          value={props.search}
          onChange={(e) => props.changeSearch(e.target.value)}
        />
        <div className="input-group-append">
          <button className="btn btn-outline-secondary" type="submit">
            <span className="icon-loup" />
          </button>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(FormSearch);
