import {
  REGISTER_ALERT_VALIDATE,
  REGISTER_ALERT_VISIBLE,
  REGISTER_ALERT_RESPONSE,
} from "../types/registerAlertTypes";

const INITIAL_STATE = {
  register_alert_validate: true,
  register_alert_visible: "",
  register_alert_response: "",
};

const registerAlertReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_ALERT_VALIDATE:
      return {
        ...state,
        register_alert_validate: action.payload,
      };
    case REGISTER_ALERT_VISIBLE:
      return {
        ...state,
        register_alert_visible: action.payload,
      };
    case REGISTER_ALERT_RESPONSE:
      return {
        ...state,
        register_alert_response: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default registerAlertReducer;
