import React from 'react';
import { connect } from 'react-redux';
import Analityc from '../../Analytic/Analytic';
import { formatNumber } from '../../../../utils/formatNumber';

const TikTok = (props) => {
  const {
    statistics,
    siteReducer: {
      site_texts: {
        text_views,
        text_estimated_minutes_viewed,
        text_total_likes,
        text_total_videos,
      },
    },
  } = props;

  return (
    <>
      <div className="analitycs__container">
        <Analityc
          icon={'fa-solid fa-users-rectangle'}
          title={text_total_likes || 'Número de Likes'}
          value={formatNumber(statistics.likes_count)}
        />
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={text_total_videos || 'Número de videos'}
          value={formatNumber(statistics.video_count)}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return { siteReducer };
};

export default connect(mapStateToProps, null)(TikTok);
