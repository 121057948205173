export const PAYMENT_METHOD = 'PAYMENT_METHOD';
export const PAYMENT_OPEN_MODAL = 'PAYMENT_OPEN_MODAL';
export const PAYMENT_CLOSE_MODAL = 'PAYMENT_CLOSE_MODAL';
export const PAYMENT_CASH_TYPE = 'PAYMENT_CASH_TYPE';
export const PAYMENT_CASH_VALUE = 'PAYMENT_CASH_VALUE';
export const PAYMENT_LOADING = 'PAYMENT_LOADING';
export const PAYMENT_CLOSE_LOADING = 'PAYMENT_CLOSE_LOADING';
export const PAYMENT_LIST_CARDS = 'PAYMENT_LIST_CARDS';
export const PAYMENT_DEFAULT = 'PAYMENT_DEFAULT';
export const PAYMENT_LOADED = 'PAYMENT_LOADED';
export const PAYMENT_NUMBER = 'PAYMENT_NUMBER';
export const PAYMENT_EXP_MONTH = 'PAYMENT_EXP_MONTH';
export const PAYMENT_EXP_YEAR = 'PAYMENT_EXP_YEAR';
export const PAYMENT_CVC = 'PAYMENT_CVC';
export const PAYMENT_DUES = 'PAYMENT_DUES';
export const PAYMENT_HEADLINE = 'PAYMENT_HEADLINE';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_LIST = 'PAYMENT_LIST';
export const PAYMENT_CLEAR = 'PAYMENT_CLEAR';
export const PAYMENT_EDIT_CARD = 'PAYMENT_EDIT_CARD';
export const PAYMENT_CLEAR_ERROR = 'PAYMENT_CLEAR_ERROR';
export const PAYMENT_CASH_SELECT = 'PAYMENT_CASH_SELECT';
export const PAYMENT_BANKS_PSE = 'PAYMENT_BANKS_PSE';
export const PAYMENT_BANKS_ERROR = 'PAYMENT_BANKS_ERROR';
export const PAYMENT_PSE_BANK = 'PAYMENT_PSE_BANK';
export const PAYMENT_NAME = 'PAYMENT_NAME';
export const PAYMENT_LASTNAME = 'PAYMENT_LASTNAME';
export const PAYMENT_DOC_TYPE = 'PAYMENT_DOC_TYPE';
export const PAYMENT_DOC_NUMBER = 'PAYMENT_DOC_NUMBER';