import React, { useState, useEffect } from 'react';
import './CollapseComponent.css';

const CollapseComponent = ({ title, children, open, superTitle, target }) => {
  const haveTitle = !!target ? target : title;
  const [icon, setIcon] = useState('fa-solid fa-caret-down collapser__icon');
  const [disabled, setDisabled] = useState(false);

  const handleCollapserClick = () => {
    if (!disabled) {
      if (icon === 'fa-solid fa-caret-down collapser__icon') {
        setIcon('fa-solid fa-caret-up collapser__icon');
      } else {
        setIcon('fa-solid fa-caret-down collapser__icon');
      }
      setDisabled(true);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisabled(false);
    }, 300);

    return () => clearTimeout(timer);
  }, [disabled]);

  return (
    <div className="collapseComponent">
      <div
        className={`collapser ${open ? '' : 'collapsed'}`}
        type="button"
        data-toggle="collapse"
        data-target={`#${haveTitle}From`}
        aria-expanded={open}
        aria-controls={`${haveTitle}From`}
        onClick={handleCollapserClick}
      >
        <div className="head__collapser">
          {title && <span className="collapser__title">{title}</span>}
          {superTitle && <span className="superTitle">{superTitle}</span>}

          <span className={icon}></span>
        </div>
      </div>

      <div className={`collapse ${open ? 'show' : ''}`} id={`${haveTitle}From`}>
        <div className="collapseComponent__container">{children}</div>
      </div>
    </div>
  );
};

export default CollapseComponent;
