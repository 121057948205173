import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CropImages from '../../../../components/all/CropImages';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import 'moment/locale/es.js';

import './css/ProductDetailView.css';

import Api from '../../../../functionality/Api';
import { cropperImage } from '../../../../functionality/actions/cropperActions';
import {
  openLoading,
  closeLoading,
} from '../../../../functionality/actions/userActions';
import {
  productAddImages,
  productName,
  productDescription,
  productPrice,
  productPricePromo,
  productCategory,
  productType,
  productSku,
  productSave,
  productClearError,
  productIsActive,
  productClear,
  myProducts,
  productId,
  productDetailError,
  productShortDescription,
  productDatePromo,
  productImagesUpload,
  productDeleteImage,
  productGetEdit,
  setProductBrandId,
} from '../../../../functionality/actions/productActions';
import TopSpinner from '../../../../components/all/TopSpinner';

const MySwal = withReactContent(Swal);

class ProductDetailView extends Component {
  componentDidMount() {
    const { codProduct, productGetEdit } = this.props;
    if (codProduct) {
      productGetEdit(codProduct);
    }
  }

  componentWillUnmount() {
    const { productClear } = this.props;
    productClear();
  }

  showError = (message) => {
    MySwal.fire({
      title: 'Upsss!!!',
      text: message,
      type: 'warning',
      confirmButtonColor: '#50318B',
      onClose: () => {
        this.props.productClearError();
        this.props.closeLoading();
      },
    });
  };

  onCrop = (image) => {
    const {
      openLoading,
      closeLoading,
      siteReducer: {
        site_texts: { text_uploading_image },
      },
    } = this.props;
    openLoading(
      text_uploading_image ? text_uploading_image : 'Subiendo Imagen...'
    );
    let data = new FormData();
    data.append('imgBase64', image);
    Api.uploadFile(data, (res) => {
      if (res.location) {
        this.addImage(res.location);
        closeLoading();
      }
    });
  };

  addImage = (image) => {
    const {
      productReducer: { product_images },
      productAddImages,
    } = this.props;
    let images = product_images;
    images.push(image);
    productAddImages(images);
  };

  handleChange = (e, ratio_width, ratio_height) => {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      let config = {
        cropper_image: reader.result,
        cropper_ratio_width: ratio_width,
        cropper_ratio_height: ratio_height,
      };
      this.props.cropperImage(config);
    };
    reader.readAsDataURL(files[0]);
  };

  renderImages = () => {
    const {
      productReducer: { product_images },
      productDeleteImage,
    } = this.props;

    const images = product_images.map((image, index) => {
      return (
        <div className="product_detail-box-image" key={image.id}>
          <div
            className="product_detail-remove"
            onClick={() => productDeleteImage(index)}
          >
            <span className="icon-bin" />
          </div>
          <img src={image.image} alt={image.image} />
        </div>
      );
    });
    return images;
  };

  renderCategories = () => {
    const {
      userReducer: { categories },
      productReducer: { product_category },
      siteReducer: {
        site_language,
        site_texts: { text_select },
      },
      productCategory,
    } = this.props;
    return (
      <select
        className="form-control"
        value={product_category ? product_category[0] : ''}
        onChange={(input) => productCategory(input.target.value)}
      >
        <option value="" key="0">
          -- {text_select ? text_select : 'Seleccionar'} --
        </option>
        {categories.map((category) => (
          <option value={category.id} key={category.id}>
            {site_language === 'es' ? category.name : category.english_name}
          </option>
        ))}
      </select>
    );
  };

  save = (e) => {
    e.preventDefault();
    // let priceExpresion = /^[0-9]*$/;
    let {
      productSave,
      openLoading,
      productReducer: {
        product_id,
        product_name,
        product_brand_id,
        product_description,
        product_price,
        product_type,
        product_images,
        product_category,
        product_price_promo,
        product_sku,
        product_is_active,
        product_short_description,
        product_date_promo,
      },
      siteReducer: {
        site_texts: {
          text_product_name,
          text_product_short_description,
          text_product_description,
          text_product_price_error,
          text_product_upload,
          text_product_category,
          text_product_type,
          text_short_description_error,
          text_product_price_promo_error,
          text_product_saving,
          //   text_product_price_minimum,
          //   text_price_error,
          //   text_price_promo_error,
          //   text_product_price_promo_minimum,
        },
      },
    } = this.props;
    if (product_name === '') {
      this.showError(
        text_product_name
          ? text_product_name
          : 'Escribe el nombre del producto o servicio.'
      );
      return;
    }
    if (product_brand_id === '') {
      this.showError(
        text_product_name ? text_product_name : 'Escoge una marca.'
      );
      return;
    }
    if (product_short_description === '') {
      this.showError(
        text_product_short_description
          ? text_product_short_description
          : 'Escribe la descripción corta del producto o servicio.'
      );
      return;
    }
    if (product_short_description.length > 300) {
      this.showError(
        text_short_description_error
          ? text_short_description_error
          : 'La descripción corta no debe tener mas de 300 caracteres.'
      );
      return;
    }
    if (product_description === '') {
      this.showError(
        text_product_description
          ? text_product_description
          : 'Escribe la descripción del producto o servicio.'
      );
      return;
    }
    if (product_price === '') {
      this.showError(
        text_product_price_error
          ? text_product_price_error
          : 'Escribe el precio del producto o servicio.'
      );
      return;
    }
    // if (!priceExpresion.test(product_price)) {
    //   this.showError(
    //     text_price_error
    //       ? text_price_error
    //       : "El precio no debe llevar puntos o comas."
    //   );
    //   return;
    // }
    // if (product_price < 20000) {
    //   this.showError(
    //     text_product_price_minimum
    //       ? `${text_product_price_minimum}: $BAX 20.000`
    //       : "El precio debe ser mínimo de: $BAX 20.000"
    //   );
    //   return;
    // }
    if (product_images.length === 0) {
      this.showError(
        text_product_upload
          ? text_product_upload
          : 'Debes subir mínimo una imagen.'
      );
      return;
    }
    if (product_category === '') {
      this.showError(
        text_product_category
          ? text_product_category
          : 'Debes seleccionar la categoría del producto o servicio.'
      );
      return;
    }
    if (product_type === '') {
      this.showError(
        text_product_type
          ? text_product_type
          : 'Debes seleccionar si es Producto o Servicio.'
      );
      return;
    }
    let data = {
      product_type_id: product_type,
      name: product_name,
      brand_id: product_brand_id,
      short_description: product_short_description,
      description: product_description,
      price: product_price,
      price_promo: '',
      promotion_date: '',
      is_active: product_is_active,
      images: product_images,
      categories: product_category,
      available: '',
    };
    if (product_id !== '') {
      data.id = product_id;
    }
    if (product_price_promo !== '' && product_price_promo !== 0) {
      //   if (!priceExpresion.test(product_price_promo)) {
      //     this.showError(
      //       text_price_promo_error
      //         ? text_price_promo_error
      //         : "El precio promocional no debe llevar puntos o comas."
      //     );
      //     return;
      //   }
      //   if (parseInt(product_price_promo) < 20000) {
      //     this.showError(
      //       text_product_price_promo_minimum
      //         ? `${text_product_price_promo_minimum}: BAX$ 20.000`
      //         : "El precio promocional debe ser mínimo de BAX$ 20.000"
      //     );
      //     return;
      //   }
      if (product_price < parseInt(product_price_promo)) {
        this.showError(
          text_product_price_promo_error
            ? text_product_price_promo_error
            : 'El precio promocional debe ser menor al precio.'
        );
        return;
      }
      if (product_date_promo === '') {
        this.showError(
          text_product_price_promo_error
            ? text_product_price_promo_error
            : 'El precio promocional debe ser menor al precio.'
        );
        return;
      }
      let d = new Date(product_date_promo);
      let year = d.getFullYear();
      let month =
        d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
      let day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
      let date = `${year}-${month}-${day}`;
      data.price_promo = product_price_promo;
      data.promotion_date = date;
    }
    if (product_sku !== '') {
      data.available = product_sku;
    }
    openLoading(
      text_product_saving ? text_product_saving : 'Guardando producto...'
    );

    productSave(data);
  };

  renderTypes = () => {
    const {
      userReducer: { type_products },
      productReducer: { product_type },
      siteReducer: {
        site_language,
        site_texts: { text_select },
      },
      productType,
    } = this.props;
    return (
      <select
        className="form-control"
        value={product_type ? product_type : ''}
        onChange={(input) => productType(input.target.value)}
      >
        <option>-- {text_select ? text_select : 'Seleccionar'} --</option>
        {type_products.map((type) => (
          <option value={type.id} key={type.id}>
            {site_language === 'es' ? type.name : type.english_name}
          </option>
        ))}
      </select>
    );
  };

  success = (data) => {
    window.location.href = `/manager/products/edit/${data.id}`;
  };

  render() {
    const {
      productName,
      productPrice,
      productPricePromo,
      productDescription,
      productSku,
      productIsActive,
      productShortDescription,
      productDatePromo,
      productReducer: {
        product_name,
        product_brand_id,
        product_description,
        product_price,
        product_price_promo,
        product_sku,
        product_is_active,
        product_error,
        product_success,
        product_loading,
        product_detail_error,
        product_short_description,
        product_date_promo,
        product_loading_images,
      },
      userReducer: {
        user_data: { brands },
        user_data: { total_cards },
      },
      siteReducer: {
        site_texts: {
          text_new_product,
          text_edit_2_product,
          text_name,
          text_short_description,
          text_product_description,
          text_product_price,
          text_promotional_price,
          text_promotion_end_date,
          text_product_images,
          text_category,
          text_type,
          text_stocktaking,
          text_status,
          text_product_change_status,
          text_payment_methods,
          text_characters_remaining,
          text_save,
          text_hidden,
          text_public,
          text_images,
          text_uploading_images,
        },
      },
      setProductBrandId,
      productImagesUpload,
    } = this.props;

    const newProductTitle = text_new_product
      ? text_new_product
      : 'Nuevo Producto';
    const editProductTitle = text_edit_2_product
      ? text_edit_2_product
      : 'Editar Producto';
    let title = this.props.codProduct ? editProductTitle : newProductTitle;

    return (
      <Fragment>
        {product_loading && product_detail_error === '' && <TopSpinner />}
        {product_error !== '' && this.showError(product_error)}
        {product_success !== '' && this.success(product_success)}
        <div className="title-manager">
          <div>
            <span className="icon-products" />
            {title}
          </div>
          {this.props.codProduct && (
            <a href="/manager/products/new" className="btn productsView_new">
              <i className="icon-add-car"></i>
              {text_new_product
                ? text_new_product.toUpperCase()
                : 'NUEVO PRODUCTO'}
            </a>
          )}
        </div>
        {!product_loading && product_detail_error !== '' && (
          <h4>{product_detail_error}</h4>
        )}
        {!product_loading && product_detail_error === '' && (
          <form className="product_detail-form">
            <div className="row">
              <div className="col-sm-2">
                {text_name ? text_name : 'Nombre'}:
              </div>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={product_name ? product_name : ''}
                  onChange={(input) => productName(input.target.value)}
                />
              </div>
              <div className="col-sm-2">{'Marca'}:</div>
              <div className="col-sm-10">
                <select
                  className="form-control"
                  value={product_brand_id}
                  onChange={(event) => {
                    setProductBrandId(event.target.value);
                  }}
                >
                  <option value={null}>
                    {brands
                      ? 'Seleccione su marca'
                      : 'No hay marcas disponibles'}
                  </option>
                  {brands &&
                    brands.map((brand) => (
                      <option value={brand.id} key={brand.id}>
                        {`${brand.name}`}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-sm-2">
                {text_short_description
                  ? text_short_description
                  : 'Descripción Corta'}
                :
              </div>
              <div className="col-sm-10">
                <div className="text-right product_detail-input-complement">
                  {text_characters_remaining
                    ? text_characters_remaining
                    : 'Caracteres restantes'}
                  : {300 - product_short_description.length}
                </div>
                <textarea
                  rows="4"
                  className="form-control"
                  value={
                    product_short_description ? product_short_description : ''
                  }
                  onChange={(input) =>
                    productShortDescription(input.target.value)
                  }
                />
              </div>
              <div className="col-sm-2">
                {text_product_description
                  ? text_product_description
                  : 'Descripción'}
                :
              </div>
              <div className="col-sm-10">
                <textarea
                  rows="6"
                  className="form-control"
                  value={product_description ? product_description : ''}
                  onChange={(input) => productDescription(input.target.value)}
                />
              </div>
              <div className="col-sm-2">
                {text_product_price ? text_product_price : 'Precio'}:
              </div>
              <div className="col-sm-10">
                <input
                  type="number"
                  className="form-control"
                  value={product_price ? product_price : ''}
                  onChange={(input) => productPrice(input.target.value)}
                />
              </div>
              <div className="col-sm-2">
                {text_promotional_price
                  ? text_promotional_price
                  : 'Precio Promocional'}
                :
              </div>
              <div className="col-sm-4">
                <input
                  type="number"
                  className="form-control"
                  value={product_price_promo ? product_price_promo : ''}
                  onChange={(input) => productPricePromo(input.target.value)}
                />
              </div>
              <div className="col-sm-2">
                {text_promotion_end_date
                  ? text_promotion_end_date
                  : 'Fecha final promoción'}
                :
              </div>
              <div className="col-sm-4">
                <DatePickerInput
                  locale="en"
                  value={product_date_promo ? product_date_promo : ''}
                  onChange={(value) => productDatePromo(value)}
                  showOnInputClick
                  showInputButton={false}
                />
              </div>
              <div className="col-sm-2">
                {text_product_images ? text_product_images : 'Imágenes'}:
              </div>
              <div className="col-sm-10">
                <input
                  type="file"
                  className="hidden"
                  id="productImage"
                  accept=".jpg, .png, .jpeg"
                  onChange={(input) => productImagesUpload(input.target.files)}
                  multiple="multiple"
                />
                <div className="small">
                  {text_images
                    ? text_images
                    : 'Puedes seleccionar hasta 3 imágenes al tiempo, recuerda que no deben tener un pero mayor a 2MB, comprime las imágenes en https://tinypng.com/'}
                </div>
                <div className="product_detail-prev-images">
                  <div
                    className="product_detail-add centerCenter"
                    onClick={() =>
                      document.getElementById('productImage').click()
                    }
                  >
                    <span className="icon-plus" />
                  </div>
                  <div className="product_detail-box-images">
                    {product_loading_images && (
                      <div>
                        {text_uploading_images
                          ? text_uploading_images
                          : 'Subiendo imágenes...'}
                      </div>
                    )}
                    {this.renderImages()}
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                {text_category ? text_category : 'Categoría'}:
              </div>
              <div className="col-sm-10">{this.renderCategories()}</div>
              <div className="col-sm-2">{text_type ? text_type : 'Tipo'}:</div>
              <div className="col-sm-4">{this.renderTypes()}</div>
              <div className="col-sm-2">
                {text_stocktaking ? text_stocktaking : 'Inventario'}:
              </div>
              <div className="col-sm-4">
                <input
                  type="number"
                  className="form-control"
                  value={product_sku ? product_sku : ''}
                  onChange={(input) => productSku(input.target.value)}
                />
              </div>
              <div className="col-sm-2">
                {text_status ? text_status : 'Estado'}:
              </div>
              <div className="col-sm-10">
                {total_cards ? (
                  <select
                    className="form-control"
                    value={product_is_active ? product_is_active : ''}
                    onChange={(input) => productIsActive(input.target.value)}
                  >
                    <option value="0">
                      {text_hidden ? text_hidden : 'Oculto'}
                    </option>
                    <option value="1">
                      {text_public ? text_public : 'Público'}
                    </option>
                  </select>
                ) : (
                  <p>
                    {text_product_change_status
                      ? text_product_change_status
                      : 'Para cambiar el estado del producto, debes primero seleccionar el método de pago para las comisiones.'}
                    <br />
                    <Link to="/manager/pyment-methods" className="color-purple">
                      {text_payment_methods
                        ? text_payment_methods
                        : 'Métodos de pago'}
                    </Link>
                  </p>
                )}
              </div>
              <div className="col-sm-8 offset-2">
                <button className="btn btn-block btn-pymex" onClick={this.save}>
                  {text_save ? text_save : 'GUARDAR'}
                </button>
              </div>
            </div>
          </form>
        )}
        <CropImages onCrop={this.onCrop} />
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  cropperReducer,
  productReducer,
  userReducer,
  siteReducer,
}) => {
  return {
    cropperReducer,
    productReducer,
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  cropperImage,
  openLoading,
  closeLoading,
  productAddImages,
  productName,
  productDescription,
  productPrice,
  productPricePromo,
  productCategory,
  productType,
  productSku,
  productSave,
  productClearError,
  productIsActive,
  productClear,
  myProducts,
  productId,
  productDetailError,
  productShortDescription,
  productDatePromo,
  productImagesUpload,
  productDeleteImage,
  productGetEdit,
  setProductBrandId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailView);
