import React from 'react';
import defaultImage from '../../../../images/believe.jpg';
import './InfluencerMetricRow.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const InfluencerMetricRow = (props) => {
  const {
    siteReducer: {
      site_language,
      site_texts: { text_view_profile },
    },
    image,
    name,
    lastname,
    city,
    social_networks,
    // rating,
    username,
    id,
    tags,
  } = props;
  const arrayName = site_language === 'es' ? 'english_name' : 'name';

  return (
    <tr className="influencer-metric-row">
      <td>
        <div className="influencer__presentation">
          <img src={image || defaultImage} alt="influencer-img" />
          <span>{username || `${name} ${lastname}`}</span>
        </div>
      </td>
      <td className="influencer__city">
        {city && (
          <>
            {city.name}
            <br />
            <small>
              ({city.department_name}, {city[`country_${arrayName}`]})
            </small>
          </>
        )}
      </td>
      <td style={{ maxWidth: 300 }}>
        <div className="influencer__categories">
          {tags.map((tag) => (
            <div className="category__container" key={tag.id}>
              <h4 className="category__name">{tag[arrayName]}</h4>
            </div>
          ))}
        </div>
      </td>
      <td style={{ maxWidth: 300 }}>
        <div className="influencer__followers">
          {social_networks.map((item) => (
            <div className="media" key={item.id}>
              <div className={`network__circle circle-${item.slug}`}>
                <i className={item.icon} />
              </div>
            </div>
          ))}
        </div>
      </td>
      <td>0</td>
      <td>
        <Link
          className="influencerRow__link__button"
          to={`/influencer/${username || id}`}
        >
          {text_view_profile ? text_view_profile : 'Ver Perfil'}
        </Link>
      </td>
    </tr>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(InfluencerMetricRow);
