import {
  PORPOSAL_CLEAR,
  PORPOSAL_IMAGES,
  PORPOSAL_NAME,
  PORPOSAL_VIDEO,
  PORPOSAL_DELIVERABLES,
  PORPOSAL_PRICE,
  PORPOSAL_LOADING,
  PORPOSAL_SOCIAL_NETWORK,
  PORPOSAL_STATUS_ID,
  PORPOSAL_LIST,
  CURRENT_PORPOSAL,
  SET_PORPOSAL_EDIT,
  PORPOSAL_CONSECUTIVE,
  PORPOSAL_VIDEO_LOADING,
  PORPOSAL_CAMPAIGNS,
  PORPOSAL_CAMPAIGNS_ACCEPTED,
  PORPOSAL_CAMPAIGNS_DECLINED,
  PROPOSAL_TYPE,
  PORPOSAL_LOADING_DETAIL,
} from '../types/porposalTypes';

const INITIAL_STATE = {
  porposal_consecutive: undefined,
  porposal_name: '',
  porposal_images: [],
  porposal_social_networks: [],
  porposal_video: [],
  proposal_status_id: undefined,
  porposal_deliverables: '',
  porposal_price: undefined,
  porposal_loading: true,
  porposal_loading_detail: true,
  porposal_list: [],
  current_porposal: null,
  porposal_video_loading: false,
  porposal_campaigns: [],
  porposal_campaigns_accepted: [],
  porposal_campaigns_declined: [],
  proposal_type: '',
};

const porposalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PORPOSAL_EDIT:
      return {
        ...state,
        porposal_consecutive: action.payload.consecutive,
        porposal_name: action.payload.name,
        porposal_images: action.payload.attachments,
        porposal_social_networks: action.payload.social_networks_ids,
        porposal_video: action.payload.video,
        proposal_status_id: action.payload.proposal_status_id,
        porposal_deliverables: action.payload.deliverables,
        porposal_price: action.payload.price,
        proposal_type: '',
      };
    case PORPOSAL_CLEAR:
      return {
        ...state,
        porposal_consecutive: undefined,
        porposal_name: '',
        porposal_images: [],
        proposal_status_id: undefined,
        porposal_video: '',
        porposal_deliverables: '',
        porposal_social_networks: [],
        porposal_price: undefined,
        porposal_list: [],
      };
    case PORPOSAL_CONSECUTIVE:
      return {
        ...state,
        porposal_consecutive: action.payload,
      };
    case PORPOSAL_NAME:
      return {
        ...state,
        porposal_name: action.payload,
      };
    case PORPOSAL_IMAGES:
      return {
        ...state,
        porposal_images: action.payload,
      };
    case PORPOSAL_SOCIAL_NETWORK:
      return {
        ...state,
        porposal_social_networks: action.payload,
      };
    case PORPOSAL_VIDEO:
      return {
        ...state,
        porposal_video: action.payload,
      };
    case PORPOSAL_STATUS_ID:
      return {
        ...state,
        proposal_status_id: action.payload,
      };
    case PORPOSAL_DELIVERABLES:
      return {
        ...state,
        porposal_deliverables: action.payload,
      };
    case PORPOSAL_PRICE:
      return {
        ...state,
        porposal_price: action.payload,
      };
    case PORPOSAL_LOADING:
      return {
        ...state,
        porposal_loading: action.payload,
      };
    case PORPOSAL_LIST:
      return {
        ...state,
        porposal_list: action.payload,
      };
    case CURRENT_PORPOSAL:
      return {
        ...state,
        current_porposal: action.payload,
      };
    case PORPOSAL_VIDEO_LOADING:
      return {
        ...state,
        porposal_video_loading: action.payload,
      };
    case PORPOSAL_CAMPAIGNS:
      return {
        ...state,
        porposal_campaigns: action.payload,
      };
    case PORPOSAL_CAMPAIGNS_ACCEPTED:
      return {
        ...state,
        porposal_campaigns_accepted: action.payload,
      };
    case PORPOSAL_CAMPAIGNS_DECLINED:
      return {
        ...state,
        porposal_campaigns_declined: action.payload,
      };
    case PROPOSAL_TYPE:
      return {
        ...state,
        proposal_type: action.payload,
      };
    case PORPOSAL_LOADING_DETAIL:
      return {
        ...state,
        porposal_loading_detail: action.payload,
      };
    default:
      return state;
  }
};
export default porposalReducer;
