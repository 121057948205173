import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Api from '../../functionality/Api';
import { changeUserSocialNetworks } from '../../functionality/actions/userActions';
import ModalSocial from './ModalSocial';
import SelectOption from './Components/SelectOption';
const {
  REACT_APP_FACEBOOK_APP: FACEBOOK_APP,
  REACT_APP_FACEBOOK_API: FACEBOOK_API,
} = process.env;

class InstagramButton extends Component {
  state = {
    accessToken: '',
    accounts: [],
    account: null,
    show_modal: false,
    step: 0,
    access_token: null,
  };

  showError = (error) => {
    const { onChangeLoading } = this.props;

    Swal.fire({
      title: 'Upsss!!!',
      text: error,
      type: 'error',
      confirmButtonColor: '#50318B',
    }).then(() => {
      onChangeLoading('');
    });
  };

  componentDidMount() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: FACEBOOK_APP,
        cookie: true,
        xfbml: true,
        version: FACEBOOK_API,
      });

      window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  onLogin = () => {
    const {
      siteReducer: {
        site_texts: {
          text_instagram_question,
          text_facebook_message,
          text_facebook_yes,
          text_facebook_no,
          text_loading_social_data,
        },
      },
      onChangeLoading,
    } = this.props;

    Swal.fire({
      title:
        text_instagram_question ||
        '¿Tienes la cuenta de instagram relacionada a un Fan Page de Facebook?',
      text:
        text_facebook_message || 'Es para validar las métricas de tu cuenta',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#50318B',
      cancelButtonColor: '#d33',
      confirmButtonText: text_facebook_yes || 'Si tengo',
      cancelButtonText: text_facebook_no || 'No tengo',
    }).then((res) => {
      if (res.value) {
        onChangeLoading(
          text_loading_social_data || 'Conectando con la red social...'
        );
        window.FB.getLoginStatus((response) => {
          if (response.status === 'connected') {
            window.FB.logout();
          }
          window.FB.login(
            (response) => {
              if (response.status === 'connected') {
                const {
                  authResponse: { accessToken },
                } = response;
                this.getData(accessToken);
              } else {
                onChangeLoading('');
              }
            },
            {
              scope:
                'pages_show_list,instagram_basic,instagram_manage_insights',
            }
          );
        });
      }
    });
  };

  getData = async (accessToken) => {
    const { onChangeLoading } = this.props;

    try {
      const instagramAccounts = await Api.asyncCallMethod(
        'v1/instagram/get-accounts',
        'POST',
        {
          accessToken,
        }
      );
      if (instagramAccounts.status === 200) {
        const { data } = instagramAccounts.response;
        const currentAccounts = data.filter(
          (item) => item.instagram_business_account
        );
        const accounts = [];

        currentAccounts.forEach((account) => {
          accounts.push(account.instagram_business_account);
        });

        this.setState({
          accessToken,
          accounts,
          account: null,
          show_modal: true,
          step: 1,
        });
        onChangeLoading('');
      }
    } catch (error) {
      console.log('Error getData: ', error);
      onChangeLoading('');
    }
  };

  onSelectAccount = (account) => {
    this.setState({ account });
  };

  getAccountData = async () => {
    const { account, accessToken } = this.state;
    const {
      siteReducer: {
        site_texts: {
          text_loading_social_contents,
          text_social_save,
          text_congratulations,
          text_social_success,
          text_error_social,
        },
      },
      onChangeLoading,
      changeUserSocialNetworks,
      id,
    } = this.props;

    this.onCloseModal();
    onChangeLoading(text_loading_social_contents || 'Trayendo los datos...');

    try {
      const insightsLifetime = await Api.asyncCallMethod(
        'v1/instagram/get-insights-lifetime',
        'POST',
        {
          accessToken,
          accountId: account.id,
        }
      );
      const insightsWeek = await Api.asyncCallMethod(
        'v1/instagram/get-insights-week',
        'POST',
        {
          accessToken,
          accountId: account.id,
        }
      );
      const insightsDays28 = await Api.asyncCallMethod(
        'v1/instagram/get-insights-days-28',
        'POST',
        {
          accessToken,
          accountId: account.id,
        }
      );
      const insightsDay = await Api.asyncCallMethod(
        'v1/instagram/get-insights-day',
        'POST',
        {
          accessToken,
          accountId: account.id,
        }
      );
      const data = {
        social_network_id: id,
        profile: account,
        contents: account.media.data,
        statistics_week: insightsWeek.response.data,
        statistics_days_28: insightsDays28.response.data,
        statistics_day: insightsDay.response.data,
        statistics_lifetime: insightsLifetime.response.data,
      };
      onChangeLoading(text_social_save || 'Guardando información...');

      const satusSave = await Api.asyncCallMethod('v1/instagram', 'POST', data);
      Swal.fire({
        title: text_congratulations || 'Felicitaciones!',
        text: text_social_success || 'Tu cuenta ha sido agregada con éxito',
        type: 'success',
        confirmButtonColor: '#50318B',
      }).then(() => {
        onChangeLoading('');
        changeUserSocialNetworks(satusSave.response.data);
      });
    } catch (error) {
      console.log('Error getAccountData: ', error);
      this.showError(
        text_error_social ||
          'Hubo un error al traer los datos, intentalo más tarde.'
      );
    }
  };

  onCloseModal = () => {
    this.setState({
      show_modal: false,
      step: 0,
      account: null,
    });
  };

  render() {
    const { show_modal, step, accounts, account } = this.state;
    const {
      siteReducer: {
        site_texts: {
          text_instagram_select_pages,
          text_instagram_fan_page_text,
        },
      },
      type,
      icon,
      name,
      isAdded,
    } = this.props;

    if (type === 'register') {
      return (
        <>
          {show_modal && (
            <ModalSocial>
              {step === 1 && (
                <SelectOption
                  options={accounts}
                  itemSelected={account}
                  onSelect={this.onSelectAccount}
                  onContinue={this.getAccountData}
                  onCloseModal={this.onCloseModal}
                  title={text_instagram_select_pages || 'Selecciona tu cuenta'}
                  text={text_instagram_fan_page_text}
                  selectName="username"
                />
              )}
            </ModalSocial>
          )}
          <button
            className="socialNetworks_social-button instagram"
            onClick={this.onLogin}
          >
            <div className="socialNetworks_social">
              <div className="socialNetworks_social-icon">
                <i className={icon} />
              </div>
              {name}
            </div>
            {isAdded && <i className="fa-solid fa-check" />}
          </button>
        </>
      );
    }

    return (
      <div className="socialNetworks_social-button instagram">
        <div className="socialNetworks_social">
          <div className="socialNetworks_social-icon">
            <i className={icon} />
          </div>
          {name}
        </div>
        {isAdded && <i className="fa-solid fa-check" />}
      </div>
    );
  }
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

const mapDispatchToProps = {
  changeUserSocialNetworks,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstagramButton);
