import {
  POST_CONSECUTIVE,
  POST_NAME,
  POST_QUOTATION_CONSECUTIVE,
  POST_STATUS,
  POST_TYPE_ID,
  POST_NETWORKS,
  POST_PHOTOS,
  POST_VIDEO,
  POST_DESCRIPTION,
  POST_LINK,
  CURRENT_POST,
  POST_LIST,
  POST_TOTAL_LIST,
  POST_PAGE,
  POST_TOTAL_PAGES,
  POST_LOADING,
  POST_CLEAR,
  SET_POST_EDIT,
  POST_LIST_LOADING,
  POST_MESSAGE,
  POST_TYPES_LIST,
  POST_STATUS_LIST,
} from '../types/postTypes';

const INITIAL_STATE = {
  post_consecutive: '',
  post_name: '',
  post_quotation_consecutive: null,
  post_status: null,
  post_type_id: '',
  post_networks: [],
  post_photos: [],
  post_video: [],
  post_description: '',
  post_link: null,
  current_post: [],
  posts_list: [],
  post_total_list: 0,
  post_page: 1,
  post_total_pages: 1,
  post_list_loading: true,
  post_loading: false,
  post_message: '',
  post_types_list: [],
  post_status_list: [],
};

const postReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_POST_EDIT:
      return {
        ...state,
        post_consecutive: action.payload.consecutive,
        post_name: action.payload.name,
        post_quotation_consecutive: action.payload.proposal.consecutive,
        post_status: action.payload.post_status_id,
        post_type_id: action.payload.post_type_id,
        post_networks: action.payload.social_networks.map(
          (network) => network.id
        ),
        post_photos:
          action.payload.attachments[0]?.mime_type !== 'video/mp4'
            ? action.payload.attachments
            : [],
        post_video:
          action.payload.attachments[0]?.mime_type === 'video/mp4'
            ? action.payload.attachments[0]
            : [],
        post_description: action.payload.description,
        post_link: action.payload.link,
      };
    case POST_CLEAR:
      return {
        ...state,
        post_consecutive: '',
        post_name: '',
        post_quotation_consecutive: undefined,
        post_status: undefined,
        post_type_id: '',
        post_networks: [],
        post_photos: [],
        post_video: [],
        post_description: '',
        post_link: '',
        current_post: [],
        //   posts_list: [],
        // post_loading: false,
        post_message: '',
      };
    case POST_CONSECUTIVE:
      return {
        ...state,
        post_consecutive: action.payload,
      };
    case POST_NAME:
      return {
        ...state,
        post_name: action.payload,
      };
    case POST_QUOTATION_CONSECUTIVE:
      return {
        ...state,
        post_quotation_consecutive: action.payload,
      };
    case POST_STATUS:
      return {
        ...state,
        post_status: action.payload,
      };
    case POST_TYPE_ID:
      return {
        ...state,
        post_type_id: action.payload,
      };
    case POST_NETWORKS:
      return {
        ...state,
        post_networks: action.payload,
      };
    case POST_PHOTOS:
      return {
        ...state,
        post_photos: action.payload,
      };
    case POST_VIDEO:
      return {
        ...state,
        post_video: action.payload,
      };
    case POST_DESCRIPTION:
      return {
        ...state,
        post_description: action.payload,
      };
    case POST_LINK:
      return {
        ...state,
        post_link: action.payload,
      };
    case CURRENT_POST:
      return {
        ...state,
        current_post: action.payload,
      };
    case POST_LIST:
      return {
        ...state,
        posts_list: action.payload,
      };
    case POST_TOTAL_LIST:
      return {
        ...state,
        post_total_list: action.payload,
      };
    case POST_PAGE:
      return {
        ...state,
        post_page: action.payload,
      };
    case POST_TOTAL_PAGES:
      return {
        ...state,
        post_total_pages: action.payload,
      };
    case POST_LOADING:
      return {
        ...state,
        post_loading: action.payload,
      };
    case POST_LIST_LOADING:
      return {
        ...state,
        post_list_loading: action.payload,
      };
    case POST_MESSAGE:
      return {
        ...state,
        post_message: action.payload,
      };
    case POST_TYPES_LIST:
      return {
        ...state,
        post_types_list: action.payload,
      };
    case POST_STATUS_LIST:
      return {
        ...state,
        post_status_list: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default postReducer;
