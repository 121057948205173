import React from 'react';
import { connect } from 'react-redux';
import { changeAdCategoryID } from '../../../../functionality/actions/adActions';

const { REACT_APP_LANGUAGE: APP_LANGUAGE } = process.env;

function SelectCategories(props) {
  const {
    userReducer: { activities, categories },
    adReducer: { ad_space_id, ad_category_id },
    changeAdCategoryID,
    siteReducer: {
      site_texts: { text_select },
    },
  } = props;

  const options = ad_space_id !== 6 ? categories : activities;

  return (
    <>
      <div>
        {text_select ? text_select : 'Seleccionar'}{' '}
        {ad_space_id.id !== 6 ? 'categoría' : 'actividad'}:
      </div>
      <select
        className="form-control"
        value={ad_category_id}
        onChange={(input) => changeAdCategoryID(input.target.value)}
      >
        <option>-- {text_select ? text_select : 'Seleccionar'} --</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {APP_LANGUAGE === 'es' ? option.name : option.english_name}
          </option>
        ))}
      </select>
    </>
  );
}

const mapStateToProps = ({ adReducer, userReducer, siteReducer }) => {
  return {
    adReducer,
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  changeAdCategoryID,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectCategories);
