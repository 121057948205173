import React, { useState, useEffect } from 'react';
import InputGroup from '../../../../../../../components/all/InputGroup/InputGroup';
import OptionCheckboxImage from '../../../../../../../components/all/OptionCheckboxImage/OptionCheckboxImage';
import UploadImage from '../../../../../../../components/all/UploadImage/UploadImage';
import InputTextArea from '../../../../../../../components/all/InputTextArea/InputTextArea';
import UploadVideo from '../../../../../../../components/all/UploadVideo/UploadVideo';
import { connect } from 'react-redux';
import {
  setPostName,
  setPostQuotation,
  setPostTypeId,
  setPostDescription,
  setPostLink,
  setPostNetworks,
  addPostPhoto,
  deletePostPhoto,
  setPostVideo,
  addPostVideo,
  sendPost,
  setPostPhotos,
  postClear,
  editPost,
  getByCampaignAndUserAll,
  changeStatusPost,
  getPostTypes,
  deleteAttachmentVideo,
} from '../../../../../../../functionality/actions/postActions';
import {
  getPorposalsByCampaign,
  porposalClear,
} from '../../../../../../../functionality/actions/porposalActions';
import Spinner from '../../../../../../../components/all/Spinner';
import { withRouter } from 'react-router';

const CampaignPostForm = (props) => {
  const [isPhoto, setIsPhoto] = useState('');

  const {
    postReducer: {
      post_consecutive,
      post_name,
      post_quotation_consecutive,
      post_status,
      post_type_id,
      post_networks,
      post_photos,
      post_video,
      post_description,
      post_link,
      post_loading,
      post_types_list,
    },
    campaignReducer: { campaign_current_detail },
    porposalReducer: { porposal_list },
    handleCloseModal,
    setPostName,
    setPostQuotation,
    setPostTypeId,
    setPostDescription,
    setPostLink,
    setPostNetworks,
    getPorposalsByCampaign,
    addPostPhoto,
    deletePostPhoto,
    setPostVideo,
    addPostVideo,
    sendPost,
    setPostPhotos,
    postClear,
    editPost,
    getByCampaignAndUserAll,
    changeStatusPost,
    match: {
      params: { id: campaign_consecutive },
    },
    getPostTypes,
    deleteAttachmentVideo,
    siteReducer: {
      site_texts: {
        text_add_post_to_campaign,
        text_quote,
        text_type_of_post,
        text_post_networks,
        text_photo,
        text_back,
        text_save,
        text_save_changes,
        text_send,
        text_post_name,
        text_select_quotation,
        text_select_post_type,
        text_give_name_post,
        text_no_post_available,
        text_no_quotation_available,
        text_description_post,
        text_publish_link,
        text_or,
      },
    },
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      if (porposal_list.length === 0) {
        await getPorposalsByCampaign(campaign_current_detail.consecutive);
      }
      if (post_types_list.length === 0) {
        getPostTypes();
      }
      if (post_video.length !== 0) {
        setIsPhoto(false);
      }
      if (post_photos.length !== 0) {
        setIsPhoto(true);
      }
    };

    fetchData();
  }, [getPorposalsByCampaign]);

  useEffect(() => {
    return () => {
      postClear();
    };
  }, []);

  let quotationsAccepted;

  if (porposal_list.length !== 0) {
    quotationsAccepted = porposal_list.filter(
      (proposal) => proposal.proposal_status_id === 4
    );
  }

  const clearMedia = () => {
    setPostPhotos([]);
    setPostVideo([]);
  };

  return (
    <>
      {post_loading ? (
        <div className="spinner-loading">
          <Spinner />
        </div>
      ) : (
        <div className="modal__create__post">
          <div className="create__post__form">
            <h3>{text_add_post_to_campaign || 'Agregar post a campaña'}</h3>
            <button className="close__btn" onClick={handleCloseModal}>
              <i className="fa-solid fa-circle-xmark" />
            </button>
            <InputGroup
              isRequired={false}
              label={text_post_name || 'Nombre del Post'}
              name={'post__name'}
              type={'text'}
              placeHolder={text_give_name_post || '¡Dale un nombre a tu post!'}
              value={post_name}
              handleInputChange={setPostName}
            />
            <div className="input__group">
              <label>{text_quote || 'Cotizacion'}*</label>
              <select
                className="form-control"
                value={post_quotation_consecutive || ''}
                onChange={(event) => {
                  setPostQuotation(event.target.value);
                }}
              >
                <option value={null}>
                  {quotationsAccepted
                    ? text_select_quotation || 'Seleccione su cotización'
                    : text_no_quotation_available ||
                      'No hay cotización disponibles'}
                </option>
                {quotationsAccepted &&
                  quotationsAccepted.map((quotation) => (
                    <option value={quotation.consecutive} key={quotation.id}>
                      {`${quotation.name}`}
                    </option>
                  ))}
              </select>
            </div>
            <div className="input__group">
              <label>{text_type_of_post || 'Tipo de post'}*</label>
              <select
                className="form-control"
                value={post_type_id}
                onChange={(event) => {
                  setPostTypeId(event.target.value);
                }}
              >
                <option value={null}>
                  {post_types_list.length !== 0
                    ? text_select_post_type || 'Seleccione su tipo de post'
                    : text_no_post_available || 'No hay posts disponibles'}
                </option>
                {post_types_list &&
                  post_types_list.map((post) => (
                    <option value={post.id} key={post.id}>
                      {`${post.name}`}
                    </option>
                  ))}
              </select>
            </div>
            <div className="campaign__block">
              <h3 className="block__title">
                {text_post_networks || 'Redes para el Post'}
              </h3>
              <div className="row">
                <>
                  {campaign_current_detail &&
                    campaign_current_detail.social_networks.map((network) => {
                      if (!network.in_campaign) return null;
                      return (
                        <div
                          className={`col-md-4 circulo-red-social circulo-${network.slug}`}
                          key={network.id}
                        >
                          <OptionCheckboxImage
                            icon={network.icon}
                            label={network.name}
                            value={network.id}
                            checked={post_networks.includes(network.id)}
                            handleInputChange={setPostNetworks}
                          />
                        </div>
                      );
                    })}
                </>
              </div>
            </div>
            <div>
              {isPhoto === '' && <h4 className="block__title">Multimedia</h4>}

              {isPhoto === '' && (
                <div className="photo__video">
                  <button
                    onClick={() => {
                      setIsPhoto(true);
                    }}
                  >
                    {text_photo || 'Foto'}
                  </button>
                  <span> {text_or || 'O'} </span>
                  <button
                    onClick={() => {
                      setIsPhoto(false);
                    }}
                  >
                    Video
                  </button>
                </div>
              )}

              {isPhoto && (
                <div className="upload__attatchment">
                  <UploadImage
                    porposal={true}
                    stateToUpdate={post_photos}
                    updateMethod={addPostPhoto}
                    deleteMethod={deletePostPhoto}
                  />
                  <button
                    className="back_btn"
                    onClick={() => {
                      setIsPhoto('');
                      clearMedia();
                    }}
                  >
                    {text_back || 'Volver'}
                  </button>
                </div>
              )}
              {isPhoto === false && (
                <div className="upload__attatchment">
                  <UploadVideo
                    stateToUpdate={post_video}
                    updateMethod={addPostVideo}
                    deleteMethod={deleteAttachmentVideo}
                    editView
                  />
                  <button
                    className="back_btn"
                    onClick={() => {
                      setIsPhoto('');
                      clearMedia();
                    }}
                  >
                    {text_back || 'Volver'}
                  </button>
                </div>
              )}
            </div>
            <InputTextArea
              isRequired
              label={text_description_post || 'Descripcion del post'}
              name={'description__post'}
              defaultValue={post_description}
              handleInputChange={setPostDescription}
            />
            {post_status === 4 && (
              <InputGroup
                isRequired={true}
                label={text_publish_link || 'Link Publicado'}
                name={'publish_link'}
                type={'text'}
                value={post_link}
                handleInputChange={setPostLink}
              />
            )}
          </div>
          <div className="modal__actions__btns">
            {!post_consecutive && (
              <button
                onClick={async () => {
                  await sendPost();
                  getByCampaignAndUserAll(campaign_consecutive);
                }}
              >
                {text_save || 'Guardar'}
              </button>
            )}
            {(post_status === 1 || post_status === 3) && (
              <button
                onClick={async () => {
                  await editPost(post_consecutive);
                  getByCampaignAndUserAll(campaign_consecutive);

                  handleCloseModal();
                }}
              >
                {text_save_changes || 'Guardar Cambios'}
              </button>
            )}
            {(post_status === 1 || post_status === 3) && (
              <button
                onClick={async () => {
                  await editPost(post_consecutive);
                  await changeStatusPost(post_consecutive, 2);
                  getByCampaignAndUserAll(campaign_consecutive);

                  handleCloseModal();
                }}
              >
                {text_send || 'Enviar'}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({
  postReducer,
  porposalReducer,
  campaignReducer,
  siteReducer,
}) => {
  return { postReducer, porposalReducer, campaignReducer, siteReducer };
};

const mapDispatchToProps = {
  setPostName,
  setPostQuotation,
  setPostTypeId,
  setPostDescription,
  setPostLink,
  setPostNetworks,
  getPorposalsByCampaign,
  porposalClear,
  addPostPhoto,
  deletePostPhoto,
  setPostVideo,
  addPostVideo,
  sendPost,
  setPostPhotos,
  postClear,
  editPost,
  getByCampaignAndUserAll,
  changeStatusPost,
  getPostTypes,
  deleteAttachmentVideo,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignPostForm)
);
