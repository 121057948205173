import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userFavoriteCompany } from "../../../functionality/actions/userActions";
import ImageCompany from "../../../images/believe.jpg";
import Rating from "./Rating";
import "./css/SlideCompany.css";

function SlideCompany(props) {
  let {
    data,
    userReducer: { favorite_companies },
    userFavoriteCompany,
  } = props;
  let image = data.image && data.image !== "" ? data.image : ImageCompany;
  let link =
    data.username && data.username !== ""
      ? `/${data.username}`
      : `/company/${data.id}`;
  let name = data.username ? data.username : data.business_name;
  let isFavorite = favorite_companies.find(
    (item) => item.company_id === data.id
  );
  isFavorite = isFavorite ? true : false;

  return (
    <Link to={link} className="slideCompany_container">
      <button
        onClick={(e) => {
          e.preventDefault();
          userFavoriteCompany(data.id);
        }}
        className="slideCompany_like"
      >
        {isFavorite ? (
          <span className="icon-like-selected" />
        ) : (
          <span className="icon-like" />
        )}
      </button>
      <img src={image} alt={data.name} className="slideCompany_image" />
      <div className="slideCompany_rating">
        <Rating value={data.rating} />
      </div>
      <div className="slideCompany_name">{name.toUpperCase()}</div>
    </Link>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

const mapDispatchToProps = {
  userFavoriteCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(SlideCompany);
