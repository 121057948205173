import React, { Component } from "react";
import "./css/Pagination.css";

class Pagination extends Component {
  renderPages = (totalPages, currentPage, changePage) => {
    let html = [];
    let initPage = currentPage > 2 ? currentPage - 2 : 1;
    let maxPage = currentPage < totalPages - 2 ? currentPage + 2 : totalPages;

    for (let i = initPage; i <= maxPage; i++) {
      let isActive = currentPage === i ? "active" : "";
      let button = (
        <button
          key={i}
          className={isActive}
          onClick={() => this.handleChangePage(i)}
        >
          {i}
        </button>
      );
      html.push(button);
    }

    return html;
  };

  handleNextPage = () => {
    let { currentPage, data, itemsPerPage } = this.props;
    let totalPage = Math.ceil(data.length / itemsPerPage);
    if (currentPage < totalPage) {
      currentPage++;
      this.handleChangePage(currentPage);
    }
  };

  handlePrevPage = () => {
    let { currentPage } = this.props;
    if (currentPage > 1) {
      currentPage--;
      this.handleChangePage(currentPage);
    }
  };

  handleChangePage = (currentPage) => {
    window.scrollTo(0, 0);
    let { itemsPerPage } = this.props;
    let initView = (currentPage - 1) * itemsPerPage;
    let endView = currentPage * itemsPerPage;
    this.props.changePage(currentPage, initView, endView);
  };

  render() {
    let { data, itemsPerPage, currentPage } = this.props;
    let totalData = data.length;
    let totalPages = Math.ceil(totalData / itemsPerPage);
    if (totalData <= itemsPerPage) {
      return null;
    }
    return (
      <div className="pagination_container">
        <button onClick={this.handlePrevPage}>
          <span className="fa fa-chevron-left" />
        </button>
        {currentPage > 3 && (
          <button onClick={() => this.handleChangePage(1)}>1</button>
        )}
        {currentPage > 4 && <button>...</button>}
        {this.renderPages(totalPages, currentPage)}
        {totalPages > 2 && currentPage < totalPages - 3 && <button>...</button>}
        {totalPages > 2 && currentPage < totalPages - 2 && (
          <button onClick={() => this.handleChangePage(totalPages)}>
            {totalPages}
          </button>
        )}
        <button onClick={this.handleNextPage}>
          <span className="fa fa-chevron-right" />
        </button>
      </div>
    );
  }
}

export default Pagination;
