import React from 'react';
import ManagerLayout from './layouts/ManagerLayout';
import CampaignDetailView from './views/CampaignDetailView/CampaignDetailView';
import CampaignPostsView from './views/CampaignDetailView/CampaignPosts/CampaignPostsView';

const CampaignPostsPage = () => {
  return (
    <ManagerLayout>
      <CampaignDetailView>
        <CampaignPostsView />
      </CampaignDetailView>
    </ManagerLayout>
  );
};

export default CampaignPostsPage;
