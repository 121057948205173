import {
  PAYMENT_METHOD,
  PAYMENT_OPEN_MODAL,
  PAYMENT_CLOSE_MODAL,
  PAYMENT_CASH_TYPE,
  PAYMENT_CASH_VALUE,
  PAYMENT_LOADING,
  PAYMENT_CLOSE_LOADING,
  PAYMENT_NUMBER,
  PAYMENT_EXP_MONTH,
  PAYMENT_EXP_YEAR,
  PAYMENT_CVC,
  PAYMENT_DUES,
  PAYMENT_HEADLINE,
  PAYMENT_LIST_CARDS,
  PAYMENT_LOADED,
  PAYMENT_ERROR,
  PAYMENT_CLEAR,
  PAYMENT_LIST,
  PAYMENT_EDIT_CARD,
  PAYMENT_DEFAULT,
  PAYMENT_BANKS_PSE,
  PAYMENT_BANKS_ERROR,
  PAYMENT_CASH_SELECT,
  PAYMENT_PSE_BANK,
  PAYMENT_NAME,
  PAYMENT_LASTNAME,
  PAYMENT_DOC_TYPE,
  PAYMENT_DOC_NUMBER,
} from "../types/paymentTypes";
import Api from "../Api";
import { USER_DATA } from "../types/userTypes";

export const changePaymentMethod = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_METHOD,
    payload: value,
  });
};

export const savePaymentMethod = (value) => (dispatch) => {
  let data = {
    payment_method: value,
  };
  Api.callMethod("v1/users/update", "PUT", data, function (res) {
    if (res.status === 200) {
      // localStorage.setItem(
      //   "bax_user_data",
      //   JSON.stringify({
      //     token: res.response.data.token,
      //   })
      // );
      dispatch({
        type: USER_DATA,
        payload: res.response.data,
      });
    }
  });
  dispatch({
    type: PAYMENT_METHOD,
    payload: value,
  });
};

export const changePaymentNumber = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_NUMBER,
    payload: value,
  });
};

export const changePaymentMonth = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_EXP_MONTH,
    payload: value,
  });
};

export const changePaymentYear = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_EXP_YEAR,
    payload: value,
  });
};

export const changePaymentCVC = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_CVC,
    payload: value,
  });
};

export const changePaymentDues = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_DUES,
    payload: value,
  });
};

export const changePaymentHeadline = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_HEADLINE,
    payload: value,
  });
};

export const openPaymentModal = (type) => (dispatch, getState) => {
  const { user_data } = getState().userReducer;
  document.body.style.overflow = "hidden";
  dispatch({
    type: PAYMENT_NAME,
    payload: user_data.name,
  });
  dispatch({
    type: PAYMENT_LASTNAME,
    payload: user_data.lastname,
  });
  dispatch({
    type: PAYMENT_DOC_TYPE,
    payload: user_data.doc_type,
  });
  dispatch({
    type: PAYMENT_DOC_NUMBER,
    payload: user_data.doc_number,
  });
  dispatch({
    type: PAYMENT_OPEN_MODAL,
    payload: type,
  });
};

export const closePaymentModal = () => (dispatch) => {
  document.body.style.overflow = "";
  dispatch({
    type: PAYMENT_CLOSE_MODAL,
  });
};

export const cashPaymentType = (type) => (dispatch) => {
  dispatch({
    type: PAYMENT_CASH_TYPE,
    payload: type,
  });
};

export const cashPaymentValue = (type) => (dispatch) => {
  dispatch({
    type: PAYMENT_CASH_VALUE,
    payload: type,
  });
};

export const paymentClear = () => (dispatch) => {
  dispatch({
    type: PAYMENT_CLEAR,
  });
};

export const paymentEditCard = (data) => (dispatch) => {
  dispatch({
    type: PAYMENT_EDIT_CARD,
    payload: data,
  });
};

export const changePaymentDefault = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_DEFAULT,
    payload: value,
  });
};

export const saveCash = (data) => (dispatch, getState) => {
  const {
    site_texts: {
      text_generating_receipt,
      text_receipt_successfully,
      text_sorry,
    },
  } = getState().siteReducer;

  dispatch({
    type: PAYMENT_LOADING,
    payload: text_generating_receipt
      ? text_generating_receipt
      : "Generando recibo...",
  });
  Api.callMethod(
    "v1/pay/cash",
    "POST",
    data,
    function (res) {
      if (res.status === 200) {
        // localStorage.setItem(
        //   "bax_user_data",
        //   JSON.stringify({
        //     token: res.response.data.token,
        //   })
        // );
        dispatch({
          type: PAYMENT_CLOSE_LOADING,
          payload: text_receipt_successfully
            ? text_receipt_successfully
            : "El recibo fue enviado con éxito, recuerda que los datos de pago son los mismos del usuario registrado y una vez cancelado en un plazo máximo de una hora se vera reflejado en tu saldo.",
        });
      } else {
        dispatch({
          type: PAYMENT_CLOSE_LOADING,
          payload: text_sorry ? text_sorry : "Lo sentimos, hubo un error.",
        });
      }
    },
    function (err) {
      dispatch({
        type: PAYMENT_CLOSE_LOADING,
        payload: text_sorry ? text_sorry : "Lo sentimos, hubo un error.",
      });
    }
  );
};

export const saveCard = (data) => (dispatch, getState) => {
  const {
    site_texts: { text_saving_card, text_card_saved, text_sorry },
  } = getState().siteReducer;
  dispatch({
    type: PAYMENT_LOADING,
    payload: text_saving_card ? text_saving_card : "Guardando tarjeta...",
  });
  Api.callMethod(
    "v1/cards",
    "POST",
    data,
    function (res) {
      if (res.status === 201) {
        dispatch({
          type: PAYMENT_LIST_CARDS,
          payload: res.response.cards,
        });
        dispatch({
          type: PAYMENT_CLOSE_LOADING,
          payload: text_card_saved
            ? text_card_saved
            : "La tarjeta de guardo con éxito.",
        });
      } else {
        dispatch({
          type: PAYMENT_CLOSE_LOADING,
          payload: text_sorry ? text_sorry : "Lo sentimos, hubo un error.",
        });
      }
    },
    function (err) {
      console.log(err);
      dispatch({
        type: PAYMENT_CLOSE_LOADING,
        payload: text_sorry ? text_sorry : "Lo sentimos, hubo un error.",
      });
    }
  );
};

export const paymentList = () => (dispatch, getState) => {
  const {
    site_texts: { text_sorry },
  } = getState().siteReducer;

  dispatch({
    type: PAYMENT_LOADED,
  });
  Api.callMethod(
    "v1/cards",
    "GET",
    null,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: PAYMENT_LIST,
          payload: res.response,
        });
      } else {
        dispatch({
          type: PAYMENT_ERROR,
          payload: text_sorry ? text_sorry : "Lo sentimos, hubo un error.",
        });
      }
    },
    function (err) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: text_sorry ? text_sorry : "Lo sentimos, hubo un error.",
      });
    }
  );
};

export const paymentCardUpdate = (data) => (dispatch, getState) => {
  const {
    site_texts: { text_sorry, text_saving_card, text_card_saved },
  } = getState().siteReducer;

  dispatch({
    type: PAYMENT_LOADING,
    payload: text_saving_card ? text_saving_card : "Guardando tarjeta...",
  });
  Api.callMethod(
    `v1/cards/${data.id_card}`,
    "PUT",
    data,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: PAYMENT_LIST_CARDS,
          payload: res.response.cards,
        });
        dispatch({
          type: PAYMENT_CLOSE_LOADING,
          payload: text_card_saved
            ? text_card_saved
            : "La tarjeta de guardo con éxito.",
        });
      } else {
        dispatch({
          type: PAYMENT_ERROR,
          payload: text_sorry ? text_sorry : "Lo sentimos, hubo un error.",
        });
      }
    },
    function (err) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: text_sorry ? text_sorry : "Lo sentimos, hubo un error.",
      });
    }
  );
};

export const paymentRemoveCard = (id) => (dispatch, getState) => {
  const {
    site_texts: { text_sorry },
  } = getState().siteReducer;

  Api.callMethod(
    `v1/cards/${id}`,
    "DELETE",
    null,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: PAYMENT_LIST_CARDS,
          payload: res.response.cards,
        });
      }
    },
    function (err) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: text_sorry ? text_sorry : "Lo sentimos, hubo un error.",
      });
    }
  );
};

export const listBanksPSE = () => (dispatch, getState) => {
  const {
    site_texts: { text_pse_no_charge },
  } = getState().siteReducer;

  fetch(
    "https://secure.payco.co/restpagos/pse/bancos.json?public_key=c289bf24b86aeadf6009ce70466b66c2"
  )
    .then(function (res) {
      res.json().then(function (response) {
        dispatch({
          type: PAYMENT_BANKS_PSE,
          payload: response.data,
        });
      });
    })
    .catch(function (err) {
      dispatch({
        type: PAYMENT_BANKS_ERROR,
        payload: text_pse_no_charge
          ? text_pse_no_charge
          : "No se puede cargar PSE, vuelve a intentarlo más tarde.",
      });
    });
};

export const chageSelectCash = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_CASH_SELECT,
    payload: value,
  });
};

export const changePSEBank = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_PSE_BANK,
    payload: value,
  });
};

export const changePaymentName = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_NAME,
    payload: value,
  });
};

export const changePaymentLastname = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_LASTNAME,
    payload: value,
  });
};

export const changePaymentDocType = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_DOC_TYPE,
    payload: value,
  });
};

export const changePaymentDocNumber = (value) => (dispatch) => {
  dispatch({
    type: PAYMENT_DOC_NUMBER,
    payload: value,
  });
};

export const savePSE = (data) => (dispatch, getState) => {
  const {
    site_texts: { text_processing_pse, text_sorry },
  } = getState().siteReducer;

  dispatch({
    type: PAYMENT_LOADING,
    payload: text_processing_pse ? text_processing_pse : "Procesando PSE...",
  });
  Api.callMethod(
    "v1/pay/pse",
    "POST",
    data,
    function (res) {
      if (res.status === 200) {
        window.location.href = res.response.data.url_redirect;
      } else {
        dispatch({
          type: PAYMENT_ERROR,
          payload: text_sorry ? text_sorry : "Lo sentimos, hubo un error.",
        });
      }
    },
    function (err) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: text_sorry ? text_sorry : "Lo sentimos, hubo un error.",
      });
    }
  );
};
