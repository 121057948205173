import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../../../components/all/Spinner';
import AdList from '../../../../components/user/manager/Ads/AdList';
import BoxWhite from '../../../../components/user/marketplace/BoxWhite';

class AdsView extends Component {
  render() {
    const {
      adReducer: { ad_my_list_loading },
      siteReducer: {
        site_texts: { text_advertising_campaigns },
      },
    } = this.props;

    return (
      <>
        <div className="title-manager">
          <div>
            <span className="icon-ad" />
            {text_advertising_campaigns || 'Campañas Publicitarias'}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <BoxWhite marginTop={-20}>
              {ad_my_list_loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner type="purple" />
                </div>
              ) : (
                <AdList />
              )}
            </BoxWhite>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ adReducer, siteReducer }) => {
  return {
    adReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(AdsView);
