import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Youtube from './NetworkPosts/Youtube';
import Facebook from './NetworkPosts/Facebook';
import Instagram from './NetworkPosts/Instagram';
import Linkedin from './NetworkPosts/Linkedin';
import TikTok from './NetworkPosts/TikTok';
import Twitter from './NetworkPosts/Twitter';
import './RecentPost.css';

const RecentPost = (props) => {
  const {
    data,
    updatedAt,
    slug,
    profile,
    image,
    siteReducer: {
      site_texts: { text_recent_posts },
    },
  } = props;

  const renderContents = () => {
    switch (slug) {
      case 'facebook':
        return <Facebook data={data} profile={profile} image={image} />;
      case 'instagram':
        return <Instagram data={data} profile={profile} image={image} />;
      case 'linkedin':
        return <Linkedin data={data} />;
      case 'tiktok':
        return (
          <TikTok
            data={data}
            returnUrl={`${process.env.REACT_APP_SITE_ROUTE}manager/profile`}
          />
        );
      case 'twitter':
        return <Twitter data={data} profile={profile} />;
      case 'youtube':
        return <Youtube data={data} />;
      default:
        return null;
    }
  };

  const daysSinceLastUpdate = () => {
    if (!updatedAt) return null;

    const date = moment(updatedAt);
    const currentDate = moment(new Date());
    const days = currentDate.diff(date, 'days');
    let response = 'Última actualización:';

    if (days > 0) {
      response = `${response} ${days} día${days > 1 ? 's' : ''}`;
    } else {
      response = `${response} hoy`;
    }

    return <span> {response}</span>;
  };

  return (
    <div className="recent__posts">
      <div className="recent__posts__title">
        <h3>{text_recent_posts ? text_recent_posts : 'Post recientes'}</h3>
        {daysSinceLastUpdate()}
      </div>
      <div className="recent__posts-contents">{renderContents()}</div>
    </div>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(RecentPost);
