import {
  DASHBOARD_LOADING,
  DASHBOARD_ERROR,
  DASHBOARD_DATA,
  DASHBOARD_CLEAR,
} from "../types/dashboardType";
import Api from "../Api";

export const clearDashboard = () => (dispatch) => {
  dispatch({
    type: DASHBOARD_CLEAR,
  });
};

export const dataDashboard = () => (dispatch, getState) => {
  const { site_language } = getState().siteReducer;

  dispatch({
    type: DASHBOARD_LOADING,
  });
  Api.callMethod(
    `v1/users/dashboard?language=${site_language}`,
    "GET",
    null,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: DASHBOARD_DATA,
          payload: res.response.graph_bar,
        });
      } else {
        dispatch({
          type: DASHBOARD_ERROR,
        });
      }
    },
    function (err) {
      dispatch({
        type: DASHBOARD_ERROR,
      });
    }
  );
};
