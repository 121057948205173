import React from 'react';

function Header(props) {
  const {
    month,
    year,
    months,
    prevMonth,
    nextMonth,
    fullName,
    days,
    currentDay,
    currentMonth,
    currentYear,
    currentDate,
    getCurrentDate,
  } = props;
  const monthName = months[month];

  return (
    <div className="calendar_header">
      <button onClick={prevMonth}>
        <i className="fa fa-chevron-left" />
      </button>
      <div>
        <div className="calendar_title">
          {monthName.substr(0, fullName ? monthName.length : 3)}
        </div>
        <div className="calendar_year">{year}</div>
        <div className="calendar_current-date" onClick={getCurrentDate}>
          {currentDay && `${days[currentDay].substr(0, 3)}, `}
          {months[currentMonth].substr(0, 3)} {`${currentDate}`.padStart(2, 0)}{' '}
          {currentYear}
        </div>
      </div>
      <button onClick={nextMonth}>
        <i className="fa fa-chevron-right" />
      </button>
    </div>
  );
}

export default Header;
