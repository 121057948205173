import { TARGETS, TARGETS_LOADING, TARGETS_USER } from '../types/targetTypes';

const INITIAL_STATE = {
  targets_loading: false,
  targets: [],
  targets_user: [],
};

const targetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TARGETS_LOADING:
      return {
        ...state,
        targets_loading: action.payload,
      };
    case TARGETS:
      return {
        ...state,
        targets: action.payload,
      };
    case TARGETS_USER:
      return {
        ...state,
        targets_user: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default targetReducer;
