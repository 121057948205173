import Api from "../Api";
import {
  REFERRED_CURRENT_PAGE,
  REFERRED_LIST,
  REFERRED_LIST_VIEW,
  REFERRED_LOADING,
  REFERRED_TOTAL,
} from "../types/ReferredTypes";

export const changeReferredLoading = (payload) => (dispatch) => {
  dispatch({
    type: REFERRED_LOADING,
    payload,
  });
};

export const referredChangePage =
  (current_page, initView, endView) => (dispatch, getState) => {
    const { referred_list } = getState().referredReducer;
    let list_view = referred_list.slice(initView, endView);

    dispatch({
      type: REFERRED_LIST_VIEW,
      payload: list_view,
    });
    dispatch({
      type: REFERRED_CURRENT_PAGE,
      payload: current_page,
    });
  };

export const getReferrals = () => (dispatch) => {
  dispatch(changeReferredLoading(true));

  Api.callMethod(
    "v1/users/referrals",
    "get",
    null,
    function (res) {
      if (res.status === 200) {
        const { data, total } = res.response;

        dispatch({
          type: REFERRED_LIST,
          payload: data,
        });
        dispatch({
          type: REFERRED_LIST_VIEW,
          payload: data.slice(0, 12),
        });
        dispatch({
          type: REFERRED_TOTAL,
          payload: total,
        });
      }
      dispatch(changeReferredLoading(false));
    },
    function (err) {
      console.log("Error getReferrals: ", err);
      dispatch(changeReferredLoading(false));
    }
  );
};
