import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./css/PaymentMethodItem.css";

const MySwal = withReactContent(Swal);

function PaymentMethodItem(props) {
  const {
    data: { is_default, id, dues },
    paymentReducer: { payment_list_cards },
    siteReducer: {
      site_texts: { text_are_you_sure, text_remove_card, text_yes_deleted },
    },
    paymentEditCard,
    paymentRemoveCard,
  } = props;
  let status = is_default === 1 ? "Yes" : "No";

  return (
    <tr className="paymentMethodItem_row">
      <td>**** **** **** {props.data.card_number}</td>
      <td>{props.data.name.replace("-", " ").toUpperCase()}</td>
      <td className="text-center">{props.data.dues}</td>
      <td className="text-center">{status}</td>
      <td className="text-center">
        <div className="d-flex justify-content-center">
          <button
            className="paymentMethodItem_btn"
            onClick={() =>
              paymentEditCard({
                id_card: id,
                dues: dues,
                is_default: is_default,
                type: "edit-card",
              })
            }
          >
            <span className="icon-edit" />
          </button>
          {!is_default && payment_list_cards.length && (
            <button
              className="paymentMethodItem_btn"
              onClick={() =>
                MySwal.fire({
                  title: text_are_you_sure
                    ? text_are_you_sure
                    : "¿Está seguro?",
                  text: text_remove_card
                    ? text_remove_card
                    : "Vas a boorar la terjeta",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#50318B",
                  cancelButtonColor: "#d33",
                  confirmButtonText: text_yes_deleted
                    ? text_yes_deleted
                    : "Si, borrar",
                }).then((res) => {
                  if (res.value) {
                    paymentRemoveCard(id);
                  }
                })
              }
            >
              <span className="icon-remove" />
            </button>
          )}
        </div>
      </td>
    </tr>
  );
}

const mapStateToProps = ({ paymentReducer, siteReducer }) => {
  return {
    paymentReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(PaymentMethodItem);
