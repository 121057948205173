import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import * as userAction from "../../../functionality/actions/userActions";

import FormLayout from "./layouts/FormLayout";
import { emailExpression } from "../../../utils/globalConst";

const MySwal = withReactContent(Swal);

class ForgotPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusSearch: false,
    };
  }

  componentWillUnmount() {
    this.props.userClear();
  }

  showError = (message) => {
    MySwal.fire({
      title: "Upsss!!!",
      text: message,
      type: "error",
      confirmButtonColor: "#50318B",
      onClose: () => this.props.userClearError(),
    });
  };

  showSuccess = (message) => {
    const {
      siteReducer: {
        site_texts: { text_congratulations },
      },
    } = this.props;

    MySwal.fire({
      title: text_congratulations ? text_congratulations : "Felicitaciones!",
      text: message,
      type: "success",
      confirmButtonColor: "#50318B",
      onClose: () => this.props.userClearError(),
    });
  };

  openCloseSearch = (statusSearch) => {
    this.setState({
      statusSearch,
    });
  };

  toForgot = () => {
    const {
      userReducer: { email },
      siteReducer: {
        site_texts: { text_write_email, text_email_written, text_recovering },
      },
      forgot,
    } = this.props;

    if (email === "") {
      this.showError(
        text_write_email ? text_write_email : "Debes escribir el email."
      );
      return false;
    }
    if (!emailExpression.test(email)) {
      this.showError(
        text_email_written
          ? text_email_written
          : "Válida que el email esté bien escrito."
      );
      return false;
    }
    forgot(text_recovering ? text_recovering : "Recuperando...");
  };

  render() {
    const {
      userReducer: { is_user, success, email },
      siteReducer: {
        site_texts: {
          text_user,
          text_recover,
          text_create_account,
          text_login,
        },
      },
    } = this.props;

    if (is_user) {
      return <Redirect to="/home" />;
    }
    return (
      <FormLayout>
        <div className="form-login">
          <div className="row">
            <div className="col-sm-12">
              {success !== "" && this.showSuccess(success)}
              <div className="form-row">
                <label>{text_user ? text_user : "Usuario"}</label>
                <input
                  type="email"
                  placeholder="email@email.com"
                  className="form-control"
                  value={email}
                  onChange={(input) =>
                    this.props.changeEmail(input.target.value)
                  }
                />
              </div>
              <div className="form-row">
                <button
                  className="btn btn-block btn-form"
                  onClick={this.toForgot}
                >
                  {text_recover ? text_recover : "RECUPERAR"}
                </button>
              </div>
              <div className="menu-form">
                <Link to="/login">{text_login ? text_login : "Ingresar"}</Link>
                <Link to="/register">
                  {text_create_account ? text_create_account : "Crear cuenta"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </FormLayout>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, userAction)(ForgotPage);
