import React from "react";

function Days(props) {
  const {
    totalDays,
    month,
    year,
    days,
    fullName,
    currentYear,
    currentMonth,
    currentDate,
    selectDays,
    selectedDates,
  } = props;
  const date = new Date(year, month);

  const formatDate = (y, m, d) => {
    return `${y}-${`${m}`.padStart(2, 0)}-${`${d}`.padStart(2, 0)}`;
  };

  const getIsActive = (dateValdiate) => {
    const isAdded = selectedDates.find((item) => item === dateValdiate);
    return isAdded ? "active" : "";
  };

  const renderDays = () => {
    const html = [];
    date.setDate(1);
    const indexFirstDate = date.getDay();
    const prevLastDate = new Date(year, month, 0).getDate();
    const lastDayIndex = new Date(year, month + 1, 0).getDay();
    const nexDays = 7 - lastDayIndex - 1;

    // Add prev days
    for (let i = indexFirstDate; i > 0; i--) {
      const day = prevLastDate - i + 1;
      const dayDate = formatDate(year, month, day);
      let active = getIsActive(dayDate);

      html.push(
        <div
          className={`calendar_prev-day calendar_day ${active}`}
          key={`prev-${i}`}
        >
          {day}
        </div>
      );
    }

    // Add calendar days
    for (let i = 1; i <= totalDays; i++) {
      const current =
        date.getMonth() === currentMonth && i === currentDate ? "current" : "";
      const dayDate = formatDate(year, month + 1, i);
      let active = getIsActive(dayDate);

      const toSelect =
        date.getMonth() === currentMonth &&
        currentDate > i &&
        date.getFullYear() === currentYear
          ? false
          : true;

      html.push(
        <button
          className={`calendar_day ${current} ${active}`}
          key={i}
          onClick={() => (toSelect ? selectDays(dayDate) : null)}
        >
          {i}
        </button>
      );
    }

    // Add next days
    const nextMonth = new Date(year, month + 1);
    for (let i = 1; i <= nexDays; i++) {
      const dayDate = formatDate(year, nextMonth.getMonth() + 1, i);
      let active = getIsActive(dayDate);

      html.push(
        <div
          className={`calendar_next-day calendar_day ${active}`}
          key={`next-${i}`}
        >
          {i}
        </div>
      );
    }

    return html;
  };

  return (
    <>
      <div className="calendar_days">
        {days.map((item, index) => (
          <div key={index}>{item.substr(0, fullName ? item.length : 3)}</div>
        ))}
      </div>
      <div className="calendar_days">{renderDays()}</div>
    </>
  );
}

export default Days;
