import React from 'react';
import './css/UserImageRating.css';
import ImageCompany from '../../../images/believe.jpg'
import Rating from './Rating';

function UserImageRating(props) {
	let {data} = props;
	let image = (data.image && data.image !== '') ? data.image : ImageCompany;
	return (
		<div className="userImageRating_container">
			<img src={image} alt={data.name} className="userImageRating_"/>
			<div className="userImageRating_rating">
				<Rating value={data.rating}/>
			</div>
		</div>
	)
}

export default UserImageRating;