export const INFLUENCER_TARGET_FILTER = 'INFLUENCER_TARGET_FILTER';
export const INFLUENCER_NETWORKS_FILTER = 'INFLUENCER_NETWORKS_FILTER';
export const INFLUENCER_TAGS_FILTER = 'INFLUENCER_TAGS_FILTER';
export const INFLUENCER_CITIES_FILTER = 'INFLUENCER_CITIES_FILTER';
export const INFLUENCER_COUNTRIES_FILTER = 'INFLUENCER_COUNTRIES_FILTER';
export const INFLUENCER_RATING_FILTER = 'INFLUENCER_RATING_FILTER';
export const INFLUENCER_AGE_MIN_FILTER = 'INFLUENCER_AGE_MIN_FILTER';
export const INFLUENCER_AGE_MAX_FILTER = 'INFLUENCER_AGE_MAX_FILTER';
export const INFLUENCER_FOLLOWER_MIN_FILTER = 'INFLUENCER_FOLLOWER_MIN_FILTER';
export const INFLUENCER_FOLLOWER_MAX_FILTER = 'INFLUENCER_FOLLOWER_MAX_FILTER';
export const INFLUENCER_LOADING = 'INFLUENCER_LOADING';
export const INFLUENCER_LIST = 'INFLUENCER_LIST';
export const INFLUENCER_PAGE = 'INFLUENCER_PAGE';
export const INFLUENCER_TOTAL_PAGES = 'INFLUENCER_TOTAL_PAGES';
export const INFLUENCER_TOTAL_LIST = 'INFLUENCER_TOTAL_LIST';
export const INFLUENCER_DETAIL = 'INFLUENCER_DETAIL';
export const INFLUENCER_DETAIL_LOADING = 'INFLUENCER_DETAIL_LOADING';
export const INFLUENCER_DETAIL_ERROR = 'INFLUENCER_DETAIL_ERROR';
