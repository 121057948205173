import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './css/ProfileView.css';
import OpenImage from '../../../../components/user/manager/OpenImage';
import CropImages from '../../../../components/all/CropImages';

import Api from '../../../../functionality/Api';
import * as userActions from '../../../../functionality/actions/userActions';
import * as cropperActions from '../../../../functionality/actions/cropperActions';
import ProfileHeader from '../../../../components/all/ProfileHeader';
import Products from '../../../../components/all/Products';
import SocialNetworks from '../../../../components/all/SocialNetworks';
import AddSocialNetworks from '../../../../components/AddSocialNetworks';
import { getSocialNetworks } from '../../../../functionality/actions/generalActions';

const MySwal = withReactContent(Swal);

const {
  openLoading,
  closeLoading,
  userUpdate,
  changePhone,
  changeWebsite,
  changeTimetable,
  changeAddress,
  changeAddressAddon,
  changeDescription,
  userClearError,
  userSaveDocs,
} = userActions;
const { cropperImage } = cropperActions;

class ProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onCrop: null,
    };
  }

  showError = (message) => {
    MySwal.fire({
      title: 'Upsss!!!',
      text: message,
      type: 'warning',
      confirmButtonColor: '#50318B',
      onClose: () => this.props.userClearError(),
    });
  };

  componentDidMount() {
    const {
      user_data: { docs },
    } = this.props.userReducer;
    const {
      generalReducer: { general_social_networks },
      changePhone,
      changeAddress,
      changeWebsite,
      changeTimetable,
      changeAddressAddon,
      changeDescription,
      getSocialNetworks,
    } = this.props;
    if (docs) {
      changePhone(docs.phone ? docs.phone : '');
      changeAddress(docs.address ? docs.address : '');
      changeAddressAddon(docs.address_addon ? docs.address_addon : '');
      changeWebsite(docs.website ? docs.website : '');
      changeTimetable(docs.timetable ? docs.timetable : '');
      changeDescription(docs.description ? docs.description : '');
    }
    if (!general_social_networks.length) {
      getSocialNetworks();
    }
  }
  onCrop = async (image) => {
    const {
      siteReducer: {
        site_texts: { text_uploading_image },
      },
    } = this.props;

    this.props.openLoading(
      text_uploading_image ? text_uploading_image : 'Subiendo Imagen...'
    );
    let data = new FormData();
    data.append('imgBase64', image);
    Api.uploadFile(data, (res) => {
      if (res.location) {
        document.getElementById('imgProfile').value = '';
        this.userUpdate(res.location);
      }
    });
  };

  userUpdate = (image) => {
    let data;
    if (this.state.onCrop === 'img_profile') {
      data = {
        image,
      };
    } else {
      data = {
        coverpage: image,
      };
    }
    this.props.userUpdate(data);
  };

  handleChange = (e, ratio_width, ratio_height, onCrop) => {
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({
        onCrop,
      });
      let config = {
        cropper_image: reader.result,
        cropper_ratio_width: ratio_width,
        cropper_ratio_height: ratio_height,
      };
      this.props.cropperImage(config);
    };
    reader.readAsDataURL(files[0]);
  };

  viewActivity = (cod_activity) => {
    const {
      userReducer: { activities },
      siteReducer: { site_languages },
    } = this.props;
    if (activities.length > 0) {
      let activity = activities.filter(
        (item) => item.id === parseInt(cod_activity)
      );
      let name =
        site_languages === 'es' ? activity[0].name : activity[0].english_name;
      return name;
    }
  };

  saveData = () => {
    const {
      userReducer: { phone, address, address_addon, website, timetable },
      siteReducer: { text_business_number, text_write_address },
    } = this.props;
    const { userSaveDocs } = this.props;
    if (phone === '') {
      this.showError(
        text_business_number
          ? text_business_number
          : 'Debes escribir el número de teléfono.'
      );
      return;
    }
    if (address === '') {
      this.showError(
        text_write_address
          ? text_write_address
          : 'Debes escribir una dirección.'
      );
      return;
    }
    let data = {
      phone,
      address,
      address_addon,
      website,
      timetable,
    };
    userSaveDocs(data);
  };

  /* The above code is defining a function called `saveDescription` which is used to save the
  description of a user's business. It first extracts the `description` property from the
  `userReducer` object and the `text_description_business` property from the `siteReducer` object.
  It then checks if the `description` is empty and shows an error message if it is. If the
  `description` is not empty, it creates a `data` object with the `description` property and calls
  the `userSaveDocs` function with this `data` object as an argument. */
  saveDescription = () => {
    const { description } = this.props.userReducer;
    const {
      userSaveDocs,
      siteReducer: { text_description_business },
    } = this.props;
    if (description === '') {
      this.showError(
        text_description_business
          ? text_description_business
          : 'Debes escribir la descripción de tu negocio.'
      );
      return;
    }
    let data = { description };
    userSaveDocs(data);
  };

  render() {
    const {
      userReducer: {
        phone,
        description,
        address,
        address_addon,
        website,
        timetable,
        user_data,
      },
      changePhone,
      changeAddress,
      changeWebsite,
      changeTimetable,
      changeAddressAddon,
      changeDescription,
      siteReducer: {
        site_texts: {
          text_profile,
          text_upload_logo_profile_image,
          text_address,
          text_address_complement,
          text_phone,
          text_web_page,
          text_save,
          text_upload_cover,
          text_about_me,
          text_brands,
        },
      },
      // generalReducer: { general_social_networks },
    } = this.props;

    if (!user_data) {
      return null;
    }
    // console.log(general_social_networks);

    const getPrincipal = (tags) => {
      const principalTag = tags.find((tag) => tag.is_principal === 1);
      return principalTag;
    };

    const Categories = ({ categories }) => {
      return (
        <div className="categories__container">
          {categories.map((categorie, index) => (
            <span key={index}>{categorie.name}</span>
          ))}
        </div>
      );
    };

    const {
      name,
      lastname,
      username,
      docs,
      seller,
      shopping,
      rating,
      user_type_id,
    } = user_data;

    let business_name = docs ? docs.business_name : '';
    let activity_id = user_data ? user_data.activity_id : '';
    let numSeller = seller && seller[0].seller ? seller[0].seller : 0;
    let numShopping =
      shopping && shopping[0].shopping ? shopping[0].shopping : 0;
    let numRating = rating;

    return (
      <Fragment>
        <div className="title-manager">
          <div>
            <span className="icon-profile" />
            {text_profile || 'Perfil'}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-3 background-white">
              <div className="profileView_img-profile">
                <input
                  type="file"
                  className="hidden"
                  name="img-profile"
                  id="imgProfile"
                  accept=".jpg, .png, .jpeg"
                  value=""
                  onChange={(input) =>
                    this.handleChange(input, 1, 1, 'img_profile')
                  }
                />
                {user_data.image && user_data.image !== '' ? (
                  <div className="profileView_box-image">
                    <button
                      onClick={() =>
                        document.getElementById('imgProfile').click()
                      }
                      className="profileView_remove"
                    >
                      <span className="icon-pencil"></span>
                    </button>
                    <img src={user_data.image} alt="Mi Logo" />
                  </div>
                ) : (
                  <OpenImage
                    open={() => document.getElementById('imgProfile').click()}
                    title={
                      text_upload_logo_profile_image
                        ? text_upload_logo_profile_image.toUpperCase()
                        : 'SUBIR LOGO O IMAGEN DE PERFIL'
                    }
                  />
                )}
              </div>
              {user_type_id !== 8 ? (
                <>
                  <div className="profileView_name profileView_center">
                    {business_name.toUpperCase()}
                  </div>
                  {activity_id && (
                    <div className="profileView_category profileView_center">
                      {this.viewActivity(activity_id)}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="principal__data">
                    <h2 className="influencer__name">{`${name} ${lastname}`}</h2>
                    <div
                      className={`influencer__icon ${
                        getPrincipal(user_data.social_networks).slug
                      }`}
                    >
                      <i
                        className={`${
                          getPrincipal(user_data.social_networks).icon
                        } `}
                      ></i>
                    </div>
                    {username && (
                      <h4 className="influencer__username">
                        <strong>{`@${username}`}</strong>
                      </h4>
                    )}
                    <h4 className="influencer__category">{`Influencer: ${
                      getPrincipal(user_data.tags).name
                    } `}</h4>
                  </div>
                  <div className="separator"></div>
                  <Categories categories={user_data.tags} />
                </>
              )}
              <div className="profileView_form">
                {user_type_id !== 8 && (
                  <>
                    <div className="profileView_row d-flex align-items-center profileView_left">
                      <div className="profileView_icon">
                        <span className="icon-address" />
                      </div>
                      <input
                        placeholder={text_address ? text_address : 'Dirección'}
                        name="address"
                        className="form-control"
                        value={address}
                        onChange={(input) => changeAddress(input.target.value)}
                      />
                    </div>
                    <div className="profileView_row d-flex align-items-center profileView_left">
                      <div className="profileView_icon"></div>
                      <input
                        placeholder={
                          text_address_complement
                            ? text_address_complement
                            : 'Complemento Dirección'
                        }
                        name="address_addon"
                        className="form-control"
                        value={address_addon}
                        onChange={(input) =>
                          changeAddressAddon(input.target.value)
                        }
                      />
                    </div>
                  </>
                )}
                <div className="profileView_row d-flex align-items-center profileView_left">
                  <div className="profileView_icon">
                    <span className="icon-phone" />
                  </div>
                  <input
                    placeholder={text_phone ? text_phone : 'Teléfono'}
                    name="phone"
                    className="form-control"
                    value={phone}
                    onChange={(input) => changePhone(input.target.value)}
                  />
                </div>
                <div className="profileView_row d-flex align-items-center profileView_left">
                  <div className="profileView_icon">
                    <span className="icon-web" />
                  </div>
                  <input
                    placeholder={text_web_page ? text_web_page : 'Página Web'}
                    name="website"
                    className="form-control"
                    value={website}
                    onChange={(input) => changeWebsite(input.target.value)}
                  />
                </div>
                {user_type_id !== 8 && (
                  <div className="profileView_row d-flex align-items-center profileView_left">
                    <div className="profileView_icon">
                      <span className="icon-clock" />
                    </div>
                    <input
                      placeholder="8:00AM - 6:00PM"
                      name="website"
                      className="form-control"
                      value={timetable}
                      onChange={(input) => changeTimetable(input.target.value)}
                    />
                  </div>
                )}
                <button
                  className="btn profileView_btn-save form-control"
                  onClick={this.saveData}
                >
                  {text_save ? text_save.toUpperCase() : 'GUARDAR'}
                </button>
              </div>
              <div className="separator"></div>
              <div className="profileView_form influencer__aboutme">
                {user_type_id === 8 && <h3>{text_about_me || 'Sobre Mí'}</h3>}
                <textarea
                  className="form-control profileView_textarea"
                  placeholder="Descripción"
                  value={description}
                  onChange={(input) => changeDescription(input.target.value)}
                />
                <button
                  className="btn profileView_btn-save form-control"
                  onClick={this.saveDescription}
                >
                  {text_save ? text_save.toUpperCase() : 'GUARDAR'}
                </button>
              </div>
              {user_type_id === 8 && user_data?.brands?.data.length !== 0 && (
                <>
                  <div className="influencer__brands">
                    <h3>{text_brands || 'Marcas'}</h3>
                    <div className="brands__logos row">
                      {user_data?.brands?.data.map((brand, index) => (
                        <img
                          className="col-md-6"
                          alt="brand__logo"
                          src={brand.image}
                          key={index}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="separator"></div>
                </>
              )}
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="row">
                <div className="profileView_cover-page">
                  <input
                    type="file"
                    className="hidden"
                    name="img-profile"
                    id="coverPage"
                    accept=".jpg, .png, .jpeg"
                    onChange={(input) =>
                      this.handleChange(input, 900, 320, 'cover_page')
                    }
                  />
                  {user_data.coverpage && user_data.coverpage !== '' ? (
                    <div className="profileView_box-image">
                      <button
                        onClick={() =>
                          document.getElementById('coverPage').click()
                        }
                        className="profileView_remove"
                      >
                        <span className="icon-pencil"></span>
                      </button>
                      <img src={user_data.coverpage} alt="Mi CoverPage" />
                    </div>
                  ) : (
                    <OpenImage
                      open={() => document.getElementById('coverPage').click()}
                      title={
                        text_upload_cover
                          ? text_upload_cover.toUpperCase()
                          : 'SUBIR PORTADA'
                      }
                    />
                  )}
                </div>
                <ProfileHeader
                  rating={numRating}
                  sales={numSeller}
                  shopping={numShopping}
                />
              </div>
              {user_type_id === 8 && (
                <div className="influencer__addSocialNetwork">
                  <AddSocialNetworks onlyButton />
                </div>
              )}
              {user_type_id !== 8 ? (
                <Products userId={user_data.id} />
              ) : (
                <SocialNetworks networks={user_data.social_networks} />
              )}
            </div>
          </div>
        </div>
        {docs && <CropImages onCrop={this.onCrop} />}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  userReducer,
  cropperReducer,
  siteReducer,
  generalReducer,
}) => {
  return {
    userReducer,
    cropperReducer,
    siteReducer,
    generalReducer,
  };
};

const mapDispatchToProps = {
  openLoading,
  closeLoading,
  cropperImage,
  userUpdate,
  changePhone,
  changeWebsite,
  changeTimetable,
  changeAddress,
  changeAddressAddon,
  changeDescription,
  userClearError,
  userSaveDocs,
  getSocialNetworks,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
