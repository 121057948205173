import React, { useEffect } from 'react';
import BreifPorposal from '../../../../../components/all/BreifPorposal/BreifPorposal';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import ManagerLayout from '../../layouts/ManagerLayout';
import CampaignDetailView from '../../views/CampaignDetailView/CampaignDetailView';
import {
  getPorposalsByCampaign,
  porposalClear,
} from '../../../../../functionality/actions/porposalActions';
import Spinner from '../../../../../components/all/Spinner';
import './NewPorposalPage.css';

const NewPorposalPage = (props) => {
  const {
    userReducer: { user_data },
    campaignReducer: { campaign_current_detail },
    porposalReducer: { porposal_list, porposal_loading },
    getPorposalsByCampaign,
    porposalClear,
  } = props;
  useEffect(() => {
    if (porposal_list.length === 0) {
      getPorposalsByCampaign(campaign_current_detail.consecutive);
    }
    return () => {
      porposalClear();
    };
  }, []);

  return (
    <ManagerLayout>
      <CampaignDetailView>
        {porposal_loading ? (
          <div className="spinner-loading">
            <Spinner type="purple" />
          </div> /* {user_type_id !== 8 && (
          <Redirect to={`campaign/${campaign_current_detail.consecutive}`} />
        )} */
        ) : (
          <>
            {user_data?.user_type_id !== 8 && (
              <Redirect
                to={`/manager/campaign/${campaign_current_detail.consecutive}`}
              />
            )}
            <div className="form-porposal-container">
              <BreifPorposal data={campaign_current_detail} />
            </div>
          </>
        )}
      </CampaignDetailView>
    </ManagerLayout>
  );
};

const mapStateToProps = ({ campaignReducer, userReducer, porposalReducer }) => {
  return {
    campaignReducer,
    userReducer,
    porposalReducer,
  };
};
const mapDispatchToProps = {
  getPorposalsByCampaign,
  porposalClear,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewPorposalPage);
