import {
  CART_VIEW,
  CART_LOADING,
  CART_ADD,
  CART_CLOSE_MODAL,
  CART_TOOLTIP,
  CART_REMOVE,
  CART_MESSAGE_ERROR,
  CART_CLEAR_MESSAGE_ERROR,
  CART_PRODUCT,
  CART_TOTAL_ITEMS,
  CART_IMAGES,
  CART_IS_PROMO,
  CART_PRICE,
  CART_COMMISSION,
  CART_IVA,
  CART_VIEW_PRICE,
  CART_CLEAR_DATA,
} from "../types/cartTypes";

const INITIAL_STATE = {
  cart: [],
  cart_total: 0,
  cart_error: false,
  cart_loading: false,
  cart_modal_detail: false,
  cart_modal: false,
  cart_message_error: "",
  cart_product: null,
  cart_total_items: 0,
  cart_images: [],
  cart_is_promo: false,
  cart_price: 0,
  cart_commission: 0,
  cart_iva: 0,
  cart_view_price: 0,
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CART_CLEAR_DATA:
      return {
        cart_product: null,
        cart_total_items: 0,
        cart_images: [],
        cart_is_promo: false,
        cart_price: 0,
        cart_commission: 0,
        cart_iva: 0,
        cart_view_price: 0,
      };
    case CART_TOOLTIP:
      return {
        ...state,
        cart_total: action.payload,
      };
    case CART_VIEW:
      return {
        ...state,
        cart: action.payload.list,
        cart_total: action.payload.total,
        cart_loading: false,
      };
    case CART_REMOVE:
      return {
        ...state,
        cart: action.payload.list,
        cart_total: action.payload.total,
      };
    case CART_LOADING:
      return {
        ...state,
        cart_loading: true,
      };
    case CART_ADD:
      return {
        ...state,
        cart_modal_detail: action.payload,
        cart_modal: true,
      };
    case CART_CLOSE_MODAL:
      return {
        ...state,
        cart_modal_detail: false,
        cart_modal: false,
      };
    case CART_MESSAGE_ERROR: {
      return {
        ...state,
        cart_message_error: action.payload,
      };
    }
    case CART_CLEAR_MESSAGE_ERROR: {
      return {
        ...state,
        cart_message_error: "",
      };
    }
    case CART_PRODUCT: {
      return {
        ...state,
        cart_product: action.payload,
      };
    }
    case CART_TOTAL_ITEMS: {
      return {
        ...state,
        cart_total_items: action.payload,
      };
    }
    case CART_IMAGES: {
      return {
        ...state,
        cart_images: action.payload,
      };
    }
    case CART_IS_PROMO: {
      return {
        ...state,
        cart_is_promo: action.payload,
      };
    }
    case CART_PRICE: {
      return {
        ...state,
        cart_price: action.payload,
      };
    }
    case CART_COMMISSION: {
      return {
        ...state,
        cart_commission: action.payload,
      };
    }
    case CART_IVA: {
      return {
        ...state,
        cart_iva: action.payload,
      };
    }
    case CART_VIEW_PRICE: {
      return {
        ...state,
        cart_view_price: action.payload,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default cartReducer;
