import React, { Component } from "react";
import { connect } from "react-redux";
import MarketplaceLayout from "./layouts/MarketplaceLayout";
import ShoppingCartView from "./views/ShoppingCartView";

import * as cartActions from "../../../functionality/actions/cartActions";

class ShoppingCartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offers: [],
      statusSearch: false,
    };
  }

  componentDidMount() {
    const { viewCart } = this.props;
    viewCart();
    let offers = [];
    for (let i = 0; i < 18; i++) {
      let limitedData = {
        id: i,
        name: `Oferta ${i}`,
        short_description:
          "Lorem Ipsum dolor sit amet, consectetur adipiscing elit, labor",
        image: "",
        color: "#000000",
        price: "100000",
      };
      offers.push(limitedData);
    }
    this.setState({
      offers,
    });
    // for (let i = 1; i <= 4; i++) {
    // 	let discountPrice = ((i % 2) === 0) ? '100000' : '';
    // 	let product = {
    // 		id: i,
    // 		name: `Producto - ${i}`,
    // 		image: `https://api.bepass.co/uploads/1562333374_product-01.jpg`,
    // 		discount: `${i}%`,
    // 		price: '120000',
    // 		discountPrice,
    // 		user: {
    // 			id: i,
    // 			name: `Nombre Usuario ${i}`,
    // 			image: '',
    // 			rating: Math.floor(Math.random() * (5 - 0)) + 0
    // 		}
    // 	}
    // 	shoppingCart.push(product);
    // }
  }
  openCloseSearch = (statusSearch) => {
    this.setState({
      statusSearch,
    });
  };
  render() {
    return (
      <MarketplaceLayout
        statusSearch={this.state.statusSearch}
        openCloseSearch={this.openCloseSearch}
      >
        <ShoppingCartView
          offers={this.state.offers}
          shoppingCart={this.state.shoppingCart}
        />
      </MarketplaceLayout>
    );
  }
}

const mapStateToProps = ({ cartReducer }) => cartReducer;

export default connect(mapStateToProps, cartActions)(ShoppingCartPage);
