import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  changePorposalStatus,
  getPorposalsByCampaign,
} from '../../../../../functionality/actions/porposalActions';
import './PorposalRow.css';
import Swal from 'sweetalert2';

const PorposalRow = (props) => {
  const {
    siteReducer: {
      site_language,
      site_texts: {
        text_cancel,
        text_edit_2,
        text_are_you_sure,
        text_not_reverse_action,
        text_cancel_proposal,
      },
    },
    match: {
      params: { id: campaignConsecutive },
    },
    data,
    changePorposalStatus,
    getPorposalsByCampaign,
  } = props;

  const cancel = () => {
    const swalStyled = Swal.mixin({
      customClass: {
        confirmButton: 'button__confirm',
        cancelButton: 'button__cancel',
      },
      buttonsStyling: false,
    });

    swalStyled
      .fire({
        title: text_are_you_sure || '¿Estas seguro?',
        text: text_not_reverse_action || 'Tú no podrás revertir esta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28cae4',
        cancelButtonColor: '#50318b',
        confirmButtonText: text_cancel_proposal || 'Cancelar Propuesta',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value === true) {
          await changePorposalStatus(data.consecutive, 3);
          getPorposalsByCampaign(campaignConsecutive);
        }
      });
  };

  return (
    <tr className="porposal__row">
      <td>
        <span className="porposal__data__name">{data.name}</span>
      </td>
      <td>
        <div className="porposal__status__row">
          <div
            className="dot"
            style={{ backgroundColor: data.status.color }}
          ></div>
          {site_language === 'es' ? data.status.name : data.status.english_name}
        </div>
      </td>
      <td>
        <div className="networks">
          {data.social_networks.slice(0, 3).map((network) => (
            <div
              className={`network__circle circle-${network.slug}`}
              key={network.id}
            >
              <i className={`${network.icon}`}></i>
            </div>
          ))}
        </div>
      </td>

      <td>
        {data.proposal_status_id === 1 && (
          <Link to={`porpose/edit/${data.consecutive}`}>
            {text_edit_2 || 'Editar'}
          </Link>
        )}
        {data.proposal_status_id === 2 && (
          <button onClick={cancel}>{text_cancel || 'Cancelar'}</button>
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};
const mapDispatchToProps = {
  changePorposalStatus,
  getPorposalsByCampaign,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PorposalRow)
);
