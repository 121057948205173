import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./css/GeneralSlider.css";

class GeneralSlider extends Component {
  render() {
    let {
      data,
      others,
      viewMore,
      viewItems,
      minItems,
      siteReducer: {
        site_texts: { text_view_all },
      },
    } = this.props;

    let itemsShow = data.length < viewItems ? data.length : viewItems;

    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 10000,
      speed: 500,
      slidesToShow: itemsShow,
      slidesToScroll: itemsShow,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: minItems,
            slidesToScroll: minItems,
            initialSlide: minItems,
          },
        },
      ],
    };
    return (
      <div className="generalSlider_container">
        {data.length > 0 && (
          <Fragment>
            <Slider {...settings} ref={(c) => (this.slider = c)}>
              {data.map((item, index) => {
                return (
                  <this.props.slide key={index} data={item} others={others} />
                );
              })}
            </Slider>
            {viewMore && (
              <Link to={viewMore} className="generalSlider_viewAll">
                {text_view_all ? text_view_all : "VER TODO"}
              </Link>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

GeneralSlider.defaultProps = {
  minItems: 2,
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(GeneralSlider);
