import React, { useEffect, useState } from 'react';
import Paginator from '../../../../../../components/all/Paginator/Paginator';
import './CampaignPosts.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PostTable from '../../../../../../components/all/Posts/PostsTable/PostTable';
import Spinner from '../../../../../../components/all/Spinner';
import CampaignPostForm from './CampaignPostForm/CampaignPostForm';
import {
  getByCampaignAndUserAll,
  setPostList,
  getPostsByCampaign,
} from '../../../../../../functionality/actions/postActions';
import CampaignPostDetail from './CampaignPostDetail/CampaignPostDetail';
import Error from '../../../../../../components/all/Error';

const CampaignPostsView = (props) => {
  const [option, setOption] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalPost, setShowModalPost] = useState(false);
  const {
    userReducer: { user_data },
    match: {
      params: { id: campaign_consecutive },
      url,
    },
    postReducer: {
      posts_list,
      post_list_loading,
      post_page,
      post_total_pages,
      post_loading,
    },
    history,
    location: { search },
    getByCampaignAndUserAll,
    setPostList,
    getPostsByCampaign,
    siteReducer: {
      site_texts: {
        text_post_campaign,
        text_sent_to,
        text_in_correction,
        text_published,
        text_draft,
        text_news,
        text_rejected_2,
        text_accepted,
        text_create_post,
      },
    },
    campaignReducer: { campaign_current_detail },
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      if (user_data?.user_type_id === 8) {
        await getByCampaignAndUserAll(campaign_consecutive);
      } else {
        await getPostsByCampaign(campaign_consecutive);
      }
    };

    if (posts_list.length === 0) {
      fetchData();
    }
    fetchData();
    if (user_data?.user_type_id === 8) {
      setOption('1');
    } else {
      setOption('');
    }

    return () => {
      setPostList([]);
    };
  }, []);

  useEffect(() => {
    if (user_data?.user_type_id === 8) {
      getByCampaignAndUserAll(campaign_consecutive, search);
    } else {
      getPostsByCampaign(campaign_consecutive, search);
    }
  }, [search]);

  const handleChangeOption = async (event) => {
    const value = event.target.value;
    if (value === '') {
      const route = `${url}`;
      setOption(value);
      await history.push(route);
    } else {
      const route = `${url}?status=${value}`;
      setOption(value);
      await history.push(route);
    }
  };

  const handleCreatePost = () => {
    setShowModal(true);
  };

  const handleViewPost = () => {
    setShowModalPost(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalPost(false);
  };

  return (
    <div className="campaign-post">
      <div className="post__campaign__header">
        <h2>{text_post_campaign || 'Post Campaña'}</h2>
        {user_data?.user_type_id === 8 &&
          campaign_current_detail?.status?.id !== 4 && (
            <button className="new__post__btn" onClick={handleCreatePost}>
              {text_create_post || 'Crear Post'}
            </button>
          )}
        {showModal && (
          <div className="modal-background">
            <CampaignPostForm handleCloseModal={handleCloseModal} />
          </div>
        )}
        {showModalPost && (
          <div className="modal-background">
            <CampaignPostDetail handleCloseModal={handleCloseModal} />
          </div>
        )}
      </div>
      <div className="influencer__options">
        {user_data?.user_type_id === 8 && (
          <button
            value={'1'}
            onClick={handleChangeOption}
            className={option === '1' ? 'active' : ''}
          >
            {text_draft || 'Borrador'}
          </button>
        )}
        {user_data?.user_type_id === 8 && (
          <button
            value={'2'}
            onClick={handleChangeOption}
            className={option === '2' ? 'active' : ''}
          >
            {text_sent_to || 'Enviado'}
          </button>
        )}
        {user_data?.user_type_id !== 8 && (
          <button
            value={''}
            onClick={handleChangeOption}
            className={option === '' ? 'active' : ''}
          >
            {text_news || 'Nuevos'}
          </button>
        )}
        <button
          value={'3'}
          onClick={handleChangeOption}
          className={option === '3' ? 'active' : ''}
        >
          {text_in_correction || 'En corrección'}
        </button>
        <button
          value={'4'}
          onClick={handleChangeOption}
          className={option === '4' ? 'active' : ''}
        >
          {text_accepted || 'Aceptados'}
        </button>
        <button
          value={'5'}
          onClick={handleChangeOption}
          className={option === '5' ? 'active' : ''}
        >
          {text_published || 'Publicados'}
        </button>
        <button
          value={'6'}
          onClick={handleChangeOption}
          className={option === '6' ? 'active' : ''}
        >
          {text_rejected_2 || 'Rechazado'}
        </button>
      </div>
      <div className="mb-4">
        {post_list_loading ? (
          <div className="spinner-loading">
            <Spinner type="purple" />
          </div>
        ) : (
          <>
            {posts_list && posts_list.length !== 0 && (
              <PostTable
                loading={false}
                data={posts_list}
                currentPage={1}
                totalPage={1}
                typeUserId={user_data?.user_type_id}
                openModal={handleCreatePost}
                closeModal={handleCloseModal}
                handleViewPost={handleViewPost}
                inChat={false}
              />
            )}
            {posts_list && !posts_list?.length && (
              <div className="post__error_container">
                <Error type="no-posts" />
              </div>
            )}
          </>
        )}
      </div>
      {post_total_pages > 1 && (
        <Paginator totalPages={post_total_pages} currentPage={post_page} />
      )}
    </div>
  );
};

const mapStateToProps = ({
  userReducer,
  postReducer,
  siteReducer,
  campaignReducer,
}) => {
  return {
    userReducer,
    postReducer,
    siteReducer,
    campaignReducer,
  };
};

const mapDispatchToProps = {
  getByCampaignAndUserAll,
  setPostList,
  getPostsByCampaign,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignPostsView)
);
