import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import './css/Dashboard.css';
import DashboardSquare from '../../../../components/all/DashboardSquare';
import DashboardBalance from '../../../../components/user/manager/DashboardBalance';
// import DashboardOrders from '../../../../components/user/manager/DashboardOrders';
// import DashboardChats from '../../../../components/user/manager/DashboardChats';

function DashboardView(props) {
  const {
    userReducer: { user_data },
    siteReducer: {
      site_texts: { text_sales, text_purchases, text_average },
    },
  } = props;
  let total_sales = 0;
  let total_shopping = 0;
  let rating = 5;
  let bank = null;
  if (user_data) {
    total_sales = user_data.total_sales;
    total_shopping = user_data.total_shopping;
    rating = user_data.rating;
    bank = user_data.bank;
  }
  let initial = bank && bank.initial_balance ? bank.initial_balance : 0;
  let total = bank && bank.total ? bank.total : 0;
  let numRating = rating;

  return (
    <Fragment>
      <div className="row dashboard_header">
        <div className="col-md-3">
          <DashboardSquare
            icon="icon-money"
            title={text_sales ? text_sales.toUpperCase() : 'VENTAS'}
            number={total_sales}
          />
        </div>
        <div className="col-md-3">
          <DashboardSquare
            icon="icon-bag"
            title={text_purchases ? text_purchases.toUpperCase() : 'COMPRAS'}
            number={total_shopping}
          />
        </div>
        <div className="col-md-3">
          <DashboardSquare
            icon="icon-score"
            title={
              text_average
                ? `${text_average.toUpperCase()} BAX`
                : 'PROMEDIO BAX'
            }
            number={numRating}
            type="rating"
          />
        </div>
        <div className="col-md-3">
          <DashboardBalance initial={total} transfered={initial} />
        </div>
      </div>
      <div className="dashboard_chart-bar">
        {props.dataChartBar && (
          <Bar
            data={props.dataChartBar}
            options={{
              maintainAspectRatio: true,
            }}
          />
        )}
      </div>
      <div className="row">
        <div className="col-md-8">
          {/* <DashboardOrders
						data={props.dataOrders}
						actions={props.actionsOrders}
					/> */}
        </div>
        <div className="col-md-4">
          {/* <DashboardChats
						data={props.dataChats}
						actions={props.actionsChats}
					/> */}
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = ({ userReducer, dashboardReducer, siteReducer }) => {
  return {
    userReducer,
    dashboardReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps)(DashboardView);
