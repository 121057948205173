import React from 'react';
import { connect } from 'react-redux';
import DashboardSquare from './DashboardSquare';
import Swal from 'sweetalert2';
import { sentToInfluencer } from '../../functionality/actions/campaignActions';

function ProfileHeader(props) {
  const {
    siteReducer: {
      site_texts: {
        text_sales,
        text_purchases,
        text_rating,
        text_purpose_request,
        text_select_campaign,
        text_send,
        text_cancel,
        text_warning_choose_campaign,
      },
    },
    sales,
    shopping,
    rating,
    roleProfile,
    currentRole,
    campaingsLive,
    sentToInfluencer,
    username,
  } = props;

  const columnClass =
    roleProfile === 8 && currentRole !== 8
      ? 'col-md-12 col-lg-3'
      : 'col-md-12 col-lg-4';

  const sendPurposeRequest = async () => {
    const swalStyled = Swal.mixin({
      customClass: {
        confirmButton: 'button__confirm',
        cancelButton: 'button__cancel',
        input: 'form-control',
      },
      buttonsStyling: false,
    });

    const resultMap = {};

    for (const campaign of campaingsLive) {
      const { consecutive, name } = campaign;
      resultMap[consecutive] = name;
    }

    const { value: campaignConsecutive } = await swalStyled.fire({
      title: text_select_campaign || 'Selecciona la campaña',
      input: 'select',
      inputOptions: resultMap,
      inputPlaceholder: text_select_campaign || 'Selecciona una campaña',
      confirmButtonText: text_send || 'Enviar',
      cancelButtonText: text_cancel || 'Cancelar',
      showCancelButton: true,
      reverseButtons: true,
      inputValidator: (value) => {
        return new Promise(async (resolve) => {
          if (value === '') {
            resolve(
              text_warning_choose_campaign || 'Necesitas escoger una campaña'
            );
          }
          if (value) {
            resolve();
          }
        });
      },
    });

    if (campaignConsecutive) {
      // swalStyled.fire(`Seleccionaste ${campaignConsecutive} `);
      await sentToInfluencer(campaignConsecutive, username);
    }
  };

  return (
    <div className="profileView_header">
      <div className="row metrics-container">
        <div className={columnClass}>
          <DashboardSquare
            icon="icon-money"
            title={text_sales ? text_sales.toUpperCase() : 'VENTAS'}
            number={sales}
          />
        </div>
        <div className={columnClass}>
          <DashboardSquare
            icon="icon-bag"
            title={text_purchases ? text_purchases.toUpperCase() : 'COMPRAS'}
            number={shopping}
          />
        </div>
        <div className={columnClass}>
          <DashboardSquare
            icon="icon-score"
            title={
              text_rating ? `${text_rating.toUpperCase()} BAX` : 'PROMEDIO BAX'
            }
            number={rating}
            type="rating"
          />
        </div>
        {roleProfile === 8 && currentRole !== 8 && (
          <div className={columnClass}>
            <button className="btn__porpose" onClick={sendPurposeRequest}>
              <i className="icon-send-message"></i>
              {text_purpose_request || 'Solicitar Propuesta'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

ProfileHeader.defaultProps = {
  roleProfile: null,
  currentRole: null,
  rating: 5,
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

const mapDispatchToProps = {
  sentToInfluencer,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader);
