import React from 'react';
import './PostRow.css';
import { connect } from 'react-redux';
import {
  getPostEdit,
  deletePost,
  getByCampaignAndUserAll,
} from '../../../../../functionality/actions/postActions';
import Swal from 'sweetalert2';
import { withRouter } from 'react-router';

const PostRow = (props) => {
  const {
    siteReducer: {
      site_language,
      site_texts: {
        text_view,
        text_edit_2,
        text_are_you_sure,
        text_not_reverse_action,
        text_yes_deleted,
      },
    },
    match: {
      params: { id: campaign_consecutive },
    },
    typeUserId,
    data,
    openModal,
    getPostEdit,
    deletePost,
    getByCampaignAndUserAll,
    handleViewPost,
    inChat,
  } = props;

  const colorDot = {
    1: '#000000',
    2: '#50318b',
    3: '#db308b',
    4: '#28bbe4',
    5: '#927ebd',
  };

  const SocialNetworks = () => {
    return (
      <>
        {data.social_networks &&
          data.social_networks.map((network) => (
            <div key={network.id}>
              {network.link === '' ? (
                <div
                  className={`network__circle circle-${network.slug}`}
                  key={network.id}
                >
                  <i className={`${network.icon}`}></i>
                </div>
              ) : (
                <a
                  data-toggle="tooltip"
                  title="Go to link"
                  className={`network__circle circle-${network.slug}`}
                  key={network.id}
                  href={network.link}
                  target="blank"
                >
                  <i className={`${network.icon}`}></i>
                </a>
              )}
            </div>
          ))}
      </>
    );
  };

  const handleDeletePost = () => {
    const swalStyled = Swal.mixin({
      customClass: {
        confirmButton: 'button__confirm',
        cancelButton: 'button__cancel',
      },
      buttonsStyling: false,
    });

    swalStyled
      .fire({
        title: text_are_you_sure || '¿Estas seguro?',
        text: text_not_reverse_action || 'Tú no podrás revertir esta acción',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28cae4',
        cancelButtonColor: '#50318b',
        confirmButtonText: text_yes_deleted || 'Borrar ',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value === true) {
          await deletePost(data.consecutive);
          getByCampaignAndUserAll(campaign_consecutive);
        }
      });
  };

  return (
    <tr className="post_row">
      <td>{data.consecutive}</td>
      <td>{data.name}</td>
      {!inChat && (
        <td>
          <div className="post__networks">
            <SocialNetworks />
          </div>
        </td>
      )}

      <td>
        <div className="post__status__row">
          <div
            className="dot"
            style={{ backgroundColor: colorDot[data.post_status_id] }}
          ></div>
          {site_language === 'es'
            ? data.post_status.name
            : data.post_status.english_name}
        </div>
      </td>

      {!inChat && (
        <td>
          {site_language === 'es'
            ? data.post_type.name
            : data.post_type.english_name}
        </td>
      )}
      {typeUserId !== 8 && !inChat && <td>@{data.influencer.username}</td>}
      <td>
        <div className="post__btn__actions">
          {typeUserId === 8 &&
            (data.post_status_id === 1 || data.post_status_id === 3) && (
              <button
                className="button_edit"
                //todo: poner style color
                // style={{ backgroundColor: colorDot[data.post_status_id] }}
                onClick={async () => {
                  await getPostEdit(data.consecutive);
                  openModal();
                }}
              >
                {text_edit_2 || 'Editar'}
              </button>
            )}
          {(data.post_status_id === 2 ||
            data.post_status_id === 4 ||
            data.post_status_id === 5) && (
            <button
              className="button_edit"
              //todo: poner style color
              // style={{ backgroundColor: colorDot[data.post_status_id] }}
              onClick={async () => {
                await getPostEdit(data.consecutive);
                handleViewPost();
              }}
            >
              {text_view || 'Ver'}
            </button>
          )}
          {typeUserId === 8 &&
            (data.post_status_id === 1 ||
              data.post_status_id === 3 ||
              data.post_status_id === 6) && (
              <button className="trash__btn" onClick={handleDeletePost}>
                <i className="fa-solid fa-trash-can"></i>
              </button>
            )}
        </div>
      </td>
    </tr>
  );
};
const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};
const mapDispatchToProps = {
  getPostEdit,
  deletePost,
  getByCampaignAndUserAll,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PostRow)
);
