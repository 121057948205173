import React from 'react';
import { connect } from 'react-redux';
const { REACT_APP_APP_ROUTE: APP_ROUTE } = process.env;

function TikTokButton(props) {
  const {
    userReducer: { user_data },
    type,
    name,
    icon,
    redirectUrl,
    isAdded,
  } = props;

  if (!user_data) return null;

  const openOauth = () => {
    const route = `${APP_ROUTE}social-network/tik-tok?email=${user_data.email}&redirect=${redirectUrl}`;
    window.location.href = route;
  };

  if (type === 'register') {
    return (
      <button
        className="socialNetworks_social-button tiktok"
        onClick={openOauth}
      >
        <div className="socialNetworks_social">
          <div className="socialNetworks_social-icon">
            <i className={icon} />
          </div>
          {name}
        </div>
        {isAdded && <i className="fa-solid fa-check" />}
      </button>
    );
  }

  return (
    <div className="socialNetworks_social-button tiktok">
      <div>
        <div className="socialNetworks_social-icon">
          <i className={icon} />
        </div>
        {name}
      </div>
    </div>
  );
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

export default connect(mapStateToProps, null)(TikTokButton);
