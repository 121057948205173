import {
  CHAT_LIST,
  CHAT_MESSAGES_LOADING,
  CHAT_LOADING,
  CHAT_SEND_LOADING,
  CHAT_MESSAGES,
  CHAT_CLEAR,
  CHAT_ERROR,
  CHAT_ALERT_ERROR,
  CHAT_CLEAR_ERROR,
  CHAT_MESSAGE,
  CHAT_MESSAGES_ERROR,
  CHAT_SUBJECT,
  CHAT_FOR,
  CHAT_COD_FOR,
  CHAT_SELECT_USERS,
  CHAT_CLEAR_SELECT_USERS,
  CHAT_NAME_FOR,
  CHAT_LIST_VIEW,
  CHAT_NEW_MESSAGE,
  CHAT_SELECTED,
  CHAT_LIST_NOTIFICATION,
  CHAT_TYPE,
  CHAT_TYPE_ID,
  CHAT_LIST_PROPOSAL,
  CHAT_RESPONSE,
} from '../types/chatTypes';

const INITIAL_STATE = {
  chat_loading: false,
  chat_messages_loading: false,
  chat_send_loading: false,
  chat_list: [],
  chat_list_proposal: [],
  chat_list_view: [],
  chat_messages: [],
  chat_error: '',
  chat_alert_error: '',
  chat_messages_error: '',
  chat_message: '',
  chat_subject: '',
  chat_for: '',
  chat_cod_for: '',
  chat_name_for: '',
  chat_type: null,
  chat_type_id: null,
  chat_select_users: [],
  chat_new: false,
  chat_selected: [],
};

const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHAT_CLEAR: {
      return {
        ...state,
        chat_loading: false,
        chat_messages_loading: false,
        chat_send_loading: false,
        chat_messages: [],
        chat_error: '',
        chat_message: '',
        chat_subject: '',
        chat_for: '',
        chat_cod_for: '',
        chat_name_for: '',
        chat_type: null,
        chat_type_id: null,
        chat_select_users: [],
        chat_selected: [],
        chat_new: false,
        chat_response: [],
      };
    }
    case CHAT_LOADING:
      return {
        ...state,
        chat_loading: true,
      };
    case CHAT_LIST:
      return {
        ...state,
        chat_list: action.payload,
        chat_list_view: action.payload,
        chat_loading: false,
      };
    case CHAT_LIST_PROPOSAL:
      return {
        ...state,
        chat_list_proposal: action.payload,
      };
    case CHAT_LIST_NOTIFICATION: {
      return {
        ...state,
        chat_list: action.payload,
        chat_list_view: action.payload,
      };
    }
    case CHAT_LIST_VIEW:
      return {
        ...state,
        chat_list_view: action.payload,
      };
    case CHAT_NEW_MESSAGE:
      return {
        ...state,
        chat_new: action.payload,
      };
    case CHAT_MESSAGES_LOADING:
      return {
        ...state,
        chat_messages_loading: true,
      };
    case CHAT_SEND_LOADING:
      return {
        ...state,
        chat_send_loading: true,
      };
    case CHAT_MESSAGES:
      return {
        ...state,
        chat_messages: action.payload,
        chat_messages_loading: false,
        chat_send_loading: false,
        chat_message: '',
      };
    case CHAT_MESSAGES_ERROR:
      return {
        ...state,
        chat_messages_error: action.payload,
        chat_messages_loading: false,
        chat_send_loading: false,
      };
    case CHAT_ERROR:
      return {
        ...state,
        chat_error: action.payload,
      };
    case CHAT_ALERT_ERROR:
      return {
        ...state,
        chat_alert_error: action.payload,
      };
    case CHAT_CLEAR_ERROR:
      return {
        ...state,
        chat_alert_error: '',
        chat_error: '',
        chat_messages_error: '',
        chat_send_loading: false,
      };
    case CHAT_MESSAGE:
      return {
        ...state,
        chat_message: action.payload,
      };
    case CHAT_SUBJECT:
      return {
        ...state,
        chat_subject: action.payload,
      };
    case CHAT_TYPE:
      return {
        ...state,
        chat_type: action.payload,
      };
    case CHAT_TYPE_ID:
      return {
        ...state,
        chat_type_id: action.payload,
      };
    case CHAT_FOR:
      return {
        ...state,
        chat_for: action.payload,
      };
    case CHAT_COD_FOR:
      return {
        ...state,
        chat_cod_for: action.payload,
      };
    case CHAT_NAME_FOR:
      return {
        ...state,
        chat_name_for: action.payload,
      };
    case CHAT_SELECT_USERS:
      return {
        ...state,
        chat_select_users: action.payload,
      };
    case CHAT_CLEAR_SELECT_USERS:
      return {
        ...state,
        chat_select_users: [],
      };
    case CHAT_SELECTED:
      return {
        ...state,
        chat_selected: action.payload,
      };
    case CHAT_RESPONSE:
      return {
        ...state,
        chat_response: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default chatReducer;
