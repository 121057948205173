import React, { useEffect } from 'react';
import OptionCheckBox from '../../OptionCheckBox/OptionCheckBox';
import MultiRange from '../../MultiRange/MultiRange';
import Score from '../../Influencer/InfluencerScore/Score';
import { getObjectives } from '../../../../functionality/actions/objectivesActions';
import { getTargets } from '../../../../functionality/actions/targetsActions';
import {
  setCampaignMinAgeRange,
  setCampaignMaxAgeRange,
  setCampaignMinFollowersRange,
  setCampaignMaxFollowersRange,
  setCampaignMinLikesRange,
  setCampaignMaxLikesRange,
  setCampaignMinViewsRange,
  setCampaignMaxViewsRange,
  setCampaignInfluencerGender,
  addOrRemoveCampaignObjective,
  setCampaignInfluencerRating,
} from '../../../../functionality/actions/campaignActions';
import { connect } from 'react-redux';
import Spinner from '../../Spinner';

const CampaignFormStep4 = (props) => {
  const {
    siteReducer: {
      site_language,
      site_texts: {
        text_campaign_objectives,
        text_age_range,
        text_gender_influencers,
        text_average_rating,
        text_average_likes,
        text_from_date,
        text_to,
      },
    },
    objectivesReducer: { objectives, objectives_loading },
    targetReducer: { targets, targets_loading },
    campaignReducer: {
      campaign_min_age_range,
      campaign_max_age_range,
      campaign_min_followers_range,
      campaign_max_followers_range,
      campaign_min_likes_range,
      campaign_max_likes_range,
      campaign_min_views_range,
      campaign_max_views_range,
      campaign_influencer_gender,
      campaign_objectives,
      campaign_influencer_rating,
    },
    setCampaignMaxAgeRange,
    setCampaignMinAgeRange,
    setCampaignMinFollowersRange,
    setCampaignMaxFollowersRange,
    setCampaignMinLikesRange,
    setCampaignMaxLikesRange,
    setCampaignMinViewsRange,
    setCampaignMaxViewsRange,
    setCampaignInfluencerGender,
    addOrRemoveCampaignObjective,
    setCampaignInfluencerRating,
    getObjectives,
    getTargets,
  } = props;

  useEffect(() => {
    if (!objectives.length) {
      getObjectives();
    }
    return () => {};
  }, []);

  useEffect(() => {
    const fetchTargets = async () => {
      try {
        if (targets.length === 0) {
          await getTargets();
        }
      } catch (error) {
        console.error('Error en el fetchTargets:', error);
      }
    };

    fetchTargets();
  }, []);

  return (
    <form className="campaign__form">
      <div className="form__column">
        <div className="campaign__block">
          <div className="influencer__profile">
            <div className="form__column">
              <div className="influencer__filter__section">
                <h4 className="filter__subtitle">
                  {text_campaign_objectives
                    ? text_campaign_objectives
                    : 'Objetivos de la campaña'}
                </h4>
                <div className="campaign__goals">
                  {/* <div> */}
                  {objectives_loading ? (
                    <div className="spinner-loading">
                      <Spinner type="purple" />
                    </div>
                  ) : (
                    objectives.map((objective) => (
                      <OptionCheckBox
                        key={objective.id}
                        value={objective}
                        label={
                          site_language === 'es'
                            ? objective.name
                            : objective.english_name
                        }
                        // checked={campaign_objectives.includes(objective.id)}
                        checked={
                          campaign_objectives.some(
                            (obj) => obj.id === objective.id
                          ) || false
                        }
                        handleInputChange={addOrRemoveCampaignObjective}
                        itemObject={true}
                      />
                    ))
                  )}
                </div>
              </div>
              <div
                className="influencer__filter__section"
                id="influencer_age_range"
              >
                <h4 className="filter__subtitle">
                  {text_age_range ? text_age_range : 'Rango de edad'}
                </h4>
                <div className="influencer__age">
                  <MultiRange
                    min={18}
                    max={70}
                    title={text_to || 'entre'}
                    defaultMin={campaign_min_age_range}
                    defaultMax={campaign_max_age_range}
                    setMinInput={setCampaignMinAgeRange}
                    setMaxInput={setCampaignMaxAgeRange}
                  />
                </div>
              </div>
            </div>
            <div className="form__column">
              <div className="influencer__filter__section">
                <h4 className="filter__subtitle">
                  {text_gender_influencers
                    ? text_gender_influencers
                    : 'Género influencers'}
                </h4>
                <div className="influencer__gender">
                  {targets_loading ? (
                    <div className="spinner-loading">
                      <Spinner type="purple" />
                    </div>
                  ) : (
                    targets.map((target) => (
                      <OptionCheckBox
                        label={target.name}
                        key={target.id}
                        value={target.id}
                        checked={campaign_influencer_gender.includes(target.id)}
                        handleInputChange={setCampaignInfluencerGender}
                      />
                    ))
                  )}
                </div>
              </div>
              <div className="influencer__filter__section">
                <h4 className="filter__subtitle">
                  {text_average_rating
                    ? text_average_rating
                    : 'Promedio calificación'}
                </h4>
                <div className="influencer__score">
                  <Score
                    score={campaign_influencer_rating}
                    setScore={setCampaignInfluencerRating}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form__column">
        <div className="campaign__block">
          <div className="influencer__ranges">
            <h4 className="filter__subtitle">Followers</h4>
            <MultiRange
              min={10000}
              max={200000}
              defaultMin={campaign_min_followers_range}
              defaultMax={campaign_max_followers_range}
              setMinInput={setCampaignMinFollowersRange}
              setMaxInput={setCampaignMaxFollowersRange}
              title={text_from_date ? text_from_date : 'desde'}
              more={true}
            />
          </div>
          <div className="influencer__ranges">
            <h4 className="filter__subtitle">
              {text_average_likes ? text_average_likes : 'Promedio likes'}
            </h4>
            <MultiRange
              min={10000}
              max={200000}
              title={text_from_date ? text_from_date : 'desde'}
              more={true}
              defaultMin={campaign_min_likes_range}
              defaultMax={campaign_max_likes_range}
              setMinInput={setCampaignMinLikesRange}
              setMaxInput={setCampaignMaxLikesRange}
            />
          </div>
          <div className="influencer__ranges">
            <h4 className="filter__subtitle">Views</h4>
            <MultiRange
              min={10000}
              max={200000}
              title={text_from_date ? text_from_date : 'desde'}
              more={true}
              defaultMin={campaign_min_views_range}
              defaultMax={campaign_max_views_range}
              setMinInput={setCampaignMinViewsRange}
              setMaxInput={setCampaignMaxViewsRange}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = ({
  campaignReducer,
  targetReducer,
  objectivesReducer,
  siteReducer,
}) => {
  return {
    campaignReducer,
    targetReducer,
    objectivesReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  setCampaignMaxAgeRange,
  setCampaignMinAgeRange,
  setCampaignMaxFollowersRange,
  setCampaignMinFollowersRange,
  setCampaignMinLikesRange,
  setCampaignMaxLikesRange,
  setCampaignMinViewsRange,
  setCampaignMaxViewsRange,
  setCampaignInfluencerGender,
  addOrRemoveCampaignObjective,
  setCampaignInfluencerRating,
  getObjectives,
  getTargets,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFormStep4);
