import React from "react";
import "./css/ListShoppingCart.css";
import BoxWhite from "./BoxWhite";
import ItemShoppingCart from "./ItemShoppingCart";
import { connect } from "react-redux";

function ListShoppingCart(props) {
  const {
    siteReducer: {
      site_texts: {
        text_product_or_service,
        text_unit_price,
        text_amount,
        text_cart_price,
      },
    },
  } = props;

  return (
    <BoxWhite>
      <div className="table-responsive-md">
        <table className="table itemShoppingCart_table">
          <thead className="itemShoppingCart_thead">
            <tr>
              <td className="align-middle">
                {text_product_or_service
                  ? text_product_or_service
                  : "PRODUCTO O SERVICIO"}
              </td>
              <td className="align-middle">
                {text_unit_price ? text_unit_price : "PRECIO UNITARIO"}
              </td>
              <td className="align-middle">
                {text_amount ? text_amount.toUpperCase() : "CANTIDAD"}
              </td>
              <td className="align-middle">
                {text_cart_price ? text_cart_price : "PRECIO"}
              </td>
              <td className="align-middle"></td>
            </tr>
          </thead>
          <tbody>
            {props.data &&
              props.data.map((item) => {
                return <ItemShoppingCart data={item} key={item.id} />;
              })}
          </tbody>
        </table>
      </div>
    </BoxWhite>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(ListShoppingCart);
