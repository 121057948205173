import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PaymentMethodsView from "./views/PaymentMethodsView";
import ManagerLayout from "./layouts/ManagerLayout";

import * as paymentActions from "../../../functionality/actions/paymentActions";
import TopSpinner from "../../../components/all/TopSpinner";

const { paymentClear, paymentList } = paymentActions;

class PaymentMethodsPage extends Component {
  componentDidMount() {
    const { paymentList } = this.props;
    paymentList();
  }

  componentWillUnmount() {
    const { paymentClear } = this.props;
    paymentClear();
  }

  handleModal = (data) => {
    let cardData = {
      id: null,
      method: null,
      card_number: null,
      type: null,
      dues: null,
      default: null,
    };
    if (data) {
      cardData = data;
    }
    this.setState({
      viewModal: this.state.viewModal ? false : true,
      cardData,
    });
  };
  render() {
    const {
      payment_loaded,
      siteReducer: {
        site_texts: { text_payment_methods, text_show_history },
      },
    } = this.props;

    return (
      <ManagerLayout>
        <div className="title-manager">
          <div>
            <span className="icon-card" />
            {text_payment_methods ? text_payment_methods : "Métodos de Pago"}
          </div>
          <Link to="/manager/payment/historial" className="btn btn-pymex">
            {text_show_history ? text_show_history : "Ver historial"}
          </Link>
        </div>
        {payment_loaded ? <TopSpinner /> : <PaymentMethodsView />}
      </ManagerLayout>
    );
  }
}

const mapStateToProps = ({ paymentReducer, siteReducer }) => {
  return {
    ...paymentReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  paymentClear,
  paymentList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsPage);
