import React from "react";
import { connect } from "react-redux";

function Select(props) {
  const {
    siteReducer: {
      site_language,
      site_texts: { text_select },
    },
    value,
    onChange,
    options,
  } = props;

  return (
    <select
      className="questions_select"
      value={value}
      onChange={(input) => onChange(input.target.value)}
    >
      <option value="">-- {text_select} --</option>
      {options.map((item) => (
        <option value={item.id} key={item.id}>
          {site_language === "en" && item.english_name
            ? item.english_name
            : item.name}
        </option>
      ))}
    </select>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(Select);
