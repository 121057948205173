import Swal from 'sweetalert2';
import Api from '../Api';
import { TARGETS, TARGETS_LOADING, TARGETS_USER } from '../types/targetTypes';

export const changeTargets = (payload) => (dispatch) => {
  dispatch({
    type: TARGETS,
    payload,
  });
};

export const changeTargetsLoading = (payload) => (dispatch) => {
  dispatch({
    type: TARGETS_LOADING,
    payload,
  });
};

export const changeTargetsUser = (payload) => (dispatch) => {
  dispatch({
    type: TARGETS_USER,
    payload,
  });
};

const addOrRemoveTargets = (targets, addTag) => {
  const isAdded = targets.find((tag) => tag.id === addTag.id);

  if (isAdded) {
    targets = targets.filter((tag) => tag.id !== addTag.id);
  } else {
    targets.push(addTag);
  }

  return targets;
};

export const addOrRemoveTargetsUser = (payload) => (dispatch, getState) => {
  const { targets_user } = getState().targetReducer;
  let targets = addOrRemoveTargets(targets_user, payload);

  dispatch(changeTargetsUser(targets));
};

export const getTargets = () => async (dispatch) => {
  dispatch(changeTargetsLoading(true));
  try {
    const response = await Api.asyncCallMethod('v1/targets', 'GET');
    dispatch(changeTargets(response.response.data));
    dispatch(changeTargetsLoading(false));
  } catch (error) {
    console.log('Error getTargets: ', error);
    dispatch(changeTargetsLoading(false));
  }
};

export const saveTargets = () => async (dispatch, getState) => {
  const { targets_user } = getState().targetReducer;
  const {
    site_texts: { text_influencer_error_7 },
  } = getState().siteReducer;

  const data = {
    targets: targets_user,
  };

  try {
    const response = await Api.asyncCallMethod(
      `v1/users/targets`,
      'POST',
      data
    );
    dispatch(changeTargetsUser(response.response.data));
  } catch (error) {
    console.log('Error saveTargets: ', error);
    Swal.fire({
      title: 'Upsss!!!',
      text: text_influencer_error_7 || 'Error al guardar el publico objetivo',
      type: 'error',
      confirmButtonColor: '#50318B',
    });
  }
};
