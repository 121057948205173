import React from 'react';
import Spinner from '../../Spinner';
import PostRow from './PostRow/PostRow';
import Paginator from '../../Paginator/Paginator';
import './PostTable.css';
import { connect } from 'react-redux';

const PostTable = (props) => {
  const {
    currentPage,
    totalPage,
    loading,
    data,
    typeUserId,
    openModal,
    CloseModal,
    handleViewPost,
    inChat,
    siteReducer: {
      site_texts: {
        text_consecutive,
        text_name,
        text_social_networks,
        text_type_post,
        text_actions,
        text_created_by,
      },
    },
  } = props;
  return (
    <>
      {loading ? (
        <div className="spinner-loading">
          <Spinner type="purple" />
        </div>
      ) : (
        <>
          <div className="postTable__container">
            <table
              className="post__table"
              style={{ marginTop: inChat ? '0' : '' }}
            >
              <thead>
                <tr>
                  <th>{text_consecutive ? text_consecutive : 'Consecutivo'}</th>
                  <th>{text_name ? text_name : 'Nombre'}</th>
                  {!inChat && (
                    <th>
                      {text_social_networks
                        ? text_social_networks
                        : 'Redes sociales'}
                    </th>
                  )}
                  <th>Status</th>
                  {!inChat && (
                    <th>{text_type_post ? text_type_post : 'Tipo de post'}</th>
                  )}
                  {typeUserId !== 8 && !inChat && (
                    <th>{text_created_by ? text_created_by : 'Creado por'}</th>
                  )}
                  <th>{text_actions ? text_actions : 'Acciones'}</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {data.length !== 0 &&
                    data.map((postData) => (
                      <PostRow
                        key={postData.id}
                        data={postData}
                        typeUserId={typeUserId}
                        openModal={openModal}
                        handleViewPost={handleViewPost}
                        inChat={inChat}
                      />
                    ))}
                </>
              </tbody>
            </table>
          </div>
          {totalPage > 1 && (
            <Paginator
              totalPage={totalPage}
              currentPage={currentPage}
              inChat={inChat}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(PostTable);
