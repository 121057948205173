import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "rc-datepicker/lib/style.css";
import "moment/locale/es.js";

import * as userActions from "../../../functionality/actions/userActions";

const MySwal = withReactContent(Swal);

class SettingsStep1 extends Component {
  componentDidMount() {
    const {
      userReducer: { user_data },
      changeDocDate,
    } = this.props;

    if (user_data.doc_expedition_date && user_data.doc_expedition_date !== "") {
      changeDocDate(user_data.doc_expedition_date);
    }
  }

  showError = (message) => {
    MySwal.fire({
      title: "Upsss!!!",
      text: message,
      type: "warning",
      confirmButtonColor: "#50318B",
      onClose: () => this.props.userClearError(),
    });
  };

  updateProfile = () => {
    let {
      userReducer: { doc_expedition_date, profession, doc_type, doc_number },
      siteReducer: {
        site_texts: {
          text_issuance_document,
          text_write_profession,
          text_issuance_document_error,
        },
      },
      userUpdate,
    } = this.props;
    if (doc_expedition_date === "") {
      this.showError(
        text_issuance_document
          ? text_issuance_document
          : "Debes seleccionar la fecha de Expedición del Documento."
      );
      return false;
    }
    const expeditionDate = doc_expedition_date.split("-");

    if (
      expeditionDate[0].length < 4 ||
      parseInt(expeditionDate[1]) > 12 ||
      parseInt(expeditionDate[2]) > 31
    ) {
      this.showError(
        text_issuance_document_error
          ? text_issuance_document_error
          : "La fecha de expedición del documento debe tener el formato (yyyy-mm-dd)"
      );
      return;
    }
    if (profession === "") {
      this.showError(
        text_write_profession
          ? text_write_profession
          : "Debes escribir la profesión."
      );
      return false;
    }
    let d = new Date(doc_expedition_date);
    let year = d.getFullYear();
    let month =
      d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    let day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    let date = `${year}-${month}-${day}`;
    doc_expedition_date = date;
    let data = { doc_expedition_date, profession, doc_type, doc_number };
    userUpdate(data, 2);
  };

  render() {
    const {
      userReducer: {
        user_data,
        doc_expedition_date,
        profession,
        doc_type,
        doc_number,
      },
      siteReducer: {
        site_texts: {
          text_representative,
          text_legal_representative_data,
          text_name,
          text_surnames,
          text_doc_number,
          text_select,
          text_cc,
          text_ce,
          text_pnn,
          //   text_ssn,
          //   text_lic,
          text_document_issue_date,
          text_email,
          text_position_representative,
          text_continue,
        },
      },
      changeDocDate,
      changeProfession,
      changeDocType,
      changeDocNumber,
    } = this.props;
    let readOnlyDate =
      user_data.doc_expedition_date && user_data.doc_expedition_date !== ""
        ? true
        : false;
    let readOnlyProfession =
      user_data.profession && user_data.profession !== "" ? true : false;
    let valueProfession = readOnlyProfession
      ? user_data.profession
      : profession;
    let readOnlyDocType =
      user_data.doc_type && user_data.doc_type !== "" ? true : false;
    let valueDocType = readOnlyDocType ? user_data.doc_type : doc_type;
    let readOnlyDocNumber =
      user_data.doc_number && user_data.doc_number !== "" ? true : false;
    let valueDocNumber = readOnlyDocNumber ? user_data.doc_number : doc_number;

    return (
      <Fragment>
        <div className="settingsView_title">
          {text_representative
            ? text_representative
            : "INFORMACIÓN REPRESENTANTE LEGAL"}
        </div>
        <div className="settingsView_description">
          {text_legal_representative_data
            ? text_legal_representative_data
            : "Sólo el propietario o representante legal es quien debe realizar la apertura de la cuenta en BAX. Si está intentando activar está cuenta a nombre de otra persona la activación será rechazada."}
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>{text_name ? text_name.toUpperCase() : "NOMBRE"}</label>
            <input
              type="text"
              placeholder={text_name ? text_name.toUpperCase() : "NOMBRE"}
              className="form-control"
              value={user_data.name}
              readOnly
            />
          </div>
          <div className="col-md-6">
            <label>
              {text_surnames ? text_surnames.toUpperCase() : "APELLIDOS"}
            </label>
            <input
              type="text"
              placeholder={
                text_surnames ? text_surnames.toUpperCase() : "APELLIDOS"
              }
              className="form-control"
              value={user_data.lastname}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>
              {text_doc_number
                ? text_doc_number.toUpperCase()
                : "DOCUMENTO DE IDENTIFICACIÓN"}
            </label>
            <div className="row">
              <div className="col-sm-6">
                <select
                  className="form-control"
                  value={valueDocType}
                  onChange={(input) => changeDocType(input.target.value)}
                  readOnly={readOnlyDocType}
                >
                  <option>
                    -- {text_select ? text_select : "Seleccionar"} --
                  </option>
                  <option value="CC">
                    {text_cc ? text_cc : "CÉDULA DE CIUDADANÍA"}
                  </option>
                  <option value="CE">
                    {text_ce ? text_ce : "CÉDULA DE EXTRANJERÍA"}
                  </option>
                  <option value="PPN">
                    {text_pnn ? text_pnn : "PASAPORTE"}
                  </option>
                </select>
              </div>
              <div className="col-sm-6">
                <input
                  type="number"
                  placeholder="NÚMERO DOCUMENTO"
                  className="form-control"
                  value={valueDocNumber}
                  onChange={(input) => changeDocNumber(input.target.value)}
                  readOnly={readOnlyDocNumber}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <label>
              {text_document_issue_date
                ? text_document_issue_date
                : "FECHA DE EXPEDICIÓN DEL DOCUMENTO (yyyy-mm-dd)"}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="yyyy-mm-dd"
              onChange={(input) => changeDocDate(input.target.value)}
              value={doc_expedition_date}
              maxLength={10}
              readOnly={
                user_data.doc_expedition_date &&
                user_data.doc_expedition_date !== ""
                  ? "readonly"
                  : ""
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>
              {text_email ? text_email.toUpperCase() : "CORREO ELECTRÓNICO"}
            </label>
            <input
              type="email"
              placeholder={
                text_email ? text_email.toUpperCase() : "CORREO ELECTRÓNICO"
              }
              className="form-control"
              value={user_data.email}
              readOnly
            />
          </div>
          <div className="col-md-6">
            <label>
              {text_position_representative
                ? text_position_representative
                : "CARGO (Representante Legal - Gerente)"}
            </label>
            <input
              type="text"
              placeholder={
                text_position_representative
                  ? text_position_representative
                  : "CARGO (Representante Legal - Gerente)"
              }
              className="form-control"
              onChange={(input) => changeProfession(input.target.value)}
              value={valueProfession}
              readOnly={readOnlyProfession}
            />
          </div>
        </div>
        {!readOnlyProfession ||
        !readOnlyDate ||
        !readOnlyDocType ||
        !readOnlyDocNumber ? (
          <div className="d-flex justify-content-end">
            <button className="settingsView_next" onClick={this.updateProfile}>
              <span className="icon-chek" />
              {text_continue ? text_continue : "Continuar"}
            </button>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, userActions)(SettingsStep1);
