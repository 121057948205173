import {
  COUNTRY_LOADING,
  COUNTRIES,
  COUNTRY_SELECTED,
  COUNTRY_CITIES_LOADING,
  COUNTRY_CITIES,
  COUNTRY_CITY_SELECTED,
  COUNTRY_CITIES_SELECTED,
  COUNTRY_MULTIPLE_CITIES,
} from '../types/countryTypes';

const INITIAL_STATE = {
  country_loading: false,
  countries: [],
  country_selected: null,
  country_cities_loading: false,
  country_cities: [],
  country_city_selected: null,
  country_multiple_cities: null,
  country_cities_selected: [],
};

const countryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COUNTRY_LOADING:
      return {
        ...state,
        country_loading: action.payload,
      };
    case COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case COUNTRY_SELECTED:
      return {
        ...state,
        country_selected: action.payload,
      };
    case COUNTRY_CITIES_LOADING:
      return {
        ...state,
        country_cities_loading: action.payload,
      };
    case COUNTRY_CITIES:
      return {
        ...state,
        country_cities: action.payload,
      };
    case COUNTRY_CITY_SELECTED:
      return {
        ...state,
        country_city_selected: action.payload,
      };
    case COUNTRY_MULTIPLE_CITIES:
      return {
        ...state,
        country_multiple_cities: action.payload,
      };
    case COUNTRY_CITIES_SELECTED:
      return {
        ...state,
        country_cities_selected: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default countryReducer;
