import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getProductsByUser } from '../../../functionality/actions/productActions';
import ProductList from '../../user/manager/ProductList';
import TopSpinner from '../TopSpinner';
import Pagination from '../Pagination';

function Products(props) {
  const {
    productReducer: {
      product_loading,
      product_list_manager_view,
      product_current_page,
      product_item_per_page,
      product_search_manager_list,
    },
    siteReducer: {
      site_texts: { text_no_products },
    },
    getProductsByUser,
    userId,
  } = props;

  useEffect(() => {
    getProductsByUser(userId);
  }, []);

  const handleChangePage = (currentPage, initView, endView) => {
    let { product_search_manager_list, productChangePage } = this.props;
    let viewProducts = product_search_manager_list.slice(initView, endView);
    productChangePage(viewProducts, currentPage);
  };

  return (
    <>
      {product_loading && <TopSpinner />}
      {!product_loading && product_list_manager_view.length > 0 ? (
        <>
          <ProductList data={product_list_manager_view} actions={false} />
          <Pagination
            data={product_search_manager_list}
            itemsPerPage={product_item_per_page}
            currentPage={product_current_page}
            changePage={handleChangePage}
          />
        </>
      ) : (
        !product_loading && <div>{text_no_products || 'No hay productos'}</div>
      )}
    </>
  );
}

const mapStateToProps = ({ productReducer, siteReducer }) => {
  return {
    productReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  getProductsByUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
