import React, { Fragment } from 'react';
import './css/Inbox.css';
import InboxChats from '../../../../components/user/manager/InboxChats';
import ChatMessage from '../../../../components/user/manager/ChatMessage';
import PorposeChat from '../../../../components/user/manager/PorposeChat/PorposeChat';
import { connect } from 'react-redux';

function InboxDetailView(props) {
  const {
    userReducer: { user_data },
    data,
    actions,
    handleFilter,
    dataPorposal,
    viewBoxMessage,
    codInbox,
    siteReducer: {
      site_texts: { text_proposals },
    },
  } = props;
  return (
    <Fragment>
      <div className="row">
        <div className="col-md-5 col-lg-4">
          <InboxChats
            data={data}
            actions={actions}
            handleFilter={handleFilter}
          />

          <PorposeChat
            chats={dataPorposal}
            path={'/manager/inbox/porposal/'}
            inboxChat={true}
            title={text_proposals || 'Propuestas'}
          />
        </div>
        <div className="col-md-7 col-lg-8">
          {viewBoxMessage && <ChatMessage cod_chat={codInbox} />}
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(InboxDetailView);
