import React from "react";
import "./css/Comments.css";
import Image from "../../images/believe.jpg";

function Comments(props) {
  return (
    <div className="comments_box">
      {props.data.map((item, index) => {
        return (
          <div className="comments_item" key={index}>
            <div className="comments_header">
              <div className="comments_users">
                {item.user.image ? (
                  <img src={item.user.image} alt={item.user.username} />
                ) : (
                  <img src={Image} alt={item.user.username} />
                )}
                {item.user.username ? (
                  <div className="comments_name">{item.user.username}</div>
                ) : (
                  <div className="comments_name">{item.user.business_name}</div>
                )}
              </div>
              <div className="comments_date">{item.created_at}</div>
            </div>
            {item.comment}
          </div>
        );
      })}
    </div>
  );
}

export default Comments;
