import React, { Component } from "react";
import "./css/GalleryImages.css";

class GalleryImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      principalImage: null,
    };
  }

  componentDidMount() {
    this.setState({
      principalImage: this.props.images[0],
    });
  }

  renderButtonImages = () => {
    let { images } = this.props;
    let menu = [];
    for (let i = 0; i < images.length; i++) {
      let is_active =
        this.state.principalImage &&
        this.state.principalImage.id === images[i].id
          ? "is_active"
          : "";

      menu.push(
        <button
          onClick={() => this.changeImage(images[i])}
          key={i}
          className={`${is_active}`}
        >
          <img src={images[i].image} alt={i} />
        </button>
      );
    }
    return menu;
  };

  changeImage = (image) => {
    this.setState({
      principalImage: image,
    });
  };

  render() {
    return (
      <div className="galleryImages_container">
        {this.state.principalImage && (
          <img
            src={this.state.principalImage.image}
            alt="Imagen Principal"
            className="galleryImages_image-principal"
          />
        )}
        {this.props.images.length > 1 && (
          <div className="galleryImages_menu">{this.renderButtonImages()}</div>
        )}
      </div>
    );
  }
}

export default GalleryImages;
