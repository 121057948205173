import React from 'react';
import Keywords from '../../keywords/Keywords';
import UploadImage from '../../UploadImage/UploadImage';
import {
  addOrRemoveTagsCampaign,
  setCampaignImages,
  deleteCampaignImage,
} from '../../../../functionality/actions/campaignActions';
import { connect } from 'react-redux';

const CampaignFormStep3 = (props) => {
  const {
    siteReducer: { site_language },
    campaignReducer: { campaign_tags, campaign_images, campaign_consecutive },
    addOrRemoveTagsCampaign,
    setCampaignImages,
    deleteCampaignImage,
  } = props;

  return (
    <form className="campaign__form">
      <div className="form__column">
        <div className="campaign__block">
          <div className="keywords__conatiner">
            <Keywords
              onChange={addOrRemoveTagsCampaign}
              selected={campaign_tags}
              objectName={site_language === 'es' ? 'name' : 'english_name'}
              title={'keywords influencers'}
            />
          </div>
        </div>
      </div>
      <div className="form__column">
        <div className="campaign__block">
          <UploadImage
            route={`v1/campaigns/${campaign_consecutive}/images`}
            stateToUpdate={campaign_images}
            updateMethod={setCampaignImages}
            deleteMethod={deleteCampaignImage}
          />
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = ({ siteReducer, campaignReducer }) => {
  return {
    siteReducer,
    campaignReducer,
  };
};
const mapDispatchToProps = {
  addOrRemoveTagsCampaign,
  setCampaignImages,
  deleteCampaignImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFormStep3);
