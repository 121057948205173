export const CHAT_LOADING = 'CHAT_LOADING';
export const CHAT_MESSAGES_LOADING = 'CHAT_MESSAGES_LOADING';
export const CHAT_MESSAGES_ERROR = 'CHAT_MESSAGES_ERROR';
export const CHAT_SEND_LOADING = 'CHAT_SEND_LOADING';
export const CHAT_ERROR = 'CHAT_ERROR';
export const CHAT_ALERT_ERROR = 'CHAT_ALERT_ERROR';
export const CHAT_CLEAR_ERROR = 'CHAT_CLEAR_ERROR';
export const CHAT_LIST = 'CHAT_LIST';
export const CHAT_LIST_PROPOSAL = 'CHAT_LIST_PROPOSAL';
export const CHAT_LIST_VIEW = 'CHAT_LIST_VIEW';
export const CHAT_MESSAGES = 'CHAT_MESSAGES';
export const CHAT_MESSAGE = 'CHAT_MESSAGE';
export const CHAT_SUBJECT = 'CHAT_SUBJECT';
export const CHAT_TYPE = 'CHAT_TYPE';
export const CHAT_TYPE_ID = 'CHAT_TYPE_ID';
export const CHAT_CLEAR_MESSAGE = 'CHAT_CLEAR_MESSAGE';
export const CHAT_CLEAR = 'CHAT_CLEAR';
export const CHAT_FOR = 'CHAT_FOR';
export const CHAT_COD_FOR = 'CHAT_COD_FOR';
export const CHAT_NAME_FOR = 'CHAT_NAME_FOR';
export const CHAT_SELECT_USERS = 'CHAT_SELECT_USERS';
export const CHAT_CLEAR_SELECT_USERS = 'CHAT_CLEAR_SELECT_USERS';
export const CHAT_NEW_MESSAGE = 'CHAT_NEW_MESSAGE';
export const CHAT_SELECTED = 'CHAT_SELECTED';
export const CHAT_LIST_NOTIFICATION = 'CHAT_LIST_NOTIFICATION';
export const CHAT_RESPONSE = 'CHAT_RESPONSE';
