export const PORPOSAL_CLEAR = 'PORPOSAL_CLEAR';
export const PORPOSAL_NAME = 'PORPOSAL_NAME';
export const PORPOSAL_IMAGES = 'PORPOSAL_IMAGES';
export const PORPOSAL_VIDEO = 'PORPOSAL_VIDEO';
export const PORPOSAL_STATUS_ID = 'PORPOSAL_STATUS_ID';
export const PORPOSAL_DELIVERABLES = 'PORPOSAL_DELIVERABLES';
export const PORPOSAL_PRICE = 'PORPOSAL_PRICE';
export const PORPOSAL_LOADING = 'PORPOSAL_LOADING';
export const PORPOSAL_SOCIAL_NETWORK = 'PORPOSAL_SOCIAL_NETWORK';
export const PORPOSAL_LIST = 'PORPOSAL_LIST';
export const SET_PORPOSAL_EDIT = 'SET_PORPOSAL_EDIT';
export const PORPOSAL_CONSECUTIVE = '  PORPOSAL_CONSECUTIVE';
export const CURRENT_PORPOSAL = 'CURRENT_PORPOSAL';
export const PORPOSAL_VIDEO_LOADING = 'PORPOSAL_VIDEO_LOADING';
export const PORPOSAL_CAMPAIGNS = 'PORPOSAL_CAMPAIGNS';
export const PORPOSAL_CAMPAIGNS_ACCEPTED = 'PORPOSAL_CAMPAIGNS_ACCEPTED';
export const PORPOSAL_CAMPAIGNS_DECLINED = 'PORPOSAL_CAMPAIGNS_DECLINED';
export const PROPOSAL_TYPE = 'PROPOSAL_TYPE';
export const PORPOSAL_LOADING_DETAIL = 'PORPOSAL_LOADING_DETAIL';
