import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  influencerRequestAprovation,
  userSaveDocs,
  userUpdate,
  changeDocType,
  changeDocNumber,
  changeDocDate,
  changeRutScann,
  changeUserProviders,
  changeRiskCenters,
  changeUserValidInformation,
  changeDocFront,
  changeDocBack,
  openLoading,
  changePrincipalNetwork,
  savePrincipalNetwork,
  changeBirthdate,
  changeUsername,
} from '../../functionality/actions/userActions';
import { addOrRemoveTagsUser } from '../../functionality/actions/tagsActions';
import { getSocialNetworks } from '../../functionality/actions/generalActions';
import {
  saveTags,
  savePrincipalTag,
  changePricipalTag,
} from '../../functionality/actions/tagsActions';
import {
  getTargets,
  addOrRemoveTargetsUser,
  saveTargets,
} from '../../functionality/actions/targetsActions';
import { cropperImage } from '../../functionality/actions/cropperActions';
import {
  getCountries,
  changeCountrySelected,
  changeCitySelected,
  addOrRemoveCities,
  changeMultipleCities,
  setUserCities,
} from '../../functionality/actions/countryAcitons';
import Questions from '../Questions';
import AddSocialNetworks from '../AddSocialNetworks';
import Spinner from '../all/Spinner';
import Keywords from '../all/keywords/Keywords';
import AddCompanies from '../user/manager/AddCompanies';
import InputFile from '../Questions/InputFile';
import CropImages from '../all/CropImages';
import Api from '../../functionality/Api';
import SelectSearch from '../SelectSearch';

class InfluencerRegister extends Component {
  async componentDidMount() {
    const {
      generalReducer: { general_social_networks },
      targetReducer: { targets },
      countryReducer: { countries },
      getSocialNetworks,
      getTargets,
      getCountries,
    } = this.props;

    if (!general_social_networks.length) {
      getSocialNetworks();
    }
    if (!targets.length) {
      getTargets();
    }
    if (!countries.length) {
      getCountries();
    }
  }

  onChangeImage = (e, imageName, width = 500, height = 500) => {
    const { cropperImage } = this.props;
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    this.setState({
      imageName,
    });

    const reader = new FileReader();
    reader.onload = () => {
      let config = {
        cropper_image: reader.result,
        cropper_ratio_width: width,
        cropper_ratio_height: height,
      };
      cropperImage(config);
    };
    reader.readAsDataURL(files[0]);
  };

  onCrop = async (image) => {
    const {
      siteReducer: { text_uploading_image },
      openLoading,
    } = this.props;
    const { imageName } = this.state;

    if (imageName === 'img_profile') {
      openLoading(
        text_uploading_image ? text_uploading_image : 'Subiendo Imagen...'
      );
      let data = new FormData();
      data.append('imgBase64', image);
      Api.uploadFile(data, (res) => {
        if (res.location) {
          document.getElementById(imageName).value = '';
          this.userUpdate(res.location);
        }
      });
      return;
    }
  };

  userUpdate = (image) => {
    const { userUpdate } = this.props;
    const { imageName } = this.state;

    let data;
    if (imageName === 'img_profile') {
      data = {
        image,
      };
    }

    userUpdate(data);
  };

  render() {
    const {
      influencerRequestAprovation,
      userSaveDocs,
      userUpdate,
      siteReducer: {
        site_language,
        site_texts: {
          text_cc,
          text_ce,
          text_pnn,
          text_social_network_work,
          text_influencer_questions_2,
          text_influencer_questions_3,
          text_influencer_questions_4,
          text_influencer_questions_5,
          text_influencer_questions_6,
          text_influencer_questions_7,
          text_influencer_questions_8,
          text_rut,
          text_upload_pdf,
          text_questions_21,
          text_no,
          text_yes,
          text_questions_22,
          text_risk_centers,
          text_request_approval,
          text_valid_information,
          text_influencer_questions_9,
          text_influencer_questions_10,
          text_influencer_questions_11,
          text_show_image,
          text_updalod_image,
          text_company_logo_description,
          text_front_face,
          text_upload_front_face,
          text_upper_side,
          text_upload_back_face,
          text_influencer_questions_12,
          text_influencer_questions_13,
          text_influencer_questions_13_b,
          text_select_coutry,
          text_select_city,
          text_select_principal_network,
          text_birthdate,
          text_write_username,
          text_remember,
        },
      },
      generalReducer: { general_social_networks },
      userReducer: {
        user_social_networks,
        doc_type,
        doc_number,
        doc_expedition_date,
        rut_scann,
        user_providers,
        user_valid_information,
        user_risk_centers,
        doc_scan_front,
        doc_scan_back,
        user_data,
        user_principal_network,
        user_birthdate,
        username,
        username_error,
      },
      tagsReducer: { tags_user, principal_tag },
      targetReducer: { targets, targets_user },
      countryReducer: {
        countries,
        country_cities,
        country_loading,
        country_cities_loading,
        country_city_selected,
        country_multiple_cities,
        country_cities_selected,
      },
      changeDocType,
      changeDocNumber,
      changeDocDate,
      addOrRemoveTagsUser,
      saveTags,
      changePricipalTag,
      savePrincipalTag,
      addOrRemoveTargetsUser,
      saveTargets,
      changeRutScann,
      changeUserProviders,
      changeRiskCenters,
      changeUserValidInformation,
      changeDocFront,
      changeDocBack,
      changeCountrySelected,
      changeCitySelected,
      addOrRemoveCities,
      changeMultipleCities,
      setUserCities,
      changePrincipalNetwork,
      savePrincipalNetwork,
      changeBirthdate,
      changeUsername,
    } = this.props;

    const questions = [
      {
        step: 1,
        question:
          text_social_network_work ||
          'Selecciona las redes sociales con las que trabajas',
        content: false,
        type: 'children',
        menu: user_social_networks.length,
        children: (
          <>
            {!general_social_networks.length ? (
              <div className="spinner-loading">
                <Spinner type="purple" />
              </div>
            ) : (
              <AddSocialNetworks />
            )}
          </>
        ),
        save: () => null,
      },
      {
        step: 2,
        question:
          text_select_principal_network || 'Selecciona tu red social principal',
        options: user_social_networks,
        type: 'select-option',
        onChange: changePrincipalNetwork,
        value: user_principal_network,
        content: true,
        buttonClass: 'questions_option',
        menu: user_principal_network && user_principal_network !== '',
        save: savePrincipalNetwork,
      },
      {
        step: 3,
        question: text_write_username || 'Escribe el nombre de usuario',
        note: text_remember || '',
        content: true,
        value: username,
        menu: username && username !== '' && username_error === '',
        onChange: changeUsername,
        save: () => {
          userUpdate({ username });
        },
      },
      {
        step: 4,
        question:
          text_birthdate || 'Escribe tu fecha de nacimiento (yyyy-mm-dd)',
        onChange: changeBirthdate,
        value: user_birthdate,
        content: true,
        menu: user_birthdate !== '' && user_birthdate.length === 10,
        placeholder: 'yyyy-mm-dd',
        save: () => {
          userUpdate({
            birthdate: user_birthdate,
          });
        },
      },
      {
        step: 5,
        question:
          text_influencer_questions_2 ||
          'Tipo de documento representante legal',
        options: [
          { id: 'CC', name: text_cc ? text_cc : 'CÉDULA DE CIUDADANÍA' },
          { id: 'CE', name: text_ce ? text_ce : 'CÉDULA DE EXTRANJERÍA' },
          { id: 'PPN', name: text_pnn ? text_pnn : 'PASAPORTE' },
        ],
        type: 'select-option',
        onChange: changeDocType,
        value: doc_type,
        content: true,
        buttonClass: 'questions_option',
        menu: doc_type !== '',
        save: () => {
          userUpdate({
            doc_type,
          });
        },
      },
      {
        step: 6,
        question:
          text_influencer_questions_3 || 'Escribe tu número de documento',
        onChange: changeDocNumber,
        value: doc_number,
        content: true,
        menu: doc_number !== '',
        inputType: 'number',
        save: () => {
          userUpdate({
            doc_number,
          });
        },
      },
      {
        step: 7,
        question:
          text_influencer_questions_4 ||
          'Fecha de expedición del documento (yyyy-mm-dd)',
        onChange: changeDocDate,
        value: doc_expedition_date,
        content: true,
        menu: doc_expedition_date !== '' && doc_expedition_date.length === 10,
        placeholder: 'yyyy-mm-dd',
        save: () => {
          userUpdate({
            doc_expedition_date,
          });
        },
      },
      {
        step: 8,
        question:
          text_influencer_questions_5 ||
          'Elige el topic principl de tus contenidos',
        menu: tags_user.length,
        children: (
          <Keywords
            onChange={addOrRemoveTagsUser}
            selected={tags_user}
            objectName={site_language === 'es' ? 'name' : 'english_name'}
          />
        ),
        save: saveTags,
      },
      {
        step: 9,
        question:
          text_influencer_questions_6 ||
          'Elige un topic principl de tus contenidos',
        menu: principal_tag !== '',
        options: tags_user,
        type: 'select',
        onChange: changePricipalTag,
        value: principal_tag,
        content: true,
        buttonClass: 'questions_option',
        save: savePrincipalTag,
      },
      {
        step: 10,
        question:
          text_influencer_questions_7 ||
          'Selecciona el publico objetivo de tus contenidos',
        menu: targets_user.length,
        options: targets,
        type: 'select-option',
        onChange: addOrRemoveTargetsUser,
        value: targets_user,
        content: true,
        buttonClass: 'questions_option',
        multipleSelection: true,
        save: saveTargets,
      },
      {
        step: 11,
        question: text_influencer_questions_8 || 'Adjunta tu RUT',
        content: true,
        menu: rut_scann !== '',
        type: 'input-file',
        inputFileId: 'rut',
        onSaveDoc: (file) => {
          let data = { rut_scann: file };
          changeRutScann(file);
          userSaveDocs(data);
        },
        value: rut_scann,
        inputFileLabel: text_rut || 'RUT',
        acceptInputFile: '.pdf',
        inputFileTitle: text_upload_pdf || 'CARGAR PDF',
        save: () => null,
      },
      {
        step: 12,
        question:
          text_influencer_questions_9 ||
          'Adjunta un escaner o una fotografía del documento tu documento de identidad (Cara frontal)',
        content: true,
        menu: doc_scan_front !== '',
        type: 'input-file',
        inputFileId: 'frontDoc',
        onSaveDoc: (file) => {
          let data = { doc_scan_front: file };
          changeDocFront(file);
          userSaveDocs(data);
        },
        value: doc_scan_front,
        inputFileLabel: text_front_face || 'Cara Frontal',
        acceptInputFile: 'image/*',
        inputFileTitle: text_upload_front_face || 'SUBIR CARA FRONTAL',
        save: () => null,
      },
      {
        step: 13,
        question:
          text_influencer_questions_10 ||
          'Adjunta un escaner o una fotografía del documento tu documento de identidad (Cara posterior)',
        content: true,
        menu: doc_scan_back !== '',
        type: 'input-file',
        inputFileId: 'backDoc',
        onSaveDoc: (file) => {
          let data = { doc_scan_back: file };
          changeDocBack(file);
          userSaveDocs(data);
        },
        value: doc_scan_back,
        inputFileLabel: text_upper_side || 'Cara Posterior',
        acceptInputFile: 'image/*',
        inputFileTitle: text_upload_back_face || 'SUBIR CARA POSTERIOR',
        save: () => null,
      },
      {
        step: 14,
        question:
          text_influencer_questions_11 ||
          'Agrega una fotografía para tu perfil',
        menu: user_data.image && user_data.image !== '',
        save: () => null,
        children: (
          <InputFile
            id="img_profile"
            saveDoc={(file) =>
              this.onChangeImage(file, 'img_profile', 500, 500)
            }
            value={user_data.image || ''}
            label={text_show_image || 'Ver imagen'}
            accept=".jpg, .png, .jpeg"
            title={text_updalod_image || 'Subir la imagen'}
            upload={false}
            description={
              text_company_logo_description ||
              `La imagen debe ser cuadrada con una medida mínima de 500px X 500px`
            }
            preview={true}
          />
        ),
      },
      {
        step: 15,
        question:
          text_influencer_questions_12 ||
          'Selecciona el país y tu ciudad principal donde creas contenido para publicidad',
        menu: country_city_selected,
        save: () => {
          let data = { city_id: country_city_selected.id };
          userSaveDocs(data);
        },
        children: (
          <>
            <SelectSearch
              label={text_select_coutry || 'Selecciona el país'}
              placeholder={text_select_coutry || 'Selecciona el país'}
              options={countries}
              loading={country_loading}
              onChange={changeCountrySelected}
              optionName={site_language === 'es' ? 'name' : 'english_name'}
            />
            <SelectSearch
              label={text_select_city || 'Selecciona la ciudad'}
              placeholder={text_select_city || 'Selecciona la ciudad'}
              options={country_cities}
              loading={country_cities_loading}
              onChange={changeCitySelected}
              optionName="name"
              secondOptionName="department_name"
              value={country_city_selected}
            />
          </>
        ),
      },
      {
        step: 16,
        question:
          text_influencer_questions_13 ||
          '¿Tines más ciudades donde creas contenido para publicidad?',
        menu:
          country_multiple_cities === 0 ||
          (country_multiple_cities === 1 && country_cities_selected.length),
        value: country_multiple_cities,
        onChange: (value) => changeMultipleCities(value),
        options: [
          { id: 0, name: text_no || 'No' },
          { id: 1, name: text_yes || 'Si' },
        ],
        content: true,
        type: 'select-option',
        save: () => {
          if (country_multiple_cities === 1) {
            if (country_cities_selected.length) {
              setUserCities();
            }
          }
        },
        isMultiple: true,
        multipleValidation: 'show',
        multipleRender: () => (
          <>
            {country_multiple_cities === 1 && (
              <>
                <div className="questions_question-text">
                  {text_influencer_questions_13_b || 'Selecciona las ciudades'}
                </div>
                <SelectSearch
                  label={text_select_city || 'Selecciona la ciudad'}
                  options={country_cities}
                  loading={country_cities_loading}
                  onChange={addOrRemoveCities}
                  optionName="name"
                  secondOptionName="department_name"
                  type="multiple"
                  showMultiple={true}
                  selectedList={country_cities_selected}
                />
              </>
            )}
          </>
        ),
      },
      {
        step: 17,
        question:
          text_questions_21 ||
          '¿Tienes proveedores que te gustaría estuvieran en INFLUENX?',
        value: user_providers,
        onChange: (value) => changeUserProviders(value),
        options: [
          { id: 0, name: text_no || 'No' },
          { id: 1, name: text_yes || 'Si' },
        ],
        menu: false,
        content: true,
        type: 'select-option',
        save: () => null,
        isMultiple: true,
        multipleValidation: 'show',
        multipleRender: () => (
          <>
            {user_providers === 1 && (
              <>
                <div className="questions_question-text">
                  {text_questions_22 || 'Escribe los proveedores'}
                </div>
                <div className="questions_content-companies">
                  <div className="questions_companies">
                    <AddCompanies />
                  </div>
                </div>
              </>
            )}
            <div className="settingsView_checkbox mt-3 mb-0">
              <label>
                <input
                  type="checkbox"
                  checked={user_risk_centers ? 'checked' : ''}
                  value={user_risk_centers}
                  onChange={() => changeRiskCenters(!user_risk_centers)}
                />
                {text_risk_centers ||
                  'Autorizó la consulta y verificación de toda la información suministrada, para obtener un cupo en BAX'}
              </label>
            </div>
            <div className="settingsView_checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={user_valid_information ? 'checked' : ''}
                  value={user_risk_centers}
                  onChange={() =>
                    changeUserValidInformation(!user_valid_information)
                  }
                />
                {text_valid_information ||
                  'Certifico que toda la información suministrada en este registro es veraz.'}
              </label>
            </div>
            <div className="questions_menu">
              <button
                className="questions_button"
                onClick={influencerRequestAprovation}
              >
                {text_request_approval || 'SOLICITAR APROBACIÓN'}
              </button>
            </div>
          </>
        ),
      },
    ];

    return (
      <>
        <CropImages onCrop={this.onCrop} />
        <Questions questions={questions} />
      </>
    );
  }
}

const mapStateToProps = ({
  userReducer,
  siteReducer,
  generalReducer,
  tagsReducer,
  targetReducer,
  countryReducer,
}) => {
  return {
    userReducer,
    siteReducer,
    generalReducer,
    tagsReducer,
    targetReducer,
    countryReducer,
  };
};

const mapDispatchToProps = {
  getSocialNetworks,
  influencerRequestAprovation,
  userSaveDocs,
  userUpdate,
  changeDocType,
  changeDocNumber,
  changeDocDate,
  addOrRemoveTagsUser,
  saveTags,
  savePrincipalTag,
  changePricipalTag,
  getTargets,
  addOrRemoveTargetsUser,
  saveTargets,
  changeRutScann,
  changeUserProviders,
  changeRiskCenters,
  changeUserValidInformation,
  changeDocFront,
  changeDocBack,
  cropperImage,
  openLoading,
  getCountries,
  changeCountrySelected,
  changeCitySelected,
  addOrRemoveCities,
  changeMultipleCities,
  setUserCities,
  changePrincipalNetwork,
  savePrincipalNetwork,
  changeBirthdate,
  changeUsername,
};

export default connect(mapStateToProps, mapDispatchToProps)(InfluencerRegister);
