import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { DatePickerInput } from 'rc-datepicker';
import DashboardSquare from '../../../../components/all/DashboardSquare';
import DashboardBalance from '../../../../components/user/manager/DashboardBalance';
import './css/HistorialView.css';
import Pagination from '../../../../components/all/Pagination';
import HistorialList from '../../../../components/user/manager/HistorialList';
import TopSpinner from '../../../../components/all/TopSpinner';

import * as historialActions from '../../../../functionality/actions/historialActions';
import Error from '../../../../components/all/Error';

class HistorialView extends Component {
  componentDidMount() {
    const { loadHistorial } = this.props;
    loadHistorial();
  }

  componentWillUnmount() {
    const { clearHistorial } = this.props;
    clearHistorial();
  }

  changePage = (currentPage, initView, endView) => {
    const {
      changePage,
      historialReducer: {
        historial,
        historial_filter,
        historial_date,
        historial_cod_order,
        historial_type,
      },
    } = this.props;
    let viewHistorial = [];
    if (
      historial_date !== '' ||
      historial_cod_order !== '' ||
      historial_type !== ''
    ) {
      viewHistorial = historial_filter.slice(initView, endView);
    } else {
      viewHistorial = historial.slice(initView, endView);
    }
    let data = {
      page: currentPage,
      data_view: viewHistorial,
    };
    changePage(data);
  };

  historialFilter = () => {
    const {
      historialReducer: {
        historial_date,
        historial_cod_order,
        historial_type,
        historial,
        historial_items_per_page,
      },
      historialFilter,
    } = this.props;
    let filter = [];
    let view_historial = [];
    if (
      historial_date !== '' ||
      historial_cod_order !== '' ||
      historial_type !== ''
    ) {
      if (historial_date !== '') {
        let d = new Date(historial_date);
        let year = d.getFullYear();
        let month =
          d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
        let day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
        let date = `${year}-${month}-${day}`;
        if (filter.length > 0) {
          filter = filter.filter(
            (item) => item.created_at.indexOf(date) !== -1
          );
          view_historial = filter.slice(0, historial_items_per_page);
        } else {
          filter = historial.filter(
            (item) => item.created_at.indexOf(date) !== -1
          );
          view_historial = filter.slice(0, historial_items_per_page);
        }
      }
      if (historial_cod_order !== '') {
        if (filter.length > 0) {
          filter = filter.filter(
            (item) => item.reference === historial_cod_order
          );
          view_historial = filter.slice(0, historial_items_per_page);
        } else {
          filter = historial.filter(
            (item) => item.reference === historial_cod_order
          );
          view_historial = filter.slice(0, historial_items_per_page);
        }
      }
      if (historial_type !== '') {
        if (filter.length > 0) {
          filter = filter.filter(
            (item) =>
              parseInt(item.transaction_type_id) === parseInt(historial_type)
          );
          view_historial = filter.slice(0, historial_items_per_page);
        } else {
          filter = historial.filter(
            (item) =>
              parseInt(item.transaction_type_id) === parseInt(historial_type)
          );
          view_historial = filter.slice(0, historial_items_per_page);
        }
      }
    } else {
      view_historial = historial.slice(0, historial_items_per_page);
    }
    let data = {
      data_view: view_historial,
      filter,
    };
    historialFilter(data);
  };

  render() {
    const {
      historialReducer: {
        historial_loading,
        historial_error,
        historial,
        historial_view,
        historial_items_per_page,
        historial_page,
        historial_date,
        historial_cod_order,
        historial_type,
        historial_filter,
      },
      historialDate,
      historialCodOrder,
      historialTypeOder,
      clearFilterHistorial,
      userReducer: { user_data },
      siteReducer: {
        site_language,
        site_texts: {
          text_my_account,
          text_sales,
          text_purchases,
          text_order_number,
          text_date,
          text_type_of_operation,
          text_select,
          text_filter,
          text_remove_filter,
          text_definition,
          text_number_ccount,
        },
      },
    } = this.props;
    let initial =
      user_data?.bank && user_data?.bank.initial_balance
        ? user_data?.bank.initial_balance
        : 0;
    let total =
      user_data?.bank && user_data?.bank.total ? user_data?.bank.total : 0;
    let total_historial =
      historial_filter.length > 0 ? historial_filter : historial;
    return (
      <Fragment>
        <div className="title-manager">
          <div>
            <span className="icon-historial" />
            {text_my_account ? text_my_account : 'Mi Cuenta BAX'}
          </div>
          <div className="historial_number">
            {text_number_ccount ? text_number_ccount : 'No. Cuenta'}:{' '}
            {user_data?.account_number}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <DashboardSquare
              icon="icon-money"
              title={text_sales ? text_sales.toUpperCase() : 'VENTAS'}
              number={user_data?.total_sales}
            />
          </div>
          <div className="col-md-4">
            <DashboardSquare
              icon="icon-bag"
              title={text_purchases ? text_purchases.toUpperCase() : 'COMPRAS'}
              number={user_data?.total_shopping}
            />
          </div>
          <div className="col-md-4">
            <DashboardBalance initial={total} transfered={initial} />
          </div>
        </div>
        <div className="historialView_filter">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <label>
                  {text_order_number ? text_order_number : 'Código de Orden'}
                </label>
                <input
                  value={historial_cod_order}
                  onChange={(input) => {
                    historialCodOrder(input.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder={
                    text_order_number ? text_order_number : 'Código de Orden'
                  }
                />
              </div>
              <div className="col-md-3">
                <label>{text_date ? text_date : 'Fecha'}</label>
                <DatePickerInput
                  locale="en"
                  value={historial_date}
                  onChange={(value) => {
                    historialDate(value);
                  }}
                  showOnInputClick
                  showInputButton={false}
                />
              </div>
              <div className="col-md-3">
                <label>
                  {text_type_of_operation
                    ? text_type_of_operation
                    : 'Tipo de Operación'}
                </label>
                <select
                  className="form-control"
                  value={historial_type}
                  onChange={(input) => {
                    historialTypeOder(input.target.value);
                  }}
                >
                  <option value="">
                    -- {text_select ? text_select : 'Seleccionar'} --
                  </option>
                  {user_data &&
                    user_data?.transaction_types?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {!site_language || site_language === 'en'
                          ? item.english_name
                          : item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-3 d-flex historial-menu">
                <button
                  className="btn btn-pymex"
                  onClick={this.historialFilter}
                >
                  {text_filter ? text_filter : 'Filtrar'}
                </button>
                <button
                  onClick={clearFilterHistorial}
                  className="btn btn-pymex-2"
                >
                  <div>
                    {text_remove_filter
                      ? text_remove_filter
                      : 'Eliminar Filtro'}
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="historialView-content">
          {historial_error !== '' && <Error type="error" />}
          {historial_loading && historial_error === '' ? (
            <TopSpinner />
          ) : (
            historial_error === '' && (
              <Fragment>
                <HistorialList data={historial_view} />
                <Pagination
                  data={total_historial}
                  itemsPerPage={historial_items_per_page}
                  currentPage={historial_page}
                  changePage={this.changePage}
                />
              </Fragment>
            )
          )}
          <div className="historial-definitions">
            <p>
              {text_definition ? text_definition : 'Definición'}{' '}
              <strong>
                {text_type_of_operation
                  ? text_type_of_operation
                  : 'Tipo de Operación'}
              </strong>
              :
            </p>
            {user_data &&
              user_data?.transaction_types?.map((item) => (
                <p key={item.id}>
                  <span className="historial-label">
                    {!site_language || site_language === 'en'
                      ? item.english_name
                      : item.name}
                    :
                  </span>{' '}
                  {!site_language || site_language === 'en'
                    ? item.english_description
                    : item.description}
                </p>
              ))}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer, historialReducer, siteReducer }) => {
  return {
    userReducer,
    historialReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, historialActions)(HistorialView);
