import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

function RedirectManager(props) {
  const {
    userReducer: { user_data, user_verified },
  } = props;
  const [redirectRegister, setRedirectRegister] = useState(false);
  const [redirectRegisterDocuments, setRedirectRegisterDocuments] =
    useState(false);
  const [redirectVerified, setRedirectVerified] = useState(false);
  const [redirectStatus, setRedirectStatus] = useState(false);

  useEffect(() => {
    if (!user_data) {
      setRedirectRegister(true);
    } else {
      setRedirectRegister(false);
      if (!user_data.is_verified) {
        setRedirectVerified(true);
      } else {
        setRedirectVerified(false);
      }
      if (user_data.is_verified && !user_data.send_request) {
        setRedirectRegisterDocuments(true);
      } else {
        setRedirectRegisterDocuments(false);
      }
      if (
        user_data.is_verified &&
        user_data.send_request &&
        !user_data.is_active
      ) {
        setRedirectStatus(true);
      } else {
        setRedirectStatus(false);
      }
    }
  }, [user_data, user_verified]);

  if (redirectRegister) {
    return <Redirect to="/register" />;
  }
  if (redirectVerified) {
    return <Redirect to="/manager/confirm" />;
  }
  if (redirectRegisterDocuments) {
    return <Redirect to="/manager/register/docs" />;
  }
  if (redirectStatus) {
    return <Redirect to="/manager/status" />;
  }

  return <></>;
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

export default connect(mapStateToProps, null)(RedirectManager);
