import {
  PRODUCT_ADD_IMAGES,
  PRODUCT_NAME,
  PRODUCT_BRAND_ID,
  PRODUCT_DESCRIPTION,
  PRODUCT_PRICE,
  PRODUCT_PRICE_PROMO,
  PRODUCT_CATEGORY,
  PRODUCT_TYPE,
  PRODUCT_SKU,
  PRODUCT_ID,
  PRODUCT_ERROR,
  PRODUCT_SUCCESS,
  PRODUCT_CLEAR_ERROR,
  PRODUCT_IS_ACTIVE,
  PRODUCT_CLEAR,
  PRODUCT_MY_LIST,
  PRODUCT_LOADING,
  PRODUCT_CHANGE_MANAGER_PAGE,
  PRODUCT_CHANGE_MANAGER_SEARCH,
  PRODUCT_DETAIL_ERROR,
  PRODUCT_SHORT_DESCRIPTION,
  PRODUCT_DETAIL,
  PRODUCT_CLEAR_DETAIL,
  PRODUCT_DATE_PROMO,
  PRODUCT_COMMENTS,
  PRODUCT_LOADING_IMAGES,
  PRODUCT_RELATED_PRODUCTS,
  PRODUCT_DETAIL_LOADING,
} from '../types/productTypes';

const INITIAL_STATE = {
  product_id: '',
  product_name: '',
  product_brand_id: null,
  product_short_description: '',
  product_description: '',
  product_price: '',
  product_price_promo: 0,
  product_images: [],
  product_loading_images: false,
  product_category: '',
  product_type: '',
  product_sku: '',
  product_error: '',
  product_success: '',
  product_is_active: 0,
  product_loading: false,
  product_my_list: [],
  product_search_manager_list: [],
  product_list_manager_view: [],
  product_current_page: 1,
  product_item_per_page: 12,
  product_detail_error: '',
  product_list_services: [],
  product_list_products: [],
  product_detail_loading: true,
  product_detail: null,
  related_products: [],
  product_date_promo: '',
  product_comments: [],
};

const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRODUCT_ID:
      return {
        ...state,
        product_id: action.payload,
      };
    case PRODUCT_NAME:
      return {
        ...state,
        product_name: action.payload,
      };
    case PRODUCT_BRAND_ID:
      return {
        ...state,
        product_brand_id: action.payload,
      };
    case PRODUCT_DESCRIPTION:
      return {
        ...state,
        product_description: action.payload,
      };
    case PRODUCT_SHORT_DESCRIPTION:
      return {
        ...state,
        product_short_description: action.payload,
      };
    case PRODUCT_PRICE:
      return {
        ...state,
        product_price: action.payload,
      };
    case PRODUCT_PRICE_PROMO:
      return {
        ...state,
        product_price_promo: action.payload,
      };
    case PRODUCT_ADD_IMAGES:
      return {
        ...state,
        product_images: action.payload,
      };
    case PRODUCT_LOADING_IMAGES:
      return {
        ...state,
        product_loading_images: action.payload,
      };
    case PRODUCT_CATEGORY:
      return {
        ...state,
        product_category: action.payload,
      };
    case PRODUCT_TYPE:
      return {
        ...state,
        product_type: action.payload,
      };
    case PRODUCT_SKU:
      return {
        ...state,
        product_sku: action.payload,
      };
    case PRODUCT_IS_ACTIVE:
      return {
        ...state,
        product_is_active: action.payload,
      };
    case PRODUCT_ERROR:
      return {
        ...state,
        product_error: action.payload,
        product_loading: false,
      };
    case PRODUCT_CLEAR_ERROR:
      return {
        ...state,
        product_error: '',
        product_loading: false,
      };
    case PRODUCT_SUCCESS:
      return {
        ...state,
        product_success: action.payload,
      };
    case PRODUCT_CLEAR:
      return {
        ...state,
        product_id: '',
        product_name: '',
        product_brand_id: null,
        product_short_description: '',
        product_description: '',
        product_price: '',
        product_price_promo: 0,
        product_images: [],
        product_category: '',
        product_type: '',
        product_sku: '',
        product_error: '',
        product_success: '',
        product_is_active: 0,
        product_current_page: 1,
        product_detail_error: '',
        product_date_promo: '',
        product_comments: [],
      };
    case PRODUCT_MY_LIST:
      return {
        ...state,
        product_my_list: action.payload.list,
        product_search_manager_list: action.payload.list,
        product_list_manager_view: action.payload.listView,
        product_loading: false,
      };
    case PRODUCT_LOADING:
      return {
        ...state,
        product_loading: true,
      };
    case PRODUCT_CHANGE_MANAGER_PAGE:
      return {
        ...state,
        product_list_manager_view: action.payload.listView,
        product_current_page: action.payload.currentPage,
      };
    case PRODUCT_CHANGE_MANAGER_SEARCH:
      return {
        ...state,
        product_list_manager_view: action.payload.listView,
        product_search_manager_list: action.payload.searchList,
        product_current_page: action.payload.currentPage,
      };
    case PRODUCT_DETAIL_ERROR:
      return {
        ...state,
        product_detail_error: action.payload,
      };
    case PRODUCT_DETAIL_LOADING:
      return {
        ...state,
        product_detail_loading: action.payload,
      };
    case PRODUCT_DETAIL:
      return {
        ...state,
        product_detail: action.payload,
      };
    case PRODUCT_RELATED_PRODUCTS:
      return {
        ...state,
        related_products: action.payload,
      };
    case PRODUCT_CLEAR_DETAIL:
      return {
        ...state,
        product_detail: null,
        product_detail_loading: true,
        related_products: [],
      };
    case PRODUCT_DATE_PROMO:
      return {
        ...state,
        product_date_promo: action.payload,
      };
    case PRODUCT_COMMENTS:
      return {
        ...state,
        product_comments: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default productReducer;
