import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './css/SaleItem.css';
import defaultPicture from '../../../images/default-picture.jpg';
import { Link } from 'react-router-dom';

import * as orderActions from '../../../functionality/actions/orderActions';

const { changeOrderStatus, orderClearError } = orderActions;
const MySwal = withReactContent(Swal);

class SaleItem extends Component {
  showError = (message) => {
    MySwal.fire({
      title: 'Hubo un error',
      text: message,
      type: 'error',
      confirmButtonColor: '#50318B',
      onClose: () => this.props.orderClearError(),
    });
  };

  changeStatus = (cod_order, status, message) => {
    const {
      siteReducer: {
        site_texts: {
          text_are_you_sure,
          text_accept_the_order,
          text_reject_the_order,
          text_continue,
        },
      },
    } = this.props;
    const acceptMessage = text_accept_the_order
      ? text_accept_the_order
      : 'Vas a aceptar la orden.';
    const rejectMessage = text_reject_the_order
      ? text_reject_the_order
      : 'Vas a rechazar la orden.';
    const textMessage = message === 'aceptar' ? acceptMessage : rejectMessage;

    MySwal.fire({
      title: text_are_you_sure ? text_are_you_sure : '¿Estás seguro?',
      text: textMessage,
      type: 'warning',
      confirmButtonColor: '#50318B',
      showCancelButton: true,
      confirmButtonText: text_continue ? text_continue : 'Continuar',
      onClose: () => this.props.orderClearError(),
    }).then((result) => {
      if (result.value) {
        this.props.changeOrderStatus(cod_order, status);
      }
    });
  };

  viewStatus = (status) => {
    const {
      siteReducer: {
        site_texts: {
          text_new,
          text_processing,
          text_delivered,
          text_rejected,
          text_finished,
        },
      },
    } = this.props;

    status = parseInt(status);
    switch (status) {
      case 2:
        return text_processing ? text_processing : 'En Proceso';
      case 3:
        return text_rejected ? text_rejected : 'Rechazada';
      case 4:
        return text_delivered ? text_delivered : 'Entregada';
      case 5:
        return text_finished ? text_finished : 'Finalizada';
      default:
        return text_new ? text_new : 'Nueva';
    }
  };

  render() {
    const {
      siteReducer: {
        site_language,
        site_texts: { text_show, text_accept, text_refuse },
      },
      userReducer: { user_data },
    } = this.props;

    const numberFormat = new Intl.NumberFormat('es-CO');

    let classCols = !this.props.actions
      ? 'saleItem_contentAll'
      : 'saleItem_contentSmall';
    let { data, order_alert_error } = this.props;

    return (
      <tr>
        {order_alert_error !== '' && this.showError(order_alert_error)}
        <td>
          <div className="saleItem_product">
            {!data.product_image ? (
              <img
                src={defaultPicture}
                className="saleItem_image"
                alt={data.product_name}
              />
            ) : (
              <img
                src={data.product_image}
                className="saleItem_image"
                alt={data.product_name}
              />
            )}
            <div className="text-left">
              {data.product_name}
              <div>
                <strong>{data.reference}</strong>
              </div>
            </div>
          </div>
        </td>
        <td className="center">
          BAX$
          <br />
          {numberFormat.format(data.price)}
        </td>
        <td className="">{data.quantity}</td>
        <td className="center">
          BAX$
          <br />
          {numberFormat.format(data.total)}
        </td>
        <td className="">{data.created_at}</td>
        <td className="">
          <div className="saleItem_box-status">
            <div
              className={`saleItem_status`}
              style={{ background: data.color }}
            />
            {!site_language || site_language === 'en'
              ? data.status_english_name
              : data.status_name}
          </div>
        </td>
        <td className="saleItem_action-content">
          {this.props.actions &&
            data.status_id === 1 &&
            parseInt(user_data.id) !== parseInt(data.buyer_id) && (
              <Fragment>
                <button
                  onClick={() => this.changeStatus(data.id, 2, 'aceptar')}
                >
                  <span className="icon-accept" />
                  {text_accept ? text_accept : 'Aceptar'}
                </button>
                <button
                  onClick={() => this.changeStatus(data.id, 4, 'rechazar')}
                >
                  <span className="icon-remove" />
                  {text_refuse ? text_refuse : 'Rechazar'}
                </button>
              </Fragment>
            )}
          <Link
            to={`/manager/order/${data.reference}`}
            className={`${classCols} saleItem_content`}
          >
            <span className="icon-edit" /> {text_show ? text_show : 'Ver'}
          </Link>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = ({ orderReducer, siteReducer, userReducer }) => {
  return {
    ...orderReducer,
    siteReducer,
    userReducer,
  };
};

const mapDispatchToProps = {
  changeOrderStatus,
  orderClearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaleItem);
