import React from "react";
import AdSlider from "./AdSlider";
import SlideCompany from "./SlideCompany";

function CompaniesList(props) {
  const { className, showAds } = props;

  return (
    <>
      {showAds && <AdSlider />}
      <div className="row">
        {props.companies.map((company) => {
          return (
            <div className={className} key={company.id}>
              <SlideCompany data={company} />
            </div>
          );
        })}
      </div>
    </>
  );
}

CompaniesList.defaultProps = {
  className: "col-sm-6 col-md-4",
  showAds: false,
};

export default CompaniesList;
