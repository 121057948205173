import { GENERAL_SOCIAL_NETWORKS } from "../types/generalTypes";

const INITIAL_STATE = {
  general_social_networks: [],
};

const generalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GENERAL_SOCIAL_NETWORKS:
      return {
        ...state,
        general_social_networks: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default generalReducer;
