import React, { Component } from "react";
import { connect } from "react-redux";
import OrderDetailView from "./views/OrderDetailView";
import ManagerLayout from "./layouts/ManagerLayout";
import TopSpinner from "../../../components/all/TopSpinner";

import { orderDetail } from "../../../functionality/actions/orderActions";
import Error from "../../../components/all/Error";

class OrderDetailPage extends Component {
  componentDidMount() {
    const {
      orderDetail,
      match: {
        params: { id },
      },
    } = this.props;
    orderDetail(id);
  }
  render() {
    const {
      order_loading,
      order_detail,
      order_error,
      siteReducer: {
        site_texts: { text_order_detail, text_sorry_order },
      },
    } = this.props;

    return (
      <ManagerLayout>
        <div className="title-manager">
          <div>
            <span className="icon-shopping-car" />
            {text_order_detail ? text_order_detail : "Detalle de Orden"}
          </div>
        </div>
        {order_loading && <TopSpinner />}
        {!order_loading && order_error !== "" && <Error type="error" />}
        {!order_loading && order_detail && order_error === "" && (
          <OrderDetailView />
        )}
        {!order_loading && !order_detail && order_error === "" && (
          <Error
            type="no-data"
            title={
              text_sorry_order
                ? text_sorry_order
                : "Lo sentimos, no se encontro la orden."
            }
          />
        )}
      </ManagerLayout>
    );
  }
}

const mapStateToProps = ({ orderReducer, siteReducer }) => {
  return {
    ...orderReducer,
    siteReducer,
  };
};
const mapDispatchToProps = {
  orderDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage);
