import React from "react";
import { Link } from "react-router-dom";
import "./css/SlideCategory.css";
import defaultImage from "../../../images/believe.jpg";
import { connect } from "react-redux";

function SlideCategory(props) {
  const {
    data,
    siteReducer: { site_language },
  } = props;

  return (
    <Link to={`/products/category/${data.id}`} className="slideCategory_box">
      {data.image && data.image !== "" ? (
        <img src={data.image} alt={data.name} />
      ) : (
        <img src={defaultImage} alt={data.name} />
      )}
      <div className="slideCategory_name">
        {site_language === "es" ? data.name : data.english_name}
      </div>
    </Link>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(SlideCategory);
