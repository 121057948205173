export const CART_VIEW = "CART_VIEW";
export const CART_ADD = "CART_ADD";
export const CART_REMOVE = "CART_REMOVE";
export const CART_LOADING = "CART_LOADING";
export const CART_CLOSE_MODAL = "CART_CLOSE_MODAL";
export const CART_TOOLTIP = "CART_TOOLTIP";
export const CART_ADD_QUANTITY = "CART_ADD_QUANTITY";
export const CART_SUBTRACT_QUANTITY = "CART_SUBTRACT_QUANTITY";
export const CART_MESSAGE_ERROR = "CART_MESSAGE_ERROR";
export const CART_CLEAR_MESSAGE_ERROR = "CART_CLEAR_MESSAGE_ERROR";
export const CART_PRODUCT = "CART_PRODUCT";
export const CART_TOTAL_ITEMS = "CART_TOTAL_ITEMS";
export const CART_IMAGES = "CART_IMAGES";
export const CART_IS_PROMO = "CART_IS_PROMO";
export const CART_PRICE = "CART_PRICE";
export const CART_COMMISSION = "CART_COMMISSION";
export const CART_IVA = "CART_IVA";
export const CART_VIEW_PRICE = "CART_VIEW_PRICE";
export const CART_CLEAR_DATA = "CART_CLEAR_DATA";
