import React from "react";
import { connect } from "react-redux";
import BoxWhite from "../../../../components/user/marketplace/BoxWhite";
import "./css/ShoppingCartView.css";
import ListOffer from "../../../../components/user/marketplace/ListOffer";
import ListShoppingCart from "../../../../components/user/marketplace/ListShoppingCart";
import Error from "../../../../components/all/Error";

function ShoppingCartView(props) {
  const {
    cartReducer: { cart },
    userReducer: { promotional_products },
    siteReducer: {
      site_texts: { text_cart },
    },
  } = props;
  let view_promo =
    promotional_products.length > 0 ? promotional_products.slice(0, 12) : [];
  return (
    <div className="container shoppingCartView_container">
      <BoxWhite>
        <div className="shoppingCartView_title">
          {text_cart ? text_cart.toUpperCase() : "CARRITO DE COMPRAS"}
          <div className="shoppingCartView_title-line" />
        </div>
        {!cart.length ? (
          <Error type="no-cart" />
        ) : (
          <ListShoppingCart data={cart} />
        )}
      </BoxWhite>
      {view_promo.length > 0 && <ListOffer data={view_promo} />}
    </div>
  );
}

const mapStateToProps = ({ cartReducer, userReducer, siteReducer }) => {
  return { cartReducer, userReducer, siteReducer };
};

export default connect(mapStateToProps)(ShoppingCartView);
