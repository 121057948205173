import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import "./css/CartModal.css";
import currentImage from "../../images/default-picture.jpg";

import * as cartActions from "../../functionality/actions/cartActions";
import { isPromo } from "../../functionality/Global";

class CartModal extends Component {
  render() {
    const {
      cart_modal,
      cart_modal_detail,
      clearCart,
      siteReducer: {
        site_texts: { text_keep_buying, text_shopping_cart },
      },
    } = this.props;
    let visible = !cart_modal ? "" : "visible";
    let images = cart_modal_detail.images ? cart_modal_detail.images : [];
    let image = images.length ? images[0].image : currentImage;
    let date_promo = cart_modal_detail.promotion_date
      ? new Date(cart_modal_detail.promotion_date)
      : false;
    let is_promo = false;
    if (date_promo) {
      is_promo = isPromo(date_promo);
    }
    const numberFormat = new Intl.NumberFormat("es-CO");

    return ReactDOM.createPortal(
      <div className={`cartModal_box-modal ${visible}`}>
        <div className={`cartModal_modal ${visible}`}>
          <div className="cartModal_info">
            <img src={image} alt="cart" />
            <div className="cartModal_name">
              <div className="cartModal_product">
                {cart_modal_detail && cart_modal_detail.name}
              </div>
              {cart_modal_detail &&
              cart_modal_detail.price_promo !== 0 &&
              is_promo ? (
                <Fragment>
                  <div className="cartModal_price">
                    BAX$ {numberFormat.format(cart_modal_detail.price_promo)}
                  </div>
                  <div className="cartModal_price-promo">
                    BAX$ {numberFormat.format(cart_modal_detail.price)}
                  </div>
                </Fragment>
              ) : (
                <div className="cartModal_price">
                  BAX$ {numberFormat.format(cart_modal_detail.price)}
                </div>
              )}
            </div>
          </div>
          <div className="cartModal_menu">
            <button className="btn btn-pymex-2" onClick={clearCart}>
              {text_keep_buying ? text_keep_buying : "Seguir Comprando"}
            </button>
            <Link to="/cart" className="btn btn-pymex" onClick={clearCart}>
              {text_shopping_cart ? text_shopping_cart : "Ir al Carrito"}
            </Link>
          </div>
        </div>
      </div>,
      document.getElementById("cart")
    );
  }
}

const mapStateToProps = ({ cartReducer, siteReducer }) => {
  return {
    ...cartReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, cartActions)(CartModal);
