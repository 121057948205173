import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ChatInboxItem.css';

const ChatInboxItem = (props) => {
  const { data, path, isActive } = props;
  const [status, setStatus] = useState(false);

  useEffect(() => {
    setStatus(data.viewed);
  });
  const paramId = data.consecutive ? data.consecutive : data.reference;
  const userName = data.consecutive
    ? `${data.user.name} ${data.user.lastname}`
    : `${
        data.userSend.business_name
          ? data.userSend.business_name
          : data.userSend.username
      } `;

  const subject = data.consecutive ? data.name : data.subject;

  return (
    <Link
      className={`inbox__contact ${isActive ? 'active' : ''}`}
      to={`${path}${paramId}`}
      onClick={() => setStatus(false)}
    >
      <div className="contact__info">
        <img
          alt="profile-miniature"
          src={data.userSend ? data.userSend.image : data.user.image}
        />
        <div className="data__contact">
          <span className="name__contact">{userName}</span>
          <span className="type__msg">{subject}</span>
        </div>
      </div>
      <div className="action__contact">
        {status ? <div className="pending__dot" /> : null}
        <button>
          <i className="fa-solid fa-ellipsis-vertical"></i>
        </button>
      </div>
    </Link>
  );
};

export default ChatInboxItem;
