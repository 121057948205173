import React from 'react';
import InfluencerCard from '../../../all/Influencer/influencerCard/InfluencerCard';
import AdSlider from '../AdSlider';
import InfluencerTable from './InfluencerTable/InfluencerTable';
import Paginator from '../../../all/Paginator/Paginator';
import './InfluencerList.css';

const InfluencerList = (props) => {
  const { showAds, influencers, type, currentPage, totalPages, otherCol } =
    props;

  const renderInfluencers = influencers.map((user, index) => (
    <div
      className={`  col-md-4 ${!!otherCol ? otherCol : 'col-sm-6'}`}
      key={index}
    >
      <InfluencerCard {...user} />
    </div>
  ));

  return (
    <div>
      {showAds && <AdSlider />}
      {type === 'list' ? (
        <div className="row influencerList ">{renderInfluencers}</div>
      ) : (
        <div className="influencer__table__container">
          <InfluencerTable influencers={influencers} />
        </div>
      )}
      {totalPages > 1 && (
        <Paginator totalPages={totalPages} currentPage={currentPage} />
      )}
    </div>
  );
};

InfluencerList.defaultProps = {
  type: 'list',
  otherCol: '',
};

export default InfluencerList;
