import Swal from 'sweetalert2';
import React from 'react';
import Api from '../Api';
import {
  PORPOSAL_CLEAR,
  PORPOSAL_IMAGES,
  PORPOSAL_NAME,
  PORPOSAL_VIDEO,
  PORPOSAL_DELIVERABLES,
  PORPOSAL_PRICE,
  PORPOSAL_LOADING,
  PORPOSAL_SOCIAL_NETWORK,
  PORPOSAL_LIST,
  PORPOSAL_CONSECUTIVE,
  CURRENT_PORPOSAL,
  SET_PORPOSAL_EDIT,
  PORPOSAL_VIDEO_LOADING,
  PORPOSAL_CAMPAIGNS,
  PORPOSAL_CAMPAIGNS_ACCEPTED,
  PROPOSAL_TYPE,
  PORPOSAL_LOADING_DETAIL,
} from '../types/porposalTypes';
import { setPostLoading } from './postActions';

const messageSwal = Swal.mixin({
  customClass: {
    confirmButton: 'button__confirm',
  },
  buttonsStyling: false,
});

export const porposalClear = () => (dispatch) => {
  dispatch({
    type: PORPOSAL_CLEAR,
  });
};

export const addImagePorposal = (value) => (dispatch, getState) => {
  const { porposal_images } = getState().porposalReducer;

  if (!porposal_images.some((image) => image.name === value.name)) {
    // Create the object with the image info and preview
    const newImage = {
      file: value,
      preview: URL.createObjectURL(value),
    };

    // Update the state with the new image object
    const updatedImages = [...porposal_images, newImage];
    dispatch(setPorposalImage(updatedImages));
  }
};

export const deleteImagePorposal = (imageIndex) => (dispatch, getState) => {
  const { porposal_images } = getState().porposalReducer;

  // Make a copy of the array before calling splice
  const updatedImages = [...porposal_images];
  updatedImages.splice(imageIndex, 1);

  const imageoToDelete = Object.values(porposal_images).find(
    (image) => image.id === imageIndex
  );

  if (imageoToDelete) {
    dispatch(deleteAttachmentImage(imageIndex));
  }

  dispatch(setPorposalImage(updatedImages));
};

const deleteAttachmentImage = (id) => async (dispatch, getState) => {
  const { porposal_images, porposal_consecutive } = getState().porposalReducer;

  const route = `v1/proposals/${porposal_consecutive}/images/${id}`;

  const imagesToSave = porposal_images.filter(
    (photo) => photo.id === undefined || photo.id === null
  );

  try {
    const response = await Api.asyncCallMethod(route, 'DELETE');
    const {
      data: { attachments },
    } = response.response;
    const {
      site_texts: { text_success_done, text_sorry, text_success_deleted_photo },
    } = getState().siteReducer;

    const newAttachments = [...attachments];

    imagesToSave.forEach((image) => {
      newAttachments.push(image);
    });

    if (response.status === 200) {
      messageSwal.fire({
        title: `${text_success_done || '¡Hecho!'}`,
        text: `${text_success_deleted_photo || 'Foto eliminada con exito'}`,
        type: 'success',
        confirmButtonColor: '#50318B',
      });
      dispatch(setPorposalImage(newAttachments));
    } else {
      messageSwal.fire({
        title: 'Error!!!',
        text: `${text_sorry || 'Ha ocurrido un error, intente mas tarde'}`,
        type: 'error',
        confirmButtonColor: '#50318B',
      });
    }
  } catch (error) {
    console.error(error);
  }
};

export const setPorposalConsecutive = (consecutive) => (dispatch) => {
  dispatch({
    type: PORPOSAL_CONSECUTIVE,
    payload: consecutive,
  });
};

export const setPorposalImage = (value) => (dispatch) => {
  dispatch({
    type: PORPOSAL_IMAGES,
    payload: value,
  });
};

export const setPorposalSocialNetworks = (value) => (dispatch, getState) => {
  const currentValues = getState().porposalReducer.porposal_social_networks;
  const newValue = parseFloat(value);
  let newValues;

  if (currentValues.includes(newValue)) {
    newValues = currentValues.filter((val) => val !== newValue);
  } else {
    newValues = [...currentValues, newValue];
  }

  dispatch({
    type: PORPOSAL_SOCIAL_NETWORK,
    payload: newValues,
  });
};

export const setPorposalName = (value) => (dispatch) => {
  dispatch({
    type: PORPOSAL_NAME,
    payload: value,
  });
};

export const addVideoPorposal = (value) => (dispatch, getState) => {
  dispatch(setPorposalVideoLoading(true));
  const { porposal_video } = getState().porposalReducer;

  if ((porposal_video ?? []).length === 0) {
    // Create the object with the image info and preview
    const newVideo = {
      file: value,
      preview: URL.createObjectURL(value),
    };

    // Update the state with the new image object
    dispatch(setPorposalVideo(newVideo));
    dispatch(setPorposalVideoLoading(false));
  }
};

export const setPorposalVideo = (value) => (dispatch) => {
  dispatch({
    type: PORPOSAL_VIDEO,
    payload: value,
  });
};

export const setPorposalVideoLoading = (value) => (dispatch) => {
  dispatch({
    type: PORPOSAL_VIDEO_LOADING,
    payload: value,
  });
};

export const setPorposalDeliverables = (value) => (dispatch) => {
  dispatch({
    type: PORPOSAL_DELIVERABLES,
    payload: value,
  });
};

export const setPorposalPrice = (value) => (dispatch) => {
  dispatch({
    type: PORPOSAL_PRICE,
    payload: value,
  });
};

export const setPorposalLoading = (value) => (dispatch) => {
  dispatch({
    type: PORPOSAL_LOADING,
    payload: value,
  });
};
export const setPorposalLoadingDetail = (value) => (dispatch) => {
  dispatch({
    type: PORPOSAL_LOADING_DETAIL,
    payload: value,
  });
};

export const setPorposalList = (value) => (dispatch) => {
  dispatch({
    type: PORPOSAL_LIST,
    payload: value,
  });
};

export const setCurrentPorposal = (value) => (dispatch) => {
  dispatch({
    type: CURRENT_PORPOSAL,
    payload: value,
  });
};

export const setPorposalEdit = () => (dispatch, getState) => {
  dispatch(setPorposalLoading(true));
  const { current_porposal } = getState().porposalReducer;
  dispatch({
    type: SET_PORPOSAL_EDIT,
    payload: current_porposal,
  });
  dispatch(setPorposalLoading(false));
};

export const getPorposalEdit = (porposalConsecutive) => async (dispatch) => {
  dispatch(setPorposalLoading(true));
  const route = `v1/proposals/${porposalConsecutive}/edit`;
  try {
    const response = await Api.asyncCallMethod(route, 'GET');
    dispatch(setCurrentPorposal(response.response.data));
    dispatch(setPorposalLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setPorposalLoading(false));
    dispatch(setPorposalLoading(false));
  }
};

export const setProposalType = (payload) => (dispatch) => {
  dispatch({
    type: PROPOSAL_TYPE,
    payload,
  });
};

export const getPorposalDetail = (porposalConsecutive) => async (dispatch) => {
  dispatch(setPorposalLoadingDetail(true));
  const route = `v1/proposals/${porposalConsecutive}`;
  try {
    const response = await Api.asyncCallMethod(route, 'GET');
    const { type, data } = response.response;

    dispatch(setCurrentPorposal(data));
    dispatch(setProposalType(type));
    dispatch(setPorposalLoadingDetail(false));
  } catch (error) {
    console.error(error);
    dispatch(setPorposalLoadingDetail(false));
  }
};

export const getCampaignPorposals =
  (campaignConsecutive) => async (dispatch) => {
    dispatch(setPorposalLoading(true));

    const route = `v1/campaigns/${campaignConsecutive}/proposals?status=2`;
    const routeAccepted = `v1/campaigns/${campaignConsecutive}/proposals?status=4`;

    try {
      const response = await Api.asyncCallMethod(route, 'GET');

      dispatch({
        type: PORPOSAL_CAMPAIGNS,
        payload: response.response.data,
      });

      const responseAccepted = await Api.asyncCallMethod(routeAccepted, 'GET');
      dispatch({
        type: PORPOSAL_CAMPAIGNS_ACCEPTED,
        payload: responseAccepted.response.data,
      });
      dispatch(setPorposalLoading(false));
    } catch (error) {
      console.error('error en getCampaignPorposals', error);
      dispatch(setPorposalLoading(false));
    }
  };

export const getPorposalsByCampaign =
  (campaignConsecutive) => async (dispatch, getState) => {
    dispatch(setPorposalLoading(true));
    const route = `v1/proposals/campaign/${campaignConsecutive}`;

    try {
      const response = await Api.asyncCallMethod(route, 'GET');
      const {
        data: { data },
      } = response.response;
      // console.log('data2', data);
      dispatch(setPorposalList(data));
      dispatch(setPorposalLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setPorposalLoading(false));
    }
  };

export const sendPorposal = (consecutive) => async (dispatch, getState) => {
  dispatch(setPostLoading(true));
  const data = new FormData();

  const {
    porposal_name,
    porposal_images,
    porposal_video,
    porposal_deliverables,
    porposal_price,
    porposal_social_networks,
  } = getState().porposalReducer;

  let videoFile = '';
  if (porposal_video) {
    videoFile = porposal_video.file;
  }

  data.append('name', porposal_name);

  porposal_images.forEach((imageObj) => {
    if (imageObj.file instanceof File) {
      data.append('images[]', imageObj.file);
    }
  });

  data.append('social_networks', porposal_social_networks);
  data.append('campaign_consecutive', consecutive);
  if (videoFile instanceof File) {
    data.append('video', videoFile);
  }
  data.append('deliverables', porposal_deliverables);
  data.append('price', porposal_price);
  data.append('proposal_status_id', 1);

  const route = `v1/proposals`;

  const response = await Api.asyncSendFormData(route, 'POST', data);
  dispatch(setPorposalConsecutive(response.response.data.consecutive));
  dispatch(setPostLoading(false));
  return response.response.data.consecutive;
};

export const changePorposalStatus =
  (porposalConsecutive, statusId) => async (dispatch, getState) => {
    const {
      site_texts: {
        text_warning_not_enough_bax,
        text_error_change_campaign_status,
      },
    } = getState().siteReducer;

    const route = `v1/proposals/${porposalConsecutive}/change-status`;

    const data = {
      proposal_status_id: parseFloat(statusId),
    };

    try {
      const response = await Api.asyncCallMethod(route, 'POST', data);

      if (response.status === 405) {
        messageSwal.fire({
          title: 'Upsss!!!',
          text: `${
            text_warning_not_enough_bax ||
            'No tienes BAX suficientes para realizar esta accion.'
          }`,
          type: 'warning',
        });
      }
      return response;
    } catch (error) {
      console.error(error);
      messageSwal.fire({
        title: 'Upsss!!!',
        text: `${
          text_error_change_campaign_status ||
          'Error al cambiar el status de campaña'
        }`,
        type: 'error',
        confirmButtonColor: '#50318B',
      });
    }
  };

export const finishProposal =
  (proposalConsecutive, statusId) => async (dispatch, getState) => {
    const route = `v1/proposals/${proposalConsecutive}/change-status`;

    const data = {
      proposal_status_id: parseFloat(statusId),
    };

    const {
      site_texts: {
        text_warning_not_enough_bax,
        text_error_change_campaign_status,
      },
    } = getState().siteReducer;

    try {
      const response = await Api.asyncCallMethod(route, 'POST', data);
      // if (response.status === 200) {
      //   return <Redirect to={'/manager/orders/purchases'} />;
      // }
      if (response.status === 405) {
        messageSwal.fire({
          title: 'Upsss!!!',
          text: `${
            text_warning_not_enough_bax ||
            'No tienes BAX suficientes para realizar esta accion.'
          }`,
          type: 'warning',
        });
      }
    } catch (error) {
      console.error(error);
      messageSwal.fire({
        title: 'Upsss!!!',
        text: `${
          text_error_change_campaign_status ||
          'Error al cambiar el status de campaña'
        }`,
        type: 'error',
        confirmButtonColor: '#50318B',
      });
    }
  };

export const publishPorposal = (consecutive) => async (dispatch, getState) => {
  const data = new FormData();

  const {
    site_texts: { text_warning_reference_image },
  } = getState().siteReducer;

  const {
    porposal_consecutive,
    porposal_name,
    porposal_images,
    porposal_video,
    porposal_deliverables,
    porposal_price,
    porposal_social_networks,
  } = getState().porposalReducer;

  if (porposal_images.length === 0) {
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `${
        text_warning_reference_image ||
        'Debes de agregar minimo una imagen de referencia'
      }`,
      type: 'error',
      confirmButtonColor: '#50318B',
    });
    return;
  }

  let videoFile = '';
  if (porposal_video) {
    videoFile = porposal_video.file;
  }

  data.append('name', porposal_name);

  porposal_images.forEach((imageObj) => {
    if (imageObj.file instanceof File) {
      data.append('images[]', imageObj.file);
    }
  });

  data.append('social_networks', porposal_social_networks);
  data.append('campaign_consecutive', consecutive);
  if (videoFile instanceof File) {
    data.append('video', videoFile);
  }
  data.append('deliverables', porposal_deliverables);
  data.append('price', porposal_price);
  data.append('proposal_status_id', 1);

  const route = `v1/proposals/${porposal_consecutive}/publish`;

  await Api.asyncSendFormData(route, 'POST', data);
};

export const editPorposal = (consecutive) => async (dispatch, getState) => {
  const data = new FormData();

  const {
    porposal_consecutive,
    porposal_name,
    porposal_images,
    porposal_video,
    porposal_deliverables,
    porposal_price,
    porposal_social_networks,
  } = getState().porposalReducer;

  let videoFile = '';
  if (porposal_video) {
    videoFile = porposal_video.file;
  }

  data.append('name', porposal_name);

  porposal_images.forEach((imageObj) => {
    if (imageObj.file instanceof File) {
      data.append('images[]', imageObj.file);
    }
  });

  data.append('social_networks', porposal_social_networks);
  data.append('campaign_consecutive', consecutive);
  if (videoFile instanceof File) {
    data.append('video', videoFile);
  }
  data.append('deliverables', porposal_deliverables);
  data.append('price', porposal_price);

  const route = `v1/proposals/${porposal_consecutive}?_method=PUT`;

  const response = await Api.asyncSendFormData(route, 'POST', data);

  // if (response.status === 200) {
  //   console.log('se hizo');
  // }
};
