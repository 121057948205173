import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import * as searchActions from "../../../../functionality/actions/searchActions";
import TopSpinner from "../../../../components/all/TopSpinner";
import CompaniesList from "../../../../components/user/marketplace/CompaniesList";
import ProductList from "../../../../components/user/marketplace/ProductList";
import Error from "../../../../components/all/Error";

const { searchList, searchLoading } = searchActions;

class SearchView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      name: "",
      city: "",
      category: "",
      rating: "",
    };
  }
  componentDidMount() {
    const { search, searchLoading } = this.props;
    searchLoading();
    let view_search = search.split("&");
    let type = view_search[0].split("=");
    for (let i = 1; i < view_search.length; i++) {
      let to_search = view_search[i].split("=");
      let name_search = to_search[0];
      let value = to_search[1];
      if (name_search === "name") {
        this.setState({ name: value });
      }
      if (name_search === "city") {
        this.setState({ city: value });
      }
      if (name_search === "category") {
        this.setState({ category: value });
      }
      if (name_search === "rating") {
        this.setState({ rating: value });
      }
    }
    this.setState({ type: type[1] });
    setTimeout(() => {
      this.search();
    }, 50);
  }

  search = () => {
    const { type, name, city, category, rating } = this.state;
    const {
      userReducer: { list_products, list_all_business },
      searchList,
    } = this.props;
    let filter = [];
    if (type === "company") {
      filter = list_all_business;
    } else {
      let cod_type = type === "service" ? 2 : 1;
      filter = list_products.filter(
        (item) => item.product_type_id === cod_type
      );
    }
    if (name !== "") {
      if (type === "company") {
        filter = filter.filter(
          (item) =>
            item.username.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
            item.business_name.toLowerCase().indexOf(name.toLowerCase()) !== -1
        );
      } else {
        filter = filter.filter(
          (item) => item.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
        );
      }
    }
    if (city !== "") {
      filter = filter.filter(
        (item) => parseInt(item.city_id) === parseInt(city)
      );
    }
    if (category !== "") {
      if (type === "company") {
        filter = filter.filter(
          (item) => item.activity_id === parseInt(category)
        );
      } else {
        console.log(filter);
        filter = filter.filter((item) =>
          item.array_categories.find(
            (category_id) => parseInt(category) === category_id
          )
        );
      }
    }
    if (rating !== "") {
      if (type === "company") {
        filter = filter.filter((item) => item.rating >= parseInt(rating));
      } else {
        filter = filter.filter((item) => item.rating >= parseInt(rating));
      }
    }
    searchList(filter);
  };

  render() {
    const {
      searchReducer: { search_loading, search_list },
      siteReducer: {
        site_texts: { text_sorry_search, text_go_home },
      },
    } = this.props;
    const { type } = this.state;
    return (
      <Fragment>
        {search_loading && <TopSpinner title="Buscando..." />}
        {!search_loading && search_list.length === 0 && (
          <Error
            type="no-data"
            title={
              text_sorry_search
                ? text_sorry_search
                : `Lo sentimos, no encontramos resultados.`
            }
            buttonName={text_go_home ? text_go_home : "Ir al Inicio"}
            link="/home"
          />
        )}
        {!search_loading && search_list.length > 0 && type === "company" ? (
          <CompaniesList companies={search_list} />
        ) : (
          search_list.length > 0 && (
            <ProductList data={search_list} classColumn="col-md-3" />
          )
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ searchReducer, userReducer, siteReducer }) => {
  return {
    searchReducer,
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  searchList,
  searchLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchView);
