import React, { Component } from "react";
import MarketplaceLayout from "./layouts/MarketplaceLayout";
import IndexView from "./views/IndexView";

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusSearch: false,
    };
  }

  componentDidMount() {}

  openCloseSearch = (statusSearch) => {
    this.setState({
      statusSearch,
    });
  };

  render() {
    return (
      <MarketplaceLayout
        statusSearch={this.state.statusSearch}
        openCloseSearch={this.openCloseSearch}
      >
        <IndexView openCloseSearch={this.openCloseSearch} />
      </MarketplaceLayout>
    );
  }
}

export default IndexPage;
