import Api from '../Api';
import { CHAT_LIST_NOTIFICATION } from '../types/chatTypes';
import { NOTIFICATION_VIEW } from '../types/notificationTypes';

export const notificationView = () => (dispatch) => {
  Api.callMethod('v1/users/notifications', 'get', null, function (res) {
    if (res.status === 200) {
      let { seller, chats, total, chat_list } = res.response;
      let dataNotification = {
        notification_total: total,
        notification_chats: chats,
        notification_orders: seller,
      };
      dispatch({
        type: CHAT_LIST_NOTIFICATION,
        payload: chat_list,
      });
      dispatch({
        type: NOTIFICATION_VIEW,
        payload: dataNotification,
      });
    }
  });
};
