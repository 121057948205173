import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from './Input';
import InputFile from './InputFile';
import Select from './Select';
import SelectOptions from './SelectOptions';

class Question extends Component {
  state = {
    showMultiple: false,
  };

  componentDidMount() {
    const { isMultiple, menu, value, multipleValidation } = this.props;

    if (isMultiple && menu && value === multipleValidation) {
      this.setShowMultiple(true);
    }
  }

  setShowMultiple = (status) => {
    this.setState({
      showMultiple: status,
    });
  };

  render() {
    const { showMultiple } = this.state;
    const {
      siteReducer: {
        site_texts: {
          text_questions_save,
          text_questions_skip,
          text_change_response,
        },
      },
      currentStep,
      step,
      question,
      note,
      skip,
      menu,
      content,
      save,
      type,
      value,
      onChange,
      inputType,
      placeholder,
      maxLength,
      inputFileTitle,
      options,
      buttonClass,
      inputFileId,
      onSaveDoc,
      inputFileLabel,
      acceptInputFile,
      isMultiple,
      multipleValidation,
      multipleRender,
      multipleSelection,
    } = this.props;
    let visible = '';

    if (currentStep === step) {
      visible = 'visible';
    }
    if (currentStep > step) {
      visible = 'hide';
    }

    return (
      <div className={`questions_question ${visible}`}>
        {!content ? (
          <div className="questions_children">
            {!showMultiple && question && (
              <div className="questions_question-text">{question}</div>
            )}
            {!showMultiple && note && (
              <div className="questions_note">{note}</div>
            )}
            {this.props.children}
            {menu && (
              <div className="questions_menu">
                <button className="questions_button" onClick={save}>
                  {text_questions_save ? text_questions_save : 'Ok'}
                </button>
                {skip && (
                  <button>
                    {text_questions_skip ? text_questions_skip : 'Omitir'}
                  </button>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="questions_question-content">
            {!showMultiple && question && (
              <div className="questions_question-text">{question}</div>
            )}
            {!showMultiple && note && (
              <div className="questions_note">{note}</div>
            )}
            <div className="questions_children">
              {type === 'input' && (
                <Input
                  value={value}
                  onChange={onChange}
                  type={inputType}
                  placeholder={placeholder}
                  max={maxLength}
                />
              )}
              {type === 'input-file' && (
                <InputFile
                  id={inputFileId}
                  saveDoc={onSaveDoc}
                  value={value}
                  label={inputFileLabel}
                  accept={acceptInputFile}
                  title={inputFileTitle}
                />
              )}
              {type === 'select' && (
                <Select value={value} onChange={onChange} options={options} />
              )}
              {type === 'textarea' && (
                <textarea
                  className="questions_textarea"
                  value={value}
                  onChange={(input) => onChange(input.target.value)}
                  placeholder={placeholder}
                />
              )}
              {type === 'select-option' && (
                <>
                  {!showMultiple ? (
                    <SelectOptions
                      value={value}
                      onChange={(value) => {
                        onChange(value);
                        if (
                          isMultiple &&
                          (multipleValidation === value ||
                            multipleValidation === 'show')
                        ) {
                          this.setShowMultiple(true);
                        }
                      }}
                      options={options}
                      buttonClass={buttonClass}
                      multipleSelection={multipleSelection}
                    />
                  ) : (
                    <div className="">
                      <button
                        onClick={() => {
                          onChange('');
                          this.setShowMultiple(false);
                        }}
                        className="questions_back-button"
                      >
                        <div className="">
                          <i className="fa fa-angle-left" />
                        </div>
                        {text_change_response || 'Cambiar respuesta'}
                      </button>
                      <div>{multipleRender && multipleRender()}</div>
                    </div>
                  )}
                </>
              )}
            </div>
            {menu && (
              <div className="questions_menu">
                <button className="questions_button" onClick={save}>
                  {text_questions_save ? text_questions_save : 'Ok'}
                </button>
                {skip && (
                  <button>
                    {text_questions_skip ? text_questions_skip : 'Omitir'}
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Question.defaultProps = {
  skip: false,
  menu: false,
  content: true,
  type: 'input',
  value: '',
  onChange: (res) => console.log(res),
  inputType: 'text',
  placeholder: '',
  maxLength: '',
  inputFileTitle: '',
  options: [],
  buttonClass: 'questions_confirm',
  inputFileId: '',
  onSaveDoc: (res) => console.log(res),
  inputFileLabel: '',
  acceptInputFile: '',
  isMultiple: null,
  multipleValidation: null,
  multipleRender: null,
  multipleSelection: false,
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(Question);
