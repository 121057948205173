import { brandChangeQuestion } from './brandActions';
import {
  changeCitiesSelected,
  changeCitySelected,
  changeMultipleCities,
  getCities,
} from './countryAcitons';
import { changePricipalTag, changeTagsUser } from './tagsActions';
import { changeTargetsUser } from './targetsActions';
import {
  changeActivity,
  changeAddress,
  changeAddressAddon,
  changeAvgSales,
  changeBusinessName,
  changeCamaraComercio,
  changeCodCity,
  changeCodDepartment,
  changeDescription,
  changeDocBack,
  changeDocDate,
  changeDocFront,
  changeDocNumber,
  changeDocType,
  changeFacebook,
  changeInstagram,
  changeLastnameLegalRepresentative,
  changeLegalRepresentative,
  changeLinkedin,
  changeNameLegalRepresentative,
  changeNitNumber,
  changePhone,
  changeProfession,
  changeRutScann,
  changeTwitter,
  changeTypePerson,
  changeUserFavoriteCompanies,
  changeUserFavorites,
  changeUserSocial,
  changeUserSocialNetworks,
  changeWebsite,
  changePrincipalNetwork,
  changeBirthdate,
  changeUsername,
} from './userActions';

export const setUserProfileData = (user_data) => (dispatch) => {
  if (user_data.legal_representative !== null) {
    dispatch(changeLegalRepresentative(user_data.legal_representative));
    if (
      !user_data.legal_representative &&
      user_data.name_legal_representative &&
      user_data.lastname_legal_representative
    ) {
      dispatch(
        changeNameLegalRepresentative(user_data.name_legal_representative)
      );
      dispatch(
        changeLastnameLegalRepresentative(
          user_data.lastname_legal_representative
        )
      );
    }
  }
  if (user_data && user_data.doc_type) {
    dispatch(changeDocType(user_data.doc_type));
  }
  if (user_data && user_data.doc_number) {
    dispatch(changeDocNumber(user_data.doc_number));
  }
  if (user_data && user_data.doc_expedition_date) {
    dispatch(changeDocDate(user_data.doc_expedition_date));
  }
  if (user_data && user_data.profession) {
    dispatch(changeProfession(user_data.profession));
  }
  if (user_data && user_data.docs && user_data.docs.nit_number) {
    dispatch(changeNitNumber(user_data.docs.nit_number));
  }
  if (user_data && user_data.docs && user_data.docs.phone) {
    dispatch(changePhone(user_data.docs.phone));
  }
  if (user_data && user_data.docs && user_data.docs.website) {
    dispatch(changeWebsite(user_data.docs.website));
  }
  if (user_data && user_data.docs && user_data.docs.activity_id) {
    dispatch(changeActivity(user_data.docs.activity_id));
  }
  if (user_data && user_data.docs && user_data.docs.average_sales) {
    dispatch(changeAvgSales(parseInt(user_data.docs.average_sales)));
  }
  if (user_data && user_data.docs && user_data.docs.description) {
    dispatch(changeDescription(user_data.docs.description));
  }
  if (user_data && user_data.docs && user_data.docs.address) {
    dispatch(changeAddress(user_data.docs.address));
  }
  if (user_data.docs && user_data.docs.department_id !== null) {
    dispatch(changeCodDepartment(user_data.docs.department_id));
  }
  if (user_data.docs && user_data.docs.city_id !== null) {
    dispatch(changeCodCity(user_data.docs.city_id));
  }
  if (user_data.docs && user_data.docs.address_addon !== null) {
    dispatch(changeAddressAddon(user_data.docs.address_addon));
  }
  if (
    user_data.docs &&
    (user_data.docs.facebook ||
      user_data.docs.twitter ||
      user_data.docs.linkedin ||
      user_data.docs.instagram)
  ) {
    dispatch(changeUserSocial(1));
  }
  if (user_data.docs && user_data.docs.facebook) {
    dispatch(changeFacebook(user_data.docs.facebook));
  }
  if (user_data.docs && user_data.docs.twitter) {
    dispatch(changeTwitter(user_data.docs.twitter));
  }
  if (user_data.docs && user_data.docs.linkedin) {
    dispatch(changeLinkedin(user_data.docs.linkedin));
  }
  if (user_data.docs && user_data.docs.instagram) {
    dispatch(changeInstagram(user_data.docs.instagram));
  }
  if (user_data && user_data.docs && user_data.docs.doc_scan_front) {
    dispatch(changeDocFront(user_data.docs.doc_scan_front));
  }
  if (user_data && user_data.docs && user_data.docs.doc_scan_back) {
    dispatch(changeDocBack(user_data.docs.doc_scan_back));
  }
  if (user_data.type_person) {
    dispatch(changeTypePerson(user_data.type_person));
  }
  if (user_data && user_data.docs && user_data.docs.camara_comercio) {
    dispatch(changeCamaraComercio(user_data.docs.camara_comercio));
  }
  if (user_data && user_data.docs && user_data.docs.rut_scann) {
    dispatch(changeRutScann(user_data.docs.rut_scann));
  }
  if (user_data && user_data.docs && user_data.docs.business_name) {
    dispatch(changeBusinessName(user_data.docs.business_name));
  }
  if (user_data.has_brands) {
    dispatch(brandChangeQuestion(user_data.has_brands));
  }
  if (user_data.birthdate) {
    dispatch(changeBirthdate(user_data.birthdate));
  }
  if (user_data.social_networks) {
    dispatch(changeUserSocialNetworks(user_data.social_networks));
    const principal = user_data.social_networks.find(
      (item) => parseInt(item.is_principal) === 1
    );
    if (principal) {
      dispatch(changePrincipalNetwork(principal.id));
    }
  }
  if (user_data.favorite_products) {
    dispatch(changeUserFavorites(user_data.favorite_products));
  }
  if (user_data.favorite_companies) {
    dispatch(changeUserFavoriteCompanies(user_data.favorite_companies));
  }
  if (user_data.tags) {
    dispatch(changeTagsUser(user_data.tags));
    const principal = user_data.tags.find((tag) => tag.is_principal);
    if (principal) {
      dispatch(changePricipalTag(`${principal.id}`));
    }
  }
  if (user_data.targets) {
    dispatch(changeTargetsUser(user_data.targets));
  }
  if (user_data && user_data.docs && user_data.docs.city) {
    dispatch(getCities(user_data.docs.city.country_id));
    dispatch(changeCitySelected(user_data.docs.city));
  }
  if (user_data && user_data.docs && user_data.docs.username) {
    dispatch(changeUsername(user_data.docs.username));
  }
  if (user_data && user_data.cities && user_data.cities.length) {
    dispatch(changeMultipleCities(1));
    dispatch(changeCitiesSelected(user_data.cities));
  }
};
