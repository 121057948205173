import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getTags } from '../../../functionality/actions/tagsActions';
import Spinner from '../Spinner';
import './Keywords.css';

const Keywords = (props) => {
  const {
    tagsReducer: { tags, tags_loading },
    title,
    objectName,
    selected,
    onChange,
    getTags,
  } = props;

  useEffect(() => {
    if (!tags.length || tags_loading) {
      getTags();
    }
  }, []);

  return (
    <>
      {title && <h4 className="keywords__title">{title}</h4>}
      {tags_loading ? (
        <div className="spinner-loading">
          <Spinner type="purple" />
        </div>
      ) : (
        <div className="keywords">
          {tags.map((word, index) => {
            const isSelected = selected
              ? selected.find((tag) => tag.id === word.id)
              : false;

            return (
              <button
                key={index}
                className={`pill ${isSelected ? 'selected' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  onChange(word);
                }}
              >
                {word[objectName]}
              </button>
            );
          })}
        </div>
      )}
    </>
  );
};

Keywords.defaultProps = {
  objectName: 'name',
};

const mapStateToProps = ({ tagsReducer }) => {
  return {
    tagsReducer,
  };
};

const mapDispatchToProps = {
  getTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(Keywords);
