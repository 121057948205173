import React from 'react';
import { withRouter } from 'react-router';
import './css/ChatItem.css';
import { Link } from 'react-router-dom';
import defaultProfile from '../../../images/believe.jpg';

function ChatItem(props) {
  let { data, actions } = props;
  let classButton = actions ? 'chatItem_goAction' : 'chatItem_goAll';
  let name = data
    ? data.userSend.username
      ? data.userSend.username
      : data.userSend.business_name
    : '';
  let isActive = props.match.params.id === data.reference ? 'active' : '';

  return (
    <div className={`chatItem_row ${isActive}`}>
      <Link
        to={`/manager/inbox/message/${data.reference}`}
        className={`chatItem_go ${classButton}`}
      >
        {data.userSend.image ? (
          <img
            src={data.userSend.image}
            alt={name}
            className="chatItem_image"
          />
        ) : (
          <img src={defaultProfile} alt={name} className="chatItem_image" />
        )}
        <div className="chatItem_description">
          <div className="chatItem_name">{name.toUpperCase()}</div>
          <div className="chatItem_message color-gray">{data.subject}</div>
        </div>
      </Link>
      {actions && (
        <div className="chatItem_box-actions">
          {data.viewed === 1 && <div className="chatItem_notification" />}
          <button className="chatItem_view-actions">
            <span className="icon-dots" />
          </button>
        </div>
      )}
    </div>
  );
}

export default withRouter(ChatItem);
