import {
  COMPANY_LOADING,
  COMPANY_ERROR,
  COMPANY_CLEAR,
  COMPANY_DATA,
  COMPANY_CHANGE_PAGE,
  COMPANY_COMMENTS,
  COMPANY_LIST_PRODUCTS,
  COMPANY_LIST_VIEW_PRODUCTS,
  COMPANY_PRODUCTS_LOADING,
  COMPANY_COMMENTS_LOADING,
} from "../types/companyTypes";

import Api from "../Api";

export const companyClear = () => (dispatch) => {
  dispatch({
    COMPANY_CLEAR,
  });
};

export const companyDetail = (cod_company) => (dispatch, getState) => {
  dispatch({
    type: COMPANY_LOADING,
  });
  Api.callMethod(
    `v1/users/${cod_company}/detail`,
    "GET",
    null,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: COMPANY_DATA,
          payload: res.response.data,
        });
        dispatch(companyProducts(cod_company));
        dispatch(companyComments(cod_company));
      } else {
        dispatch({
          type: COMPANY_ERROR,
        });
      }
    },
    function (err) {
      dispatch({
        type: COMPANY_ERROR,
      });
    }
  );
};

export const companyChangePage = (data) => (dispatch) => {
  dispatch({
    type: COMPANY_CHANGE_PAGE,
    payload: data,
  });
};

export const companyError = () => (dispatch) => {
  dispatch({
    type: COMPANY_ERROR,
  });
};

export const companyProducts = (cod_user) => (dispatch, getState) => {
  const { company_item_per_page } = getState().companyReducer;
  dispatch({
    type: COMPANY_PRODUCTS_LOADING,
    payload: true,
  });
  Api.callMethod(`v1/users/${cod_user}/products`, "GET", null, function (res) {
    if (res.status === 200) {
      const { data } = res.response;
      const listView = data.slice(0, company_item_per_page);

      dispatch({
        type: COMPANY_LIST_PRODUCTS,
        payload: data,
      });
      dispatch({
        type: COMPANY_LIST_VIEW_PRODUCTS,
        payload: listView,
      });
      dispatch({
        type: COMPANY_PRODUCTS_LOADING,
        payload: false,
      });
    }
  });
};

export const companyComments = (cod_user) => (dispatch) => {
  dispatch({
    type: COMPANY_COMMENTS_LOADING,
    payload: true,
  });
  Api.callMethod(`v1/users/${cod_user}/comments`, "GET", null, function (res) {
    if (res.status === 200) {
      dispatch({
        type: COMPANY_COMMENTS,
        payload: res.response.data,
      });
      dispatch({
        type: COMPANY_COMMENTS_LOADING,
        payload: false,
      });
    }
  });
};
