import Swal from 'sweetalert2';
import Api from '../Api';
import {
  BRAND_CATEGORY,
  BRAND_CLEAR,
  BRAND_COVER,
  BRAND_DESCRIPTION,
  BRAND_ID,
  BRAND_IMAGE,
  BRAND_IS_ACTIVE,
  BRAND_LOADING,
  BRAND_NAME,
  BRAND_QUESTION,
  BRAND_USER_LIST,
} from '../types/brandTypes';
import { openLoading } from './userActions';

export const getBrand = (brandId) => (dispatch) => {
  console.log(brandId);
};

export const getBrands = () => (dispatch) => {
  console.log('Solicitamod Brands');
};

export const brandClear = () => (dispatch) => {
  dispatch({
    type: BRAND_CLEAR,
  });
};

export const brandChangeQuestion = (payload) => (dispatch) => {
  dispatch({
    type: BRAND_QUESTION,
    payload,
  });
};

export const brandChangeId = (payload) => (dispatch) => {
  dispatch({
    type: BRAND_ID,
    payload,
  });
};

export const brandChangeCategory = (payload) => (dispatch) => {
  dispatch({
    type: BRAND_CATEGORY,
    payload,
  });
};

export const brandChangeName = (payload) => (dispatch) => {
  dispatch({
    type: BRAND_NAME,
    payload,
  });
};

export const brandChangeDescription = (payload) => (dispatch) => {
  dispatch({
    type: BRAND_DESCRIPTION,
    payload,
  });
};

export const brandChangeImage = (payload) => (dispatch) => {
  dispatch({
    type: BRAND_IMAGE,
    payload,
  });
};

export const brandChangeCover = (payload) => (dispatch) => {
  dispatch({
    type: BRAND_COVER,
    payload,
  });
};

export const brandChangeIsActive = (payload) => (dispatch) => {
  dispatch({
    type: BRAND_IS_ACTIVE,
    payload,
  });
};

export const brandChangeLoading = (payload) => (dispatch) => {
  dispatch({
    type: BRAND_LOADING,
    payload,
  });
};

const showError = (text = '', type = 'warning') => {
  Swal.fire({
    title: 'Upsss!!!',
    text: text,
    type,
    confirmButtonColor: '#50318B',
  });
};

export const getBrandByUser = () => (dispatch, getState) => {
  const { user_data } = getState().userReducer;
  const {
    site_texts: { text_session_error },
  } = getState().siteReducer;

  if (!user_data) {
    showError(text_session_error || 'Error al ver los datos del usuario');
    return;
  }
  dispatch(brandChangeLoading(true));

  Api.callMethod(
    `v1/users/${user_data.id}/brands`,
    'GET',
    null,
    (res) => {
      if (res.status === 200) {
        const { data } = res.response;
        dispatch({
          type: BRAND_USER_LIST,
          payload: data,
        });
      }
      dispatch(brandChangeLoading(false));
    },
    (error) => {
      console.log('Error getBrandByUser: ', error);
      dispatch(brandChangeLoading(false));
    }
  );
};

export const brandSave = () => (dispatch, getState) => {
  const {
    site_texts: {
      text_brand_category_error,
      text_brand_name_error,
      text_brand_image_error,
      text_saving_brand,
      text_brand_save,
      text_congratulations,
      text_brand_error,
    },
  } = getState().siteReducer;
  const {
    brand_id,
    brand_category,
    brand_name,
    brand_description,
    brand_image,
    brand_cover,
    brand_is_active,
  } = getState().brandReducer;
  const route = !brand_id ? 'v1/brands' : `v1/brands/${brand_id}`;
  const method = !brand_id ? 'POST' : 'PUT';

  if (brand_category === '') {
    showError(
      text_brand_category_error || 'Selecciona la categoría de la marca'
    );
    return;
  }
  if (brand_name === '') {
    showError(text_brand_name_error || 'Escribe el nombre de la marca');
    return;
  }
  if (brand_image === '') {
    showError(
      text_brand_image_error ||
        'Selecciona una imagen para identificar la marca'
    );
    return;
  }

  const data = {
    category_id: brand_category,
    name: brand_name,
    description: brand_description,
    image: brand_image,
    cover: brand_cover,
    is_active: brand_is_active,
  };

  dispatch(openLoading(text_saving_brand || 'Guardando marca...'));

  Api.callMethod(
    route,
    method,
    data,
    (res) => {
      if (res.status === 201) {
        const { brands } = res.response;
        Swal.fire({
          title: text_congratulations || 'Felicitaciones!',
          text: text_brand_save || 'La marca ha sido guardada.',
          type: 'success',
          confirmButtonColor: '#50318B',
          onClose: () => {
            if (!brand_id) {
              dispatch(brandClear());
            }
          },
        });
        dispatch({
          type: BRAND_USER_LIST,
          payload: brands,
        });
      } else {
        showError(text_brand_error || 'Hubo un error al guardar la marca.');
      }

      dispatch(openLoading(''));
    },
    (error) => {
      console.log('Error brandSave: ', error);
      showError(text_brand_error || 'Hubo un error al guardar la marca.');
      dispatch(openLoading(''));
    }
  );
};

export const brandEdit = (brand) => (dispatch) => {
  dispatch(brandChangeId(brand.id));
  dispatch(brandChangeCategory(brand.category_id));
  dispatch(brandChangeName(brand.name));
  dispatch(brandChangeDescription(brand.description || ''));
  dispatch(brandChangeImage(brand.image));
  dispatch(brandChangeCover(brand.cover || ''));
};
