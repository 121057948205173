import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getTargets } from '../../../functionality/actions/targetsActions';
import OptionCheckBox from '../OptionCheckBox/OptionCheckBox';

function CheckboxTargets(props) {
  const {
    targetReducer: { targets },
    getTargets,
    labelName,
    values,
    onChange,
  } = props;

  useEffect(() => {
    if (!targets.length) {
      getTargets();
    }
  }, []);

  return (
    <>
      {targets.map((target) => {
        const isChecked = values.find((item) => item === target.id);
        return (
          <OptionCheckBox
            label={target[labelName]}
            value={target.id}
            handleInputChange={onChange}
            checked={isChecked ? true : false}
            key={target.id}
          />
        );
      })}
    </>
  );
}

CheckboxTargets.defaultProps = {
  labelName: 'name',
};

const mapStateToProps = ({ targetReducer }) => {
  return {
    targetReducer,
  };
};

const mapDispatchToProps = {
  getTargets,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckboxTargets);
