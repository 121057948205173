import {
  ORDER_LOADING,
  ORDER_ERROR,
  ORDER_MY_LIST,
  ORDER_CHANGE_SELLER,
  ORDER_CHANGE_BUYER,
  ORDER_DETAIL,
  ORDER_CLEAR_ERROR,
  ORDER_ALERT_ERROR,
  ORDER_USER_RATING,
  ORDER_USER_COMMENT,
  ORDER_PRODUCT_RATING,
  ORDER_PRODUCT_COMMENT,
  ORDER_REPORT,
  ORDER_VIEW_REPORT,
  ORDER_VIEW_RATING,
  ORDER_NEXT_RATING,
  ORDER_LIST_VIEW,
  ORDER_STATUS_CODE,
} from '../types/orderTypes';
import Api from '../Api';
import { USER_LOADING, USER_CLOSE_LOADING } from '../types/userTypes';

export const orderChangeLoading = (payload) => (dispatch) => {
  dispatch({
    type: ORDER_LOADING,
    payload,
  });
};

export const viewOrdersByType = (type) => (dispatch, getState) => {
  const {
    site_texts: { text_sorry_large },
  } = getState().siteReducer;

  dispatch(orderChangeLoading(true));
  Api.callMethod(
    `v1/orders/${type}`,
    'GET',
    null,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: ORDER_MY_LIST,
          payload: res.response.data,
        });
      } else {
        dispatch({
          type: ORDER_ERROR,
          payload: text_sorry_large
            ? text_sorry_large
            : 'Lo sentimos, hubo un error vuelve a intentarlo más tarde.',
        });
      }
      dispatch(orderChangeLoading(false));
    },
    function (err) {
      dispatch({
        type: ORDER_ERROR,
        payload: text_sorry_large
          ? text_sorry_large
          : 'Lo sentimos, hubo un error vuelve a intentarlo más tarde.',
      });
      dispatch(orderChangeLoading(false));
    }
  );
};

export const orderFilterByStatus = (status) => (dispatch, getState) => {
  const { order_list } = getState().orderReducer;

  dispatch({
    type: ORDER_STATUS_CODE,
    payload: status,
  });
  if (status === '') {
    dispatch({
      type: ORDER_LIST_VIEW,
      payload: order_list,
    });
  } else {
    const orders = order_list.filter(
      (order) => parseInt(order.status_id) === parseInt(status)
    );
    dispatch({
      type: ORDER_LIST_VIEW,
      payload: orders,
    });
  }
};

export const oderChangeViewSeller = (data) => (dispatch) => {
  dispatch({
    type: ORDER_CHANGE_SELLER,
    payload: data,
  });
};

export const oderChangeViewBuyer = (data) => (dispatch) => {
  dispatch({
    type: ORDER_CHANGE_BUYER,
    payload: data,
  });
};

export const oderUserRating = (value) => (dispatch) => {
  dispatch({
    type: ORDER_USER_RATING,
    payload: value,
  });
};

export const oderUserComment = (value) => (dispatch) => {
  dispatch({
    type: ORDER_USER_COMMENT,
    payload: value,
  });
};

export const oderProductRating = (value) => (dispatch) => {
  dispatch({
    type: ORDER_PRODUCT_RATING,
    payload: value,
  });
};

export const oderProductComment = (value) => (dispatch) => {
  dispatch({
    type: ORDER_PRODUCT_COMMENT,
    payload: value,
  });
};

export const orderReport = (value) => (dispatch) => {
  dispatch({
    type: ORDER_REPORT,
    payload: value,
  });
};

export const orderViewReport = (value) => (dispatch) => {
  dispatch({
    type: ORDER_VIEW_REPORT,
    payload: value,
  });
};

export const orderViewRating = (value) => (dispatch) => {
  dispatch({
    type: ORDER_VIEW_RATING,
    payload: value,
  });
};

export const orderNextRating = (value) => (dispatch) => {
  dispatch({
    type: ORDER_NEXT_RATING,
    payload: value,
  });
};

export const orderClearError = () => (dispatch) => {
  dispatch({
    type: ORDER_CLEAR_ERROR,
  });
};

export const orderDetail = (cod_order) => (dispatch, getState) => {
  const {
    site_texts: { text_sorry_large },
  } = getState().siteReducer;

  dispatch({
    type: ORDER_LOADING,
    payload: true,
  });

  Api.callMethod(
    `v1/orders/${cod_order}/detail`,
    'GET',
    null,
    function (res) {
      if (res.status === 200) {
        dispatch({
          type: ORDER_DETAIL,
          payload: res.response.data,
        });
      } else {
        dispatch({
          type: ORDER_ERROR,
          payload: text_sorry_large
            ? text_sorry_large
            : 'Lo sentimos, hubo un error vuelve a intentarlo más tarde.',
        });
      }
    },
    function (err) {
      dispatch({
        type: ORDER_ERROR,
        payload: text_sorry_large
          ? text_sorry_large
          : 'Lo sentimos, hubo un error vuelve a intentarlo más tarde.',
      });
    }
  );
};

export const changeOrderStatus =
  (cod_order, status) => (dispatch, getState) => {
    const {
      site_texts: {
        text_changing_status,
        text_sorry_space_accept_bax,
        text_sorry,
      },
    } = getState().siteReducer;

    dispatch({
      type: USER_LOADING,
      payload: text_changing_status
        ? text_changing_status
        : 'Cambiando estado de la order...',
    });
    let data = {
      status,
    };
    Api.callMethod(
      `v1/orders/status/${cod_order}`,
      'PUT',
      data,
      function (res) {
        if (res.status === 200) {
          window.location.reload();
          return;
        }
        dispatch({
          type: USER_CLOSE_LOADING,
        });
        if (res.status === 406) {
          dispatch({
            type: ORDER_ALERT_ERROR,
            payload: text_sorry_space_accept_bax
              ? text_sorry_space_accept_bax
              : 'Lo sentimos, no tienes cupo suficiente para aceptar más BAX.',
          });
          return;
        }
        dispatch({
          type: ORDER_ALERT_ERROR,
          payload: text_sorry
            ? text_sorry
            : 'Lo sentimos, vuelve a intentarlo más tarde.',
        });
      },
      function (err) {
        dispatch({
          type: USER_CLOSE_LOADING,
        });
        dispatch({
          type: ORDER_ALERT_ERROR,
          payload: text_sorry
            ? text_sorry
            : 'Lo sentimos, vuelve a intentarlo más tarde.',
        });
      }
    );
  };

export const orderClaim = (reference_code, data) => (dispatch, getState) => {
  const {
    site_texts: { text_sending_report, text_sorry },
  } = getState().siteReducer;

  dispatch({
    type: USER_LOADING,
    payload: text_sending_report ? text_sending_report : 'Enviando reporte...',
  });
  dispatch({
    type: ORDER_VIEW_REPORT,
    payload: false,
  });

  Api.callMethod(
    `v1/orders/claim/${reference_code}`,
    'POST',
    data,
    function (res) {
      if (res.status === 200) {
        window.location.reload();
        return;
      }
      dispatch({
        type: USER_CLOSE_LOADING,
      });
      dispatch({
        type: ORDER_ALERT_ERROR,
        payload: text_sorry
          ? text_sorry
          : 'Lo sentimos, vuelve a intentarlo más tarde.',
      });
    },
    function (err) {
      dispatch({
        type: USER_CLOSE_LOADING,
      });
      dispatch({
        type: ORDER_ALERT_ERROR,
        payload: text_sorry
          ? text_sorry
          : 'Lo sentimos, vuelve a intentarlo más tarde.',
      });
    }
  );
};

export const orderApproved = (reference_code, data) => (dispatch, getState) => {
  const {
    site_texts: { text_accepting_order, text_sorry },
  } = getState().siteReducer;

  dispatch({
    type: USER_LOADING,
    payload: text_accepting_order ? text_accepting_order : 'Aceptando orden...',
  });
  dispatch({
    type: ORDER_VIEW_RATING,
    payload: false,
  });

  Api.callMethod(
    `v1/orders/approved/${reference_code}`,
    'POST',
    data,
    function (res) {
      if (res.status === 200) {
        window.location.reload();
        return;
      }
      dispatch({
        type: USER_CLOSE_LOADING,
      });
      dispatch({
        type: ORDER_ALERT_ERROR,
        payload: text_sorry
          ? text_sorry
          : 'Lo sentimos, vuelve a intentarlo más tarde.',
      });
    },
    function (err) {
      dispatch({
        type: USER_CLOSE_LOADING,
      });
      dispatch({
        type: ORDER_ALERT_ERROR,
        payload: text_sorry
          ? text_sorry
          : 'Lo sentimos, vuelve a intentarlo más tarde.',
      });
    }
  );
};
