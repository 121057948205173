import Api from "../Api";
import {
  FAVORITE_CLEAR,
  FAVORITE_LOADING,
  FAVORITE_PRODUCTS,
  FAVORITE_COMPANIES,
  FAVORITE_COMPANIES_VIEW,
  FAVORITE_PRODUCTS_VIEW,
  FAVORITE_PRODUCTS_PAGE,
  FAVORITE_COMPANIES_PAGE,
} from "../types/favoriteTypes";

export const changeFavoritesLoading = (payload) => (dispatch) => {
  dispatch({
    type: FAVORITE_LOADING,
    payload,
  });
};

export const getFavorites = () => (dispatch, getState) => {
  const { favorite_item_per_page } = getState().favoriteReducer;
  dispatch(changeFavoritesLoading(true));

  Api.callMethod(
    "v1/users/favorites",
    "get",
    null,
    (res) => {
      if (res.status === 200) {
        const { companies, products } = res.response;

        dispatch({
          type: FAVORITE_PRODUCTS,
          payload: products,
        });
        dispatch({
          type: FAVORITE_PRODUCTS_VIEW,
          payload: products.slice(0, favorite_item_per_page),
        });
        dispatch({
          type: FAVORITE_COMPANIES,
          payload: companies,
        });
        dispatch({
          type: FAVORITE_COMPANIES_VIEW,
          payload: companies.slice(0, favorite_item_per_page),
        });
      }
      dispatch(changeFavoritesLoading(false));
    },
    (error) => {
      console.log("Error getFavorites: ", error);
      dispatch(changeFavoritesLoading(false));
    }
  );
};

export const changeProductsPage =
  (current_page, initView, endView) => (dispatch, getState) => {
    const { favorite_products } = getState().favoriteReducer;

    dispatch({
      type: FAVORITE_PRODUCTS_VIEW,
      payload: favorite_products.slice(initView, endView),
    });
    dispatch({
      type: FAVORITE_PRODUCTS_PAGE,
      payload: current_page,
    });
  };

export const changeCompaniesPage =
  (current_page, initView, endView) => (dispatch, getState) => {
    const { favorite_companies } = getState().favoriteReducer;

    dispatch({
      type: FAVORITE_COMPANIES_VIEW,
      payload: favorite_companies.slice(initView, endView),
    });
    dispatch({
      type: FAVORITE_COMPANIES_PAGE,
      payload: current_page,
    });
  };

export const favoriteClear = () => (dispatch) => {
  dispatch({
    type: FAVORITE_CLEAR,
  });
};
