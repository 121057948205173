import React from "react";
import "./css/BoxWhite.css";

function BoxWhite(props) {
  const { marginTop } = props;

  return (
    <div className="boxWhite_container" style={{ marginTop }}>
      {props.title && (
        <div className="boxWhite_title">
          <div className="boxWhite_line" />
          <div className="boxWhite_title-text">{props.title}</div>
          <div className="boxWhite_line" />
        </div>
      )}
      {props.children}
    </div>
  );
}

BoxWhite.marginTop = {
  marginTop: 0,
};

export default BoxWhite;
