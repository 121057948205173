import React from 'react';
import CampaignForm from '../../../../../components/all/Forms/CampaingForm/CampaignForm';
import ManagerLayout from '../../layouts/ManagerLayout';

const NewCampaignView = () => {
  return (
    <ManagerLayout>
      <CampaignForm />
    </ManagerLayout>
  );
};

export default NewCampaignView;
