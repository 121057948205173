import React from 'react';
import { Link } from 'react-router-dom';
import './CampaignRow.css';
import { connect } from 'react-redux';
import Image from '../../../../images/believe.jpg';

const CampaignRow = (props) => {
  const {
    siteReducer: { site_language },
    data,
  } = props;

  return (
    <tr className="campaign__row">
      <td>
        <div className="campaign__data__main">
          <img
            alt="brand__logo"
            src={data.brand_image ? data.brand_image : Image}
          />
          <span className="campaign__data__name">{data.name}</span>
        </div>
      </td>
      <td>
        <div className="networks">
          {data.social_networks.slice(0, 3).map((network) => (
            <div
              className={`network__circle circle-${network.slug}`}
              key={network.id}
            >
              <i className={`${network.icon}`}></i>
            </div>
          ))}
        </div>
      </td>
      <td>{`${data.date_start} - ${data.date_end}`}</td>
      <td>
        <div className="campaign__status__row">
          <div
            className="dot"
            style={{ backgroundColor: data.status_color }}
          ></div>
          {site_language === 'es' ? data.status_name : data.status_english_name}
        </div>
      </td>
      <td>{data.total_members}</td>
      <td>
        <Link
          className="view__detail"
          style={{ color: data.status_color }}
          to={
            data.campaign_status_id === 1
              ? `/manager/campaign/${data.consecutive}/edit`
              : `/manager/campaign/${data.consecutive}`
          }
        >
          {data.campaign_status_id === 1 ? 'Editar' : 'Ver detalle'}
        </Link>
      </td>
    </tr>
  );
};
const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(CampaignRow);
