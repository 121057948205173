import {
  PRINCIPAL_TAG,
  TAGS,
  TAGS_LOADING,
  TAGS_USER,
} from '../types/tagTypes';

const INITIAL_STATE = {
  tags_loading: true,
  tags: [],
  tags_user: [],
  principal_tag: '',
};

const tagsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TAGS_LOADING:
      return {
        ...state,
        tags_loading: action.payload,
      };
    case TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case TAGS_USER:
      return {
        ...state,
        tags_user: action.payload,
      };
    case PRINCIPAL_TAG:
      return {
        ...state,
        principal_tag: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default tagsReducer;
