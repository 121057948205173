import React, { Component } from "react";
import { connect } from "react-redux";
import OrdersView from "./views/OrdersView";
import ManagerLayout from "./layouts/ManagerLayout";

import * as orderActions from "../../../functionality/actions/orderActions";

class OrdersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSales: [
        {
          id: 1,
          description: "Mi 1 transacción",
          value: "100000",
          updated_on: "01/06/19",
          status: "1",
          image: "",
        },
        {
          id: 2,
          description: "Mi 2 transacción",
          value: "200000",
          updated_on: "02/06/19",
          status: "1",
          image: "",
        },
        {
          id: 3,
          description: "Mi 3 transacción",
          value: "300000",
          updated_on: "03/06/19",
          status: "2",
          image: "",
        },
        {
          id: 4,
          description: "Mi 4 transacción",
          value: "400000",
          updated_on: "04/06/19",
          status: "3",
          image: "",
        },
        {
          id: 5,
          description: "Mi 5 transacción",
          value: "500000",
          updated_on: "05/06/19",
          status: "4",
          image: "",
        },
      ],
      viewSales: [
        {
          id: 1,
          description: "Mi 1 transacción",
          value: "100000",
          updated_on: "01/06/19",
          status: "1",
          image: "",
        },
        {
          id: 2,
          description: "Mi 2 transacción",
          value: "200000",
          updated_on: "02/06/19",
          status: "1",
          image: "",
        },
        {
          id: 3,
          description: "Mi 3 transacción",
          value: "300000",
          updated_on: "03/06/19",
          status: "2",
          image: "",
        },
        {
          id: 4,
          description: "Mi 4 transacción",
          value: "400000",
          updated_on: "04/06/19",
          status: "3",
          image: "",
        },
        {
          id: 5,
          description: "Mi 5 transacción",
          value: "500000",
          updated_on: "05/06/19",
          status: "4",
          image: "",
        },
      ],
      dataOrders: [
        {
          id: 1,
          user: {
            id: 1,
            name: "Luis Ruiz",
            image: "",
          },
          quantity: 1,
          description: "1 Solicitud",
          value: "100000",
          created_at: "01/06/19",
          status: "1",
          image: "",
        },
        {
          id: 2,
          user: {
            id: 2,
            name: "Carlos Gonzáles",
            image: "",
          },
          description: "2 Solicitud",
          value: "200000",
          created_at: "02/06/19",
          status: "1",
          image: "",
        },
        {
          id: 3,
          user: {
            id: 3,
            name: "Maria Sarmiento",
            image: "",
          },
          description: "3 Solicitud",
          value: "300000",
          created_at: "03/06/19",
          status: "2",
          image: "",
        },
        {
          id: 4,
          user: {
            id: 4,
            name: "Cristina Rodríguez",
            image: "",
          },
          description: "4 Solicitud",
          value: "400000",
          created_at: "04/06/19",
          status: "3",
          image: "",
        },
        {
          id: 5,
          user: {
            id: 5,
            name: "Alfonso Boyacá",
            image: "",
          },
          description: "5 Solicitud",
          value: "500000",
          created_at: "05/06/19",
          status: "1",
          image: "",
        },
      ],
      viewOrders: [
        {
          id: 1,
          user: {
            id: 1,
            name: "Luis Ruiz",
            image: "",
          },
          quantity: 1,
          description: "1 Solicitud",
          value: "100000",
          created_at: "01/06/19",
          status: "1",
          image: "",
        },
        {
          id: 2,
          user: {
            id: 2,
            name: "Carlos Gonzáles",
            image: "",
          },
          quantity: 2,
          description: "2 Solicitud",
          value: "200000",
          created_at: "02/06/19",
          status: "1",
          image: "",
        },
        {
          id: 3,
          user: {
            id: 3,
            name: "Maria Sarmiento",
            image: "",
          },
          quantity: 1,
          description: "3 Solicitud",
          value: "300000",
          created_at: "03/06/19",
          status: "2",
          image: "",
        },
        {
          id: 4,
          user: {
            id: 4,
            name: "Cristina Rodríguez",
            image: "",
          },
          quantity: 3,
          description: "4 Solicitud",
          value: "400000",
          created_at: "04/06/19",
          status: "3",
          image: "",
        },
        {
          id: 5,
          user: {
            id: 5,
            name: "Alfonso Boyacá",
            image: "",
          },
          quantity: 1,
          description: "5 Solicitud",
          value: "500000",
          created_at: "05/06/19",
          status: "1",
          image: "",
        },
      ],
      dataPurchases: [
        {
          id: 1,
          description: "Mi 1 transacción",
          value: "100000",
          updated_on: "01/06/19",
          status: "1",
          image: "",
        },
        {
          id: 2,
          description: "Mi 2 transacción",
          value: "200000",
          updated_on: "02/06/19",
          status: "2",
          image: "",
        },
        {
          id: 3,
          description: "Mi 3 transacción",
          value: "300000",
          updated_on: "03/06/19",
          status: "3",
          image: "",
        },
        {
          id: 4,
          description: "Mi 4 transacción",
          value: "400000",
          updated_on: "04/06/19",
          status: "4",
          image: "",
        },
      ],
      viewPurchases: [
        {
          id: 1,
          description: "Mi 1 transacción",
          value: "100000",
          updated_on: "01/06/19",
          status: "1",
          image: "",
        },
        {
          id: 2,
          description: "Mi 2 transacción",
          value: "200000",
          updated_on: "02/06/19",
          status: "2",
          image: "",
        },
        {
          id: 3,
          description: "Mi 3 transacción",
          value: "300000",
          updated_on: "03/06/19",
          status: "3",
          image: "",
        },
        {
          id: 4,
          description: "Mi 4 transacción",
          value: "400000",
          updated_on: "04/06/19",
          status: "4",
          image: "",
        },
      ],
      viewSection: "sales",
      codFilter: "",
      codFilterOrders: "",
      codFilterPurchases: "",
    };
  }

  componentDidMount() {
    const {
      viewOrdersByType,
      match: {
        params: { type },
      },
    } = this.props;
    viewOrdersByType(type);
  }

  handleChangeSection = (viewSection) => {
    this.setState({
      viewSection,
    });
  };

  handleFilter = (codStatus) => {
    const { order_list_seller, oderChangeViewSeller } = this.props;
    let viewSales = [];
    if (codStatus !== "") {
      viewSales = order_list_seller.filter(
        (item) => parseInt(item.status) === codStatus
      );
    } else {
      viewSales = order_list_seller;
    }
    oderChangeViewSeller(viewSales);
    this.setState({ codFilter: codStatus });
  };

  handleFilterOrders = (codStatus) => {
    let viewOrders = [];
    if (codStatus !== "") {
      viewOrders = this.state.dataOrders.filter(
        (item) => parseInt(item.status) === codStatus
      );
    } else {
      viewOrders = this.state.dataOrders;
    }
    this.setState({ viewOrders, codFilterOrders: codStatus });
  };

  handleFilterPurchases = (codStatus) => {
    const { order_list_buyer, oderChangeViewBuyer } = this.props;
    let viewPurchases = [];
    if (codStatus !== "") {
      viewPurchases = order_list_buyer.filter(
        (item) => parseInt(item.status) === codStatus
      );
    } else {
      viewPurchases = order_list_buyer;
    }
    oderChangeViewBuyer(viewPurchases);
    this.setState({ codFilterPurchases: codStatus });
  };

  componentDidUpdate(oldProps) {
    const {
      match: {
        params: { type: oldType },
      },
    } = oldProps;
    const {
      match: {
        params: { type },
      },
      viewOrdersByType,
    } = this.props;

    if (oldType !== type) {
      viewOrdersByType(type);
    }
  }

  render() {
    const {
      orderReducer: { order_list_view_seller, order_list_view_buyer },
      siteReducer: {
        site_texts: { text_orders },
      },
    } = this.props;

    return (
      <ManagerLayout>
        <div className="title-manager">
          <div>
            <span className="icon-shopping-car" />
            {text_orders ? text_orders : "Órdenes"}
          </div>
        </div>
        <OrdersView
          sales={order_list_view_seller}
          orders={this.state.viewOrders}
          purchases={order_list_view_buyer}
          viewSection={this.state.viewSection}
          changeSection={this.handleChangeSection}
          filter={this.handleFilter}
          codFilter={this.state.codFilter}
          filterOrders={this.handleFilterOrders}
          codFilterOrders={this.state.codFilterOrders}
          filterPurchases={this.handleFilterPurchases}
          codFilterPurchases={this.state.codFilterPurchases}
        />
      </ManagerLayout>
    );
  }
}

const mapStateToProps = ({ orderReducer, siteReducer }) => {
  return {
    orderReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, orderActions)(OrdersPage);
