import {
  SEARCH_CITY,
  SEARCH_CATEGORY,
  SEARCH_RATING,
  SEARCH_NAME,
  SEARCH_CLEAR,
  SEARCH_TYPE,
  SEARCH_DEPARTMENT,
  SEARCH_CITIES,
  SEARCH_URL,
  SEARCH_REDIRECT,
  SEARCH_LOADING,
  SEARCH_LIST,
} from "../types/searchTypes";

const INITIAL_STATE = {
  search_city: "",
  search_category: "",
  search_rating: "",
  search_name: "",
  search_type: "company",
  search_cities: [],
  search_department: "",
  search_redirect: false,
  search_url: "",
  search_loading: false,
  search_list: [],
};

const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_CLEAR:
      return {
        ...state,
        search_city: "",
        search_category: "",
        search_rating: "",
        search_name: "",
        search_type: "company",
        search_cities: [],
        search_department: "",
        search_redirect: false,
        search_url: "",
        search_loading: false,
        search_list: [],
      };
    case SEARCH_TYPE:
      return {
        ...state,
        search_type: action.payload,
      };
    case SEARCH_CITY:
      return {
        ...state,
        search_city: action.payload,
      };
    case SEARCH_CATEGORY:
      return {
        ...state,
        search_category: action.payload,
      };
    case SEARCH_RATING:
      return {
        ...state,
        search_rating: action.payload,
      };
    case SEARCH_NAME:
      return {
        ...state,
        search_name: action.payload,
      };
    case SEARCH_DEPARTMENT:
      return {
        ...state,
        search_department: action.payload,
      };
    case SEARCH_CITIES:
      return {
        ...state,
        search_cities: action.payload,
      };
    case SEARCH_URL:
      return {
        ...state,
        search_url: action.payload,
        search_redirect: true,
      };
    case SEARCH_REDIRECT:
      return {
        ...state,
        search_redirect: action.payload,
      };
    case SEARCH_LOADING:
      return {
        ...state,
        search_loading: true,
      };
    case SEARCH_LIST:
      return {
        ...state,
        search_list: action.payload,
        search_loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default searchReducer;
