export const USER_VALIDATE = 'user_validate';
export const USER_DATA = 'user_data';
export const USER_LOGOUT = 'user_logout';
export const USER_FIRSTNAME = 'user_firstname';
export const USER_LASTNAME = 'user_lastname';
export const USER_EMAIL = 'user_email';
export const USER_DOC_TYPE = 'user_doc_type';
export const USER_DOC_NUMBER = 'user_number';
export const USER_PASSWORD = 'user_password';
export const USER_ACTIVITY = 'user_activity';
export const USER_INIT = 'user_init';
export const USER_INIT_ERROR = 'user_init_error';
export const USER_LOADING = 'user_loading';
export const USER_LOADING_INIT = 'user_loading_init';
export const USER_ERROR = 'user_error';
export const USER_CLEAR = 'user_clear';
export const USER_CLEAR_ERROR = 'user_clear_error';
export const USER_SUCCESS = 'user_success';
export const USER_CLOSE_LOADING = 'user_close_loading';
export const USER_DOC_DATE = 'user_doc_date';
export const USER_PROFESSION = 'user_profession';
export const USER_BILL = 'user_bill';
export const USER_RUT_SCANN = 'user_rut_scann';
export const USER_NIT_NUMBER = 'user_nit_number';
export const USER_DOC_FRONT = 'user_doc_front';
export const USER_DOC_BACK = 'user_doc_back';
export const USER_BANK_CERTIFICATION = 'user_bank_certification';
export const USER_CAMARA_COMERCIO = 'user_camara_comercio';
export const USER_BUSINESS_NAME = 'user_business_name';
export const USER_PHONE = 'user_phone';
export const USER_DESCRIPTION = 'user_description';
export const USER_AVG_SALES = 'user_avg_sales';
export const USER_WEBSITE = 'user_website';
export const USER_FACEBOOK = 'user_facebook';
export const USER_TWITTER = 'user_twitter';
export const USER_LINKEDIN = 'user_linkedin';
export const USER_INSTAGRAM = 'user_instagram';
export const USER_COD_CITY = 'user_cod_city';
export const USER_ADDRESS = 'user_address';
export const USER_ADDRESS_ADDON = 'user_address_addon';
export const USER_COD_ACTIVITY = 'user_cod_activity';
export const USER_COD_DEPARTMENT = 'user_cod_department';
export const USER_CHANGE_STEP = 'user_change_step';
export const USER_INITIAL_STEP = 'user_initial_step';
export const USER_SUCCESS_ACTIVATION = 'user_success_activation';
export const USER_TIMETABLE = 'user_timetable';
export const USER_LIST_BUSINESS = 'user_list_business';
export const USER_CHANGE_BUSINESS_PAGE = 'user_change_business_page';
export const USER_BUSINESS_CATEGORY = 'user_business_category';
export const USER_BUSINESS_CATEGORY_CLEAR = 'user_business_category_clear';
export const USER_BUSINESS_CHANGE_PAGE_CATEGORY =
  'user_business_change_page_category';
export const USER_PRODUCTS = 'user_products';
export const USER_LIST_TYPE_PRODUCTS = 'user_list_type_products';
export const USER_CHANGE_PAGE_PRODUCTS = 'user_change_page_products';
export const USER_CLEAR_TYPE_PRODUCTS = 'user_clear_type_products';
export const USER_USERNAME = 'user_username';
export const USER_USERNAME_ERROR = 'user_username_error';
export const USER_USERNAME_VALIDATE = 'user_username_validate';
export const USER_USERNAME_SUCCESS = 'user_username_success';
export const USER_PASSWORD_NEW = 'user_password_new';
export const USER_PASSWORD_REPEAT = 'user_password_repeat';
export const USER_PRODUCT_SEARCH = 'user_product_search';
export const USER_PRODUCT_CHANGE_SEARCH = 'user_product_change_search';
export const USER_BUSINESS_SEARCH = 'user_business_search';
export const USER_BUSINESS_CHANGE_SEARCH = 'user_business_change_search';
export const USER_BUSINESS_CATEGORY_SEARCH = 'user_business_category_search';
export const USER_CHANGE_FILTER_PRICE = 'user_change_filter_price';
export const USER_FILTER_PRICE = 'user_filter_price';
export const USER_FAVORITES = 'user_favorites';
export const USER_TYPE_ID = 'user_type_id';
export const USER_FAVORITE_COMPANIES = 'user_FAVORITE_COMPANIES';
export const USER_VERIFIED = 'USER_VERIFIED';
export const USER_COMPANIES = 'USER_COMPANIES';
export const USER_REFERRED = 'USER_REFERRED';
export const USER_TYPE_PERSON = 'USER_TYPE_PERSON';
export const USER_RISK_CENTERS = 'USER_RISK_CENTERS';
export const USER_LEGAL_REPRESENTATIVE = 'USER_LEGAL_REPRESENTATIVE';
export const USER_NAME_LEGAL_REPRESENTATIVE = 'USER_NAME_LEGAL_REPRESENTATIVE';
export const USER_LASTNAME_LEGAL_REPRESENTATIVE =
  'USER_LASTNAME_LEGAL_REPRESENTATIVE';
export const USER_SOCIAL = 'USER_SOCIAL';
export const USER_PROVIDERS = 'USER_PROVIDERS';
export const USER_VALID_INFORMATION = 'USER_VALID_INFORMATION';
export const USER_SOCIAL_NETWORKS = 'USER_SOCIAL_NETWORKS';
export const USER_PRINCIPAL_NETWORK = 'USER_PRINCIPAL_NETWORK';
export const USER_BIRTHDATE = 'USER_BIRTHDATE';
