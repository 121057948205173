import React, { Component } from 'react';
import { connect } from 'react-redux';
import ManagerLayout from './layouts/ManagerLayout';
import TopSpinner from '../../../components/all/TopSpinner';

import * as paymentHistorialActions from '../../../functionality/actions/paymentHistorialActions';
import Error from '../../../components/all/Error';
import Pagination from '../../../components/all/Pagination';

class PaymentHistorialPage extends Component {
  componentDidMount() {
    const { paymentHistorial } = this.props;
    paymentHistorial();
  }

  changePage = (currentPage, initView, endView) => {
    const {
      paymentHistorialReducer: { payment_historial },
      paymentHistorialPage,
    } = this.props;
    let list_view = payment_historial.slice(initView, endView);
    let data = {
      list_view,
      page: currentPage,
    };
    paymentHistorialPage(data);
  };

  render() {
    const {
      paymentHistorialReducer: {
        payment_historial_loading,
        payment_historial_error,
        payment_historial_view,
        payment_historial,
        payment_historial_items_page,
        payment_historial_page,
      },
      siteReducer: {
        site_texts: {
          text_commission_history,
          text_date,
          text_bill,
          text_concept,
          text_bank,
          text_status,
          text_price,
          text_transactions_made,
        },
      },
    } = this.props;
    const numberFormat = new Intl.NumberFormat('es-CO');

    return (
      <ManagerLayout>
        <div className="title-manager">
          <div>
            <span className="icon-card" />
            {text_commission_history
              ? text_commission_history
              : 'Historial Comisiones'}
          </div>
        </div>
        {payment_historial_loading && <TopSpinner />}
        {!payment_historial_loading && payment_historial_error && (
          <Error type="error" />
        )}
        {!payment_historial_error &&
          !payment_historial_loading &&
          !payment_historial_view.length && (
            <Error
              type="no-data"
              title={
                text_transactions_made
                  ? text_transactions_made
                  : 'No tienes transacciones efectuadas.'
              }
            />
          )}
        {!payment_historial_loading && payment_historial_view.length > 0 && (
          <div className="table-responsive-md">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th className={`color-purple text-center`}>
                    {text_date ? text_date : 'Fecha'}
                  </th>
                  <th className={`color-purple text-center`}>
                    {text_bill ? text_bill : 'No. Factura'}
                  </th>
                  <th className={`color-purple text-center`}>
                    {text_concept ? text_concept : 'Concepto'}
                  </th>
                  <th className={`color-purple text-center`}>
                    {text_bank ? text_bank : 'Banco'}
                  </th>
                  <th className={`color-purple text-center`}>
                    {text_status ? text_status : 'Estado'}
                  </th>
                  <th className={`color-purple text-center`}>
                    {text_price ? text_price : 'Valor'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {payment_historial_view.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.created_at}</td>
                      <td>{item.bill}</td>
                      <td>{item.description}</td>
                      <td>{item.bank ? item.bank : item.franchise}</td>
                      <td>{item.response}</td>
                      <td className="center">
                        $ {numberFormat.format(item.value / 100)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              data={payment_historial}
              itemsPerPage={payment_historial_items_page}
              currentPage={payment_historial_page}
              changePage={this.changePage}
            />
          </div>
        )}
      </ManagerLayout>
    );
  }
}

const mapStateToProps = ({ paymentHistorialReducer, siteReducer }) => {
  return {
    paymentHistorialReducer,
    siteReducer,
  };
};

export default connect(
  mapStateToProps,
  paymentHistorialActions
)(PaymentHistorialPage);
