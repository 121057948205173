import React, { useState, useEffect } from 'react';
import './InputTextArea.css';

const InputTextArea = (props) => {
  const { name, label, isRequired, defaultValue, handleInputChange } = props;
  const [value, setValue] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
      handleInputChange(defaultValue);
    }
  }, []);

  /**
   * This function takes a value and calls the handleInputChange function with it.
   */
  const handleChange = (value) => {
    setValue(value);
    handleInputChange(value);
  };
  return (
    <div className="input__group">
      {label && (
        <label htmlFor={name}>{`${label} ${isRequired ? '*' : ''}`}</label>
      )}
      <textarea
        className="textarea__input"
        id={name}
        name={name}
        value={value}
        onChange={(event) => handleChange(event.currentTarget.value)}
        required={isRequired}
      />
    </div>
  );
};

export default InputTextArea;
