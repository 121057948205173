import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UserImageRating from "./UserImageRating";
import "./css/SlideProduct.css";

import * as userActions from "../../../functionality/actions/userActions";
import * as cartActions from "../../../functionality/actions/cartActions";
import { isPromo } from "../../../functionality/Global";

const { addCart } = cartActions;
const { userFavorite } = userActions;

class SlideProduct extends Component {
  toLike = (e, id) => {
    e.preventDefault();
    const { userFavorite } = this.props;
    userFavorite(id);
  };

  toAddCart = (e, data) => {
    e.preventDefault();
    const { addCart } = this.props;
    addCart(data);
  };

  render() {
    const numberFormat = new Intl.NumberFormat("es-CO");
    let {
      data,
      others,
      userReducer: { favorite_products },
      siteReducer: {
        site_texts: { text_new_circle },
      },
    } = this.props;
    data = data.product ? data.product : data;
    let prev_discount =
      data.price_promo !== 0 ? (data.price_promo * 100) / data.price : 0;
    let discount = prev_discount > 0 ? 100 - prev_discount : 0;
    let images = data.images;
    let user_data = {
      name: data.user.business_name
        ? data.user.business_name
        : data.user.username,
      image: data.user.image,
      rating: data.user.rating,
    };

    let date_promo = data.promotion_date
      ? new Date(data.promotion_date)
      : false;
    let is_promo = false;
    if (date_promo) {
      is_promo = isPromo(date_promo);
    }

    let is_favorite =
      favorite_products && favorite_products.length > 0
        ? favorite_products.filter((item) => item.product_id === data.id)
        : [];

    return (
      <div className="slideProduct_container">
        {others.type === "percentage" && discount !== 0 && is_promo && (
          <div className="slideProduct_circle">{Math.ceil(discount)}%</div>
        )}
        {others.type === "new" && (
          <div className="slideProduct_circle">
            {text_new_circle ? text_new_circle : "Nuevo"}
          </div>
        )}
        <Link to={`/product/${data.slug}`} innerRef={(i) => (this.link = i)}>
          <div className="slideProduct_image">
            <div className="slideProduct_menu">
              <button onClick={(e) => this.toLike(e, data.id)}>
                {is_favorite.length > 0 ? (
                  <span className="icon-like-selected" />
                ) : (
                  <span className="icon-like" />
                )}
              </button>
              <button onClick={(e) => this.toAddCart(e, data)}>
                <span className="icon-to-cart" />
              </button>
            </div>
            <img src={images[0].image} alt={data.name} />
          </div>
          <div className="slideProduct_info">
            <UserImageRating data={user_data} />
            <div className="slideProduct_box-name">
              <div className="slideProduct_name">{data.name}</div>
              {data.price_promo !== 0 && is_promo ? (
                <div className="slideProduct_box-price">
                  <div className="slideProduct_discount">
                    BAX$ {numberFormat.format(data.price)}
                  </div>
                  <div className="slideProduct_price">
                    BAX$ {numberFormat.format(data.price_promo)}
                  </div>
                </div>
              ) : (
                <div className="slideProduct_box-price">
                  <div className="slideProduct_price">
                    BAX$ {numberFormat.format(data.price)}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="slideProduct_box-price-mobile">
            {data.price_promo !== 0 && is_promo ? (
              <>
                <div className="slideProduct_discount">
                  BAX$ {numberFormat.format(data.price)}
                </div>
                <div className="slideProduct_price">
                  BAX$ {numberFormat.format(data.price_promo)}
                </div>
              </>
            ) : (
              <div className="slideProduct_price">
                BAX$ {numberFormat.format(data.price)}
              </div>
            )}
          </div>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = ({ cartReducer, userReducer, siteReducer }) => {
  return {
    cartReducer,
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  addCart,
  userFavorite,
};

export default connect(mapStateToProps, mapDispatchToProps)(SlideProduct);
