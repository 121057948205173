export const PRODUCT_ADD_IMAGES = 'product_add_images';
export const PRODUCT_LOADING_IMAGES = 'PRODUCT_LOADING_IMAGES';
export const PRODUCT_NAME = 'product_name';
export const PRODUCT_BRAND_ID = 'PRODUCT_BRAND_ID';
export const PRODUCT_DESCRIPTION = 'product_description';
export const PRODUCT_SHORT_DESCRIPTION = 'product_SHORT_description';
export const PRODUCT_PRICE = 'product_price';
export const PRODUCT_PRICE_PROMO = 'product_price_promo';
export const PRODUCT_CATEGORY = 'product_category';
export const PRODUCT_TYPE = 'product_type';
export const PRODUCT_SKU = 'product_sku';
export const PRODUCT_CLEAR = 'product_clear';
export const PRODUCT_ID = 'product_id';
export const PRODUCT_ERROR = 'product_error';
export const PRODUCT_DETAIL_ERROR = 'product_detail_error';
export const PRODUCT_SUCCESS = 'product_success';
export const PRODUCT_CLEAR_ERROR = 'product_clear_error';
export const PRODUCT_IS_ACTIVE = 'product_is_active';
export const PRODUCT_MY_LIST = 'product_my_list';
export const PRODUCT_LOADING = 'product_loading';
export const PRODUCT_CHANGE_MANAGER_PAGE = 'product_change_manager_page';
export const PRODUCT_CHANGE_MANAGER_SEARCH = 'product_change_manager_search';
export const PRODUCT_LIST_SERVICES = 'product_list_services';
export const PRODUCT_LIST_PRODUCTS = 'product_list_products';
export const PRODUCT_DETAIL = 'product_detail';
export const PRODUCT_DETAIL_LOADING = 'PRODUCT_DETAIL_LOADING';
export const PRODUCT_RELATED_PRODUCTS = 'PRODUCT_RELATED_PRODUCTS';
export const PRODUCT_CLEAR_DETAIL = 'product_clear_detail';
export const PRODUCT_DATE_PROMO = 'product_date_promo';
export const PRODUCT_COMMENTS = 'product_comments';
