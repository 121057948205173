import React from "react";
import "./css/ListOffer.css";
import BoxWhite from "./BoxWhite";
import GeneralSlider from "./GeneralSlider";
import SlideOffer from "./SlideOffer";
import { connect } from "react-redux";

function ListOffer(props) {
  const {
    siteReducer: {
      site_texts: { text_title_offer },
    },
  } = props;

  return (
    <BoxWhite
      title={
        text_title_offer ? text_title_offer : "OFERTAS POR TIEMPO LIMITADO"
      }
    >
      <GeneralSlider
        data={props.data}
        slide={SlideOffer}
        viewItems={3}
        minItems={1}
        viewMore="/offers"
      />
    </BoxWhite>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(ListOffer);
