import React from 'react';
import Analityc from '../../Analytic/Analytic';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../utils/formatNumber';

function Youtube(props) {
  const {
    statistics,
    siteReducer: {
      site_texts: {
        text_views,
        text_estimated_minutes_viewed,
        text_network_comments,
        text_shared,
        text_new_subscribers,
        text_subscribers_removed,
        text_videos_added_to_playlist,
        text_videos_removed_from_playlist,
      },
    },
  } = props;

  const getValue = (name) => {
    if (!statistics.columnHeaders || !statistics.rows) {
      return 0;
    }
    const index = statistics.columnHeaders.findIndex(
      (item) => item.name === name
    );
    if (index < 0) return 0;

    let total = 0;

    statistics.rows.forEach((item) => {
      total = total + item[index];
    });

    return formatNumber(total);
  };

  return (
    <>
      <div className="analitycs__container">
        <Analityc
          icon={'fa-solid fa-users-rectangle'}
          title={text_views || 'Vistas'}
          value={getValue('views')}
        />
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={text_estimated_minutes_viewed || 'Minutos estimados vistos'}
          value={getValue('estimatedMinutesWatched')}
        />
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={text_estimated_minutes_viewed || 'Minutos estimados vistos'}
          value={getValue('averageViewDuration')}
        />
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={text_new_subscribers || 'Suscriptores nuevos'}
          value={getValue('subscribersGained')}
        />
      </div>
      <div className="analitycs__container">
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={text_network_comments || 'Comentarios'}
          value={getValue('comments')}
        />
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={'Likes'}
          value={getValue('likes')}
        />
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={'Dislikes'}
          value={getValue('dislikes')}
        />
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={text_shared || 'Compartido'}
          value={getValue('shares')}
        />
      </div>
      <div className="analitycs__container">
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={text_new_subscribers || 'Suscritores nuevos'}
          value={getValue('subscribersGained')}
        />
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={text_subscribers_removed || 'Suscritores retirados'}
          value={getValue('subscribersLost')}
        />
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={
            text_videos_added_to_playlist || 'Videos agregados al playlist'
          }
          value={getValue('videosAddedToPlaylists')}
        />
        <Analityc
          icon={'fa-solid fa-layer-group'}
          title={
            text_videos_removed_from_playlist || 'Videos removidos del playlist'
          }
          value={getValue('videosRemovedFromPlaylists')}
        />
      </div>
    </>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return { siteReducer };
};

export default connect(mapStateToProps, null)(Youtube);
