import React, { Component } from "react";
import ProductDetailView from "./views/ProductDetailView";
import ManagerLayout from "./layouts/ManagerLayout";

class ProductDetailPage extends Component {
  render() {
    return (
      <ManagerLayout>
        <ProductDetailView codProduct={this.props.match.params.id} />
      </ManagerLayout>
    );
  }
}

export default ProductDetailPage;
