import React, { Component } from 'react';
// import { render } from "react-dom";
// import Api from "../../../functionality/Api";

class InstagramRegisterPage extends Component {
  async componentDidMount() {
    // const {
    //   location: { search },
    // } = this.props;
    // console.log(this.props);
    // if (search && search.indexOf("?code=") >= 0) {
    //   let code = search.split("?code=");
    //   code = code[1];
    //   console.log(code);
    //   Api.callMethod(
    //     "v1/instagram/get-token",
    //     "POST",
    //     { code },
    //     (res) => {
    //       console.log(res);
    //     },
    //     (error) => {
    //       console.log("Error: ", error);
    //     }
    //   );
    // }
  }

  render() {
    return (
      <div>
        <h1>Instagram Page</h1>
      </div>
    );
  }
}

export default InstagramRegisterPage;
