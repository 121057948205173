import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./css/AdSlider.css";

function AdSlider(props) {
  const {
    adReducer: { ad_list_view },
  } = props;

  if (!ad_list_view.length) {
    return null;
  }

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="adSlider_container generalSlider_container">
      <Slider {...settings}>
        {ad_list_view.map((item) => {
          const { resource } = item;
          let link = "/";

          if (item.space === 5 || item.space === 6) {
            const company = resource.username ? resource.username : resource.id;
            link = `${link}${company}`;
          } else {
            const product = resource.slug ? resource.slug : resource.id;
            link = `${link}product/${product}`;
          }

          return (
            <Link to={link} key={item.id}>
              <picture>
                <source srcSet={item.image} media="(min-width: 600px)" />
                <img src={item.image} alt={item.name} />
              </picture>
            </Link>
          );
        })}
      </Slider>
    </div>
  );
}

const mapStateToProps = ({ adReducer }) => {
  return {
    adReducer,
  };
};

export default connect(mapStateToProps, null)(AdSlider);
