import React from 'react';
import Analityc from '../Analytic/Analytic';
import Audience from '../Audience/Audience';
import RecentPost from '../RecentPost/RecentPost';
import Header from './Header';
import './Metrics.css';
import Analytics from './Analytics';

// todo: do a request with the value of the metric button in (influencer profile) in this component.
const Metrics = (props) => {
  const { network, currentId, profileId } = props;

  return (
    <>
      {network && (
        <Header
          id={network.id}
          cover={network.cover}
          slug={network.slug}
          name={network.name}
          icon={network.icon}
          image={network.image}
          username={network.profile.username || network.profile.title}
          followers={network.followers}
          fans={network.fans}
          updateButton={currentId === profileId}
        />
      )}
      <div className="metrics__data">
        <RecentPost
          profile={network.profile}
          data={network.download_contents || network.contents}
          slug={network.slug}
          image={network.image}
          updatedAt={network.updated_at}
        />
        <Analytics {...network} />
        {/* <div className="audience">
          <div className="audience__title">
            <h3>audiencia</h3>
            <span> Demografico</span>
          </div>
          <div className=" row audience__container">
            <Audience data={data.ages_stat} title={'rango edades'} />
            <Audience data={data.country_stat} title={'top paises'} />
            <Audience data={data.gender_stat} title={'género'} />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Metrics;
