import React from 'react';
import CampaignRow from '../Row/CampaignRow';
import './CampaignTable.css';
import { connect } from 'react-redux';

const CampaignTable = (props) => {
  const {
    siteReducer: {
      site_texts: {
        text_campaign,
        text_networks,
        text_date,
        text_state,
        text_members,
        text_actions,
      },
    },
    campaignData,
  } = props;

  const campaign = campaignData.map((campaign) => (
    <CampaignRow data={campaign} key={campaign.id} />
  ));

  return (
    <div className="campaign__table__container">
      <table className="campaign__table">
        <thead className="campaignTable__head">
          <tr>
            <th className="campaignTable__title">
              {text_campaign ? text_campaign : 'Campaña'}
            </th>
            <th className="campaignTable__title">
              {text_networks ? text_networks : 'Redes'}
            </th>
            <th className="campaignTable__title">
              {text_date ? text_date : 'Fecha'}
            </th>
            <th className="campaignTable__title">
              {text_state ? text_state : 'Estado'}
            </th>
            <th className="campaignTable__title">
              {text_members ? text_members : 'Miembros'}
            </th>
            <th className="campaignTable__title">
              {text_actions ? text_actions : 'Acciones'}
            </th>
          </tr>
        </thead>
        <tbody>{campaign}</tbody>
      </table>
    </div>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(CampaignTable);
