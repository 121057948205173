import {
  PAYMENT_HISTORIAL,
  PAYMENT_HISTORIAL_CLEAR,
  PAYMENT_HISTORIAL_PAGE,
  PAYMENT_HISTORIAL_LOADING,
  PAYMENT_HISTORIAL_ERROR,
} from "../types/paymentHistorialTypes";

const INITIAL_STATE = {
  payment_historial: [],
  payment_historial_view: [],
  payment_historial_page: 1,
  payment_historial_items_page: 12,
  payment_historial_loading: false,
  payment_historial_error: false,
};

const paymentHistoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAYMENT_HISTORIAL_LOADING: {
      return {
        ...state,
        payment_historial_loading: true,
      };
    }
    case PAYMENT_HISTORIAL_CLEAR:
      return {
        ...state,
        payment_historial: [],
        payment_historial_view: [],
        payment_historial_page: 1,
        payment_historial_loading: false,
        payment_historial_error: false,
      };
    case PAYMENT_HISTORIAL:
      return {
        ...state,
        payment_historial: action.payload.list_total,
        payment_historial_view: action.payload.list_view,
        payment_historial_loading: false,
      };
    case PAYMENT_HISTORIAL_PAGE:
      return {
        ...state,
        payment_historial_view: action.payload.list_view,
        payment_historial_page: action.payload.page,
      };
    case PAYMENT_HISTORIAL_ERROR:
      return {
        ...state,
        payment_historial_error: true,
        payment_historial_loading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default paymentHistoryReducer;
