import React from 'react';
import Porposal from '../../../all/Porposal/Porposal';

function OrderProposal(props) {
  const { orderDetail } = props;

  return (
    <>
      <Porposal data={orderDetail.product} />
    </>
  );
}

export default OrderProposal;
