import Api from '../Api';
import {
  COUNTRY_LOADING,
  COUNTRIES,
  COUNTRY_SELECTED,
  COUNTRY_CITIES_LOADING,
  COUNTRY_CITIES,
  COUNTRY_CITY_SELECTED,
  COUNTRY_CITIES_SELECTED,
  COUNTRY_MULTIPLE_CITIES,
} from '../types/countryTypes';

export const changeCountryLoading = (payload) => (dispatch) => {
  dispatch({
    type: COUNTRY_LOADING,
    payload,
  });
};

export const changeCountries = (payload) => (dispatch) => {
  dispatch({
    type: COUNTRIES,
    payload,
  });
};

export const changeCountryCitiesLoading = (payload) => (dispatch) => {
  dispatch({
    type: COUNTRY_CITIES_LOADING,
    payload,
  });
};

export const changeCountryCities = (payload) => (dispatch) => {
  dispatch({
    type: COUNTRY_CITIES,
    payload,
  });
};

export const changeMultipleCities = (payload) => (dispatch) => {
  dispatch({
    type: COUNTRY_MULTIPLE_CITIES,
    payload,
  });
};

export const getCities = (country_id) => async (dispatch) => {
  dispatch(changeCountryCitiesLoading(true));

  try {
    const response = await Api.asyncCallMethod(
      `v1/countries/${country_id}/cities`,
      'GET'
    );
    dispatch(changeCountryCitiesLoading(false));
    dispatch(changeCountryCities(response.response.data));
  } catch (error) {
    console.log('Error getCities: ', error);
    dispatch(changeCountryCitiesLoading(false));
  }
};

export const changeCountrySelected =
  (payload, callCities = true) =>
  (dispatch) => {
    dispatch({
      type: COUNTRY_SELECTED,
      payload,
    });
    dispatch(changeCitySelected(null));

    if (callCities && payload) {
      dispatch(getCities(payload.id));
    }
  };

export const getCountries = () => async (dispatch) => {
  dispatch(changeCountryLoading(true));

  try {
    const response = await Api.asyncCallMethod(`v1/countries`, 'GET');
    dispatch(changeCountries(response.response.data));
    dispatch(changeCountryLoading(false));
  } catch (error) {
    console.log('Error getCountries: ', error);
    dispatch(changeCountryLoading(false));
  }
};

export const changeCitySelected = (payload) => (dispatch) => {
  dispatch({
    type: COUNTRY_CITY_SELECTED,
    payload,
  });
};

export const changeCitiesSelected = (payload) => (dispatch) => {
  dispatch({
    type: COUNTRY_CITIES_SELECTED,
    payload,
  });
};

export const addOrRemoveCities = (payload) => (dispatch, getState) => {
  const { country_cities_selected } = getState().countryReducer;
  let cities = [];

  if (payload) {
    cities = country_cities_selected;
    const isAdded = country_cities_selected.find(
      (city) => city.id === payload.id
    );

    if (isAdded) {
      cities = cities.filter((city) => city.id !== payload.id);
    } else {
      cities.push(payload);
    }
  }

  dispatch(changeCitiesSelected(cities));
};

export const setUserCities = () => async (dispatch, getState) => {
  const { country_cities_selected } = getState().countryReducer;

  const data = {
    cities: country_cities_selected,
  };

  try {
    await Api.asyncCallMethod(`v1/users/cities`, 'POST', data);
  } catch (error) {
    console.log('Error setUserCities: ', error);
  }
};
