import React, { Component } from "react";
import { connect } from "react-redux";
import MarketPlaceLayout from "./layouts/MarketplaceLayout";
import BoxWhite from "../../../components/user/marketplace/BoxWhite";
import Error from "../../../components/all/Error";
import TopSpinner from "../../../components/all/TopSpinner";
import SlideOffer from "../../../components/user/marketplace/SlideOffer";

class OffersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusSearch: false,
    };
  }

  openCloseSearch = (statusSearch) => {
    this.setState({
      statusSearch,
    });
  };

  render() {
    const {
      userReducer: { promotional_products, loading_init },
      siteReducer: {
        site_texts: { text_title_offer },
      },
    } = this.props;
    let view_promo =
      promotional_products.length > 0 ? promotional_products.slice(0, 12) : [];
    return (
      <MarketPlaceLayout
        statusSearch={this.state.statusSearch}
        openCloseSearch={this.openCloseSearch}
      >
        <div className="container shoppingCartView_container">
          <BoxWhite
            title={
              text_title_offer
                ? text_title_offer
                : "OFERTAS POR TIEMPO LIMITADO"
            }
          >
            {loading_init && <TopSpinner />}
            {!loading_init && view_promo.length > 0 ? (
              <div className="row">
                {view_promo.map((item) => {
                  return (
                    <div className="col-md-4" key={item.id}>
                      <SlideOffer data={item} />
                    </div>
                  );
                })}
              </div>
            ) : (
              <Error
                type="no-data"
                title={`No hay ofertas disponibles.`}
                buttonName="Ir al Inicio"
                link="/home"
              />
            )}
          </BoxWhite>
        </div>
      </MarketPlaceLayout>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return { userReducer, siteReducer };
};

export default connect(mapStateToProps)(OffersPage);
