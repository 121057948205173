import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";
import "./css/Header.css";
import MenuHeader from "./MenuHeader";
import Search from "./Search";

function viewLogo(is_user, onChangeMenu) {
  return (
    <Fragment>
      {is_user ? (
        <div className="header_box-logo">
          <button onClick={onChangeMenu}>
            <span className="icon-menu" />
          </button>
          <Link to="/home" className="">
            <img src={Logo} alt="Logo BAX" />
          </Link>
        </div>
      ) : (
        <div className="header_logo">
          <Link
            to="/home"
            className="d-flex align-items-center justify-content-center"
          >
            <img src={Logo} alt="Logo BAX" />
          </Link>
        </div>
      )}
    </Fragment>
  );
}

function Header(props) {
  let fixed = props.top > 540 ? true : false;
  return (
    <Fragment>
      <div className={`d-flex header_container`}>
        {viewLogo(props.is_user, props.onChangeMenu)}
        <MenuHeader search={true} openCloseSearch={props.openCloseSearch} />
        <Search
          statusSearch={props.statusSearch}
          openCloseSearch={props.openCloseSearch}
        />
      </div>
      {fixed === true && (
        <div className={`d-flex header_container header_fixed fixed`}>
          {viewLogo(props.is_user, props.onChangeMenu)}
          <MenuHeader
            search={true}
            openCloseSearch={props.openCloseSearch}
            fixed={fixed}
          />
          <Search
            statusSearch={props.statusSearch}
            openCloseSearch={props.openCloseSearch}
          />
        </div>
      )}
    </Fragment>
  );
}

const mapStateToProps = ({ userReducer }) => userReducer;

export default connect(mapStateToProps)(Header);
