import Api from "../Api";
import { SITE_LANGUAGE, SITE_LOADING, SITE_TEXTS } from "../types/siteTypes";
const { REACT_APP_LANGUAGE: APP_LANGUAGE } = process.env;

export const siteLoading = (payload) => (dispatch) => {
  dispatch({
    type: SITE_LOADING,
    payload,
  });
};

export const siteGetLanguage = () => (dispatch) => {
  let language = !APP_LANGUAGE ? "es" : APP_LANGUAGE;
  dispatch(siteLoading(true));

  dispatch({
    type: SITE_LANGUAGE,
    payload: language,
  });

  Api.callMethod(
    `v1/languages/${language}`,
    "get",
    null,
    (res) => {
      if (res.status === 200) {
        dispatch({
          type: SITE_TEXTS,
          payload: res.response.data,
        });
      }
      dispatch(siteLoading(false));
    },
    (error) => {
      console.log("Error siteGetLanguage: ", error);
    }
  );
};
