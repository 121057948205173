const {
  REACT_APP_API_ROUTE: API_URL,
  REACT_APP_LANGUAGE: APP_LANGUAGE,
  REACT_APP_COOKIE_NAME: COOKIE_NAME,
} = process.env;

class Api {
  getParams(method, data) {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const cookies = document.cookie.split(';');
    let token = cookies.filter(
      (item) => item.indexOf(`${COOKIE_NAME}_token=`) >= 0
    );
    if (token.length) {
      token = token[0].split('=');
      headers.Authorization = `Bearer ${token[1]}`;
    }

    let params = {
      mode: 'cors',
      method,
      headers,
    };

    if (
      method.toLowerCase() !== 'get' &&
      method.toLowerCase() !== 'delete' &&
      data
    ) {
      if (data) {
        data.language = APP_LANGUAGE ? APP_LANGUAGE : 'en';
      }
      params.body = JSON.stringify(data);
    }

    return params;
  }

  callMethod(path, method, data = null, callback, error) {
    const params = this.getParams(method, data);

    let url = API_URL + path;

    fetch(url, params)
      .then(function (res) {
        res.json().then(function (response) {
          callback({
            status: res.status,
            response,
          });
        });
      })
      .catch(function (err) {
        if (typeof error === 'function') {
          error(err);
        } else {
          console.log(err);
        }
      });
  }

  asyncCallMethod = async (path, method, data = null) => {
    const params = this.getParams(method, data);

    let url = API_URL + path;

    const firstPetition = await fetch(url, params).then((res) => res);
    const response = await firstPetition.json().then((res) => res);

    return {
      status: firstPetition.status,
      response,
    };
  };

  asyncSendFormData = async (path, method, data = null) => {
    const params = this.getParams(method);
    delete params.headers['Content-Type'];
    params.body = data;

    let url = API_URL + path;

    const firstPetition = await fetch(url, params).then((res) => res);
    const response = await firstPetition.json().then((res) => res);

    return {
      status: firstPetition.status,
      response,
    };
  };

  submitForm(url, data, callback) {
    let headers = {
      Accept: 'application/json',
    };

    const cookies = document.cookie.split(';');
    let token = cookies.filter(
      (item) => item.indexOf(`${COOKIE_NAME}_token=`) >= 0
    );
    if (token.length) {
      token = token[0].split('=');
      headers.Authorization = `Bearer ${token[1]}`;
    }

    fetch(API_URL + url, {
      method: 'POST',
      headers,
      body: data,
    })
      .then(function (res) {
        res.json().then(function (response) {
          callback({
            status: res.status,
            response,
          });
        });
      })
      .catch(function () {
        console.log('Error al subir la imagen');
      });
  }

  uploadFile(data, callback) {
    let headers = {
      Accept: 'application/json',
    };

    const cookies = document.cookie.split(';');
    let token = cookies.filter(
      (item) => item.indexOf(`${COOKIE_NAME}_token=`) >= 0
    );
    if (token.length) {
      token = token[0].split('=');
      headers.Authorization = `Bearer ${token[1]}`;
    }

    fetch(API_URL + 'assets/upload', {
      method: 'POST',
      headers,
      body: data,
    })
      .then(function (res) {
        res.json().then(function (data) {
          callback(data);
        });
      })
      .catch(function () {
        console.log('Error al subir la imagen');
      });
  }
  async uploadCampaignFile(data, route) {
    let headers = {
      Accept: 'application/json',
    };

    const cookies = document.cookie.split(';');
    let token = cookies.filter(
      (item) => item.indexOf(`${COOKIE_NAME}_token=`) >= 0
    );
    if (token.length) {
      token = token[0].split('=');
      headers.Authorization = `Bearer ${token[1]}`;
    }
    try {
      const res = await fetch(API_URL + route, {
        method: 'POST',
        headers,
        body: data,
      });
      return await res.json();
    } catch {
      console.log('Error al subir la imagen');
    }
  }
}

export default new Api();
