import React from 'react';
import CampaignDetailView from './views/CampaignDetailView/CampaignDetailView';
import CampaignChats from './views/CampaignDetailView/CampaignChats/CampaignChats';
import ManagerLayout from './layouts/ManagerLayout';

const CampaignChatsPage = () => {
  return (
    <ManagerLayout>
      <CampaignDetailView>
        <CampaignChats />
      </CampaignDetailView>
    </ManagerLayout>
  );
};

export default CampaignChatsPage;
