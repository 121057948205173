import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as userActions from '../../../../functionality/actions/userActions';
import { adListView } from '../../../../functionality/actions/adActions';
import HomeSlider from '../../../../components/user/marketplace/HomeSlider';
import HomeMenu from '../../../../components/user/marketplace/HomeMenu';
import BoxWhite from '../../../../components/user/marketplace/BoxWhite';
import Pagination from '../../../../components/all/Pagination';
// import ProductList from '../../../../components/user/marketplace/ProductList';
import MenuCategory from '../../../../components/user/marketplace/MenuCategory';
import CompaniesList from '../../../../components/user/marketplace/CompaniesList';
import TopSpinner from '../../../../components/all/TopSpinner';
import Error from '../../../../components/all/Error';
import FormSearch from '../../../../components/all/FormSearch';
import './css/ProductsView.css';

class CompaniesView extends Component {
  componentDidMount() {
    const { adListView } = this.props;

    adListView(5);
  }

  renderTitleActivity = (id, activities) => {
    const {
      siteReducer: { site_language },
    } = this.props;

    if (activities.length > 0) {
      let data = activities.filter((item) => item.id === parseInt(id));
      return (
        <div className="productsView_title-category">
          <Link to="/products">/MARKETPLACE</Link>/
          {site_language === 'es' ? data[0].name : data[0].english_name}
        </div>
      );
    }
    return null;
  };

  changePage = (currentPage, initView, endView) => {
    const { list_business, businessChangePage } = this.props;
    let business = list_business.slice(initView, endView);
    businessChangePage(business, currentPage);
  };

  handleChangeSearch = (search) => {
    const {
      list_all_business,
      item_per_page,
      businessChangeSearch,
      businessSearch,
    } = this.props;
    businessChangeSearch(search);
    if (search !== '') {
      businessChangeSearch(search);
    } else {
      let viewBusiness = list_all_business.slice(0, item_per_page);
      businessSearch({
        list_total: list_all_business,
        list_view: viewBusiness,
      });
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    const {
      list_all_business,
      item_per_page,
      business_search,
      businessSearch,
    } = this.props;
    if (business_search !== '') {
      let business = list_all_business.filter(
        (item) =>
          item.username.toLowerCase().indexOf(business_search.toLowerCase()) !==
            -1 ||
          item.business_name
            .toLowerCase()
            .indexOf(business_search.toLowerCase()) !== -1
      );
      let viewBusiness = business.slice(0, item_per_page);
      businessSearch({ list_total: business, list_view: viewBusiness });
    }
  };

  componentWillUnmount() {
    const { list_all_business, item_per_page, businessSearch } = this.props;
    let viewBusiness = list_all_business.slice(0, item_per_page);
    businessSearch({ list_total: list_all_business, list_view: viewBusiness });
  }

  render() {
    const {
      principalSlider,
      openCloseSearch,
      viewActivity,
      activities,
      loading_init,
      list_view_business,
      list_business,
      item_per_page,
      current_page,
      business_search,
      siteReducer: {
        site_texts: { text_no_companies, text_go_home },
      },
    } = this.props;
    return (
      <div>
        <HomeSlider data={principalSlider} />
        <HomeMenu openCloseSearch={openCloseSearch} />
        <div className="container">
          <BoxWhite>
            <div id="titleCategory">
              {viewActivity &&
                this.renderTitleActivity(viewActivity, activities)}
            </div>
            <div className="row">
              <div className="col-lg-3">
                <MenuCategory type="companies" data={activities} />
              </div>
              <div className="col-lg-9">
                {loading_init ? (
                  <TopSpinner />
                ) : (
                  <FormSearch
                    toSearch={this.handleSearch}
                    search={business_search}
                    changeSearch={this.handleChangeSearch}
                  />
                )}
                {!list_view_business.length && !loading_init ? (
                  <Error
                    type="no-data"
                    title={
                      text_no_companies
                        ? text_no_companies
                        : 'No hay empresas disponibles.'
                    }
                    buttonName={text_go_home ? text_go_home : 'Ir al Inicio'}
                    link="/home"
                  />
                ) : (
                  <Fragment>
                    <CompaniesList
                      companies={list_view_business}
                      showAds={true}
                    />
                    <Pagination
                      data={list_business}
                      itemsPerPage={item_per_page}
                      currentPage={current_page}
                      changePage={this.changePage}
                    />
                  </Fragment>
                )}
              </div>
            </div>
          </BoxWhite>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    ...userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  ...userActions,
  adListView,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesView);
