import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { getDateFormat } from '../../utils/dateActions';
import { changeUserSocialNetworks } from '../../functionality/actions/userActions';
import SelectOption from './Components/SelectOption';
import ModalSocial from './ModalSocial';
import Api from '../../functionality/Api';
const { REACT_APP_GOOGLE_CLIENT_ID: CLIENT_ID } = process.env;

class YouTubeButton extends Component {
  state = {
    tokenClient: null,
    gapiInited: null,
    gisInited: null,
    channels: [],
    contents: [],
    channel: null,
    show_modal: false,
    step: 0,
  };

  componentDidMount() {
    this.getScript('https://apis.google.com/js/api.js', this.gapiLoad);
    this.getScript('https://accounts.google.com/gsi/client', this.gisInit);
  }

  getScript = (src, onload) => {
    let scriptTag = document.createElement('script');
    scriptTag.src = src;
    scriptTag.onload = onload;
    scriptTag.onreadystatechange = onload;

    document.body.appendChild(scriptTag);
  };

  gapiInit = () => {
    window.gapi.client
      .init({
        // NOTE: OAuth2 'scope' and 'client_id' parameters have moved to initTokenClient().
      })
      .then(function () {
        // Load the Calendar API discovery document.
        window.gapi.client.load(
          'https://youtubeanalytics.googleapis.com/$discovery/rest?version=v2'
        );
        window.gapi.client.load(
          'https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest'
        );
      });
  };

  gapiLoad = () => {
    window.gapi.load('client', this.gapiInit);
  };

  gisInit = () => {
    const tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope:
        'https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/youtube.readonly',
    });
    this.setState({ tokenClient });
  };

  showError = (error) => {
    const { onChangeLoading } = this.props;

    Swal.fire({
      title: 'Upsss!!!',
      text: error,
      type: 'error',
      confirmButtonColor: '#50318B',
    }).then(() => {
      onChangeLoading('');
    });
  };

  getData = () => {
    const { tokenClient } = this.state;
    const {
      siteReducer: {
        site_texts: { text_loading_social_data, text_error_social },
      },
      onChangeLoading,
    } = this.props;

    tokenClient.callback = async (res) => {
      onChangeLoading(
        text_loading_social_data || 'Conectando con la red social...'
      );
      try {
        const channels = await window.gapi.client.youtube.channels
          .list({
            part: [
              'id,snippet,statistics,brandingSettings,contentDetails,contentOwnerDetails,localizations',
            ],
            mine: true,
          })
          .then((res) => res);

        if (channels.status === 200) {
          const {
            result: { items },
          } = channels;

          const listChannels = [];

          items.forEach((channel) => {
            const data = {
              id: channel.id,
              etag: channel.etag,
              cover:
                channel.brandingSettings.image &&
                channel.brandingSettings.image.bannerExternalUrl
                  ? channel.brandingSettings.image.bannerExternalUrl
                  : null,
              ...channel.snippet,
              contentDetails: channel.contentDetails,
              statistics: channel.statistics,
              localizations: channel.localizations
                ? channel.localizations
                : null,
            };
            listChannels.push(data);
          });

          this.setState({
            channels: listChannels,
            channel: null,
            show_modal: true,
            step: 1,
          });
          onChangeLoading('');
        }
      } catch (error) {
        console.log('Error getData: ', error);
        onChangeLoading('');
        this.showError(
          text_error_social ||
            'Hubo un error al traer los datos, intentalo más tarde.'
        );
      }
    };

    if (window.gapi.client.getToken() === null) {
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      tokenClient.requestAccessToken({ prompt: '' });
    }
  };

  onCloseModal = () => {
    this.setState({
      show_modal: false,
      step: 0,
      account: null,
    });
  };

  onSelectChannel = (channel) => {
    this.setState({ channel });
  };

  getChannelAnalytics = async () => {
    const { channel } = this.state;
    const {
      siteReducer: {
        site_texts: {
          text_loading_social_contents,
          text_social_save,
          text_social_success,
          text_error_social,
          text_congratulations,
        },
      },
      onChangeLoading,
      changeUserSocialNetworks,
      id,
    } = this.props;

    this.onCloseModal();
    onChangeLoading(text_loading_social_contents || 'Trayendo los datos...');
    try {
      let endDate = new Date();
      let startDate = new Date();
      startDate = startDate.setDate(startDate.getDate() - 30);
      startDate = new Date(startDate);
      endDate = getDateFormat(endDate);
      startDate = getDateFormat(startDate);

      const videos = await window.gapi.client.youtube.search
        .list({
          part: ['snippet,id'],
          channelId: channel.id,
          maxResults: 15,
          type: ['video'],
        })
        .then((res) => res);

      const analytics = await window.gapi.client.youtubeAnalytics.reports
        .query({
          ids: `channel==${channel.id}`,
          startDate,
          endDate,
          metrics:
            'views,estimatedMinutesWatched,averageViewDuration,averageViewPercentage,subscribersGained,comments,likes,dislikes,shares,subscribersGained,subscribersLost,videosAddedToPlaylists,videosRemovedFromPlaylists',
          dimensions: 'day',
          sort: 'day',
        })
        .then((res) => res);

      const data = {
        social_network_id: id,
        profile: channel,
        contents: videos,
        statistics: channel.statistics,
        statistics_lifetime: analytics.result,
      };

      onChangeLoading(text_social_save || 'Guardando información...');

      const satusSave = await Api.asyncCallMethod('v1/youtube', 'POST', data);
      Swal.fire({
        title: text_congratulations || 'Felicitaciones!',
        text: text_social_success || 'Tu cuenta ha sido agregada con éxito',
        type: 'success',
        confirmButtonColor: '#50318B',
      }).then(() => {
        onChangeLoading('');
        changeUserSocialNetworks(satusSave.response.data);
      });
    } catch (error) {
      console.log('Error getChannelAnalytics: ', error);
      this.showError(
        text_error_social ||
          'Hubo un error al traer los datos, intentalo más tarde.'
      );
    }
  };

  render() {
    const { show_modal, step, channels, channel } = this.state;
    const {
      siteReducer: {
        site_texts: { text_youtube_select_channels, text_youtube_channel_text },
      },
      type,
      name,
      icon,
      isAdded,
    } = this.props;

    if (type === 'register') {
      return (
        <>
          {show_modal && (
            <ModalSocial>
              {step === 1 && (
                <SelectOption
                  options={channels}
                  itemSelected={channel}
                  onSelect={this.onSelectChannel}
                  onContinue={this.getChannelAnalytics}
                  onCloseModal={this.onCloseModal}
                  title={text_youtube_select_channels || 'Selecciona un canal'}
                  text={text_youtube_channel_text}
                  selectName="title"
                />
              )}
            </ModalSocial>
          )}
          <button
            className="socialNetworks_social-button youtube"
            onClick={this.getData}
          >
            <div className="socialNetworks_social">
              <div className="socialNetworks_social-icon">
                <i className={icon} />
              </div>
              {name}
            </div>
            {isAdded && <i className="fa-solid fa-check" />}
          </button>
        </>
      );
    }
    return (
      <div className="socialNetworks_social-button youtube">
        <div className="socialNetworks_social">
          <div className="socialNetworks_social-icon">
            <i className={icon} />
          </div>
          {name}
        </div>
        {isAdded && <i className="fa-solid fa-check" />}
      </div>
    );
  }
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

const mapDispatchToProps = {
  changeUserSocialNetworks,
};

export default connect(mapStateToProps, mapDispatchToProps)(YouTubeButton);
