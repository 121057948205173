import React from 'react';
import { formatNumber } from '../../../utils/formatNumber';
import './Analytic.css';
const Analityc = ({ icon, title, value }) => {
  return (
    <div className="analityc__content">
      <div className="analityc__data__container">
        <div className="analityc__data__title">
          <div className="data__icon">
            <i className={icon}></i>
          </div>
          <h3>{title}</h3>
        </div>
        <div className="analityc__data">
          <span>{formatNumber(value)}</span>
        </div>
      </div>
    </div>
  );
};

export default Analityc;
