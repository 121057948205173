import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import TopSpinner from "../../../../components/all/TopSpinner";
import ImgDefault from "../../../../images/believe.jpg";
import "./css/UserView.css";
import ProfileHeader from "../../../../components/all/ProfileHeader";
import ProductList from "../../../../components/user/marketplace/ProductList";
import Pagination from "../../../../components/all/Pagination";
import Error from "../../../../components/all/Error";
import Coverpage from "../../../../images/coverpage.jpg";
import Comments from "../../../../components/all/Comments";

import * as companyActions from "../../../../functionality/actions/companyActions";
import ViewModal from "../../../../components/all/ViewModal";

class UserView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cod_user: null,
      view_products: true,
      view_info: false,
      view_comments: false,
    };
  }

  componentDidMount() {
    const { companyDetail, username } = this.props;
    if (username) {
      companyDetail(username);
    }
  }

  changePage = (current_page, initView, endView) => {
    const {
      companyChangePage,
      companyReducer: { company_list_products },
    } = this.props;
    let list_view = company_list_products.slice(initView, endView);
    let data = {
      list_view,
      current_page,
    };
    companyChangePage(data);
  };

  renderImageProfile = (company) => {
    const {
      siteReducer: { site_language },
    } = this.props;

    let image = company.image ? company.image : ImgDefault;
    const activity =
      site_language === "es" ? company.activity : company.english_activity;

    return (
      <div className="userView_box-image">
        <div className="userView_image-profile">
          <img src={image} alt="Logo" />
        </div>
        <div className="userView_name-company">
          {company.business_name && (
            <div className="userView_name">
              {company.business_name.toUpperCase()}
            </div>
          )}
          <div className="userView_activity">{activity}</div>
          <div className="userView_social-mobile">
            {this.renderSocial(company)}
          </div>
        </div>
      </div>
    );
  };

  renderInfo = (company) => {
    const {
      siteReducer: {
        site_texts: { text_product_description, text_see_comments },
      },
    } = this.props;

    return (
      <Fragment>
        <div className="userView_box-info">
          <div className="userView_row-info">
            <div className="userView_icon">
              <span className="icon-address" />
            </div>
            <div className="userView_info">{company.address}</div>
          </div>
          {company.address_addon && company.address_addon !== "" && (
            <div className="userView_row-info">
              <div className="userView_icon"></div>
              <div className="userView_info">{company.address_addon}</div>
            </div>
          )}
          <div className="userView_row-info">
            <div className="userView_icon">
              <span className="icon-phone" />
            </div>
            <div className="userView_info">{company.phone}</div>
          </div>
          {company.website && company.website !== "" && (
            <div className="userView_row-info">
              <div className="userView_icon">
                <span className="icon-web" />
              </div>
              <div className="userView_info">
                <a
                  href={company.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {company.website}
                </a>
              </div>
            </div>
          )}
          {company.timetable && company.timetable !== "" && (
            <div className="userView_row-info">
              <div className="userView_icon">
                <span className="icon-clock" />
              </div>
              <div className="userView_info">{company.timetable}</div>
            </div>
          )}
          <h5>
            {text_product_description
              ? text_product_description
              : "Descripción"}
          </h5>
          <div className="userView_description">{company.description}</div>
        </div>
        {this.state.view_info && (
          <button
            className="btn btn-block btn-pymex"
            onClick={() => this.setState({ view_comments: true })}
          >
            {text_see_comments
              ? text_see_comments.toUpperCase()
              : "VER COMENTARIOS"}
          </button>
        )}
      </Fragment>
    );
  };

  renderSocial = (company) => {
    return (
      <div className="userView_social">
        {company.facebook && company.facebook !== "" && (
          <a
            href={`https://www.facebook.com/${company.facebook}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-facebook" />
          </a>
        )}
        {company.twitter && company.twitter !== "" && (
          <a
            href={`https://twitter.com/${company.twitter}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-twitter" />
          </a>
        )}
        {company.linkedin && company.linkedin !== "" && (
          <a
            href={`https://www.linkedin.com/in/${company.linkedin}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-linkedin" />
          </a>
        )}
        {company.instagram && company.instagram !== "" && (
          <a
            href={`https://www.instagram.com/${company.instagram}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fa fa-instagram" />
          </a>
        )}
      </div>
    );
  };

  render() {
    const {
      companyReducer: {
        company_loading,
        company_products_loading,
        company_data,
        company_error,
        company_list_products,
        company_list_view_products,
        company_item_per_page,
        company_current_page,
        company_comments,
      },
      siteReducer: {
        site_texts: {
          text_comments,
          text_products,
          text_see_comments,
          text_close,
          text_currently_reviews,
          text_go_companies,
          text_not_products_services_available,
          text_information,
        },
      },
    } = this.props;

    let status_products = this.state.view_products ? "visible" : "no-visible";
    let status_info = this.state.view_info ? "visible" : "no-visible";

    return (
      <Fragment>
        <ViewModal is_visible={this.state.view_comments}>
          <div className="paymentModal_title">
            {text_comments ? text_comments.toUpperCase() : "COMENTARIOS"}
          </div>
          {company_comments.length === 0 ? (
            <Error
              type="no-data"
              title={
                text_currently_reviews
                  ? text_currently_reviews
                  : "En este momento no hay reseñas."
              }
            />
          ) : (
            <div className="userView_comments">
              <Comments data={company_comments} />
            </div>
          )}
          <div className="viewModal_menu">
            <button
              className="btn btn-pymex-2"
              onClick={() => this.setState({ view_comments: false })}
            >
              {text_close ? text_close : "Cerrar"}
            </button>
          </div>
        </ViewModal>
        {!company_data && company_error && (
          <Error
            type="404"
            buttonName={text_go_companies ? text_go_companies : "Ir a Empresas"}
            link="/companies"
          />
        )}
        {company_loading && !company_error && <TopSpinner />}
        {company_data && (
          <div className="container">
            <div className="row">
              <div className="col-lg-3 userView_profile-desktop">
                {this.renderImageProfile(company_data)}
                {this.renderInfo(company_data)}
                {this.renderSocial(company_data)}
                <button
                  className="btn btn-block btn-pymex"
                  onClick={() => this.setState({ view_comments: true })}
                >
                  {text_see_comments
                    ? text_see_comments.toUpperCase()
                    : "VER COMENTARIOS"}
                </button>
              </div>
              <div className="col-lg-9">
                <div className="userView_box-container">
                  {company_data.coverpage ? (
                    <img
                      src={company_data.coverpage}
                      alt="Coverpage"
                      className="userView_coverpage"
                    />
                  ) : (
                    <img
                      src={Coverpage}
                      alt="Coverpage"
                      className="userView_coverpage"
                    />
                  )}
                  <div className="userView_profile-mobile">
                    {this.renderImageProfile(company_data)}
                  </div>
                  {company_products_loading && !company_error && <TopSpinner />}
                  {!company_products_loading && company_error && (
                    <Error
                      type="error"
                      buttonName={
                        text_go_companies ? text_go_companies : "Ir a Empresas"
                      }
                      link="/companies"
                    />
                  )}
                  {!company_products_loading && !company_error && (
                    <Fragment>
                      <ProfileHeader
                        rating={company_data.rating}
                        sales={company_data.total_sales}
                        shopping={company_data.total_shopping}
                      />
                      <div className="userView_profile-mobile">
                        <div className="userView_menu-mobile">
                          <button
                            onClick={() => {
                              this.setState({
                                view_products: true,
                                view_info: false,
                              });
                            }}
                            className={status_products}
                          >
                            {text_products ? text_products : "Productos"}
                          </button>
                          <button
                            onClick={() => {
                              this.setState({
                                view_products: false,
                                view_info: true,
                              });
                            }}
                            className={status_info}
                          >
                            {text_information
                              ? text_information
                              : "Información"}
                          </button>
                        </div>
                        <div className={`userView_info ${status_info}`}>
                          {this.renderInfo(company_data)}
                        </div>
                      </div>
                      <div
                        className={`userView_product-mobile ${status_products}`}
                      >
                        {!company_list_view_products.length ? (
                          <Error
                            type="no-data"
                            title={
                              text_not_products_services_available
                                ? text_not_products_services_available
                                : "No hay productos o servicios disponibles."
                            }
                            buttonName={
                              text_go_companies
                                ? text_go_companies
                                : "Ir a Empresas"
                            }
                            link="/companies"
                          />
                        ) : (
                          <Fragment>
                            <ProductList
                              type="products"
                              data={company_list_view_products}
                            />
                            <Pagination
                              data={company_list_products}
                              itemsPerPage={company_item_per_page}
                              currentPage={company_current_page}
                              changePage={this.changePage}
                            />
                          </Fragment>
                        )}
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer, companyReducer, siteReducer }) => {
  return {
    userReducer,
    companyReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, companyActions)(UserView);
