import React from 'react';
import { connect } from 'react-redux';
import { changeLoading } from '../../../functionality/actions/userActions';
import { formatNumber } from '../../../utils/formatNumber';
import YouTubeButton from '../../AddSocialNetworks/YouTubeButton';
import FacebookButton from '../../AddSocialNetworks/FacebookButton';
import InstagramButton from '../../AddSocialNetworks/InstagramButton';
import TikTokButton from '../../AddSocialNetworks/TikTokButton';
import TwitterButton from '../../AddSocialNetworks/TwitterButton';

function Header(props) {
  const {
    id,
    icon,
    cover,
    slug,
    image,
    username,
    followers,
    fans,
    updateButton,
    changeLoading,
    siteReducer: {
      site_texts: { text_updated },
    },
    userReducer: { user_data },
  } = props;

  if (!user_data) return null;

  const socialColors = {
    twitter: '#1DA1F2',
    instagram: '#E1306C',
    facebook: '#4267B2',
    youtube: '#FF0000',
    tiktok: '#000000',
    linkedin: '#0077B5',
  };

  const renderUpdateButton = () => {
    let button = null;
    switch (slug) {
      case 'facebook':
        button = (
          <FacebookButton
            type="register"
            id={id}
            name={text_updated || 'Actualizar datos'}
            icon={icon}
            onChangeLoading={changeLoading}
          />
        );
        break;
      case 'instagram':
        button = (
          <InstagramButton
            type="register"
            id={id}
            name={text_updated || 'Actualizar datos'}
            icon={icon}
            onChangeLoading={changeLoading}
          />
        );
        break;
      case 'tiktok':
        button = (
          <TikTokButton
            type="register"
            id={id}
            name={text_updated || 'Actualizar datos'}
            icon={icon}
            onChangeLoading={changeLoading}
            redirectUrl={`${process.env.REACT_APP_SITE_ROUTE}manager/profile`}
          />
        );
        break;
      case 'twitter':
        button = (
          <TwitterButton
            type="register"
            id={id}
            name={text_updated || 'Actualizar datos'}
            icon={icon}
            onChangeLoading={changeLoading}
          />
        );
        break;
      case 'youtube':
        button = (
          <YouTubeButton
            type="register"
            id={id}
            name={text_updated || 'Actualizar datos'}
            icon={icon}
            onChangeLoading={changeLoading}
          />
        );
        break;
    }

    if (!button) return null;

    return <div className="profile__update-button">{button}</div>;
  };

  return (
    <div className="profile__header">
      <div
        className={`profile__banner ${cover ? 'cover' : 'no-cover'}`}
        style={{
          backgroundImage: cover ? `url(${cover})` : 'none',
          backgroundColor: socialColors[slug],
        }}
      >
        {updateButton && renderUpdateButton()}
      </div>
      <div className="profile__info">
        <div
          className="profile__image"
          style={{ backgroundColor: socialColors[slug] }}
        >
          <img src={image} alt="profile" />
        </div>
        <div className="profile__text">
          {username && <h3 className="profile__username">{`@${username}`}</h3>}
          <div className="profile__stats">
            {followers && (
              <div className="profile__stat">
                {formatNumber(followers)} followers
              </div>
            )}
            {fans && slug === 'facebook' && (
              <div className="profile__stat">
                {formatNumber(fans)} following
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  changeLoading,
};

const mapStateToProps = ({ siteReducer, userReducer }) => {
  return {
    siteReducer,
    userReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
