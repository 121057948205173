import { SITE_LANGUAGE, SITE_TEXTS, SITE_LOADING } from '../types/siteTypes';

const INITIAL_STATE = {
  site_language: 'es',
  site_texts: {
    text_user: null,
    text_password: null,
    text_password_repeat: null,
    text_password_recovery: null,
    text_create_account: null,
    text_login: null,
    text_products: null,
    text_services: null,
    text_companies: null,
    text_search: null,
    text_categories: null,
    text_featured_companies: null,
    text_settings: null,
    text_menu: null,
    text_about_us: null,
    text_faq: null,
    text_privacy_policy: null,
    text_video: null,
    text_links: null,
    text_sales: null,
    text_purchases: null,
    text_rating: null,
    text_my_account: null,
    text_cart: null,
    text_my_profile: null,
    text_my_orders: null,
    text_lets_talk: null,
    text_sign_up: null,
    text_from_date: null,
    text_to: null,
    text_subject: null,
    text_message: null,
    text_suscribe: null,
    text_all: null,
    text_new: null,
    text_processing: null,
    text_delivered: null,
    text_rejected: null,
    text_finished: null,
    text_my_first_product: null,
    text_order_number: null,
    text_date: null,
    text_type_of_operation: null,
    text_start_purchase: null,
    text_start_sale: null,
    text_pay: null,
    text_transfer: null,
    text_concept: null,
    text_price: null,
    text_type: null,
    text_my_balance: null,
    text_filter: null,
    text_remove_filter: null,
    text_save: null,
    text_setting: null,
    text_update_password: null,
    text_current_password: null,
    text_new_password: null,
    text_change_password: null,
    text_registration_data: null,
    text_legal_representative_data: null,
    text_representative: null,
    text_name: null,
    text_surnames: null,
    text_doc_number: null,
    text_document_issue_date: null,
    text_email: null,
    text_position_representative: null,
    text_source_of_funds_statement: null,
    text_source_funds_1: null,
    text_source_funds_2: null,
    text_source_funds_3: null,
    text_source_funds_4: null,
    text_source_funds_5: null,
    text_company_information: null,
    text_tradename: null,
    text_remember: null,
    text_business_name: null,
    text_phone_number: null,
    text_website: null,
    text_trade_activity: null,
    text_monthly_sales: null,
    text_describe_business: null,
    text_social_media: null,
    text_location_company: null,
    text_more_about_company: null,
    text_state: null,
    text_city: null,
    text_address: null,
    text_complement: null,
    text_validate_company: null,
    text_legal_representative: null,
    text_front_face: null,
    text_upper_side: null,
    text_chamber_commerce: null,
    text_last_30_days: null,
    text_rut: null,
    text_see_document: null,
    text_request_approval: null,
    text_payment_methods: null,
    text_commissions: null,
    text_paypal: null,
    text_credit_card: null,
    text_charge_balance: null,
    text_show_history: null,
    text_log_out: null,
    text_regiter: null,
    text_names: null,
    text_doc_type: null,
    text_doc_numer_register: null,
    text_accept_terms: null,
    text_terms_conditions: null,
    text_accept_policies: null,
    text_policies: null,
    text_cc: null,
    text_ce: null,
    text_pnn: null,
    text_ssn: null,
    text_lic: null,
    text_select: null,
    text_form_register: null,
    text_form_login: null,
    text_recover: null,
    text_fields_required: null,
    text_email_written: null,
    text_agree_terms: null,
    text_agree_policies: null,
    text_registering: null,
    text_logging: null,
    text_write_email: null,
    text_recovering: null,
    text_issuance_document: null,
    text_issuance_document_error: null,
    text_write_profession: null,
    text_sorry: null,
    text_send_password: null,
    text_error_password: null,
    text_congratulations: null,
    text_commercial_business: null,
    text_commercial_name: null,
    text_name_business: null,
    text_nit_number: null,
    text_business_number: null,
    text_average_sales: null,
    text_description_business: null,
    text_nit_rut: null,
    text_username: null,
    text_user_valid: null,
    text_username_not_available: null,
    text_username_characters: null,
    text_username_error: null,
    text_changing_password: null,
    text_blanks_characters: null,
    text_department_city: null,
    text_write_address: null,
    text_tower: null,
    text_form_validate: null,
    text_already_request: null,
    text_uploading: null,
    text_documents_required: null,
    text_identification_document: null,
    text_legal_representative_name: null,
    text_show: null,
    text_upload_front_face: null,
    text_upload_back_face: null,
    text_upload_pdf: null,
    text_success_text: null,
    text_requesting_activation: null,
    text_hi: null,
    text_hi_profile: null,
    text_average: null,
    text_messages: null,
    text_all_message: null,
    text_not_read: null,
    text_new_message: null,
    text_select_from: null,
    text_write_subject: null,
    text_write_message: null,
    text_send_message: null,
    text_sending_message: null,
    text_orders: null,
    text_my_products: null,
    text_how_search: null,
    text_by_name: null,
    text_qualification: null,
    text_state_capitalize: null,
    text_city_capitalize: null,
    text_category: null,
    text_close: null,
    text_go_search: null,
    text_search_results: null,
    text_sorry_search: null,
    text_go_home: null,
    text_new_products: null,
    text_activity: null,
    text_no_products: null,
    text_new_product: null,
    text_import_products: null,
    text_no_services: null,
    text_no_companies: null,
    text_search_error: null,
    text_filter_price: null,
    text_apply_filter: null,
    text_keep_buying: null,
    text_shopping_cart: null,
    text_not_page: null,
    text_was_error: null,
    text_no_items: null,
    text_error_purchase: null,
    text_product_or_service: null,
    text_unit_price: null,
    text_amount: null,
    text_cart_price: null,
    text_create_order: null,
    text_error_cart: null,
    text_edit_2_product: null,
    text_short_description: null,
    text_product_description: null,
    text_product_price: null,
    text_promotional_price: null,
    text_promotion_end_date: null,
    text_stocktaking: null,
    text_product_change_status: null,
    text_characters_remaining: null,
    text_product_name: null,
    text_product_short_description: null,
    text_product_price_error: null,
    text_product_price_minimum: null,
    text_product_upload: null,
    text_product_category: null,
    text_product_type: null,
    text_product_sorry: null,
    text_status: null,
    text_product_images: null,
    text_uploading_image: null,
    text_price_error: null,
    text_short_description_error: null,
    text_price_promo_error: null,
    text_product_price_promo_minimum: null,
    text_product_price_promo_error: null,
    text_product_saving: null,
    text_hidden: null,
    text_public: null,
    text_follow_us_on: null,
    text_directory: null,
    text_transactions_made: null,
    text_commission_history: null,
    text_saving_card: null,
    text_card_saved: null,
    text_card_number: null,
    text_date_mm_yy: null,
    text_number_installments: null,
    text_cardholder: null,
    text_following_information: null,
    text_write_card_number: null,
    text_select_expiration_month: null,
    text_select_expiration_year: null,
    text_add_security_code_card: null,
    text_select_number_installments: null,
    text_write_name_cardholder: null,
    text_assign_balance: null,
    text_payment_method: null,
    text_value_assign_remember: null,
    text_write_value_dots_commas: null,
    text_bank: null,
    text_write_name_surname: null,
    text_select_type_document: null,
    text_write_document_number: null,
    text_select_payment_method: null,
    text_enter_value: null,
    text_generating_receipt: null,
    text_receipt_successfully: null,
    text_select_bank: null,
    text_processing_pse: null,
    text_cash: null,
    text_pse: null,
    text_add_card: null,
    text_edit_2_card: null,
    text_select_nit: null,
    text_year: null,
    text_mounth: null,
    text_default: null,
    text_yes: null,
    text_no: null,
    text_minimum_value: null,
    text_recharge: null,
    text_pse_no_charge: null,
    text_sorry_large: null,
    text_finish_purchase: null,
    the_payment_will_be_made: null,
    text_processing_purchase: null,
    text_transaction: null,
    text_total: null,
    text_actions: null,
    text_no_order: null,
    text_seller: null,
    text_buyer: null,
    text_product: null,
    text_order_to_approve: null,
    text_accept: null,
    text_refuse: null,
    text_are_you_sure: null,
    text_accept_the_order: null,
    text_reject_the_order: null,
    text_continue: null,
    text_changing_order_status: null,
    text_sorry_space_accept_bax: null,
    text_sending_report: null,
    text_accepting_order: null,
    text_change_status: null,
    text_change_of_states: null,
    text_select_state: null,
    text_deliver: null,
    text_report_non_conformity: null,
    text_accept_satisfaction: null,
    text_rate_product: null,
    text_comment: null,
    text_next: null,
    text_qualify_seller: null,
    text_order_completed: null,
    text_commission_costs: null,
    text_commission: null,
    text_iva: null,
    text_rejected_single: null,
    text_order_delivered: null,
    text_order_accepted: null,
    text_order_rejected: null,
    text_you_must_select_state: null,
    text_handling_order_no: null,
    text_payment: null,
    text_definition: null,
    text_home_purchase: null,
    text_start_process_purchase: null,
    text_home_sale: null,
    text_request_product_service: null,
    text_purchase_completed_deducted_bax: null,
    text_bax_receive_accepted_sale: null,
    text_cancel_order: null,
    text_bill: null,
    text_cannot_products: null,
    text_enough_bax: null,
    text_sorry_payment: null,
    text_order_detail: null,
    text_sorry_order: null,
    text_changing_status: null,
    text_send: null,
    text_select_value: null,
    text_new_circle: null,
    text_confirm_new_password: null,
    text_enter_current_password: null,
    text_enter_new_password: null,
    text_confirm_new_password_error: null,
    text_passwords_not_match: null,
    text_upload_logo_profile_image: null,
    text_upload_cover: null,
    text_profile: null,
    text_address_complement: null,
    text_phone: null,
    text_web_page: null,
    text_currently_reviews: null,
    text_go_companies: null,
    text_comments: null,
    text_information: null,
    text_not_products_services_available: null,
    text_see_comments: null,
    text_product_not_available: null,
    text_availability: null,
    text_in_stock: null,
    text_add_to_cart: null,
    text_product_information: null,
    text_reviews: null,
    text_sold_by: null,
    text_related_products: null,
    text_intro: null,
    text_number_ccount: null,
    text_my_favorite_products: null,
    text_favorite_products_error: null,
    text_my_favorite_companies: null,
    text_favorite_companies_error: null,
    text_title_offer: null,
    text_view_all: null,
    text_remove_card: null,
    text_yes_deleted: null,
    text_verified: null,
    text_verified_button: null,
    text_sending_email: null,
    text_message_email: null,
    text_validation_error: null,
    text_validation: null,
    text_recommended_company: null,
    text_company_description: null,
    text_company_name: null,
    text_company_address: null,
    text_company_website: null,
    text_company_contact_name: null,
    text_company_contact_phone: null,
    text_company_contact_email: null,
    text_company_name_error: null,
    text_share_on: null,
    text_products_loading: null,
    text_product_import_file: null,
    text_products_error: null,
    text_products_upload: null,
    text_products_upload_error: null,
    text_referrals: null,
    text_referred_description: null,
    text_referred_1: null,
    text_referred_2: null,
    text_referred_3: null,
    text_referred_copy: null,
    text_referred_copied: null,
    text_my_referrals: null,
    text_my_referrals_description: null,
    text_referred_title: null,
    text_referred_share: null,
    text_type_person: null,
    text_natural_person: null,
    text_legal_person: null,
    text_type_person_error: null,
    text_type_person_chamber_commerce: null,
    text_validation_congratulations: null,
    text_risk_centers: null,
    text_risk_centers_error: null,
    text_valid_information: null,
    text_valid_information_error: null,
    text_advertisement: null,
    text_questions: null,
    text_questions_time: null,
    text_questions_1: null,
    text_questions_1_suggestion: null,
    text_questions_2: null,
    text_questions_3: null,
    text_questions_4: null,
    text_questions_5: null,
    text_questions_6: null,
    text_questions_7: null,
    text_questions_8: null,
    text_questions_9: null,
    text_questions_10: null,
    text_questions_11: null,
    text_questions_12: null,
    text_questions_13: null,
    text_questions_14: null,
    text_questions_15: null,
    text_questions_16: null,
    text_questions_17: null,
    text_questions_18: null,
    text_questions_19: null,
    text_questions_20: null,
    text_questions_21: null,
    text_questions_22: null,
    text_questions_save: null,
    text_questions_skip: null,
    text_questions_start: null,
    text_my_brands: null,
    text_my_campaigns: null,
    text_campaign_brief: null,
    text_brand: null,
    text_phrase: null,
    text_suggested_images: null,
    text_campaign_name: null,
    text_campaign_dates: null,
    text_networks: null,
    text_location: null,
    text_objectives: null,
    text_target_gender: null,
    text_deliverables: null,
    // agregados por diego
    text_campaign_start: null,
    text_end_of_campaign: null,
    text_proposed_name: null,
    text_give_name_proposal: null,
    text_campaign_networks: null,
    text_cost_in_bax: null,
    text_save_changes: null,
    text_publish: null,
    text_campaign: null,
    text_members: null,
    text_not_interested: null,
    text_interested: null,
    text_save_brief: null,
    text_publish_brief: null,
    text_select_brand: null,
    text_no_brands_available: null,
    text_describe_campaign: null,
    text_campaign_phrase: null,
    text_campaign_destination_link: null,
    text_campaign_hashtags: null,
    text_target_brand: null,
    text_gender: null,
    text_age_range: null,
    text_target_description: null,
    text_where_target_campaign: null,
    text_country: null,
    text_or: null,
    text_return: null,
    text_campaign_objectives: null,
    text_gender_influencers: null,
    text_average_rating: null,
    text_average_likes: null,
    text_see_detail: null,
    text_updated_on: null,
    text_view_profile: null,
    text_cost: null,
    text_see_post_here: null,
    text_consecutive: null,
    text_social_networks: null,
    text_type_post: null,
    text_created_by: null,
    text_recent_posts: null,
    text_upload_images: null,
    text_upload_images_caption: null,
    text_upload_video: null,
    text_upload_video_caption: null,
    text_accepted: null,
    text_start_date: null,
    text_finish_date: null,
    text_not_sent_approval: null,
    text_status_approval: null,
    text_delete: null,
    text_edit_2: null,
    text_start_campaign: null,
    text_no_have_campaigns: null,
    text_write_name_campaign: null,
    text_send_campaign_validate: null,
    text_back: null,
    text_image_desktop: null,
    text_image_mobile: null,
    text_upload_image_desktop: null,
    text_upload_image_mobile: null,
    text_last_orders: null,
    text_quantity: null,
    text_alls: null,
    text_news: null,
    text_approved: null,
    text_report_nonconformity: null,
    text_warning_date_publication: null,
    text_success_congratulations: null,
    text_success_campaign_saved: null,
    text_question_delete_campaign: null,
    text_success_deleted_campaign: null,
    text_warning_choose_location: null,
    text_success_accepted_campaign: null,
    text_success_rejected_campaign: null,
    text_success_done: null,
    text_success_deleted_photo: null,
    text_success_deleted_video: null,
    text_warning_not_enough_bax: null,
    text_error_change_campaign_status: null,
    text_warning_send_media: null,
    text_warning_send_post_description: null,
    text_warning_choose_quotation: null,
    text_success_post_sent: null,
    text_success_requested_correction: null,
    text_success_post_accepted: null,
    text_success_post_publish: null,
    text_success_post_rejected: null,
    text_succes_post_deleted: null,
    text_question_reason_not_register: null,
    text_success_see_you_later: null,
    text_thank_you: null,
    text_error_bring_activities: null,
    text_updating_profile: null,
    text_search_influencer: null,
    text_finish_campaign: null,
    text_inbox: null,
    text_resume: null,
    text_brief: null,
    text_my_proposals: null,
    text_influencers: null,
    text_posts: null,
    text_create_propose_caption: null,
    text_create_propose: null,
    text_inbox_campaign: null,
    text_proposals: null,
    text_accept_proposal: null,
    text_discard: null,
    text_not_reverse_action: null,
    text_see_post: null,
    text_request_correction: null,
    text_reject: null,
    text_add_post_link: null,
    text_warning_enter_link: null,
    text_warning_message_here: null,
    text_add_post_to_campaign: null,
    text_quote: null,
    text_type_of_post: null,
    text_post_networks: null,
    text_photo: null,
    text_post_name: null,
    text_select_quotation: null,
    text_select_post_type: null,
    text_give_name_post: null,
    text_no_post_available: null,
    text_no_quotation_available: null,
    text_description_post: null,
    text_publish_link: null,
    text_post_campaign: null,
    text_sent_to: null,
    text_in_correction: null,
    text_published: null,
    text_draft: null,
    text_create_post: null,
    text_brands: null,
    text_about_me: null,
    text_advertising_campaign: null,
    text_advertising_campaigns: null,
    text_view_proposal: null,
    text_view_proposal_detail: null,
    text_view_proposal_post: null,
    text_submit_proposal: null,
    text_confirm_campaign: null,
    text_demiss_campaign: null,
    text_publish_campaign: null,
    text_cancel: null,
    text_error_enter_date_after: null,
    text_error_no_influencers: null,
    text_error_no_posts: null,
    text_view: null,
    text_publish_proposal: null,
    text_pending: null,
    text_declined: null,
    text_warning_reference_image: null,
    text_campaign_details: null,
    text_topics_and_subjects: null,
    text_influencers_profile: null,
    text_cities: null,
    text_cancel_proposal: null,
    text_warning_only_video_file: null,
    text_demographics: null,
    text_average_influenx_rating: null,
    text_age: null,
    text_user_engagement: null,
    text_clicks_on_content: null,
    text_clicks_content_per_user: null,
    text_clicks_from_mobile: null,
    text_reach: null,
    text_prints: null,
    text_views: null,
    text_estimated_minutes_viewed: null,
    text_network_comments: null,
    text_shared: null,
    text_new_subscribers: null,
    text_subscribers_removed: null,
    text_videos_added_to_playlist: null,
    text_videos_removed_from_playlist: null,
    text_warning_add_payment_1: null,
    text_add_payment_here: null,
    text_filters: null,
    text_select_campaign: null,
    text_warning_choose_campaign: null,
    text_finalize_proposal: null,
    text_success_proposal_finalized: null,
    text_warning_only_published: null,
    text_request_sent_to: null,
    text_rejected_2: null,
    text_add_social_network_2: null,
    text_accepted_influencers: null,
    text_total_influencers: null,
    text_pending_influencers: null,
    text_rejected_influencers: null,
    text_accepted_proposals: null,
    text_proposals_pending: null,
    text_rejected_proposals: null,
    text_finalized_proposals: null,
    text_total_proposals: null,
    text_accepted_posts: null,
    text_pending_posts: null,
    text_rejected_posts: null,
    text_published_posts: null,
    text_total_posts: null,
    text_warning_choose_social_network: null,
  },
  site_loading: true,
};

const siteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SITE_LANGUAGE:
      return {
        ...state,
        site_language: action.payload,
      };
    case SITE_TEXTS:
      return {
        ...state,
        site_texts: action.payload,
      };
    case SITE_LOADING:
      return {
        ...state,
        site_loading: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default siteReducer;
