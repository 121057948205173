import React from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function Menu(props) {
  const {
    changeStep,
    step,
    name,
    plan,
    space,
    category,
    resource,
    image,
    mobileImage,
    save,
    siteReducer: {
      site_texts: { text_next, text_save, text_back },
    },
  } = props;

  const showWarning = (message) => {
    MySwal.fire({
      title: 'Upsss!!!',
      text: message,
      type: 'warning',
      confirmButtonColor: '#50318B',
    });
  };

  const next = () => {
    let nextStep = step + 1;

    if (step === 1 && name === '') {
      showWarning('Escribe el nombre de la campaña.');
      return;
    }
    if (step === 2 && plan === '') {
      showWarning('Selecciona un plan.');
      return;
    }
    if (step === 3 && space === '') {
      showWarning('Selecciona el espacio donde vas a publicar.');
      return;
    }
    if (space.id === 2 || space.id === 4 || space.id === 6) {
      nextStep = nextStep;
    } else if (step === 3) {
      if (space.id === 5 || space.id === 8) {
        nextStep = 6;
      } else {
        nextStep = 5;
      }
    }
    if (step === 4 && space.id === 6) {
      nextStep = 6;
    }
    if (step === 4 && category === '') {
      const type = space.id !== 6 ? 'categoría' : 'actividad';
      showWarning(`Selecciona la ${type}.`);
      return;
    }
    if (step === 5 && resource === '') {
      const type = space.id === 1 && space.id === 2 ? 'producto' : 'servicio';
      showWarning(`Selecciona el ${type}.`);
      return;
    }
    if (step === 6 && (image === '' || mobileImage === '')) {
      showWarning(
        `Debes subir las imágenes para desktop y mobile de tu campaña`
      );
      return;
    }

    changeStep(nextStep);
  };

  const prev = () => {
    let prevStep = step - 1;
    if (space.id === 2 || space.id === 4 || space.id === 6) {
      prevStep = prevStep;
    } else if (step === 5 || space.id === 5 || space.id === 8) {
      prevStep = 3;
    }
    if (step === 6 && space.id === 6) {
      prevStep = 4;
    }
    changeStep(prevStep);
  };

  return (
    <div className="form_menu">
      {step > 1 ? (
        <button className="btn btn-bax" onClick={prev}>
          {text_back ? text_back : 'Atrás'}
        </button>
      ) : (
        <div />
      )}
      {step < 7 ? (
        <button className="btn btn-bax" onClick={next}>
          {text_next ? text_next : 'Siguiente'}
        </button>
      ) : (
        <button onClick={save} className="btn btn-bax">
          {text_save ? text_save : 'Guardar'}
        </button>
      )}
    </div>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(Menu);
