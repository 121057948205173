import React from 'react';
import InfluencerRow from '../../../../all/Influencer/InfluencerRow/InfluencerRow';
import './InfluencerTable.css';
import { connect } from 'react-redux';

const InfluencerTable = (props) => {
  const {
    influencers,
    siteReducer: {
      site_texts: {
        text_photo,
        text_name,
        text_city,
        text_categories,
        text_social_networks,
      },
    },
  } = props;

  const renderInfluencers = influencers.map((user, index) => (
    <InfluencerRow key={index} {...user} />
  ));

  return (
    <table className="influencer-table">
      <thead className="influencerTable__head">
        <tr>
          <th className="influencerTable__title">{text_photo || 'Foto'}</th>
          <th className="influencerTable__title">{text_name || 'Nombre'}</th>
          <th className="influencerTable__title">{text_city || 'Ciudad'}</th>
          <th className="influencerTable__title">
            {text_categories || 'Categorías'}
          </th>
          <th className="influencerTable__title">
            {text_social_networks || 'Redes Sociales'}
          </th>
          <th className="influencerTable__title"></th>
        </tr>
      </thead>
      <tbody>{renderInfluencers}</tbody>
    </table>
  );
};

const mapStateToProps = ({ siteReducer }) => {
  return { siteReducer };
};

export default connect(mapStateToProps, null)(InfluencerTable);
