import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './css/ItemShoppingCart.css';
import { isPromo } from '../../../functionality/Global';
import QuantityItemCart from '../../all/QuantityItemCart';

import * as cartActions from '../../../functionality/actions/cartActions';

const MySwal = withReactContent(Swal);

function showError(message) {
  MySwal.fire({
    title: 'Upsss!!!',
    text: message,
    type: 'warning',
    confirmButtonColor: '#50318B',
  });
}

function ItemShoppingCart(props) {
  let {
    data,
    removeCart,
    is_user,
    user_data: { total_products, user_type_id },
    siteReducer: {
      site_texts: { text_create_order, text_error_cart },
    },
  } = props;
  let max = data.sku ? data.sku : 5;
  let total_items = data.total_items ? data.total_items : 1;
  let images = data.images;
  let date_promo = data.promotion_date ? new Date(data.promotion_date) : false;
  let is_promo = false;
  if (date_promo) {
    is_promo = isPromo(date_promo);
  }
  let price =
    data.price_promo !== 0 && is_promo
      ? data.price_promo * total_items
      : data.price * total_items;
  const numberFormat = new Intl.NumberFormat('es-CO');

  return (
    <tr>
      <td className="d-flex align-items-center">
        <button
          className="itemShoppingCart_remove"
          onClick={() => removeCart(data.id)}
        >
          <span className="icon-remove" />
        </button>
        <img
          src={images[0].image}
          alt={data.name}
          className="itemShoppingCart_image"
        />
        <div className="itemShoppingCart_name">{data.name}</div>
      </td>
      <td className="align-middle">
        {data.price_promo !== 0 && is_promo ? (
          <div className="itemShoppingCart_box-price">
            <div className="itemShoppingCart_price text-right">
              BAX$ {numberFormat.format(data.price_promo)}
            </div>
            <div className="itemShoppingCart_discount text-right">
              BAX$ {numberFormat.format(data.price)}
            </div>
          </div>
        ) : (
          <div className="itemShoppingCart_box-price">
            <div className="itemShoppingCart_price text-right">
              BAX$ {numberFormat.format(data.price)}
            </div>
          </div>
        )}
      </td>
      <td className="align-middle">
        <QuantityItemCart
          total_items={total_items}
          max={max}
          min={1}
          cod_product={data.id_product}
        />
      </td>
      <td className="align-middle">BAX$ {numberFormat.format(price)}</td>
      <td className="align-middle">
        {is_user && (total_products > 0 || user_type_id === 8) ? (
          <Link
            to={`/checkout/${data.slug}`}
            className="btn itemShoppingCart_go-order"
          >
            {text_create_order ? text_create_order : 'CREAR ORDEN DE PEDIDO'}
          </Link>
        ) : (
          is_user && (
            <button
              className="btn itemShoppingCart_go-order"
              onClick={() =>
                showError(
                  text_error_cart
                    ? text_error_cart
                    : 'Debes publicar mínimo un producto, para poder proceder a la compra.'
                )
              }
            >
              {text_create_order ? text_create_order : 'CREAR ORDEN DE PEDIDO'}
            </button>
          )
        )}
        {!is_user && (
          <Link to="/login" className="btn itemShoppingCart_go-order">
            {text_create_order ? text_create_order : 'CREAR ORDEN DE PEDIDO'}
          </Link>
        )}
      </td>
    </tr>
  );
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    ...userReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, cartActions)(ItemShoppingCart);
