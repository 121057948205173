import React from "react";
import SlideProduct from "./SlideProduct.js";
import ItemProductInline from "./ItemProductInline.js";
import AdSlider from "./AdSlider.js";

function ProductList(props) {
  const { classColumn, showAds } = props;
  let others = {
    type: "percentage",
  };

  return (
    <>
      {showAds && <AdSlider />}
      <div className="row">
        {props.type === "inline"
          ? props.data.map((item) => {
              return (
                <div className="col-md-12" key={item.id}>
                  <ItemProductInline data={item} others={others} />
                </div>
              );
            })
          : props.data.map((item) => {
              return (
                <div className={classColumn} key={item.id}>
                  <SlideProduct data={item} others={others} />
                </div>
              );
            })}
      </div>
    </>
  );
}

ProductList.defaultProps = {
  classColumn: "col-md-4 col-sm-6",
  showAds: false,
};

export default ProductList;
