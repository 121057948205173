import React from 'react';
import { connect } from 'react-redux';
import { referredChangePage } from '../../../../functionality/actions/referredActions';
import { Link } from 'react-router-dom';
import CopyButton from '../../../../components/all/CopyButton';
import Pagination from '../../../../components/all/Pagination';
import SocialNetwork from '../../../../components/all/SocialNetwork';
import Spinner from '../../../../components/all/Spinner';
import BoxWhite from '../../../../components/user/marketplace/BoxWhite';
import icon1 from '../../../../images/icon-1.svg';
import icon2 from '../../../../images/icon-2.svg';
import icon3 from '../../../../images/icon-3.svg';
import image from '../../../../images/believe.jpg';
import './css/ReferredView.css';

const SITE_ROUTE = process.env.REACT_APP_SITE_ROUTE;

function ReferredView(props) {
  const {
    siteReducer: {
      site_texts: {
        text_referrals,
        text_referred_description,
        text_referred_1,
        text_referred_2,
        text_referred_3,
        text_referred_copy,
        text_referred_copied,
        text_my_referrals,
        text_my_referrals_description,
        text_referred_title,
        text_referred_share,
      },
    },
    userReducer: { user_data },
    referredReducer: {
      referred_loading,
      referred_list,
      referred_item_per_page,
      referred_current_page,
      referred_list_view,
    },
    referredChangePage,
  } = props;

  const route = `${SITE_ROUTE}r/${user_data?.username}`;

  return (
    <>
      <div className="title-manager">
        <div>
          <span className="icon-referrals" />
          {text_referrals ? text_referrals : 'Referidos'}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <BoxWhite marginTop={-20}>
            <h2 className="referredView_description">
              {text_referred_description}
            </h2>
            <div className="row">
              <div className="col-md-4">
                <div className="referredView_item">
                  <img src={icon1} alt="Icon 1" />
                  <div>{text_referred_1}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="referredView_item">
                  <img src={icon2} alt="Icon 2" />
                  <div>{text_referred_2}</div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="referredView_item">
                  <img src={icon3} alt="Icon 3" />
                  <div>{text_referred_3}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="referredView_input-route">
                  <div>{route}</div>
                  <CopyButton
                    text={route}
                    copy={text_referred_copy}
                    copied={text_referred_copied}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <SocialNetwork
                  url={route}
                  title={text_referred_title}
                  description={text_referred_share}
                  hashtags={['influenx']}
                  showTitle={false}
                  className="referredView_social"
                  email={true}
                />
              </div>
            </div>
            <div className="referredView_list-content">
              {referred_loading ? (
                <div className="d-flex justify-content-center">
                  <Spinner type="purple" />
                </div>
              ) : !referred_loading && referred_list.length ? (
                <div className="referredView_list">
                  <h3>{text_my_referrals}</h3>
                  <p>{text_my_referrals_description}</p>
                  <div className="row">
                    {referred_list_view.map((item) => (
                      <div className="col-md-3" key={item.id}>
                        <Link
                          to={`/${item.username ? item.username : item.id}`}
                          className="referredView_referred"
                        >
                          <img
                            src={item.coverpage ? item.coverpage : image}
                            alt={item.business_name}
                          />
                          {item.business_name}
                        </Link>
                      </div>
                    ))}
                  </div>
                  <Pagination
                    data={referred_list}
                    itemsPerPage={referred_item_per_page}
                    currentPage={referred_current_page}
                    changePage={referredChangePage}
                  />
                </div>
              ) : null}
            </div>
          </BoxWhite>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ siteReducer, userReducer, referredReducer }) => {
  return {
    siteReducer,
    userReducer,
    referredReducer,
  };
};

const mapDispatchToProps = {
  referredChangePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferredView);
