import React from "react";
import Slider from "react-slick";
import Logo from "../../../../images/logo.png";
import slide01 from "../../../../images/slider-01.png";
import slide02 from "../../../../images/slider-02.png";
import slide03 from "../../../../images/slider-03.png";
import slide04 from "../../../../images/slider-04.png";
import slide05 from "../../../../images/slider-05.png";
import slide06 from "../../../../images/slider-06.png";
import "./css/FormLayout.css";

function FormLayout(props) {
  const { id } = props;

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="formLayout_container" id={id}>
      <div className="formLayout_column formLayout_right">
        <div className="formLayout_slider">
          <Slider {...settings}>
            <div className="formLayout_slide">
              <h2>Crea fácil y rápido tu perfil</h2>
              <p>Edita cada uno de los aspectos y da a conocer tu negocio</p>
              <img src={slide01} alt="Slide01" />
            </div>
            <div className="formLayout_slide">
              <h2>Lleva un registro de tus ventas y compras</h2>
              <p>
                Fomentamos el intercambio comercial a través del canje
                empresarial
              </p>
              <img src={slide02} alt="Slide02" />
            </div>
            <div className="formLayout_slide">
              <h2>Tu Inbox es fundamental para crear negocios en Bax</h2>
              <p>Envía tus consultas y responde inquietudes </p>
              <img src={slide03} alt="Slide03" />
            </div>
            <div className="formLayout_slide">
              <h2>Encuentra todas tus ordenes</h2>
              <p>Tanto de tus ventas, como de tus compras</p>
              <img src={slide04} alt="Slide04" />
            </div>
            <div className="formLayout_slide">
              <h2>Añade todas tus preferencias al carrito de compra</h2>
              <p>Añade y elimina todos los productos y servicios que desees</p>
              <img src={slide05} alt="Slide05" />
            </div>
            <div className="formLayout_slide">
              <h2>Guarda tus favoritos</h2>
              <p>
                Añade tus favoritos las compañías, productos y servicios que más
                te gusten
              </p>
              <img src={slide06} alt="Slide06" />
            </div>
          </Slider>
        </div>
      </div>
      <div className="formLayout_column formLayout_left">
        <div className="formLayout_form">
          <img src={Logo} alt="BAX" className="formLayout_logo" />
          {props.children}
        </div>
      </div>
    </div>
  );
}

FormLayout.defaultProps = {
  id: "",
};

export default FormLayout;
