import React, { useEffect, useState } from 'react';
import purpleSpinner from '../../images/purple-spinner.svg';
import './SelectSearch.css';

function SelectSearch(props) {
  const [search, setSearch] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [mounted, setMounted] = useState(false);
  const {
    options,
    onChange,
    type,
    optionName,
    loading,
    style,
    secondOptionName,
    label,
    placeholder,
    value,
    showMultiple,
    selectedList,
  } = props;

  useEffect(() => {
    if (options.length) {
      setSearchOptions(options);
    }
    if (loading) {
      onChangeSearch('');
    }

    if (!mounted && value) {
      changeSearchText(value);
      setMounted(true);
    }
  }, [options, loading, mounted, value]);

  const onChangeSearch = (text) => {
    if (text.length > 3) {
      const selectOptions = options.filter(
        (option) =>
          option[optionName].toLowerCase().indexOf(text.toLowerCase()) >= 0
      );

      setSearchOptions(selectOptions);
    } else {
      setSearchOptions(options);
    }
    setSearch(text);
  };

  const changeSearchText = (option) => {
    if (type === 'multiple') {
      onChangeSearch('');
    } else {
      let value = option[optionName];
      if (secondOptionName) {
        value = `${value} (${option[secondOptionName]})`;
      }
      setSearch(value);
    }
  };

  return (
    <>
      {label && <label>{label}</label>}
      {showMultiple && selectedList && (
        <div className="SelectSearch_selected">
          {selectedList.map((item) => (
            <div className="SelectSearch_selected-item" key={item.id}>
              <span>
                {item[optionName]}
                {secondOptionName && <small>({item[secondOptionName]})</small>}
              </span>
              <button onClick={() => onChange(item)}>
                <i className="fa-regular fa-trash-can" />
              </button>
            </div>
          ))}
        </div>
      )}
      <div className={`SelectSearch_container ${style}`}>
        {loading && (
          <div className="SelectSearch_loading">
            <img src={purpleSpinner} alt="loading" />
          </div>
        )}
        <i className="fa-solid fa-chevron-down" />
        <input
          value={search}
          onChange={(input) => onChangeSearch(input.target.value)}
          placeholder={placeholder || ''}
        />
        {searchOptions.length ? (
          <div className="SelectSearch_options">
            {searchOptions.map((option, index) => {
              return (
                <button
                  key={index}
                  onClick={(event) => {
                    event.preventDefault();
                    changeSearchText(option);
                    onChange(option);
                  }}
                >
                  {option[optionName]}
                  {secondOptionName && (
                    <small>({option[secondOptionName]})</small>
                  )}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
    </>
  );
}

SelectSearch.defaultProps = {
  optionName: 'name',
  style: 'line',
  showMultiple: false,
  selectedList: null,
};

export default SelectSearch;
