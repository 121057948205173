import React from 'react';
import './InputGroup.css';

const InputGroup = (props) => {
  const {
    name,
    type,
    label,
    isRequired,
    aditionalClassname,
    value,
    disabled,
    handleInputChange,
    placeHolder,
  } = props;

  return (
    <div
      className={`input__group ${aditionalClassname ? aditionalClassname : ''}`}
    >
      <label htmlFor={`${name}`}>{`${label} ${isRequired ? '*' : ''}`}</label>

      {type === 'number' ? (
        <div className="input__number">
          <span>$</span>
          <input
            type={`${type}`}
            className={`campaign__input`}
            id={`${name}`}
            name={`${name}`}
            required={isRequired}
            disabled={disabled}
            value={value}
            placeholder={placeHolder}
            onChange={(event) => handleInputChange(event.target.value)}
          />
        </div>
      ) : (
        <input
          type={`${type}`}
          className={`campaign__input`}
          id={`${name}`}
          name={`${name}`}
          required={isRequired}
          disabled={disabled}
          value={value}
          placeholder={placeHolder}
          onChange={(event) => handleInputChange(event.target.value)}
        />
      )}
    </div>
  );
};
InputGroup.defaultProps = {
  value: '',
  disabled: false,
};
export default InputGroup;
