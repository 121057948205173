import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './css/PaymentModal.css';

import * as paymentActions from '../../../functionality/actions/paymentActions';

const MySwal = withReactContent(Swal);

const {
  closePaymentModal,
  cashPaymentType,
  cashPaymentValue,
  saveCash,
  changePaymentNumber,
  changePaymentMonth,
  changePaymentYear,
  changePaymentCVC,
  changePaymentDues,
  changePaymentHeadline,
  saveCard,
  changePaymentDefault,
  paymentCardUpdate,
  listBanksPSE,
  chageSelectCash,
  changePSEBank,
  changePaymentName,
  changePaymentLastname,
  changePaymentDocType,
  changePaymentDocNumber,
  savePSE,
} = paymentActions;

class PaymentModal extends Component {
  componentDidMount() {
    const { listBanksPSE } = this.props;
    listBanksPSE();
  }

  showError = (message) => {
    MySwal.fire({
      title: 'Upsss!!!',
      text: message,
      type: 'warning',
      confirmButtonColor: '#50318B',
      // onClose: () => this.props.userClearError(),
    });
  };

  renderMonths = () => {
    let months = [];
    for (let i = 1; i <= 12; i++) {
      let value = i < 10 ? `0${i}` : i;
      months.push(
        <option key={i} value={value}>
          {value}
        </option>
      );
    }
    return months;
  };

  renderYears = () => {
    let currentYear = new Date().getFullYear();
    let years = [];
    for (let i = 0; i < 10; i++) {
      let value = currentYear + i;
      years.push(
        <option key={i} value={value}>
          {value}
        </option>
      );
    }
    return years;
  };

  renderDues = () => {
    let dues = [];
    for (let i = 1; i <= 36; i++) {
      dues.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return dues;
  };

  paymentSave = () => {
    const {
      payment_type,
      payment_cash_type,
      payment_cash_value,
      saveCash,
      payment_number,
      payment_exp_month,
      payment_exp_year,
      payment_cvc,
      payment_dues,
      payment_headline,
      payment_is_default,
      payment_id_card,
      payment_name,
      payment_lastname,
      payment_doc_type,
      payment_doc_number,
      payment_cash_select,
      payment_pse_bank,
      saveCard,
      paymentCardUpdate,
      savePSE,
      siteReducer: {
        site_texts: {
          text_write_name_surname,
          text_select_type_document,
          text_write_document_number,
          text_enter_value,
          text_minimum_value,
          text_select_payment_method,
          text_select_bank,
          text_write_card_number,
          text_select_expiration_month,
          text_select_expiration_year,
          text_add_security_code_card,
          text_select_number_installments,
          text_write_name_cardholder,
          text_recharge,
        },
      },
    } = this.props;
    if (payment_type === 'cash') {
      if (payment_name === '' || payment_lastname === '') {
        this.showError(
          text_write_name_surname
            ? text_write_name_surname
            : 'Escribe el nombre y los apellidos'
        );
        return;
      }
      if (payment_doc_type === '') {
        this.showError(
          text_select_type_document
            ? text_select_type_document
            : 'Seleccionar el tipo de documento'
        );
        return;
      }
      if (payment_doc_number === '') {
        this.showError(
          text_write_document_number
            ? text_write_document_number
            : 'Escribe el número de documento'
        );
        return;
      }
      if (payment_cash_value === '') {
        this.showError(
          text_enter_value ? text_enter_value : 'Debes ingresar un valor.'
        );
        return;
      }
      if (payment_cash_value < 100000) {
        this.showError(
          text_minimum_value
            ? `${text_minimum_value} $100`
            : 'El valor debe ser mínimo de: COP$ 100.000'
        );
        return;
      }
      let data = {
        firstname: payment_name,
        lastname: payment_lastname,
        doc_type: payment_doc_type,
        doc_number: payment_doc_number,
        value: payment_cash_value,
        description: text_recharge ? text_recharge : 'Recarga saldo prepago.',
      };
      if (payment_cash_select === 'cash') {
        if (payment_cash_type === '') {
          this.showError(
            text_select_payment_method
              ? text_select_payment_method
              : 'Debes seleccionar el medio de pago'
          );
          return;
        }
        data.type = payment_cash_type;
        saveCash(data);
      }
      if (payment_cash_select === 'pse') {
        if (parseInt(payment_pse_bank) === 0) {
          this.showError(
            text_select_bank ? text_select_bank : 'Debes seleccionar el banco'
          );
          return;
        }
        data.cod_bank = payment_pse_bank;
        savePSE(data);
      }
    }
    if (payment_type === 'card') {
      if (payment_number === '') {
        this.showError(
          text_write_card_number
            ? text_write_card_number
            : 'Debes escribir número de la terjeta.'
        );
        return;
      }
      if (payment_exp_month === '') {
        this.showError(
          text_select_expiration_month
            ? text_select_expiration_month
            : 'Debes seleccionar el mes de expiración.'
        );
        return;
      }
      if (payment_exp_year === '') {
        this.showError(
          text_select_expiration_year
            ? text_select_expiration_year
            : 'Debes seleccionar el año de expiración.'
        );
        return;
      }
      if (payment_cvc === '') {
        this.showError(
          text_add_security_code_card
            ? text_add_security_code_card
            : 'Debes agregar el código de seguridad de la tarjeta.'
        );
        return;
      }
      if (payment_dues === '') {
        this.showError(
          text_select_number_installments
            ? text_select_number_installments
            : 'Debes seleccionar el número de cuotas por defecto.'
        );
        return;
      }
      if (payment_headline === '') {
        this.showError(
          text_write_name_cardholder
            ? text_write_name_cardholder
            : 'Debes escribir el nombre del títular de la tarjeta.'
        );
        return;
      }
      let data = {
        name: payment_headline,
        card_number: payment_number,
        expiration_month: payment_exp_month,
        expiration_year: payment_exp_year,
        cvc: payment_cvc,
        dues: payment_dues,
      };
      saveCard(data);
    }
    if (payment_type === 'edit-card') {
      if (payment_dues === '') {
        this.showError(
          text_select_number_installments
            ? text_select_number_installments
            : 'Debes seleccionar el número de cuotas por defecto.'
        );
        return;
      }
      let data = {
        id_card: payment_id_card,
        dues: payment_dues,
        is_default: payment_is_default,
      };
      paymentCardUpdate(data);
    }
  };

  render() {
    const {
      payment_modal,
      payment_type,
      closePaymentModal,
      payment_cash_type,
      payment_cash_value,
      payment_loading,
      payment_loading_message,
      cashPaymentType,
      cashPaymentValue,
      payment_number,
      payment_exp_month,
      payment_exp_year,
      payment_cvc,
      payment_dues,
      payment_headline,
      payment_is_default,
      payment_banks_pse,
      payment_cash_select,
      payment_pse_bank,
      payment_banks_error,
      payment_name,
      payment_lastname,
      payment_doc_type,
      payment_doc_number,
      changePaymentNumber,
      changePaymentMonth,
      changePaymentYear,
      changePaymentCVC,
      changePaymentDues,
      changePaymentHeadline,
      changePaymentDefault,
      chageSelectCash,
      changePSEBank,
      changePaymentName,
      changePaymentLastname,
      changePaymentDocType,
      changePaymentDocNumber,
      siteReducer: {
        site_texts: {
          text_assign_balance,
          text_add_card,
          text_edit_2_card,
          text_cash,
          text_pse,
          text_following_information,
          text_name,
          text_surnames,
          text_doc_type,
          text_select,
          text_cc,
          text_ce,
          text_pnn,
          text_ssn,
          text_lic,
          text_select_nit,
          text_doc_numer_register,
          text_payment_method,
          text_value_assign_remember,
          text_write_value_dots_commas,
          text_close,
          text_save,
          text_bank,
          text_card_number,
          text_date_mm_yy,
          text_mounth,
          text_year,
          text_number_installments,
          text_cardholder,
          text_default,
          text_yes,
          text_no,
          text_cards_dues,
        },
      },
    } = this.props;

    let is_active = payment_modal ? 'active' : '';
    let cash_select = payment_cash_select === 'cash' ? 'active' : '';
    let pse_select = payment_cash_select === 'pse' ? 'active' : '';

    return ReactDOM.createPortal(
      <div className={`paymentModal_overlay ${is_active}`}>
        <div className={`paymentModal_content ${is_active}`}>
          <div className="paymentModal_title">
            {payment_type === 'cash' && (
              <>{text_assign_balance ? text_assign_balance : 'ASIGNAR SALDO'}</>
            )}
            {payment_type === 'card' && (
              <Fragment>
                <span className="icon-double-card" />
                {text_add_card ? text_add_card : 'AGREGAR TARJETA'}
              </Fragment>
            )}
            {payment_type === 'edit-card' && (
              <Fragment>
                <span className="icon-double-card" />
                {text_edit_2_card ? text_edit_2_card : 'EDITAR TARJETA'}
              </Fragment>
            )}
          </div>
          <div className="row">
            {payment_type === 'cash' &&
              !payment_loading &&
              payment_loading_message === '' && (
                <Fragment>
                  <div className="col-md-12">
                    <div className="paymentModal_menu">
                      <button
                        onClick={() => chageSelectCash('cash')}
                        className={`${cash_select}`}
                      >
                        {text_cash ? text_cash : 'Efectivo'}
                      </button>
                      <button
                        onClick={() => chageSelectCash('pse')}
                        className={`${pse_select}`}
                      >
                        {text_pse ? text_pse : 'PSE'}
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          {text_following_information
                            ? text_following_information
                            : 'Los siguientes datos, son los mismos a indicar al momento de cancelar.'}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label>{text_name ? text_name : 'Nombre'}</label>
                        <input
                          className="form-control"
                          type="text"
                          value={payment_name}
                          onChange={(input) =>
                            changePaymentName(input.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <label>
                          {text_surnames ? text_surnames : 'Apellidos'}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={payment_lastname}
                          onChange={(input) =>
                            changePaymentLastname(input.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <label>
                          {text_doc_type ? text_doc_type : 'Tipo de Documento'}
                        </label>
                        <select
                          className="form-control"
                          value={payment_doc_type}
                          onChange={(input) =>
                            changePaymentDocType(input.target.value)
                          }
                        >
                          <option value="">
                            -- {text_select ? text_select : 'Seleccionar'} --
                          </option>
                          <option value="CC">
                            {text_cc ? text_cc : 'Cedula de Ciudadania'}
                          </option>
                          <option value="CE">
                            {text_ce ? text_ce : 'Cedula Extranjera'}
                          </option>
                          <option value="PPN">
                            {text_pnn ? text_pnn : 'Pasaporte'}
                          </option>
                          <option value="SSN">
                            {text_ssn ? text_ssn : 'Número de Seguridad Social'}
                          </option>
                          <option value="LIC">
                            {text_lic ? text_lic : 'Licencia de Conducción'}
                          </option>
                          <option value="NIT">
                            {text_select_nit
                              ? text_select_nit
                              : 'Número de Identificación Tributaria'}
                          </option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label>
                          {text_doc_numer_register
                            ? text_doc_numer_register
                            : 'No. Documento'}
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          value={payment_doc_number}
                          onChange={(input) =>
                            changePaymentDocNumber(input.target.value)
                          }
                        />
                      </div>
                    </div>
                    {payment_cash_select === 'pse' &&
                    payment_banks_error === '' ? (
                      <Fragment>
                        <label>{text_bank ? text_bank : 'Banco'}</label>
                        <select
                          className="form-control"
                          defaultValue={payment_pse_bank}
                          onChange={(input) =>
                            changePSEBank(input.target.value)
                          }
                        >
                          {payment_banks_pse.length > 0 &&
                            payment_banks_pse.map((item) => {
                              return (
                                <option
                                  value={item.bankCode}
                                  key={item.bankCode}
                                >
                                  {item.bankName}
                                </option>
                              );
                            })}
                        </select>
                      </Fragment>
                    ) : (
                      <div>{payment_banks_error}</div>
                    )}
                    {payment_cash_select === 'cash' && (
                      <Fragment>
                        <label>
                          {text_payment_method
                            ? text_payment_method
                            : 'Medio de Pago'}
                        </label>
                        <select
                          className="form-control"
                          defaultValue={payment_cash_type}
                          onChange={(input) =>
                            cashPaymentType(input.target.value)
                          }
                        >
                          <option>
                            -- {text_select ? text_select : 'Seleccionar'} --
                          </option>
                          <option value="efecty">Efecty</option>
                          <option value="baloto">Baloto</option>
                          {/* <option value="red_servi">Red Servi</option>
												<option value="punto_red">Punto Red</option> */}
                        </select>
                      </Fragment>
                    )}
                    <label>
                      {text_value_assign_remember
                        ? text_value_assign_remember
                        : 'VALOR A ASIGNAR: Recuerda que no debe ser menor de $100.000'}
                    </label>
                    <label>
                      {text_write_value_dots_commas
                        ? text_write_value_dots_commas
                        : 'Escribe el valor sin puntos y comas.'}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={payment_cash_value}
                      onChange={(input) => cashPaymentValue(input.target.value)}
                    />
                  </div>
                </Fragment>
              )}
            {payment_type === 'card' &&
              !payment_loading &&
              payment_loading_message === '' && (
                <Fragment>
                  <div className="col-md-12">
                    <label>
                      {text_card_number
                        ? text_card_number
                        : 'NÚMERO DE LA TARJETA'}
                    </label>
                    <div className="d-flex align-items-center paymentModal_row-card">
                      <input
                        type="number"
                        className="form-control"
                        value={payment_number}
                        onChange={(input) =>
                          changePaymentNumber(input.target.value)
                        }
                      />
                      <span className="icon-select-card" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label>
                      {text_date_mm_yy ? text_date_mm_yy : 'FECHA MM/AA'}
                    </label>
                  </div>
                  <div className="col-sm-6">
                    <select
                      className="form-control"
                      value={payment_exp_month}
                      onChange={(input) =>
                        changePaymentMonth(input.target.value)
                      }
                    >
                      <option>{text_mounth ? text_mounth : 'Mes'}</option>
                      {this.renderMonths()}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <select
                      className="form-control"
                      value={payment_exp_year}
                      onChange={(input) =>
                        changePaymentYear(input.target.value)
                      }
                    >
                      <option>{text_year ? text_year : 'Año'}</option>
                      {this.renderYears()}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>CVC/CVV</label>
                    <input
                      type="number"
                      className="form-control"
                      value={payment_cvc}
                      onChange={(input) => changePaymentCVC(input.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label>
                      {text_number_installments
                        ? text_number_installments
                        : 'No. Cuotas'}
                    </label>
                    <select
                      className="form-control"
                      value={payment_dues}
                      onChange={(input) =>
                        changePaymentDues(input.target.value)
                      }
                    >
                      <option>
                        {text_cards_dues ? text_cards_dues : 'No. Cuotas'}
                      </option>
                      {this.renderDues()}
                    </select>
                  </div>
                  <div className="col-md-12">
                    <label>
                      {text_cardholder
                        ? text_cardholder
                        : 'TITULAR DE LA TARJETA'}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={payment_headline}
                      onChange={(input) =>
                        changePaymentHeadline(input.target.value)
                      }
                    />
                  </div>
                </Fragment>
              )}
            {payment_type === 'edit-card' &&
              !payment_loading &&
              payment_loading_message === '' && (
                <Fragment>
                  <div className="col-sm-6">
                    <label>
                      {text_number_installments
                        ? text_number_installments
                        : 'No. Cuotas'}
                    </label>
                    <select
                      className="form-control"
                      value={payment_dues}
                      onChange={(input) =>
                        changePaymentDues(input.target.value)
                      }
                    >
                      <option value="">
                        {text_number_installments
                          ? text_number_installments
                          : 'No. Cuotas'}
                      </option>
                      {this.renderDues()}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label>
                      {text_default ? text_default : 'Predeterminada'}
                    </label>
                    <select
                      className="form-control"
                      value={payment_is_default}
                      onChange={(input) =>
                        changePaymentDefault(input.target.value)
                      }
                    >
                      <option value="0">{text_no ? text_no : 'No'}</option>
                      <option value="1">{text_yes ? text_yes : 'Si'}</option>
                    </select>
                  </div>
                </Fragment>
              )}
            {payment_loading_message !== '' && (
              <div className="col-md-12">
                <p>{payment_loading_message}</p>
              </div>
            )}
            <div className="col-md-12 d-flex justify-content-center">
              <button
                className="btn paymentModal_btn-cancel"
                onClick={() => closePaymentModal()}
              >
                {text_close ? text_close.toUpperCase() : 'CERRAR'}
              </button>
              {payment_loading_message === '' && (
                <button
                  className="btn paymentModal_btn-save"
                  onClick={this.paymentSave}
                >
                  {text_save ? text_save.toUpperCase() : 'GUARDAR'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>,
      document.getElementById('modal')
    );
  }
}

const mapStateToProps = ({ paymentReducer, siteReducer }) => {
  return {
    ...paymentReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  closePaymentModal,
  cashPaymentType,
  cashPaymentValue,
  saveCash,
  changePaymentNumber,
  changePaymentMonth,
  changePaymentYear,
  changePaymentCVC,
  changePaymentDues,
  changePaymentHeadline,
  saveCard,
  changePaymentDefault,
  paymentCardUpdate,
  listBanksPSE,
  chageSelectCash,
  changePSEBank,
  changePaymentName,
  changePaymentLastname,
  changePaymentDocType,
  changePaymentDocNumber,
  savePSE,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
