export const POST_CONSECUTIVE = 'POST_CONSECUTIVE';
export const POST_NAME = 'POST_NAME';
export const POST_QUOTATION_CONSECUTIVE = 'POST_QUOTATION_CONSECUTIVE';
export const POST_STATUS = 'POST_STATUS';
export const POST_TYPE_ID = 'POST_TYPE_ID';
export const POST_NETWORKS = 'POST_NETWORKS';
export const POST_PHOTOS = 'POST_PHOTOS';
export const POST_VIDEO = 'POST_VIDEO';
export const POST_DESCRIPTION = 'POST_DESCRIPTION';
export const POST_LINK = 'POST_LINK';
export const CURRENT_POST = 'CURRENT_POST';
export const POST_LIST = 'POST_LIST';
export const POST_LOADING = 'POST_LOADING';
export const POST_CLEAR = 'POST_CLEAR';
export const SET_POST_EDIT = 'SET_POST_EDIT';
export const POST_LIST_LOADING = 'POST_LIST_LOADING';
export const POST_TOTAL_LIST = 'POST_TOTAL_LIST';
export const POST_PAGE = 'POST_PAGE';
export const POST_TOTAL_PAGES = 'POST_TOTAL_PAGES';
export const POST_MESSAGE = 'POST_MESSAGE';
export const POST_TYPES_LIST = 'POST_TYPES_LIST';
export const POST_STATUS_LIST = 'POST_STATUS_LIST';
