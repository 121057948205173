import React from 'react';
import Spinner from './Spinner';
import './css/TopSpinner.css';

function TopSpinner(props) {
  return(
    <div className="centerTop">
      <Spinner type="purple"/>
      {props.title &&
        <div>{props.title}</div>
      }
    </div>
  )
}

export default TopSpinner;