import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';

function ItemSlider(props) {
	let {data} = props;
	if (data.link !== '' && data.link !== null) {
		return (
			<Link to={data.link}>
				<img src={data.image} alt={data.name} className="homeSlicer_img-desktop"/>
				<img src={data.image_mobile} alt={data.name} className="homeSlicer_img-mobile"/>
			</Link>
		)
	}
	return (
		<Fragment>
			<img src={data.image} alt={data.name} className="homeSlicer_img-desktop"/>
			<img src={data.image_mobile} alt={data.name} className="homeSlicer_img-mobile"/>
		</Fragment>
	)
}

export default ItemSlider;