import {
  COMPANY_LOADING,
  COMPANY_DATA,
  COMPANY_ERROR,
  COMPANY_CLEAR,
  COMPANY_CHANGE_PAGE,
  COMPANY_COMMENTS,
  COMPANY_LIST_PRODUCTS,
  COMPANY_LIST_VIEW_PRODUCTS,
} from "../types/companyTypes";

const INITIAL_STATE = {
  company_data: null,
  company_list_products: [],
  company_list_view_products: [],
  company_seller: "",
  company_shopping: "",
  company_loading: true,
  company_item_per_page: 12,
  company_current_page: 1,
  company_error: false,
  company_comments: [],
};

const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COMPANY_LOADING:
      return {
        ...state,
        company_loading: action.payload,
      };
    case COMPANY_DATA:
      return {
        ...state,
        company_data: action.payload,
        company_loading: false,
        company_error: false,
      };
    case COMPANY_ERROR:
      return {
        ...state,
        company_error: true,
        company_loading: false,
      };
    case COMPANY_CLEAR:
      return {
        ...state,
        company_data: null,
        company_list_products: [],
        company_list_view_products: [],
        company_seller: "",
        company_shopping: "",
        company_loading: true,
        company_current_page: 1,
        company_error: false,
        company_comments: [],
      };
    case COMPANY_CHANGE_PAGE:
      return {
        ...state,
        company_list_view_products: action.payload.list_view,
        company_current_page: action.payload.current_page,
      };
    case COMPANY_LIST_PRODUCTS:
      return {
        ...state,
        company_list_products: action.payload,
      };
    case COMPANY_LIST_VIEW_PRODUCTS:
      return {
        ...state,
        company_list_view_products: action.payload,
      };
    case COMPANY_COMMENTS:
      return {
        ...state,
        company_comments: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default companyReducer;
