import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import Spinner from '../../../../components/all/Spinner';
import DocumentRegister from '../../../../components/DocumentRegister';
import InfluencerRegister from '../../../../components/InfluencerRegister';

function DocumentRegisterView({ userReducer: { user_data } }) {
  if (user_data && user_data.send_request) {
    return <Redirect to="/manager/status" />;
  }

  if (user_data && user_data.user_type_id === 8) {
    return <InfluencerRegister />;
  }

  if (user_data) {
    return <DocumentRegister />;
  }

  return <Spinner type="purple" />;
}

const mapStateToProps = ({ userReducer }) => {
  return {
    userReducer,
  };
};

export default connect(mapStateToProps, null)(DocumentRegisterView);
