import React, { useState, useEffect } from 'react';
import './UploadVideo.css';
import { connect } from 'react-redux';
import Spinner from '../Spinner';
import Swal from 'sweetalert2';

const UploadVideo = (props) => {
  const {
    stateToUpdate,
    updateMethod,
    deleteMethod,
    editView,
    porposalReducer: { porposal_video_loading },
    siteReducer: {
      site_texts: {
        text_upload_video,
        text_upload_video_caption,
        text_warning_only_video_file,
      },
    },
  } = props;

  useEffect(() => {}, [stateToUpdate]);

  const messageSwal = Swal.mixin({
    customClass: {
      confirmButton: 'button__confirm',
    },
    buttonsStyling: false,
  });

  /**
   * When the user selects a video file, set the video state to the URL of the selected file.
   */
  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    const extension = file.name.split('.').pop();
    if (extension === 'mp4' || extension === 'mov') {
      // verificar que sea un archivo de video
      updateMethod(file);
    } else {
      messageSwal.fire({
        title: 'Upsss!!!',
        text: `${
          text_warning_only_video_file ||
          'Solo se permiten archivos de video mp4 o mov'
        }`,
        type: 'warning',
        confirmButtonColor: '#50318B',
      });
    }
  };

  /**
   * When the user clicks the delete button, prevent the default action, and set the video to null.
   */
  const handleDeleteVideo = (event, id) => {
    event.preventDefault();
    // console.log(id);
    deleteMethod(id);
  };

  /**
   * It prevents the default behavior of the browser when a file is dragged and dropped.
   */
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  };

  /**
   * It prevents the default behavior of the event.
   */
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * This function prevents the default behavior of the event and stops the event from propagating.
   */
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  /**
   * When the user drops a file on the video element, prevent the default behavior, stop the event from
   * propagating, get the file from the event, and set the video element's source to the file.
   */
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    const extension = file.name.split('.').pop(); // obtener la extensión del archivo
    if (extension === 'mp4' || extension === 'mov') {
      // verificar que sea un archivo de video
      updateMethod(file);
    } else {
      messageSwal.fire({
        title: 'Upsss!!!',
        text: `${
          text_warning_only_video_file ||
          'Solo se permiten archivos de video mp4 o mov'
        }`,
        type: 'warning',
        confirmButtonColor: '#50318B',
      });
    }
  };
  return (
    <div className="uploadVideo">
      <h4 className="block__title">
        {text_upload_video ? text_upload_video : 'Subir video'}
      </h4>
      <div className="upload__container">
        {stateToUpdate &&
        (stateToUpdate.length ||
          stateToUpdate.preview ||
          stateToUpdate.file) ? (
          <div className="video__preview__container">
            {porposal_video_loading ? (
              <div className="spinner-loading">
                <Spinner type="purple" />
              </div>
            ) : (
              <video
                className="preview__video"
                src={
                  stateToUpdate.preview || stateToUpdate.file || stateToUpdate
                }
                controls
              ></video>
            )}
            <button
              className="preview__closeButton"
              onClick={(event) =>
                handleDeleteVideo(
                  event,
                  stateToUpdate.file ? stateToUpdate.id : null
                )
              }
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        ) : (
          <label
            htmlFor="upload__video__input"
            className="drag-drop"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <i className="fa-solid fa-video"></i>
            {text_upload_video_caption
              ? text_upload_video_caption
              : 'Sube un archivo o arrastra y suelta MP4, MOV'}
            <br />
            up to 100MB
            <input
              className="upload__input"
              id="upload__video__input"
              type="file"
              accept="video/*"
              onChange={handleVideoChange}
            />
          </label>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ porposalReducer, siteReducer }) => {
  return {
    porposalReducer,
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(UploadVideo);
