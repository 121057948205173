import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  userReqActivation,
  userClear,
  userClearError,
  saveRecommendedCompanies,
  getRecommendedCompanies,
  changeRiskCenters,
} from "../../../functionality/actions/userActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddCompanies from "./AddCompanies";

const MySwal = withReactContent(Swal);

class SettingsStep5 extends Component {
  componentDidMount() {
    const {
      userReducer: { user_data },
      getRecommendedCompanies,
    } = this.props;

    if (user_data && user_data.recommended_companies) {
      getRecommendedCompanies();
    }
  }

  showError = (message) => {
    MySwal.fire({
      title: "Upsss!!!",
      text: message,
      type: "warning",
      confirmButtonColor: "#50318B",
      onClose: () => this.props.userClearError(),
    });
  };

  requestAprovation = () => {
    const {
      userReducer: { user_data, user_risk_centers },
      siteReducer: {
        site_texts: {
          text_form_validate,
          text_already_request,
          text_risk_centers_error,
        },
      },
      saveRecommendedCompanies,
    } = this.props;

    if (!user_data.send_request) {
      let { docs } = user_data;
      if (
        !docs ||
        user_data.profession === null ||
        user_data.doc_expedition_date === null ||
        docs.business_name === null ||
        docs.nit_number === null ||
        docs.phone === null ||
        docs.cod_category === null ||
        docs.average_sales === null ||
        docs.description === null ||
        docs.cod_department === null ||
        docs.cod_city === null ||
        docs.address === null ||
        docs.rut_scann === null ||
        docs.doc_scan_front === null ||
        docs.doc_scan_back === null ||
        user_data.type_person === null
      ) {
        this.showError(
          text_form_validate
            ? text_form_validate
            : "Válida que cada uno de los puntos del formulario estén debidamente diligenciados."
        );
        return;
      }
      if (!user_risk_centers) {
        this.showError(
          text_risk_centers_error
            ? text_risk_centers_error
            : "Debe autorizar la consulta en centrales de riesgo."
        );
        return;
      }
      saveRecommendedCompanies();
    } else {
      this.showError(
        text_already_request
          ? text_already_request
          : "Ya enviaste la solicitud de aprobación."
      );
    }
  };

  render() {
    const {
      userReducer: { user_data, user_risk_centers },
      siteReducer: {
        site_texts: {
          text_recommended_company,
          text_company_description,
          text_request_approval,
          text_save,
          text_risk_centers,
        },
      },
      saveRecommendedCompanies,
      changeRiskCenters,
    } = this.props;

    return (
      <Fragment>
        <div className="settingsView_title">
          {text_recommended_company
            ? text_recommended_company
            : "EMPRESAS REFERIDAS"}
        </div>
        <div className="settingsView_description settingsView_no-border">
          {text_company_description
            ? text_company_description
            : "Agrega mínimo 3 empresas que te gustaría tener dentro de la plataforma, recuerda que solo el nombre de la empresa es obligatorio."}
        </div>
        <AddCompanies />
        {!user_data.send_request && (
          <div className="settingsView_checkbox">
            <label>
              <input
                type="checkbox"
                checked={user_risk_centers ? "checked" : ""}
                value={user_risk_centers}
                onChange={() => changeRiskCenters(!user_risk_centers)}
              />
              {text_risk_centers
                ? text_risk_centers
                : "Autorizó para consulta las centrales de riesgo"}
            </label>
          </div>
        )}
        <div className="d-flex justify-content-center">
          {!user_data.send_request ? (
            <button
              className="btn settingsView_save"
              onClick={this.requestAprovation}
            >
              {text_request_approval
                ? text_request_approval
                : "SOLICITAR APROBACIÓN"}
            </button>
          ) : (
            <button
              className="btn settingsView_save"
              onClick={saveRecommendedCompanies}
            >
              {text_save ? text_save : "Guardar"}
            </button>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  userReqActivation,
  userClear,
  userClearError,
  saveRecommendedCompanies,
  getRecommendedCompanies,
  changeRiskCenters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsStep5);
