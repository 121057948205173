import React, { useEffect } from 'react';
import ManagerLayout from './layouts/ManagerLayout';
import CampaignDetailView from './views/CampaignDetailView/CampaignDetailView';
import CampaignResumeView from './views/CampaignDetailView/CampaignResume/CampaignResumeView';
import { connect } from 'react-redux';
import { getStatistics } from '../../../functionality/actions/campaignActions';
import { withRouter } from 'react-router';

const CampaignResumePage = (props) => {
  const {
    match: {
      params: { id: campaignConsecutive },
    },
    getStatistics,
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      await getStatistics(campaignConsecutive);
    };

    fetchData();

    return () => {};
  }, []);

  return (
    <ManagerLayout>
      <CampaignDetailView>
        <CampaignResumeView />
      </CampaignDetailView>
    </ManagerLayout>
  );
};

const mapStateToProps =
  () =>
  ({ userReducer }) => {
    return {
      userReducer,
    };
  };

const mapDispatchToProps = {
  getStatistics,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CampaignResumePage)
);
