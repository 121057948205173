import React, { Component } from "react";
import { connect } from "react-redux";
import ManagerLayout from "./layouts/ManagerLayout";
import ReferredView from "./views/ReferredView";
import { getReferrals } from "../../../functionality/actions/referredActions";

class ReferredPage extends Component {
  componentDidMount() {
    const { getReferrals } = this.props;
    getReferrals();
  }

  render() {
    return (
      <ManagerLayout>
        <ReferredView />
      </ManagerLayout>
    );
  }
}

const mapDispatchToProps = {
  getReferrals,
};

export default connect(null, mapDispatchToProps)(ReferredPage);
