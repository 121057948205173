import {
  DASHBOARD_DATA,
  DASHBOARD_LOADING,
  DASHBOARD_ERROR,
  DASHBOARD_CLEAR,
} from "../types/dashboardType";

const INITIAL_STATE = {
  dashboard_loading: false,
  dashboard_graph_bar: null,
  dashboard_error: false,
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DASHBOARD_CLEAR:
      return {
        ...state,
        dashboard_loading: false,
        dashboard_graph_bar: null,
        dashboard_error: false,
      };
    case DASHBOARD_LOADING:
      return {
        ...state,
        dashboard_loading: true,
      };
    case DASHBOARD_ERROR:
      return {
        ...state,
        dashboard_error: true,
      };
    case DASHBOARD_DATA:
      return {
        ...state,
        dashboard_loading: false,
        dashboard_graph_bar: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default dashboardReducer;
