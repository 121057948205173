import React from "react";
import SaleItem from "./SaleItem";
import "./css/SaleList.css";
import { connect } from "react-redux";

function SaleList(props) {
  const {
    siteReducer: {
      site_texts: {
        text_transaction,
        text_product_price,
        text_amount,
        text_total,
        text_date,
        text_status,
        text_actions,
      },
    },
  } = props;

  return (
    <div className="saleList_container">
      {props.data.length === 0 ? (
        <div className="no-products">
          En este momento no tienes {props.type}.
        </div>
      ) : (
        <div className="table-responsive-md">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <td className={`color-purple text-center`}>
                  {text_transaction ? text_transaction : "Transacción"}
                </td>
                <td className={`color-purple text-center`}>
                  {text_product_price ? text_product_price : "Precio"}
                </td>
                <td className={`color-purple text-center`}>
                  {text_amount ? text_amount : "Cantidad"}
                </td>
                <td className={`color-purple text-center`}>
                  {text_total ? text_total : "Total"}
                </td>
                <td className={`color-purple text-center`}>
                  {text_date ? text_date : "Fecha"}
                </td>
                <td className={`color-purple text-center`}>
                  {text_status ? text_status : "Estado"}
                </td>
                <td className={`color-purple text-center`}>
                  {text_actions ? text_actions : "Acciones"}
                </td>
              </tr>
            </thead>
            <tbody>
              {props.data.map((item) => {
                return (
                  <SaleItem key={item.id} data={item} actions={props.actions} />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(SaleList);
