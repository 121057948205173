import Swal from 'sweetalert2';
import {
  POST_CONSECUTIVE,
  POST_NAME,
  POST_QUOTATION_CONSECUTIVE,
  POST_STATUS,
  POST_TYPE_ID,
  POST_NETWORKS,
  POST_PHOTOS,
  POST_VIDEO,
  POST_DESCRIPTION,
  POST_LINK,
  CURRENT_POST,
  POST_LIST,
  POST_LOADING,
  POST_CLEAR,
  SET_POST_EDIT,
  POST_LIST_LOADING,
  POST_TOTAL_LIST,
  POST_PAGE,
  POST_TOTAL_PAGES,
  POST_MESSAGE,
  POST_TYPES_LIST,
  POST_STATUS_LIST,
} from '../types/postTypes';
import Api from '../Api';

const messageSwal = Swal.mixin({
  customClass: {
    confirmButton: 'button__confirm',
  },
  buttonsStyling: false,
});

export const postClear = () => (dispatch) => {
  dispatch({
    type: POST_CLEAR,
  });
};

export const setPostConsecutive = (value) => (dispatch) => {
  dispatch({
    type: POST_CONSECUTIVE,
    payload: value,
  });
};
export const setPostName = (value) => (dispatch) => {
  dispatch({
    type: POST_NAME,
    payload: value,
  });
};
export const setPostMessage = (value) => (dispatch) => {
  dispatch({
    type: POST_MESSAGE,
    payload: value,
  });
};
export const setPostQuotation = (value) => (dispatch) => {
  dispatch({
    type: POST_QUOTATION_CONSECUTIVE,
    payload: value,
  });
};
export const setPostStatus = (value) => (dispatch) => {
  dispatch({
    type: POST_STATUS,
    payload: value,
  });
};
export const setPostTypesList = (value) => (dispatch) => {
  dispatch({
    type: POST_TYPES_LIST,
    payload: value,
  });
};
export const setPostStatus_list = (value) => (dispatch) => {
  dispatch({
    type: POST_STATUS_LIST,
    payload: value,
  });
};
export const setPostTypeId = (value) => (dispatch) => {
  dispatch({
    type: POST_TYPE_ID,
    payload: value,
  });
};
export const setPostNetworks = (value) => (dispatch, getState) => {
  const currentValues = getState().postReducer.post_networks;
  const newValue = parseFloat(value);
  let newValues;

  if (currentValues.includes(newValue)) {
    newValues = currentValues.filter((val) => val !== newValue);
  } else {
    newValues = [...currentValues, newValue];
  }

  dispatch({
    type: POST_NETWORKS,
    payload: newValues,
  });
};

//* PHOTOS

export const setPostPhotos = (value) => (dispatch) => {
  dispatch({
    type: POST_PHOTOS,
    payload: value,
  });
};

export const addPostPhoto = (value) => (dispatch, getState) => {
  const { post_photos } = getState().postReducer;

  if (!post_photos.some((image) => image.name === value.name)) {
    const newImage = {
      file: value,
      preview: URL.createObjectURL(value),
    };

    const updatedImages = [...post_photos, newImage];
    dispatch(setPostPhotos(updatedImages));
  }
};

export const deletePostPhoto = (imageIndex) => (dispatch, getState) => {
  const { post_photos } = getState().postReducer;

  const updatedImages = [...post_photos];
  updatedImages.splice(imageIndex, 1);

  const photoToDelete = Object.values(post_photos).find(
    (photo) => photo.id === imageIndex
  );
  if (photoToDelete) {
    dispatch(deleteAttachmentImage(imageIndex));
  }
  dispatch(setPostPhotos(updatedImages));
};

export const deleteAttachmentImage = (id) => async (dispatch, getState) => {
  const { post_consecutive, post_photos } = getState().postReducer;

  const {
    site_texts: { text_success_deleted_photo, text_sorry },
  } = getState().siteReducer;

  const route = `v1/proposal-posts/${post_consecutive}/images/${id}`;
  const imagesToSave = post_photos.filter(
    (photo) => photo.id === undefined || photo.id === null
  );
  try {
    const response = await Api.asyncCallMethod(route, 'DELETE');
    const {
      data: { attachments },
    } = response.response;

    const newAttachments = [...attachments];

    imagesToSave.forEach((image) => {
      newAttachments.push(image);
    });

    if (response.status === 200) {
      messageSwal.fire({
        title: 'Done!!!',
        text: `${text_success_deleted_photo || 'Foto eliminada con exito'}`,
        type: 'success',
        confirmButtonColor: '#50318B',
      });
      dispatch(setPostPhotos(newAttachments));
    } else {
      messageSwal.fire({
        title: 'Error!!!',
        text: `${text_sorry || 'Ha ocurrido un error, intente mas tarde'}`,
        type: 'error',
        confirmButtonColor: '#50318B',
      });
    }
  } catch (error) {
    console.error(error);
  }
};

//* VIDEO

export const setPostVideo = (value) => (dispatch) => {
  dispatch({
    type: POST_VIDEO,
    payload: value,
  });
};

export const addPostVideo = (value) => (dispatch, getState) => {
  //todo: loading video
  const { post_video } = getState().postReducer;

  if ((post_video ?? []).length === 0) {
    const newVideo = {
      file: value,
      preview: URL.createObjectURL(value),
    };
    dispatch(setPostVideo(newVideo));
  }
};

export const deleteAttachmentVideo = (id) => async (dispatch, getState) => {
  const { post_consecutive } = getState().postReducer;

  const {
    site_texts: { text_success_deleted_video, text_sorry },
  } = getState().siteReducer;
  const route = `v1/proposal-posts/${post_consecutive}/images/${id}`;

  if (!id) {
    dispatch(setPostVideo([]));
  } else {
    try {
      const response = await Api.asyncCallMethod(route, 'DELETE');
      const {
        data: { attachments },
      } = response.response;
      if (response.status === 200) {
        messageSwal.fire({
          title: 'Done!!!',
          text: `${text_success_deleted_video || 'Video eliminado con exito'}`,
          type: 'success',
          confirmButtonColor: '#50318B',
        });
        dispatch(setPostVideo(attachments));
      } else {
        messageSwal.fire({
          title: 'Error!!!',
          text: `${text_sorry || 'Ha ocurrido un error, intente mas tarde'}`,
          type: 'error',
          confirmButtonColor: '#50318B',
        });
      }
    } catch (error) {}
  }
};

export const setPostDescription = (value) => (dispatch) => {
  dispatch({
    type: POST_DESCRIPTION,
    payload: value,
  });
};
export const setPostLink = (value) => (dispatch) => {
  dispatch({
    type: POST_LINK,
    payload: value,
  });
};
export const setCurrentPost = (value) => (dispatch) => {
  dispatch({
    type: CURRENT_POST,
    payload: value,
  });
};
export const setPostList = (value) => (dispatch) => {
  dispatch({
    type: POST_LIST,
    payload: value,
  });
};
export const setPostLoading = (value) => (dispatch) => {
  dispatch({
    type: POST_LOADING,
    payload: value,
  });
};
export const setPostListLoading = (value) => (dispatch) => {
  dispatch({
    type: POST_LIST_LOADING,
    payload: value,
  });
};

export const setEditPost = (value) => (dispatch) => {
  dispatch({
    type: SET_POST_EDIT,
    payload: value,
  });
};

export const sendPost = () => async (dispatch, getState) => {
  const data = new FormData();
  const {
    site_texts: {
      text_warning_send_media,
      text_warning_send_post_description,
      text_warning_choose_quotation,
      text_warning_choose_social_network,
    },
  } = getState().siteReducer;
  const {
    post_quotation_consecutive,
    post_name,
    post_type_id,
    post_networks,
    post_photos,
    post_video,
    post_description,
  } = getState().postReducer;

  // Verifica si se ha seleccionado una foto o un video
  if (post_video.length === 0 && post_photos.length === 0) {
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `${
        text_warning_send_media || 'Debes de enviar una foto o un video'
      }`,
      type: 'warning',
      confirmButtonColor: '#50318B',
    });
    return; // Sale de la función si no hay foto ni video
  }

  // Verifica si se ha ingresado una descripción para el post
  if (post_description === '') {
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `${
        text_warning_send_post_description ||
        'Debes añadir una descripción para tu post'
      }`,
      type: 'warning',
      confirmButtonColor: '#50318B',
    });
    return; // Sale de la función si no hay descripción
  }

  // Verifica si se ha seleccionado una cotización para el post
  if (post_quotation_consecutive === null) {
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `${
        text_warning_choose_quotation ||
        'Debes escoger una cotización para el post'
      }`,
      type: 'warning',
      confirmButtonColor: '#50318B',
    });
    return; // Sale de la función si no hay cotización seleccionada
  }

  // Agrega el nombre al FormData si existe
  if (post_name !== '') {
    data.append('name', post_name);
  }

  // Agrega la descripción al FormData si existe
  if (post_description !== '') {
    data.append('description', post_description);
  }

  // Agrega la cotización al FormData si existe
  if (post_quotation_consecutive !== null) {
    data.append('proposal_consecutive', post_quotation_consecutive);
  }

  // Agrega las fotos al FormData si existen
  if (post_photos.length !== 0) {
    post_photos.forEach((imageObj) => {
      if (imageObj.file instanceof File) {
        data.append('attachments[]', imageObj.file);
      }
    });
  }

  // Agrega el video al FormData si existe
  if (post_video?.file instanceof File) {
    data.append('attachments[]', post_video.file);
  }

  // Agrega las redes sociales al FormData si existen
  if (post_networks.length >= 1) {
    data.append('social_networks', post_networks);
  } else {
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `${
        text_warning_choose_social_network ||
        'Debes escoger una Red Social para el post'
      }`,
      type: 'warning',
      confirmButtonColor: '#50318B',
    });
    return;
  }

  // Agrega el tipo de post al FormData si existe
  if (post_type_id !== '') {
    data.append('post_type_id', post_type_id);
  }

  const route = `v1/proposal-posts`;

  const response = await Api.asyncSendFormData(route, 'POST', data);
  dispatch(getPostEdit(response.response.data.consecutive));
  dispatch(setPostConsecutive(response.response.data.consecutive));
  dispatch(setPostStatus(response.response.data.post_status_id));
  // await dispatch(getPostEdit(response.response.data.consecutive));
};

export const editPost = (postConsecutive) => async (dispatch, getState) => {
  const data = new FormData();

  const {
    site_texts: {
      text_warning_send_media,
      text_warning_send_post_description,
      text_warning_choose_quotation,
    },
  } = getState().siteReducer;

  const {
    post_quotation_consecutive,
    post_name,
    post_type_id,
    post_networks,
    post_photos,
    post_video,
    post_description,
    post_link,
  } = getState().postReducer;

  // Verifica si se ha seleccionado una foto o un video
  if (post_video.length === 0 && post_photos.length === 0) {
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `${
        text_warning_send_media || 'Debes de enviar una foto o un video'
      }`,
      type: 'warning',
      confirmButtonColor: '#50318B',
    });
    return; // Sale de la función si no hay foto ni video
  }

  // Verifica si se ha ingresado una descripción para el post
  if (post_description === '') {
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `${
        text_warning_send_post_description ||
        'Debes añadir una descripción para tu post'
      }`,
      type: 'warning',
      confirmButtonColor: '#50318B',
    });
    return; // Sale de la función si no hay descripción
  }

  // Verifica si se ha seleccionado una cotización para el post
  if (post_quotation_consecutive === '') {
    messageSwal.fire({
      title: 'Upsss!!!',
      text: `${
        text_warning_choose_quotation ||
        'Debes escoger una cotización para el post'
      }`,
      type: 'warning',
      confirmButtonColor: '#50318B',
    });
    return; // Sale de la función si no hay cotización seleccionada
  }

  // Agrega el nombre al FormData si existe
  if (post_name !== '') {
    data.append('name', post_name);
  }

  // Agrega la descripción al FormData si existe
  if (post_description !== '') {
    data.append('description', post_description);
  }

  // Agrega la cotización al FormData si existe
  if (post_quotation_consecutive !== '') {
    data.append('proposal_consecutive', post_quotation_consecutive);
  }

  // Agrega las fotos al FormData si existen
  if (post_photos.length !== 0) {
    post_photos.forEach((imageObj) => {
      if (imageObj.file instanceof File) {
        data.append('attachments[]', imageObj.file);
      }
    });
  }

  // Agrega el video al FormData si existe
  if (post_video?.file instanceof File) {
    data.append('attachments[]', post_video.file);
  }

  // Agrega las redes sociales al FormData si existen
  if (post_networks.length !== 0) {
    data.append('social_networks', post_networks);
  }

  // Agrega el tipo de post al FormData si existe
  if (post_type_id !== '') {
    data.append('post_type_id', post_type_id);
  }

  if (post_link !== null) {
    data.append('link', post_link);
  }

  const route = `v1/proposal-posts/${postConsecutive}?_method=PUT`;
  const response = await Api.asyncSendFormData(route, 'POST', data);
};

export const getPostEdit = (postConsecutive) => async (dispatch) => {
  dispatch(setPostLoading(true));
  const route = `v1/proposal-posts/${postConsecutive}/edit`;

  try {
    const response = await Api.asyncCallMethod(route, 'GET');
    dispatch(setCurrentPost(response.response.data));
    dispatch(setEditPost(response.response.data));
    dispatch(setPostLoading(false));
  } catch (error) {
    console.error(error);
    dispatch(setPostLoading(false));
  }
};

export const getByCampaignAndUserAll =
  (campaignConsecutive, search) => async (dispatch, getState) => {
    dispatch(setPostListLoading(true));

    const {
      site_texts: { text_sorry },
    } = getState().siteReducer;

    const {
      user_data: { username },
    } = getState().userReducer;

    const route = search
      ? `v1/campaigns/${campaignConsecutive}/posts/${username}${search}&type=all`
      : `v1/campaigns/${campaignConsecutive}/posts/${username}?type=all`;

    try {
      const response = await Api.asyncCallMethod(route, 'GET');

      if (response.status === 200) {
        const {
          meta: { last_page, total, current_page },
        } = response.response;

        dispatch({
          type: POST_TOTAL_LIST,
          payload: total,
        });
        dispatch({
          type: POST_PAGE,
          payload: current_page,
        });
        dispatch({
          type: POST_TOTAL_PAGES,
          payload: last_page,
        });
        dispatch(setPostList(response.response.data));

        dispatch(setPostListLoading(false));
      } else {
        dispatch(setPostListLoading(false));
        messageSwal.fire({
          title: 'Error!!!',
          text: `${text_sorry || 'ha ocurrido un error, trata más tarde'}`,
          type: 'error',
          confirmButtonColor: '#50318B',
        });
      }
    } catch (error) {
      dispatch(setPostListLoading(false));
    }
  };

export const getPostsByCampaign =
  (campaignConsecutive, search) => async (dispatch, getState) => {
    dispatch(setPostListLoading(true));

    const {
      site_texts: { text_sorry },
    } = getState().siteReducer;

    const route = search
      ? `v1/campaigns/${campaignConsecutive}/posts${search}`
      : `v1/campaigns/${campaignConsecutive}/posts`;
    try {
      const response = await Api.asyncCallMethod(route, 'GET');

      if (response.status === 200) {
        const {
          meta: { last_page, total, current_page },
        } = response.response;

        dispatch({
          type: POST_TOTAL_LIST,
          payload: total,
        });
        dispatch({
          type: POST_PAGE,
          payload: current_page,
        });
        dispatch({
          type: POST_TOTAL_PAGES,
          payload: last_page,
        });
        dispatch(setPostList(response.response.data));

        dispatch(setPostListLoading(false));
      } else {
        dispatch(setPostListLoading(false));
        messageSwal.fire({
          title: 'Error!!!',
          text: `${text_sorry || 'ha ocurrido un error, trata más tarde'}`,
          type: 'error',
          confirmButtonColor: '#50318B',
        });
      }
    } catch (error) {
      dispatch(setPostListLoading(false));
    }
  };

export const changeStatusPost =
  (postConsecutive, statusId, socialNetworks = null) =>
  async (dispatch, getState) => {
    dispatch(setPostLoading(true));
    const { post_message } = getState().postReducer;
    const route = `v1/proposal-posts/${postConsecutive}/change-status`;

    const {
      site_texts: {
        text_success_done,
        text_success_post_sent,
        text_success_requested_correction,
        text_success_post_accepted,
        text_success_post_publish,
        text_success_post_rejected,
      },
    } = getState().siteReducer;

    // 1 - Borrador; 2 - Enviado; 3 - En corrección; 4 - Aceptado; 5 - Publicado; 6 - Rechazado

    const data = {
      post_status_id: parseFloat(statusId),
      message: statusId !== 2 ? post_message : null,
    };

    if (socialNetworks) {
      data.social_networks = socialNetworks;
    }

    const messageByStatus = {
      2: `${text_success_post_sent || 'Post enviado correctamente'}`,
      3: `${text_success_requested_correction || 'Correccion solicitada'}`,
      4: `${text_success_post_accepted || 'Post aceptado correctamente'}`,
      5: `${text_success_post_publish || 'Post publicado correctamente'}`,
      6: `${text_success_post_rejected || 'Post rechazado correctamente'}`,
    };

    try {
      const response = await Api.asyncCallMethod(route, 'POST', data);
      if (response.status === 200) {
        messageSwal.fire({
          title: `${text_success_done || '¡Hecho!'}`,
          text: messageByStatus[statusId],
          type: 'success',
          confirmButtonColor: '#50318B',
        });
      }
      dispatch(setPostLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setPostLoading(false));
    }
  };

export const getPostTypes = (value) => async (dispatch) => {
  // dispatch(setPostListLoading(true));

  const route = `v1/post-types`;

  try {
    const response = await Api.asyncCallMethod(route, 'GET');
    dispatch(setPostTypesList(response.response.data));
    // dispatch(setPostListLoading(false));
  } catch (error) {
    console.error(error);
    // dispatch(setPostListLoading(false));
  }
};

export const deletePost = (postConsecutive) => async (dispatch, getState) => {
  const route = `v1/proposal-posts/${postConsecutive}`;

  const {
    site_texts: { text_success_done, text_succes_post_deleted },
  } = getState().siteReducer;

  try {
    const response = await Api.asyncCallMethod(route, 'DELETE');
    if (response.status === 200) {
      messageSwal.fire({
        title: `${text_success_done || '¡Hecho!'}`,
        text: `${
          text_succes_post_deleted || 'Post borrado satisfactoriamente'
        }`,
        type: 'success',
        confirmButtonColor: '#50318B',
      });
    }
  } catch (error) {
    console.error(error);
  }
};
