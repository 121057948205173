import React, { Component } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormLayout from "./layouts/FormLayout";
import { emailExpression } from "../../../utils/globalConst";
import { changeRegisterAlertVisible } from "../../../functionality/actions/registerAlertActions";
import {
  changeReferred,
  userClear,
  userClearError,
  changeFirstname,
  changeLastname,
  changeEmail,
  changePassword,
  changePasswordRepeat,
  changeUserTypeId,
  changeBusinessName,
  register,
} from "../../../functionality/actions/userActions";
import AlertRegister from "../../../components/all/AlertRegister";

const MySwal = withReactContent(Swal);

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusSearch: false,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { username },
      },
      changeReferred,
    } = this.props;

    if (username) {
      changeReferred(username);
    }

    this.validateScroll();
  }

  componentWillUnmount() {
    this.props.userClear();
  }

  openCloseSearch = (statusSearch) => {
    this.setState({
      statusSearch,
    });
  };

  validateScroll = () => {
    const registerPage = document.getElementById("register-page");

    if (registerPage) {
      registerPage.onmouseleave = ({ screenY }) => {
        if (screenY < 300) {
          this.props.changeRegisterAlertVisible("visible");
        }
      };
    }
  };

  showError = (message) => {
    MySwal.fire({
      title: "Upsss!!!",
      text: message,
      type: "warning",
      confirmButtonColor: "#50318B",
      onClose: () => this.props.userClearError(),
    });
  };

  toRegister = () => {
    const {
      userReducer: {
        firstname,
        lastname,
        email,
        password,
        password_repeat,
        user_type_id,
      },
      siteReducer: {
        site_texts: {
          text_fields_required,
          text_email_written,
          text_agree_terms,
          text_agree_policies,
          text_registering,
          text_passwords_not_match,
        },
      },
      register,
    } = this.props;

    let acceptTC = document.getElementById("acceptTC").checked;
    let acceptPP = document.getElementById("acceptPP").checked;
    if (
      firstname.trim() === "" ||
      lastname.trim() === "" ||
      email.trim() === "" ||
      password.trim() === "" ||
      password_repeat.trim() === "" ||
      user_type_id === ""
    ) {
      this.showError(
        text_fields_required
          ? text_fields_required
          : "Todos los campos son obligatorios."
      );
      return false;
    }
    if (!emailExpression.test(email)) {
      this.showError(
        text_email_written
          ? text_email_written
          : "Válida que el email esté bien escrito."
      );
      return false;
    }
    if (password !== password_repeat) {
      this.showError(
        text_passwords_not_match
          ? text_passwords_not_match
          : "Las contraseñas no coinciden."
      );
      return false;
    }
    if (!acceptTC) {
      this.showError(
        text_agree_terms
          ? text_agree_terms
          : "Debes aceptar los Términos y Condiciones."
      );
      return false;
    }
    if (!acceptPP) {
      this.showError(
        text_agree_policies
          ? text_agree_policies
          : "Debes aceptar las Políticas de Privacidad."
      );
      return false;
    }
    register(text_registering ? text_registering : "Registrando...");
  };

  render() {
    const {
      userReducer: {
        is_user,
        firstname,
        lastname,
        email,
        password,
        password_repeat,
        user_type_id,
      },
      siteReducer: {
        site_texts: {
          // text_regiter,
          text_names,
          text_surnames,
          text_email,
          text_password,
          text_user_type,
          text_user_type_company,
          text_user_type_influencer,
          text_password_repeat,
          text_select,
          text_accept_terms,
          text_terms_conditions,
          text_accept_policies,
          text_policies,
          text_form_register,
          text_form_login,
        },
      },
      changeFirstname,
      changeLastname,
      changeEmail,
      changePassword,
      changePasswordRepeat,
      changeUserTypeId,
    } = this.props;

    if (is_user) {
      return <Redirect to="/manager/dashboard" />;
    }
    return (
      <FormLayout id="register-page">
        <AlertRegister />
        <div className="form-register">
          <div className="row form-row">
            <div className="col-md-6">
              <label>{text_names ? text_names : "Nombres"}</label>
              <input
                type="text"
                placeholder={text_names ? text_names : "Nombres"}
                className="form-control"
                value={firstname}
                onChange={(input) => changeFirstname(input.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>{text_surnames ? text_surnames : "Apellidos"}</label>
              <input
                type="text"
                placeholder={text_surnames ? text_surnames : "Apellidos"}
                className="form-control"
                value={lastname}
                onChange={(input) => changeLastname(input.target.value)}
              />
            </div>
          </div>
          <div className="row form-row">
            <div className="col-md-12">
              <label>{text_email ? text_email : "Correo Electrónico"}</label>
              <input
                type="email"
                placeholder="email@email.com"
                className="form-control"
                value={email}
                onChange={(input) => changeEmail(input.target.value)}
              />
            </div>
          </div>
          <div className="row form-row">
            <div className="col-md-6">
              <label>{text_password ? text_password : "Contraseña"}</label>
              <input
                type="password"
                placeholder={text_password ? text_password : "Contraseña"}
                className="form-control"
                value={password}
                onChange={(input) => changePassword(input.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>
                {text_password_repeat
                  ? text_password_repeat
                  : "Repetir Contraseña"}
              </label>
              <input
                type="password"
                placeholder={
                  text_password_repeat
                    ? text_password_repeat
                    : "Repetir Contraseña"
                }
                className="form-control"
                value={password_repeat}
                onChange={(input) => changePasswordRepeat(input.target.value)}
              />
            </div>
          </div>
          <div className="row form-row">
            <div className="col-md-12">
              <label>
                {text_user_type ? text_user_type : "Tipo de usuario"}
              </label>
              <select
                className="form-control"
                value={user_type_id}
                onChange={(input) => changeUserTypeId(input.target.value)}
              >
                <option>
                  -- {text_select ? text_select : "Seleccionar"} --
                </option>
                <option value="1">
                  {text_user_type_company
                    ? text_user_type_company
                    : "Empresa - Negocio"}
                </option>
                <option value="8">
                  {text_user_type_influencer
                    ? text_user_type_influencer
                    : "Influencer"}
                </option>
              </select>
            </div>
          </div>
          <div className="form- align-items-center">
            <label className="d-flex align-items-center label-tc">
              <input type="checkbox" id="acceptTC" />
              {text_accept_terms ? text_accept_terms : "Acepto los"}
              <a
                href="https://influenx.co/terminos-y-condiciones-de-uso/"
                rel="noopener noreferrer"
                className="form-link"
                target="_blank"
              >
                {text_terms_conditions
                  ? text_terms_conditions
                  : "Términos y Condiciones"}
              </a>
            </label>
          </div>
          <div className="form- align-items-center">
            <label className="d-flex align-items-center label-tc">
              <input type="checkbox" id="acceptPP" />
              {text_accept_policies ? text_accept_policies : "Acepto las"}
              <a
                href="https://influenx.co/politica-de-privacidad/"
                rel="noopener noreferrer"
                className="form-link"
                target="_blank"
              >
                {text_policies ? text_policies : "Políticas de Privacidad"}
              </a>
            </label>
          </div>
          <div className="row form-menu">
            <div className="col-sm-6">
              <Link to="/login" className="btn btn-block btn-pymex-2">
                {text_form_login ? text_form_login : "Iniciar Sesión"}
              </Link>
            </div>
            <div className="col-sm-6">
              <button
                className="btn btn-block btn-form"
                onClick={this.toRegister}
                id="button-register"
              >
                {text_form_register ? text_form_register : "Registrarme"}
              </button>
            </div>
          </div>
        </div>
      </FormLayout>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  changeReferred,
  userClear,
  userClearError,
  changeFirstname,
  changeLastname,
  changeEmail,
  changePassword,
  changePasswordRepeat,
  changeUserTypeId,
  changeBusinessName,
  changeRegisterAlertVisible,
  register,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
