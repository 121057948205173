import { OBJECTIVES, OBJECTIVES_LOADING } from '../types/objectivesTypes';
const INITIAL_STATE = {
  objectives: [],
  objectives_loading: false,
};
const objectivesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OBJECTIVES:
      return {
        ...state,
        objectives: action.payload,
      };
    case OBJECTIVES_LOADING:
      return {
        ...state,
        objectives_loading: action.payload,
      };
    default:
      return state;
  }
};
export default objectivesReducer;
