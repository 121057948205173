import React from 'react';
import Analityc from '../../Analytic/Analytic';
import { connect } from 'react-redux';
import { formatNumber } from '../../../../utils/formatNumber';

function Facebook(props) {
  const {
    statistics,
    siteReducer: {
      site_texts: {
        text_user_engagement,
        text_clicks_on_content,
        text_clicks_content_per_user,
        text_clicks_from_mobile,
      },
    },
  } = props;

  const getValue = (name) => {
    const item = statistics.find((item) => item.name === name);

    if (!item || !item.values) {
      return 0;
    }

    return formatNumber(parseInt(item.values[0].value));
  };

  return (
    <>
      <div className="analitycs__container">
        <Analityc
          icon={'fa-solid fa-user'}
          title={text_user_engagement || 'Engagement de usuarios'}
          value={getValue('page_engaged_users')}
        />
        <Analityc
          icon={'fa-solid fa-eye'}
          title={text_clicks_on_content || 'Clicks en los contenidos'}
          value={getValue('page_consumptions')}
        />
        <Analityc
          icon={'fa-solid fa-heart'}
          title={
            text_clicks_content_per_user ||
            'Clicks en los contenidos por usuario'
          }
          value={getValue('page_consumptions_unique')}
        />
        <Analityc
          icon={'fa-solid fa-user'}
          title={text_clicks_from_mobile || 'Clicks desde mobile'}
          value={getValue('page_places_checkin_mobile')}
        />
      </div>
      {/* <div className="analitycs__container">
        <Analityc
          icon={'fa-solid fa-comment'}
          title={'Clicks por los tipos de contenido'}
          value={getValue('page_consumptions_by_consumption_type')}
        />
        <Analityc
          icon={'fa-solid fa-eye'}
          title={'Feedback'}
          value={getValue('page_positive_feedback_by_type')}
        />
      </div> */}
    </>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return { siteReducer };
};

export default connect(mapStateToProps, null)(Facebook);
