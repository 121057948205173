import React, { useEffect } from 'react';
import './css/ListMessage.css';
import DefaultImage from '../../../images/believe.jpg';
import { connect } from 'react-redux';

function ListMessage(props) {
  const {
    siteReducer: {
      site_texts: { text_message },
    },
    userReducer: { user_data },
    messages,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('listMessage_container');
      if (element && element.scrollHeight) {
        element.scrollTop = element.scrollHeight;
      }
    }, 100);
  }, [messages]);

  return (
    <div className="listMessage_container" id="listMessage_container">
      {messages.map((message, i) => {
        let image = message.user.image ? message.user.image : DefaultImage;
        let name = message.user.username
          ? message.user.username
          : message.user.business_name;
        let typeUser =
          user_data && user_data.id === message.user.id ? 'current' : 'other';

        return (
          <div key={i} className={`listMessage_item ${typeUser}`}>
            <div className={`listMessage_box-user ${typeUser}`}>
              <div className="listMessage_send-by">
                <div className="listMessage_user">
                  <img src={image} alt={name + i} />
                  {name
                    .toUpperCase()
                    .replace('-', ' ')
                    .replace('_', ' ')
                    .replace('.', ' ')}
                </div>
                <div className="listMessage_date">{message.created_at}</div>
              </div>
              <div className="small">
                {text_message ? text_message : 'Mensaje'}:
              </div>
              <div
                className="listMessage_message_content"
                dangerouslySetInnerHTML={{ __html: message.message }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

const mapStateToProps = ({ siteReducer, userReducer }) => {
  return {
    siteReducer,
    userReducer,
  };
};

export default connect(mapStateToProps, null)(ListMessage);
