import Api from "../Api";
import { GENERAL_SOCIAL_NETWORKS } from "../types/generalTypes";

export const getSocialNetworks = () => (dispatch) => {
  Api.callMethod(
    "v1/social-networks",
    "GET",
    null,
    (res) => {
      if (res.status === 200) {
        const {
          response: { data },
        } = res;
        dispatch({
          type: GENERAL_SOCIAL_NETWORKS,
          payload: data,
        });
      }
    },
    (error) => {
      console.log("Error getSocialNetworks: ", error);
    }
  );
};
