import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  brandChangeCategory,
  brandChangeName,
  brandChangeDescription,
  brandChangeImage,
  brandChangeCover,
  brandChangeIsActive,
  brandSave,
  brandClear,
} from '../../functionality/actions/brandActions';
import CropImages from '../all/CropImages';
import InputFile from '../Questions/InputFile';
import Input from '../Questions/Input';
import Select from '../Questions/Select';
import { cropperImage } from '../../functionality/actions/cropperActions';

function BrandForm(props) {
  const [imageType, setImageType] = useState('');
  const {
    userReducer: { categories },
    siteReducer: {
      site_texts: {
        text_brand_add_title,
        text_updalod_image,
        text_brand_image,
        text_brand_cover,
        text_brand_image_label,
        text_brand_cover_label,
        text_brand_name,
        text_brand_description,
        text_brand_category,
        text_save,
      },
    },
    brandReducer: {
      brand_category,
      brand_name,
      brand_description,
      brand_image,
      brand_cover,
    },
    brandChangeCategory,
    brandChangeName,
    brandChangeDescription,
    brandChangeImage,
    brandChangeCover,
    modal,
    setModal,
    onCropImage,
    brandSave,
    brandClear,
  } = props;

  const onChangeImage = (e, imageType, width = 500, height = 500) => {
    const { cropperImage } = props;
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    setImageType(imageType);

    const reader = new FileReader();
    reader.onload = () => {
      let config = {
        cropper_image: reader.result,
        cropper_ratio_width: width,
        cropper_ratio_height: height,
      };
      cropperImage(config);
    };
    reader.readAsDataURL(files[0]);
  };

  const onCrop = (image) => {
    if (imageType === 'brand_image') {
      brandChangeImage(image);
    }
    if (imageType === 'brand_image') {
      brandChangeCover(image);
    }
    setImageType('');
  };

  return (
    <>
      {!onCropImage && <CropImages onCrop={onCrop} />}
      <div className={`site_modal ${modal}`}>
        <div className="modal_content large">
          <div className="modal_header">
            <div className="modal_title">
              {text_brand_add_title || 'Agregar marca'}
            </div>
            <button
              onClick={() => {
                setModal('');
                brandClear();
              }}
            >
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
          <div className="modal_body">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <label>
                    {text_brand_category || 'Selecciona la categoría:'}
                  </label>
                  <Select
                    value={brand_category}
                    onChange={brandChangeCategory}
                    options={categories}
                  />
                </div>
                <div className="col-md-12">
                  <label>{text_brand_name || 'Nombre:'}</label>
                  <Input
                    value={brand_name}
                    onChange={brandChangeName}
                    type={'text'}
                  />
                </div>
                <div className="col-md-12">
                  <label>{text_brand_description || 'Descripción:'}</label>
                  <textarea
                    className="questions_textarea"
                    value={brand_description}
                    rows={4}
                    onChange={(input) =>
                      brandChangeDescription(input.target.value)
                    }
                  />
                </div>
                <div className="col-md-6">
                  <InputFile
                    id="brand_image"
                    saveDoc={(file) => {
                      if (onCropImage) {
                        onCropImage(file, 'brand_image');
                      } else {
                        onChangeImage(file, 'brand_image');
                      }
                    }}
                    value={brand_image}
                    label={text_brand_image || 'Ver imagen'}
                    accept=".jpg, .png, .jpeg"
                    title={text_updalod_image || 'Subir la imagen'}
                    upload={false}
                    description={
                      text_brand_image_label ||
                      `Logo: <small>La imagen debe ser cuadrada 500px X 500px</small>`
                    }
                    preview={true}
                  />
                </div>
                <div className="col-md-6">
                  <InputFile
                    id="brand_cover"
                    saveDoc={(file) => {
                      if (onCropImage) {
                        onCropImage(file, 'brand_cover', 1000, 500);
                      } else {
                        onChangeImage(file, 'brand_cover', 900, 320);
                      }
                    }}
                    value={brand_cover}
                    label={text_brand_cover || 'Ver portada'}
                    accept=".jpg, .png, .jpeg"
                    title={text_updalod_image || 'Subir la imagen'}
                    upload={false}
                    description={
                      text_brand_cover_label ||
                      `Cover: <small>Image must be horizontal 1500px X 500px</small>`
                    }
                    preview={true}
                  />
                </div>
                <div className="col-md-12">
                  <button className="modal_save" onClick={brandSave}>
                    {text_save || 'Guardar'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ siteReducer, brandReducer, userReducer }) => {
  return {
    siteReducer,
    brandReducer,
    userReducer,
  };
};

const mapDispatchToProps = {
  brandChangeCategory,
  brandChangeName,
  brandChangeDescription,
  brandChangeImage,
  brandChangeCover,
  brandChangeIsActive,
  cropperImage,
  brandSave,
  brandClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandForm);
