import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './css/Error.css';
import imageError from '../../images/error.png';
import imageCart from '../../images/cart.png';
import { connect } from 'react-redux';

function Error(props) {
  const {
    type,
    buttonName,
    link,
    title,
    siteReducer: {
      site_texts: {
        text_not_page,
        text_was_error,
        text_no_items,
        text_error_purchase,
        text_error_campaign,
        text_error_no_influencers,
        text_error_no_posts,
        text_create_propose_caption,
      },
    },
  } = props;
  return (
    <div className="error_container">
      {type === '404' && (
        <Fragment>
          <img src={imageError} alt="error" className="error_img" />
          <div className="error_title">404</div>
          <div className="error_subtitle">
            {text_not_page ? text_not_page : 'Página no encontrada.'}
          </div>
        </Fragment>
      )}
      {type === 'error' && (
        <Fragment>
          <div className="error_title">Oops!</div>
          <div className="error_subtitle">
            {text_was_error
              ? text_was_error
              : 'Hubo un error, vuelve a intentarlo más tarde.'}
          </div>
        </Fragment>
      )}
      {type === 'no-data' && (
        <Fragment>
          <img src={imageError} alt="error" className="error_img" />
          <div className="error_subtitle">{title}</div>
        </Fragment>
      )}
      {type === 'no-cart' && (
        <Fragment>
          <img src={imageCart} alt="error" className="error_img" />
          <div className="error_subtitle">
            {text_no_items
              ? text_no_items
              : 'No hay elementos en el carrito de compras.'}
          </div>
        </Fragment>
      )}
      {type === 'no-checkout' && (
        <Fragment>
          <img src={imageCart} alt="error" className="error_img" />
          <div className="error_subtitle">
            {text_error_purchase
              ? text_error_purchase
              : 'La compra no se puede procesar.'}
          </div>
        </Fragment>
      )}
      {type === 'no-campaign' && (
        <>
          <img src={imageError} alt="error" className="error_img" />
          <div className="error_subtitle">
            {text_error_campaign || 'No tienes campañas'}
          </div>
        </>
      )}
      {type === 'no-influencer' && (
        <>
          <img src={imageError} alt="error" className="error_img" />
          <div className="error_subtitle">
            {text_error_no_influencers || 'No hay influencers'}
          </div>
        </>
      )}
      {type === 'no-posts' && (
        <>
          <img src={imageError} alt="error" className="error_img" />
          <div className="error_subtitle">
            {text_error_no_posts || 'No tienes posts'}
          </div>
        </>
      )}
      {type === 'no-proposals' && (
        <>
          <img src={imageError} alt="error" className="error_img" />
          <div className="error_subtitle">
            {text_create_propose_caption ||
              '¡Crea una propuesta para esta campaña!'}
          </div>
        </>
      )}
      {link && (
        <Link to={link} className="btn btn-pymex">
          {buttonName}
        </Link>
      )}
    </div>
  );
}

const mapStateToProps = ({ siteReducer }) => {
  return {
    siteReducer,
  };
};

export default connect(mapStateToProps, null)(Error);
