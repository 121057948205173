import React from 'react';
import './css/BoxForm.css';

function BoxForm(props) {
  let classForm = (props.type) ? props.type : '';
  return(
    <div className={`box-form ${classForm}`}>
      <h4 className="color-fuchsia">{props.title}</h4>
      {props.children}
    </div>
  )
}

export default BoxForm;