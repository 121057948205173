export const BRAND_USER_LIST = "BRAND_USER_LIST";
export const BRAND_ID = "BRAND_ID";
export const BRAND_CATEGORY = "BRAND_CATEGORY";
export const BRAND_NAME = "BRAND_NAME";
export const BRAND_DESCRIPTION = "BRAND_DESCRIPTION";
export const BRAND_IMAGE = "BRAND_IMAGE";
export const BRAND_COVER = "BRAND_COVER";
export const BRAND_IS_ACTIVE = "BRAND_IS_ACTIVE";
export const BRAND_CLEAR = "BRAND_CLEAR";
export const BRAND_QUESTION = "BRAND_QUESTION";
export const BRAND_LOADING = "BRAND_LOADING";
