import React, { Component } from "react";

class CopyButton extends Component {
  state = {
    statusCopy: false,
  };

  copyAction = () => {
    const { text } = this.props;
    this.setState({
      statusCopy: true,
    });

    navigator.clipboard.writeText(text);

    setTimeout(() => {
      this.setState({
        statusCopy: false,
      });
    }, 3000);
  };

  render() {
    const { copy, copied } = this.props;
    const { statusCopy } = this.state;

    return (
      <button onClick={this.copyAction} className="copy-button" id="copy-text">
        {statusCopy ? copied : copy}
      </button>
    );
  }
}

export default CopyButton;
