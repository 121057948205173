import React, { Component } from "react";
import { connect } from "react-redux";
import {
  changeUserCompanyName,
  changeUserCompanyWebsite,
  changeUserCompanyContactName,
  changeUserCompanyContactEmail,
  changeUserCompanyContactPhone,
  addUserCompanies,
  removeUserCompanies,
} from "../../../functionality/actions/userActions";

class AddCompanies extends Component {
  render() {
    const {
      userReducer: { user_companies },
      siteReducer: {
        site_texts: {
          text_add_company,
          text_company_name,
          text_company_website,
          text_company_contact_name,
          text_company_contact_phone,
          text_company_contact_email,
        },
      },
      changeUserCompanyName,
      changeUserCompanyWebsite,
      changeUserCompanyContactName,
      changeUserCompanyContactEmail,
      changeUserCompanyContactPhone,
      addUserCompanies,
      removeUserCompanies,
    } = this.props;

    return (
      <div className="settingsView_companies">
        <div className="d-flex justify-content-end">
          <button
            className="settingsView_companies-button"
            onClick={addUserCompanies}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            {text_add_company ? text_add_company : "Agregar Empresa"}
          </button>
        </div>
        {user_companies.map((company, index) => (
          <div className="settingsView_company" key={index}>
            <div className="settingsView_company-form-content">
              <div className="settingsView_company-form settingsView_company-form-first">
                <div className="settingsView_company-input">
                  <label>
                    {text_company_name
                      ? text_company_name
                      : "Nombre de la empresa"}
                  </label>
                  <input
                    placeholder={
                      text_company_name
                        ? text_company_name
                        : "Nombre de la empresa"
                    }
                    className="form-control"
                    value={company.name}
                    onChange={(input) =>
                      changeUserCompanyName(input.target.value, index)
                    }
                  />
                </div>
                <div className="settingsView_company-input">
                  <label>
                    {text_company_website ? text_company_website : "Página Web"}
                  </label>
                  <input
                    placeholder={
                      text_company_website ? text_company_website : "Página Web"
                    }
                    className="form-control"
                    value={company.website}
                    onChange={(input) =>
                      changeUserCompanyWebsite(input.target.value, index)
                    }
                  />
                </div>
              </div>
              <div className="settingsView_company-form">
                <div className="settingsView_company-input">
                  <label>
                    {text_company_contact_name
                      ? text_company_contact_name
                      : "Nombre Contacto"}
                  </label>
                  <input
                    placeholder={
                      text_company_contact_name
                        ? text_company_contact_name
                        : "Nombre Contacto"
                    }
                    className="form-control"
                    value={company.contact_name}
                    onChange={(input) =>
                      changeUserCompanyContactName(input.target.value, index)
                    }
                  />
                </div>
                <div className="settingsView_company-input">
                  <label>
                    {text_company_contact_phone
                      ? text_company_contact_phone
                      : "Teléfono Contacto"}
                  </label>
                  <input
                    placeholder={
                      text_company_contact_phone
                        ? text_company_contact_phone
                        : "Teléfono Contacto"
                    }
                    className="form-control"
                    type="number"
                    value={company.contact_phone}
                    onChange={(input) =>
                      changeUserCompanyContactPhone(input.target.value, index)
                    }
                  />
                </div>
                <div className="settingsView_company-input">
                  <label>
                    {text_company_contact_email
                      ? text_company_contact_email
                      : "Email Contacto"}
                  </label>
                  <input
                    placeholder={
                      text_company_contact_email
                        ? text_company_contact_email
                        : "Email Contacto"
                    }
                    type="email"
                    className="form-control"
                    value={company.contact_email}
                    onChange={(input) =>
                      changeUserCompanyContactEmail(input.target.value, index)
                    }
                  />
                </div>
              </div>
            </div>
            <button
              className="settingsView_company-remove"
              onClick={() => removeUserCompanies(index)}
            >
              <i className="fa fa-trash-o" aria-hidden="true"></i>
            </button>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ userReducer, siteReducer }) => {
  return {
    userReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  changeUserCompanyName,
  changeUserCompanyWebsite,
  changeUserCompanyContactName,
  changeUserCompanyContactEmail,
  changeUserCompanyContactPhone,
  addUserCompanies,
  removeUserCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCompanies);
