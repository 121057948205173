import React, { Component } from 'react';
import ProfileView from './views/ProfileView';
import ManagerLayout from './layouts/ManagerLayout';

class ProfilePage extends Component {
  render() {
    return (
      <ManagerLayout>
        <ProfileView />
      </ManagerLayout>
    );
  }
}

export default ProfilePage;
