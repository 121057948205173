import React from 'react';
import { connect } from 'react-redux';
import {
  changeRegisterAlertVisible,
  sendAlertResponse,
  changeRegisterAlertResponse,
} from '../../functionality/actions/registerAlertActions';
import logo from '../../images/logo.png';
import './css/AlertRegister.css';

function AlertRegister(props) {
  const {
    registerAlertReducer: { register_alert_visible, register_alert_response },
    changeRegisterAlertVisible,
    sendAlertResponse,
    changeRegisterAlertResponse,
  } = props;

  return (
    <div className={`alertRegister_container ${register_alert_visible}`}>
      <div className="alertRegister_alert">
        <div className="alertRegister_header">
          <img src={logo} alt="Logo INFLUENX" />
          <button onClick={() => changeRegisterAlertVisible('')}>
            <i className="fa fa-times" />
          </button>
        </div>
        <div className="alertRegister_body">
          <h3>No te quedes sin abrir tu cuenta en BAX!</h3>
          <p>
            Recuerda, en BAX canjeas tu inventario quieto, espacio vacío, tiempo
            libre o capacidad sin usar para obtener productos o servicios
            necesarios para financiar tu negocio, sin dinero de por medio. Y
            totalmente gratis!
          </p>
          <button onClick={() => changeRegisterAlertVisible('')}>
            REGISTRARME
          </button>
          <p>
            Si deseas no registrarte ¿nos puedes compartir el motivo? Es muy
            importante para nosotros mejorar cada día.
          </p>
          <textarea
            className="form-control"
            value={register_alert_response}
            onChange={(input) =>
              changeRegisterAlertResponse(input.target.value)
            }
          />
          <button onClick={sendAlertResponse}>ENVIAR</button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ registerAlertReducer }) => {
  return {
    registerAlertReducer,
  };
};

const mapDispatchToProps = {
  changeRegisterAlertVisible,
  sendAlertResponse,
  changeRegisterAlertResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertRegister);
