import React from 'react';
import { connect } from 'react-redux';
import {
  chatList,
  chatListView,
  chatProposalList,
} from '../../../../../functionality/actions/chatActions';
import InboxChats from '../../../../../components/user/manager/InboxChats';
import PorposeChat from '../../../../../components/user/manager/PorposeChat/PorposeChat';
import ChatMessage from '../../../../../components/user/manager/ChatMessage';
import './InboxPorposalView.css';

const InboxPorposalView = (props) => {
  const {
    chatReducer: { chat_list_view, chat_list_proposal },
    porposalReducer: { porposal_loading },
    handleFilter,
    codInbox,
    viewBoxMessage,
    siteReducer: {
      site_texts: { text_proposals },
    },
  } = props;

  return (
    <>
      <div className="row">
        <div className="col-md-5 col-lg-4">
          <InboxChats
            data={chat_list_view}
            actions={true}
            handleFilter={handleFilter}
          />
          <PorposeChat
            chats={chat_list_proposal}
            path={'/manager/inbox/porposal/'}
            inboxChat={true}
            title={text_proposals || 'Propuestas'}
          />
        </div>
        <div className="col-md-7 col-lg-8">
          {viewBoxMessage && (
            <ChatMessage
              loadingPorposal={porposal_loading}
              cod_chat={codInbox}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ chatReducer, porposalReducer, siteReducer }) => {
  return {
    chatReducer,
    porposalReducer,
    siteReducer,
  };
};

const mapDispatchToProps = {
  chatList,
  chatListView,
  chatProposalList,
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxPorposalView);
